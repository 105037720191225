import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import EventsRow from 'components/BuildingEvents/EventsRow/EventsRow';
import EventsCell from 'components/BuildingEvents/EventsRow/EventsCell';
import Svg from 'components/Svg/Svg';
import FolderRow from './FolderRow';
import ActionColumn from './ActionColumn';
import {
  getFileIconByExtension,
  getHumanreadableSize,
  indentationCSS,
  shouldShowInlineLink,
} from 'utils/Data/documents';

const Cell = styled(EventsCell)`
  position: ${props => props.relative && 'relative'};
  padding: ${props => props.theme.spacing.xs};

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
      &:hover ${Name} {
        color: ${props.theme.colors.cerulean};
      }
      &:hover ${FileIcon} {
        fill: ${props.theme.colors.cerulean};
      }
    `}

  ${indentationCSS}
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
`;

const FileIcon = styled(Svg)`
  float: left;
  font-size: ${props => props.theme.font.size.xxl};
  min-width: ${props => props.theme.font.size.xxl};
  margin: 0 ${props => props.theme.spacing.xs} 0 0;
  fill: ${props => props.theme.colors.midnight};

  ${props => props.theme.media.landscape`
        min-width: ${props => props.theme.font.size.xxl};
    `}
`;

const Name = styled.span`
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.normal};
  line-height: ${props => props.theme.font.lineHeight.md};
  word-break: break-word;
`;

const GrayText = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-weight: ${props => props.theme.fontWeight.text};
`;

export const CeruleanText = styled.a`
  color: ${props => props.theme.colors.cerulean};
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: 0.5em;
  cursor: pointer;
  white-space: nowrap;

  ${props => props.theme.media.landscape`
        padding: 0.5em 2em;
    `}

  &:active {
    color: ${props => props.theme.colors.midnight};
  }
`;

const DocumentRow = props => {
  const { rowData, t, onClick, depth = 0 } = props;

  if (!rowData.documentId) {
    return <FolderRow {...props} depth={depth + 1} />;
  }

  const download = e => {
    e.preventDefault();
    onClick(rowData);
  };
  const showInlineLink = shouldShowInlineLink(rowData);

  return (
    <EventsRow>
      <Cell hideOnMobile depth={depth} onClick={showInlineLink ? () => onClick(rowData, true) : undefined}>
        <NameContainer>
          <FileIcon name={getFileIconByExtension(rowData)} />
          <Name>{rowData.name}</Name>
        </NameContainer>
      </Cell>
      <Cell hideOnMobile>
        <GrayText>
          {moment
            .utc(rowData.date.date)
            .local()
            .format('Do MMMM YYYY, HH:mm')}
        </GrayText>
      </Cell>
      <Cell hideOnMobile>
        <GrayText>{getHumanreadableSize(rowData.size)}</GrayText>
      </Cell>
      <Cell hideOnMobile>
        {typeof onClick === 'function' && (
          <CeruleanText href="" onClick={download}>
            {t('Download')}
          </CeruleanText>
        )}
      </Cell>
      <Cell hideOnMobile />
      <Cell hideOnDesktop depth={depth}>
        <GrayText>
          {moment
            .utc(rowData.date.date)
            .local()
            .format('Do MMMM YYYY, HH:mm')}
        </GrayText>
        <br />
        <br />

        <NameContainer>
          <FileIcon name={getFileIconByExtension(rowData.extension)} />
          <Name>
            {rowData.name} <GrayText>({getHumanreadableSize(rowData.size)})</GrayText>
          </Name>
          {typeof onClick === 'function' && (
            <Fragment>
              <br />
              <CeruleanText href="" onClick={download}>
                {t('Download')}
              </CeruleanText>
            </Fragment>
          )}
        </NameContainer>
      </Cell>
      <Cell relative>{rowData.actions && <ActionColumn t={t} rowData={rowData} />}</Cell>
    </EventsRow>
  );
};

export default React.memo(DocumentRow);
