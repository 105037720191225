import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import _ from 'lodash';
import translations from 'decorators/Translations/translations';
import withErrors from 'decorators/Errors/withErrors';
import { getPartnerNumbers } from 'utils/profile';
import StandardPage from 'components/StandardPage/StandardPage.jsx';
import Header from 'containers/Application/Header/Header';
import { icons } from 'components/Icon/IconNames';
import FadeTransition from 'components/FadeTransition/FadeTransition';

import {
  setProfileProperty,
  loadProfile,
  saveProfile,
  setEditProfile,
  loadCustomers,
  loadPermissionsHierarchy,
  loadTranslations,
  setLanguage,
  loadPermissions,
  addLoading,
  removeLoading,
  getPortfolioCounts,
  loadUserCustomViews,
} from 'redux/modules/index.js';

import { default as UserForm } from './UserForm/UserForm';

const LOADING_PERMISSIONS = 'permissions';

class AdminEditUser extends React.Component {
  state = { saving: false, saved: false };

  componentDidMount() {
    if (!this.props.editProfiles || !this.props.editProfiles[this.props.match.params.id]) {
      this.loadProfile(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadProfile(this.props.match.params.id);
    }
    if (this.props.errorMessage && !_.isEqual(prevProps.errorMessage, this.props.errorMessage)) {
      this.props.showError();
    }
  }

  loadProfile(id) {
    this.props.addLoading(LOADING_PERMISSIONS);
    this.props.loadProfile(id).then(() => this.props.removeLoading(LOADING_PERMISSIONS));
    this.props.loadUserCustomViews(id);
  }

  saveProfile = (id, data) => {
    const oldLanguage = this.props.profile.language;

    this.setState({ saving: true });

    this.props.saveProfile(id, data).then(data => {
      if (data.result) {
        // Edited own profile -> switch language and load permissions if needed
        if (this.props.profile.id === id) {
          const newLanguage = data.result.language;
          const addedPermissions = _.flatten(
            Object.keys(data.result.permissions).map(x =>
              data.result.permissions[x].filter(y => this.props.functionalLocations[y] === undefined)
            )
          );

          if (oldLanguage !== newLanguage) {
            this.props.loadTranslations(newLanguage);
            this.props.setLanguage(newLanguage);
          }

          // Load permissions to all added functional locations
          if (addedPermissions.length > 0) {
            this.props.loadPermissions();
          }
        }

        this.setState({ saving: false, saved: true });

        setTimeout(() => {
          this.props.setProfileProperty(id, 'edited', false);
        }, 3000);

        setTimeout(() => {
          this.setState({ saved: false });
        }, 4000);
      } else {
        this.setState({ saving: false });
      }
    });
  };

  render() {
    const {
      match: {
        params: { id },
      },
      t,
      loading,
    } = this.props;
    const profile = this.props.editProfiles[id];

    const links = [
      { title: 'Back to user list', icon: icons.ARROW_LEFT, to: { pathname: '/Admin/Users' }, smallIcon: true },
      { title: 'Edit user', icon: icons.ADMIN_MANAGE_USERS, pathname: `/Admin/Users/Edit/${id}` },
    ];

    return (
      <FadeTransition>
        <StandardPage>
          <Helmet title={t('Edit user')} />
          <Header t={t} links={links} />
          <UserForm
            id="editUserForm"
            profileId={id}
            model={profile}
            setProperty={(property, value) => this.props.setProfileProperty(id, property, value)}
            submit={profile => this.saveProfile(id, profile)}
            t={t}
            saving={this.state.saving}
            saved={this.state.saved}
            loadingPermissions={_.includes(loading, LOADING_PERMISSIONS)}
          />
        </StandardPage>
      </FadeTransition>
    );
  }
}

const COMPONENT = 'AdminEditUser';

const mapStateToProps = (state, props) => ({
  editProfiles: state.profile.editProfiles,
  profile: state.profile.profile,
  functionalLocations: state.functionalLocations.functionalLocations,
  loading: state.common.loading[COMPONENT] || [],
});

function mapDispatchToProps(dispatch) {
  return {
    setProfileProperty: (id, property, value) => dispatch(setProfileProperty(id, property, value)),

    loadProfile: id =>
      dispatch(loadProfile(id)).then(p =>
        Promise.all([
          // Set profile to be edited.
          dispatch(setEditProfile(p.result.id)),
          dispatch(loadCustomers(getPartnerNumbers(p.result))),
          dispatch(
            loadPermissionsHierarchy(
              _.flatten(Object.keys(p.result.permissions || {}).map(x => p.result.permissions[x]))
            )
          ),
          dispatch(getPortfolioCounts(p.result.partnerPermissions || [])),
        ])
      ),

    saveProfile: (id, data) => dispatch(saveProfile(id, data)),

    loadTranslations: language => dispatch(loadTranslations(language)),

    setLanguage: language => dispatch(setLanguage(language)),

    loadPermissions: functionalLocation => dispatch(loadPermissions(functionalLocation)),

    addLoading: key => dispatch(addLoading(key, COMPONENT)),

    removeLoading: key => dispatch(removeLoading(key, COMPONENT)),

    loadUserCustomViews: id => dispatch(loadUserCustomViews(id)),
  };
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(withErrors(translations(AdminEditUser)));
