import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT } from '@caverion/redux/api/actions';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import values from 'lodash/values';

const initialState = {
  loading: false,
  alarms: {},
  error: '',
};

export const getSensorAlarmsById = state => state.sensorAlarms.alarms;
export const getSensorAlarmsBySensorId = createSelector(
  getSensorAlarmsById,
  alarms => keyBy(values(alarms), 'sensorId')
);

export const LOAD_SENSOR_ALARMS = 'CUSTOMER_PLATFORM/SensorAlarms/LOAD_SENSOR_ALARMS';
export const LOAD_SENSOR_ALARMS_SUCCESS = 'CUSTOMER_PLATFORM/SensorAlarms/LOAD_SENSOR_ALARMS_SUCCESS';
export const LOAD_SENSOR_ALARMS_FAIL = 'CUSTOMER_PLATFORM/SensorAlarms/LOAD_SENSOR_ALARMS_FAIL';

export const loadSensorAlarms = () => {
  return async dispatch => {
    dispatch({ type: LOAD_SENSOR_ALARMS });
    try {
      const alarms = await dispatch(IoT.getSensorAlarms());
      dispatch({ type: LOAD_SENSOR_ALARMS_SUCCESS, alarms });
    } catch (error) {
      return dispatch({
        type: LOAD_SENSOR_ALARMS_FAIL,
        error,
      });
    }
  };
};

export const UPDATE_SENSOR_ALARMS = 'CUSTOMER_PLATFORM/SensorAlarms/UPDATE_SENSOR_ALARMS';
export const UPDATE_SENSOR_ALARMS_SUCCESS = 'CUSTOMER_PLATFORM/SensorAlarms/UPDATE_SENSOR_ALARMS_SUCCESS';
export const UPDATE_SENSOR_ALARMS_FAIL = 'CUSTOMER_PLATFORM/SensorAlarms/UPDATE_SENSOR_ALARMS_FAIL';

export const upsertSensorAlarm = sensorAlarm => async dispatch => {
  dispatch({ type: UPDATE_SENSOR_ALARMS });
  try {
    const updated = await dispatch(IoT.upsertSensorAlarm(sensorAlarm));
    dispatch({ type: UPDATE_SENSOR_ALARMS_SUCCESS, alarm: updated });
  } catch (error) {
    dispatch({ type: UPDATE_SENSOR_ALARMS_FAIL, error });
    throw error;
  }
};

export const DELETE_SENSOR_ALARM = 'CUSTOMER_PLATFORM/SensorAlarms/DELETE_SENSOR_ALARM';
export const DELETE_SENSOR_ALARM_SUCCESS = 'CUSTOMER_PLATFORM/SensorAlarms/DELETE_SENSOR_ALARM_SUCCESS';
export const DELETE_SENSOR_ALARM_FAIL = 'CUSTOMER_PLATFORM/SensorAlarms/DELETE_SENSOR_ALARM_FAIL';

export const deleteSensorAlarm = alarmId => async dispatch => {
  dispatch({ type: DELETE_SENSOR_ALARM });
  try {
    await dispatch(IoT.deleteSensorAlarm(alarmId));
    dispatch({ type: DELETE_SENSOR_ALARM_SUCCESS, alarmId });
  } catch (error) {
    dispatch({ type: DELETE_SENSOR_ALARM_FAIL });
    throw error;
  }
};

export default createReducerFromMapping(
  {
    [LOAD_SENSOR_ALARMS]: state => ({ ...state, loading: true }),
    [LOAD_SENSOR_ALARMS_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_SENSOR_ALARMS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      alarms: keyBy(action.alarms, 'id'),
    }),
    [UPDATE_SENSOR_ALARMS]: state => ({ ...state, loading: true }),
    [UPDATE_SENSOR_ALARMS_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [UPDATE_SENSOR_ALARMS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      alarms: {
        ...state.alarms,
        [action.alarm.id]: action.alarm,
      },
    }),
    [DELETE_SENSOR_ALARM_SUCCESS]: (state, action) => ({
      ...state,
      alarms: omit(state.alarms, action.alarmId),
    }),
  },
  initialState
);
