import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'components/Select/Select';
import { VIEW_TYPES } from '../../containers/Application/Admin/CustomViews/utils';

const SelectWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.xs};
  right: 0;

  &&& {
    .Select-menu-outer {
      margin-top: -6px;
    }
    .Select-arrow-zone {
      right: 4px;
    }
  }
`;
SelectWrapper.displayName = 'SelectWrapper';

const ActionColumn = ({ customView, onAction, t }) => {
  return (
    <SelectWrapper>
      <Select
        lightBackground
        t={t}
        onChange={value => onAction(customView, value)}
        options={[
          { label: t('Duplicate'), value: 'DUPLICATE', disabled: customView.viewType === VIEW_TYPES.embeddedDocument },
          { label: t('Edit'), value: 'EDIT' },
          { label: t('Delete'), value: 'DELETE' },
        ]}
        customArrow="fa-ellipsis-h"
        placeholder=" "
      />
    </SelectWrapper>
  );
};
ActionColumn.displayName = 'ActionColumn';

ActionColumn.propTypes = {
  customView: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ActionColumn;
