import React from 'react';

import PropTypes from 'prop-types';
import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import SensorValues from 'containers/Application/SensorValues/SensorValues';

import connect from './connectEnergyModuleSensorDialog';
export const EnergyModuleSensorDialog = props => {
  const { onDialogClose, sensorId, energySensors, functionalLocation, sensorAlarmsById, visible } = props;
  if (!visible) {
    return null;
  }
  return (
    <Dialog isActive onOverlayClick={onDialogClose}>
      <DialogFrame onClose={onDialogClose} smallVerticalPadding>
        <SensorValues
          sensorsIds={[sensorId]}
          buildingSensors={energySensors}
          functionalLocation={functionalLocation}
          sensorAlarmsById={sensorAlarmsById}
        />
      </DialogFrame>
    </Dialog>
  );
};

EnergyModuleSensorDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  functionalLocation: PropTypes.object.isRequired,
  sensorId: PropTypes.number,
  sensorAlarmsById: PropTypes.object.isRequired,
  energySensors: PropTypes.array.isRequired,
};

export default connect(EnergyModuleSensorDialog);
