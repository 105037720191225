import React, { memo } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const QueryLink = props => {
  const { to } = props;

  // Clean unused props from withRouter since they cause warnings
  const linkProps = omit(props, ['history', 'location', 'match', 'staticContext']);
  linkProps.to = {
    ...to,
    search: queryString.stringify(to.query),
  };

  return <Link {...linkProps} />;
};
QueryLink.propTypes = {
  to: PropTypes.object,
};

export default withRouter(memo(QueryLink));
