import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, countBy, find, values, includes, orderBy, capitalize, sumBy } from 'lodash';
import moment from 'moment-timezone';

import { loadInspectionsContainer, loadMoreInspections } from 'redux/modules/containers/remote-center';
import Benchmarking from './Benchmarking';
import ChartSection from './ChartSection';
import { filterFLsBySelectedPartner } from 'containers/Application/Energy/EnergyOptimizationUtil';
import { FilterPillsContainer, SkeletonFilter, overrideColumnWidth } from 'components/BuildingEvents/BuildingEvents';
import FilterPill from 'components/FilterPills/FilterPill';
import FilterPills from 'components/FilterPills/FilterPills';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { mapFls } from './utils';

const EMPTY_ARRAY = [];
const filters = ['extended', 'nightly', 'limited', 'adhoc'];

const getMostInspections = (inspections, functionalLocations, partnerNumber) => {
  if (!inspections || !functionalLocations) {
    return EMPTY_ARRAY;
  }
  return mapFls(functionalLocations, partnerNumber, inspections, 'Inspections');
};

const getChartData = inspections =>
  [...Array(12)].map(
    (dummy, month) => (find(inspections, item => new Date(item.timestamp).getMonth() === month) || { count: 0 }).count
  );

const getMostPlanned = (slas, functionalLocations, partnerNumber, inspectionFilter) => {
  if (!slas || !functionalLocations) {
    return EMPTY_ARRAY;
  }
  const flList = functionalLocations.map(fl => {
    const to = `/${partnerNumber}/FunctionalLocation/${fl.functionalLocation}/RemoteCenter/Inspections`;
    const address = (fl.address && fl.city && `${fl.address}, ${fl.city}`) || '';
    const sla = find(slas, item => includes(item.path, fl.functionalLocation));
    let count = 0;
    if (sla) {
      if (inspectionFilter.all) {
        count = sla.nightlySlaTarget + sla.extendedSlaTarget + sla.limitedSlaTarget + (sla.adhocSlaTarget || 0);
      } else {
        count = filters.reduce((accu, type) => {
          if (inspectionFilter[type]) {
            accu = accu + sla[`${type}SlaTarget`];
          }
          return accu;
        }, 0);
      }
    }
    return { title: fl.description, subtitle: address, value: count, to };
  });
  return orderBy(flList, 'value', 'desc');
};

const currentYear = moment().year();

const Inspections = props => {
  const {
    t,
    partnerNumber,
    loadData,
    inspectionsByPartner,
    functionalLocations,
    monthlyInspections,
    loadMoreInspections,
  } = props;

  const [inspectionFilter, setFilter] = useState({ all: true });
  const [year, setYear] = useState(currentYear);

  useEffect(
    () => {
      loadData(partnerNumber);
      setYear(currentYear);
    },
    [partnerNumber]
  );

  const changeYear = newYear => {
    setYear(newYear);
    loadMoreInspections(partnerNumber, newYear);
  };

  const inspections = (inspectionsByPartner[partnerNumber] || {}).data;
  const inspectionCounts = countBy(inspections, 'type');
  const slas = (inspectionsByPartner[partnerNumber] || {}).slaData;
  const filteredInspections = inspectionFilter.all
    ? inspections
    : filter(inspections, inspection => inspectionFilter[inspection.type]);
  const loading = !inspections || inspections.loading;
  const flList = values(filterFLsBySelectedPartner(functionalLocations, partnerNumber)) || EMPTY_ARRAY;
  const mostInspections = getMostInspections(filteredInspections, flList, partnerNumber);
  const mostPlanned = getMostPlanned(slas, flList, partnerNumber, inspectionFilter);

  const partnerMonthlyInspections = monthlyInspections && monthlyInspections[partnerNumber];
  const chartData = partnerMonthlyInspections && partnerMonthlyInspections[year];
  const chartFilters = filter(filters, type => !chartData || !(!chartData[type] && type === 'adhoc'));

  const categories = [];
  filters.forEach(type => {
    // Inspections to date
    const inspectionActual = inspectionCounts[type] || 0;

    // Inspections SLA target
    const SLATarget = (slas && sumBy(slas, `${type}SlaTarget`)) || 0;

    // Completion percentage to date
    const value = (p => (isNaN(p) ? 0 : p > 1 ? 100 : Math.round(p * 100)))(inspectionActual / SLATarget);
    const valueInside = !SLATarget && !value && 'N/a';

    // Add inspections that have value or target
    if (value || SLATarget) {
      categories.push({
        title: t(`${capitalize(type)} Monitoring`),
        value,
        inspectionActual,
        SLATarget,
        valueInside,
        type,
      });
    }
  });
  const activeCategories = inspectionFilter.all
    ? categories
    : filter(categories, category => inspectionFilter[category.type]);
  const inspectionFilters = [{ title: t('Show All'), type: 'all' }].concat(
    categories.map(category => ({ title: category.title, type: category.type }))
  );

  const toggleFilter = (name, value) => {
    if (name === 'all') {
      setFilter({ all: value });
      return;
    }

    const newValues = { ...inspectionFilter, [name]: value, all: false };
    setFilter(newValues);
  };

  return (
    <Fragment>
      <FilterPillsContainer>
        {loading && (
          <FilterPills>
            <SkeletonFilter />
            <SkeletonFilter />
            <SkeletonFilter />
          </FilterPills>
        )}
        {!loading && (
          <FilterPills>
            {inspectionFilters.map(filter => (
              <FilterPill
                key={filter.type}
                selected={inspectionFilter[filter.type]}
                onClick={value => toggleFilter(filter.type, value, false)}
              >
                {filter.title}
              </FilterPill>
            ))}
          </FilterPills>
        )}
      </FilterPillsContainer>
      <OPICards>
        {loading && <OPICard loading overrideColumnWidth={overrideColumnWidth} />}
        {activeCategories.map(category => (
          <OPICard
            key={category.title}
            t={t}
            loading={loading}
            title={category.title}
            value={category.valueInside || category.value}
            subtitle={`${category.inspectionActual}\u00A0${t('Inspections')}\u00A0/\u00A0${category.SLATarget}\u00A0${t(
              'SLA'
            )}\n(${currentYear})`}
            ctxHelp={`${CTXHELP_PREFIX} ${category.title} OPI`}
            neutral
            overrideColumnWidth={overrideColumnWidth}
          />
        ))}
      </OPICards>
      <Benchmarking
        t={t}
        ctxHelp={`${CTXHELP_PREFIX} Inspections Benchmarking`}
        leftTitle="Most inspections done"
        rightTitle="Most inspections planned"
        leftData={mostInspections}
        rightData={mostPlanned}
        infoText={`${currentYear}`}
        loading={loading}
      />
      <ChartSection
        t={t}
        title={t('Inspections Done Per Month')}
        year={year}
        changeYear={changeYear}
        series={chartFilters.map(group => ({
          data: chartData && getChartData(chartData[group]),
          name: t(`${capitalize(group)} Monitoring`),
          _showTooltipForZeroValue: true,
          _unit: t('Inspections'),
          _hideTooltipUnit: true,
        }))}
        loading={!chartData || partnerMonthlyInspections.loading}
      />
    </Fragment>
  );
};

Inspections.propTypes = {
  t: PropTypes.func.isRequired,
  partnerNumber: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  inspectionsByPartner: state.notice.inspectionsByPartner,
  functionalLocations: state.functionalLocations.functionalLocations,
  monthlyInspections: state.notice.monthlyInspections,
});

const mapDispatchToProps = dispatch => ({
  loadData: partnerNumber => dispatch(loadInspectionsContainer(partnerNumber)),
  loadMoreInspections: (partnerNumber, year) => dispatch(loadMoreInspections(partnerNumber, year)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inspections);
