export const calculateStartEnd = (currentPage, lastPage) => {
  // Show all pages
  if (lastPage <= 7) {
    return { start: 1, end: lastPage };
  }

  // Show first 7 pages if page is less than 5
  if (currentPage <= 5) {
    return { start: 1, end: 7 };
  }

  // Show last 7 pages if at the end of the pages (not including last page)
  if (currentPage >= lastPage - 4) {
    return { start: lastPage - 7, end: lastPage - 1 };
  }

  // Default: show 2 pages before and 2 after
  return { start: currentPage - 3, end: currentPage + 2 };
};

export const calculateStartEndForMobile = (currentPage, lastPage) => {
  // Show all pages
  if (lastPage <= 5) {
    return { start: 1, end: lastPage };
  }

  // Show first 5 pages if page is less than 3
  if (currentPage <= 3) {
    return { start: 1, end: 5 };
  }

  // Show last 5 pages if at the end of the pages (not including last page)
  if (currentPage >= lastPage - 2) {
    return { start: lastPage - 5, end: lastPage - 1 };
  }

  // Default: show 1 page before and one after
  return { start: currentPage - 2, end: currentPage + 1 };
};
