import React from 'react';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader';

const WasteChartSkeleton = () => (
  <SkeletonChart>
    <Loader color="BLUE" size="LARGE" />
  </SkeletonChart>
);

export default WasteChartSkeleton;
