import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten } from 'polished';
import { isNil } from 'lodash';
import Svg from 'components/Svg/Svg';
import { icons } from 'components/Icon/IconNames';
import { getHumanreadableSize } from 'utils/Data/documents';
import withProgress from 'decorators/Progress/withProgress';

const File = styled.li`
  color: ${props => props.theme.colors.black};
  padding: ${props => props.theme.spacing.sm} 0;
  border-top: 2px solid ${props => props.theme.colors.mystic};
  position: relative;
  font-size: ${props => props.theme.font.size.xxs};
  overflow: hidden;
  position: relative;

  &:last-child {
    border-bottom: 2px solid ${props => props.theme.colors.mystic};
  }
`;

const Progress = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => lighten(0.4, props.theme.colors.emerald)};
  transform: translateX(${props => props.progress - 100}%);
  transition: ${props => props.theme.motion.quick} box-shadow ${props => props.theme.motion.easing},
    ${props => props.theme.motion.quick} transform linear;
`;

const RelativeWrapper = styled.div`
  position: relative;
  padding: 0 ${props => props.theme.spacing.xs};
`;

const FileName = styled.span`
  font-weight: ${props => props.theme.font.weight.bold};
  word-break: break-all;
  color: ${props => props.hasErrored && props.theme.colors.radicalRed};
`;

const FileSize = styled.span`
  white-space: nowrap;
`;

const Icon = styled(({ trash, ok, ...props }) => <Svg {...props} />)`
  position: relative;
  float: right;
  fill: ${props => (props.trash && props.theme.colors.radicalRed) || (props.ok && props.theme.colors.emerald)};
  vertical-align: text-top;
  cursor: ${props => props.trash && 'pointer'};
`;

const DocumentFile = ({ index, file, onFileRemoval, isCreateMode, hasErrored, progress, allLoading }) => {
  return (
    <File>
      {isCreateMode && <Progress progress={progress} />}
      <RelativeWrapper>
        <FileName hasErrored={hasErrored}>{file.filename || file.name}</FileName>
        <FileSize>{!isNil(file.size) && ` (${getHumanreadableSize(file.size)})`}</FileSize>
        {isCreateMode && !progress && !allLoading && (
          <Icon trash name={icons.DEL} onClick={() => onFileRemoval(index)} />
        )}
        {progress === 100 && <Icon ok name={icons.OK} />}
      </RelativeWrapper>
    </File>
  );
};

DocumentFile.propTypes = {
  index: PropTypes.number.isRequired,
  file: PropTypes.object.isRequired,
  onFileRemoval: PropTypes.func.isRequired,
  isCreateMode: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
  allLoading: PropTypes.bool.isRequired,
};

export default withProgress(DocumentFile);
