import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'components/Svg/Svg';
import isEmpty from 'lodash/isEmpty';
import WasteTotalsSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTotals/WasteTotalsSkeleton';
import styled, { withTheme } from 'styled-components';
import { formatNumber } from 'utils/Number/decimalFormat';

const StyledWasteAmountsTotals = styled.div`
  color: ${props => props.theme.colors.darkGray} 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${props => props.theme.colors.lightGray} 1px solid;
  font-family: ${props => props.theme.font.family.arial};
  padding: 1em;

  > div {
    display: flex;
  }
`;

const Title = styled.div`
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.xxs};
`;

const Icon = styled.div`
  padding-top: 1em;
  text-align: center;
`;

const Main = styled.div`
  flex-direction: column;
  align-items: center;
  padding-top: 1em;
`;

const Sum = styled.div`
  font-size: ${props => props.theme.font.size.md};
`;

const Subtitle = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  padding-top: 0.25em;
  &::first-letter {
    text-transform: capitalize;
  }
`;

const Breakdown = styled.div`
  width: 100%;
  padding-top: 0.5em;
  & > div {
    width: 50%;
    padding: 0.5em;
    text-align: center;
  }
`;

const BreakdownColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BreakdownLabel = styled.div`
  color: ${props => props.theme.charts.colorsRecycling[props.colorName]};
`;

export const WasteTotals = ({ profile, selectedTimeframeDescription, totals }) => {
  if (!totals) {
    return <WasteTotalsSkeleton />;
  }
  return (
    <StyledWasteAmountsTotals>
      <Title>{totals.title}</Title>
      <Icon>
        <Svg name="waste-bin" width="30px" height="30px" />
      </Icon>
      <Main>
        <Sum>{`${formatNumber(profile.language, totals.total)} ${totals.unit}`}</Sum>
        <Subtitle>{selectedTimeframeDescription}</Subtitle>
      </Main>
      {!isEmpty(totals.perCategory) && (
        <Breakdown>
          {totals.perCategory.map(entry => (
            <BreakdownColumn key={entry.categoryId}>
              <BreakdownLabel colorName={entry.colorName}>
                {`${formatNumber(profile.language, entry.total)} ${totals.unit}`}
              </BreakdownLabel>
              <Subtitle>{entry.categoryName}</Subtitle>
            </BreakdownColumn>
          ))}
        </Breakdown>
      )}
    </StyledWasteAmountsTotals>
  );
};

WasteTotals.propTypes = {
  profile: PropTypes.object.isRequired,
  selectedTimeframeDescription: PropTypes.string.isRequired,
  totals: PropTypes.shape({
    title: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
    perCategory: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.string.isRequired,
        categoryName: PropTypes.string.isRequired,
        colorName: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
      })
    ),
  }),
};

export default withTheme(WasteTotals);
