import { handleActions, combineActions, createAction } from 'redux-actions';
import { isError } from 'flux-standard-action';

const createActionWithMeta = type => createAction(type, ({ payload }) => payload, ({ meta }) => meta);

export const createActions = type => ({
  load: createActionWithMeta(`${type}`),
  fail: createActionWithMeta(`${type}_FAIL`),
  success: createActionWithMeta(`${type}_SUCCESS`),
});

export const createReducer = (
  { load, fail, success },
  initialState = { error: { status: false }, data: null, meta: null }
) =>
  handleActions(
    {
      [combineActions(load, fail, success)]: (state, action) => {
        const { payload, error, meta } = action;
        if (isError(action)) {
          return { ...state, error: { status: error, message: payload.message }, meta };
        }
        return { ...state, error: { status: false }, data: payload, meta };
      },
    },
    initialState
  );
