import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputSearch from 'components/Form/InputSearch';
import OutsideClickHandler from 'react-outside-click-handler';

const KEY_ESCAPE = 27;

const Container = styled.div`
  position: relative;
`;

const ResultContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  overflow: auto;
  max-height: 300px;
  z-index: ${props => props.theme.zIndex('hero')};
  background: ${props => props.theme.colors.white};
  border: ${props => props.theme.input.border.active};
  border-top: none;
`;

const Autocomplete = ({ suggestions, suggestionsComponent: SuggestionsComponent, onSelect, ...other }) => {
  const [focus, setFocus] = React.useState(false);
  const [prevSuggestions, setPrevSuggestions] = React.useState(suggestions);

  const handleSelect = React.useCallback(
    suggestion => {
      setFocus(false);
      if (onSelect) {
        onSelect(suggestion);
      }
    },
    [setFocus, onSelect]
  );

  const handleEscape = React.useCallback(event => {
    if (event.keyCode === KEY_ESCAPE) {
      setFocus(false);
    }
  }, []);

  React.useEffect(
    () => {
      document.addEventListener('keydown', handleEscape, false);
      return () => document.removeEventListener('keydown', handleEscape, false);
    },
    [handleEscape]
  );

  React.useEffect(
    () => {
      if (suggestions !== prevSuggestions) {
        setPrevSuggestions(suggestions);
        setFocus(true);
      }
    },
    [suggestions, prevSuggestions]
  );

  return (
    <Container>
      <InputSearch {...other} onFocus={() => setFocus(true)} />
      {focus && typeof suggestions !== 'undefined' && (
        <OutsideClickHandler onOutsideClick={() => setFocus(false)}>
          <ResultContainer>
            <SuggestionsComponent suggestions={suggestions} onSelect={handleSelect} />
          </ResultContainer>
        </OutsideClickHandler>
      )}
    </Container>
  );
};

Autocomplete.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  suggestions: PropTypes.any,
  suggestionsComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Autocomplete;
