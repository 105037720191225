import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TypeColumn from './TypeColumn';
import NameColumn from './NameColumn';
import UsersColumn from './UsersColumn';
import ActionColumn from './ActionColumn';
import FunctionalLocationsColumn from './FunctionalLocationsColumn';

const Row = styled.tr`
  border-bottom: ${props => props.theme.spacing.border} solid ${props => props.theme.colors.alabaster};
  overflow: hidden;

  &:last-child {
    border-bottom: none;
  }

  ${props =>
    props.hasHover &&
    `
        &:hover {
            cursor: pointer;
            background: ${props.theme.colors.alabaster};

            svg {
                cursor: pointer;
            }
        }

    `}
`;
Row.displayName = 'Row';

const Cell = styled.td`
  position: relative;
  width: ${props => props.columnWidth};
  padding: ${props => !props.noPaddingOnMobile && `${props.theme.spacing.md} 0`};
  display: ${props => props.hideOnMobile && 'none'};
  vertical-align: middle;
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.colors.black};

  &:first-child {
    padding-left: ${props => props.theme.spacing.sm};
  }
  &:last-child {
    padding-right: ${props => props.theme.spacing.sm};
  }

  ${props => props.theme.media.landscape`
        display: ${props => (props.hideOnDesktop ? 'none' : 'table-cell')};
        padding: ${props => props.theme.spacing.sm};
        &:last-child { padding-right: initial; }
    `}
`;
Cell.displayName = 'Cell';

const CustomViewRow = props => {
  const {
    rowData: { customView, onAction },
    t,
  } = props;

  return (
    <Row data-test-id={props['data-test-id']}>
      <Cell>
        <NameColumn data={customView.viewName} />
      </Cell>
      <Cell>
        <TypeColumn data={customView.viewType} />
      </Cell>
      <Cell hideOnMobile>
        <FunctionalLocationsColumn t={t} data={customView.functionalLocations} />
      </Cell>
      <Cell hideOnMobile>
        <UsersColumn t={t} data={customView.users} />
      </Cell>
      <Cell>
        <ActionColumn t={t} customView={customView} onAction={onAction} />
      </Cell>
    </Row>
  );
};
CustomViewRow.displayName = 'CustomViewRow';

CustomViewRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  'data-test-id': PropTypes.string,
};

export default CustomViewRow;
