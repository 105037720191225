import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import queryString from 'query-string';
import get from 'lodash/get';
import map from 'lodash/map';
import includes from 'lodash/includes';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';
import head from 'lodash/head';
import Helmet from 'react-helmet';
import translations from 'decorators/Translations/translations';
import connector from './connectFunctionalLocation';
import PropTypes from 'prop-types';

import { isValidPartner, findPartnerNumberForFL } from 'utils/Data/partners';
import {
  getParentFunctionLocations,
  getHeroContext,
  isBusinessUnit,
  isTenant,
  isSystem,
  getBuildingImage,
} from 'utils/Data/functionalLocations.js';
import { getEnabledTabs, FEATURE_TO_TAB } from 'utils/Data/features';
import {
  getBuildingSensorsFromHierarchy,
  getBuildingFloorsFromHierarchy,
  getBuildingGroupsFromHierarchy,
} from 'utils/Data/sensorHierarchy';

import StandardPage from 'components/StandardPage/StandardPage.jsx';
import BusinessUnit from 'components/FunctionalLocations/BusinessUnit';
import TechnicalSystem from 'components/FunctionalLocations/TechnicalSystem';
import GenericFunctionalLocation from 'components/FunctionalLocations/GenericFunctionalLocation';
import LoadingFunctionalLocation from 'components/FunctionalLocations/LoadingFunctionalLocation';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage.jsx';
import FadeTransition from 'components/FadeTransition/FadeTransition';

const startDate = moment.utc().startOf('month');
const endDate = moment.utc().endOf('month');
const emptyArray = [];
const emptyObject = {};

class FunctionalLocation extends Component {
  state = {
    floors: [],
    sensors: [],
    groups: [],
    disableScrollToTop: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { functionalLocationId },
      },
      location: { search },
      functionalLocations,
    } = this.props;
    if (prevProps.match.params.functionalLocationId !== functionalLocationId) {
      this.loadData();
      return;
    }

    const oldTab = queryString.parse(prevProps.location.search).tab;
    const tab = queryString.parse(search).tab;
    const disableScrollToTop = tab === 'floors' && oldTab === 'floors';
    prevState.disableScrollToTop !== disableScrollToTop && this.setState({ disableScrollToTop });

    const oldPage = parseInt(queryString.parse(prevProps.location.search).page, 10) || 1;
    const page = parseInt(queryString.parse(search).page, 10) || 1;
    const functionalLocation = functionalLocations[functionalLocationId];
    const functionalLocationsLoaded =
      Object.keys(functionalLocations).length > 0 && Object.keys(prevProps.functionalLocations).length === 0;

    if (oldPage !== page || functionalLocationsLoaded) {
      if (tab === 'equipment') {
        this.props.loadEquipmentsModule(functionalLocation);
      }
    }
    if (prevProps.sensorHierarchy !== this.props.sensorHierarchy) {
      const hierarchy = this.props.sensorHierarchy.buildingHierarchy[functionalLocationId];
      let floors = [],
        sensors = [],
        groups = [];
      if (hierarchy && hierarchy.length > 0) {
        floors = getBuildingFloorsFromHierarchy(head(hierarchy));
        sensors = getBuildingSensorsFromHierarchy(head(hierarchy));
        groups = getBuildingGroupsFromHierarchy(head(hierarchy));
      }
      this.setState({
        floors,
        sensors,
        groups,
      });
    }
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    const {
      match: {
        params: { partnerNumber, functionalLocationId },
      },
      location: { search },
      features,
      loadFunctionalLocationContainer,
    } = this.props;
    const realData = queryString.parse(search).realData;

    loadFunctionalLocationContainer(functionalLocationId, startDate, endDate, features, partnerNumber, realData);
  };

  render() {
    const {
      t,
      loading,
      loadingParentFL,
      loadingChildren,
      loadingEquipment,
      functionalLocations,
      permissions,
      equipments,
      subEquipments,
      features,
      match: {
        params: { partnerNumber, functionalLocationId },
      },
      match,
      location: { search },
      functionalLocationDocuments,
      sensorCategories,
      sensorHierarchy,
      functionalLocationImages,
      notice,
      alarm,
      meta,
      slaByFL,
      customViewsByFl,
      disabledCustomViews,
      sensorAlarmsById,
      featureTeasers,
      partnerMeta,
      profile,
    } = this.props;

    const functionalLocation = functionalLocations[functionalLocationId];

    if (!functionalLocation && !loading) {
      return <ErrorPage type="functionalLocation" />;
    }

    // Show error page if partnerNumber does not match with FL partner numbers
    if (
      functionalLocation &&
      partnerNumber !== 'all' &&
      !includes(functionalLocation.partnerNumberWithParents, partnerNumber)
    ) {
      return <ErrorPage type="functionalLocation" />;
    }

    const query = queryString.parse(search);
    const page = parseInt(query.page, 10) || 1;
    const scrollToTopOnMount = query.tab === 'service orders';

    const path = functionalLocation && functionalLocation.path;
    const childFunctionalLocations =
      (path &&
        Object.keys(get(permissions, path) || {})
          .map(child => functionalLocations[child])
          .filter(
            child => !isValidPartner(partnerNumber) || includes(child.partnerNumberWithParents, partnerNumber)
          )) ||
      [];

    // We MUST strictly compare to undefined, since it might still have null value after loading has completed
    // on technical system (discipline) functional location. This is probably a bug in the permissions reducer
    // and should be checked out.
    const loadingFunctionalLocations = !functionalLocation || get(permissions, path) === undefined;

    const totalFunctionalLocations = childFunctionalLocations.length;
    const parents = getParentFunctionLocations(functionalLocations, path, functionalLocationId).reverse();
    const parentImages = omitBy(map(parents, parent => getBuildingImage(functionalLocationImages, parent)), isEmpty);

    // Show documents loading indicator if the  functionalLocationDocuments is null.
    let loadingDocuments = false;
    let documentCount = 0;
    if (functionalLocationDocuments && functionalLocation) {
      if (isArray(functionalLocationDocuments[functionalLocation.functionalLocation])) {
        const files = flatten(
          functionalLocationDocuments[functionalLocation.functionalLocation]
            .filter(d => d.files.length > 0)
            .map(d => d.files)
        );
        documentCount = files.length;
      } else {
        loadingDocuments = true;
      }
    }

    const equipmentCount =
      (functionalLocation &&
        equipments &&
        equipments[functionalLocation.functionalLocation] &&
        equipments[functionalLocation.functionalLocation].length) ||
      0;
    const categories = functionalLocation && get(sensorCategories, [functionalLocation.functionalLocation]);

    const customViews = customViewsByFl[functionalLocationId];
    const enabledCustomViews = filter(customViews || [], customView => !includes(disabledCustomViews, customView.id));

    const defaultTab =
      profile.defaultFeature && FEATURE_TO_TAB[profile.defaultFeature]
        ? profile.defaultFeature === 'technicalTab'
          ? functionalLocation?.type === 'TO'
            ? 'Systems'
            : 'Technical'
          : FEATURE_TO_TAB[profile.defaultFeature]
        : head(getEnabledTabs(features, functionalLocation?.type, customViews));

    const sharedProperties = {
      t,
      loading,
      features,
      functionalLocation,
      childFunctionalLocations,
      equipments,
      subEquipments,
      parents,
      match,
      loadingParent: loadingParentFL,
      loadingChildren,
      loadingEquipment,
      partnerNumber:
        partnerNumber && partnerNumber !== 'all' ? partnerNumber : findPartnerNumberForFL(functionalLocation, profile),
      getHeroContext: getHeroContext,
      totalFunctionalLocations,
      loadingFunctionalLocations,
      documentCount,
      loadingDocuments,
      equipmentCount,
      buildingImage: parentImages && parentImages[0],
      page,
      customViews: enabledCustomViews,
      featureTeasers,
      partnerMeta,
      profile,
      defaultTab,
    };

    const { sensors, floors, groups, disableScrollToTop } = this.state;

    return (
      <FadeTransition>
        <StandardPage withTabs disableScrollToTop={disableScrollToTop} scrollToTopOnMount={scrollToTopOnMount}>
          <Helmet title={functionalLocation && functionalLocation.description} />
          {(() => {
            if (loading) {
              return <LoadingFunctionalLocation {...sharedProperties} />;
            } else if (isSystem(functionalLocation)) {
              return <TechnicalSystem {...sharedProperties} />;
            } else if (isBusinessUnit(functionalLocation) || isTenant(functionalLocation)) {
              const flAnnouncements = this.props.announcements.byFL?.[functionalLocationId] ?? emptyArray;

              return (
                <BusinessUnit
                  {...sharedProperties}
                  floors={floors}
                  groups={groups}
                  buildingSensors={sensors}
                  buildingHierarchy={sensorHierarchy.buildingHierarchy[functionalLocationId] || emptyArray}
                  valuesBySensorId={this.props.valuesBySensorId}
                  latestValuesBySensorId={this.props.latestValuesBySensorId}
                  cleaningValues={this.props.cleaningValues}
                  buildingConditions={this.props.buildingConditions[functionalLocationId]}
                  observations={notice.byFL?.[functionalLocationId]?.observations ?? emptyArray}
                  inspections={notice.byFL?.[functionalLocationId]?.inspections ?? emptyArray}
                  alarms={alarm.byFL?.[functionalLocationId] ?? emptyObject}
                  buildingImage={getBuildingImage(functionalLocationImages, functionalLocation)}
                  functionalLocationImages={functionalLocationImages}
                  announcements={flAnnouncements}
                  sensorHierarchy={sensorHierarchy}
                  meta={meta[functionalLocation.functionalLocation]}
                  sla={slaByFL[functionalLocation.functionalLocation]}
                  sensorAlarmsById={sensorAlarmsById}
                />
              );
            }

            return (
              <GenericFunctionalLocation {...sharedProperties} noIoTData={!categories || categories.length === 0} />
            );
          })()}
        </StandardPage>
      </FadeTransition>
    );
  }
}

FunctionalLocation.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingParentFL: PropTypes.bool.isRequired,
  loadingChildren: PropTypes.bool.isRequired,
  loadingEquipment: PropTypes.bool.isRequired,
  functionalLocations: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  equipments: PropTypes.object.isRequired,
  subEquipments: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  functionalLocationDocuments: PropTypes.object.isRequired,
  sensorCategories: PropTypes.object.isRequired,
  sensorHierarchy: PropTypes.object.isRequired,
  functionalLocationImages: PropTypes.object.isRequired,
  notice: PropTypes.object.isRequired,
  alarm: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  slaByFL: PropTypes.object.isRequired,
  customViewsByFl: PropTypes.object.isRequired,
  disabledCustomViews: PropTypes.array.isRequired,
  sensorAlarmsById: PropTypes.object.isRequired,
  featureTeasers: PropTypes.array.isRequired,
  partnerMeta: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  announcements: PropTypes.object.isRequired,
  valuesBySensorId: PropTypes.object.isRequired,
  latestValuesBySensorId: PropTypes.object.isRequired,
  cleaningValues: PropTypes.array.isRequired,
  buildingConditions: PropTypes.object.isRequired,
  loadFunctionalLocationContainer: PropTypes.func.isRequired,
  loadEquipmentsModule: PropTypes.func.isRequired,
};

export default connector(translations(withRouter(FunctionalLocation)));
