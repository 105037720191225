import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';

const DeviceToken = styled.div`
  text-align: center;
`;
DeviceToken.displayName = 'DeviceToken';

const DeviceTokenLabel = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
`;
DeviceTokenLabel.displayName = 'DeviceTokenLabel';

const InitializeNiagaraStationToken = ({ t, onClose, initializeNiagaraStationToken }) => (
  <DialogModal
    isActive
    onOverlayClick={onClose}
    t={t}
    footer={
      <DialogFooter>
        <Button cancel onClick={onClose}>
          {t('Close')}
        </Button>
      </DialogFooter>
    }
  >
    <DeviceToken>
      <p>
        <DeviceTokenLabel>{`${t('Device token')}: `}</DeviceTokenLabel>
        {initializeNiagaraStationToken}
      </p>
    </DeviceToken>
  </DialogModal>
);

InitializeNiagaraStationToken.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initializeNiagaraStationToken: PropTypes.string.isRequired,
};

export default InitializeNiagaraStationToken;
