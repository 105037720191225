import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputLabel from 'components/Form/InputLabel';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import InputText from 'components/Form/InputText';
import ButtonGroup, { ButtonGroupButton } from 'components/Form/ButtonGroup';
import InfoBox from 'components/InfoBox/InfoBox';
import find from 'lodash/find';

const Container = styled.div`
  max-width: 700px;
`;

const Heading = styled.div`
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.bold};
`;
Heading.displayName = 'Heading';

const Name = styled.span`
  font-weight: ${props => props.theme.fontWeight.text};
`;

const Thresholds = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;
Thresholds.displayName = 'Thresholds';

const RadioButtonTitle = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.note};
  color: ${props => props.theme.colors.black};
  padding: ${props => props.theme.spacing.xs} 0;
`;
RadioButtonTitle.displayName = 'RadioButtonTitle';

const Group = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing.md};
  }
`;
Group.displayName = 'Group';

const Row = styled(Group)`
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.portrait`
        flex-direction: row;
        margin-left: -${props => props.theme.spacing.md};
    `}
`;
Row.displayName = 'Row';

const Column = styled.div`
  width: 100%;

  ${props => props.theme.media.portrait`
        width: 50%;
        margin-left: ${props => props.theme.spacing.md};
    `}
`;
Column.displayName = 'Column';

const DelayContainer = styled.div`
  display: flex;
`;

const DelayInput = styled(InputText)`
  flex: 1;
  margin-right: ${props => props.theme.spacing.md};
`;

const SensorAlarmForm = ({ t, model, onPropertyChange, sensorName, isPresence, delayUnits, invalidDelay }) => {
  const onChangeNumber = (property, value) => {
    const numberValue = parseFloat(value, 10);
    onPropertyChange(property, numberValue);
  };

  const delayUnit = find(delayUnits, unit => unit.unit === model?.alarmDelay?.unit);
  const delayMaxValue = (60 * 60 * 24 * 7) / delayUnit.coefficient;
  return (
    <Container>
      <Group>
        <Heading>
          {`${t('Measuring Point')}: `}
          <Name>{sensorName}</Name>
        </Heading>
      </Group>
      {!isPresence && (
        <Row>
          <Column>
            <InputLabel text={t('Min threshold')} id="minValue" />
            <InputText
              value={model?.minValue}
              property="minValue"
              id="minValue"
              type="number"
              onChange={(property, value) => onChangeNumber(property, value)}
              inputProps={{ step: 0.01 }}
            />
          </Column>
          <Column>
            <InputLabel text={t('Max threshold')} id="maxValue" ctxHelp="ctxhelp sensor alarm config" />
            <InputText
              value={model?.maxValue}
              property="maxValue"
              id="maxValue"
              type="number"
              onChange={(property, value) => onChangeNumber(property, value)}
              inputProps={{ step: 0.01 }}
            />
          </Column>
        </Row>
      )}
      {isPresence && (
        <Group>
          <RadioButtonTitle>{t('Presence alarms')}</RadioButtonTitle>
          <RadioButtonGroup name="occupiedAlarm" value={model?.occupiedAlarm} onChange={onPropertyChange}>
            <RadioButton label={t('When occupied')} value="occupied" />
            <RadioButton label={t('When available')} value="available" />
          </RadioButtonGroup>
        </Group>
      )}
      <Row>
        <Column>
          <RadioButtonTitle>{t('Send Alarms')}</RadioButtonTitle>
          <RadioButtonGroup value={model?.weekdaysOnly} onChange={onPropertyChange} name="weekdaysOnly">
            <RadioButton label={t('During weekdays (Monday to Friday)')} value={true} />
            <RadioButton label={t('Whole week')} value={false} />
          </RadioButtonGroup>
        </Column>
        <Column>
          <RadioButtonTitle>{t('Alarm Delay')}</RadioButtonTitle>
          <DelayContainer>
            <DelayInput
              value={model?.alarmDelay?.value}
              property="alarmDelay.value"
              type="number"
              onChange={onChangeNumber}
              inputProps={{
                min: 0,
                max: delayMaxValue,
              }}
              invalid={invalidDelay}
            />
            <ButtonGroup value={model?.alarmDelay?.unit} onChange={value => onPropertyChange('alarmDelay.unit', value)}>
              {delayUnits.map(unit => (
                <ButtonGroupButton key={unit.unit} value={unit.unit}>
                  {t(unit.label)}
                </ButtonGroupButton>
              ))}
            </ButtonGroup>
          </DelayContainer>
        </Column>
      </Row>
      <InfoBox>
        {`${t('Alarm will be send into')} `}
        <b>{`${t("your profile's email account")} `}</b>
        {t(
          'once when the threshold is being crossed. ' +
            'You will only receive one alarm within the time the sensor is over the threshold.'
        )}
      </InfoBox>
    </Container>
  );
};

SensorAlarmForm.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.shape({
    sensorId: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    weekdaysOnly: PropTypes.bool,
    occupiedAlarm: PropTypes.oneOf(['occupied', 'available']),
    alarmDelay: PropTypes.shape({
      unit: PropTypes.oneOf(['minute', 'hour', 'day']),
      value: PropTypes.number.isRequired,
    }),
  }).isRequired,
  onPropertyChange: PropTypes.func.isRequired,
  sensorName: PropTypes.string.isRequired,
  isPresence: PropTypes.bool,
  delayUnits: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  invalidDelay: PropTypes.bool,
};

SensorAlarmForm.defaultProps = {
  isPresence: false,
};

export default SensorAlarmForm;
