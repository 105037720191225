import React, { Component } from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import forEach from 'lodash/forEach';
import lodashOrderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import toLower from 'lodash/toLower';
import PropTypes from 'prop-types';

import SortableTableCell from './SortableTableCell';
import SortableTableRow from './SortableTableRow';

const TableBody = styled.tbody``;

class SortableTableBody extends Component {
  state = {
    orderedData: [],
  };

  componentDidMount() {
    const { data, order, orderBy, visibleRows, paginate, page } = this.props;

    if (data && data.length > 0) {
      this.setOrderedData(data, orderBy, order, visibleRows, paginate, page);
    }
  }

  componentDidUpdate(prevProps) {
    const { data, order, orderBy, visibleRows, paginate, page } = this.props;

    if (
      data &&
      data.length > 0 &&
      (prevProps.order !== order ||
        prevProps.orderBy !== orderBy ||
        prevProps.visibleRows !== visibleRows ||
        prevProps.page !== page ||
        !isEqual(prevProps.data, data))
    ) {
      this.setOrderedData(data, orderBy, order, visibleRows, paginate, page);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { columns, data, onClick, TableRowComponent, page, order, orderBy, visibleRows } = this.props;

    return (
      !isEqual(columns, nextProps.columns) ||
      !isEqual(order, nextProps.order) ||
      !isEqual(orderBy, nextProps.orderBy) ||
      !isEqual(onClick, nextProps.onClick) ||
      !isEqual(TableRowComponent, nextProps.TableRowComponent) ||
      !isEqual(this.state, nextState) ||
      !isEqual(visibleRows, nextProps.visibleRows) ||
      page !== nextProps.page ||
      !isEqual(data, nextProps.data)
    );
  }

  setOrderedData = (data, orderBy, order, visibleRows, paginate, page) => {
    const orderData = dataToOrder =>
      lodashOrderBy(
        dataToOrder,
        d => {
          if (typeof d[orderBy] === 'object') {
            return d[orderBy] ? d[orderBy].value : '';
          }
          // Convert strings to lowercase for sorting
          if (typeof d[orderBy] === 'string') {
            return toLower(d[orderBy]);
          }
          return d[orderBy];
        },
        order
      );

    const orderDataRecursively = dataToOrder => {
      forEach(dataToOrder, row => {
        if (row.children) {
          row.children = orderDataRecursively(row.children);
        }
      });

      return orderData(dataToOrder);
    };

    // if data has children (=accordion-structure), use recursive function that mutates data when ordering children
    let orderedData = some(data, 'children') ? orderDataRecursively(cloneDeep(data)) : orderData(data);

    if (visibleRows) {
      if (paginate) {
        const start = page === 1 ? 0 : (page - 1) * visibleRows;
        const end = start + visibleRows;
        orderedData = orderedData.slice(start, end);
      } else {
        orderedData = orderedData.slice(0, visibleRows);
      }
    }

    this.setState({
      orderedData,
    });
  };

  render() {
    const { columns, data, onClick, TableRowComponent, cellPadding, stripes, t, keyField } = this.props;

    if (data && data.length > 0) {
      // If data is object, order by 'value' field. Else order by the primitive data.
      const orderedData = this.state.orderedData;
      return (
        <TableBody>
          {orderedData.map((row, idx) => {
            if (TableRowComponent) {
              return (
                <TableRowComponent
                  key={row[keyField] || `tablerow-${idx}`}
                  onClick={onClick}
                  rowData={row}
                  stripes={stripes}
                  t={t}
                  data-test-id="SortableTableRow"
                />
              );
            }
            return (
              <SortableTableRow
                key={row[keyField] || `tablerow-${idx}`}
                onClick={onClick}
                rowData={row}
                stripes={stripes}
                t={t}
                data-test-id="SortableTableRow"
              >
                {columns &&
                  columns.length > 0 &&
                  columns.map(col => {
                    let cellData;
                    // Check if data has preformatted content in content field,
                    // else use value or the primitive data.
                    // Columns are used to select which data will be shown
                    // and for the order of columns.
                    if (row[col.field] && typeof row[col.field] === 'object') {
                      if (React.isValidElement(row[col.field].content)) {
                        cellData = row[col.field].content;
                      } else {
                        cellData = <span>{row[col.field].value}</span>;
                      }
                    } else {
                      cellData = <span>{row[col.field] || ''}</span>;
                    }
                    return (
                      <SortableTableCell
                        key={col.field || col.title}
                        padding={cellPadding}
                        align={col.align}
                        cellWidth={col.width}
                      >
                        {cellData}
                      </SortableTableCell>
                    );
                  })}
              </SortableTableRow>
            );
          })}
        </TableBody>
      );
    }
    return null;
  }
}

SortableTableBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  visibleRows: PropTypes.number,
  onClick: PropTypes.func,
  TableRowComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  cellPadding: PropTypes.string,
  stripes: PropTypes.bool,
  t: PropTypes.func.isRequired,
  paginate: PropTypes.bool.isRequired,
  page: PropTypes.number,
  keyField: PropTypes.string,
};

export default SortableTableBody;
