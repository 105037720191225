import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import SensorValues from 'containers/Application/SensorValues/SensorValues';
import SapEquipment from '../SapEquipment/SapEquipment';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';

const FloorSensorDialog = ({
  buildingMeta,
  sensors,
  functionalLocation,
  partnerNumber,
  features,
  sensorAlarmsById,
  floors,
  t,
  dialogOpen,
  dialogTitle,
  dialogType,
  sensorsIds,
  defaultSensorId,
  category,
  openingHours,
  sensor,
  toggleDialog,
}) => {
  const areas = filter(flatMap(floors, 'children'), { type: 'area' });

  const isSapEquipment = dialogType === 'sap-equipment';
  const isPerformance = dialogType === 'technical_performance';
  const isUtilizationRate = dialogType === 'utilization_rate';
  const isFloorOPISensors = dialogType === 'floor_opi';

  if (!dialogOpen) {
    return null;
  }
  return (
    <Dialog isActive={true} onOverlayClick={toggleDialog()}>
      <DialogFrame onClose={toggleDialog()} smallVerticalPadding>
        {!isSapEquipment && (
          <SensorValues
            sensorsIds={sensorsIds}
            buildingSensors={sensors}
            functionalLocation={functionalLocation}
            defaultSensorId={defaultSensorId}
            isAirQuality={isPerformance}
            isUtilizationRate={isUtilizationRate}
            useDeviceNames={isFloorOPISensors}
            showCombinationGroup={isUtilizationRate}
            buildingMeta={buildingMeta}
            category={category}
            sensorAlarmsById={sensorAlarmsById}
            title={dialogTitle}
            areas={areas}
            openingHours={openingHours}
          />
        )}
        {isSapEquipment && (
          <SapEquipment
            t={t}
            functionalLocation={functionalLocation}
            partnerNumber={partnerNumber}
            features={features}
            sensor={sensor}
          />
        )}
      </DialogFrame>
    </Dialog>
  );
};

FloorSensorDialog.defaultProps = {
  dialogOpen: false,
  dialogType: null,
  dialogTitle: null,
};

FloorSensorDialog.propTypes = {
  buildingMeta: PropTypes.object,
  sensors: PropTypes.array,
  functionalLocation: PropTypes.object,
  partnerNumber: PropTypes.string,
  features: PropTypes.object,
  sensorAlarmsById: PropTypes.object,
  floors: PropTypes.array,
  t: PropTypes.func,
  dialogOpen: PropTypes.bool,
  dialogTitle: PropTypes.string,
  dialogType: PropTypes.string,
  sensorsIds: PropTypes.array,
  defaultSensorId: PropTypes.number,
  category: PropTypes.object,
  openingHours: PropTypes.array,
  sensor: PropTypes.object,
  toggleDialog: PropTypes.func,
};

export default FloorSensorDialog;
