import { createReducerFromMapping } from 'redux/utils/index.js';
import {
  loadPartnerAlarmCounts,
  loadPartnerAlarmActionCounts,
  loadPartnerHandlingTimeByMonth,
  loadPartnerTimeToActionByMonth,
  loadHandlingTimeKPI,
  loadTimeToActionKPI,
  loadBenchmarking,
} from 'redux/modules/iot/alarm';
import { loadPartnerObservations, loadPartnerInspections, loadMonthlyInspections } from 'redux/modules/iot/notice';
import { loadPermissions } from 'redux/modules/profile/profile';
import moment from 'moment-timezone';

import { getWhereFromPermissions } from '../../utils/partnerFilter';

export const LOAD_REMOTE_CENTER = 'CUSTOMER_PLATFORM/RemoteCenterContainer/LOAD_REMOTE_CENTER';
export const LOAD_REMOTE_CENTER_SUCCESS = 'CUSTOMER_PLATFORM/RemoteCenterContainer/LOAD_REMOTE_CENTER_SUCCESS';
export const LOAD_REMOTE_CENTER_FAIL = 'CUSTOMER_PLATFORM/RemoteCenterContainer/LOAD_REMOTE_CENTER_FAIL';

const initialState = {
  loading: true,
  error: false,
};

const getTimes = () => {
  const yearAgo = moment
    .utc()
    .subtract(365, 'days')
    .startOf('day');
  const yearStart = moment.utc().startOf('year');
  const now = moment.utc().endOf('hour');
  const year = moment.utc().format('YYYY');
  return { yearAgo, yearStart, now, year };
};

// Fetch based on partner number if user has partner wide permissions, otherwise specify functional locations
const getBaseWhere = (partnerNumber, profile) =>
  getWhereFromPermissions(profile, partnerNumber, {
    buildPartnerNumberFilter: partnerNumber => ({
      partnerNumber: {
        'any x': {
          x: partnerNumber,
        },
      },
    }),
  });

export const loadAlarmsContainer = partnerNumber => {
  return async (dispatch, getState) => {
    const { profile } = getState().profile;
    const baseWhere = getBaseWhere(partnerNumber, profile);
    const { yearAgo, now, year } = getTimes();

    try {
      dispatch(loadPartnerAlarmCounts(partnerNumber, baseWhere, yearAgo, now));
      dispatch(loadHandlingTimeKPI(partnerNumber));
      dispatch(loadTimeToActionKPI(partnerNumber));
      dispatch(loadBenchmarking(partnerNumber));
      dispatch(loadPartnerAlarmActionCounts(partnerNumber, baseWhere));
      dispatch(loadPermissions());
      dispatch(loadPartnerHandlingTimeByMonth(partnerNumber, baseWhere, year));
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export const loadMoreAlarms = (partnerNumber, year, handlingTime) => {
  return async (dispatch, getState) => {
    const { profile } = getState().profile;
    const baseWhere = getBaseWhere(partnerNumber, profile);

    try {
      handlingTime && dispatch(loadPartnerHandlingTimeByMonth(partnerNumber, baseWhere, `${year}`));
      !handlingTime && dispatch(loadPartnerTimeToActionByMonth(partnerNumber, baseWhere, `${year}`));
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export const loadObservationsContainer = partnerNumber => {
  return async (dispatch, getState) => {
    const { profile } = getState().profile;
    const baseWhere = getBaseWhere(partnerNumber, profile);
    const { yearAgo, now } = getTimes();

    try {
      dispatch(loadPartnerObservations(partnerNumber, baseWhere, yearAgo, now));
      dispatch(loadPermissions());
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export const loadMoreObservations = (partnerNumber, year) => {
  return async (dispatch, getState) => {
    const { profile } = getState().profile;
    const baseWhere = getBaseWhere(partnerNumber, profile);
    const start = moment.utc(year, 'YYYY').startOf('year');
    const end = moment.utc(year, 'YYYY').endOf('year');

    try {
      dispatch(loadPartnerObservations(partnerNumber, baseWhere, start, end));
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export const loadInspectionsContainer = partnerNumber => {
  return async (dispatch, getState) => {
    const { profile } = getState().profile;
    const baseWhere = getBaseWhere(partnerNumber, profile);
    const { yearStart, now } = getTimes();

    try {
      dispatch(loadPartnerInspections(partnerNumber, baseWhere, yearStart, now));
      dispatch(loadMonthlyInspections(partnerNumber, baseWhere, yearStart, now));
      dispatch(loadPermissions());
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export const loadMoreInspections = (partnerNumber, year) => {
  return async (dispatch, getState) => {
    const { profile } = getState().profile;
    const baseWhere = getBaseWhere(partnerNumber, profile);
    const start = moment.utc(year, 'YYYY').startOf('year');
    const end = moment.utc(year, 'YYYY').endOf('year');

    try {
      dispatch(loadMonthlyInspections(partnerNumber, baseWhere, start, end));
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_REMOTE_CENTER]: state => ({
      ...state,
      loading: true,
    }),
    [LOAD_REMOTE_CENTER_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [LOAD_REMOTE_CENTER_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
