export const getPartnerNumber = (_, { match }) => match.params.partnerNumber;

export const getFunctionalLocation = (_, { match }) => match.params.functionalLocationId;

export const getActiveFilter = (_, { activeFilter }) => activeFilter;

export const getMonths = (_, { months }) => months;

export const getDay = (_, { day }) => day;

export const getFunctionalLocations = state => state.functionalLocations.functionalLocations;

export const getLoadingFLs = (_, { loadingFLs }) => loadingFLs;

export const getProfile = state => state.profile.profile;

export const getPartnerMeta = state => state.partnerMeta.meta;
