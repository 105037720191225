import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';
import { withRouter } from 'react-router-dom';
import { includes, find, map } from 'lodash';
import styled from 'styled-components';

import Alarms from 'components/BuildingEvents/Alarms/Alarms';
import Inspections from 'components/BuildingEvents/Inspections/Inspections';
import Observations from 'components/BuildingEvents/Observations/Observations';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';

const Container = styled.div`
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  margin: 0 auto;

  ${props => props.theme.media.landscape`
        margin-top: -2em;
    `}
`;

const ControlRoomModule = props => {
  const {
    t,
    observations,
    inspections,
    alarms,
    sla,
    loading,
    loadingBuilding,
    loadingNotices,
    loadingAlarms,
    match: { path, url },
    location,
    history,
  } = props;

  const isLoading = loading || loadingBuilding || loadingNotices || loadingAlarms;

  const tabOptions = [
    { value: 'Alarms', label: t('Alarms') },
    { value: 'Inspections', label: t('Inspections') },
    { value: 'Observations', label: t('Observations') },
  ];

  const selectedTab =
    find(map(tabOptions, 'value'), tabValue => includes(location.pathname, tabValue)) || tabOptions[0].value;

  return (
    <Container>
      <SectionHeader t={t}>
        <SectionTabSelector
          left
          large
          t={t}
          options={tabOptions}
          model={{ selectedTab }}
          property="selectedTab"
          onTabChange={(property, value) => history.push(`${url}/${value}`)}
        />
      </SectionHeader>
      <Switch>
        <Route exact path={`${path}/Alarms`} render={() => <Alarms t={t} alarms={alarms} loading={isLoading} />} />
        <Route exact path={`${path}`} render={() => <Alarms t={t} alarms={alarms} loading={isLoading} />} />
        <Route
          exact
          path={`${path}/Inspections`}
          render={() => <Inspections t={t} inspections={inspections} loading={isLoading} inspectionSLAs={sla} />}
        />
        <Route
          exact
          path={`${path}/Observations`}
          render={() => <Observations t={t} observations={observations} loading={isLoading} />}
        />
      </Switch>
    </Container>
  );
};

const mapStateToProps = state => ({
  loadingBuilding: state.buildingContainer.loading,
  loadingNotices: state.notice.loading || state.notice.loadingSLA,
  loadingAlarms: state.alarm.loading,
});

const connector = connect(
  mapStateToProps,
  null
);
export default withRouter(connector(ControlRoomModule));
