import React from 'react';
import styled from 'styled-components';

import InputBooleanCheckbox from 'components/Form/InputBooleanCheckbox';

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
`;

const DefaultCheckbox = props => (
  <CheckBoxContainer>
    <InputBooleanCheckbox {...props} />
  </CheckBoxContainer>
);

export default DefaultCheckbox;
