import React, { Fragment } from 'react';
import RecyclingOPICardsSkeleton from 'containers/Application/Modules/RecyclingModule/RecyclingOPICards/RecyclingOPICardsSkeleton';
import WasteBreakdownSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteBreakdownSkeleton';

export const RecyclingModuleSkeleton = () => (
  <Fragment>
    <RecyclingOPICardsSkeleton />
    <WasteBreakdownSkeleton />
  </Fragment>
);

export default RecyclingModuleSkeleton;
