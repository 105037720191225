import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SkeletonText from 'components/Skeletons/SkeletonText';

const Cards = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-right-color: transparent;
  margin-bottom: ${props => props.theme.spacing.xl};

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  ${props => props.theme.media.portrait`
        overflow-x: initial;
        border-right-color: ${props => props.theme.colors.lightGray};
    `}
`;
Cards.displayName = 'Cards';

const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.md};
  border-right: 1px solid ${props => props.theme.colors.lightGray};

  ${props => props.theme.media.landscape`
        &:last-child {
            border-right-color: transparent;
        }
    `}
`;
Card.displayName = 'Card';

const Value = styled.span`
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.color || props.theme.colors.black}
    ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.font.size.sm};
    `};
`;
Value.displayName = 'Value';

const LabelWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-bottom: ${props => props.theme.spacing.xs};

  ${props => props.theme.media.landscape`
        align-items: flex-end;
    `}
`;
LabelWrapper.displayName = 'LabelWrapper';

const Label = styled.span`
  color: ${props => (props.invalid ? props.theme.colors.radicalRed : props.theme.colors.darkGray)};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  font-family: ${props => props.theme.font.arial};
  letter-spacing: 0.3px;
  white-space: pre-line;
  text-align: center;
`;
Label.displayName = 'Label';

const ValueSkeleton = styled(SkeletonText)`
  width: 80px;
  margin: 0;
`;
ValueSkeleton.displayName = 'ValueSkeleton';

const LabelSkeleton = styled(SkeletonText)`
  max-width: 170px;
  margin-bottom: ${props => props.theme.spacing.xs};
`;
LabelSkeleton.displayName = 'LabelSkeleton';

const KeyCards = ({ items }) => {
  return (
    <Cards>
      {items.map(({ value, color, label, loading, contextualHelp, hide }) => {
        if (hide) {
          return null;
        }
        return (
          <Card key={label}>
            {loading ? (
              <Fragment>
                <LabelSkeleton />
                <ValueSkeleton />
              </Fragment>
            ) : (
              <Fragment>
                {label && (
                  <LabelWrapper>
                    <Label hasContextualHelp={contextualHelp}>{label}</Label>
                    {contextualHelp}
                  </LabelWrapper>
                )}
                <Value color={color}>{value}</Value>
              </Fragment>
            )}
          </Card>
        );
      })}
    </Cards>
  );
};

KeyCards.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      color: PropTypes.string,
      label: PropTypes.string,
      loading: PropTypes.bool,
      contextualHelp: PropTypes.node,
    })
  ),
};

export default KeyCards;
