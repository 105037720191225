import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { withTheme } from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { getResponseSLAs } from 'utils/Data/serviceOrders';
import { DEFAULT_PRIORITY, BreakdownFields } from 'constants/serviceCalendar';
import { priorityColor } from 'containers/Application/ServiceOrderStatistics/charts/OrderBreakdown/PrioritySelector/utils';
import KeyCards from 'components/OPICard/KeyCards';
import ContextualHelp, { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { hideResponseTimes, formatValue } from 'containers/Application/ServiceCalendar/utils';

export const createContextualHelp = (title, t) => (
  <ContextualHelp t={t} title={t(title)} text={`${CTXHELP_PREFIX} ${title}`} position="bottom" />
);

const getValue = value => (isNil(value) ? '-' : value / 60 / 60); // convert from seconds to hours

export const ServiceOrderKeys = ({
  t,
  order,
  isMaintenance,
  operations,
  loadingOrder,
  partnerMeta,
  documentCount,
  partnerNumber,
  theme,
  match,
}) => {
  const loading = loadingOrder || !operations || operations.loading;
  const memoizedPartnerMeta = useMemo(() => ({ [partnerNumber]: partnerMeta }), [partnerMeta, partnerNumber]);
  const priority = order.priority || DEFAULT_PRIORITY;
  const slas = getResponseSLAs(memoizedPartnerMeta, partnerNumber) || { downtime: {}, reactionTime: {}, leadTime: {} };

  const downtimeValue = formatValue(getValue(order[BreakdownFields.DOWNTIME]));
  const downtimeColor = priorityColor(getValue(order[BreakdownFields.DOWNTIME]), theme, false, slas.downtime[priority]);

  const reactionTimeValue = formatValue(getValue(order[BreakdownFields.REACTION_TIME]));
  const reactionTimeColor = priorityColor(
    getValue(order[BreakdownFields.REACTION_TIME]),
    theme,
    false,
    slas.reactionTime[priority]
  );

  const leadTimeValue = formatValue(getValue(order[BreakdownFields.LEAD_TIME]));
  const leadTimeColor = priorityColor(
    getValue(order[BreakdownFields.LEAD_TIME]),
    theme,
    false,
    slas.leadTime[priority]
  );

  const hideResponse = partnerMeta && hideResponseTimes(partnerMeta.meta);

  const items = [
    {
      label: t('Status'),
      value: (order.detailedStatus && t(order.detailedStatus)) || '-',
      loading,
    },
  ];

  if (order.maintenancePlanNumber) {
    const uri = `${match.url}/Search?search=${order.maintenancePlanNumber}`;
    items.push({
      label: t('Maintenance Plan Number'),
      value: <Link to={uri}>{order.maintenancePlanNumber}</Link>,
      loading,
    });
  }

  items.push({
    label: t('Operation count'),
    value: (operations && operations.items && operations.items.length) || 0,
    loading,
  });

  if (!isMaintenance) {
    if (order[BreakdownFields.REACTION_TIME]) {
      items.push({
        label: t('Reaction Time'),
        value: reactionTimeValue,
        color: reactionTimeColor,
        loading,
        contextualHelp: createContextualHelp('Reaction Time', t),
        hide: hideResponse,
      });
    }
    if (order[BreakdownFields.LEAD_TIME]) {
      items.push({
        label: t('Lead Time'),
        value: leadTimeValue,
        color: leadTimeColor,
        loading,
        contextualHelp: createContextualHelp('Lead Time', t),
        hide: hideResponse,
      });
    }
    if (order[BreakdownFields.DOWNTIME]) {
      items.push({
        label: t('Downtime'),
        value: downtimeValue,
        color: downtimeColor,
        loading,
        contextualHelp: createContextualHelp('Downtime', t),
        hide: hideResponse,
      });
    }
  }

  items.push(
    {
      label: t('Priority'),
      value: priority,
      loading,
    },
    {
      label: t('Documents'),
      value: documentCount || '0',
      loading,
    }
  );

  return <KeyCards items={items} />;
};

ServiceOrderKeys.propTypes = {
  t: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  isMaintenance: PropTypes.bool,
  operations: PropTypes.shape({
    loading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.object,
  }),
  loadingOrder: PropTypes.bool,
  partnerMeta: PropTypes.object,
  documentCount: PropTypes.number,
  match: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  partnerNumber: PropTypes.string.isRequired,
};

export default withTheme(withRouter(ServiceOrderKeys));
