import React from 'react';
import PropTypes from 'prop-types';
import DialogFooter from 'components/Dialog/DialogFooter';
import Button from 'components/Button/Button';

const QuotaModelFooter = ({ t, close, disabled }) => {
  return (
    <DialogFooter>
      <Button cancel onClick={close}>
        {t('Cancel')}
      </Button>
      <Button submit type="submit" disabled={disabled}>
        {t('Save')}
      </Button>
    </DialogFooter>
  );
};

QuotaModelFooter.propTypes = {
  t: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default QuotaModelFooter;
