import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import memoizeOne from 'memoize-one';

import { InputRow, InputBooleanCheckbox, InputSelectDropdown, InputLabel } from 'components/index.js';
import Section from 'components/Section/Section';
import ContextualHelp, { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import MultiCheck from 'components/Form/MultiCheck';
import { performanceSourceLabels } from 'constants/performance';

const Container = styled.div`
  width: 100%; /* For IE11 */
  display: flex;
  flex-wrap: wrap;
`;
Container.displayName = 'Container';

const Title = styled.h2`
  padding: ${props => props.theme.spacing.md} 0;
  font-size: ${props => props.theme.font.size.lg};
`;
Title.displayName = 'Title';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FeatureDescription = styled.div`
  display: flex;
  font-size: ${props => props.theme.font.size.xs};
  padding-left: 1px;
  white-space: pre-wrap;
  line-height: 1.5em;
  margin-top: ${props => !props.noMargin && props.theme.spacing.xs};
  color: ${props => props.theme.colors.darkGray};
  font-family: ${props => props.theme.font.family.arial};
  overflow: hidden;
  font-weight: ${props => props.theme.font.weight.normal};
`;
FeatureDescription.displayName = 'FeatureDescription';

const MetadataItem = styled.div`
  display: flex;
  font-size: ${props => props.theme.font.size.xxs};
`;
MetadataItem.displayName = 'MetadataItem';

const MetadataTitle = styled.p`
  text-transform: uppercase;
  font-weight: ${props => props.theme.font.weight.bold};
`;
MetadataTitle.displayName = 'MetadataTitle';

const MetadataValue = styled.p`
  color: ${props => props.theme.colors.abbey};
  font-weight: ${props => props.theme.font.weight.bold};
  text-transform: uppercase;
`;
MetadataValue.displayName = 'MetadataValue';

const StyledCheckboxInputRow = styled(({ wrap, ...props }) => <InputRow {...props} />)`
  white-space: ${props => !props.wrap && 'nowrap'};
  margin-right: 2.5% !important;
  margin-left: 0 !important;
  min-width: 200px;
  width: 100%;
  flex: none;

  :first-child {
    margin-top: 20px;
  }

  ${props => props.theme.media.portrait`
        flex: 0 0 47%;
    `}

  ${props => props.theme.media.landscape`
        flex: 0 0 31%;

        &:nth-child(3n) {
            margin-right: 0 !important;
        }
    `}
`;

const DefaultFeatureLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.font.size.xs};
  margin-bottom: ${props => props.theme.spacing.md};
  color: ${props => props.theme.input.font.color.default};
`;
DefaultFeatureLabel.displayName = 'DefaultFeatureLabel';

const Divider = styled(() => <hr />)`
  border-top-color: ${props => props.theme.colors.mystic};
  border-top-width: 1px;
  width: 60%;
  margin: 0;
  padding: 0;
`;

const getGeneralFeatures = (t, features) => [
  {
    name: 'sr',
    label: t('New Service Request'),
    info: t('Create new service requests with detailed information about the problem and location.'),
    noTeaser: true,
  },
  {
    name: 'serviceOrders',
    label: t('Service Calendar'),
    info: t('View service orders and planned maintenance from building level to single equipment.'),
    value: features && (features.serviceOrders || features.serviceOrders === undefined),
    noTeaser: true,
  },
  {
    name: 'controlRoomTab',
    label: t('Control Room'),
    info: t('The Control Room consists of three tabs: Alarms, Observations and Inspections.'),
  },
  {
    name: 'recycling',
    label: t('Recycling'),
    info: t('View recycling, recovery and carbon footprint rates'),
  },
  {
    name: 'api',
    label: t('API'),
    info: t('The API enables programmatic access to data stored on Caverion SmartView.'),
    noTeaser: true,
  },
];

const getPortfolioFeatures = t => [
  {
    name: 'energyKpi',
    label: t('Energy Optimization'),
    info: t(
      "Information about total energy consumption in user's portfolio. " +
        'Compare energy efficiency between buildings.'
    ),
  },
  {
    name: 'portfolioDocuments',
    label: t('Documents'),
    info: t('Upload and download portfolio level documents.'),
  },
];

const getBuildingFeatures = (t, features) => [
  {
    name: 'conditions',
    label: t('Conditions'),
    info: t(
      'Conditions shows a complete performance list of sensor and device groups attached to building or equipment.'
    ),
  },
  {
    name: 'energyTab',
    label: t('Energy'),
    info: t(
      'Building energy view gives detailed information about energy consumption and efficiency in ' +
        'selected building.'
    ),
  },
  {
    name: 'buildingAutomationTab',
    label: t('Building'),
    info: t('Overview of operational performance of the building.'),
  },
  {
    name: 'floorsTab',
    label: t('Floors'),
    info: t('View sensor values per floor, area or room on top of a floor plan image.'),
  },
  {
    name: 'cleaningTab',
    label: t('Cleaning'),
    info: t('The Cleaning view shows when a building, floor or area has been cleaned during the last 7 days.'),
  },
  {
    name: 'technicalTab',
    label: t('Technical'),
    info: t('View all Locations that are directly under the current Location in the Location tree.'),
    value: features && (features.technicalTab || features.technicalTab === undefined),
  },
  {
    name: 'equipment',
    label: t('Equipment'),
    info: t('View all Equipment attached to the current Location, Discipline or Equipment.'),
    value: features && (features.equipment || features.equipment === undefined),
  },
  {
    name: 'documents',
    label: t('External Documents'),
    info: t('The External Documents view lists all M-files documents attached to the current Location.'),
  },
  {
    name: 'files',
    label: t('Documents'),
    info: t('The Documents view lists all documents uploaded to the current building or tenant.'),
  },
  {
    name: 'announcementsTab',
    label: t('News'),
    info: t('View and create news related to the location.'),
  },
];

const getKpiFeatures = (t, features) => [
  {
    value: 'serviceOrders',
    label: t('Service Orders'),
    enabled: features && (features.serviceOrders || features.serviceOrders === undefined),
  },
  {
    value: 'plannedMaintenance',
    label: t('Planned Maintenance'),
    enabled: features && (features.serviceOrders || features.serviceOrders === undefined),
  },
  {
    value: 'energy',
    label: t('Energy Rating'),
    enabled: features && features.energyKpi,
  },
  {
    value: 'alarms',
    label: t('Alarms Performance'),
    enabled: features && features.controlRoomTab,
  },
  {
    value: 'alarmHandlingTime',
    label: t('Alarm Handling Time'),
    enabled: features && features.controlRoomTab,
  },
  {
    value: 'alarmTimeToAction',
    label: t('Alarm Time to Action'),
    enabled: features && features.controlRoomTab,
  },
  {
    value: 'observations',
    label: t('Observations'),
    enabled: features && features.controlRoomTab,
  },
  {
    value: 'inspections',
    label: t('Inspections'),
    enabled: features && features.controlRoomTab,
  },
  {
    value: 'energyConsumption',
    label: t('Energy consumption'),
    enabled: features && features.energyKpi,
  },
  {
    value: 'electricity',
    label: t('Electricity'),
    enabled: features && features.energyKpi,
  },
  {
    value: 'districtHeating',
    label: t('District heating'),
    enabled: features && features.energyKpi,
  },
  {
    value: 'water',
    label: t('Water'),
    enabled: features && features.energyKpi,
  },
  {
    value: 'indoorAirQuality',
    label: t('Indoor Air Quality'),
    enabled: features && features.conditions,
  },
];

const getBuildingPerformanceSourceOptions = memoizeOne((t, features) => {
  const options = [];
  if (!features) {
    return options;
  }

  features.controlRoomTab &&
    options.push({
      value: 'alarms',
      label: t(performanceSourceLabels.alarms),
    });

  features.controlRoomTab &&
    options.push({
      value: 'observations',
      label: t(performanceSourceLabels.observations),
    });

  features.conditions &&
    options.push({
      value: 'indoorAirQuality',
      label: t(performanceSourceLabels.indoorAirQuality),
    });

  features.energyKpi &&
    options.push({
      value: 'energy',
      label: t(performanceSourceLabels.energy),
    });

  features.energyKpi &&
    options.push({
      value: 'energyConsumption',
      label: t(performanceSourceLabels.energyConsumption),
    });

  return options;
});

const Features = ({
  t,
  model,
  isCaverionAdminOrUser,
  model: { features, featureTeasers = [], kpis = [], buildingPerformanceSource } = {},
  onChange,
}) => {
  const sharedProps = { model, onChange };

  const generalFeatures = getGeneralFeatures(t, features);
  const portfolioFeatures = getPortfolioFeatures(t);
  const buildingFeatures = getBuildingFeatures(t, features);
  const generalTeasers =
    (features &&
      _.filter(generalFeatures, feature => !feature.noTeaser && !features[feature.name]).map(teaser => ({
        label: teaser.label,
        value: teaser.name,
      }))) ||
    [];
  const portfolioTeasers =
    (features &&
      _.filter(portfolioFeatures, feature => !feature.noTeaser && !features[feature.name]).map(teaser => ({
        label: teaser.label,
        value: teaser.name,
      }))) ||
    [];
  const buildingTeasers =
    (features &&
      _.filter(buildingFeatures, feature => !feature.noTeaser && !feature.value && !features[feature.name]).map(
        teaser => ({ label: teaser.label, value: teaser.name })
      )) ||
    [];
  const hasTeasers = !_.isEmpty(generalTeasers) || !_.isEmpty(portfolioTeasers) || !_.isEmpty(buildingTeasers);

  const generalTabOptions = [];
  features &&
    features.serviceOrders &&
    generalTabOptions.push({ label: t('Service Calendar'), value: 'serviceOrders' });
  features && features.controlRoomTab && generalTabOptions.push({ label: t('Control Room'), value: 'controlRoomTab' });

  const defaultFeatureOptions = generalTabOptions.concat(
    _.filter(buildingFeatures, feature => features && features[feature.name]).map(feature => ({
      label: feature.label,
      value: feature.name,
    }))
  );

  const kpiOptions = _.filter(getKpiFeatures(t, features), 'enabled');

  const toggleFeatureTeaser = (name, enabled) => {
    const newFeatureTeasers = enabled ? _.concat(featureTeasers, name) : _.without(featureTeasers, name);
    onChange('featureTeasers', newFeatureTeasers);
  };

  const toggleKPI = (name, enabled) => {
    const newKPIs = enabled ? _.concat(kpis, name) : _.without(kpis, name);
    onChange('kpis', newKPIs);
  };
  const toggleFeature = (property, value) => {
    // When disabling a feature, remove also related KPIs and selected performance source
    if (!value) {
      const newFeatures = { ...features, [property[1]]: false };
      const kpiOptions = _.filter(getKpiFeatures(t, newFeatures), 'enabled');
      const perfOptions = getBuildingPerformanceSourceOptions(t, newFeatures);
      const newKPIs = _.filter(kpis, kpi => _.find(kpiOptions, { value: kpi }));
      onChange('kpis', newKPIs);

      if (!_.find(perfOptions, option => option.value === buildingPerformanceSource)) {
        onChange('buildingPerformanceSource', null);
      }
    }
    onChange(property, value);
  };

  return (
    <Fragment>
      <Section>
        <Title>{t('General Features')}</Title>
        <Container>
          {generalFeatures.map(feature => (
            <StyledCheckboxInputRow key={feature.name}>
              <InputBooleanCheckbox
                {...sharedProps}
                id={feature.name}
                property={['features', feature.name]}
                disabled={!isCaverionAdminOrUser}
                label={feature.label}
                onChange={(property, value) => toggleFeature(property, value)}
              />
              <FeatureDescription>{feature.info}</FeatureDescription>
            </StyledCheckboxInputRow>
          ))}
        </Container>
      </Section>
      <Section>
        <Title>{t('Portfolio Features')}</Title>
        <Container>
          {portfolioFeatures.map(feature => (
            <StyledCheckboxInputRow key={feature.name}>
              <InputBooleanCheckbox
                {...sharedProps}
                id={feature.name}
                property={['features', feature.name]}
                disabled={!isCaverionAdminOrUser}
                label={feature.label}
                onChange={(property, value) => toggleFeature(property, value)}
              />
              <FeatureDescription>{feature.info}</FeatureDescription>
            </StyledCheckboxInputRow>
          ))}
          <InputRow fullRow>
            <Divider />
          </InputRow>
          <InputRow fullRow>
            <DefaultFeatureLabel>
              {t('Key Performance Indicators')}
              <ContextualHelp
                t={t}
                title={t('Key Performance Indicators')}
                text={`${CTXHELP_PREFIX} Select KPIs`}
                position="top"
              />
            </DefaultFeatureLabel>
            <MultiCheck options={kpiOptions} value={kpis} onChange={(name, value) => toggleKPI(name, value)} />
          </InputRow>
          <StyledCheckboxInputRow>
            <DefaultFeatureLabel>
              {t('Building Performance Source')}
              <ContextualHelp
                t={t}
                title={t('Building Performance Source')}
                text={`${CTXHELP_PREFIX} Building Performance Source`}
                position="top"
              />
            </DefaultFeatureLabel>
            <InputSelectDropdown
              {...sharedProps}
              property="buildingPerformanceSource"
              options={getBuildingPerformanceSourceOptions(t, features)}
              t={t}
              clearable
            />
          </StyledCheckboxInputRow>
        </Container>
      </Section>
      <Section>
        <Title>{t('Building Features')}</Title>
        <Container>
          {buildingFeatures.map(feature => (
            <StyledCheckboxInputRow key={feature.name}>
              <InputBooleanCheckbox
                {...sharedProps}
                id={feature.name}
                property={['features', feature.name]}
                disabled={!isCaverionAdminOrUser}
                label={feature.label}
                value={feature.value}
                onChange={(property, value) => toggleFeature(property, value)}
              />
              <FeatureDescription>{feature.info}</FeatureDescription>
            </StyledCheckboxInputRow>
          ))}
          <InputRow fullRow>
            <Divider />
          </InputRow>
          <StyledCheckboxInputRow>
            <DefaultFeatureLabel>
              {t('Select Default Feature')}
              <ContextualHelp
                t={t}
                title={t('Default Feature')}
                text={`${CTXHELP_PREFIX} Default Feature`}
                position="top"
              />
            </DefaultFeatureLabel>
            <InputSelectDropdown
              {...sharedProps}
              property="defaultFeature"
              options={defaultFeatureOptions}
              t={t}
              clearable
            />
          </StyledCheckboxInputRow>
        </Container>
      </Section>
      {isCaverionAdminOrUser && hasTeasers && (
        <Section>
          <TitleContainer>
            <Title>{t('Feature Teasers')}</Title>
            <ContextualHelp
              t={t}
              title={t('Feature Teasers')}
              text={`${CTXHELP_PREFIX} Feature Teasers`}
              standalone
              position="right"
            />
          </TitleContainer>
          {!_.isEmpty(generalTeasers) && (
            <InputRow fullRow>
              <InputLabel text={t('General Features')} />
              <MultiCheck
                options={generalTeasers}
                value={featureTeasers}
                onChange={(name, value) => toggleFeatureTeaser(name, value)}
              />
            </InputRow>
          )}
          {!_.isEmpty(portfolioTeasers) && (
            <InputRow fullRow>
              <InputLabel text={t('Portfolio Features')} />
              <MultiCheck
                options={portfolioTeasers}
                value={featureTeasers}
                onChange={(name, value) => toggleFeatureTeaser(name, value)}
              />
            </InputRow>
          )}
          {!_.isEmpty(buildingTeasers) && (
            <InputRow fullRow>
              <InputLabel text={t('Building Features')} />
              <MultiCheck
                options={buildingTeasers}
                value={featureTeasers}
                onChange={(name, value) => toggleFeatureTeaser(name, value)}
              />
            </InputRow>
          )}
        </Section>
      )}
    </Fragment>
  );
};

Features.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.shape({
    features: PropTypes.object,
    disabledCustomViews: PropTypes.array,
  }),
  isCaverionAdminOrUser: PropTypes.bool.isRequired,
};

export default Features;
