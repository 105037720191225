import React, { Fragment, useState } from 'react';
import { countBy, filter, isEmpty, capitalize } from 'lodash';
import moment from 'moment-timezone';

import InspectionsTable from 'components/BuildingEvents/Inspections/InspectionsTable';
import FilterPill from 'components/FilterPills/FilterPill';
import FilterPills from 'components/FilterPills/FilterPills';
import Section from 'components/Section/Section';
import OPICard from 'components/OPICard/OPICard';
import OPICards from 'components/OPICard/OPICards';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import {
  FilterPillsContainer,
  overrideColumnWidth,
  NoDataAvailable,
  SkeletonFilter,
  ShowContainer,
} from 'components/BuildingEvents/BuildingEvents';

const Inspections = props => {
  const { t, inspections, loading, inspectionSLAs } = props;

  const [inspectionFilter, setInspectionFilter] = useState({ all: true });
  const [showPlannedInspections, setShowPlannedInspections] = useState(false);

  const toggleFilter = (filter, value) => {
    if (filter === 'all') {
      setInspectionFilter({ all: value });
      return;
    }

    const newValues = Object.assign({}, inspectionFilter, { [filter]: value, all: false });
    setInspectionFilter(newValues);
  };

  // Get inspections for ongoing year, exclude future inspections (=planned)
  const now = moment.utc();
  const yearStart = moment().startOf('year');
  const currentYearInspections = filter(
    inspections,
    inspection =>
      inspection.date.date && inspection.date.date.isSameOrAfter(yearStart) && inspection.date.date.isSameOrBefore(now)
  );
  let filteredInspections = inspectionFilter.all
    ? inspections
    : filter(inspections, inspection => inspectionFilter[inspection.type]);
  const workOrderInspections = filter(
    filteredInspections,
    inspection => inspection.date.date && inspection.date.date.isSameOrBefore(now)
  );
  const plannedInspections = filter(
    filteredInspections,
    inspection => !inspection.date.date || inspection.date.date.isSameOrAfter(now)
  );
  filteredInspections = showPlannedInspections ? plannedInspections : workOrderInspections;
  const inspectionCounts = countBy(currentYearInspections, 'type');

  const categories = [];
  const filters = ['extended', 'nightly', 'limited', 'adhoc'];

  filters.forEach(type => {
    // Inspections to date
    const inspectionActual = inspectionCounts[type] || 0;

    // Inspections SLA target
    const SLATarget = (inspectionSLAs && inspectionSLAs[`${type}SlaTarget`]) || 0;

    // Completion percentage to date
    const value = (p => (isNaN(p) ? 0 : p > 1 ? 100 : Math.round(p * 100)))(inspectionActual / SLATarget);
    const valueInside = !SLATarget && !value && 'N/a';

    // Add inspections that have value or target
    if (value || SLATarget) {
      categories.push({
        title: t(`${capitalize(type)} Monitoring`),
        value,
        inspectionActual,
        SLATarget,
        valueInside,
        type,
      });
    }
  });

  const activeCategories = inspectionFilter.all
    ? categories
    : filter(categories, category => inspectionFilter[category.type]);
  const showInspectionOpis = !isEmpty(activeCategories);
  const inspectionFilters = [{ title: t('Show All'), type: 'all' }].concat(
    categories.map(category => ({ title: category.title, type: category.type }))
  );

  return (
    <Fragment>
      {(!isEmpty(inspections) || loading) && (
        <FilterPillsContainer>
          {loading ? (
            <FilterPills>
              <SkeletonFilter />
              <SkeletonFilter />
              <SkeletonFilter />
            </FilterPills>
          ) : (
            <FilterPills>
              {inspectionFilters.map(filter => (
                <FilterPill
                  key={filter.type}
                  selected={inspectionFilter[filter.type]}
                  onClick={value => toggleFilter(filter.type, value, false)}
                >
                  {filter.title}
                </FilterPill>
              ))}
            </FilterPills>
          )}
        </FilterPillsContainer>
      )}
      {showInspectionOpis && (
        <OPICards>
          {activeCategories.map(category => (
            <OPICard
              key={category.title}
              t={t}
              loading={loading}
              title={category.title}
              value={category.valueInside || category.value}
              subtitle={`${category.inspectionActual}\u00A0${t('Inspections')}\u00A0/\u00A0${
                category.SLATarget
              }\u00A0${t('SLA')}\n(${moment().format('YYYY')})`}
              ctxHelp={`${CTXHELP_PREFIX} ${category.title} OPI`}
              neutral
              overrideColumnWidth={overrideColumnWidth}
            />
          ))}
        </OPICards>
      )}
      <Section>
        <ShowContainer>
          <RadioButtonGroup
            name="inspectionType"
            value={showPlannedInspections ? 'planned' : 'work_orders'}
            onChange={(property, value) => setShowPlannedInspections(value === 'planned')}
            row
          >
            <RadioButton value="work_orders" label={t('Work Orders')} />
            <RadioButton value="planned" label={t('Planned')} />
          </RadioButtonGroup>
        </ShowContainer>
        {isEmpty(workOrderInspections) && !loading && !showPlannedInspections && (
          <NoDataAvailable>{t('No inspections found for the last 365 days.')}</NoDataAvailable>
        )}
        {isEmpty(plannedInspections) && !loading && showPlannedInspections && (
          <NoDataAvailable>{t('No future inspections found.')}</NoDataAvailable>
        )}
        {(loading ||
          (!isEmpty(workOrderInspections) && !showPlannedInspections) ||
          (!isEmpty(plannedInspections) && showPlannedInspections)) && (
          <InspectionsTable t={t} loading={loading} data={filteredInspections} planned={showPlannedInspections} />
        )}
      </Section>
    </Fragment>
  );
};

export default Inspections;
