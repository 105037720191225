import React, { Fragment } from 'react';
import styled from 'styled-components';

import ContextualHelp from 'components/ContextualHelp/ContextualHelp';

const EventSummaryTitle = styled.div`
  font-family: ${props => props.theme.font.family.sanchez};
  font-size: ${props => props.theme.fontSize.md};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: ${props => props.theme.spacing.md};
  text-align: center;
`;

const EventTitle = styled.div`
  font-size: ${props => props.theme.fontSize.xxs};
  color: ${props => props.theme.colors.darkGray};
`;

const EventValue = styled.div`
  font-size: ${props => props.theme.fontSize.sm};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-left: ${props => props.theme.spacing.sm};
`;

const BreakdownList = styled.ul`
  font-family: ${props => props.theme.font.family.sanchez};
`;

const StyledBreakdownListItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.xxs};
`;

const BreakdownListItem = props => {
  return (
    <StyledBreakdownListItem>
      <EventTitle>{props.heading}</EventTitle>
      <EventValue>{props.value}</EventValue>
    </StyledBreakdownListItem>
  );
};

const EventSummary = props => {
  const { breakdownCtxHelp, ctxHelpOverride, breakdownItems, breakdownHeading, t } = props;
  const hasBreakdownItems = breakdownItems && breakdownItems.length > 0;

  return (
    <Fragment>
      {hasBreakdownItems && (
        <Fragment>
          <EventSummaryTitle>
            {breakdownHeading}{' '}
            {breakdownCtxHelp && (
              <ContextualHelp t={t} title={breakdownHeading} text={breakdownCtxHelp} override={ctxHelpOverride} />
            )}
          </EventSummaryTitle>
          <BreakdownList>
            {breakdownItems.map((row, idx) => (
              <BreakdownListItem heading={t(row.title)} value={row.value} key={'breakdown' + idx} />
            ))}
          </BreakdownList>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EventSummary;
