import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { keys } from 'lodash';

import StatisticsBarChart from 'components/Charts/StatisticsBarChart';
import StatisticsHelp from 'containers/Application/ServiceOrderStatistics/StatisticsHelp/StatisticsHelp';
import StatisticsChartContainer from 'containers/Application/ServiceOrderStatistics/StatisticsChartContainer/StatisticsChartContainer';
import PrioritySelector from '../OrderBreakdown/PrioritySelector/PrioritySelector';

import { GroupedServiceOrders, PartnerMeta, StatisticsType } from 'containers/Application/ServiceOrderStatistics/types';
import * as utils from 'containers/Application/ServiceOrderStatistics/utils';
import { chartStatistics, slas, helpTexts } from '../OrderBreakdown/data';
import { OrderType } from 'constants/serviceCalendar';

const OrderBreakdownPercent = ({ t, theme, yearControls, serviceOrders, breakdownSLAs, statisticsType }) => {
  const [selectedPriority, setSelectedPriority] = useState('all');

  const orders = serviceOrders[OrderType.ORDER];
  const statistics = chartStatistics(orders, breakdownSLAs, statisticsType);

  if (!statistics || statistics.all.slaSampleSize === 0) {
    return <StatisticsChartContainer empty controls={yearControls} t={t} />;
  }

  const priorities = keys(statistics);
  const data = statistics[selectedPriority].monthlySlaPercents || [];
  const monthlySlaSampleSize = statistics[selectedPriority].monthlySlaSampleSize || [];
  const series = utils.getResponseSlaSeries(data, monthlySlaSampleSize, t);
  const slaData = slas(breakdownSLAs, statisticsType);
  const statsHelp = helpTexts(statisticsType, t);

  return (
    <React.Fragment>
      <PrioritySelector
        isPercent
        selectedPriority={selectedPriority}
        setSelectedPriority={setSelectedPriority}
        priorities={priorities}
        statistics={statistics}
        t={t}
        slas={slaData}
      />
      <StatisticsChartContainer
        count={statistics[selectedPriority].sampleSize || 0}
        countLabel={t('Service Orders')}
        controls={yearControls}
      >
        <StatisticsBarChart
          t={t}
          type="column"
          height={utils.CHART_HEIGHT}
          hideLegend={false}
          categories={utils.getMonths(t)}
          noZoom
          plotBorderWidth="0"
          legendAlign="left"
          labelStyle={utils.getLabelStyle(theme)}
          series={series}
          colors={utils.getReactionTimeStackedColors(theme)}
          yTitle={t('SLA realization percentage')}
          valueDecimals={0}
          backgroundColor="transparent"
          unit="%"
          stacked
          yMax={100}
        />
      </StatisticsChartContainer>
      <StatisticsHelp heading={statsHelp.heading} text={statsHelp.text} />
    </React.Fragment>
  );
};

OrderBreakdownPercent.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  yearControls: PropTypes.node.isRequired,
  breakdownSLAs: PropTypes.object.isRequired,
  serviceOrders: GroupedServiceOrders.isRequired,
  statisticsType: StatisticsType,
};

export default withTheme(OrderBreakdownPercent);
