import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import Svg from 'components/Svg/Svg';

const StyledStatisticsButton = styled(Button)`
  margin: 0 ${props => props.theme.spacing.xxs};
  width: auto;
  min-width: auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: ${props => props.theme.spacing.xs};

  svg {
    margin-right: ${props => props.theme.spacing.xs};
    font-size: ${props => props.theme.font.size.md};
  }
`;

const StatisticsButton = ({ children, ...other }) => (
  <StyledStatisticsButton {...other}>
    <Svg name="statistics" fill="white" />
    {children}
  </StyledStatisticsButton>
);

export default StatisticsButton;
