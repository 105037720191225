import React from 'react';
import styled from 'styled-components';
import PageTemplate from './PageTemplate';

const Error = styled.h3`
  color: ${props => props.theme.colors.radicalRed};
`;

const Description = styled.h3`
  color: ${props => props.theme.colors.rockBlue};
`;

const Note = styled.p`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.note};
`;

const Button = styled.a`
  cursor: pointer;
  display: inline-block;
  font-family: ${props => props.theme.fontFamily.heading};
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.cerulean};
  border-radius: 4px;
  padding: 1em 4em;
`;

const NoProfileFound = () => {
  return (
    <PageTemplate title="Customer Platform profile not found">
      <Error>Error!</Error>
      <br />
      <Description>Customer Platform profile cannot be found.</Description>
      <br />
      <Note>Please contact local support for further assistance.</Note>
      <br />
      <br />
      <br />
      <Button href="/Logout">Go Back</Button>
    </PageTemplate>
  );
};

export default NoProfileFound;
