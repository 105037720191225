import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled, { withTheme } from 'styled-components';
import { capitalize } from 'lodash';
import { Icon, ICON_SIZES } from 'components/Icon/Icon';
import MarkdownContent from './MarkdownContent';
import {
  statuses,
  statusToColor,
  statusToIcon,
  FL_DESCRIPTION_MAX_LENGTH,
  announcementShape,
  ANNOUNCEMENT_TYPE,
} from './utils';

const StyledAnnouncement = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  margin-right: ${props => props.theme.spacing.xl};
  padding-bottom: ${props => props.theme.spacing.xl};
  ${props => props.theme.media.landscape`
       width: calc(100% / 2 - 2em);
       padding-bottom: 0;
    `}
  ${props => props.theme.media.desktop`
       width: calc(100% / 3 - 2em);
    `}
`;
StyledAnnouncement.displayName = 'StyledAnnouncement';

const Title = styled.h4`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-family: ${props => props.theme.font.family.arial};
`;
Title.displayName = 'Title';

const StyledDate = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  font-family: ${props => props.theme.font.family.arial};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: ${props => props.theme.spacing.xs};
`;
StyledDate.displayName = 'StyledDate';

const DescriptionWrapper = styled.div`
  width: 100%;
  margin: ${props => `${props.theme.spacing.xs} 0 ${props.theme.spacing.xxs} 0`};
  overflow: hidden;
  max-height: 64px;
`;
DescriptionWrapper.displayName = 'DescriptionWrapper';

const StyledMarkdownContent = styled(MarkdownContent)`
  color: ${props => props.theme.colors.darkGray};
  line-height: ${props => props.theme.font.lineHeight.lg};

  p {
    padding: 0;
  }
`;
StyledMarkdownContent.displayName = 'StyledMarkdownContent';

const ReadMore = styled.a`
  display: ${props => !props.show && 'none'};
  text-decoration: underline;
  white-space: nowrap;
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xs};
  &:hover {
    color: ${props => props.theme.colors.black};
    cursor: pointer;
  }
`;
ReadMore.displayName = 'ReadMore';

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;
StatusContainer.displayName = 'StatusContainer';

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatusText = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.font.size.xxs};
  margin-left: ${props => props.theme.spacing.xs};
`;

const FLAnnouncement = ({ announcement, onClick, t }) => {
  const descriptionHasLineBreaks = /\r|\n/.exec(announcement.description);

  return (
    <StyledAnnouncement>
      <StyledDate>
        {moment
          .utc(announcement.startDate)
          .local()
          .format('DD.MM.YYYY')}
      </StyledDate>
      <Title>{announcement.title}</Title>
      {announcement.description && (
        <DescriptionWrapper>
          <StyledMarkdownContent content={announcement.description} />
        </DescriptionWrapper>
      )}
      <InfoContainer>
        <StatusContainer>
          <Icon
            name={statusToIcon[announcement.status]}
            fill={statusToColor[announcement.status]}
            size={ICON_SIZES.MEDIUM_MINUS}
          />
          <StatusText>{t(capitalize(announcement.status))}</StatusText>
        </StatusContainer>
        <ReadMore
          onClick={onClick}
          show={descriptionHasLineBreaks || announcement.description.length > FL_DESCRIPTION_MAX_LENGTH}
        >
          {t('Read more')} &rarr;
        </ReadMore>
      </InfoContainer>
    </StyledAnnouncement>
  );
};

FLAnnouncement.propTypes = {
  t: PropTypes.func.isRequired,
  announcement: announcementShape(ANNOUNCEMENT_TYPE.FL),
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

FLAnnouncement.defaultProps = {
  status: statuses[0],
};

export default withTheme(FLAnnouncement);
