import React, { Fragment } from 'react';
import styled, { withTheme, css } from 'styled-components';
import { isNil, isEmpty } from 'lodash';
import { stripUnit } from 'polished';

import ShadowCard from 'components/ShadowCard/ShadowCard';
import StatusInfoChart from './StatusInfoChart';
import StatusInfoHeader from './StatusInfoHeader';
import StatusInfoTrendChart from './StatusInfoTrendChart';
import StatusInfoBars from './StatusInfoBars';
import StatusInfoOutsideValue from './StatusInfoOutsideValue';
import StatusInfoOutsideValueNotes from './StatusInfoOutsideValueNotes';
import ProgressBar, { ProgressBarValue } from 'components/ProgressBar/ProgressBar';
import { ChartLoader } from './StatusInfoChart';

import { getPerformanceStatus, performanceColors } from 'utils/Data/performance';
import StatusInfoTrend from './StatusInfoTrend';

export const CHART_HEIGHT = 32;
export const CHART_HEIGHT_MOBILE = 84;
export const BAR_HEIGHT = 8;
export const BAR_WRAPPER_MOBILE = 18;

const getLandscapeStyles = props => {
  return css`
    width: calc(33% - ${stripUnit(props.theme.grid.gutter) * 0.66}rem);

    &:nth-child(3n) {
      margin-right: 0;
    }
  `;
};

const getWideLandscapeStyles = props => {
  return css`
    width: calc(25% - ${stripUnit(props.theme.grid.gutter) * 0.76}rem);

    &:nth-child(3n) {
      margin-right: ${props.theme.grid.gutter};
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  `;
};

const getWideStyles = props => {
  return css`
    width: calc(100% / 5 - ${stripUnit(props.theme.grid.gutter)}rem * 0.8);
    padding-bottom: ${props.theme.spacing.xl};

    &:nth-child(4n) {
      margin-right: ${props.theme.grid.gutter};
    }

    &:nth-child(5n) {
      margin-right: 0;
    }
  `;
};

/**
 * One item width: 1 / rowWidth - (rowWidth - 1) * gutters
 */
export const StyledStatusInfo = styled(ShadowCard)`
  display: flex;
  flex-flow: column nowrap;
  color: ${props => props.theme.gaugeChart.noteColor};
  text-align: center;
  cursor: ${props => (props.hasHover ? 'pointer' : 'auto')};
  border-radius: 0;
  border: 1px solid ${props => props.theme.colors.lightGray};
  box-shadow: none;
  transition: ${props => props.theme.motion.easeIn} ${props => props.theme.motion.quick};
  padding: ${props => props.theme.spacing.xs};
  height: 200px;

  ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.sm};
        width: calc(100% / 4);
    `}

  ${props => props.theme.media.landscape`
        margin-bottom: ${props => props.theme.grid.gutter};
        border-radius: 0.25rem;
        border: none;
        box-shadow: ${props => !props.isLoading && props.theme.shadows.default};
        padding: 0;
        padding-bottom: ${props => props.theme.spacing.md};
        margin-right: ${props => props.theme.grid.gutter};
        height: auto;
        ${props => !props.naviExtended && getWideLandscapeStyles(props)};
        ${props => props.naviExtended && getLandscapeStyles(props)};

        &:hover {
            margin-top: -4px;
            margin-bottom: calc(${props => props.theme.grid.gutter} + 4px);
            box-shadow: ${props => props.theme.shadows.kpiCard};
        }
    `};

  ${props => props.theme.media.desktop`
        ${props => !props.naviExtended && getWideStyles(props)};
        ${props => props.naviExtended && getWideLandscapeStyles(props)};
    `};

  @media (min-width: ${1200 / 16}em) {
    ${props => props.naviExtended && getWideLandscapeStyles(props)};
  }

  @media (min-width: ${1400 / 16}em) {
    ${props => getWideStyles(props)};
  }

  ${props => props.theme.media.bigDesktop`
        width: calc(100% / 6 - ${stripUnit(props.theme.grid.gutter)}rem * 0.84);
        
        &:nth-child(4n) {
          margin-right: ${props.theme.grid.gutter};
        }

        &:nth-child(5n) {
          margin-right: ${props.theme.grid.gutter};
        }

        &:nth-child(6n) {
          margin-right: 0;
        };
    `}
`;
StyledStatusInfo.displayName = 'StyledStatusInfo';

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${props => props.theme.cards.backgroundColor};
  transition: ${props => props.theme.motion.quick} background-color ${props => props.theme.motion.easing};
  width: 130px;

  ${props => props.theme.media.portrait`
        width: auto;
    `}
`;
Content.displayName = 'Content';

export const ProgressBarWrapper = styled.div`
  display: ${props => props.hideOnMobile && 'none'};
  height: ${BAR_WRAPPER_MOBILE}px;

  ${Content}:hover & {
    ${ProgressBarValue} {
      box-shadow: 0 0 1rem ${props => props.color};
    }
  }

  ${props => props.theme.media.landscape`
        display: block;
        padding: ${props => !props.isLoading && props.theme.spacing.md} 0;
        margin: ${props => props.theme.spacing.md} ${props => props.theme.spacing.xl};
    `}
`;
ProgressBarWrapper.displayName = 'ProgressBarWrapper';

const ChartWrapper = styled.div`
  ${props => props.theme.media.landscape`
        display: ${props => props.hideOnDesktop && 'none'};
    `}
`;
ChartWrapper.displayName = 'ChartWrapper';

const StatusInfo = props => {
  const {
    t,
    value,
    label,
    min,
    max,
    unit,
    axisTitle,
    valueNote,
    heading,
    headingPosition,
    onClick,
    minWidth,
    positiveValueDirection,
    valuePosition,
    spaceBeforeUnit,
    precision,
    loading,
    ctxHelp,
    ctxHelpOverride,
    theme,
    chart,
    color,
    bars,
    isChange,
    formatTooltip,
    positiveValue,
    sideNaviExtended,
    ctxHelpPosition,
    isAirQuality,
    isPerformance,
  } = props;

  const hasHover = typeof onClick === 'function';
  const roundedValue = !isNil(value) && typeof value !== 'string' ? +value.toFixed(precision) : value;

  const showTrendChart = !isEmpty(chart);
  const showBars = bars;
  const showProgressBar = unit === '%';

  return (
    <StyledStatusInfo onClick={onClick} hasHover={hasHover} naviExtended={sideNaviExtended}>
      <Content>
        <StatusInfoHeader
          text={heading}
          t={t}
          loading={loading}
          hasHover={hasHover}
          ctxHelpTitle={heading}
          ctxHelpText={ctxHelp}
          ctxHelpOverride={ctxHelpOverride}
          position={headingPosition}
          ctxHelpPosition={ctxHelpPosition}
        />
        {showTrendChart && <StatusInfoTrendChart chart={chart} loading={loading} formatTooltip={formatTooltip} />}
        {!showBars && !showTrendChart && !showProgressBar && (
          <StatusInfoTrend
            positiveValue={positiveValue}
            positiveValueDirection={positiveValueDirection}
            loading={loading}
            value={value}
            isPerformance={isPerformance}
          />
        )}
        {showBars && <StatusInfoBars bars={bars} loading={loading} t={t} />}
        {!showTrendChart && !showBars && isNil(positiveValue) && (
          <ChartWrapper hideOnDesktop={valuePosition !== 'inside'}>
            <StatusInfoChart
              loading={loading}
              value={roundedValue}
              label={label}
              min={min}
              max={max}
              unit={unit}
              axisTitle={axisTitle}
              minWidth={minWidth}
              addPadding={false}
            />
          </ChartWrapper>
        )}
        {valuePosition === 'outside' && (
          <Fragment>
            {showProgressBar && loading && <ChartLoader size={CHART_HEIGHT} hideOnMobile />}
            {showProgressBar && !loading && (
              <ProgressBarWrapper
                color={color || performanceColors({ theme })[getPerformanceStatus(value, isAirQuality)]}
                isLoading={loading}
                hideOnMobile
              >
                <ProgressBar
                  value={value || 0}
                  color={color || performanceColors({ theme })[getPerformanceStatus(value, isAirQuality)]}
                  hasHover={hasHover}
                  height={BAR_HEIGHT}
                />
              </ProgressBarWrapper>
            )}
            <StatusInfoOutsideValue
              value={roundedValue}
              spaceBeforeUnit={spaceBeforeUnit}
              unit={unit}
              valueNote={valueNote}
              loading={loading}
              positiveValueDirection={positiveValueDirection}
              hideOnMobile={!showTrendChart && !showBars && isNil(positiveValue)}
              showArrow={isChange}
            />
            <StatusInfoOutsideValueNotes valueNote={valueNote} />
          </Fragment>
        )}
      </Content>
    </StyledStatusInfo>
  );
};

StatusInfo.defaultProps = {
  value: 0,
  label: '',
  minWidth: 100,
  valuePosition: 'inside',
  positiveValueDirection: 'up',
  showValue: true,
  spaceBeforeUnit: true,
  precision: 0,
  headingPosition: 'top',
  color: undefined,
  isAirQuality: false,
};

export default withTheme(StatusInfo);
