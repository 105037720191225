import _ from 'lodash';
import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT } from '@caverion/redux/api/actions';

export const initialState = {
  cleaningValues: [],
  loadingCleaning: false,
};

export const LOAD_CLEANING = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CLEANING';
export const LOAD_CLEANING_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CLEANING_SUCCESS';
export const LOAD_CLEANING_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CLEANING_FAIL';

export const loadCleaning = (sensorIds, startTime, endTime) => {
  const filter = {
    where: {
      sensorId: { inq: sensorIds },
      timestamp: { between: [startTime, endTime] },
      aggregation: 'raw',
    },
    fields: ['sensorId', 'aggregation', 'timestamp', 'value'],
  };

  return async dispatch => {
    dispatch({ type: LOAD_CLEANING });
    try {
      const result = await dispatch(IoT.findWithPost(filter));

      return dispatch({
        type: LOAD_CLEANING_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_CLEANING_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_CLEANING]: state => ({
      ...state,
      loadingCleaning: true,
    }),
    [LOAD_CLEANING_SUCCESS]: (state, action) => ({
      ...state,
      cleaningValues: _.compact([].concat(state.cleaningValues, action.result)),
      loadingCleaning: false,
    }),

    [LOAD_CLEANING_FAIL]: state => ({
      ...state,
      loadingCleaning: false,
    }),
  },
  initialState
);
