import { createReducerFromMapping } from 'redux/utils/index.js';
import { Device } from '@caverion/redux/api/actions';
import moment from 'moment';
import { isAdminRole } from 'utils/Data/profileData';

const initialState = {
  devices: {},
  loadingDevices: false,
  initializingStation: false,
};

export const LOAD_DEVICES = 'CUSTOMER_PLATFORM/Device/LOAD_DEVICES';
export const LOAD_DEVICES_SUCCESS = 'CUSTOMER_PLATFORM/Device/LOAD_DEVICES_SUCCESS';
export const LOAD_DEVICES_FAIL = 'CUSTOMER_PLATFORM/Device/LOAD_DEVICES_FAIL';

export const loadDevices = fl => {
  const filter = {
    where: {
      functionalLocation: fl,
    },
  };

  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    // TODO: Proper cache invalidation
    const state = getState();
    const profile = state.profile && state.profile.profile;
    if (profile && isAdminRole(profile.role)) {
      filter.timestamp = moment.utc().toISOString();
    }
    dispatch({ type: LOAD_DEVICES });
    try {
      const result = await dispatch(Device.find({ filter }));
      return dispatch({
        type: LOAD_DEVICES_SUCCESS,
        result,
        fl,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_DEVICES_FAIL,
        error,
      });
    }
  };
};

export const INITIALIZE_NIAGARA_STATION = 'CUSTOMER_PLATFORM/Device/INITIALIZE_NIAGARA_STATION';
export const INITIALIZE_NIAGARA_STATION_SUCCESS = 'CUSTOMER_PLATFORM/Device/INITIALIZE_NIAGARA_STATION_SUCCESS';
export const INITIALIZE_NIAGARA_STATION_FAIL = 'CUSTOMER_PLATFORM/Device/INITIALIZE_NIAGARA_STATION_FAIL';

export const initializeNiagaraStation = data => {
  return async dispatch => {
    dispatch({ type: INITIALIZE_NIAGARA_STATION });
    try {
      const result = await dispatch(Device.initializeNiagaraStation({ data }));

      return dispatch({
        type: INITIALIZE_NIAGARA_STATION_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: INITIALIZE_NIAGARA_STATION_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_DEVICES]: (state, action) => ({
      ...state,
      loadingDevices: true,
    }),
    [LOAD_DEVICES_SUCCESS]: (state, action) => ({
      ...state,
      loadingDevices: false,
      devices: {
        ...state.devices,
        [action.fl]: action.result,
      },
    }),

    [LOAD_DEVICES_FAIL]: (state, action) => ({
      ...state,
      loadingDevices: false,
    }),
    [INITIALIZE_NIAGARA_STATION]: (state, action) => ({
      ...state,
      initializingStation: true,
    }),
    [INITIALIZE_NIAGARA_STATION_SUCCESS]: (state, action) => ({
      ...state,
      initializingStation: false,
    }),
    [INITIALIZE_NIAGARA_STATION_FAIL]: (state, action) => ({
      ...state,
      initializingStation: false,
    }),
  },
  initialState
);
