import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

const StyledDateColumn = styled.div`
  color: ${props => props.theme.colors.black};
`;

const DateRow = styled.div`
  font-size: ${props => props.theme.fontSize.xxs};

  ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.fontSize.xs};
    `}
`;

const DateColumn = props => {
  if (!props.data || !props.data.date) {
    return null;
  }
  const date = moment(props.data.date);

  return (
    <StyledDateColumn>
      <DateRow>
        {date.format('L')} - {date.format('LT')}
      </DateRow>
    </StyledDateColumn>
  );
};

export default DateColumn;
