import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const performanceColors = props => ({
  observed: props.theme.status.warningColor,
  warning: props.theme.status.warningColor,
  offered: props.theme.status.warningColor,
  processed: props.theme.status.warningColor,
  started: props.theme.status.warningColor,
  completed: props.theme.status.okColor,
  Completed: props.theme.status.okColor,
  ok: props.theme.status.okColor,
  alert: props.theme.status.alarmColor,
  Open: props.theme.status.warningColor,
  'In progress': props.theme.status.warningColor,
});

const StyledStatusColumn = styled.div`
  ${props => props.theme.media.landscape`
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
    `}
`;

const Status = styled.div`
  display: ${props => (props.showOnMobile ? 'block' : 'none')};
  margin-left: ${props => props.theme.spacing.xs};
  text-align: center;
  color: ${props => props.theme.colors.darkGray};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxs};
  letter-spacing: 0.3px;
  ${props => props.theme.media.landscape`
        display: block;
    `}
`;

const StyledRating = styled.div`
  display: flex;
  flex-flow: row nowrap;
  float: left;
  padding-right: 1em;

  ${props => props.theme.media.landscape`
        float: none;
        padding-right: 0;
    `}
`;

const RatingItem = styled.span`
  width: ${props => props.theme.spacing.xs};
  height: ${props => props.theme.spacing.xs};
  margin: 1px;
  border-radius: 50%;
  background-color: ${props => (props.checked && performanceColors(props)[props.status]) || props.theme.colors.mystic};

  ${props => props.theme.media.landscape`
        width: ${props => props.theme.spacing.sm};
        height: ${props => props.theme.spacing.sm};
    `}
`;

const Rating = ({ value, status }) => {
  return (
    <StyledRating>
      {_.times(3, idx => {
        return <RatingItem checked={idx < value} status={status} key={'rating' + idx} />;
      })}
    </StyledRating>
  );
};

const StatusColumn = props => {
  if (!props.data) {
    return null;
  }

  const { value, status, title } = props.data;

  return (
    <StyledStatusColumn>
      <Rating value={value} status={status} />
      <Status>{props.t(title)}</Status>
    </StyledStatusColumn>
  );
};

export default StatusColumn;
