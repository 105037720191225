export const createDataLayerEvents = data => {
  const { partnerNumber, title } = data;

  const eventData = {
    event: 'pageview',
    pageTitle: title,
    pagePath: window.location.href,
    partnerNumber,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventData);

  // If there is active partner, create partner-pageview event with same data
  if (partnerNumber && partnerNumber !== 'all') {
    window.dataLayer.push({
      ...eventData,
      event: `partner-pageview-${partnerNumber}`,
    });
  }
};
