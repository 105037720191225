import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import InputForm from 'components/Form/InputForm';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import InputTextArea from 'components/Form/InputTextArea';
import InputRow from 'components/Form/InputRow';
import Button from 'components/Button/Button';
import FeedbackRadioButton from './FeedbackRadioButton';

export const Label = styled.label`
  font-weight: ${props => props.theme.font.weight.bold};
`;
Label.displayName = 'Label';

const booleanToString = value => (typeof value === 'boolean' ? value.toString() : undefined);

const stringToBoolean = value => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return undefined;
};

const FeedbackForm = ({ t, model, onSubmit, onChange, sending, sent, theme }) => (
  <InputForm onPropertyChange={onChange} onSubmit={onSubmit} model={model}>
    <InputRow fullRow>
      <Label>{t('Are you happy with Smartview on today?')}</Label>
      <RadioButtonGroup
        name="happyWithSmartView"
        value={booleanToString(model.happyWithSmartView)}
        type="icon"
        onChange={(property, value) => onChange(property, stringToBoolean(value))}
        row
      >
        <FeedbackRadioButton label={t('Yes')} value="true" icon="smiley-good" color={theme.colors.emerald} />
        <FeedbackRadioButton label={t('No')} value="false" icon="smiley-bad" color={theme.colors.radicalRed} />
      </RadioButtonGroup>
    </InputRow>
    <InputRow fullRow>
      <Label>{t('Feedback')}</Label>
      <InputTextArea property="freeFormFeedback" placeholder={t('Feel free to add any comments...')} />
    </InputRow>
    <Button submit loading={sending} disabled={sent}>
      {sent ? t('Sent') : t('Send')}
    </Button>
  </InputForm>
);

FeedbackForm.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  sent: PropTypes.bool.isRequired,
};

export default withTheme(FeedbackForm);
