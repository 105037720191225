import { colors, font, text, defaultColor, breakpoints } from './definitions.js';
import customerPlatform from './tokens/customerPlatform';

const themes = {
  customerPlatform: {
    ...customerPlatform,
    colors: colors,
    font: font,
    text: text,
    defaultColor: defaultColor,
    breakpoints: breakpoints,
  },
};

export default themes;
