import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import find from 'lodash/find';
import omitBy from 'lodash/omitBy';
import clone from 'lodash/clone';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import Helmet from 'react-helmet';

import { loadEquipmentContainer } from 'redux/modules/containers/equipment.js';

import translations from 'decorators/Translations/translations';
import ExternalDocumentModule from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentModule.jsx';
import MaintenanceModule from 'containers/Application/Modules/MaintenanceModule/MaintenanceModule.jsx';
import ServiceRequest from 'containers/Application/ServiceRequest/ServiceRequest';

import EquipmentModule from 'containers/Application/Modules/EquipmentModule/EquipmentModule.jsx';
import Hero from 'components/Hero/Hero.jsx';
import Header from 'containers/Application/Header/Header.jsx';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage.jsx';
import TabContent from 'components/TabContent/TabContent';
import StandardPage from 'components/StandardPage/StandardPage.jsx';
import { icons } from 'components/Icon/IconNames';

import {
  getParentFunctionLocations,
  getHeroContext,
  getBuildingImage,
  addServiceOrderLink,
  addConditionsLink,
  addEquipmentLink,
  addExternalDocumentsLink,
  addNewServiceRequestLink,
} from 'utils/Data/functionalLocations';
import { isServiceOrdersEnabled, isEquipmentEnabled } from 'utils/Data/profileData';

import PerformanceData from './Conditions/PerformanceData';

const tabs = ['ServiceCalendar', 'Conditions', 'ServiceRequest', 'ExternalDocuments', 'SubEquipment'];

const Equipment = props => {
  const {
    t,
    features,
    functionalLocations,
    equipments,
    subEquipments,
    location,
    loading,
    equipmentDocuments,
    match: {
      params: { functionalLocationId, equipmentNumber, partnerNumber, superordinate },
      url,
      path,
    },
    loadingParents,
    functionalLocationImages,
    loadEquipmentContainer,
  } = props;

  React.useEffect(
    () => {
      !includes(tabs, equipmentNumber) &&
        isEquipmentEnabled(features) &&
        loadEquipmentContainer(functionalLocationId, equipmentNumber, features, superordinate, partnerNumber);
    },
    [functionalLocationId, equipmentNumber, superordinate]
  );

  // redirect old match path /:equipmentNumber/TabName
  if (includes(tabs, equipmentNumber)) {
    const to = `/${partnerNumber}/Equipment/${functionalLocationId}//${superordinate}/${equipmentNumber}`;
    return <Redirect to={to} />;
  }

  if (!isEquipmentEnabled(features)) {
    return null;
  }

  const equipment = find(equipments[functionalLocationId], { equipmentNumber });

  if (!equipment && !loading) {
    return <ErrorPage type="equipment" />;
  }

  // Show documents loading indicator if equipmentDocuments is null.
  let loadingDocuments = false;
  let documentCount = 0;
  if (equipmentDocuments && isArray(equipmentDocuments[equipmentNumber])) {
    const files = flatten(equipmentDocuments[equipmentNumber].filter(d => d.files.length > 0).map(d => d.files));
    documentCount = files.length;
  } else {
    loadingDocuments = true;
  }

  // Show subequipment loading indicator if subEquipments is null.
  let loadingSubEquipment = false;
  let subEquipmentCount = 0;
  if (subEquipments) {
    if (subEquipments[equipmentNumber]) {
      subEquipmentCount = subEquipments[equipmentNumber].length;
    } else {
      loadingSubEquipment = true;
    }
  }

  const functionalLocation = functionalLocations[functionalLocationId];
  const parents = clone(
    getParentFunctionLocations(functionalLocations, functionalLocation?.path, functionalLocationId)
  ).reverse();
  parents.push(functionalLocation);

  if (superordinate) {
    const parentEquipment = find(equipments[functionalLocationId], { equipmentNumber: superordinate });
    parents.push(parentEquipment);
  }

  const partnerPart = !partnerNumber ? '' : `/${partnerNumber}`;
  const { from, fromTab } = queryString.parse(location.search);
  const backLink = { pathname: `${partnerPart}/FunctionalLocation/${from}/${fromTab || 'Conditions'}` };

  const parentImages = omitBy(map(parents, parent => getBuildingImage(functionalLocationImages, parent)), isEmpty);

  let context = { loadingContext: true, loading: true };
  let links = [];
  from &&
    links.push({
      title: 'Back to Building Conditions',
      icon: icons.ARROW_LEFT,
      to: backLink,
      smallIcon: true,
    });

  links = addServiceOrderLink(links, url, features);
  links = addConditionsLink(links, url, features);
  links = addExternalDocumentsLink(links, url, features, documentCount, loadingDocuments);
  links = addEquipmentLink(links, url, features, subEquipmentCount, loadingSubEquipment, [], true);

  let linksMobile = clone(links);
  linksMobile = addNewServiceRequestLink(linksMobile, partnerNumber, functionalLocation, features);

  if (equipment) {
    const heroContext = getHeroContext(parents, partnerNumber, features);
    context = Object.assign({}, heroContext, {
      type: 'EQ',
      loadingContext: loadingParents,
      functionalLocation,
      equipment,
      text: equipment.text,
    });
  }

  return (
    <StandardPage disableScrollToTop withTabs>
      <Helmet title={equipment && equipment.text} />
      <Header context={context} t={t} links={links} linksMobile={linksMobile} />
      <Hero
        equipment={equipment}
        type="EQ"
        title={equipment && equipment.text}
        t={t}
        loadingContext={loadingParents}
        partnerNumber={partnerNumber}
        heroImage={parentImages && parentImages[0]}
      />
      <TabContent>
        <Switch>
          {features?.documents && (
            <Route path={`${path}/ExternalDocuments`}>
              <ExternalDocumentModule functionalLocation={functionalLocation} equipment={equipment} />
            </Route>
          )}
          {features?.conditions && (
            <Route path={`${path}/Conditions`}>
              <PerformanceData t={t} equipment={equipment} functionalLocationId={functionalLocationId} />
            </Route>
          )}
          {isServiceOrdersEnabled(features) && (
            <Route path={`${path}/ServiceCalendar`}>
              <MaintenanceModule functionalLocation={functionalLocation} equipment={equipment} />
            </Route>
          )}
          {features?.sr && (
            <Route path={`${path}/ServiceRequest`}>
              <ServiceRequest />
            </Route>
          )}
          <Route path={`${path}/Subequipment`}>
            <EquipmentModule
              title={t('Subequipment')}
              equipments={subEquipments[equipmentNumber]}
              subEquipments={subEquipments}
              loadingEquipment={loadingSubEquipment}
            />
          </Route>
          <Redirect exact from={`${path}/`} to={`${path}/Subequipment`} />
        </Switch>
      </TabContent>
    </StandardPage>
  );
};

Equipment.propTypes = {
  t: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
  functionalLocations: PropTypes.object.isRequired,
  equipments: PropTypes.object.isRequired,
  subEquipments: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  equipmentDocuments: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loadingParents: PropTypes.bool,
  functionalLocationImages: PropTypes.object.isRequired,
  loadEquipmentContainer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: state.equipmentContainer.loading,
  loadingParents: state.equipmentContainer.loadingParents,
  features: state.profile.profile.syntheticFeatures,
  functionalLocations: state.functionalLocations.functionalLocations,
  equipments: state.equipments.equipments,
  subEquipments: state.equipments.subEquipments,
  equipmentDocuments: state.document.filteredEquipment,
  functionalLocationImages: state.partnerImage.byFL,
});

const mapDispatchToProps = {
  loadEquipmentContainer,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRouter(connector(translations(Equipment)));
