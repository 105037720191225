import { createReducerFromMapping } from 'redux/utils/index.js';
import _ from 'lodash';
import moment from 'moment';
import { IoT } from '@caverion/redux/api/actions';

const initialState = {
  alarms: {},
  byPartner: {
    loading: false,
    data: {},
  },
  byFL: {},
  loading: false,
  handlingTimeKPI: {
    loading: false,
  },
  timeToActionKPI: {
    loading: false,
  },
  performance: {},
  actionCounts: {},
  handlingTime: {},
  timeToAction: {},
  benchmarking: {},
};

export const LOAD_FOR_FL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_FOR_FL';
export const LOAD_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_FOR_FL_SUCCESS';
export const LOAD_FOR_FL_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_FOR_FL_FAIL';

export const loadFunctionalLocationAlarms = (functionalLocation, start) => {
  const filter = {
    where: {
      path: {
        'any x': {
          x: functionalLocation.functionalLocation,
        },
      },
      timestamp: {
        gt: start.toISOString(),
      },
    },
    // Highchart requires that data is sorted by depended variable in ascending order.
    orderBy: {
      timestamp: 'ASC',
    },
  };

  return async dispatch => {
    dispatch({ type: LOAD_FOR_FL });
    try {
      const result = await dispatch(IoT.alarms(filter));

      return dispatch({
        type: LOAD_FOR_FL_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FOR_FL_FAIL,
        error,
      });
    }
  };
};

export const LOAD_PARTNER_COUNTS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_COUNTS';
export const LOAD_PARTNER_COUNTS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_COUNTS_SUCCESS';
export const LOAD_PARTNER_COUNTS_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_COUNTS_FAIL';

export const loadPartnerAlarmCounts = (partnerNumber, baseWhere, start, end) => {
  const filter = {
    where: {
      ...baseWhere,
      timestamp: {
        between: [start.toISOString(), end.toISOString()],
      },
    },
  };

  return async dispatch => {
    dispatch({ type: LOAD_PARTNER_COUNTS });
    try {
      const result = await dispatch(IoT.alarmCounts(filter));

      return dispatch({
        type: LOAD_PARTNER_COUNTS_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_PARTNER_COUNTS_FAIL,
        error,
      });
    }
  };
};

export const LOAD_HANDLING_TIME_KPI = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_KPI';
export const LOAD_HANDLING_TIME_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_KPI_SUCCESS';
export const LOAD_HANDLING_TIME_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_KPI_FAIL';

export const loadHandlingTimeKPI = partnerNumber => {
  return async dispatch => {
    dispatch({ type: LOAD_HANDLING_TIME_KPI });
    try {
      const result = await dispatch(IoT.alarmHandlingTimeKpi(partnerNumber));

      return dispatch({
        type: LOAD_HANDLING_TIME_KPI_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_HANDLING_TIME_KPI_FAIL,
        error,
      });
    }
  };
};

export const LOAD_TIME_TO_ACTION_KPI = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_KPI';
export const LOAD_TIME_TO_ACTION_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_KPI_SUCCESS';
export const LOAD_TIME_TO_ACTION_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_KPI_FAIL';

export const loadTimeToActionKPI = partnerNumber => {
  return async dispatch => {
    dispatch({ type: LOAD_TIME_TO_ACTION_KPI });
    try {
      const result = await dispatch(IoT.alarmTimeToActionKpi(partnerNumber));

      return dispatch({
        type: LOAD_TIME_TO_ACTION_KPI_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_TIME_TO_ACTION_KPI_FAIL,
        error,
      });
    }
  };
};

export const LOAD_ALARM_PERFORMANCE = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_ALARM_PERFORMANCE';
export const LOAD_ALARM_PERFORMANCE_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_ALARM_PERFORMANCE_SUCCESS';
export const LOAD_ALARM_PERFORMANCE_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_ALARM_PERFORMANCE_FAIL';

export const loadAlarmPerformance = partnerNumber => async dispatch => {
  dispatch({ type: LOAD_ALARM_PERFORMANCE, partnerNumber });
  try {
    const result = await dispatch(IoT.alarmPerformance(partnerNumber));
    dispatch({
      type: LOAD_ALARM_PERFORMANCE_SUCCESS,
      partnerNumber,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_ALARM_PERFORMANCE_FAIL,
      partnerNumber,
      error,
    });
  }
};

export const LOAD_BENCHMARKING = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_BENCHMARKING';
export const LOAD_BENCHMARKING_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_BENCHMARKING_SUCCESS';
export const LOAD_BENCHMARKING_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_BENCHMARKING_FAIL';

export const loadBenchmarking = partnerNumber => {
  return async dispatch => {
    dispatch({ type: LOAD_BENCHMARKING });
    try {
      const result = await dispatch(IoT.alarmBenchmarking(partnerNumber));

      return dispatch({
        type: LOAD_BENCHMARKING_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_BENCHMARKING_FAIL,
        error,
      });
    }
  };
};

export const LOAD_PARTNER_ACTION_COUNTS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_ACTION_COUNTS';
export const LOAD_PARTNER_ACTION_COUNTS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_ACTION_COUNTS_SUCCESS';
export const LOAD_PARTNER_ACTION_COUNTS_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_ACTION_COUNTS_FAIL';

export const loadPartnerAlarmActionCounts = (partnerNumber, baseWhere) => {
  const filter = { where: baseWhere };

  return async dispatch => {
    dispatch({ type: LOAD_PARTNER_ACTION_COUNTS });
    try {
      const result = await dispatch(IoT.alarmActionCount(filter));

      return dispatch({
        type: LOAD_PARTNER_ACTION_COUNTS_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_PARTNER_ACTION_COUNTS_FAIL,
        error,
      });
    }
  };
};

export const LOAD_HANDLING_TIME_BY_MONTH = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_BY_MONTH';
export const LOAD_HANDLING_TIME_BY_MONTH_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_BY_MONTH_SUCCESS';
export const LOAD_HANDLING_TIME_BY_MONTH_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_BY_MONTH_FAIL';

export const loadPartnerHandlingTimeByMonth = (partnerNumber, baseWhere, year) => {
  const filter = { where: baseWhere };

  return async dispatch => {
    dispatch({ type: LOAD_HANDLING_TIME_BY_MONTH, year });
    try {
      const result = await dispatch(IoT.alarmHandlingTimeByMonth(filter, year));

      return dispatch({
        type: LOAD_HANDLING_TIME_BY_MONTH_SUCCESS,
        partnerNumber,
        result,
        year,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_HANDLING_TIME_BY_MONTH_FAIL,
        error,
        year,
      });
    }
  };
};

export const LOAD_TIME_TO_ACTION_BY_MONTH = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_BY_MONTH';
export const LOAD_TIME_TO_ACTION_BY_MONTH_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_BY_MONTH_SUCCESS';
export const LOAD_TIME_TO_ACTION_BY_MONTH_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_BY_MONTH_FAIL';

export const loadPartnerTimeToActionByMonth = (partnerNumber, baseWhere, year) => {
  const filter = { where: baseWhere };

  return async dispatch => {
    dispatch({ type: LOAD_TIME_TO_ACTION_BY_MONTH, year });
    try {
      const result = await dispatch(IoT.alarmTimeToActionByMonth(filter, year));

      return dispatch({
        type: LOAD_TIME_TO_ACTION_BY_MONTH_SUCCESS,
        partnerNumber,
        result,
        year,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_TIME_TO_ACTION_BY_MONTH_FAIL,
        error,
        year,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_FOR_FL]: state => ({
      ...state,
      loading: true,
    }),
    [LOAD_FOR_FL_SUCCESS]: (state, action) => {
      const byFL = state.byFL;
      byFL[action.functionalLocation] = mapAlarmsForSortedTable(action.result);
      return {
        ...state,
        byFL,
        loading: false,
      };
    },

    [LOAD_FOR_FL_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
      loading: false,
    }),

    [LOAD_PARTNER_COUNTS]: (state, action) => ({
      ...state,
      byPartner: {
        ...state.byPartner,
        loading: true,
      },
    }),
    [LOAD_PARTNER_COUNTS_SUCCESS]: (state, action) => ({
      ...state,
      byPartner: {
        ...state.byPartner,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),
    [LOAD_PARTNER_COUNTS_FAIL]: (state, action) => ({
      ...state,
      byPartner: {
        ...state.byPartner,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_HANDLING_TIME_KPI]: state => ({
      ...state,
      handlingTimeKPI: {
        ...state.handlingTimeKPI,
        loading: true,
      },
    }),

    [LOAD_HANDLING_TIME_KPI_SUCCESS]: (state, action) => ({
      ...state,
      handlingTimeKPI: {
        ...state.handlingTimeKPI,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),

    [LOAD_HANDLING_TIME_KPI_FAIL]: (state, action) => ({
      ...state,
      handlingTimeKPI: {
        ...state.handlingTimeKPI,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_TIME_TO_ACTION_KPI]: state => ({
      ...state,
      timeToActionKPI: {
        ...state.timeToActionKPI,
        loading: true,
      },
    }),

    [LOAD_TIME_TO_ACTION_KPI_SUCCESS]: (state, action) => ({
      ...state,
      timeToActionKPI: {
        ...state.timeToActionKPI,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),

    [LOAD_TIME_TO_ACTION_KPI_FAIL]: (state, action) => ({
      ...state,
      timeToActionKPI: {
        ...state.timeToActionKPI,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_BENCHMARKING]: state => ({
      ...state,
      benchmarking: {
        ...state.benchmarking,
        loading: true,
      },
    }),

    [LOAD_BENCHMARKING_SUCCESS]: (state, action) => ({
      ...state,
      benchmarking: {
        ...state.benchmarking,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),

    [LOAD_BENCHMARKING_FAIL]: (state, action) => ({
      ...state,
      benchmarking: {
        ...state.benchmarking,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_ALARM_PERFORMANCE]: (state, action) => ({
      ...state,
      performance: {
        ...state.performance,
        [action.partnerNumber]: {
          loading: true,
        },
      },
    }),
    [LOAD_ALARM_PERFORMANCE_SUCCESS]: (state, action) => ({
      ...state,
      performance: {
        ...state.performance,
        [action.partnerNumber]: {
          loading: false,
          data: action.result,
        },
      },
    }),
    [LOAD_ALARM_PERFORMANCE_FAIL]: (state, action) => ({
      ...state,
      performance: {
        ...state.performance,
        [action.partnerNumber]: {
          loading: false,
          error: action.error,
        },
      },
    }),

    [LOAD_PARTNER_ACTION_COUNTS]: (state, action) => ({
      ...state,
      actionCounts: {
        ...state.actionCounts,
        [action.partnerNumber]: {
          loading: true,
        },
      },
    }),
    [LOAD_PARTNER_ACTION_COUNTS_SUCCESS]: (state, action) => ({
      ...state,
      actionCounts: {
        ...state.actionCounts,
        [action.partnerNumber]: {
          loading: false,
          data: action.result.actions,
        },
      },
    }),
    [LOAD_PARTNER_ACTION_COUNTS_FAIL]: (state, action) => ({
      ...state,
      actionCounts: {
        ...state.actionCounts,
        [action.partnerNumber]: {
          loading: false,
          error: action.error,
        },
      },
    }),

    [LOAD_HANDLING_TIME_BY_MONTH]: (state, action) => ({
      ...state,
      handlingTime: {
        ...state.handlingTime,
        [action.partnerNumber]: {
          ...state.handlingTime[action.partnerNumber],
          [action.year]: {
            loading: true,
          },
        },
      },
    }),
    [LOAD_HANDLING_TIME_BY_MONTH_SUCCESS]: (state, action) => ({
      ...state,
      handlingTime: {
        ...state.handlingTime,
        [action.partnerNumber]: {
          ...state.handlingTime[action.partnerNumber],
          [action.year]: {
            loading: false,
            data: action.result,
          },
        },
      },
    }),
    [LOAD_HANDLING_TIME_BY_MONTH_FAIL]: (state, action) => ({
      ...state,
      handlingTime: {
        ...state.handlingTime,
        [action.partnerNumber]: {
          ...state.handlingTime[action.partnerNumber],
          [action.year]: {
            loading: false,
            error: action.error,
          },
        },
      },
    }),

    [LOAD_TIME_TO_ACTION_BY_MONTH]: (state, action) => ({
      ...state,
      timeToAction: {
        ...state.timeToAction,
        [action.partnerNumber]: {
          ...state.timeToAction[action.partnerNumber],
          [action.year]: {
            loading: true,
          },
        },
      },
    }),
    [LOAD_TIME_TO_ACTION_BY_MONTH_SUCCESS]: (state, action) => ({
      ...state,
      timeToAction: {
        ...state.timeToAction,
        [action.partnerNumber]: {
          ...state.timeToAction[action.partnerNumber],
          [action.year]: {
            loading: false,
            data: action.result,
          },
        },
      },
    }),
    [LOAD_TIME_TO_ACTION_BY_MONTH_FAIL]: (state, action) => ({
      ...state,
      timeToAction: {
        ...state.timeToAction,
        [action.partnerNumber]: {
          ...state.timeToAction[action.partnerNumber],
          [action.year]: {
            loading: false,
            error: action.error,
          },
        },
      },
    }),
  },
  initialState
);

const mapAlarmsForSortedTable = result => {
  const grouped = _.groupBy(result, 'source');
  const alarms = [];
  _.forEach(grouped, (rows, alarmClass) => {
    // Pick latest alarm from the list. Data might have actionTimestamps or they are nulls...
    let row = _.first(_.orderBy(_.filter(rows, row => row.actionTimestamp !== null), 'actionTimestamp', 'desc'));
    // Fallback if all actionTimestamps are null, take first.
    if (_.isEmpty(row)) {
      row = _.first(rows);
    }

    const rowWithAction = _.first(_.filter(_.orderBy(rows, 'actionTimestamp', 'desc'), 'actionCategoryId'));
    const fixedSource = alarmClass.replace(/ {2}/g, ' ').replace('Source: ', '');

    // Add only alarms with action(s)
    if (rowWithAction) {
      alarms.push({
        alarm: {
          value: fixedSource,
        },
        alerts: rows.length,
        date: {
          date: moment.utc(rowWithAction.actionTimestamp).local(),
          value: moment.utc(rowWithAction.actionTimestamp).local(),
        },
        status: {
          value: '1',
          status: row.type,
          title: row.type,
        },
        event: {
          type: 'notice',
          description: rowWithAction.actionText,
        },
        message: row.message,
      });
    }
  });

  const actionTimes = _.filter(result, alarm => alarm.acknowledgedTimestamp).map(alarm =>
    moment.utc(alarm.acknowledgedTimestamp).diff(moment.utc(alarm.timestamp), 'minutes')
  );
  const timeToAction = _.filter(result, alarm => alarm.actionTimestamp).map(alarm =>
    moment.utc(alarm.actionTimestamp).diff(moment.utc(alarm.timestamp), 'minutes')
  );
  const actionGroups = _.groupBy(_.filter(result, alarm => alarm.actionCategoryId), 'actionCategoryId');

  const byAction = [];
  const byActionUnknown = { title: `alarmActionGroup_unknown`, value: 0 };
  _.forEach(actionGroups, (rows, key) => {
    const country = rows[0].country;
    const countryPart = country ? `${country}_` : '';
    // Keys 1-8 have defined translations in all countries (FI has 1-10). All others are unknown.
    const activeKeyMax = country && country === 'FI' ? 10 : 8;
    key <= activeKeyMax
      ? byAction.push({ title: `alarmActionGroup_${countryPart}${key}`, value: rows.length })
      : Object.assign(byActionUnknown, { value: byActionUnknown.value + rows.length });
  });

  if (byActionUnknown.value > 0) {
    byAction.push(byActionUnknown);
  }

  return {
    alarms,
    totals: {
      all: result.length,
      open: _.filter(result, { status: 0 }).length,
      acknowledged: _.filter(result, { status: 1 }).length,
      withAction: _.filter(result, alarm => alarm.actionTimestamp).length,
      averageActionTime: _.mean(actionTimes).toFixed(0),
      averageTimeToAction: _.mean(timeToAction).toFixed(0),
      byAction,
    },
  };
};
