import { createReducerFromMapping } from 'redux/utils/index.js';
import { getDocumentByCustomType, CUSTOM_TYPE } from 'components/UserManual/utils/UserManualUtils';

const initialState = {
  loading: false,
  general: null,
  admin: null,
  error: null,
};

export const PRISMIC_LOAD_DATA = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_DATA';
export const PRISMIC_LOAD_DATA_FINISHED = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_DATA_FINISHED';
export const PRISMIC_LOAD_DATA_FAIL = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_DATA_FAIL';

export const loadData = () => async dispatch => {
  dispatch({ type: PRISMIC_LOAD_DATA });
  try {
    const response = await getDocumentByCustomType(CUSTOM_TYPE.GENERAL_USER_MANUAL);
    const data = response.results[0].data;
    return dispatch({
      type: PRISMIC_LOAD_DATA_FINISHED,
      data,
    });
  } catch (error) {
    return dispatch({
      type: PRISMIC_LOAD_DATA_FAIL,
      error,
    });
  }
};

export const PRISMIC_LOAD_ADMIN_USERMANUAL_DATA = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_ADMIN_USERMANUAL_DATA';
export const PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FINISHED =
  'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FINISHED';
export const PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FAIL =
  'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FAIL';

export const loadAdminUserManualData = () => async dispatch => {
  dispatch({ type: PRISMIC_LOAD_ADMIN_USERMANUAL_DATA });
  try {
    const response = await getDocumentByCustomType(CUSTOM_TYPE.ADMIN_USER_MANUAL);
    const data = response.results[0].data;
    return dispatch({
      type: PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FINISHED,
      data,
    });
  } catch (error) {
    return dispatch({
      type: PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FAIL,
      error,
    });
  }
};

export default createReducerFromMapping(
  {
    [PRISMIC_LOAD_DATA]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [PRISMIC_LOAD_DATA_FINISHED]: (state, action) => ({
      ...state,
      general: action.data,
      loading: false,
    }),
    [PRISMIC_LOAD_DATA_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
    [PRISMIC_LOAD_ADMIN_USERMANUAL_DATA]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FINISHED]: (state, action) => ({
      ...state,
      admin: action.data,
      loading: false,
    }),
    [PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
