import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinnerSpin = keyframes`
    from    { transform:rotate(0deg);   }
    to      { transform:rotate(360deg); }
`;

const dashLength = {
  large: 0.9,
  medium: 1,
  small: 1.05,
};

function dashAdjust(key) {
  if (key in dashLength) {
    return dashLength[key];
  }
  console.log('Unknown `#{$key}` in dashLength.');
  return dashLength.medium;
}

const Spinner = styled.span`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: inline-block;
  animation: ${spinnerSpin} 1s linear infinite;
  transform: translateZ(0);
`;

const Circle = styled.circle`
  fill: transparent;
  stroke-linecap: butt;
  stroke: ${props =>
    (props.colour === loaderColor.BLUE && props.theme.colors.blue) ||
    (props.colour === loaderColor.WHITE && props.theme.colors.white) ||
    (props.colour === loaderColor.BLACK && props.theme.colors.black) ||
    (props.colour === loaderColor.CERULEAN && props.theme.colors.cerulean) ||
    (props.colour === loaderColor.GRAY && props.theme.colors.rockBlue)};
  stroke-width: ${props => props.size / 8};

  // Dash length 3/4 of the circumference of the circle, adjusted to size.
  stroke-dasharray: ${props => (21 / 32) * props.size * Math.PI * dashAdjust(props.sizeVariant)};
  transform-origin: ${props => 0.5 * props.size}px ${props => 0.5 * props.size}px 0;
`;

const loaderSize = {
  LARGE: 48,
  MEDIUM: 36,
  SMALL: 16,
};

const loaderColor = {
  BLUE: 'blue',
  WHITE: 'white',
  BLACK: 'black',
  GRAY: 'gray',
  CERULEAN: 'cerulean',
};

const sizeMap = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const Loader = props => {
  const size = loaderSize[props.size] || loaderSize.MEDIUM;
  const color = loaderColor[props.color] || loaderColor.BLUE;
  const sizeClass = sizeMap[props.size] || sizeMap.MEDIUM;
  const alt = props.alt || 'Loading';

  return (
    <div data-test-id="Loader">
      <Spinner size={size}>
        <svg viewBox={`0 0 ${size} ${size}`}>
          <title>{alt}</title>
          <Circle
            cx={`${size / 2}`}
            cy={`${size / 2}`}
            r={`${(7 * size) / 16}`}
            size={size}
            colour={color}
            sizeVariant={sizeClass}
          />
        </svg>
      </Spinner>
    </div>
  );
};

export default Loader;
