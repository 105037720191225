import React from 'react';
import PropTypes from 'prop-types';

const Svg = props => {
  return (
    <svg width="1em" height="1em" {...props}>
      <use xlinkHref={`#${props.name}.inline`} />
    </svg>
  );
};

Svg.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Svg;
