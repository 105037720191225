import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { assign } from 'lodash';
import moment from 'moment-timezone';
import translations from 'decorators/Translations/translations';
import hashCode from 'utils/Hash/hashCode';

import styled from 'styled-components';
import Svg from 'components/Svg/Svg';
import { saveProfile, setTOSStatus } from 'redux/modules/index.js';

const ModalAdapter = ({ className, modalClassName, ...props }) => (
  <Modal className={modalClassName} portalClassName={className} {...props} />
);

const StyledModal = styled(ModalAdapter).attrs(() => ({
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
  modalClassName: {
    base: 'Modal',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
}))`
  .Modal {
    position: absolute;
    top: 40px;
    left: 50%;
    bottom: 40px;
    background-color: ${props => props.theme.colors.white};
    transform: translateX(-50%);
    border-radius: 4px;
    box-shadow: 0 0 24px 0 rgba(51, 51, 51, 0.1);
    overflow: hidden;
    width: 800px;
    max-width: 100%;
  }

  .Overlay {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.colors.lightGray};
    z-index: ${props => props.theme.zIndex('tos')};
    transition: opacity 800ms ease-in-out;

    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }
  }
`;

const Terms = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TermsHeading = styled.div`
  display: flex;
  align-items: center;
  padding: 2em 2em 1em;
`;

const TermsTitle = styled.div`
  font-size: 1.25em;
  font-family: ${props => props.theme.font.family.sanchez};
  margin-top: 8px;
  margin-left: auto;
  text-align: right;
`;

const TermsContent = styled.div`
  display: flex;
  margin: 2em 0;
  padding: 0 2em;
  white-space: pre-line;
  overflow: auto;
  flex: 1;
`;

const TermsButtons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 2em 1em;
  align-items: center;
`;

const Button = styled.button`
    font-size: 16px;
    font-family: 'Sanchez-Regular', serif;
    padding: 1em;
    background-color: transparent;
    border: 1px solid;
    border-radius: 4px;
    cursor: pointer;
    width: 35%;

    ${props =>
      props.decline &&
      `
        color: ${props.theme.colors.radicalRed};`}
    ${props =>
      props.accept &&
      `
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.emerald};`}
    ${props => props.close && 'margin-left: auto;'}
`;

class ToS extends Component {
  state = { isOpen: false };

  componentDidMount() {
    this.checkIfShouldShowToS();
  }

  componentDidUpdate() {
    this.checkIfShouldShowToS();
  }

  checkIfShouldShowToS() {
    // Skip if already open
    if (this.state.isOpen) {
      return;
    }

    if (this.props.t('tos_text') !== 'tos_text') {
      const currentHash = this.props.profile.acceptedTerms || '';
      const translationHash = hashCode(this.props.t('tos_text'));

      if (currentHash !== translationHash) {
        this.setState({ isOpen: true });
      }
    }
    if (this.props.tosOpen) {
      this.setState({ isOpen: true });
    }
  }

  closeTOS = () => {
    if (this.props.tosOpen) {
      this.setState({ isOpen: false });
      this.props.setTOSStatus(false);
    }
  };

  render() {
    const { t, profile, tosOpen } = this.props;
    const editedProfile = assign({}, profile, {
      acceptedTerms: hashCode(t('tos_text')),
      termsAcceptedDate: moment.utc(),
    });

    return (
      <StyledModal
        isOpen={this.state.isOpen}
        contentLabel={t('Terms and Conditions')}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={600}
        onRequestClose={this.closeTOS}
      >
        <Terms>
          <TermsHeading>
            <Svg name="caverion_logo" height={50} width={170} style={{ fill: '#002F6C' }} />
            <TermsTitle>{t('Terms and Conditions')}</TermsTitle>
          </TermsHeading>
          <hr />
          <TermsContent>{t('tos_text')}</TermsContent>
          <hr style={{ marginTop: 'auto' }} />
          <TermsButtons>
            {!tosOpen && (
              <Button decline onClick={() => this.props.history.push('/Logout')}>
                {t('Decline the terms')}
              </Button>
            )}
            {!tosOpen && (
              <Button
                accept
                data-test-id="AcceptToSButton"
                onClick={() =>
                  this.props.saveProfile(profile.id, editedProfile).then(() => this.setState({ isOpen: false }))
                }
              >
                {t('Accept the terms')}
              </Button>
            )}
            {tosOpen && (
              <Button close onClick={this.closeTOS}>
                {t('Close')}
              </Button>
            )}
          </TermsButtons>
        </Terms>
      </StyledModal>
    );
  }
}

Modal.setAppElement('#root');

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    tosOpen: state.navigation.tosOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveProfile: (id, data) => dispatch(saveProfile(id, data)),

    setTOSStatus: open => dispatch(setTOSStatus(open)),
  };
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(withRouter(ToS)));
