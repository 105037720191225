import { createReducerFromMapping } from 'redux/utils/index.js';
import { Document } from '@caverion/redux/api/actions';
import FileSaver from 'file-saver';

const initialState = {
  functionalLocations: {},
  partners: {},
  error: null,
  downloadingFolder: false,
};

export const LOAD_FOLDERS = 'CUSTOMER_PLATFORM/Folder/LOAD_FOLDERS';
export const LOAD_FOLDERS_SUCCESS = 'CUSTOMER_PLATFORM/Folder/LOAD_FOLDERS_SUCCESS';
export const LOAD_FOLDERS_FAIL = 'CUSTOMER_PLATFORM/Folder/LOAD_FOLDERS_FAIL';

export const loadFolders = (functionalLocation, noCache) => {
  // TODO: Proper cache invalidation
  const filter = {
    where: {
      functionalLocation: functionalLocation.functionalLocation,
    },
    timestamp: noCache ? Date.now() : undefined,
  };

  return async dispatch => {
    dispatch({ type: LOAD_FOLDERS, functionalLocation: functionalLocation.functionalLocation });
    try {
      const result = await dispatch(Document.folders(JSON.stringify(filter)));

      return dispatch({
        type: LOAD_FOLDERS_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FOLDERS_FAIL,
        functionalLocation: functionalLocation.functionalLocation,
        error,
      });
    }
  };
};

export const LOAD_PARTNER_FOLDERS = 'CUSTOMER_PLATFORM/Folder/LOAD_PARTNER_FOLDERS';
export const LOAD_PARTNER_FOLDERS_SUCCESS = 'CUSTOMER_PLATFORM/Folder/LOAD_PARTNER_FOLDERS_SUCCESS';
export const LOAD_PARTNER_FOLDERS_FAIL = 'CUSTOMER_PLATFORM/Folder/LOAD_PARTNER_FOLDERS_FAIL';

export const loadPartnerFolders = (partnerNumber, noCache) => {
  // TODO: Proper cache invalidation
  return async dispatch => {
    dispatch({ type: LOAD_PARTNER_FOLDERS, partnerNumber });
    try {
      const result = await dispatch(Document.foldersForPartner(partnerNumber, noCache ? Date.now() : undefined));

      return dispatch({
        type: LOAD_PARTNER_FOLDERS_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_PARTNER_FOLDERS_FAIL,
        partnerNumber,
        error,
      });
    }
  };
};

export const UPSERT_FOLDER = 'CUSTOMER_PLATFORM/Folder/UPSERT_FOLDER';
export const UPSERT_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/UPSERT_FOLDER_SUCCESS';
export const UPSERT_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/UPSERT_FOLDER_FAIL';

export const upsertFolder = (folder, functionalLocation) => {
  const filter = {
    where: {
      functionalLocation: functionalLocation.functionalLocation,
    },
  };

  return async dispatch => {
    dispatch({ type: UPSERT_FOLDER });
    try {
      const result = await dispatch(Document.upsertFolder(folder, filter));
      return dispatch({ type: UPSERT_FOLDER_SUCCESS, folderId: result.id });
    } catch (error) {
      return dispatch({
        type: UPSERT_FOLDER_FAIL,
        functionalLocation: functionalLocation.functionalLocation,
        error,
      });
    }
  };
};

export const UPSERT_PARTNER_FOLDER = 'CUSTOMER_PLATFORM/Folder/UPSERT_PARTNER_FOLDER';
export const UPSERT_PARTNER_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/UPSERT_PARTNER_FOLDER_SUCCESS';
export const UPSERT_PARTNER_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/UPSERT_PARTNER_FOLDER_FAIL';

export const upsertPartnerFolder = folder => {
  return async dispatch => {
    dispatch({ type: UPSERT_PARTNER_FOLDER });
    try {
      await dispatch(Document.upsertPartnerFolder(folder));
      return dispatch({ type: UPSERT_PARTNER_FOLDER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPSERT_PARTNER_FOLDER_FAIL,
        error,
      });
    }
  };
};

export const DELETE_FOLDER = 'CUSTOMER_PLATFORM/Folder/DELETE_FOLDER';
export const DELETE_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/DELETE_FOLDER_FAIL';

export const deleteFolder = id => {
  return async dispatch => {
    dispatch({ type: DELETE_FOLDER });
    try {
      await dispatch(Document.deleteFolder(id));
      return dispatch({ type: DELETE_FOLDER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPSERT_FOLDER_FAIL,
        id,
        error,
      });
    }
  };
};

export const DOWNLOAD_FOLDER = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_FOLDER';
export const DOWNLOAD_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_FOLDER_SUCCESS';
export const DOWNLOAD_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_FOLDER_FAIL';

export const downloadFolder = (folderId, folderName, functionalLocation) => {
  const filter = {
    where: {
      functionalLocation: functionalLocation.functionalLocation,
    },
  };

  return async dispatch => {
    dispatch({ type: DOWNLOAD_FOLDER });
    try {
      const result = await dispatch(Document.downloadFolder(JSON.stringify(filter), folderId));
      FileSaver.saveAs(result, `${folderName}.zip`);

      return dispatch({ type: DOWNLOAD_FOLDER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_FOLDER_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_PARTNER_FOLDER = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_PARTNER_FOLDER';
export const DOWNLOAD_PARTNER_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_PARTNER_FOLDER_SUCCESS';
export const DOWNLOAD_PARTNER_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_PARTNER_FOLDER_FAIL';

export const downloadPartnerFolder = (folderId, folderName, partnerNumber) => {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_PARTNER_FOLDER });
    try {
      const result = await dispatch(Document.downloadPartnerFolder(partnerNumber, folderId));
      FileSaver.saveAs(result, `${folderName}.zip`);

      return dispatch({ type: DOWNLOAD_PARTNER_FOLDER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_PARTNER_FOLDER_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_FOLDERS]: (state, action) => ({
      ...state,
    }),
    [LOAD_FOLDERS_SUCCESS]: (state, action) => ({
      ...state,
      functionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: action.result,
      },
    }),
    [LOAD_FOLDERS_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [LOAD_PARTNER_FOLDERS]: (state, action) => ({
      ...state,
    }),
    [LOAD_PARTNER_FOLDERS_SUCCESS]: (state, action) => ({
      ...state,
      partners: {
        ...state.partners,
        [action.partnerNumber]: action.result,
      },
    }),
    [LOAD_PARTNER_FOLDERS_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPSERT_FOLDER]: (state, action) => ({
      ...state,
    }),
    [UPSERT_FOLDER_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [UPSERT_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPSERT_PARTNER_FOLDER]: (state, action) => ({
      ...state,
    }),
    [UPSERT_PARTNER_FOLDER_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [UPSERT_PARTNER_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [DELETE_FOLDER]: (state, action) => ({
      ...state,
    }),
    [DELETE_FOLDER_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [DELETE_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [DOWNLOAD_FOLDER]: (state, action) => ({
      ...state,
      downloadingFolder: true,
    }),
    [DOWNLOAD_FOLDER_SUCCESS]: (state, action) => ({
      ...state,
      downloadingFolder: false,
    }),
    [DOWNLOAD_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
      downloadingFolder: false,
    }),

    [DOWNLOAD_PARTNER_FOLDER]: (state, action) => ({
      ...state,
      downloadingFolder: true,
    }),
    [DOWNLOAD_PARTNER_FOLDER_SUCCESS]: (state, action) => ({
      ...state,
      downloadingFolder: false,
    }),
    [DOWNLOAD_PARTNER_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
      downloadingFolder: false,
    }),
  },
  initialState
);
