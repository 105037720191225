import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { map, find, isNil, includes } from 'lodash';
import memoizeOne from 'memoize-one';

import InputDate from 'components/Form/InputDate';
import InputLabel, { Label } from 'components/Form/InputLabel';
import Svg from 'components/Svg/Svg';
import ButtonGroup, { ButtonGroupButton } from './ButtonGroup';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  ${props => props.theme.media.portrait`
        justify-content: ${({ alignment }) => {
          if (alignment === 'left') {
            return 'flex-start';
          } else if (alignment === 'right') {
            return 'flex-end';
          }
          return 'center';
        }};
    `}
`;
Container.displayName = 'Container';

const QuickRangeWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin-top: ${props => props.arrows && props.theme.spacing.md};
    margin-bottom: ${props => !props.arrows && props.theme.spacing.md};

    ${props => props.theme.media.portrait`
        margin-top: 0;
        margin-right: ${props => !props.arrows && props.theme.spacing.md};
        margin-left: ${props => props.arrows && props.theme.spacing.md};
        margin-bottom: ${props => props.theme.spacing.xs};
    `}

    & > ${Label} {
        line-height: 2.5em;
    }
`;
QuickRangeWrapper.displayName = 'QuickRangeWrapper';

const RowWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  ${props => props.theme.media.portrait`
        justify-content: center
    `}
`;
RowWrapper.displayName = 'RowWrapper';

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.md};
  &:last-child {
    margin-bottom: ${props => props.theme.spacing.xs};
  }

  ${props => props.theme.media.portrait`
        margin-bottom: ${props => props.theme.spacing.xs};
        width: 200px;
    `}

  ${props => props.theme.media.desktop`
        width: 250px;
    `}

    & > label {
    width: 100%;
    display: inline-block;
  }

  & > ${Label} {
    line-height: 2.5em;
  }

  .Select-control {
    width: 100%;
    height: 2.5em;
  }
  .Select-value {
    display: flex;
    align-items: center;
  }
`;
InputWrapper.displayName = 'InputWrapper';

const Icon = styled(Svg)`
  fill: ${props => props.theme.colors.abbey};
  font-size: ${props => props.theme.font.size.xxs};
`;
Icon.displayName = 'Icon';

export const DATE_RANGES = {
  HOURS_24: '24 h',
  DAYS_7: '7 d',
  DAYS_30: '30 d',
  DAYS_365: '365 d',
};

export const getDateRanges = memoizeOne(
  timestamp => ({
    [DATE_RANGES.HOURS_24]: {
      value: [
        moment(timestamp)
          .subtract(24, 'hours')
          .startOf('hour'),
        moment(timestamp).endOf('hour'),
      ],
      label: DATE_RANGES.HOURS_24,
      window: [24, 'hours'],
    },
    [DATE_RANGES.DAYS_7]: {
      value: [
        moment(timestamp)
          .subtract(6, 'days')
          .startOf('day'),
        moment(timestamp).endOf('hour'),
      ],
      label: DATE_RANGES.DAYS_7,
      window: [6, 'days'],
    },
    [DATE_RANGES.DAYS_30]: {
      value: [
        moment(timestamp)
          .subtract(29, 'days')
          .startOf('day'),
        moment(timestamp).endOf('hour'),
      ],
      label: DATE_RANGES.DAYS_30,
      window: [29, 'days'],
    },
    [DATE_RANGES.DAYS_365]: {
      value: [
        moment(timestamp)
          .subtract(364, 'days')
          .startOf('day'),
        moment(timestamp).endOf('hour'),
      ],
      label: DATE_RANGES.DAYS_365,
      window: [364, 'days'],
    },
  }),
  (currArgs, prevArgs) => currArgs.length === 1 && prevArgs.length === 1 && currArgs[0].isSame(prevArgs[0], 'hour')
);

const isSameRange = (range1, range2) =>
  !!(range1 && range2 && range1[0].isSame(range2[0], 'day') && range1[1].isSame(range2[1], 'day'));

class DateTools extends PureComponent {
  state = {};
  startInputId = 'DateTools-start-input';
  endInputId = 'DateTools-end-input';

  componentDidMount() {
    const {
      model: { startDatetime, endDatetime },
      defaultRange,
    } = this.props;
    let quickRangeOption;

    if (startDatetime && endDatetime) {
      quickRangeOption = find(getDateRanges(moment()), option =>
        isSameRange(option.value, [startDatetime, endDatetime])
      );
    } else {
      quickRangeOption = find(getDateRanges(moment()), { label: defaultRange });
    }

    const quickRange = quickRangeOption && quickRangeOption.value;

    const window = quickRangeOption ? quickRangeOption.window : [endDatetime.diff(startDatetime, 'days'), 'days'];

    this.setState({
      start: startDatetime || quickRange[0],
      end: endDatetime || quickRange[1],
      quickRange,
      window,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { start, end, quickRange } = this.state;
    const { onChange } = this.props;

    if (!start || !end) {
      return;
    }

    if (!start.isSame(prevState.start)) {
      // Start time should always be beginning of the day, unless user has selected 24h
      const newValue =
        moment()
          .subtract(1, 'days')
          .isSame(start, 'day') && !isNil(quickRange)
          ? start
          : moment(start).startOf('day');
      onChange('startDatetime', moment.utc(newValue));
    }

    if (!end.isSame(prevState.end)) {
      // End time should be end of the day, unless it is current day
      const newValue = moment().isSame(end, 'day') ? moment().endOf('hour') : moment(end).endOf('day');
      onChange('endDatetime', moment.utc(newValue));
    }
  }

  handleQuickRange = (value, window) => this.setState({ start: value[0], end: value[1], quickRange: value, window });
  handleDateChange = (property, value) =>
    this.setState({ [property]: value, quickRange: null }, () =>
      this.setState(oldState => ({ window: [oldState.end.diff(oldState.start, 'days'), 'days'] }))
    );
  handleLeftArrow = () =>
    this.setState(oldState => ({
      start: moment(oldState.start).subtract(...oldState.window),
      end: moment(oldState.end).subtract(...oldState.window),
    }));
  handleRightArrow = () =>
    this.setState(oldState => ({
      start: moment(oldState.start).add(...oldState.window),
      end: moment(oldState.end).add(...oldState.window),
    }));

  render() {
    const { t, children, alignment, placement, showArrows, disabledButtons } = this.props;
    const { quickRange } = this.state;

    return (
      <Container alignment={alignment}>
        <QuickRangeWrapper>
          <InputLabel text={t('Time scale')} />
          <ButtonGroup>
            {map(getDateRanges(moment()), ({ value, label, window }, key) => {
              if (disabledButtons && includes(disabledButtons, key)) {
                return null;
              }
              return (
                <ButtonGroupButton
                  onClick={() => this.handleQuickRange(value, window)}
                  selected={isSameRange(value, quickRange)}
                  key={label}
                >
                  {label}
                </ButtonGroupButton>
              );
            })}
          </ButtonGroup>
        </QuickRangeWrapper>
        <RowWrapper>
          <InputWrapper>
            <InputLabel text={t('From')} />
            <InputDate
              fixedHeight
              showIcon
              property="start"
              id={this.startInputId}
              model={this.state}
              onChange={this.handleDateChange}
              clearable={false}
              selectsStart
              startDateProperty="start"
              endDateProperty="end"
              placement={placement}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel text={t('To')} />
            <InputDate
              fixedHeight
              showIcon
              property="end"
              id={this.endInputId}
              model={this.state}
              onChange={this.handleDateChange}
              clearable={false}
              selectsEnd
              startDateProperty="start"
              endDateProperty="end"
              placement={placement}
            />
          </InputWrapper>
        </RowWrapper>
        {showArrows && (
          <QuickRangeWrapper arrows>
            <InputLabel text="" hideOnMobile />
            <ButtonGroup>
              <ButtonGroupButton thin onClick={this.handleLeftArrow} selected={false}>
                <Icon name="ion-arrow-left" />
              </ButtonGroupButton>
              <ButtonGroupButton thin onClick={this.handleRightArrow} selected={false}>
                <Icon name="ion-arrow-right" />
              </ButtonGroupButton>
            </ButtonGroup>
          </QuickRangeWrapper>
        )}
        {children}
      </Container>
    );
  }
}

export default DateTools;

DateTools.defaultProps = {
  alignment: 'center',
  placement: 'top',
  showArrows: false,
};

DateTools.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultRange: PropTypes.string.isRequired,
  alignment: PropTypes.string,
  placement: PropTypes.string,
  showArrows: PropTypes.bool,
  disabledButtons: PropTypes.arrayOf(PropTypes.string),
};
