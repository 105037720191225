import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import styled, { withTheme } from 'styled-components';

import { GroupedServiceOrders } from '../../types';
import { OrderType, OrderStatus, SyntheticOrderStatus } from 'constants/serviceCalendar';
import StatisticsBarChart from 'components/Charts/StatisticsBarChart';
import StatusMeter from 'components/StatusMeter/StatusMeter';
import * as utils from '../../utils';
import StatisticsChartContainer from '../../StatisticsChartContainer/StatisticsChartContainer';
import StatisticsHelp from 'containers/Application/ServiceOrderStatistics/StatisticsHelp/StatisticsHelp';
import { percentage } from 'utils/math';
import { getPlannedMaintenanceStatus } from 'utils/Data/serviceOrders';
import _ from 'lodash';

const calculateStatistics = memoizeOne(utils.calculateMonthlyStatistics);
const getSeries = memoizeOne(utils.getSeriesFromMonthlyStatistics);

const PerformanceItems = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: ${props => props.theme.spacing.md};
`;

const PlannedPerformanceRatio = ({ t, serviceOrders, theme, yearControls }) => {
  const planned = serviceOrders[OrderType.PLANNED];
  const stats = calculateStatistics(planned, 'plannedDate', getPlannedMaintenanceStatus, 'modifiedDate');

  // Remove COMPLETED_OTHER if there is no data
  const hasOtherCompleted = stats.year[SyntheticOrderStatus.COMPLETED_OTHER] > 0;
  const plannedStatuses = hasOtherCompleted
    ? utils.plannedStatuses
    : _.without(utils.plannedStatuses, SyntheticOrderStatus.COMPLETED_OTHER);

  const series = getSeries(stats.monthly, plannedStatuses, t);
  const total = utils.getAggregateTotal(stats.year, plannedStatuses);

  const open = stats.year[SyntheticOrderStatus.UNFINISHED] || 0;
  const completed = stats.year[SyntheticOrderStatus.COMPLETED_OTHER] || 0;
  const completedOnTime = stats.year[OrderStatus.COMPLETED_ON_TIME] || 0;
  const completedEarly = stats.year[OrderStatus.COMPLETED_EARLY] || 0;
  const completedLate = stats.year[OrderStatus.COMPLETED_LATE] || 0;
  const overdue = stats.year[OrderStatus.OVERDUE] || 0;

  return (
    <React.Fragment>
      {!!total && (
        <PerformanceItems>
          <StatusMeter
            t={t}
            title={t('Open / Unfinished')}
            subtitle={`${open} ${t('Total')}`}
            value={Math.round(percentage(open, total))}
            color={theme.serviceOrder.openColor}
          />
          <StatusMeter
            t={t}
            title={t('Completed on Time')}
            subtitle={`${completedOnTime} ${t('Total')}`}
            value={Math.round(percentage(completedOnTime, total))}
            color={theme.colors.midnight}
          />
          <StatusMeter
            t={t}
            title={t('Completed Early')}
            subtitle={`${completedEarly} ${t('Total')}`}
            value={Math.round(percentage(completedEarly, total))}
            color={theme.serviceOrder.inProgressColor}
          />
          <StatusMeter
            t={t}
            title={t('Completed Late')}
            subtitle={`${completedLate} ${t('Total')}`}
            value={Math.round(percentage(completedLate, total))}
            color={theme.serviceOrder.lateColor}
          />
          <StatusMeter
            t={t}
            title={t('Overdue')}
            subtitle={`${overdue} ${t('Total')}`}
            value={Math.round(percentage(overdue, total))}
            color={theme.serviceOrder.overdueColor}
          />
          {!!completed && (
            <StatusMeter
              t={t}
              title={`${t('Completed, other')} *`}
              subtitle={`${completed} ${t('Total')}`}
              value={Math.round(percentage(completed, total))}
              color={theme.serviceOrder.completedColor}
            />
          )}
        </PerformanceItems>
      )}
      <StatisticsChartContainer
        t={t}
        empty={!total}
        count={total}
        countLabel={t('Planned Maintenance')}
        controls={yearControls}
      >
        <StatisticsBarChart
          t={t}
          type="column"
          height={utils.CHART_HEIGHT}
          hideLegend={false}
          categories={utils.getMonths(t)}
          noZoom
          stacked
          plotBorderWidth="0"
          legendAlign="left"
          labelStyle={utils.getLabelStyle(theme)}
          series={series}
          colors={utils.getPlannedColors(theme, !!completed)}
          yTitle={t('Planned Maintenance')}
          hideDecimals
          backgroundColor="transparent"
        />
      </StatisticsChartContainer>
      {!!completed && (
        <StatisticsHelp
          useAsterisk
          heading={t('Completed, other')}
          text={t('contains all the completed planned maintenance that are missing planned date.')}
        />
      )}
    </React.Fragment>
  );
};

PlannedPerformanceRatio.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  serviceOrders: GroupedServiceOrders.isRequired,
  yearControls: PropTypes.node.isRequired,
};

export default withTheme(PlannedPerformanceRatio);
