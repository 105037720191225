import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Footer from 'containers/Application/Footer/Footer';

const Article = styled.article`
    margin: 0;
    padding: ${props => props.theme.navigation.height} 0 0;
    transition: margin-left ${props => props.theme.navigation.transition};
    min-height: calc(100vh - 166px);
    background-color: ${props => !props.noBackground && props.theme.colors.white};
    display: flex;
    flex-direction: column;

    &::after {
        content: "";
        display: table;
        clear: both;
    }

    ${props => props.theme.media.portrait`
        min-height: calc(100vh - 166px - 48px);
        background-color: ${props => !props.noBackground && props.theme.colors.alabaster};
        margin: 0 ${props => (props.noMargin ? 0 : props.theme.navigation.tabletSpacing)} ${props =>
      props.noMargin ? 0 : '3rem'};
    `}

    ${props => props.theme.media.landscape`
        padding: calc(${props => props.theme.navigation.height} + ${props => props.theme.navigation.tabletSpacing}) 0 0;
        margin-left: ${props =>
          !props.noMargin &&
          `calc(${
            props.naviExtended ? props.theme.navigation.sideTabletWidth : props.theme.navigation.sideSmallWidth
          } + ${props.theme.navigation.tabletSpacing})`};
    `}

    ${props => props.theme.media.desktop`
        min-height: ${props => `calc(100vh - 106px - ${props.noMargin ? '0px' : '48px'})`};
        padding: calc(${props => props.theme.navigation.height} + ${props => props.theme.navigation.spacing}) 0 0;
        margin: 0 ${props => (props.noMargin ? 0 : props.theme.navigation.spacing)} ${props =>
      props.noMargin ? 0 : '3rem'};
        margin-left: ${props =>
          !props.noMargin &&
          `calc(${props.naviExtended ? props.theme.navigation.sideWidth : props.theme.navigation.sideSmallWidth} + ${
            props.theme.navigation.spacing
          })`};
    `};
`;

const StandardPage = props => {
  const renderScrollComponent = (disableScrollToTop, scrollToTopOnMount) => {
    if (!disableScrollToTop) {
      return <ScrollToTop mountOnly={scrollToTopOnMount} />;
    }
  };

  const {
    naviExtended,
    noMargin,
    noBackground,
    noFooter,
    location: { pathname },
  } = props;

  const withTabs = props.withTabs && queryString.parse(props.location.search).noNavi !== 'true';

  return (
    <Fragment>
      <Article noMargin={noMargin} withTabs={withTabs} naviExtended={naviExtended} noBackground={noBackground}>
        {renderScrollComponent(props.disableScrollToTop, props.scrollToTopOnMount)}
        {props.children}
      </Article>
      {!noFooter && <Footer pathname={pathname} noMargin={noMargin} />}
    </Fragment>
  );
};

StandardPage.defaultProps = {
  disableScrollToTop: false,
  scrollToTopOnMount: false,
  withTabs: false,
  noMargin: false,
};

StandardPage.propTypes = {
  disableScrollToTop: PropTypes.bool,
  scrollToTopOnMount: PropTypes.bool,
  withTabs: PropTypes.bool,
  noMargin: PropTypes.bool,
};

const mapStateToProps = state => ({
  naviExtended: state.navigation.sideNaviExtended,
});

const connector = connect(mapStateToProps);

export default withRouter(connector(StandardPage));
