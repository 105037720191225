import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import { connect } from 'react-redux';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import map from 'lodash/map';
import remove from 'lodash/remove';
import includes from 'lodash/includes';

import { Button } from 'components/index.js';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import DocumentFile from './DocumentFile';
import DocumentForm from './DocumentForm';
import InfoBox from 'components/InfoBox/InfoBox';
import { getFolderOptions, findIdPath } from 'utils/Data/documents';
import {
  uploadFile,
  updateFile,
  loadFiles,
  uploadPartnerFile,
  updatePartnerFile,
  loadPartnerFiles,
} from 'redux/modules/index';

const Container = styled.section`
  form {
    display: flex;
    flex-direction: column;
  }
`;

const StyledHeader = styled.h3``;

const ErrorBox = styled(InfoBox)`
  margin: ${props => props.theme.spacing.xs} 0;
`;

export const MAX_SIZE = 104857600; // 100MiB

const calcInitStep = fileSize => {
  if (!fileSize) {
    return undefined;
  }
  const mebibytes = fileSize / 1048576;
  const approximateStep = 0.3 * Math.exp(-1.5 * mebibytes);
  return Math.max(approximateStep, 0.001);
};

const DocumentManager = props => {
  const {
    t,
    onClose,
    onSubmit,
    isCreateMode,
    document,
    folders,
    isCaverionAdmin,
    onNewFolder,
    uploadFile,
    updateFile,
    loadFiles,
    functionalLocation,
    partnerNumber,
    folderId,
  } = props;

  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [model, setModel] = useState(isEmpty(document) ? [] : [clone(document)]);
  const [uploaded, setUploaded] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [loadingFile, setLoadingFile] = useState(null);
  const [erroredIndices, setErroredIndices] = useState([]);

  useEffect(
    () => {
      if (folderId) {
        const newModel = model.map(file => ({ ...file, folderId: folderId }));
        setModel(newModel);
      }
    },
    [folderId]
  );

  const handleUpload = useCallback(
    async () => {
      if (isEmpty(model)) {
        return setErrorMessages([t('Select file')]);
      }

      if (model.length !== uploadedCount) {
        setLoading(true);
        setErroredIndices([]);
        const errored = [];

        for (let index = 0; index < model.length; index++) {
          if (!uploaded || includes(erroredIndices, index)) {
            setLoadingFile(index);
            const file = model[index];
            const response = await uploadFile({
              ...file,
              functionalLocation: functionalLocation && functionalLocation.functionalLocation,
              partnerNumber,
            });
            if (response.error) {
              errored.push(index);
              setErroredIndices(errored);
            }
          }
        }

        setLoadingFile(null);
        setUploaded(true);
        setUploadedCount(model.length - errored.length);

        if (!isEmpty(errored)) {
          return setLoading(false);
        }
      }

      return handleClosing(true);
    },
    [model, erroredIndices, uploaded, uploadedCount]
  );

  const handleUpdate = useCallback(
    async () => {
      setLoading(true);
      setLoadingFile(0);
      setErroredIndices([]);

      const file = model[0];

      // Use null instead of empty string or 0
      if (!file.folderId) {
        file.folderId = null;
      }

      const response = await updateFile(file.id, file);

      setLoadingFile(null);
      setUploaded(true);

      if (response.error) {
        setErroredIndices([0]);
        setLoading(false);
        return;
      }
      return handleClosing(true);
    },
    [model]
  );

  const handleFormChange = useCallback(
    ([index, property], value) => {
      // Set the value for all files since the selections are common
      const newModel = model.map(file => ({ ...file, [property]: value }));
      setModel(newModel);
    },
    [model]
  );

  const handleFileUpload = useCallback(
    files => {
      setUploaded(false);
      setErrorMessages(null);

      let filesToAdd = files;
      let errors = [];
      const overSizedFiles = map(filter(files, file => file.size > MAX_SIZE), 'name');
      const missingMimeTypeFiles = map(filter(files, file => !file.type), 'name');

      if (!isEmpty(overSizedFiles)) {
        errors = errors.concat(overSizedFiles.map(file => `${t('File is too big')}: ${file}`));
        filesToAdd = filter(filesToAdd, file => file.size <= MAX_SIZE);
      }

      if (!isEmpty(missingMimeTypeFiles)) {
        errors = errors.concat(missingMimeTypeFiles.map(file => `${t('File extension missing')}: ${file}`));
        filesToAdd = filter(filesToAdd, file => file.type);
      }

      if (!isEmpty(errors)) {
        setErrorMessages(errors);
      }

      const newModel = [...model];
      let fileCounter = 0;

      forEach(filesToAdd, file => {
        const filename = file.name;
        const mimeType = file.type;
        const size = file.size;

        const reader = new FileReader();

        reader.onload = event => {
          if (event.target.result) {
            let fileString = event.target.result;
            if (fileString.indexOf(',') !== -1) {
              fileString = fileString.split(',')[1];
            }
            const data = {
              file: fileString,
              filename,
              mimeType,
              size,
              folderId,
            };

            newModel.push(data);
            fileCounter++;

            if (fileCounter === filesToAdd.length) {
              setModel(newModel);
            }
          }
        };

        reader.readAsDataURL(file);
      });
    },
    [model]
  );

  const handleNewFolderClick = useCallback(event => {
    event.preventDefault();
    onNewFolder();
  }, []);

  const handleFileRemoval = useCallback(
    fileIndex => {
      setModel(remove(model, (file, index) => index !== fileIndex));
    },
    [model]
  );

  const handleClosing = useCallback(
    async hasSubmitted => {
      if (hasSubmitted) {
        setLoading(true);
        await loadFiles();

        const notification = {
          notificationType: 'success',
          notificationVisible: true,
          notificationMessage: isCreateMode ? t('Document successfully saved') : t('Document successfully updated'),
        };

        return onSubmit(notification, false, null, findIdPath(model[0].folderId, folders));
      }
      onClose();
    },
    [model, folders]
  );

  const folderOptions = getFolderOptions(folders);

  return (
    <DialogModal
      isActive
      onOverlayClick={() => handleClosing(uploadedCount > 0)}
      t={t}
      footer={
        <DialogFooter>
          <Button cancel onClick={() => handleClosing(uploadedCount > 0)}>
            {t('Cancel')}
          </Button>
          <Button submit loading={loading} form="documentForm">
            {isCreateMode
              ? uploaded
                ? model.length === uploadedCount
                  ? t('Close')
                  : t('Retry')
                : t('Upload')
              : t('Save')}
          </Button>
        </DialogFooter>
      }
    >
      <Container>
        <StyledHeader>{isCreateMode ? t('Upload files') : t('Edit file')}</StyledHeader>
        <DocumentForm
          t={t}
          formId="documentForm"
          model={model}
          onChange={handleFormChange}
          onSubmit={isCreateMode ? handleUpload : handleUpdate}
          onFileUpload={handleFileUpload}
          onNewFolderClick={handleNewFolderClick}
          folderOptions={folderOptions}
          isCaverionAdmin={isCaverionAdmin}
          isCreateMode={isCreateMode}
        >
          {!isEmpty(model) && (
            <ul>
              {model.map((file, index) => (
                <DocumentFile
                  key={`${index}-${file.filename || file.name}`}
                  file={file}
                  index={index}
                  onFileRemoval={handleFileRemoval}
                  isCreateMode={isCreateMode}
                  loading={loadingFile === index}
                  full={!includes(erroredIndices, index) && ((loadingFile > index && loading) || uploaded)}
                  hasErrored={includes(erroredIndices, index)}
                  initStep={calcInitStep(file.size)}
                  allLoading={loading}
                />
              ))}
            </ul>
          )}
          {!isEmpty(erroredIndices) && (
            <ErrorBox error>
              <b>{t('There was a problem when uploading.')} </b>
              {t('Please try again or one file at a time.')}
            </ErrorBox>
          )}
        </DocumentForm>
        {errorMessages && (
          <InfoBox error>
            <ul>
              {errorMessages.map(error => (
                <li>{error}</li>
              ))}
            </ul>
          </InfoBox>
        )}
      </Container>
    </DialogModal>
  );
};

DocumentManager.defaultProps = {
  isCreateMode: true,
  isCaverionAdmin: false,
};

DocumentManager.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  folders: PropTypes.array.isRequired,
  document: PropTypes.object.isRequired,
  onNewFolder: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  updateFile: PropTypes.func.isRequired,
  loadFiles: PropTypes.func.isRequired,
  isCreateMode: PropTypes.bool,
  isCaverionAdmin: PropTypes.bool,
  folderId: PropTypes.number,
  functionalLocation: PropTypes.object,
  partnerNumber: PropTypes.string,
  t: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadFile: data =>
    ownProps.partnerNumber
      ? dispatch(uploadPartnerFile(data))
      : dispatch(uploadFile(data, ownProps.functionalLocation)),

  updateFile: (fileId, file) =>
    ownProps.partnerNumber
      ? dispatch(updatePartnerFile(fileId, file))
      : dispatch(updateFile(fileId, file, ownProps.functionalLocation)),

  loadFiles: () =>
    ownProps.partnerNumber
      ? dispatch(loadPartnerFiles(ownProps.partnerNumber, true))
      : dispatch(loadFiles(ownProps.functionalLocation, true)),
});

const connector = connect(
  null,
  mapDispatchToProps
);

export default connector(translations(DocumentManager));
