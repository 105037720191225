import moment from 'moment-timezone';

const getServiceOrderStartDate = (serviceOrderStartDate, start, end) => {
  const startDate = serviceOrderStartDate && moment.utc(serviceOrderStartDate).startOf('day');

  if (startDate) {
    if (startDate.isAfter(end)) {
      return null;
    }
    if (startDate.isAfter(start)) {
      return startDate;
    }
  }

  return start;
};

export default getServiceOrderStartDate;
