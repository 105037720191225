import React, { Fragment, PureComponent } from 'react';
import translations from 'decorators/Translations/translations';
import connect from './connectEnergyModule';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import EnergyBreakdown from 'components/Modules/EnergyModule/EnergyBreakdown/EnergyBreakdown';
import { energyBreakdownTypes, isEnergyRatingSensor } from 'utils/Data/values';
import OPICard, { TREND_TYPE } from 'components/OPICard/OPICard';
import OPICards from 'components/OPICard/OPICards';
import Observations from 'components/Observations';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import Skeletons from 'components/Skeletons';
import {
  getEnergyValues,
  getTotalRawValues,
  getEnergyRatingValues,
  getOutdoorsTemperatureData,
} from './EnergyModuleUtils';

import withQuery from 'decorators/Query/withQuery';
import { OUTDOOR_TYPE } from 'utils/Data/values';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import EnergyModuleSensorDialog from './EnergyModuleSensorDialog/EnergyModuleSensorDialog';

const NoDataAvailable = styled.p`
  text-align: center;
  margin-top: ${props => props.theme.spacing.md};
`;

NoDataAvailable.displayName = 'NoDataAvailable';

const defaultValues = {
  series: [],
  yearTotals: {},
  years: [],
  categories: [],
  last365DaysConsumption: 0,
};

const DEFAULT_OUTDOORS_TEMPERATURE_KEY = 'default_outdoor_temperature_id';

export class EnergyModule extends PureComponent {
  static displayName = 'EnergyModule';

  static defaultProps = {
    energySensors: [],
    energyValues: {},
    loading: false,
    loadingParent: false,
    energyRatingValuesByFL: {},
  };

  static propTypes = {
    energySensors: PropTypes.array.isRequired,
    functionalLocation: PropTypes.object,
    energyValues: PropTypes.object,
    loading: PropTypes.bool,
    loadingParent: PropTypes.bool,
    buildingMeta: PropTypes.shape({
      meta: PropTypes.object.isRequired,
    }).isRequired,
    loadBuildingMeta: PropTypes.func.isRequired,
    sensorTypes: PropTypes.array,
    buildingSensors: PropTypes.array.isRequired,
    loadEnergyRatingValuesByFL: PropTypes.func.isRequired,
    loadFunctionalLocationsEnergyValues: PropTypes.func.isRequired,
    energyRatingValuesByFL: PropTypes.object,
    valuesBySensorId: PropTypes.object.isRequired,
    loadSensorsValues: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    theme: PropTypes.object,
    query: PropTypes.object,
    setQuery: PropTypes.func,
  };

  state = {
    energyRatingValues: {},
    valuesByType: {},
    totalValues: {
      ...defaultValues,
    },
    outdoorsTemperatureData: {},
    sensorId: null,
  };

  componentDidMount() {
    if (!this.props.functionalLocation || this.props.loadingParent) {
      return;
    }

    this.props.loadFunctionalLocationsEnergyValues(null, [this.props.functionalLocation]);
    this.props.loadEnergyRatingValuesByFL([this.props.functionalLocation.functionalLocation]); // energy rating for opi cards
    this.props.loadBuildingMeta([this.props.functionalLocation.functionalLocation], false);
    this.loadOutdoorsTemperatureValues();
  }

  // Listen to value updates
  componentDidUpdate(prevProps, prevState) {
    if (this.props.loadingParent) {
      return;
    }
    const {
      energyValues,
      energySensors,
      theme,
      buildingMeta: { meta },
      functionalLocation: { functionalLocation },
      t,
      buildingSensors,
      valuesBySensorId,
    } = this.props;

    // update energy after loading energy values
    if (energyValues !== prevProps.energyValues) {
      this.updateEnergyValues(energyValues, energySensors, theme, meta, functionalLocation);
    }

    // load outdoors temperature values
    if (meta !== prevProps.buildingMeta.meta) {
      this.loadOutdoorsTemperatureValues();
    }

    // update outdoors temperature after loaded values
    if (valuesBySensorId !== prevProps.valuesBySensorId) {
      this.updateOutdoorsTemperature(this.getOutdoorsTemperatureSensorValues(), this.props.theme, this.props.t);
    }

    if (!this.hasDefaultOutdoorsTemperatureSensor()) {
      // if buildingSensors change and we rely on condition sensor for outdoors temperature, load sensor values
      if (buildingSensors !== prevProps.buildingSensors) {
        this.loadOutdoorsTemperatureValues();
      }
    }
  }

  // Update state with ready values
  updateEnergyValues = (energyValues, energySensors, theme, meta, functionalLocation) => {
    const valuesByType = {};
    let energyRatingValues = {};
    let totalValues = { ...defaultValues };

    if (energyValues) {
      energyBreakdownTypes.forEach(type => {
        if (energyValues[type] && energyValues[type].length > 0) {
          valuesByType[type] = getEnergyValues(energyValues[type], theme, meta, energySensors);
        }
      });

      const totalRawValues = getTotalRawValues(energyValues);

      if (totalRawValues && totalRawValues.length > 0) {
        totalValues = getEnergyValues(totalRawValues, theme, meta, energySensors);
        valuesByType.energy = totalValues;
      }
      if (energyValues.energyRating && energyValues.energyRating.length > 0) {
        energyRatingValues = getEnergyRatingValues(energyValues.energyRating)[functionalLocation];
      }
    }

    if (valuesByType || totalValues) {
      this.setState({
        energyRatingValues,
        valuesByType,
        totalValues,
      });
    }
  };

  updateOutdoorsTemperature = (outdoorsTemperatureValues, theme, t) => {
    if (outdoorsTemperatureValues && outdoorsTemperatureValues.length > 0) {
      this.setState({
        outdoorsTemperatureData: getOutdoorsTemperatureData(outdoorsTemperatureValues, theme, t),
      });
    }
  };

  get noDataAvailableText() {
    return <NoDataAvailable>{this.props.t('No data available')}</NoDataAvailable>;
  }

  getEnergyRatingSensor = (energySensors, sensorTypes) => {
    const energyRatingType = sensorTypes.find(sensor => isEnergyRatingSensor(sensor.name));
    if (isEmpty(energyRatingType)) {
      return;
    }
    return energySensors.find(sensor => energyRatingType.id === sensor.sensorTypeId);
  };

  getOutdoorsTemperatureSensorValues = () => {
    const sensorId = this.getOutdoorsTemperatureSensorId();
    if (sensorId) {
      return this.props.valuesBySensorId[sensorId] || [];
    }
    return [];
  };

  hasDefaultOutdoorsTemperatureSensor = () => {
    return !!this.getDefaultOutdoorsTemperatureSensorId();
  };

  getDefaultOutdoorsTemperatureSensorId = () => {
    const {
      buildingMeta: { meta },
      functionalLocation: { functionalLocation },
    } = this.props;

    if (meta && meta[functionalLocation]) {
      const metaDataItem = meta[functionalLocation].find(item => item.key === DEFAULT_OUTDOORS_TEMPERATURE_KEY);
      return metaDataItem ? metaDataItem.value : null;
    }
    return null;
  };

  getOutdoorsTemperatureSensorId = () => {
    return this.getDefaultOutdoorsTemperatureSensorId() || this.getOutdoorsTemperatureConditionSensorId();
  };

  getOutdoorsTemperatureConditionSensorId = () => {
    const outdoorsTemperatureSensors = this.props.buildingSensors.filter(
      sensor => sensor.sensorType && sensor.sensorType.name === OUTDOOR_TYPE
    );
    return outdoorsTemperatureSensors.length > 0 && outdoorsTemperatureSensors[0].id;
  };

  loadOutdoorsTemperatureValues = () => {
    const sensorId = this.getOutdoorsTemperatureSensorId();
    if (sensorId) {
      this.props.loadSensorsValues(
        [sensorId],
        moment
          .utc()
          .subtract(2, 'years')
          .startOf('year'),
        moment.utc().endOf('day'),
        'dailyAverage'
      );
    }
  };

  scrollToElement = (scrollTo, tab) => {
    // Scroll to element
    this.setState({
      scrollTo,
    });
    setTimeout(() => this.setState({ scrollTo: null }), 500);
    // Change tab if needed
    if (scrollTo === 'breakdown' && !isNil(tab)) {
      this.props.setQuery({ breakdown: tab });
    }
  };

  showSensor = sensorId => this.setState({ sensorId: sensorId });

  hideSensor = () => this.setState({ sensorId: null });

  renderOpiCards = () => {
    const { energySensors, t, sensorTypes, notices } = this.props;
    const { totalValues, valuesByType = {}, energyRatingValues } = this.state;
    const energyRatingSensor = this.getEnergyRatingSensor(energySensors, sensorTypes);
    const { energy, electricity, districtHeating, waterConsumption } = valuesByType;

    const isOpiCardVisible = type => type && type.last365DaysConsumption && type.last365DaysConsumptionFrom30DaysAgo;
    const getRatingAndPrefixForType = type => {
      const rating = round(
        ((type.last365DaysConsumption - type.last365DaysConsumptionFrom30DaysAgo) /
          type.last365DaysConsumptionFrom30DaysAgo) *
          100
      );
      let prefix = '';
      if (rating > 0) {
        prefix = '+';
      }
      return { rating, prefix };
    };

    const getTrendTypeForRating = rating => {
      if (rating > 0) {
        return TREND_TYPE.NEGATIVE;
      }
      if (rating < 0) {
        return TREND_TYPE.POSITIVE;
      }
      return TREND_TYPE.NEUTRAL;
    };

    // Get OPI cards data
    const opiCards = [];
    // Add total energy consumption
    if (isOpiCardVisible(energy)) {
      const { rating, prefix } = getRatingAndPrefixForType(energy);
      opiCards.push(
        <OPICard
          t={t}
          noCircle
          key="cards-0"
          title={t('Energy consumption')}
          subtitle={t('Last 12 Months')}
          valueInside={energy.last365DaysConsumption}
          valueInsideLabel={energy.unit}
          trend={{
            value: `${prefix}${rating}\u00A0%`,
            type: getTrendTypeForRating(rating),
          }}
          ctxHelpType={'energy consumption'}
          ctxHelp={`${CTXHELP_PREFIX} Energy energy consumption OPI`}
          onClick={() => this.scrollToElement('breakdown', 'energy')}
        />
      );
    }

    if (isOpiCardVisible(electricity)) {
      const { rating, prefix } = getRatingAndPrefixForType(electricity);
      opiCards.push(
        <OPICard
          t={t}
          noCircle
          key="cards-1"
          title={t('Electricity')}
          subtitle={t('Last 12 Months')}
          valueInside={`${prefix}${rating}\u00A0%`} // Non-breaking space between value and %-character
          valueInside={electricity.last365DaysConsumption}
          valueInsideLabel={electricity.unit}
          trend={{
            value: `${prefix}${rating}\u00A0%`,
            type: getTrendTypeForRating(rating),
          }}
          ctxHelp={`${CTXHELP_PREFIX} Energy breakdown electricity OPI`}
          onClick={() => this.scrollToElement('breakdown', 'electricity')}
        />
      );
    }
    if (isOpiCardVisible(districtHeating)) {
      const { rating, prefix } = getRatingAndPrefixForType(districtHeating);
      opiCards.push(
        <OPICard
          t={t}
          noCircle
          key="cards-2"
          title={t('District heating')}
          subtitle={t('Last 12 Months')}
          valueInside={districtHeating.last365DaysConsumption}
          valueInsideLabel={districtHeating.unit}
          trend={{
            value: `${prefix}${rating}\u00A0%`,
            type: getTrendTypeForRating(rating),
          }}
          ctxHelp={`${CTXHELP_PREFIX} Energy breakdown districtHeating OPI`}
          onClick={() => this.scrollToElement('breakdown', 'districtHeating')}
        />
      );
    }
    if (isOpiCardVisible(waterConsumption)) {
      const { rating, prefix } = getRatingAndPrefixForType(waterConsumption);
      opiCards.push(
        <OPICard
          t={t}
          noCircle
          key="cards-3"
          title={t('Water')}
          subtitle={t('Last 12 Months')}
          valueInside={waterConsumption.last365DaysConsumption}
          valueInsideLabel={waterConsumption.unit}
          trend={{
            value: `${prefix}${rating}\u00A0%`,
            type: getTrendTypeForRating(rating),
          }}
          ctxHelp={`${CTXHELP_PREFIX} Energy breakdown waterConsumption OPI`}
          onClick={() => this.scrollToElement('breakdown', 'waterConsumption')}
        />
      );
    }
    // Add energy rating
    if (isOpiCardVisible(energyRatingValues)) {
      const { last365DaysConsumption, unit } = energyRatingValues;

      const { rating, prefix } = getRatingAndPrefixForType(energyRatingValues);
      opiCards.push(
        <OPICard
          t={t}
          noCircle
          key="cards-4"
          title={t('Energy rating')}
          subtitle={t('Yearly consumption')}
          valueInside={last365DaysConsumption}
          valueInsideLabel={unit}
          trend={{
            value: `${prefix}${rating}\u00A0%`,
            type: getTrendTypeForRating(rating),
          }}
          ctxHelp={`${CTXHELP_PREFIX} Energy energy rating OPI`}
          icon={'opi-expand'}
          onClick={energyRatingSensor && (() => this.showSensor(energyRatingSensor.id))}
        />
      );
    }
    if (totalValues && totalValues.last365DaysConsumption) {
      opiCards.push(
        <OPICard
          t={t}
          noCircle
          key="cards-6"
          title={`CO₂ ${t('Emissions')}`}
          subtitle={t('Last 12 Months')}
          valueInside={round(totalValues.last365DaysCO2Emissions / 1000, 1)}
          valueInsideLabel={`CO₂ ${t('tonnes')}`}
          ctxHelp={`${CTXHELP_PREFIX} Energy co2 OPI`}
        />
      );
    }
    if (notices.observations.length > 0) {
      opiCards.push(
        <OPICard
          t={t}
          key="cards-7"
          neutral
          value={notices.completedRatio}
          title={t('Observations')}
          subtitle={`${notices.completedCount} ${t('completed')} / ${notices.totalCount} ${t('Total')}
          (${t('Last 12 Months')})`}
          ctxHelp={`${CTXHELP_PREFIX} Observations OPI`}
        />
      );
    }

    return opiCards.length > 0 && <OPICards>{opiCards}</OPICards>;
  };

  render() {
    const { loading, loadingParent, energySensors, functionalLocation, notices } = this.props;
    const { valuesByType, outdoorsTemperatureData, scrollTo, sensorId } = this.state;

    if (loading || loadingParent) {
      return <Skeletons.SkeletonWithOPIAndChart />;
    } else if (!loading && isEmpty(valuesByType)) {
      return this.noDataAvailableText;
    }

    return (
      <div data-test-id="EnergyModule">
        {this.renderOpiCards()}
        {notices.observations.length > 0 && <Observations observations={notices.observations} />}
        {!isEmpty(valuesByType) && (
          <Fragment>
            {scrollTo === 'breakdown' && <ScrollToComponent />}
            <EnergyBreakdown
              functionalLocation={functionalLocation}
              valuesByType={valuesByType}
              outdoorsTemperatureData={outdoorsTemperatureData}
              handleTabChange={this.handleTabChange}
            />
          </Fragment>
        )}
        <EnergyModuleSensorDialog
          visible={!!sensorId}
          onDialogClose={this.hideSensor}
          functionalLocation={functionalLocation}
          sensorId={sensorId}
          energySensors={energySensors}
        />
      </div>
    );
  }
}

export default withQuery(withTheme(connect(translations(EnergyModule))));
