import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { loadBuildingMeta } from 'redux/modules/buildingConfig/buildingMeta';
import { loadFunctionalLocationsEnergyValues } from 'redux/modules/iot/values/fl_energy_values';
import { loadSensorsValues } from 'redux/modules/iot/values/sensor_values';
import { loadEnergyRating as loadEnergyRatingValuesByFL } from 'redux/modules/iot/values/energy_rating';

const getNoticesFromState = (state, props) => {
  return (
    (props.functionalLocation.functionalLocation &&
      state.notice.byFL[props.functionalLocation.functionalLocation]?.observations) ||
    []
  ).filter(item => item.energyImpact);
};

const noticeSelector = createSelector(
  getNoticesFromState,
  observations => {
    let completedRatio = 100;
    let completedCount = 0;
    if (observations.length > 0) {
      completedCount = observations.filter(item => item.status.status === 'completed').length;
      completedRatio = (completedCount / observations.length) * 100;
    }
    return {
      observations,
      completedCount,
      totalCount: observations.length,
      completedRatio,
    };
  }
);

export const mapStateToProps = (state, props) => ({
  energySensors:
    props.functionalLocation && state.values.flEnergyValues.energySensors[props.functionalLocation.functionalLocation],
  energyValues:
    props.functionalLocation && state.values.flEnergyValues.energyValues[props.functionalLocation.functionalLocation],
  loading: state.values.flEnergyValues.loadingEnergyValues,
  buildingMeta: state.buildingMeta,
  energyRatingValuesByFL: state.values.energyRating.energyRating.data,
  valuesBySensorId: state.values.sensors.valuesBySensorId,
  notices: noticeSelector(state, props),
  loadingSensorValues: state.values.sensors.loadingSensorValues,
});

const mapDispatchToProps = {
  loadFunctionalLocationsEnergyValues,
  loadBuildingMeta,
  loadEnergyRatingValuesByFL,
  loadSensorsValues,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector;
