import React, { Fragment, useState } from 'react';
import { filter, isEmpty } from 'lodash';

import ObservationsTable from 'components/BuildingEvents/Observations/ObservationsTable';
import FilterPill from 'components/FilterPills/FilterPill';
import FilterPills from 'components/FilterPills/FilterPills';
import Section from 'components/Section/Section';
import OPICard from 'components/OPICard/OPICard';
import OPICards from 'components/OPICard/OPICards';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import {
  FilterPillsContainer,
  ShowContainer,
  overrideColumnWidth,
  NoDataAvailable,
  SkeletonFilter,
} from 'components/BuildingEvents/BuildingEvents';

const Observations = props => {
  const { t, observations, loading } = props;

  const [observationFilter, setObservationFilter] = useState({ all: true });
  const [showOnlyOpenObservations, setShowOnlyOpenObservations] = useState(false);

  const toggleFilter = (filter, value, isObservationFilter = true) => {
    if (filter === 'all') {
      setObservationFilter({ all: value });
      return;
    }

    const newValues = Object.assign({}, observationFilter, { [filter]: value, all: false });
    setObservationFilter(newValues);
  };

  let filteredObservations = observationFilter.all
    ? observations
    : filter(observations, observation => observationFilter[observation.system.value]);

  const readyObservations = filter(filteredObservations, observation => observation.status.status === 'completed');
  const totalObservationsCount = filteredObservations.length;

  // Filter out 'completed' observations if 'show only open' is selected
  filteredObservations = filter(
    filteredObservations,
    observation => !showOnlyOpenObservations || observation.status.status !== 'completed'
  );

  return (
    <Fragment>
      {(!isEmpty(observations) || loading) && (
        <FilterPillsContainer>
          {loading ? (
            <FilterPills>
              <SkeletonFilter />
              <SkeletonFilter />
              <SkeletonFilter />
            </FilterPills>
          ) : (
            <FilterPills>
              <FilterPill selected={observationFilter.all} onClick={value => toggleFilter('all', value)}>
                {t('Show All')}
              </FilterPill>
              <FilterPill
                icon="Cooling"
                selected={observationFilter.cooling}
                onClick={value => toggleFilter('cooling', value)}
              >
                {t('Cooling systems')}
              </FilterPill>
              <FilterPill
                icon="Heating"
                selected={observationFilter.heating}
                onClick={value => toggleFilter('heating', value)}
              >
                {t('Heating systems')}
              </FilterPill>
              <FilterPill
                icon="Ventilation"
                selected={observationFilter.ventilation}
                onClick={value => toggleFilter('ventilation', value)}
              >
                {t('Ventilation')}
              </FilterPill>
              <FilterPill
                icon="Other"
                selected={observationFilter.other}
                onClick={value => toggleFilter('other', value)}
              >
                {t('Other')}
              </FilterPill>
            </FilterPills>
          )}
        </FilterPillsContainer>
      )}
      <OPICards>
        <OPICard
          t={t}
          loading={loading}
          title={t('Observations')}
          value={
            readyObservations.length && totalObservationsCount
              ? Math.round((readyObservations.length / totalObservationsCount) * 100)
              : 0
          }
          subtitle={`${readyObservations.length}\u00A0${t('Completed')}\u00A0/\u00A0${totalObservationsCount}\u00A0${t(
            'Total'
          )}\n(${t('Last 365 Days')})`}
          ctxHelp={`${CTXHELP_PREFIX} Observations OPI`}
          neutral
          overrideColumnWidth={overrideColumnWidth}
        />
      </OPICards>
      <Section>
        {isEmpty(observations) && !loading ? (
          <NoDataAvailable>{t('No observations found for the last 365 days.')}</NoDataAvailable>
        ) : (
          <Fragment>
            <ShowContainer>
              <RadioButtonGroup
                data-test-id="BuildingEvents-ObservationsShowAll"
                name="observations-all"
                value={showOnlyOpenObservations}
                onChange={() => setShowOnlyOpenObservations(!showOnlyOpenObservations)}
                row
              >
                <RadioButton value={false} label={t('Show All')} />
                <RadioButton value={true} label={t('Only Open Observations')} />
              </RadioButtonGroup>
            </ShowContainer>
            <ObservationsTable loading={loading} t={t} data={filteredObservations} />
          </Fragment>
        )}
      </Section>
    </Fragment>
  );
};

export default Observations;
