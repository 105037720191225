import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import meanBy from 'lodash/meanBy';
import round from 'lodash/round';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import fromPairs from 'lodash/fromPairs';
import find from 'lodash/find';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import { isEnergyRatingSensor, isEnergyConsumptionSensor, getSensorMetaValue } from 'utils/Data/values';
import { technicalPerformanceOrder } from 'utils/Data/performance';
import { getConditionValue } from 'components/Conditions/ConditionUtils';

export const STATISTICS_TYPES = {
  perHour: 'PER_HOUR',
  perDay: 'PER_DAY',
  perSensor: 'PER_SENSOR',
};

export const getSensorData = memoizeOne((sensor, sensorType, valuesBySensorId) => {
  if (!sensor || !sensorType || !valuesBySensorId) {
    return [];
  }

  let data;
  const graphType = sensorType.graphType;

  switch (graphType) {
    case 'iot':
    case 'air_quality':
    case 'technical_performance':
    case 'performance':
      data = getIoTDataset(sensor, valuesBySensorId, sensorType);
      break;
    case 'presence':
      data = getPresenceDataset(sensor, valuesBySensorId);
      break;
    case 'bar':
      data = getBarDataset(sensor, valuesBySensorId);
      break;
    case 'cleaning':
      data = getCleaningDataset(sensor, valuesBySensorId);
      break;
    default:
      data = [];
  }
  return data;
});

export const getIoTDataset = (sensor, valuesBySensorId, sensorType) => {
  const sensorValues = getIoTSensorValues(sensor, valuesBySensorId) || [];

  if (sensorType.name === 'area_count') {
    const capacity = sensor?.sensorMeta && getSensorMetaValue('capacity', sensor.sensorMeta);
    if (capacity) {
      return getAreaUtilizationDataset(sensorValues, capacity);
    }
  }
  return sensorValues;
};

export const getIoTSensorValues = (sensor, valuesBySensorId) => {
  if (sensor.id) {
    return valuesBySensorId && valuesBySensorId[sensor.id];
  }
  if (sensor.sensors && sensor.sensors.length === 1) {
    return valuesBySensorId && valuesBySensorId[sensor.sensors[0].id];
  }
  if (sensor.sensorIds) {
    const sensorValues = flatMap(sensor.sensorIds, sensorId => valuesBySensorId[sensorId]);
    const valuesByDate = groupBy(sensorValues, 'timestamp');
    const averageSensorValues = flatMap(valuesByDate, (values, timestamp) => ({
      value: meanBy(values, 'value'),
      timestamp,
    }));
    return averageSensorValues;
  }
};

export const getAreaUtilizationDataset = (sensorValues, capacity) => {
  if (!isEmpty(sensorValues)) {
    return map(sensorValues, point => ({ ...point, value: round((point.value / capacity) * 100) }));
  }
  return [];
};

export const getPresenceDataset = (sensor, valuesBySensorId) => {
  const sensorValues =
    valuesBySensorId && map(valuesBySensorId[sensor.id], row => ({ ...row, value: row.value > 0 ? 1 : 0 }));
  return sensorValues || [];
};

export const getUtilizationDataset = (sensor, sensors, utilizationRateChartValues, statistics, areas) => {
  if (statistics === STATISTICS_TYPES.perSensor) {
    const isArea = sensor.sensorType && sensor.sensorType.name === 'presence_area';
    const hasSensorGroups = !sensors[0].id;

    const data = utilizationRateChartValues[statistics];

    const allSensors = hasSensorGroups && isArea ? flatMap(sensors, 'sensors') : sensors;

    /**
     * Data is per sensor id, so we need to map sensor ids to names.
     * Area sensors are mapped to area names.
     * In case of duplicate names, ids are added to the name string.
     */

    const nameMap = fromPairs(
      map(allSensors, sensor => {
        let newName = sensor.name;
        if (isArea) {
          const parentArea = find(areas, area => some(area.sensors, { id: sensor.id })) || { name: sensor.name };
          newName = parentArea.name;
        }
        return [sensor.id || sensor.name, newName];
      })
    );

    const isDuplicateNames = uniq(values(nameMap)).length < allSensors.length;

    return sortBy(
      map(data, ([id, value]) => {
        if (isDuplicateNames) {
          return [`${nameMap[id]} (${id})`, value];
        }
        return [nameMap[id], value];
      }),
      sensor => sensor && -sensor[1]
    );
  }

  const id = sensor.id || sensor.name;
  return (utilizationRateChartValues[id] && utilizationRateChartValues[id][statistics]) || [];
};

export const getBarDataset = (sensor, valuesBySensorId) => {
  const sensorValues = getSensorValues(sensor, valuesBySensorId);
  return map(sensorValues, point => [
    moment
      .utc(point.timestamp)
      .seconds(0)
      .local()
      .valueOf(),
    point.value,
  ]);
};

export const getCleaningDataset = (sensor, valuesBySensorId) => {
  const sensorValues = getSensorValues(sensor, valuesBySensorId);
  return map(values(groupBy(sensorValues, point => moment.utc(point.timestamp).format('YYYY-MM-DD'))), dayValues => [
    moment
      .utc(dayValues[0].timestamp)
      .seconds(0)
      .local()
      .valueOf(),
    dayValues.length,
  ]);
};

export const getSensorValues = (sensor, valuesBySensorId) => {
  const sensorValues =
    valuesBySensorId &&
    filter(valuesBySensorId[sensor.id], value => value.aggregation !== 'latest' && !isNil(value.value));
  return sensorValues || [];
};

export const getSensor = memoizeOne((sensorId, buildingSensors) => {
  const sensorsWithChildren = reduce(
    buildingSensors,
    (buildingSensors, sensor) => buildingSensors.concat([sensor], sensor.children),
    []
  );
  return find(sensorsWithChildren, { id: sensorId });
});

export const getSensorTitle = memoizeOne((title, sensor) => {
  if (title) {
    return title;
  }

  if (sensor && !isNil(sensor.name)) {
    return sensor.name;
  }
  return '-';
});

export const getSensorType = memoizeOne((sensor, sensorTypes) => {
  if (sensor) {
    if (sensor.sensorType && sensor.sensorType.aggregations) {
      return sensor.sensorType;
    }
    if (sensor.sensorTypeId) {
      return find(sensorTypes, { id: sensor.sensorTypeId });
    }
  }
  return {};
});

export const getUtilizationRateSelectorOptions = memoizeOne(
  (sensors, combinedSensor, utilizationRateChartValues, areas) => {
    if (!sensors || sensors.length === 0) {
      return [];
    }

    const sensorOptions = sensors.map(sensor => ({
      sensor,
      performance:
        utilizationRateChartValues[sensor.id || sensor.name] &&
        utilizationRateChartValues[sensor.id || sensor.name].performance,
    }));

    const alphabeticallyOrdered = sortBy(sensorOptions, option => {
      const sensor = option.sensor;
      if (sensor.sensorType && sensor.sensorType.name === 'presence_area') {
        const parentArea = find(areas, area => some(area.sensors, { id: sensor.id }));
        if (parentArea) {
          return parentArea.name;
        }
      }
      return sensor.name;
    });

    if (combinedSensor) {
      const combinedSensorOption = {
        sensor: combinedSensor,
        performance:
          utilizationRateChartValues[combinedSensor.name] &&
          utilizationRateChartValues[combinedSensor.name].performance,
      };
      return [combinedSensorOption, ...alphabeticallyOrdered];
    }

    return alphabeticallyOrdered;
  }
);

export const getPerformanceSelectorOptions = memoizeOne((sensors, combinedSensor, valuesBySensorId) => {
  if (!sensors || sensors.length === 0 || !valuesBySensorId) {
    return [];
  }

  const sensorOptions = sensors.map(sensor => ({
    sensor,
    performance: getAirQuality([sensor.id || get(sensor, 'sensors[0].id')], valuesBySensorId),
  }));

  const sortedOptions = sortBy(sensorOptions, option => {
    const index = technicalPerformanceOrder.indexOf(get(option, 'sensor.sensorType.name'));
    return index !== -1 ? index : get(option, 'sensor.id');
  });

  if (combinedSensor) {
    const combinedSensorOption = {
      sensor: combinedSensor,
      performance: getAirQuality(combinedSensor.sensorIds, valuesBySensorId),
    };
    return [combinedSensorOption, ...sortedOptions];
  }

  return sortedOptions;
});

export const getSensorSelectorOptions = memoizeOne(
  (sensors, latestValuesBySensorId, buildingMeta, theme, t, sensorAlarmsById) => {
    if (!sensors || sensors.length === 0 || !latestValuesBySensorId) {
      return [];
    }

    const sensorOptions = sensors.map(sensor => {
      const { value, color } = getConditionValue({
        sensor,
        parent: null,
        latestValueObject: latestValuesBySensorId[sensor.id],
        buildingMeta,
        theme,
        t,
        noDefaultSensor: false,
        isPerformance: false,
        alarm: sensorAlarmsById[sensor.id],
      });

      return {
        sensor,
        performance: value,
        backgroundColor: color,
      };
    });

    return sortBy(sensorOptions, option => option.sensor.name);
  }
);

export const getAirQuality = (sensorIds, latestValues) => {
  const airQualityValues = values(pick(latestValues, sensorIds)).flat();
  return airQualityValues.length ? Math.round(meanBy(airQualityValues, 'value')) : undefined;
};

export const getUtilization = (values, utilizationHours) => {
  if (!isEmpty(values) && values[0].aggregation === 'hourlyUtilizationRate') {
    const [start, end] = map(utilizationHours.split('-'), time => +time.split(':')[0]) || [];

    const utilizationValues = filter(values, value => isBetweenUtilizationHours(value.timestamp, start, end));

    return isEmpty(utilizationValues) ? null : round(100 * meanBy(utilizationValues, 'value'));
  }
  return null;
};

export const isBetweenUtilizationHours = (timestamp, start, end) => {
  const dateObj = new Date(timestamp);
  const weekday = dateObj.getDay();
  const hour = dateObj.getHours();
  return weekday >= 1 && weekday <= 5 && hour >= start && hour < end;
};

// key values for a single presence sensor, which has raw values
export const getRawUtilization = memoizeOne((values, parameters, utilizationHours) => {
  const { startDatetime, endDatetime } = parameters;

  if (!isEmpty(values) && startDatetime && endDatetime) {
    const [startHour, endHour] = utilizationHours;
    const hourData = groupBy(values, value => moment(value.timestamp).format('YYYY-DD-HH'));

    let hours = 0;
    let utilizedHours = 0;

    const it = moment(startDatetime).local();
    const itEnd = moment(endDatetime).local();

    /**
     * Iterate from startDatetime to endDatetime by hour.
     * Increase hours only between opening hours on business days, and increase utilizedHours
     * if there is also an occupied data point.
     */
    while (it.isSameOrBefore(itEnd)) {
      if (isBetweenUtilizationHours(it.toDate(), startHour, endHour)) {
        hours++;
        if (some(hourData[it.format('YYYY-DD-HH')], value => value.value > 0)) {
          utilizedHours++;
        }
      }
      it.add(1, 'hour');
    }

    return {
      utilizationRate: (100 * utilizedHours) / hours,
      unusedHours: hours - utilizedHours,
    };
  }
  return {};
});

export const getAirQualityAggregation = (start, end) => {
  if (end.diff(start, 'years') > 0) {
    return 'monthlyAverage';
  } else if (end.diff(start, 'weeks') > 10) {
    return 'weeklyAverage';
  } else if (end.diff(start, 'days') > 3) {
    return 'dailyAverage';
  }
  return 'hourlyAverage';
};

const getSuitableAggregation = (preferred, available) => {
  if (!available || !available.length) {
    return 'raw';
  }

  const precedence = ['monthly', 'daily', 'hourly'];
  const preferredIndex = precedence.indexOf(preferred);
  if (preferredIndex !== -1) {
    const byFrequency = groupBy(available, 'frequency');
    const suitableFrequencies = precedence.slice(preferredIndex);
    const suitableFrequency = suitableFrequencies.find(frequency => frequency in byFrequency);
    if (suitableFrequency) {
      return byFrequency[suitableFrequency][0].aggregation;
    }
  }

  return 'raw';
};

export const getAggregation = memoizeOne((sensorType, parameters, sensor, isUtilizationRate) => {
  if (!sensorType || !parameters) {
    return 'raw';
  }
  const { startDatetime, endDatetime } = parameters;

  if (isEnergyRatingSensor(sensorType.name)) {
    return 'energyRating';
  }

  // Handle energy consumption sensors
  if (isEnergyConsumptionSensor(sensorType.name)) {
    if ((sensor && sensor.granularity === 'month') || (endDatetime && endDatetime.diff(startDatetime, 'days') > 365)) {
      return getSuitableAggregation('monthly', sensorType.aggregations);
    } else if (
      (sensor && sensor.granularity === 'day') ||
      (endDatetime && endDatetime.diff(startDatetime, 'days') > 31)
    ) {
      return getSuitableAggregation('daily', sensorType.aggregations);
    }
    // At least hourlySums should be always available so we never use raw data
    return getSuitableAggregation('hourly', sensorType.aggregations);
  }

  if (isUtilizationRate) {
    return 'hourlyUtilizationRate';
  }

  if (sensorType.graphType === 'presence') {
    return 'raw';
  }

  if (!parameters.endDatetime) {
    return 'raw';
  }

  if (sensorType.graphType === 'air_quality' || sensorType.graphType === 'technical_performance') {
    return getAirQualityAggregation(startDatetime, endDatetime);
  }

  // time range is over 14 days
  if (endDatetime.diff(startDatetime, 'days') > 31) {
    return getSuitableAggregation('daily', sensorType.aggregations);
  } else if (endDatetime.diff(startDatetime, 'hours') > 24 * 3) {
    return getSuitableAggregation('hourly', sensorType.aggregations);
  }

  // default
  return 'raw';
});

export const getInitialParameters = (parameters, graphType, isSensorChange) => {
  if (!parameters || isEmpty(parameters) || !isObject(parameters)) {
    return {};
  }

  return {
    ...parameters,
    // moment formatter used in grouping the values
    aggregation: graphType === 'cleaning' || graphType === 'bar' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH',
    // supports multiple ranges if needed
    presence: isSensorChange ? parameters.presence : [9, 15],
  };
};

export const getDefaultMinValue = timestamp => {
  const month = moment.utc(timestamp).month() + 1;
  return includes([6, 7, 8], month) ? 21 : 20.5;
};

export const getDefaultMaxValue = timestamp => {
  const month = moment.utc(timestamp).month() + 1;
  return includes([1, 2, 3, 12], month) ? 23 : includes([4, 10, 11], month) ? 24 : includes([5, 9], month) ? 25 : 26;
};

export const getStatisticsOptions = memoizeOne((t, sensorType, hasSensorGroups) => {
  const presenceType = sensorType && sensorType.name && sensorType.name.split('_')[1];
  const type =
    hasSensorGroups && presenceType !== 'area'
      ? 'floor'
      : presenceType
      ? presenceType === 'area'
        ? 'room'
        : presenceType
      : 'sensor';

  return [
    { label: t('Utilization per day'), value: STATISTICS_TYPES.perDay },
    { label: t('Utilization per hour'), value: STATISTICS_TYPES.perHour },
    { label: t(`Utilization per ${type}`), value: STATISTICS_TYPES.perSensor },
  ];
});

export const getUtilizationHours = memoizeOne(buildingMeta => {
  const utilizationHoursMeta = (find(buildingMeta, { key: 'utilization_calculation_hours' }) || { value: '9:00-15:00' })
    .value;
  return map(utilizationHoursMeta.split('-'), time => +time.split(':')[0]);
});
