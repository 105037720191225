import React from 'react';
import styled from 'styled-components';

const Image = styled.div`
  width: calc(25% - ${props => props.theme.spacing.md});
  flex-shrink: 0;
  height: 100%;
  background-image: ${props => 'url(' + props.image + ')'};
  background-position: center center;
  background-size: cover;
  display: none;

  ${props => props.theme.media.portrait`
        display: block;
    `}
`;

const HeroImage = props => {
  const { image } = props;

  if (!image) {
    return null;
  }

  return <Image image={image} />;
};

export default HeroImage;
