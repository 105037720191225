import React from 'react';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PowerBIReport from 'containers/Application/PowerBI/PowerBIReport';
import Section from 'components/Section/Section';
import SkeletonText from 'components/Skeletons/SkeletonText';
import FreeLayoutView from 'components/FreeLayoutView/FreeLayoutView';
import EmbeddedPageView from 'components/EmbeddedPageView/EmbeddedPageView';
import EmbeddedDocumentView from 'components/EmbeddedDocumentView/EmbeddedDocumentView';

import { customViewShape } from 'components/CustomView/utils';
import { VIEW_TYPES } from 'containers/Application/Admin/CustomViews/utils';

const isPowerBiView = view => view && view.viewType === VIEW_TYPES.powerBi;
const isEmbeddedView = view => view && view.viewType === VIEW_TYPES.embedded;
const isEmbeddedDocumentView = view => view && view.viewType === VIEW_TYPES.embeddedDocument;

export const NoData = styled.p`
  width: 100%;
  text-align: center;
  margin-top: ${props => props.theme.spacing.xl};
`;
NoData.displayName = 'NoData';

const Description = styled.p`
  padding-bottom: ${props => props.theme.spacing.sm};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.colors.black};
`;
Description.displayName = 'Description';

const CustomViewModule = ({ t, isPortfolioLevel, customView }) => {
  const LoadingRender = (
    <Section>
      <SkeletonText header />
    </Section>
  );

  const NoDataRender = <NoData>{t('No data available')}</NoData>;

  if (isPowerBiView(customView)) {
    return (
      <PowerBIReport
        customView={customView}
        isPortfolioLevel={isPortfolioLevel}
        loadingRender={LoadingRender}
        noDataRender={NoDataRender}
      />
    );
  }
  if (isEmbeddedView(customView)) {
    return <EmbeddedPageView customView={customView} loadingRender={LoadingRender} noDataRender={NoDataRender} />;
  }
  if (isEmbeddedDocumentView(customView)) {
    return (
      <EmbeddedDocumentView
        customView={customView}
        loadingRender={LoadingRender}
        noDataRender={NoDataRender}
        t={t}
        hasHero
      />
    );
  }
  return <FreeLayoutView customView={customView} loadingRender={LoadingRender} noDataRender={NoDataRender} />;
};

CustomViewModule.defaultProps = {
  isPortfolioLevel: false,
};

CustomViewModule.propTypes = {
  customView: PropTypes.oneOfType([customViewShape()]).isRequired,
  t: PropTypes.func.isRequired,
  isPortfolioLevel: PropTypes.bool,
};

export default translations(CustomViewModule);
