import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { metaReferenceKeys } from 'utils/Data/values';
import { loadEnergyChartValues } from 'redux/modules/iot/values/energy_chart_values';

const getZoomedChartValues = (state, props) =>
  props.functionalLocation && {
    ...state.values.energyChartValues.energyChartValues.byFl[props.functionalLocation.functionalLocation],
  };

const getMonthlyChartValues = (state, props) =>
  props.functionalLocation && state.values.flEnergyValues.energyValues[props.functionalLocation.functionalLocation];

const getChartValuesByPartnerNumber = (state, props) =>
  props.partnerNumber && state.values.energyChartValues.energyChartValues.byPartner[props.partnerNumber];

const energyChartValuesSelector = createSelector(
  [getMonthlyChartValues, getZoomedChartValues, getChartValuesByPartnerNumber],
  (monthlySum, zoomedValues, chartValuesByPartnerNumber) => {
    if (monthlySum || zoomedValues) {
      return { monthlySum, ...zoomedValues };
    }
    return chartValuesByPartnerNumber || {};
  }
);

const getFunctionalLocationReferenceValues = createSelector(
  [
    (state, props) => {
      const { meta } = state.buildingMeta;
      return meta[props.functionalLocation.functionalLocation];
    },
  ],
  referenceMeta => {
    const metaReferenceKeyValues = Object.keys(metaReferenceKeys).map(key => metaReferenceKeys[key]);
    return referenceMeta
      ?.filter(metaRow => metaReferenceKeyValues.includes(metaRow.key))
      .reduce((acc, item) => {
        const { key, value } = item;
        let values;
        // Reference values come from building meta. An admin user has configured them as text so parsing it might cause an exception.
        try {
          values = JSON.parse(value);
        } catch (error) {
          values = [];
        }
        acc[key] = values;
        return acc;
      }, {});
  }
);

export const mapStateToProps = (state, props) => ({
  energyChartValues: energyChartValuesSelector(state, props),
  referenceValues: getFunctionalLocationReferenceValues(state, props),
  loadingEnergyChartValues: state.values.energyChartValues.loadingEnergyChartValues,
});

const mapDispatchToProps = {
  loadEnergyChartValues,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector;
