import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import get from 'lodash/get';
import values from 'lodash/values';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import memoizeOne from 'memoize-one';
import { getUseCase } from '@caverion/loopback-shared/utility/performance';

import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { FLOOR_OPI } from './FloorOPIUtils';
import { CELSIUS } from 'utils/Data/values';

const getUseCases = memoizeOne(data => {
  const devices = flatMap(values(data), group => group.sensors).map(sensor => sensor && sensor.parent);
  return uniq(devices.map(device => getUseCase(get(device, 'sensorType.name'))).filter(_ => _));
});

const FloorOPICards = ({ t, loading, ctxHelpOverride, toggleDialog, data }) => {
  if (loading) {
    return (
      <OPICards smallMargin>
        <OPICard loading t={text => text} />
        <OPICard loading t={text => text} />
        <OPICard loading t={text => text} />
      </OPICards>
    );
  }

  if (!data || !some(data, opi => opi.sensors && opi.sensors.length > 0)) {
    return null;
  }

  const airQuality = data[FLOOR_OPI.airQuality] || {};
  const temperature = data[FLOOR_OPI.temperature] || {};
  const co2 = data[FLOOR_OPI.co2] || {};
  const humidity = data[FLOOR_OPI.humidity] || {};
  const pm10 = data[FLOOR_OPI.pm10] || {};
  const pm25 = data[FLOOR_OPI.pm25] || {};
  const pressureDifference = data[FLOOR_OPI.pressureDifference] || {};
  const radon = data[FLOOR_OPI.radon] || {};
  const tvoc = data[FLOOR_OPI.tvoc] || {};
  const presenceArea = data[FLOOR_OPI.presenceArea] || {};
  const presenceSeat = data[FLOOR_OPI.presenceSeat] || {};
  const presenceZone = data[FLOOR_OPI.presenceZone] || {};
  const areaUtilizations = data[FLOOR_OPI.areaUtilizations] || [];

  const useCases = getUseCases(data);
  const performanceTitle = t(useCases.length ? 'Technical performance' : 'Indoor Air Quality');

  return (
    <OPICards smallMargin>
      {airQuality.sensors && airQuality.sensors.length > 0 && (
        <OPICard
          t={t}
          title={performanceTitle}
          ctxHelp={`${CTXHELP_PREFIX} Indoor Air Quality`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={t('7 Days Average')}
          value={airQuality.value || 0}
          icon="opi-expand"
          onClick={() =>
            toggleDialog(performanceTitle, 'technical_performance', airQuality.sensors.map(sensor => sensor.id), true)
          }
          isAirQuality
        />
      )}
      {!isNil(temperature.value) && (
        <OPICard
          t={t}
          title={t('Temperature')}
          ctxHelp={`${CTXHELP_PREFIX} Temperature`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={temperature.latestTime}
          valueInside={`${temperature.value} ${CELSIUS}`}
          invalidValue={temperature.valueIsInvalid}
          value={temperature.statusValue}
          icon="opi-expand"
          noCircle
          onClick={() => toggleDialog(null, 'floor_opi', temperature.sensors.map(sensor => sensor.id))}
        />
      )}
      {!isNil(co2.value) && (
        <OPICard
          t={t}
          title="CO₂"
          ctxHelp={`${CTXHELP_PREFIX} CO2`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={co2.latestTime}
          valueInside={co2.value}
          valueInsideLabel={get(co2, ['sensors', 0, 'sensorType', 'unit'])}
          value={co2.statusValue}
          icon="opi-expand"
          noCircle
          onClick={() => toggleDialog(null, 'floor_opi', co2.sensors.map(sensor => sensor.id))}
          invalidValue={co2.valueIsInvalid}
        />
      )}
      {!isNil(humidity.value) && (
        <OPICard
          t={t}
          title={t('Humidity')}
          ctxHelp={`${CTXHELP_PREFIX} Humidity`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={humidity.latestTime}
          valueInside={`${parseFloat(humidity.value).toFixed(0)} ${get(humidity, [
            'sensors',
            0,
            'sensorType',
            'unit',
          ]) || ''}`}
          value={humidity.statusValue}
          icon="opi-expand"
          noCircle
          onClick={() => toggleDialog(null, 'floor_opi', humidity.sensors.map(sensor => sensor.id))}
          invalidValue={humidity.valueIsInvalid}
        />
      )}
      {!isNil(pressureDifference.value) && (
        <OPICard
          t={t}
          title={t('Pressure difference')}
          ctxHelp={`${CTXHELP_PREFIX} Pressure difference`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={pressureDifference.latestTime}
          valueInside={`${parseFloat(pressureDifference.value).toFixed(0)} ${get(pressureDifference, [
            'sensors',
            0,
            'sensorType',
            'unit',
          ]) || ''}`}
          value={pressureDifference.statusValue}
          icon="opi-expand"
          noCircle
          onClick={() => toggleDialog(null, 'floor_opi', pressureDifference.sensors.map(sensor => sensor.id))}
          invalidValue={pressureDifference.valueIsInvalid}
        />
      )}
      {!isNil(pm10.value) && (
        <OPICard
          t={t}
          title="PM10"
          ctxHelp={`${CTXHELP_PREFIX} PM10`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={pm10.latestTime}
          valueInside={pm10.value}
          valueInsideLabel={get(pm10, ['sensors', 0, 'sensorType', 'unit'])}
          value={pm10.statusValue}
          icon="opi-expand"
          noCircle
          onClick={() => toggleDialog(null, 'floor_opi', pm10.sensors.map(sensor => sensor.id))}
          invalidValue={pm10.valueIsInvalid}
        />
      )}
      {!isNil(pm25.value) && (
        <OPICard
          t={t}
          title="PM2.5"
          ctxHelp={`${CTXHELP_PREFIX} PM2.5`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={pm25.latestTime}
          valueInside={pm25.value}
          valueInsideLabel={get(pm25, ['sensors', 0, 'sensorType', 'unit'])}
          value={pm25.statusValue}
          icon="opi-expand"
          noCircle
          onClick={() => toggleDialog(null, 'floor_opi', pm25.sensors.map(sensor => sensor.id))}
          invalidValue={pm25.valueIsInvalid}
        />
      )}
      {!isNil(radon.value) && (
        <OPICard
          t={t}
          title={t('Radon')}
          ctxHelp={`${CTXHELP_PREFIX} Radon`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={radon.latestTime}
          valueInside={radon.value}
          valueInsideLabel={get(radon, ['sensors', 0, 'sensorType', 'unit'])}
          value={radon.statusValue}
          icon="opi-expand"
          noCircle
          onClick={() => toggleDialog(null, 'floor_opi', radon.sensors.map(sensor => sensor.id))}
          invalidValue={radon.valueIsInvalid}
        />
      )}
      {!isNil(tvoc.value) && (
        <OPICard
          t={t}
          title="TVOC"
          ctxHelp={`${CTXHELP_PREFIX} TVOC`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={tvoc.latestTime}
          valueInside={tvoc.value}
          valueInsideLabel={get(tvoc, ['sensors', 0, 'sensorType', 'unit'])}
          value={tvoc.statusValue}
          icon="opi-expand"
          noCircle
          onClick={() => toggleDialog(null, 'floor_opi', tvoc.sensors.map(sensor => sensor.id))}
          invalidValue={tvoc.valueIsInvalid}
        />
      )}
      {!isNil(presenceArea.value) && (
        <OPICard
          neutral
          t={t}
          title={t('Meeting Room Utilization')}
          ctxHelp={`${CTXHELP_PREFIX} Meeting Room Utilization`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={t('Last 7 days')}
          value={presenceArea.value}
          icon="opi-expand"
          onClick={() =>
            toggleDialog(
              t('Meeting Room Utilization'),
              'utilization_rate',
              presenceArea.sensors.map(sensor => sensor.id)
            )
          }
        />
      )}
      {!isNil(presenceZone.value) && (
        <OPICard
          neutral
          t={t}
          title={t('Open Office Utilization')}
          ctxHelp={`${CTXHELP_PREFIX} Open Office Utilization`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={t('Last 7 days')}
          value={presenceZone.value}
          icon="opi-expand"
          onClick={() =>
            toggleDialog(
              t('Open Office Utilization'),
              'utilization_rate',
              presenceZone.sensors.map(sensor => sensor.id)
            )
          }
        />
      )}
      {!isNil(presenceSeat.value) && (
        <OPICard
          neutral
          t={t}
          title={t('Seat Utilization')}
          ctxHelp={`${CTXHELP_PREFIX} Seat Utilization`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={t('Last 7 days')}
          value={presenceSeat.value}
          icon="opi-expand"
          onClick={() =>
            toggleDialog(t('Seat Utilization'), 'utilization_rate', presenceSeat.sensors.map(sensor => sensor.id))
          }
        />
      )}
      {areaUtilizations.map(utilization => (
        <OPICard
          t={t}
          title={t(utilization.title)}
          key={utilization.title}
          ctxHelp={`${CTXHELP_PREFIX} Area Utilization Rate`}
          ctxHelpOverride={ctxHelpOverride}
          subtitle={utilization.latestTime}
          icon="opi-expand"
          onClick={() => toggleDialog(null, null, utilization.sensors.map(sensor => sensor.id))}
          value={utilization.value}
          status={utilization.status}
        />
      ))}
    </OPICards>
  );
};

export default FloorOPICards;

FloorOPICards.defaultProps = {
  loading: false,
  ctxHelpOverride: false,
};

FloorOPICards.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  ctxHelpOverride: PropTypes.bool,
};
