import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT } from '@caverion/redux/api/actions';

export const initialState = {
  airQualityKPI: {
    loading: false,
    data: {},
  },
};

export const LOAD_AIR_QUALITY_KPI = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_AIR_QUALITY_KPI';
export const LOAD_AIR_QUALITY_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_AIR_QUALITY_KPI_SUCCESS';
export const LOAD_AIR_QUALITY_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_AIR_QUALITY_KPI_FAIL';

export const loadAirQualityKPI = partnerNumber => async dispatch => {
  dispatch({ type: LOAD_AIR_QUALITY_KPI });
  try {
    const result = await dispatch(IoT.airQualityKPI(partnerNumber));
    return dispatch({
      type: LOAD_AIR_QUALITY_KPI_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_AIR_QUALITY_KPI_FAIL,
      error,
    });
  }
};

export default createReducerFromMapping(
  {
    [LOAD_AIR_QUALITY_KPI]: state => ({
      ...state,
      airQualityKPI: {
        ...state.airQualityKPI,
        loading: true,
      },
    }),
    [LOAD_AIR_QUALITY_KPI_SUCCESS]: (state, action) => ({
      ...state,
      airQualityKPI: {
        ...state.airQualityKPI,
        loading: false,
        data: action.result,
      },
    }),
    [LOAD_AIR_QUALITY_KPI_FAIL]: (state, action) => ({
      ...state,
      airQualityKPI: {
        ...state.airQualityKPI,
        loading: false,
        error: action.error,
        data: {},
      },
    }),
  },
  initialState
);
