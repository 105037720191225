import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import styled, { withTheme } from 'styled-components';

const StyledWasteTimeframeCategorySelector = styled.div`
  width: 100%;
  margin-bottom: 1em;
  p {
    text-transform: capitalize;
  }
  ${props =>
    props.isMobile
      ? `> div {
        justify-content: center;
        margin-top: 0.5em;
      }`
      : ''}
`;

export const WasteTimeframeCategorySelector = ({
  theme,
  timeframeCategoryOptions,
  selectedTimeframeCategory,
  onTimeframeCategoryChange,
}) => {
  const isMobile = window.innerWidth < theme.breakpoints.landscape;
  return (
    <StyledWasteTimeframeCategorySelector isMobile={isMobile}>
      <RadioButtonGroup
        name="timeframeCategory"
        value={selectedTimeframeCategory}
        onChange={(property, value) => onTimeframeCategoryChange(value)}
        row
      >
        {timeframeCategoryOptions.map(option => (
          <RadioButton key={option.value} value={option.value} label={option.label} />
        ))}
      </RadioButtonGroup>
    </StyledWasteTimeframeCategorySelector>
  );
};

WasteTimeframeCategorySelector.propTypes = {
  theme: PropTypes.object.isRequired,
  timeframeCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedTimeframeCategory: PropTypes.string.isRequired,
  onTimeframeCategoryChange: PropTypes.func.isRequired,
};

export default withTheme(WasteTimeframeCategorySelector);
