import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT } from '@caverion/redux/api/actions';

export const initialState = {
  energyRating: {
    loading: false,
    data: {
      averages: {},
    },
  },
};

export const LOAD_ENERGY_RATING = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_RATING';
export const LOAD_ENERGY_RATING_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_RATING_SUCCESS';
export const LOAD_ENERGY_RATING_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_RATING_FAIL';

export const loadEnergyRating = functionalLocations => {
  if (!functionalLocations) {
    return dispatch =>
      dispatch({
        type: LOAD_ENERGY_RATING_FAIL,
        error: "Parameter 'functionalLocations' is required.",
      });
  }

  return async dispatch => {
    dispatch({ type: LOAD_ENERGY_RATING });
    try {
      const result = await dispatch(
        IoT.energyRating({
          where: {
            functionalLocation: {
              inq: functionalLocations,
            },
          },
        })
      );

      return dispatch({
        type: LOAD_ENERGY_RATING_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_ENERGY_RATING_FAIL,
        error,
      });
    }
  };
};

export const loadEnergyRatingKPI = partnerNumber => async dispatch => {
  dispatch({ type: LOAD_ENERGY_RATING });
  try {
    const result = await dispatch(IoT.energyRatingKPI(partnerNumber));
    return dispatch({
      type: LOAD_ENERGY_RATING_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_ENERGY_RATING_FAIL,
      error,
    });
  }
};

export default createReducerFromMapping(
  {
    [LOAD_ENERGY_RATING]: (state, action) => ({
      ...state,
      energyRating: {
        ...initialState.energyRating,
        loading: true,
      },
    }),
    [LOAD_ENERGY_RATING_SUCCESS]: (state, action) => ({
      ...state,
      energyRating: {
        loading: false,
        data: action.result,
      },
    }),
    [LOAD_ENERGY_RATING_FAIL]: (state, action) => ({
      ...state,
      energyRating: {
        ...state.energyRating,
        loading: false,
        error: action.error,
      },
    }),
  },
  initialState
);
