import styled from 'styled-components';

const SkeletonChart = styled.div`
  display: flex;
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '400px')};
  border-radius: 4px;
  background-color: ${props => props.theme.colors.alabaster};
  align-self: center;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.darkGray};
  white-space: pre-line;
  line-height: ${props => props.theme.font.lineHeight.lg};
  text-align: center;
`;

SkeletonChart.displayName = 'SkeletonChart';

export default SkeletonChart;
