export const toggleScroll = toBe => {
  // classList.toggle is not supported for IE11
  if (toBe) {
    window.document.body.classList.add('noscroll');
    window.document.documentElement.classList.add('noscroll');
  } else {
    window.document.body.classList.remove('noscroll');
    window.document.documentElement.classList.remove('noscroll');
  }
};
