export const percentage = (count, total) => (total ? (count / total) * 100 : 0);

export const weightedMean = (items, valueField, weightField) => {
  let sum = 0;
  let count = 0;
  items.forEach(item => {
    sum += item[valueField] * item[weightField];
    count += item[weightField];
  });
  return sum / count;
};
