import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';

import ServiceOrderTable from 'components/Calendar/ServiceOrderTable';
import translations from 'decorators/Translations/translations';
import { OrderType } from 'constants/serviceCalendar';

const NoServiceOrders = styled.div`
  text-align: center;
  padding: 2em 0 1em;
`;

const handleOnClick = memoizeOne(showServiceOrder => selected => {
  const orderId = selected[selected.orderType === OrderType.ORDER ? 'serviceOrderNumber' : 'id'];
  showServiceOrder(orderId, selected.orderType, selected.functionalLocation, selected.meta.authorizedPartnerNumber);
});

const EMPTY_ARRAY = [];

class CalendarListView extends PureComponent {
  render() {
    const { t, loading, loadingFLs, columns, RowComponent, serviceOrders, showServiceOrder, showNoOrders } = this.props;

    const data = (!loading && !loadingFLs && serviceOrders) || EMPTY_ARRAY;
    if (!loading && !loadingFLs && data.length === 0) {
      return showNoOrders ? (
        <NoServiceOrders data-test-id="NoServiceOrders">{t('No Service Orders')}</NoServiceOrders>
      ) : null;
    }

    return (
      <ServiceOrderTable
        t={t}
        data={data}
        loading={loading}
        columns={columns}
        RowComponent={RowComponent}
        onClick={handleOnClick(showServiceOrder)}
      />
    );
  }
}

CalendarListView.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingFLs: PropTypes.bool,
  serviceOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  RowComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func]).isRequired,
  showServiceOrder: PropTypes.func.isRequired,
  showNoOrders: PropTypes.bool,
};

CalendarListView.defaultProps = {
  partnerNumber: undefined,
  loading: false,
};

export default translations(CalendarListView);
