import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

const Title = styled.h3`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 2em 1em;
  ${props => props.theme.media.desktop`
        margin: 2em 0;
    `}
`;
Title.displayName = 'Title';

const Info = styled.span`
  font-weight: ${props => props.theme.font.weight.normal};
`;

const List = styled.ul``;
List.displayName = 'List';

const ListItem = styled.li`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  &:hover {
    cursor: ${props => (props.clickEnabled ? 'pointer' : 'not-allowed')};
    opacity: ${props => (props.clickEnabled ? '.6' : '1')};
  }
  &:last-child {
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  }
  &:nth-of-type(even) {
    background-color: ${props => props.theme.colors.white};
  }
`;
ListItem.displayName = 'ListItem';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing.sm};
`;
Wrapper.displayName = 'Wrapper';

const ItemTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
ItemTitles.displayName = 'ItemTitles';

const ItemTitle = styled.span`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.bold};
`;
ItemTitle.displayName = 'ItemTitle';

const ItemSubtitle = styled.span`
  margin-top: ${props => props.theme.spacing.xxs};
  color: ${props => props.theme.colors.darkGray};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.normal};
`;
ItemSubtitle.displayName = 'ItemSubtitle';

const ItemValue = styled.span`
  color: ${props => props.theme.colors.black};
  background-color: ${props => (props.light ? lighten(0.05, props.theme.colors.mystic) : props.theme.colors.mystic)};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.normal};
  border-radius: 4px;
  padding: ${props => props.theme.spacing.xs} 0;
  min-width: ${props => `${props.width}px`};
  text-align: center;
`;
ItemValue.displayName = 'ItemValue';

const PerformanceList = ({ title, info, items, t, clickEnabled, valueWidth }) => {
  const handleClick = e => {
    if (!clickEnabled) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Title>
        {t(title)}
        <Info>{info ? ` (${t(info)})` : ''}</Info>
      </Title>
      <List>
        {items.map(item => (
          <ListItem clickEnabled={clickEnabled} key={item.to}>
            <Link to={item.to} onClick={handleClick}>
              <Wrapper>
                <ItemTitles>
                  <ItemTitle data-test-id="PerformanceListItemTitle">{item.title}</ItemTitle>
                  <ItemSubtitle>{item.subtitle}</ItemSubtitle>
                </ItemTitles>
                <ItemValue width={valueWidth} light={item.value === 0}>
                  {item.value}
                </ItemValue>
              </Wrapper>
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default PerformanceList;

PerformanceList.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      to: PropTypes.string,
    })
  ).isRequired,
  clickEnabled: PropTypes.bool.isRequired,
  info: PropTypes.string,
  valueWidth: PropTypes.number,
};

PerformanceList.defaultProps = {
  title: '',
  info: '',
  valueWidth: 115,
};
