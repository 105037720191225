import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { size, isEmpty } from 'lodash';

const Column = styled.div`
  white-space: nowrap;
`;
Column.displayName = 'Column';

const Users = styled.span`
  display: inline-block;
  width: 190px;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props => props.theme.media.portrait`
        width: auto;
    `}

  ${props => props.theme.media.landscape`
        font-weight: ${props => props.theme.font.weight.normal};
    `}
`;
Users.displayName = 'Users';

const UsersColumn = ({ t, data }) => {
  let text = '';
  if (!isEmpty(data)) {
    const length = size(data);
    if (length > 0) {
      text = `${length} ${t('selected')}`;
    }
  } else {
    text = t('Public');
  }
  return (
    <Column>
      <Users>{text}</Users>
    </Column>
  );
};
UsersColumn.displayName = 'UsersColumn';

UsersColumn.propTypes = {
  data: PropTypes.array.isRequired,
};

export default UsersColumn;
