import React from 'react';
import styled from 'styled-components';

const StyledColumn = styled.div`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.fontWeight.normal};
  text-align: ${props => props.center && 'center'};
`;

const SimpleColumn = ({ data, center }) => {
  if (!data) {
    return null;
  }

  return <StyledColumn center={center}>{data}</StyledColumn>;
};

export default SimpleColumn;
