import styled, { css } from 'styled-components';

const TableCell = styled.td`
  padding: ${props => (props.noPadding ? 0 : `${props.theme.spacing.md} ${props.theme.spacing.xs}`)};
  display: ${props => props.hideOnMobile && 'none'};
  vertical-align: ${props => (props.alignMiddle ? 'middle' : 'top')};

  ${props => props.theme.media.landscape`
        padding: ${props => (props.noPadding ? 0 : props.theme.spacing.md)};
        display: ${props => (props.hideOnDesktop ? 'none' : 'table-cell')};
        vertical-align: ${props => !props.alignTop && 'middle'};
    `}

  &:first-child {
    padding-left: 0;
  }

  ${props =>
    props.grow &&
    css`
      width: 100%;
    `}
`;

export default TableCell;
