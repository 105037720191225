export const categoryOptions = t => [
  { label: t('General contact'), value: t('General contact') },
  { label: t('New features'), value: t('New features') },
];

export const mockContacts = [
  {
    header: 'Caverion contact person',
    name: 'Lorem Ipsunen',
    tel: '+358 40 123 4567',
    email: 'lorem.ipsunen@caverion.com',
  },
  {
    header: 'Division sales',
    name: 'Caverion Suomi',
    tel: '09 123 4567',
    email: 'finland@caverion.com',
  },
];
