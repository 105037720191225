import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.colors.alabaster};
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding: ${props => props.theme.spacing.lg};

  ${props => props.theme.media.landscape`
        padding: ${props => props.theme.spacing.lg} ${props => props.theme.spacing.xl};
    `}
`;
Footer.displayName = 'Footer';

const DialogFooter = ({ children, backgroundColor, ...other }) => (
  <Footer backgroundColor={backgroundColor} {...other}>
    {children}
  </Footer>
);
DialogFooter.displayName = 'DialogFooter';

DialogFooter.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
};

DialogFooter.defaultProps = {
  children: null,
  backgroundColor: null,
};

export default DialogFooter;
