import { connect } from 'react-redux';
import { getSensorAlarmsBySensorId } from 'redux/modules/iot/sensorAlarms';

const mapStateToProps = state => ({
  sensorAlarmsById: getSensorAlarmsBySensorId(state),
});

const connector = connect(mapStateToProps);

export default connector;
