import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { uniqueId } from 'lodash';

import Button from 'components/Button/Button';
import InputDate from 'components/Form/InputDate';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputText from 'components/Form/InputText';
import InputTextArea from 'components/Form/InputTextArea';
import InputForm from 'components/Form/InputForm';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import MarkdownContent from './MarkdownContent';
import { announcementShape, ANNOUNCEMENT_TYPE, statusToIcon, statusToColor } from './utils';

export const ANNOUNCEMENT_TYPE_NAME = { SYSTEM_LEVEL: 'System Level', PARTNER_LEVEL: 'Customer Level' };

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
Header.displayName = 'Header';

const HeaderText = styled.h3``;
HeaderText.displayName = 'HeaderText';

const StyledSection = styled.section`
  form {
    display: flex;
    flex-flow: column nowrap;
  }
`;
StyledSection.displayName = 'StyledSection';

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;
StyledFormContainer.displayName = 'StyledFormContainer';

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-right: 0;
  &:last-child {
    padding-right: 0;
  }
  textarea {
    height: 10em;
  }

  ${props => props.theme.media.landscape`

        &:first-child {
            width: 60%;
            padding-right: 2em;
        }

        &:last-child {
            width: 40%;
        }

        textarea {
            height: 14em;
        }
    `}
`;
FormColumn.displayName = 'FormColumn';

const StyledInputRow = styled(InputRow)`
  width: 100%;
  &&& {
    margin-right: 0;
    margin-left: 0;
  }
`;
StyledInputRow.displayName = 'StyledInputRow';

const StyledDateInputRow = styled(StyledInputRow)`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;

const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-bottom: ${props => props.theme.spacing.xs};
  }

  ${props => props.theme.media.landscape`
        &:first-child {
            padding-right: 2em;
            margin-bottom: 0;
        }
    `}
`;

const PreviewArea = styled.div`
  height: 20em;
  overflow-y: auto;
  background-color: ${props => props.theme.colors.alabaster};
  padding: ${props => props.theme.spacing.md};
`;
PreviewArea.displayName = 'PreviewArea';

const Title = styled.h2`
  font-size: ${props => props.theme.font.size.lg};
`;
Title.displayName = 'Title';

const getHeaderText = (t, type, isNew) => {
  switch (type) {
    case ANNOUNCEMENT_TYPE.SYSTEM:
      return isNew
        ? t(`Add New ${ANNOUNCEMENT_TYPE_NAME.SYSTEM_LEVEL} Announcement`)
        : t(`Edit ${ANNOUNCEMENT_TYPE_NAME.SYSTEM_LEVEL} Announcement`);
    case ANNOUNCEMENT_TYPE.PARTNER:
      return isNew
        ? t(`Add New ${ANNOUNCEMENT_TYPE_NAME.PARTNER_LEVEL} Announcement`)
        : t(`Edit ${ANNOUNCEMENT_TYPE_NAME.PARTNER_LEVEL} Announcement`);
    default:
      return isNew ? t(`Add New Announcement`) : t(`Edit Announcement`);
  }
};

const AnnouncementForm = props => {
  const {
    t,
    announcement,
    addNew,
    onSubmit,
    onClose,
    onChange,
    loading,
    errorMessage,
    type,
    partner,
    showPartners,
  } = props;
  return (
    <DialogModal
      large
      animate
      isActive
      onOverlayClick={onClose}
      t={t}
      footer={
        <DialogFooter>
          <Button cancel onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button submit onClick={onSubmit} type="submit" data-test-id="AnnouncementSubmitButton" loading={loading}>
            {addNew ? t('Publish') : t('Save')}
          </Button>
        </DialogFooter>
      }
    >
      <StyledSection>
        <Header showDropdown={showPartners}>
          <HeaderText>{getHeaderText(t, type, addNew)}</HeaderText>
        </Header>
        <InputForm
          id={uniqueId('announcement-form-')}
          model={announcement}
          onPropertyChange={onChange}
          onSubmit={onSubmit}
        >
          <StyledFormContainer>
            <FormColumn>
              {showPartners && partner && (
                <StyledInputRow required>
                  <InputLabel text={`${t('Partner')}`} />
                  <InputText id="partner" type="text" disabled value={`${partner.label} (${partner.value})`} />
                </StyledInputRow>
              )}
              <StyledInputRow required>
                <InputLabel text={t('Title')} />
                <InputText
                  id="title"
                  data-test-id="AnnouncementTitleInput"
                  type="text"
                  property="title"
                  placeholder={announcement.title}
                />
              </StyledInputRow>
              <StyledInputRow>
                <InputLabel text={`${t('Text')} (${t('Markdown')})`} />
                <InputTextArea
                  id="description"
                  data-test-id="AnnouncementDescriptionInput"
                  type="text"
                  property="description"
                  placeholder={announcement.description}
                />
              </StyledInputRow>
              <StyledDateInputRow required>
                <DateInputContainer>
                  <InputLabel text={t('Publish date')} />
                  <InputDate id="startDate" data-test-id="AnnouncementPublishInput" property="startDate" showIcon />
                </DateInputContainer>
                <DateInputContainer>
                  <InputLabel text={t('Expiry date')} />
                  <InputDate
                    id="expirationDate"
                    data-test-id="AnnouncementExpirationInput"
                    property="expirationDate"
                    showIcon
                    iconName="calendar-expiry"
                  />
                </DateInputContainer>
              </StyledDateInputRow>
              <StyledInputRow required>
                <InputLabel text={t('Announcement category')} />
                <RadioButtonGroup name="status" value={announcement.status} onChange={onChange} type="icon">
                  <RadioButton
                    label={t('Info')}
                    value="info"
                    id="info"
                    icon={statusToIcon.info}
                    color={statusToColor.info}
                  />
                  <RadioButton
                    label={t('Warning')}
                    value="warning"
                    id="warning"
                    icon={statusToIcon.warning}
                    color={statusToColor.warning}
                  />
                  <RadioButton
                    label={t('Success')}
                    value="success"
                    id="success"
                    icon={statusToIcon.success}
                    color={statusToColor.success}
                  />
                  {type === ANNOUNCEMENT_TYPE.SYSTEM && (
                    <RadioButton
                      label={t('Release Notes')}
                      value="release notes"
                      id="release notes"
                      icon={statusToIcon['release notes']}
                    />
                  )}
                </RadioButtonGroup>
              </StyledInputRow>
            </FormColumn>
            <FormColumn>
              <StyledInputRow>
                <InputLabel text={t('Preview')} />
                <PreviewArea>
                  <Title>{announcement.title}</Title>
                  <MarkdownContent content={announcement.description} />
                </PreviewArea>
              </StyledInputRow>
            </FormColumn>
          </StyledFormContainer>
          {errorMessage}
        </InputForm>
      </StyledSection>
    </DialogModal>
  );
};

AnnouncementForm.propTypes = {
  // required:
  announcement: PropTypes.oneOfType([
    announcementShape(ANNOUNCEMENT_TYPE.FL),
    announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
    announcementShape(ANNOUNCEMENT_TYPE.SYSTEM),
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  // optional:
  addNew: PropTypes.bool,
  errorMessage: PropTypes.element,
  loading: PropTypes.bool,
  showPartners: PropTypes.bool,
  partner: PropTypes.object,
  type: PropTypes.string,
};

AnnouncementForm.defaultProps = {
  showPartners: false,
  partner: {},
  loading: false,
  errorMessage: <div />,
  type: undefined,
  addNew: false,
};

export default withTheme(AnnouncementForm);
