import React from 'react';
import styled from 'styled-components';

const StyledInfoColumn = styled.div`
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
  text-align: left;
`;

const InfoColumn = props => {
  const { data, t } = props;
  const value = (data === 0 && t('Out')) || (data === 100 && t('In')) || '';

  return <StyledInfoColumn>{value}</StyledInfoColumn>;
};

export default InfoColumn;
