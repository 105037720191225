import React, { Fragment } from 'react';

import SectionHeader from 'components/Section/SectionHeader';
import Section from 'components/Section/Section';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import OPICard from 'components/OPICard/OPICard';
import OPICards from 'components/OPICard/OPICards';

const SkeletonWithOPIAndChart = () => {
  return (
    <Fragment>
      <OPICards>
        <OPICard loading />
        <OPICard loading />
      </OPICards>
      <Section>
        <SectionHeader>
          <SkeletonText header />
        </SectionHeader>
        <Columns>
          <Column
            columnWidth={{
              landscape: 9,
              default: 12,
            }}
          >
            <SkeletonChart height="200px" />
          </Column>
          <Column
            columnWidth={{
              landscape: 3,
              default: 12,
            }}
          >
            <SkeletonText height="200px" />
          </Column>
        </Columns>
      </Section>
    </Fragment>
  );
};

export default SkeletonWithOPIAndChart;
