import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import { rgba } from 'polished';

import { Columns, Column } from 'components/Columns';
import { Icon } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';
import Hero from 'components/Hero/Hero';
import { canEditGeneralAnnouncements } from 'utils/Data/profileData';
import { adminCommonPropType } from './Admin';
import { hideGlobalNotification } from 'redux/modules';
import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';

const Container = styled.section`
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  margin: ${props => props.theme.section.spacing} auto;
`;

const AdminCardContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${props => props.theme.grid.gutter});
  margin-bottom: ${props => props.theme.grid.gutter};
  padding: ${props => props.theme.spacing.xl};
  padding-top: ${props => props.theme.spacing.md};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: 0.25em;
  box-shadow: 0 2px 20px ${rgba(0, 0, 0, 0.08)};
  color: ${props => props.theme.colors.rockBlue};

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.midnight};
  }
`;

AdminCardContainer.displayName = 'AdminCardContainer';

const AdminContainerHeader = styled.h4`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.midnight};
  font-weight: ${props => props.theme.font.weight.bold};
`;

const IconContainer = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.colors.rockBlue};
  padding-right: ${props => props.theme.spacing.md};
`;

AdminContainerHeader.displayName = 'AdminContainerHeader';

const AdminContainerText = styled.h6`
  width: 100%;
`;

AdminContainerText.displayName = 'AdminContainerText';

const AdminContainerLink = styled.div`
  display: flex;
  padding-top: ${props => props.theme.spacing.md};
  color: ${props => props.theme.colors.cerulean};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

AdminContainerLink.displayName = 'AdminContainerLink';

const AdminContainerLinkArrow = styled.span`
  display: inline-block;
  margin-left: 8px;

  &::after {
    content: '→';
  }
`;

AdminContainerLinkArrow.displayName = 'AdminContainerLinkArrow';

const AdminContainerLinkText = styled.p``;
AdminContainerLinkText.displayName = 'AdminContainerLinkText';

const AdminContainer = ({ match, t, profile }) => (
  <Container>
    <Columns>
      <Column columnWidth={{ desktop: 4, landscape: 6, default: 12 }}>
        <AdminCardContainer data-test-id="LinkToCreateNewUser" to={`${match.url}/Users/Create`}>
          <AdminContainerHeader>
            <IconContainer>
              <Icon name={icons.ADMIN_ADD_USER} fill="currentColor" />
            </IconContainer>
            {t('Create new user')}
          </AdminContainerHeader>
          <AdminContainerText>
            {t('Create new caverion.com or external user to SmartView and set their permissions.')}
          </AdminContainerText>
          <AdminContainerLink>
            <AdminContainerLinkText>{t('Create New')}</AdminContainerLinkText>
            <AdminContainerLinkArrow />
          </AdminContainerLink>
        </AdminCardContainer>
      </Column>
      <Column columnWidth={{ desktop: 4, landscape: 6, default: 12 }}>
        <AdminCardContainer data-test-id="LinkToManageUsers" to={`${match.url}/Users`}>
          <AdminContainerHeader>
            <IconContainer>
              <Icon name={icons.ADMIN_MANAGE_USERS} fill="currentColor" />
            </IconContainer>
            {t('Manage existing users')}
          </AdminContainerHeader>
          <AdminContainerText>
            {t(
              'Remove, adjust permissions and change details of already existing caverion.com ' +
                'or external SmartView users.'
            )}
          </AdminContainerText>
          <AdminContainerLink>
            <AdminContainerLinkText>{t('Manage')}</AdminContainerLinkText>
            <AdminContainerLinkArrow />
          </AdminContainerLink>
        </AdminCardContainer>
      </Column>
      {canEditGeneralAnnouncements(profile.role) && (
        <Column columnWidth={{ desktop: 4, landscape: 6, default: 12 }}>
          <AdminCardContainer data-test-id="LinkToManageAnnouncements" to={`${match.url}/Announcements`}>
            <AdminContainerHeader>
              <IconContainer>
                <Icon name={icons.ADMIN_MANAGE_NOTIFICATIONS} fill="currentColor" />
              </IconContainer>
              {t('Manage general announcements')}
            </AdminContainerHeader>
            <AdminContainerText>{t('Create, update and remove announcements seen by all users.')}</AdminContainerText>
            <AdminContainerLink>
              <AdminContainerLinkText>{t('Manage')}</AdminContainerLinkText>
              <AdminContainerLinkArrow />
            </AdminContainerLink>
          </AdminCardContainer>
        </Column>
      )}
      <Column columnWidth={{ desktop: 4, landscape: 6, default: 12 }}>
        <AdminCardContainer data-test-id="linkToAdminTags" to={`${match.url}/Tags`}>
          <AdminContainerHeader>
            <IconContainer>
              <Icon name={icons.ADMIN_TAGS} fill="currentColor" />
            </IconContainer>
            {t('Tag management')}
          </AdminContainerHeader>
          <AdminContainerText>{t('Create, update and remove tags and groups.')}</AdminContainerText>
          <AdminContainerLink>
            <AdminContainerLinkText>{t('Manage')}</AdminContainerLinkText>
            <AdminContainerLinkArrow />
          </AdminContainerLink>
        </AdminCardContainer>
      </Column>
      {profile.role === 'caverion-admin' && (
        <Column columnWidth={{ desktop: 4, landscape: 6, default: 12 }}>
          <AdminCardContainer data-test-id="linkToAdminCustomViews" to={`${match.url}/CustomViews`}>
            <AdminContainerHeader>
              <IconContainer>
                <Icon name={icons.CUSTOM_VIEW} fill="currentColor" />
              </IconContainer>
              {t('Manage custom views')}
            </AdminContainerHeader>
            <AdminContainerText>
              {t('Manage custom views shown in defined locations all over portal.')}
            </AdminContainerText>
            <AdminContainerLink>
              <AdminContainerLinkText>{t('Manage')}</AdminContainerLinkText>
              <AdminContainerLinkArrow />
            </AdminContainerLink>
          </AdminCardContainer>
        </Column>
      )}
      {profile.role === 'caverion-admin' && (
        <Column columnWidth={{ desktop: 4, landscape: 6, default: 12 }}>
          <AdminCardContainer data-test-id="linkToAdminTranslations" to={`${match.url}/Translations`}>
            <AdminContainerHeader>
              <IconContainer>
                <Icon name={icons.LANGUAGE} fill="currentColor" />
              </IconContainer>
              {t('Update translations')}
            </AdminContainerHeader>
            <AdminContainerText>{t('Take new translations in use all over the portal.')}</AdminContainerText>
            <AdminContainerLink>
              <AdminContainerLinkText>{t('Manage')}</AdminContainerLinkText>
              <AdminContainerLinkArrow />
            </AdminContainerLink>
          </AdminCardContainer>
        </Column>
      )}
    </Columns>
  </Container>
);

AdminContainer.propTypes = adminCommonPropType;

export class AdminPage extends React.Component {
  static propTypes = adminCommonPropType;

  componentWillUnmount() {
    this.props.hideGlobalNotification();
  }

  render() {
    const { match, t, profile } = this.props;
    const links = [
      { title: 'Back to Overview', icon: icons.ARROW_LEFT, to: { pathname: '/' }, smallIcon: true },
      { title: 'Admin Panel', icon: icons.ADMIN_PANEL, pathname: '/Admin' },
    ];

    return (
      <StandardPage>
        <Header t={t} links={links} />
        <Hero title={t('Admin Panel')} t={t} type="ADMIN_PANEL" />
        <AdminContainer match={match} t={t} profile={profile} />
      </StandardPage>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
});

const mapDispatchToProps = {
  hideGlobalNotification,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(AdminPage));
