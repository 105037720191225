import head from 'lodash/head';
import round from 'lodash/round';
import times from 'lodash/times';
import sum from 'lodash/sum';
import setWith from 'lodash/setWith';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import each from 'lodash/each';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import inRange from 'lodash/inRange';
import constant from 'lodash/constant';
import meanBy from 'lodash/meanBy';
import mean from 'lodash/mean';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import moment from 'moment';

import {
  energyBreakdownTypes,
  totalEnergyConsumptionTypes,
  getUnitBySensorType,
  isEnergyRatingSensor,
  CELSIUS,
} from 'utils/Data/values';

import { lighten } from 'polished';

export const energyRatingPrecision = 1;
export const CO2Multiplier = 217;
export const CO2BuildingMetaKey = 'co2_multiplier';

export const defaultValues = {
  series: [],
  yearTotals: {},
  years: [],
  categories: [],
  last365DaysConsumption: 0,
  unit: 'kWh',
};

// Update state with ready values
export const updateEnergyValues = (energyValues, theme, functionalLocations, meta, energySensors) => {
  const valuesByType = {};
  let energyRatingValues = [];
  if (energyValues) {
    energyBreakdownTypes.forEach(type => {
      if (energyValues[type] && energyValues[type].length > 0) {
        valuesByType[type] = getEnergyValues(energyValues[type], theme, meta, energySensors);
      }
    });
    if (energyValues.energyRating && energyValues.energyRating.length > 0) {
      energyRatingValues = getEnergyRatingValues(energyValues.energyRating);
    }
  }
  if (valuesByType || energyRatingValues) {
    return {
      energyRatingValues,
      valuesByType,
    };
  }
};

// Get UI ready energy values
export const getEnergyValues = (values, theme, meta, energySensors) => {
  const monthlyData = getMonthlyConsumption(values);
  const categories = moment.monthsShort();
  const series = [];
  const yearTotals = {};
  const years = keys(monthlyData);
  const colors = theme.charts.colorsEnergy.slice(0);
  let unit = getUnitBySensorType(head(values).type);
  // Convert kWh to MWh
  if (unit === 'kWh') {
    unit = 'MWh';
  }

  const granularityToZoomTypeMap = {
    month: 'year',
    day: 'month',
    hour: 'date',
  };

  let maxZoomLevel = ZOOM_TYPE.hour;
  forEach(values, value => {
    if (!value) {
      return;
    }
    const sensor = find(energySensors, { id: +value.sensorId });
    if (!sensor || !sensor.granularity) {
      return;
    }
    const sensorZoomType = granularityToZoomTypeMap[sensor.granularity];
    if (sensorZoomType && maxZoomLevel > ZOOM_TYPE[sensorZoomType]) {
      maxZoomLevel = ZOOM_TYPE[sensorZoomType];
    }
  });

  const last365DaysConsumption =
    unit === 'MWh' ? round(getLast365DaysConsumption(values) / 1000) : round(getLast365DaysConsumption(values));

  const last365DaysCO2Emissions =
    unit === 'MWh'
      ? round(getLast365DaysCO2Emissions(values, meta) / 1000)
      : round(getLast365DaysCO2Emissions(values, meta));

  const last365DaysConsumptionFrom30DaysAgo =
    unit === 'MWh'
      ? round(getLast365DaysConsumptionFrom30DaysAgo(values) / 1000)
      : round(getLast365DaysConsumptionFrom30DaysAgo(values));

  const last30DaysConsumption =
    unit === 'MWh' ? round(getLast30DaysConsumption(values) / 1000) : round(getLast30DaysConsumption(values));

  const last30DaysConsumptionFromLastYear =
    unit === 'MWh'
      ? round(getLast30DaysConsumptionFromLastYear(values) / 1000)
      : round(getLast30DaysConsumptionFromLastYear(values));

  if (monthlyData) {
    forEach(Object.keys(monthlyData), year => {
      // Map data to every month (0-11) of the year
      const currentYearData = times(12, month => {
        if (monthlyData[year][month]) {
          return monthlyData[year][month];
        }
        return 0;
      });
      // Sum months
      yearTotals[year] = unit === 'MWh' ? round(sum(currentYearData) / 1000) : round(sum(currentYearData));
      // Create chart's series data
      series.push({
        name: year,
        data: currentYearData.map(data => {
          return unit === 'MWh'
            ? // Intentional change of kWh value to MWh
              // after rounding to show more precise values
              round(data) / 1000
            : round(data);
        }),
        color: colors[moment.utc().year() - year],
        _unit: unit,
        type: 'column',
      });
    });
  }

  return {
    series,
    years,
    yearTotals,
    categories,
    last365DaysConsumption,
    last365DaysConsumptionFrom30DaysAgo,
    last30DaysConsumption,
    last30DaysConsumptionFromLastYear,
    last365DaysCO2Emissions,
    unit,
    maxZoomLevel,
  };
};

export const getTotalRawValues = energyValues => {
  let totalRawValues = [];
  totalEnergyConsumptionTypes.forEach(type => {
    if (energyValues[type] && energyValues[type].length > 0) {
      totalRawValues = totalRawValues.concat(energyValues[type]);
    }
  });
  return totalRawValues;
};

export const getElectricityValues = (values, functionalLocations) => {
  const electricityArray = [];
  map(values, value => {
    const electricity = {};
    electricity.value = round(value.value) / 1000;
    if (value.unit === 'kWh') {
      electricity.unit = 'MWh';
    }

    if (functionalLocations[value.functionalLocation]) {
      electricity.title = functionalLocations[value.functionalLocation].description;
      electricity.address = `${functionalLocations[value.functionalLocation].address}, ${
        functionalLocations[value.functionalLocation].city
      }`;
    } else {
      // if user does not have permissions to the fl where sensor is mapped, use placeholder text until
      // partner permission task is finished
      electricity.title = value.functionalLocation ? value.functionalLocation : 'title placeholder';
    }
    electricityArray.push(electricity);
  });
  return electricityArray;
};

/**
 * Calculate energy rating values by FL.
 * @param {Object[]} values - Values.
 * @returns {Object.<string, Object>} EnergyRatingValues - Description
 */
export const getEnergyRatingValues = values => {
  const result = values
    .map(value => {
      return {
        ...value,
        timestamp: moment.utc(value.timestamp),
        unit: value.type ? getUnitBySensorType(value.type) : 'kWh/m2',
      };
    })
    .reduce((acc, value) => {
      acc[value.functionalLocation] = acc[value.functionalLocation] ?? [];
      acc[value.functionalLocation].push(value);
      return acc;
    }, {});
  Object.keys(result).map(
    fl =>
      (result[fl] = {
        values: result[fl],
        unit: result[fl][0]?.unit,
        last365DaysConsumption: getLast365DaysConsumption(result[fl], { mean: true }),
        last365DaysConsumptionFrom30DaysAgo: getLast365DaysConsumptionFrom30DaysAgo(result[fl], { mean: true }),
      })
  );
  return result;
};

// Sum last month consumption from raw data
export const getLastMonthConsumption = values => {
  const lastMonth = moment.utc().subtract(1, 'month');
  const startDate = moment(lastMonth).startOf('month');
  const endDate = moment(lastMonth).endOf('month');
  return [getConsumptionBetweenDates(values, startDate, endDate), startDate.toISOString()];
};

// Sum 30 days consumption from raw data
export const getLast30DaysConsumption = values => {
  const startDate = moment.utc().subtract(31, 'days');
  const endDate = moment.utc().subtract(1, 'days');
  return getConsumptionBetweenDates(values, startDate, endDate);
};

/**
 * Calculate last 365 days consumption from raw data.
 * @param {Object[]} values - Values.
 * @param {{mean:boolean}} [options]- Options to return mean instead of sum
 */
export const getLast365DaysConsumptionFrom30DaysAgo = (values, options) => {
  const startDate = moment
    .utc()
    .subtract(31, 'days')
    .subtract(1, 'year');
  const endDate = moment.utc().subtract(31, 'days');
  return getConsumptionBetweenDates(values, startDate, endDate, options);
};

// Sum year ago 30 days consumption from raw data
export const getLast30DaysConsumptionFromLastYear = values => {
  const startDate = moment
    .utc()
    .subtract(31, 'days')
    .subtract(1, 'years');
  const endDate = moment
    .utc()
    .subtract(1, 'days')
    .subtract(1, 'years');
  return getConsumptionBetweenDates(values, startDate, endDate);
};

/**
 * Sum last 365 days consumption from raw data.
 * @param {Object[]} values - Values.
 * @param {{mean:boolean}} [options]- Options to return mean instead of sum
 */
export const getLast365DaysConsumption = (values, options) => {
  const startDate = moment.utc().subtract(365, 'days');
  const endDate = moment.utc();
  return getConsumptionBetweenDates(values, startDate, endDate, options);
};

export const getLast365DaysCO2Emissions = (values, meta) => {
  const emissionsByFl = [];
  const groupedValues = groupBy(values, 'functionalLocation');
  each(groupedValues, (values, key) => {
    const startDate = moment.utc().subtract(365, 'days');
    const endDate = moment.utc();
    // use building meta co2 multiplier if it exist else use constant
    const co2MultiplierMetaData = meta && find(meta[key], flMeta => flMeta.key === CO2BuildingMetaKey);
    const co2Multiplier = co2MultiplierMetaData ? parseInt(co2MultiplierMetaData.value, 10) : CO2Multiplier;
    const emissionObject = {};
    emissionObject.totalEmissions = getConsumptionBetweenDates(values, startDate, endDate) * co2Multiplier;
    emissionsByFl.push(emissionObject);
  });
  return sumBy(emissionsByFl, 'totalEmissions');
};

/**
 * Get total consumption between given dates from values.
 * @param {Object[]} values - Values.
 * @param {{mean:boolean}} [options]- Options to return mean instead of sum
 */
export const getConsumptionBetweenDates = (values = [], startDate, endDate, options = {}) => {
  const filteredValues = values
    .filter(value => moment.utc(value.timestamp).isBetween(startDate, endDate))
    .map(value => value.value);
  if (options.mean) {
    return mean(filteredValues);
  }
  return sum(filteredValues);
};

export const getMonthlyEnergyRatingValues = values => {
  if (!values || values.length === 0) {
    return {};
  }
  const monthlyData = {};
  const sortedValues = sortBy(values, 'timestamp');

  // Values are sorted from oldest to newest
  // Iterate over values and set the latest value found as monthly value
  sortedValues.forEach(value => {
    // Get year and month from timestamp
    const valueDate = moment.utc(value.timestamp);
    const year = valueDate.year();
    const month = valueDate.month();

    // Set value to a month in result object
    if (value.value != null) {
      setWith(monthlyData, [year, month], value.value, Object);
    }
  });
  return monthlyData;
};

// Get monthly consumption data by year and month from raw data
// Output format: { '2018': { '0': 123 }, { '1': 123 }, ... }
export const getMonthlyConsumption = values => {
  if (values && values.length > 0) {
    const monthlyData = {};
    values.forEach(value => {
      // Get year and month from timestamp
      const valueDate = moment.utc(value.timestamp);
      const year = valueDate.year();
      const month = valueDate.month();
      let newValue = value.value;
      // Add value to monthyData object
      if (monthlyData && monthlyData[year] && monthlyData[year][month]) {
        newValue += monthlyData[year][month];
      }
      if (newValue != null) {
        setWith(monthlyData, [year, month], newValue, Object);
      }
    });
    return monthlyData;
  }
  return {};
};

export const getLastMonthsMonthlySum = values => {
  const timestamp = moment
    .utc()
    .subtract(1, 'months')
    .startOf('month')
    .toISOString();
  let result;
  if (values && values.length > 0) {
    result = find(values, { aggregation: 'monthlySum', timestamp });
  }
  return [(result && result.value) || 0, timestamp];
};

// get new values for energy consumption chart when zooming in
export const getZoomedEnergyValues = (values, theme, zoomType, selected, unit) => {
  if (!values || !Array.isArray(values) || isNil(zoomType) || !theme) {
    return {};
  }
  const { start, end, view, category, categories, title } = getZoomDetails(zoomType, selected);
  const data = getGenericEnergyData(values, start, end, view, category);
  const years = keys(data);
  const series = [];
  const colors = theme.charts.colorsEnergy.slice(0);

  if (data && Object.keys(data).length > 0) {
    forEach(years, year => {
      series.push({
        name: year,
        data: map(categories, (categ, index) => {
          const value = view === 'year' ? data[year][index] : data[year][categ] || 0;
          return unit === 'MWh'
            ? // Intentional change of kWh value to MWh
              // after rounding to show more precise values
              round(value) / 1000
            : round(value);
        }),
        color: colors[moment.utc().year() - year],
        _unit: unit,
        _showTooltipForZeroValue: true,
        type: 'column',
      });
    });
  } else {
    series.push(times(categories.length, constant(0)));
  }

  return {
    series,
    categories: view === 'date' ? categories.map(value => `${value < 10 ? '0' + value : value}:00`) : categories,
    xTitle: title,
  };
};

// e.g. zoomed into March =>
// values: energyvalues, start: moment(1.3.), end: moment(1.4.), view: 'month', categ: 'date'
export const getGenericEnergyData = (values, start, end, view, categ) => {
  const data = {};
  if (values && values.length > 0) {
    values.forEach(value => {
      const valueDate = moment.utc(value.timestamp).local();
      if (
        inRange(
          valueDate.get(view),
          start.get(view),
          start.year() === end.year()
            ? end.get(view)
            : moment(end)
                .subtract(1, 'day')
                .get(view) + 1
        )
      ) {
        const year = valueDate.year();
        const category = valueDate.get(categ);
        let newValue = value.value;
        if (data && data[year] && data[year][category]) {
          newValue += data[year][category];
        }
        if (newValue != null) {
          setWith(data, [year, category], newValue, Object);
        }
      }
    });
  }
  return data;
};

export const ZOOMS = ['year', 'month', 'date', 'hour'];
export const ZOOM_TYPE = {
  year: 0,
  month: 1,
  date: 2,
  hour: 3,
};
export const ZOOMABLE = {
  energyBreakdown: 'energyBreakdown',
  energyConsumption: 'energyConsumption',
};
// all chart data details based on selected zoom
// e.g. zoomed into March => zoomType: ZOOM_TYPE.month, selected: 2
export const getZoomDetails = (zoomType, selected) => {
  let start, end, view, category, categories, title;
  switch (zoomType) {
    case ZOOM_TYPE.year:
      start = moment().subtract(2, ZOOMS[zoomType]);
      end = moment().add(1, ZOOMS[zoomType]);
      view = ZOOMS[zoomType];
      category = ZOOMS[zoomType + 1];
      categories = moment.monthsShort();
      title = '';
      break;
    case ZOOM_TYPE.month:
      start = moment()
        .month(selected)
        .date(1);
      end = moment(start).add(1, ZOOMS[zoomType]);
      view = ZOOMS[zoomType];
      category = ZOOMS[zoomType + 1];
      categories = times(end.diff(start, 'days'), index =>
        moment(start)
          .add(index, 'days')
          .get(category)
      );
      title = start.format('MMMM');
      break;
    case ZOOM_TYPE.date:
      start = moment()
        .dayOfYear(selected)
        .startOf('day');
      end = moment(start)
        .add(1, 'days')
        .startOf('day');
      view = ZOOMS[zoomType];
      category = ZOOMS[zoomType + 1];
      categories = times(24, index =>
        moment(start)
          .add(index, 'hours')
          .get(category)
      );
      title = start.format('D. MMM');
      break;
    default:
      break;
  }
  return { start, end, view, category, categories, title };
};

// Map breakdown text props
export const mapBreakdownTexts = (type, t) => {
  switch (type) {
    case 'electricity':
      return {
        heading: t('Electricity'),
        subHeading: t('Total electricity consumption'),
      };
    case 'districtHeating':
      return {
        heading: t('District heating'),
        subHeading: t('Total district heating'),
      };
    case 'waterConsumption':
      return {
        heading: t('Water'),
        subHeading: t('Total water consumption'),
      };
    default:
      return {
        heading: t('Energy'),
        subHeading: t('Total energy consumption'),
      };
  }
};

// Get OPI cards data
export const getOpiCards = (totalValues, valuesByType, energyRatingValues, t, energyRatingSensor) => {
  const cards = [];
  // Add energy rating
  if (energyRatingValues && energyRatingValues.latest && energyRatingValues.previous) {
    const difference = round(energyRatingValues.latest - energyRatingValues.previous, energyRatingPrecision);
    let prefix = '';
    if (difference > 0) {
      prefix = '+';
    }
    cards.push({
      index: 4,
      title: t('Energy rating'),
      subtitle: t('Last month'),
      valueInside: `${prefix}${difference}`,
      valueInsideIsPositive: difference < 0,
      valueInsideIsNegative: difference > 0,
      valueInsideLabel: 'kWh/m²',
      ctxHelpType: 'energy rating',
      sensorId: energyRatingSensor && energyRatingSensor.id,
    });
  }
  // Add total energy consumption
  if (totalValues && totalValues.last365DaysConsumption) {
    cards.push(
      {
        index: 0,
        title: t('Energy consumption'),
        subtitle: t('Last 365 days'),
        valueInside: totalValues.last365DaysConsumption,
        valueInsideIsPositive: true,
        valueInsideLabel: totalValues.unit,
        ctxHelpType: 'energy consumption',
        scrollTo: 'energyConsumption',
      },
      {
        index: 6,
        title: `CO₂ ${t('Emissions')}`,
        subtitle: t('Last 365 days'),
        valueInside: round(totalValues.last365DaysCO2Emissions / 1000, 1),
        valueInsideIsPositive: true,
        valueInsideLabel: `CO₂ ${t('tonnes')}`,
        ctxHelpType: 'co2',
      }
    );
  }
  // Add breakdown energy types
  if (valuesByType) {
    energyBreakdownTypes.forEach((type, index) => {
      if (
        valuesByType[type] &&
        valuesByType[type].last365DaysConsumption &&
        valuesByType[type].last365DaysConsumptionFrom30DaysAgo
      ) {
        const heading = mapBreakdownTexts(type, t).heading;
        const rating = round(
          ((valuesByType[type].last365DaysConsumption - valuesByType[type].last365DaysConsumptionFrom30DaysAgo) /
            valuesByType[type].last365DaysConsumptionFrom30DaysAgo) *
            100
        );
        let prefix = '';
        if (rating > 0) {
          prefix = '+';
        }

        cards.push({
          index: index + 1,
          title: heading,
          subtitle: t('Last 30 days'),
          valueInside: `${prefix}${rating}\u00A0%`, // Non-breaking space between value and %-character
          valueInsideIsPositive: rating < 0,
          valueInsideIsNegative: rating > 0,
          ctxHelpType: `breakdown ${type}`,
          scrollTo: 'breakdown',
          changeTabTo: index,
        });
      }
    });
  }
  // Index will determinate the order of cards in the UI
  return cards.sort((a, b) => a.index - b.index);
};

export const getMeters = meterValues => {
  if (meterValues && meterValues.length > 0) {
    return meterValues.map(meter => {
      let data = [];
      if (meter && meter.monthlyData && !isEmpty(meter.monthlyData)) {
        const last6Months = times(6, idx => {
          const targetDate = moment.utc().subtract(idx, 'months');
          return {
            year: targetDate.year(),
            month: targetDate.month(),
          };
        });
        data = last6Months.map(dateObject => {
          const { year, month } = dateObject;
          if (meter && meter.monthlyData && meter.monthlyData[year] && meter.monthlyData[year][month]) {
            return meter.monthlyData[year][month];
          }
          return 0;
        });
      }
      return {
        meterName: (meter.sensor && meter.sensor.name) || 'N/A',
        meterValue: round(meter.last30Days),
        meterUnit: meter.unit,
        meterData: data.reverse(),
        sensorId: meter.sensor && meter.sensor.id,
        timestamp: meter.timestamp,
        granularity: meter.sensor && meter.sensor.granularity,
      };
    });
  }
  return [];
};

export const getMeterValues = (sensors, values, functionalLocation) => {
  const sensorData = [];
  sensors.forEach(sensor => {
    if (!sensor) {
      return;
    }
    const sensorValues = values[sensor.id];
    if (sensorValues && sensorValues.length > 0) {
      const sensorType = head(sensorValues).type;
      const unit = getUnitBySensorType(sensorType);
      let last30Days;
      let monthlyData = {};
      let timestamp;
      if (isEnergyRatingSensor(sensorType)) {
        const lastRatings = find(getEnergyRatingValues(sensorValues), { functionalLocation });
        last30Days = lastRatings.latestValue;
        timestamp = lastRatings.timestamp;
        monthlyData = getMonthlyEnergyRatingValues(sensorValues);
      } else if (sensor.granularity === 'month') {
        [last30Days, timestamp] = getLastMonthsMonthlySum(sensorValues);
        monthlyData = getMonthlyConsumption(sensorValues);
      } else {
        [last30Days, timestamp] = getLastMonthConsumption(sensorValues);
        monthlyData = getMonthlyConsumption(sensorValues);
      }

      sensorData.push({
        id: sensor.id,
        sensor,
        unit,
        monthlyData,
        last30Days,
        timestamp,
      });
    }
  });
  return sensorData;
};

export const getOutdoorsTemperatureData = (outdoorsTemperatureValues, theme, t) => {
  if (
    !outdoorsTemperatureValues ||
    !Array.isArray(outdoorsTemperatureValues) ||
    outdoorsTemperatureValues.length === 0 ||
    !theme
  ) {
    return {};
  }

  const MONTHS = 12;
  const data = groupBy(outdoorsTemperatureValues, value => moment.utc(value.timestamp).year());
  // to monthly data points
  forEach(data, (monthData, year) => {
    data[year] = groupBy(monthData, value => moment.utc(value.timestamp).month());
  });
  const years = keys(data);
  const series = [];
  const monthSeries = [];
  const unit = CELSIUS;
  const colors = theme.charts.colorsEnergy.slice(0);

  const valueOfDataPoints = points => {
    if (isEmpty(points)) {
      return null;
    }
    const valueProperty = points[0].value !== undefined ? 'value' : 'avg';
    return round(meanBy(points, valueProperty), 1);
  };

  if (data && !isEmpty(data)) {
    // form datasets for year-view
    forEach(years, year => {
      const colorIndex = moment.utc().year() - year;
      series.push({
        name: `${t('Temperature')}(${year})`,
        data: times(MONTHS, month => valueOfDataPoints(data[year][month])),
        color: lighten(0.1, colors[inRange(colorIndex, 0, colors.length) ? colorIndex : 0]),
        _unit: unit,
      });
    });

    // form datasets for each month-view
    times(MONTHS, month => {
      const yearSeriesForMonth = [];

      forEach(years, year => {
        const dailyData = groupBy(data[year][month], value => moment.utc(value.timestamp).date() - 1);
        const DAYS = moment
          .utc()
          .year(year)
          .month(month)
          .daysInMonth();
        const colorIndex = moment.utc().year() - year;

        if (dailyData && !isEmpty(dailyData)) {
          yearSeriesForMonth.push({
            name: `${t('Temperature')}(${year})`,
            data: times(DAYS, date => valueOfDataPoints(dailyData[date + 1])),
            color: lighten(0.1, colors[inRange(colorIndex, 0, colors.length) ? colorIndex : 0]),
            _unit: unit,
          });
        }
      });

      monthSeries.push(yearSeriesForMonth);
    });
  }

  return {
    [ZOOM_TYPE.year]: [series],
    [ZOOM_TYPE.month]: monthSeries,
  };
};

export const getReferenceSeries = (referenceValues, theme, seriesName) => {
  const series = [];
  let monthSeries = [];

  if (referenceValues && Array.isArray(referenceValues) && referenceValues.length > 0) {
    series.push({
      name: seriesName,
      data: referenceValues,
      color: theme ? theme.colors.orange : undefined,
      type: 'spline',
      lineWidth: 1,
      zIndex: 0,
      showInLegend: true,
      marker: { enabled: false },
      _unit: 'MWh',
    });

    monthSeries = map(referenceValues, (value, index) => {
      const days = moment
        .utc()
        .month(index)
        .daysInMonth();
      const avg = round(value / days, 3);

      return {
        name: seriesName,
        data: times(days, () => avg), // same value for each day
        color: theme ? theme.colors.orange : undefined,
        type: 'spline',
        lineWidth: 1,
        zIndex: 0,
        showInLegend: true,
        marker: { enabled: false },
        _unit: 'MWh',
      };
    });
  }

  return {
    [ZOOM_TYPE.year]: series,
    [ZOOM_TYPE.month]: monthSeries,
  };
};

export const loadDailyChartValues = (loadEnergyChartValues, partnerNumber) => {
  if (typeof loadEnergyChartValues === 'function') {
    const betweens = [
      [
        moment()
          .subtract(2, 'years')
          .startOf('year')
          .utc()
          .toISOString(),
        moment.utc().toISOString(),
      ],
    ];

    loadEnergyChartValues(partnerNumber, betweens, 'dailySum');
  }
};

export const loadHourlyChartValues = (day, loadEnergyChartValues, partnerNumber) => {
  if (typeof loadEnergyChartValues === 'function') {
    const betweens = [];
    const start = moment()
      .dayOfYear(day)
      .startOf('day');
    const end = moment(start)
      .add(1, 'day')
      .startOf('day');

    if (moment().isAfter(start)) {
      betweens.push([start.utc().toISOString(), end.utc().toISOString()]);
    }
    betweens.push([
      moment(start)
        .subtract(1, 'years')
        .utc()
        .toISOString(),
      moment(end)
        .subtract(1, 'years')
        .utc()
        .toISOString(),
    ]);
    betweens.push([
      moment(start)
        .subtract(2, 'years')
        .utc()
        .toISOString(),
      moment(end)
        .subtract(2, 'years')
        .utc()
        .toISOString(),
    ]);

    loadEnergyChartValues(partnerNumber, betweens, 'hourlySum');
  }
};
