import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { Infotip } from 'components';

const Column = styled.div``;
Column.displayName = 'Column';

const Value = styled.div`
  padding: ${props => props.theme.spacing.xxs};
  background-color: ${props => props.color && transparentize(0.8, props.color)};
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.bold};
  text-align: center;

  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props => props.theme.media.landscape`
        width: 100%;
    `}
`;
Value.displayName = 'Value';

const StyledInfotip = styled(Infotip)`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: ${props => props.theme.spacing.md};
  width: calc(100% - ${props => props.theme.spacing.md});
  font-size: ${props => props.theme.fontSize.xxs};
`;

const ValueColumn = ({ t, data }) => {
  const value = (
    <Value color={data.color}>
      {data.value}&#8287;{data.unit}
    </Value>
  );
  return (
    <Column>
      {data.infotip ? (
        <StyledInfotip absolute text={t(data.infotip)}>
          {value}
        </StyledInfotip>
      ) : (
        value
      )}
    </Column>
  );
};
ValueColumn.displayName = 'ValueColumn';

ValueColumn.defaultProps = {
  data: {},
};

ValueColumn.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
};

export default ValueColumn;
