import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const MAX_HEIGHT = 780;

const StyledSensor = styled.section`
  height: 100%;
  width: 100vw;
  background-color: ${props => props.theme.colors.alabaster};

  ${props => props.theme.media.landscape`
        max-height: ${MAX_HEIGHT}px;
        height: initial;
        max-width: ${props => props.theme.grid.maxWidth};
        width: 95vw;
    `}
`;
StyledSensor.displayName = 'StyledSensor';

const Sensor = props => <StyledSensor>{props.children}</StyledSensor>;

export default Sensor;

Sensor.propTypes = {
  children: PropTypes.node,
};
