import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

const StyledDateColumn = styled.div`
  text-align: right;
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
`;

const DateColumn = props => {
  if (!props.data) {
    return null;
  }
  const date = moment.utc(props.data.date).local();

  return (
    <StyledDateColumn>
      <div>{date.format('D.M.YYYY - HH:mm')}</div>
    </StyledDateColumn>
  );
};

export default DateColumn;
