import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { announcementShape, ANNOUNCEMENT_TYPE, toPublishedAndScheduled, sortAnnouncements } from './utils';
import AdminAnnouncement from './AdminAnnouncement';
import SkeletonText from 'components/Skeletons/SkeletonText';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  ${props => props.theme.media.desktop`
        flex-direction: row;
        overflow: visible;
    `}
`;

const Column = styled.div`
  margin: 0 0 0.75em 0;
  width: 100%;
  ${props => props.theme.media.desktop`
        margin: 0.75em;
        width: 50%;
    `}
`;

Column.displayName = 'Column';

const Title = styled.span`
  color: ${props => props.theme.colors.rockBlue};
  font-size: ${props => props.theme.font.size.xs};
  font-family: ${props => props.theme.font.family.arial};
  margin-left: 0.6em;
  text-transform: uppercase;
`;

Title.displayName = 'Title';

const AdminAnnouncementColumns = ({ announcements, t, onEdit, onDestroy, loading }) => {
  const processedAnnouncements = toPublishedAndScheduled(sortAnnouncements(announcements));
  const renderAnnouncement = (announcement, key) => (
    <AdminAnnouncement
      t={t}
      announcement={announcement}
      key={key}
      onEdit={() => onEdit(announcement)}
      onDestroy={() => onDestroy(announcement)}
    />
  );

  return (
    <Wrapper>
      <Column>
        <Title data-test-id="publishedTitle">{t('Published')}</Title>
        {loading ? (
          <SkeletonText />
        ) : (
          <div data-test-id="publishedAnnouncements">
            {processedAnnouncements.published.map((announcement, index) =>
              renderAnnouncement(announcement, `announcement-${index}`)
            )}
          </div>
        )}
      </Column>
      <Column data-test-id="scheduledTitle">
        <Title>{t('Scheduled')}</Title>
        {loading ? (
          <SkeletonText />
        ) : (
          <div data-test-id="scheduled">
            {processedAnnouncements.scheduled.map((announcement, index) =>
              renderAnnouncement(announcement, `announcement-${index}`)
            )}
          </div>
        )}
      </Column>
    </Wrapper>
  );
};
AdminAnnouncementColumns.displayName = 'AdminAnnouncementColumns';

AdminAnnouncementColumns.defaultProps = {
  loading: false,
};

AdminAnnouncementColumns.propTypes = {
  announcements: PropTypes.arrayOf(
    PropTypes.oneOfType([
      announcementShape(ANNOUNCEMENT_TYPE.FL),
      announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
      announcementShape(ANNOUNCEMENT_TYPE.SYSTEM),
    ])
  ).isRequired,
  t: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default withTheme(AdminAnnouncementColumns);
