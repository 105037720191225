import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HelpContainer = styled.div`
  display: flex;
  color: ${props => props.theme.colors.rockBlue};
  align-items: center;
  max-width: 560px;
  margin-top: ${props => props.theme.spacing.lg};
  margin-bottom: ${props => props.theme.spacing.xl};
  margin-left: ${props => props.theme.spacing.sm};
  margin-right: ${props => props.theme.spacing.sm};
  line-height: ${props => props.theme.lineHeight.text};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xs};
`;

const Icon = styled.div`
  width: ${props => props.size}px;
  min-width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: ${props => !props.noCirle && props.size / 12}px solid ${props => props.theme.colors.rockBlue};
  margin-right: ${props => props.theme.spacing.md};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: ${props => (props.noCirle ? props.size : props.size * 0.42)}px;
  font-weight: bold;
  color: ${props => props.theme.colors.rockBlue};
`;

const Heading = styled.span`
  font-weight: bold;
`;

const StatisticsHelp = ({ heading, text, useAsterisk }) => (
  <HelpContainer>
    <Icon size={32} noCirle={useAsterisk}>
      {useAsterisk ? '*' : '?'}
    </Icon>
    <span>
      <Heading>{heading}</Heading>&nbsp;
      <span>{text}</span>
    </span>
  </HelpContainer>
);

StatisticsHelp.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  useAsterisk: PropTypes.bool,
};

StatisticsHelp.defaultProps = {
  useAsterisk: false,
};

export default StatisticsHelp;
