import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { findLast } from 'lodash';
import { rgba } from 'polished';
import Button from 'components/Button/Button';
import { showModal } from 'redux/modules/modal/modal';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { uploadAndCreateImage } from 'redux/modules/common/fileupload';
import { deleteImage } from 'redux/modules/customer/partnerImage';
import Loader from 'components/Loader/Loader.jsx';

const CurrentImage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center center;
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const AddNewImage = styled.div`
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSize.xxs};
  border: 1px dashed ${props => rgba(props.theme.colors.white, 0.2)};
  padding: 2em;
  cursor: pointer;
`;

const RemoveButton = styled(Button)`
  min-width: auto;
  max-width: 90%;
`;

export const ImageUpload = ({
  t,
  theme,
  uploadAndCreateImage,
  uploading,
  showModal,
  partnerNumber,
  functionalLocation,
  equipment,
  images,
  deleteImage,
  imageType,
  onUploadSuccess,
  onUploadFail,
  onRemoveSuccess,
  onRemoveFail,
  removeModalType,
}) => {
  const handleRemoveImage = e => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    const lastImage = findLastImage();

    showModal(
      removeModalType,
      null,
      () => removeImage(lastImage),
      `${t('Image')} ${t('{0} will be removed', lastImage.filename)}`
    );
  };

  const removeImage = image => {
    if (image) {
      return deleteImage(image.id)
        .then(result => {
          if (typeof onRemoveSuccess === 'function') {
            return Promise.resolve(onRemoveSuccess(result)).then(Promise.resolve());
          }
          return Promise.resolve();
        })
        .catch(error => {
          if (typeof onRemoveFail === 'function') {
            return Promise.resolve(onRemoveFail(error)).then(Promise.reject(new Error('Deleting Image Failed')));
          }
          return Promise.reject(new Error('Deleting Image Failed'));
        });
    }
    return Promise.reject(new Error('Image was not found'));
  };

  const uploadImage = files => {
    const filename = files[0].name;
    const mimeType = files[0].type;
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target.result) {
        let fileString = event.target.result;
        if (fileString.indexOf(',') !== -1) {
          fileString = fileString.split(',')[1];
        }
        const data = {
          file: fileString,
          container: 'cdn',
          filename: imageType + 'Image/' + filename,
          mimeType,
          imageType: imageType,
        };
        if (functionalLocation) {
          data.functionalLocation = functionalLocation;
        } else if (partnerNumber) {
          data.partnerNumber = partnerNumber;
        } else if (equipment) {
          data.equipment = equipment;
        }
        uploadAndCreateImage(data)
          .then(result => {
            if (typeof onUploadSuccess === 'function') {
              Promise.resolve(onUploadSuccess(result));
            }
          })
          .catch(error => {
            if (typeof onUploadFail === 'function') {
              Promise.resolve(onUploadFail(error)).then(() => {
                throw error;
              });
            } else {
              throw error;
            }
            showModal(MODALTYPE.UPLOAD_ERROR);
          });
      }
    };
    reader.readAsDataURL(files[0]);
  };

  const findLastImage = () => findLast(images, image => image.type === imageType);

  const lastImage = findLastImage();

  if (!imageType) {
    return null;
  }

  if (lastImage) {
    return (
      <CurrentImage image={lastImage.path}>
        <DeleteButtonContainer>
          <RemoveButton remove onClick={handleRemoveImage}>
            {t('Remove image')}
          </RemoveButton>
        </DeleteButtonContainer>
      </CurrentImage>
    );
  }

  if (uploading) {
    return <Loader size="MEDIUM" color="GRAY" />;
  }

  return (
    <Dropzone onDrop={uploadImage} style={{}}>
      <AddNewImage>{t('Click to add image')}</AddNewImage>
    </Dropzone>
  );
};

ImageUpload.defaultProps = {
  uploading: false,
  images: [],
  functionalLocation: null,
  partnerNumber: null,
  equipment: null,
  removeModalType: MODALTYPE.CONFIRMATION_DELETE_IMAGE_MODAL,
  onUploadSuccess: null,
  onUploadFail: null,
  onRemoveSuccess: null,
  onRemoveFail: null,
};

ImageUpload.propTypes = {
  t: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  uploadAndCreateImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  imageType: PropTypes.string.isRequired,
  functionalLocation: PropTypes.string,
  partnerNumber: PropTypes.string,
  equipment: PropTypes.string,
  uploading: PropTypes.bool,
  images: PropTypes.array,
  removeModalType: PropTypes.string,
  onUploadSuccess: PropTypes.func,
  onUploadFail: PropTypes.func,
  onRemoveSuccess: PropTypes.func,
  onRemoveFail: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  showModal: (modalType, preConditions, onSubmitAction) =>
    dispatch(showModal(modalType, preConditions, onSubmitAction)),
  uploadAndCreateImage: data => dispatch(uploadAndCreateImage(data)),
  deleteImage: id => dispatch(deleteImage(id)),
});

const connector = connect(
  null,
  mapDispatchToProps
);

export default connector(withTheme(translations(ImageUpload)));
