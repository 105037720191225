import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { withTheme } from 'styled-components';

import StatisticsBarChart from 'components/Charts/StatisticsBarChart';
import { GroupedServiceOrders } from '../../types';
import { OrderType } from 'constants/serviceCalendar';
import * as utils from '../../utils';
import StatisticsChartContainer from '../../StatisticsChartContainer/StatisticsChartContainer';

const calculateStatistics = memoizeOne(utils.calculateMonthlyStatistics);
const getSeries = memoizeOne(utils.getSeriesFromMonthlyStatistics);

const OrderPerformanceRatio = ({ t, theme, serviceOrders, yearControls }) => {
  const orders = serviceOrders[OrderType.ORDER];
  const stats = calculateStatistics(orders, 'createdDate', utils.getServiceOrderStatus);
  const series = getSeries(stats.monthly, utils.orderStatuses, t);
  const total = utils.getAggregateTotal(stats.year, utils.orderStatuses);
  return (
    <React.Fragment>
      <StatisticsChartContainer
        t={t}
        empty={!total}
        count={total}
        countLabel={t('Service Orders')}
        controls={yearControls}
      >
        <StatisticsBarChart
          t={t}
          type="column"
          height={utils.CHART_HEIGHT}
          hideLegend={false}
          categories={utils.getMonths(t)}
          noZoom
          stacked
          plotBorderWidth="0"
          legendAlign="left"
          labelStyle={utils.getLabelStyle(theme)}
          series={series}
          colors={utils.getOrderColors(theme)}
          yTitle={t('Service Orders')}
          hideDecimals
          backgroundColor="transparent"
        />
      </StatisticsChartContainer>
    </React.Fragment>
  );
};

OrderPerformanceRatio.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  serviceOrders: GroupedServiceOrders.isRequired,
  yearControls: PropTypes.node.isRequired,
};

export default withTheme(OrderPerformanceRatio);
