import React from 'react';
import PropTypes from 'prop-types';
import SortableTable from 'components/SortableTable/SortableTable';
import CustomViewProfileRow from './CustomViewProfileRow';

const CustomViewProfileTable = props => {
  const { data, t, loading, onClick } = props;

  return (
    <SortableTable
      columns={[
        {
          title: t('Active'),
          field: 'active',
          sortable: false,
          align: 'center',
          width: '50px',
        },
        {
          title: t('Name'),
          field: 'name',
          sortable: false,
        },
        {
          title: t('Location'),
          field: 'location',
          sortable: false,
          hideOnMobile: true,
          width: '50%',
        },
      ]}
      data={data}
      RowComponent={CustomViewProfileRow}
      stripes={false}
      t={t}
      loading={loading}
      orderBy="name"
      cellPadding="12px"
      onClick={onClick}
    />
  );
};

CustomViewProfileTable.propTypes = {
  data: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CustomViewProfileTable;
