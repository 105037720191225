import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SortableTable from 'components/SortableTable/SortableTable';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import ConditionRow from './ConditionRow';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { TableHeadCell } from '../../SortableTable/SortableTableHead';

const LargeCell = styled(TableHeadCell)`
  ${props => props.theme.media.landscape`
        width: 30%;
    `}

  ${props => props.theme.media.desktop`
        width: 45%;
    `}
`;

const MediumCell = styled(TableHeadCell)`
  ${props => props.theme.media.landscape`
        width: 30%;
    `}

  ${props => props.theme.media.desktop`
        width: 25%;
    `}
`;

const SmallCell = styled(TableHeadCell)`
  width: 105px;

  ${props => props.theme.media.landscape`
        width: 20%;
    `}

  ${props => props.theme.media.desktop`
        width: 15%;
    `}
`;

const SkeletonBodyComponent = () => (
  <tbody>
    <tr>
      <TableCell>
        <SkeletonText />
      </TableCell>
      <TableCell>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
    </tr>
  </tbody>
);
const ConditionTable = props => {
  const { data, t, loading, onClick } = props;

  return (
    <SortableTable
      columns={[
        {
          title: t('Name'),
          field: 'name',
          sortable: true,
          hideOnDesktop: true,
          component: LargeCell,
        },
        {
          title: t('Name'),
          field: 'name',
          sortable: true,
          hideOnMobile: true,
          component: LargeCell,
        },
        {
          title: t('Type'),
          field: 'type',
          sortable: true,
          align: 'left',
          hideOnMobile: true,
          component: MediumCell,
        },
        {
          title: t('Data status'),
          field: 'status',
          sortable: true,
          align: 'left',
          hideOnMobile: true,
          component: SmallCell,
        },
        {
          title: t('Value'),
          field: 'value',
          align: 'center',
          hideOnMobile: true,
          component: SmallCell,
        },
        {
          title: t('Value'),
          field: 'value',
          align: 'left',
          hideOnDesktop: true,
          component: SmallCell,
        },
        {
          title: '',
          field: 'addon',
          sortable: false,
        },
      ]}
      data={data}
      RowComponent={ConditionRow}
      onClick={onClick}
      SkeletonBodyComponent={SkeletonBodyComponent}
      stripes={false}
      t={t}
      loading={loading}
      orderBy="name"
      cellPadding="12px"
      keyField="key"
    />
  );
};
ConditionTable.displayName = 'ConditionTable';

ConditionTable.defaultProps = {
  loading: false,
};

ConditionTable.propTypes = {
  data: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ConditionTable;
