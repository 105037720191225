import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import themes from 'styles/themes';
import Icon from 'components/Icon/Icon';

// margin-top is a hack to align the box alongside of the charts
const StyledValuesBox = styled.div`
  font-family: ${props => props.theme.font.family.arial};
  border-radius: 0.25rem;
  overflow: hidden;
  margin-bottom: ${props => props.theme.spacing.sm};
`;
StyledValuesBox.displayName = 'StyledValuesBox';

const Wrapper = styled.div`
  border-radius: 0.25rem;
  border-color: ${props => props.theme.colors.lightGray};
  border-style: solid;
  border-width: ${({ theme }) => `${theme.spacing.border} ${theme.spacing.border}`};
  padding: ${props => props.theme.spacing.md};
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
  line-height: 1rem;
  padding: 0.5rem;
`;
Title.displayName = 'Title';

const ValueWrapper = styled.div`
  padding: ${props => props.theme.spacing.md};
  text-align: center;
  line-height: 1;
`;
ValueWrapper.displayName = 'ValueWrapper';

const IconWrapper = styled.div`
  margin-top: ${props => props.theme.spacing.xs};
  text-align: center;
  svg {
    margin: 0;
  }
`;
IconWrapper.displayName = 'IconWrapper';

export const Value = styled.div`
  font-size: ${props => props.theme.fontSize.lg};
  color: ${props => (props.valueIsNegative ? props.theme.colors.radicalRed : props.positiveColor)};
`;
Value.displayName = 'Value';

export const Label = styled.div`
  margin-top: ${props => props.theme.spacing.xxs};
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontSize.xxs};
`;
Label.displayName = 'Label';

export const ValuesBoxFooter = styled.div`
  font-family: ${props => props.theme.font.family.arial};
  background: ${props => props.theme.colors.alabaster};
  overflow: hidden;
  margin: -${props => props.theme.spacing.md};
  margin-top: ${props => props.theme.spacing.sm};
  padding: ${props => props.theme.spacing.sm};
  border-top-color: ${props => props.theme.colors.lightGray};
  border-top-style: solid;
  border-top-width: ${({ theme }) => `${theme.spacing.border}`};
  text-align: center;
  ${Title} {
    padding-top: 0;
  }
  ${Value} {
    font-size: ${props => props.theme.fontSize.md};
    color: ${props => props.theme.colors.amethyst};
  }
  ${Label} {
    margin-top: ${props => props.theme.spacing.sm}};
  }
`;
ValuesBoxFooter.displayName = 'ValuesBoxFooter';

const ValuesBox = ({ heading, value, icon, valueIsNegative, label, children, positiveValueColor, ...others }) => {
  return (
    <StyledValuesBox {...others}>
      <Wrapper>
        {heading && <Title>{heading}</Title>}
        {icon && (
          <IconWrapper>
            <Icon name={icon} fill={valueIsNegative ? null : positiveValueColor} />
          </IconWrapper>
        )}
        {(value || label) && (
          <ValueWrapper>
            {value && (
              <Value valueIsNegative={valueIsNegative} positiveColor={positiveValueColor}>
                {value}
              </Value>
            )}
            {label && <Label>{label}</Label>}
          </ValueWrapper>
        )}
        {children}
      </Wrapper>
    </StyledValuesBox>
  );
};

ValuesBox.defaultProps = {
  heading: '',
  value: '',
  label: '',
  valueIsNegative: false,
  positiveValueColor: themes.customerPlatform.colors.emerald,
};

ValuesBox.propTypes = {
  /** Heading for the box */
  heading: PropTypes.string,
  /** Value with unit */
  value: PropTypes.string,
  /** Value label */
  label: PropTypes.string,
  /** Boolean to indicate value's negative sense in the context of UI */
  valueIsNegative: PropTypes.bool,
  /** Color to be used in positive context */
  positiveValueColor: PropTypes.string,
  children: PropTypes.any,
};

export default ValuesBox;
