import React from 'react';
import styled from 'styled-components';

const Error = styled.div`
  color: ${props => props.theme.colors.radicalRed};
`;

const ErrorText = ({ style, children }) => <Error style={style}>{children}</Error>;

export default ErrorText;
