import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { capitalize, omit } from 'lodash';
import styled from 'styled-components';

import { default as getCalendarWeeks } from './getCalendarWeeks';
import CalendarWeek from './CalendarWeek/CalendarWeek';
import { ViewMode } from 'constants/serviceCalendar';

const CalendarDayHeader = styled.th`
  padding: ${props => props.theme.spacing.xxs} 0 ${props => props.theme.spacing.xs};
  width: 2em;
  text-align: center;
  color: ${props => props.theme.colors.rockBlue};
  font-size: ${props => props.theme.fontSize.xxs};
  font-family: ${props => props.theme.fontFamily.heading};
`;

const ExtraRow = styled.div`
  margin: 6px 0;
  padding: ${props => props.theme.spacing.xxs} 0;
  font-size: ${props => props.theme.font.size.xxs};
`;

const StyledCalendarMonth = styled.li`
  display: inline-block;
  margin: 0 ${props => props.theme.spacing.md};
`;

const CalendarMonthHeader = styled.h4`
  display: flex;
  justify-content: space-between;
  font-family: ${props => props.theme.font.family.sanchez};
  color: ${props => props.theme.colors.cerulean};
  margin-bottom: ${props => props.theme.spacing.md};
  margin-top: ${props => props.theme.spacing.xl};
  text-overflow: ellipsis;
`;

const getExtraRows = (number, tail = []) => {
  return number && number > 0
    ? getExtraRows(
        number - 1,
        tail.concat(
          <tr key={number}>
            <td key={number}>
              <ExtraRow key={number}>&nbsp;</ExtraRow>
            </td>
          </tr>
        )
      )
    : tail;
};

const LOADING_INDICATOR = '···';

const CalendarMonth = ({ date, serviceOrderCount, location, partnerNumber, statusesByDay, query }) => {
  const qs = queryString.stringify({
    ...omit(query, ['day']),
    year: date.format('YYYY'),
    months: date.format('MM'),
    mode: ViewMode.LIST,
  });

  const monthLink = `${location.pathname}?${qs}`;
  const weeks = getCalendarWeeks(date);
  const days = moment.weekdaysMin().map(day => ({ day, name: day.split('')[0].toUpperCase() }));

  // moment.weekdaysMin() always has Sunday as index 0, move that to last
  days.push(days.shift());
  const count = serviceOrderCount || serviceOrderCount === 0 ? serviceOrderCount : LOADING_INDICATOR;

  return (
    <StyledCalendarMonth>
      <CalendarMonthHeader>
        <Link to={monthLink}>
          {' '}
          {capitalize(date.format('MMMM'))} ({count}){' '}
        </Link>
      </CalendarMonthHeader>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            {days.map(day => {
              const key = `day-header-${day.day}`;
              return <CalendarDayHeader key={key}>{day.name}</CalendarDayHeader>;
            })}
          </tr>
        </thead>
        <tbody>
          {weeks.map(week => (
            <CalendarWeek
              key={week.number}
              week={week}
              pathname={location.pathname}
              partnerNumber={partnerNumber}
              statusesByDay={statusesByDay}
              query={query}
            />
          ))}
          {getExtraRows(6 - weeks.length) // Add extra rows to months with only 5 or 4 weeks.
          }
        </tbody>
      </table>
    </StyledCalendarMonth>
  );
};

CalendarMonth.propTypes = {
  date: PropTypes.instanceOf(moment).isRequired,
  location: PropTypes.object.isRequired,
  partnerNumber: PropTypes.string.isRequired,
  serviceOrderCount: PropTypes.number,
  statusesByDay: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.instanceOf(Set))),
  query: PropTypes.object.isRequired,
};

export default React.memo(CalendarMonth);
