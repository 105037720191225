import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { orderBy, isEmpty, find } from 'lodash';
import { rgba } from 'polished';

import ButtonDropdown from 'components/Button/ButtonDropdown';
import CircleTabsContainer from 'components/CircleTabs/CircleTabsContainer';
import CircleTabs from 'components/CircleTabs/CircleTabs';
import CircleTab from 'components/CircleTabs/CircleTab';
import Section from 'components/Section/Section';
import Floor from './Floor';

import { Icon, ICON_TYPES, ICON_SIZES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';

const StyledSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  h3,
  h4 {
    min-width: 120px;
  }
`;

const FloorIconGroup = styled.div`
  margin-right: auto;
  div {
    margin: 8px;
  }
`;

export const BuildingAdminCircleTabsContainer = styled(CircleTabsContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: 0 0.125rem 1rem ${props => rgba(props.theme.colors.black, 0.15)};
  margin: 0 auto 2em;
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  background-color: ${props => props.theme.colors.white};

  ${props => props.theme.media.landscape`
        margin: -2em auto 2em;
    `};
`;

export const BuildingAdminCircleTabs = styled(CircleTabs)`
  display: flex;
  align-items: center;
  box-shadow: none;
`;

const BuildingAdminFloors = ({
  t,
  theme,
  floors,
  floorData,
  addFloor,
  addSensor,
  addArea,
  editFloor,
  handleRemoveFloorModal,
  handleScrollToNode,
  onCurrentFloorChange,
  currentFloorId,
}) => {
  const orderedFloors = floors && floors.length > 0 ? orderBy(floors, ['order'], ['asc']) : [];

  const currentFloor = find(floors, { id: currentFloorId });

  const getCurrentFloorTitle = t => {
    return currentFloor ? `${t('Floor')} ${currentFloor.shortName}` : '';
  };

  return (
    <Fragment>
      <BuildingAdminCircleTabsContainer>
        <BuildingAdminCircleTabs align="left">
          <p>{t(floors && floors.length > 0 ? 'Select floor' : 'Add Floor') + ':'}</p>
          {orderedFloors.map(floor => (
            <CircleTab key={`${floor.id}`} selected={floor.id === currentFloorId} selectedColor={theme.colors.rockBlue}>
              <a href="" onClick={e => onCurrentFloorChange(e, floor.id)}>
                {floor.shortName}
              </a>
            </CircleTab>
          ))}
          <CircleTab selected selectedColor={theme.colors.cerulean}>
            <a href="" onClick={addFloor}>
              <p style={{ fontSize: '2em' }}>+</p>
            </a>
          </CircleTab>
        </BuildingAdminCircleTabs>
      </BuildingAdminCircleTabsContainer>

      {floors && floors.length > 0 && currentFloorId && (
        <Section>
          <StyledSection>
            <h3>{getCurrentFloorTitle(t)}</h3>
            <FloorIconGroup>
              <Icon name={icons.PEN} iconType={ICON_TYPES.DEFAULT} size={ICON_SIZES.SMALL} onClick={editFloor} />
              <Icon
                name={icons.DEL}
                iconType={ICON_TYPES.TRASH_CAN}
                size={ICON_SIZES.SMALL}
                onClick={() => handleRemoveFloorModal(currentFloorId, currentFloor.shortName)}
              />
            </FloorIconGroup>
            <ButtonDropdown
              buttonLabel={`${t('Functions')} +`}
              items={[
                { label: t('Add device'), onClick: e => addSensor(e, currentFloorId) },
                { label: t('Add custom shape area'), onClick: addArea },
              ]}
            />
          </StyledSection>
          {!isEmpty(floorData) && (
            <Floor scrollToNode={handleScrollToNode} t={t} floorData={floorData} key={'floor' + currentFloorId} />
          )}
        </Section>
      )}
    </Fragment>
  );
};

BuildingAdminFloors.propTypes = {
  /** Translations function */
  t: PropTypes.func.isRequired,
  /** Floors on current hierarchy */
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Floor data for Blueprint */
  floorData: PropTypes.object.isRequired,
  /** Theme object */
  theme: PropTypes.object.isRequired,
  /** Functions: */
  addFloor: PropTypes.func.isRequired,
  addSensor: PropTypes.func.isRequired,
  addArea: PropTypes.func.isRequired,
  editFloor: PropTypes.func.isRequired,
  handleRemoveFloorModal: PropTypes.func.isRequired,
  handleScrollToNode: PropTypes.func.isRequired,
  onCurrentFloorChange: PropTypes.func.isRequired,
  /** Current (selected) floor id */
  currentFloorId: PropTypes.number,
};

export default withTheme(BuildingAdminFloors);
