import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';

const initialState = {
  sending: false,
  error: null,
};

export const CREATE = 'CUSTOMER_PLATFORM/ContactMessages/CREATE';
export const CREATE_SUCCESS = 'CUSTOMER_PLATFORM/ContactMessages/CREATE_SUCCESS';
export const CREATE_FAIL = 'CUSTOMER_PLATFORM/ContactMessages/CREATE_FAIL';

export const createContactMessage = contactMessage => {
  return async dispatch => {
    dispatch({ type: CREATE });
    try {
      const result = await dispatch(MasterData.createContactMessage(JSON.stringify(contactMessage)));
      return dispatch({
        type: CREATE_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: CREATE_FAIL,
        error: 'Sending message failed',
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [CREATE]: (state, action) => ({
      ...state,
      sending: true,
      error: null,
    }),
    [CREATE_SUCCESS]: (state, action) => ({
      ...state,
      sending: false,
    }),
    [CREATE_FAIL]: (state, action) => ({
      ...state,
      sending: false,
      error: action.error,
    }),
  },
  initialState
);
