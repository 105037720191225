import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled, { withTheme } from 'styled-components';
import Svg from 'components/Svg/Svg';
import { statusToColor, statusToIcon, announcementShape, ANNOUNCEMENT_TYPE } from './utils';
import MarkdownContent from './MarkdownContent';

const Content = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: left;
  line-height: ${props => props.theme.font.lineHeight.lg};
`;
Content.displayName = 'Content';

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 0 1em;
`;
Header.displayName = 'Header';

const Icon = styled(({ status, ...props }) => <Svg {...props} />)`
  font-size: ${props => props.theme.text.h1};
  fill: ${props => props.fill};
  margin-right: 0.25em;
`;
Icon.displayName = 'Icon';

const Title = styled.h2`
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.font.weight.bold};
`;
Title.displayName = 'Title';

const StyledDate = styled.p`
  color: ${props => props.theme.colors.rockBlue};
  font-size: ${props => props.theme.font.size.sm};
  font-family: ${props => props.theme.font.family.arial};
  font-style: italic;
  margin: 1em 0;
`;
StyledDate.displayName = 'StyledDate';

const AnnouncementModalContent = ({ announcement }) => (
  <Content>
    <Header>
      <Icon name={statusToIcon[announcement.status]} fill={statusToColor[announcement.status]} />
      <Title>{announcement.title}</Title>
    </Header>
    <MarkdownContent content={announcement.description} />
    <StyledDate>
      {moment
        .utc(announcement.startDate)
        .local()
        .format('DD.MM.YYYY')}
    </StyledDate>
  </Content>
);

AnnouncementModalContent.propTypes = {
  announcement: PropTypes.oneOfType([
    announcementShape(ANNOUNCEMENT_TYPE.FL),
    announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
    announcementShape(ANNOUNCEMENT_TYPE.SYSTEM),
  ]),
};

export default withTheme(AnnouncementModalContent);
