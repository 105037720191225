import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import SortableTable from 'components/SortableTable/SortableTable';
import EventsCell from 'components/BuildingEvents/EventsRow/EventsCell';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { Info } from './Api';

const Row = styled.tr`
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.colors.black};
  border-bottom: ${props => props.theme.spacing.border} solid ${props => props.theme.colors.alabaster};
  &:last-child {
    border-bottom: none;
  }
`;

const ApiKeyTableRow = ({ t, rowData }) => {
  const expires = rowData.expires ? moment(rowData.expires) : undefined;
  return (
    <Row>
      <EventsCell>{rowData.name || '-'}</EventsCell>
      <EventsCell hideOnMobile>
        {moment(rowData.created)
          .local()
          .format('DD.MM.YYYY')}
      </EventsCell>
      <EventsCell>
        {expires ? expires.local().format('DD.MM.YYYY') : t('Never')}
        {expires && expires.isBefore(moment()) && ` (${t('expired')})`}
      </EventsCell>
      <EventsCell>
        <Button type="button" remove autoWidth onClick={rowData.handleDeleteClick}>
          {t('Delete')}
        </Button>
      </EventsCell>
    </Row>
  );
};

const ApiKeyTable = ({ t, profileId, apiKeys, showModal, deleteApiKey }) => {
  const columns = React.useMemo(
    () => [
      {
        title: t('Name'),
        field: 'name',
        sortable: true,
      },
      {
        title: t('Created'),
        field: 'created',
        sortable: true,
        hideOnMobile: true,
      },
      {
        title: t('Expires'),
        field: 'expires',
        sortable: true,
      },
      {
        width: '100px',
        mobileWidth: '100px',
      },
    ],
    [t]
  );

  const data = React.useMemo(
    () =>
      apiKeys &&
      apiKeys.data &&
      apiKeys.data.map(apiKey => ({
        ...apiKey,
        handleDeleteClick: () =>
          showModal(
            MODALTYPE.CONFIRMATION_DELETE_API_KEY,
            null,
            async () => deleteApiKey(profileId, apiKey.id),
            t(`API key "{0}" will be deleted and all applications using it will stop working.`, apiKey.name)
          ),
      })),
    [t, apiKeys, showModal, deleteApiKey, profileId]
  );

  if (!apiKeys || apiKeys.loading) {
    return <Loader />;
  }

  if (apiKeys.error) {
    return t('An error occurred while fetching API keys. Please try again later.');
  }

  return (
    <React.Fragment>
      {data.length ? (
        <SortableTable t={t} pure columns={columns} data={data} RowComponent={ApiKeyTableRow} />
      ) : (
        <Info>{t('You have not created API keys yet. Click "Generate new key" to start using the API.')}</Info>
      )}
    </React.Fragment>
  );
};

ApiKeyTable.propTypes = {
  t: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  apiKeys: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: PropTypes.array,
  }),
  deleteApiKey: PropTypes.func.isRequired,
  profileId: PropTypes.string.isRequired,
};

export default ApiKeyTable;
