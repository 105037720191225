import { find } from 'lodash';
import { divisionOptions } from 'containers/Application/Admin/UserForm/Settings/Settings';

export const showContactCaverion = (partnerMeta, profile) =>
  !!(divisionEmail(profile.division) || (find(partnerMeta, { key: 'contact_email' }) || {}).value);

export const contactPersonInfo = partnerMeta => ({
  header: 'Caverion contact person',
  name: (find(partnerMeta, { key: 'contact_name' }) || {}).value,
  tel: (find(partnerMeta, { key: 'contact_tel' }) || {}).value,
  email: (find(partnerMeta, { key: 'contact_email' }) || {}).value,
});

export const contactSalesInfo = (profile, t) => ({
  header: 'Division sales',
  name: `Caverion ${divisionName(profile.division, t)}`,
  tel: divisionTel(profile.division),
  email: divisionEmail(profile.division),
});

const divisionName = (division, t) => (find(divisionOptions(t), { value: division }) || {}).label || division;

const divisionTel = division => {
  switch (division) {
    case 'fi':
      return process.env.REACT_APP_SALES_FI_DIVISION_TEL;
    case 'se':
      return process.env.REACT_APP_SALES_SE_DIVISION_TEL;
    case 'no':
      return process.env.REACT_APP_SALES_NO_DIVISION_TEL;
    case 'dk':
      return process.env.REACT_APP_SALES_DK_DIVISION_TEL;
    case 'lt':
      return process.env.REACT_APP_SALES_LT_DIVISION_TEL;
    case 'de':
      return process.env.REACT_APP_SALES_DE_DIVISION_TEL;
    case 'ru':
      return process.env.REACT_APP_SALES_RU_DIVISION_TEL;
    case 'at':
      return process.env.REACT_APP_SALES_AT_DIVISION_TEL;
    case 'development':
      return process.env.REACT_APP_SALES_DEVELOPMENT_DIVISION_TEL;
    default:
      break;
  }
};

const divisionEmail = division => {
  switch (division) {
    case 'fi':
      return process.env.REACT_APP_SALES_FI_DIVISION_EMAIL;
    case 'se':
      return process.env.REACT_APP_SALES_SE_DIVISION_EMAIL;
    case 'no':
      return process.env.REACT_APP_SALES_NO_DIVISION_EMAIL;
    case 'dk':
      return process.env.REACT_APP_SALES_DK_DIVISION_EMAIL;
    case 'lt':
      return process.env.REACT_APP_SALES_LT_DIVISION_EMAIL;
    case 'de':
      return process.env.REACT_APP_SALES_DE_DIVISION_EMAIL;
    case 'ru':
      return process.env.REACT_APP_SALES_RU_DIVISION_EMAIL;
    case 'at':
      return process.env.REACT_APP_SALES_AT_DIVISION_EMAIL;
    case 'development':
      return process.env.REACT_APP_SALES_DEVELOPMENT_DIVISION_EMAIL;
    default:
      break;
  }
};
