import React from 'react';
import PropTypes from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';
import pullAt from 'lodash/pullAt';
import setWith from 'lodash/setWith';
import find from 'lodash/find';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';

import MultiInput from 'components/Form/MultiInput/MultiInput';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import InfoBox from 'components/InfoBox/InfoBox';
import Button from 'components/Button/Button';
import { Columns, Column } from 'components/Columns/index';
import InputLabel from 'components/Form/InputLabel';
import InputDate from 'components/Form/InputDate';

import { newSensorValue, formatSensorValue } from './utils';

const CustomDateInput = props => (
  <div>
    <InputDate simple showTimeSelect {...props} />
  </div>
);

const ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.spacing.lg};
`;

const SingleValuesForm = ({ t, sensors, sensorTypes, onSubmitValues, sensorAdminTo }) => {
  const [singleValues, setSingleValues] = React.useState([newSensorValue()]);
  const [sensorId, setSensorId] = React.useState(null);
  const [aggregation, setAggregation] = React.useState('raw');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleAddRow = () => setSingleValues(prevValues => [...prevValues, newSensorValue()]);

  const handleRemoveRow = rowIndex => {
    setSingleValues(prevValues => {
      const clonedValues = cloneDeep(prevValues);
      pullAt(clonedValues, rowIndex);
      return clonedValues;
    });
  };

  const handleRowChange = (property, value) => {
    setSingleValues(prevValues => {
      const newValues = setWith({ singleValues: cloneDeep(prevValues) }, property, value, Object);
      return newValues.singleValues;
    });
  };

  const handleSubmit = async () => {
    setError('');

    const values = singleValues
      .filter(value => value.value !== '')
      .map(value => ({
        value: formatSensorValue(value.value),
        sensorId,
        aggregation,
        timestamp: value.timestamp || moment.utc().toISOString(),
      }));

    if (some(values, value => Number.isNaN(value.value))) {
      setError(t('Input only numeric values'));
      return;
    }

    setLoading(true);
    await onSubmitValues(values);
    setLoading(false);
  };

  const handleSensorChange = (property, value) => {
    setSensorId(value);
    setAggregation('raw');
  };
  const handleAggregationChange = (property, value) => setAggregation(value);

  const sensorOptions = React.useMemo(
    () => {
      const measuringPoints = filter(sensors, sensor => isEmpty(sensor.children));
      return map(measuringPoints, sensor => ({ label: `${sensor.name} (${sensor.id})`, value: sensor.id }));
    },
    [sensors]
  );

  const sensor = find(sensors, { id: sensorId });
  const sensorType = sensor && find(sensorTypes, { id: sensor.sensorTypeId });

  const aggregations = (sensorType && !isEmpty(sensorType.aggregations) && sensorType.aggregations) || [];
  const aggregationOptions = map(aggregations, aggr => ({ label: aggr.aggregation, value: aggr.aggregation }));
  aggregationOptions.push({ label: 'raw', value: 'raw' });

  return (
    <React.Fragment>
      <Columns>
        <Column
          columnWidth={{
            desktop: 6,
            landscape: 6,
            portrait: 6,
            default: 12,
          }}
        >
          <InputLabel text={t('Sensor')} />
          <InputSelectDropdown
            t={t}
            model={{ sensorId }}
            options={sensorOptions}
            onChange={handleSensorChange}
            property="sensorId"
            placeholder={t('Select sensor')}
          />
        </Column>
        <Column
          columnWidth={{
            desktop: 6,
            landscape: 6,
            portrait: 6,
            default: 12,
          }}
        >
          <InputLabel text={t('Aggregation')} />
          <InputSelectDropdown
            t={t}
            model={{ aggregation }}
            options={aggregationOptions}
            onChange={handleAggregationChange}
            property="aggregation"
            placeholder={t('Select aggregation')}
          />
        </Column>
      </Columns>
      {(!sensor || !aggregation) && (
        <InfoBox>
          {`${t('Select sensor and aggregation')} ${t('or')} `}
          <Link to={sensorAdminTo}>{t('create new sensor')}</Link>
        </InfoBox>
      )}
      {error && <InfoBox error>{error}</InfoBox>}
      <MultiInput
        dense
        t={t}
        model={{ singleValues }}
        onAddRow={handleAddRow}
        onRemoveRow={handleRemoveRow}
        onPropertyChange={handleRowChange}
        baseProperty="singleValues"
        subProperties={['value', 'timestamp']}
        subPropertyLabels={{ value: t('Value'), timestamp: t('Timestamp') }}
        subPropertyComponents={{ timestamp: CustomDateInput }}
      />
      <ButtonWrapper>
        <Button
          submit
          disabled={!sensor || !aggregation || isEmpty(singleValues)}
          onClick={handleSubmit}
          loading={loading}
        >
          {t('Send')}
        </Button>
      </ButtonWrapper>
    </React.Fragment>
  );
};

SingleValuesForm.propTypes = {
  t: PropTypes.func.isRequired,
  sensors: PropTypes.arrayOf(PropTypes.object).isRequired,
  sensorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmitValues: PropTypes.func.isRequired,
  sensorAdminTo: PropTypes.string.isRequired,
};

export default React.memo(SingleValuesForm);
