import React, { Fragment } from 'react';
import clone from 'lodash/clone';
import some from 'lodash/some';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import MaintenanceModule from 'containers/Application/Modules/MaintenanceModule/MaintenanceModule.jsx';
import FunctionalLocationsModule from 'components/Modules/FunctionalLocationsModule/FunctionalLocationsModule.jsx';
import ExternalDocumentModule from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentModule.jsx';
import EquipmentModule from 'containers/Application/Modules/EquipmentModule/EquipmentModule.jsx';
import Hero from 'components/Hero/Hero.jsx';
import Header from 'containers/Application/Header/Header.jsx';
import ServiceRequest from 'containers/Application/ServiceRequest/ServiceRequest';
import CustomViewModule from 'containers/Application/CustomViews/CustomViewModule';
import TabContent from 'components/TabContent/TabContent';

import {
  addServiceOrderLink,
  addCCCLink,
  addTechnicalLink,
  addEquipmentLink,
  addExternalDocumentsLink,
  addCustomViewLinks,
  addNewServiceRequestLink,
  isSystem,
  isTechnicalObject,
} from 'utils/Data/functionalLocations';
import { isServiceOrdersEnabled, isTechnicalTabEnabled, isEquipmentEnabled } from 'utils/Data/profileData';

export const GenericFunctionalLocation = props => {
  const {
    functionalLocation,
    features,
    featureTeasers,
    equipments,
    parents,
    getHeroContext,
    loadingParent,
    loadingEquipment,
    t,
    partnerNumber,
    documentCount,
    loadingDocuments,
    equipmentCount,
    totalFunctionalLocations,
    loadingFunctionalLocations,
    noIoTData,
    buildingImage,
    loadingChildren,
    childFunctionalLocations,
    page,
    match: { url },
    match,
    customViews,
    profile,
    defaultTab,
  } = props;

  const type = functionalLocation.type || 'UNKNOWN';

  let links = [];
  links = addServiceOrderLink(links, url, features);
  links = addCCCLink(links, url, features, functionalLocation, noIoTData);
  links = addTechnicalLink(
    links,
    url,
    features,
    functionalLocation,
    totalFunctionalLocations,
    loadingFunctionalLocations,
    featureTeasers
  );
  links = addEquipmentLink(links, url, features, equipmentCount, loadingEquipment, featureTeasers);
  links = addExternalDocumentsLink(links, url, features, documentCount, loadingDocuments, featureTeasers);
  links = addCustomViewLinks(links, url, customViews);

  let linksMobile = clone(links);
  linksMobile = addNewServiceRequestLink(linksMobile, url, features);

  const heroContext = getHeroContext(parents, partnerNumber, features, profile.customViews);
  const context = Object.assign({}, heroContext, { loadingContext: loadingParent, functionalLocation });
  const showEquipment = some(childFunctionalLocations, isSystem);

  return (
    <Fragment>
      <Header context={context} t={t} links={links} linksMobile={linksMobile} />
      <Hero
        title={functionalLocation.description}
        functionalLocation={functionalLocation}
        type={type}
        loadingContext={loadingParent}
        t={t}
        showAddress
        partnerNumber={partnerNumber}
        heroImage={buildingImage}
      />
      <TabContent>
        <Switch>
          {features?.documents && (
            <Route path={`${match?.path}/ExternalDocuments`}>
              <ExternalDocumentModule functionalLocation={functionalLocation} />
            </Route>
          )}
          {features?.sr && (
            <Route path={`${match?.path}/ServiceRequest`}>
              <ServiceRequest />
            </Route>
          )}
          {customViews?.map(customView => (
            <Route path={`${match?.path}/${customView.id}`} key={customView.id}>
              <CustomViewModule customView={customView} />
            </Route>
          ))}
          {isServiceOrdersEnabled(features) && (
            <Route path={`${match?.path}/ServiceCalendar`}>
              <MaintenanceModule functionalLocation={functionalLocation} partnerNumber={partnerNumber} />
            </Route>
          )}
          {isTechnicalTabEnabled(features) && (
            <Route path={`${match?.path}/${isTechnicalObject(functionalLocation) ? 'Systems' : 'Technical'}`}>
              <FunctionalLocationsModule
                t={t}
                loadingChildren={loadingChildren}
                childFunctionalLocations={childFunctionalLocations}
                partnerNumber={partnerNumber}
                page={page}
                hasEquipment={showEquipment}
                title={isTechnicalObject(functionalLocation) && t('Systems')}
                equipments={equipments}
              />
            </Route>
          )}
          {equipments && isEquipmentEnabled(features) && (
            <Route path={`${match?.path}/Equipment`}>
              <EquipmentModule equipments={equipments} loadingEquipment={loadingEquipment} />
            </Route>
          )}
          <Redirect exact from={`${match?.path}/`} to={`${match?.path}/${defaultTab}`} />
        </Switch>
      </TabContent>
    </Fragment>
  );
};

GenericFunctionalLocation.propTypes = {
  t: PropTypes.func.isRequired,
  loadingParent: PropTypes.bool.isRequired,
  loadingChildren: PropTypes.bool.isRequired,
  loadingEquipment: PropTypes.bool.isRequired,
  loadingDocuments: PropTypes.bool.isRequired,
  loadingFunctionalLocations: PropTypes.bool.isRequired,
  equipments: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  featureTeasers: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  functionalLocation: PropTypes.object.isRequired,
  getHeroContext: PropTypes.func.isRequired,
  partnerNumber: PropTypes.string.isRequired,
  parents: PropTypes.array.isRequired,
  documentCount: PropTypes.number.isRequired,
  equipmentCount: PropTypes.number.isRequired,
  totalFunctionalLocations: PropTypes.number.isRequired,
  buildingImage: PropTypes.string,
  page: PropTypes.number.isRequired,
  childFunctionalLocations: PropTypes.array.isRequired,
  customViews: PropTypes.array.isRequired,
  defaultTab: PropTypes.string.isRequired,
  noIoTData: PropTypes.bool.isRequired,
};

export default withRouter(GenericFunctionalLocation);
