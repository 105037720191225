import {
  each,
  find,
  includes,
  mergeWith,
  isArray,
  sortBy,
  isEqual,
  pickBy,
  keys,
  some,
  inRange,
  isPlainObject,
  isEmpty,
} from 'lodash';
import memoizeOne from 'memoize-one';
import { isValidPartner } from 'utils/Data/partners';

export const generateFilterInputs = memoizeOne((buildingMeta, t) => {
  if (buildingMeta && buildingMeta.meta) {
    const metaKeys = [];

    each(buildingMeta.meta, flMeta => {
      each(flMeta, singleMeta => {
        if (!!singleMeta.filterable) {
          const { key, value } = singleMeta;
          const metaKey = find(metaKeys, _metaKey => _metaKey.key === key);
          const objetValue = { label: t(value), value };
          if (metaKey) {
            if (!find(metaKey.values, valueObject => valueObject.value === value)) {
              metaKey.values.push(objetValue);
            }
          } else {
            const object = {};
            object.key = key;
            object.values = [objetValue];
            metaKeys.push(object);
          }
        }
      });
    });
    return metaKeys;
  }
});

/**
 * FL:s are filtered based in building meta.
 * Here we find FL:s with meta that is included in the selected filter.
 */
export const getFLsByFilterMatchedBuildingMeta = (buildingMetas, selectedFilterValues) => {
  const FlArray = [];
  // loop over building meta
  each(buildingMetas, (flMeta, fl) => {
    let found = false;
    // check if selected filters apply to current FL
    each(selectedFilterValues, (value, key) => {
      // find corresponding meta for current filter (also checks that meta value matches filtering)
      const foundMeta = find(
        flMeta,
        metaRow =>
          metaRow.key === key && isArray(value) // multi dropdown and slider values are arrays
            ? isPlainObject(value[0]) // multi dropdown individial values are option-objects
              ? some(value, { value: metaRow.value }) // check that meta value is one of multiple filter values
              : inRange(metaRow.value, value[0], value[1] + 1) // check that meta value is in filtered range
            : metaRow.value === value // or simple value check
      );
      if (foundMeta) {
        found = true;
      } else {
        // current filter didn't apply, so FL shouldn't be included in the result
        found = false;
        return false; // exit from each
      }
    });

    // all filters applied to FL:s meta => push FL to result and reset found
    if (found) {
      FlArray.push(fl);
      found = false;
    }
  });
  return FlArray;
};

export const constructAndSortEnergyValues = (energyValues, filterableFLs) => {
  const results = {};
  each(energyValues, (energyValue, key) => {
    if (includes(filterableFLs, key)) {
      mergeWith(results, energyValue, (objValue, srcValue) => {
        if (isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      });
    }
  });
  each(results, (result, key) => {
    results[key] = sortBy(result, 'timestamp');
  });
  return results;
};

export const extendEnergyRatingObjectsForBenchMarking = (energyRatingValues, functionalLocations, t) => {
  each(energyRatingValues, energyRatingObject => {
    const fl = functionalLocations[energyRatingObject.functionalLocation];
    energyRatingObject.title = fl ? fl.name : energyRatingObject.functionalLocation;
    energyRatingObject.value = energyRatingObject.latest;
    energyRatingObject.address = fl ? `${fl.address}, ${fl.postalCode} ${fl.city}` : t('No Address Found');
  });
};

export const hasPartnerChanged = (prevProps, partnerNumber) =>
  isValidPartner(partnerNumber) && !isEqual(prevProps.match.params.partnerNumber, partnerNumber);

export const hasEnergyValuesByPartnerChanged = (prevProps, partnerNumber, energyValuesByPartner) =>
  isValidPartner(partnerNumber) &&
  !isEqual(prevProps.energyValuesByPartner[partnerNumber], energyValuesByPartner[partnerNumber]);

export const filterFLsBySelectedPartner = (functionalLocations, partnerNumber) =>
  pickBy(functionalLocations, fl => includes(fl.partnerNumber, partnerNumber));

export const hasFunctionalLocationsChanged = (functionalLocations, prevProps) =>
  functionalLocations &&
  keys(functionalLocations).length > 0 &&
  !isEqual(functionalLocations, prevProps.functionalLocations);

export const hasFilters = filter =>
  !isEmpty(filter) && some(filter, option => option.values && option.values.length > 1);
