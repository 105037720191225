import React from 'react';
import { isEmpty, filter, map } from 'lodash';
import InputLabel from 'components/Form/InputLabel';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import PropTypes from 'prop-types';

const SensorSelector = props => {
  const { sensorId, setSensorId, setAggregation, t, sensors, placeholder } = props;

  const handleSensorChange = (property, value) => {
    setSensorId(value);
    setAggregation('raw');
  };

  const sensorOptions = React.useMemo(
    () => {
      const measuringPoints = filter(sensors, sensor => isEmpty(sensor.children));
      return map(measuringPoints, sensor => ({ label: `${sensor.id}: ${sensor.name}`, value: sensor.id }));
    },
    [sensors]
  );

  return (
    <React.Fragment>
      <InputLabel text={t('Sensor')} />
      <InputSelectDropdown
        t={t}
        model={{ sensorId }}
        options={sensorOptions}
        onChange={handleSensorChange}
        property="sensorId"
        placeholder={placeholder}
      />
    </React.Fragment>
  );
};

SensorSelector.propTypes = {
  sensorId: PropTypes.number,
  t: PropTypes.func.isRequired,
  sensors: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSensorId: PropTypes.func.isRequired,
  setAggregation: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default React.memo(SensorSelector);
