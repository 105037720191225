import { createGlobalStyle } from 'styled-components';

const BaseCss = createGlobalStyle`
    h1, h2, h3, h4, h5, h6 {
    font-family: 'Sanchez-Regular', serif;
    color: black; }

    h1 {
    font-size: 2.75em;
    line-height: 1.2em; }
    h1.huge {
        font-size: 5em;
        line-height: 1.125em; }
    @media (max-width: 48em) {
        h1 {
        font-size: 2.5em; } }
    @media (max-width: 425px) {
        h1 {
        font-size: 1.75em; } }

    h2 {
    font-size: 2.5em;
    line-height: 1.125em; }
    @media (max-width: 48em) {
        h2 {
        font-size: 1.75em; } }
    @media (max-width: 425px) {
        h2 {
        font-size: 1.25em; } }

    h3 {
    font-size: 1.75em;
    line-height: 1.2em; }

    h4 {
    font-size: 1.25em;
    line-height: 1.2em; }
    h4.flat {
        line-height: 1em; }

    h6 {
    font-size: 0.875em;
    line-height: 1.143em; }

    small {
    font-size: 0.75em; }

    .bold {
    font-weight: bold; }

    .white {
    color: #ffffff; }

    .center {
    text-align: center; }

    .fade-enter, .fade-appear {
    opacity: 0.01; }

    .fade-enter.fade-enter-active, .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 280ms ease-in; }

    .fade-exit {
    opacity: 1; }

    .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 280ms ease-out; }

    .noscroll {
    overflow: hidden; }

    #giosg_chat_now_button {
        display: none !important;
    }
`;

export default BaseCss;
