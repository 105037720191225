import React from 'react';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SkeletonText from 'components/Skeletons/SkeletonText';
import BreakdownAllWasteTypesSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownAllWasteTypes/BreakdownAllWasteTypesSkeleton';

const WasteBreakdownSkeleton = () => (
  <Section>
    <SectionHeader>
      <SkeletonText style={{ height: '2em' }} />
    </SectionHeader>
    <BreakdownAllWasteTypesSkeleton />
  </Section>
);

export default WasteBreakdownSkeleton;
