import moment from 'moment';

export const newSensorValue = () => ({ value: '', timestamp: moment.utc().toISOString() });

export const formatSensorValue = value => {
  const parts = value.split(/[.,]/g);

  if (parts.length === 1) {
    return +parts[0];
  }

  return +`${parts.slice(0, -1).join('')}.${parts[parts.length - 1]}`;
};
