import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components/Icon/Icon';
import { Link } from './AnnouncementLink';

export const FeedbackLink = ({ onClick, t }) => (
  <Link data-test-id="Feedback" onClick={onClick}>
    <Icon name="smiley-good" fill="currentColor" />
    {t('Feedback')}
  </Link>
);

FeedbackLink.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
