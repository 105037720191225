import React, { Fragment, useState, createRef } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import QueryLink from '../QueryLink/QueryLink.jsx';
import SkeletonCircle from 'components/Skeletons/SkeletonCircle';
import Infotip, { InfotipContent, InfotipContentWrapper } from 'components/Infotip/Infotip';
import { toggleScroll } from 'utils/Modal/toggleScroll';
import withQuery from 'decorators/Query/withQuery';

const performanceColors = props => ({
  ok: props.theme.status.okColor,
  warning: props.theme.status.warningColor,
  neutral: props.theme.colors.rockBlue,
});

const selectedPerformanceColors = props => ({
  ok: props.theme.status.okColor,
  warning: props.theme.status.warningColor,
  neutral: props.theme.colors.blue,
});

export const FloorTabsContainer = styled.div`
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});

  ${props => props.theme.media.landscape`
        margin: -2em auto 0;
    `};
`;

// Scroll overflow handling
export const ScrollContainer = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FloorTabsListContainer = styled.div`
  height: ${props => props.theme.navigation.height};
`;

export const FloorTabsList = styled.div`
    position: relative;
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => `0 2px 5px ${rgba(props.theme.colors.black, 0.1)}`};
    white-space: nowrap;
    text-align: center;
    font-family: ${props => props.theme.font.sanchez};

    ${props =>
      props.fixedMobile &&
      css`
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: ${props => props.theme.zIndex('circleTabs')};
      `};

    ${props => props.theme.media.portrait`
        position: initial;
        ${props =>
          props.fixedTablet &&
          css`
            position: fixed;
            top: 80px;
          `}
    `}

    ${props => props.theme.media.landscape`
        position: initial;
        ${props =>
          props.fixed &&
          css`
            position: fixed;
            top: ${props => props.theme.navigation.height};
            left: ${props =>
              props.naviExtended ? props.theme.navigation.sideTabletWidth : props.theme.navigation.sideSmallWidth};
            z-index: ${props => props.theme.zIndex('circleTabs')};
          `}
    `}
    ${props => props.theme.media.desktop`
        position: initial;
        ${props =>
          props.fixed &&
          css`
            position: fixed;
            left: ${props =>
              props.naviExtended ? props.theme.navigation.sideWidth : props.theme.navigation.sideSmallWidth};
          `}
    `}
`;

const FloorTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 0 0.25em;
  min-width: ${props => (props.small ? '2em' : '2.5em')};
  height: ${props => (props.small ? '2em' : '2.5em')};
  font-size: ${props => props.small && '1em'};
  border: 1px solid
    ${props =>
      props.selected
        ? selectedPerformanceColors(props)[props.performance]
        : performanceColors(props)[props.performance]};
  border-radius: 2.5em;
  background-color: ${props =>
    props.selected ? selectedPerformanceColors(props)[props.performance] : props.theme.colors.white};
  box-shadow: ${props => !props.selected && `0 0 0 2px ${rgba(performanceColors(props)[props.performance], 0.15)}`};

  a {
    padding: 0 0.5em;
    color: ${props => (props.selected ? props.theme.colors.white : performanceColors(props)[props.performance])};
  }
`;

const FloorTabItem = styled.div`
  display: ${props => (props.selected ? 'block' : 'none')};
  margin-top: 0.75em;
`;

const TabLabel = styled.div`
  display: inline-block;
`;

const StyledInfotip = styled(Infotip)`
  && {
    display: block;
    position: static;
  }

  && ${InfotipContentWrapper} {
    position: static;
    transform: none;
  }

  && ${InfotipContent} {
    position: fixed;
    z-index: ${props => props.theme.zIndex('infoTip')};
    top: ${props => props.offsetTop + 'px'};
    left: ${props => props.offsetLeft + 'px'};
    transform: translateX(-50%);
  }
`;

const FloorTabs = ({ scroll: { scrollTop }, naviExtended, location, loading, content, t, query, setQuery }) => {
  const [toolTipStyles, setToolTipStyles] = useState([]);
  const labelRefs = [];

  const handleInfoTipMouseOver = index => {
    const bounds = getLabelPosition(index);
    if (bounds) {
      setInfoTipPosition(bounds, index);
    }
  };

  /**
   * Gets given index label bounding client rect
   */
  const getLabelPosition = index => {
    if (labelRefs && labelRefs.length > 0) {
      const element = labelRefs[index].current;
      if (element) {
        return element.getBoundingClientRect();
      }
    }
    return null;
  };

  /**
   * Sets infotip positioning styles to state
   */
  const setInfoTipPosition = (bounds, index) => {
    setToolTipStyles(prevState => {
      const styles = Array.isArray(prevState) ? prevState.slice(0) : [];
      styles[index] = {
        left: bounds.left + bounds.width / 2,
        top: bounds.top - 66,
      };
      return styles;
    });
  };

  const toggleExpand = () => {
    const isExpanded = getExpandedState();
    setQuery({ expanded: !isExpanded });
    toggleScroll(!isExpanded);
  };

  const getExpandedState = () => {
    return query.expanded === 'true';
  };

  const renderTabLabel = (label, index, selected, location, t, small) => {
    const layer = label.title ? label.title.toLowerCase() : '';

    return (
      <FloorTab selected={selected} key={index} performance={label.performance} small={small}>
        <QueryLink
          role="button"
          to={{
            pathname: location && location.pathname,
            query: { layer, expanded: getExpandedState() },
          }}
        >
          {t(label.title)}
        </QueryLink>
      </FloorTab>
    );
  };

  const renderLabels = selectedTab => {
    const scrollOffset = 150 + 40; // Hero + margin
    const scrollOffsetMobile = 64 + 80; // Navi + tab navi
    const scrollOffsetTablet = 150 + 64 + 24; // Hero + navi + tablet margin
    const fixed = scrollOffset < scrollTop;
    const fixedMobile = scrollOffsetMobile < scrollTop;
    const fixedTablet = scrollOffsetTablet < scrollTop;

    if (loading) {
      return (
        <FloorTabsListContainer>
          <FloorTabsList>
            <ScrollContainer>
              <SkeletonCircle width="40px" borderWidth="2px" />
              <SkeletonCircle width="40px" borderWidth="2px" />
              <SkeletonCircle width="40px" borderWidth="2px" />
              <SkeletonCircle width="40px" borderWidth="2px" />
              <SkeletonCircle width="40px" borderWidth="2px" />
            </ScrollContainer>
          </FloorTabsList>
        </FloorTabsListContainer>
      );
    }
    return (
      <FloorTabsListContainer>
        <FloorTabsList fixed={fixed} fixedMobile={fixedMobile} fixedTablet={fixedTablet} naviExtended={naviExtended}>
          <ScrollContainer>
            {content.map((child, index) => {
              if (child) {
                const layer = child.title ? child.title.toLowerCase() : '';
                const selected = selectedTab === layer || (!selectedTab && index === 0);
                const tooltipContent = child.tooltipContent;
                labelRefs[index] = createRef();
                if (tooltipContent && !selected && fixed === false) {
                  return (
                    <TabLabel ref={labelRefs[index]} onMouseOver={() => handleInfoTipMouseOver(index)} key={index}>
                      <StyledInfotip
                        offsetLeft={toolTipStyles[index] && toolTipStyles[index].left}
                        offsetTop={toolTipStyles[index] && toolTipStyles[index].top}
                        index={index}
                        text={tooltipContent}
                      >
                        {renderTabLabel(child, index, selected, location, t)}
                      </StyledInfotip>
                    </TabLabel>
                  );
                }

                return <TabLabel key={index}>{renderTabLabel(child, index, selected, location, t)}</TabLabel>;
              }
              return null;
            })}
          </ScrollContainer>
        </FloorTabsList>
      </FloorTabsListContainer>
    );
  };

  const renderContent = (selectedTab, isExpanded) => {
    return (
      <Fragment>
        {content.map((child, index) => {
          if (child) {
            const layer = child.title ? child.title.toLowerCase() : '';
            const selected = selectedTab === layer || (!selectedTab && index === 0);

            if (selected) {
              const newChild = React.cloneElement(child.content, {
                isSelectedStickyTab: selected,
                selectedTab,
                renderTabLabel,
                toggleExpand,
                isExpanded,
                content,
              });
              return (
                <FloorTabItem selected={selected} key={index}>
                  {newChild}
                </FloorTabItem>
              );
            }
            return null;
          }
          return child;
        })}
      </Fragment>
    );
  };

  const isExpanded = getExpandedState();
  const selectedTab = query.layer;
  const noNavi = query.noNavi === 'true';

  if (!content) {
    return null;
  }

  return (
    <FloorTabsContainer>
      {!noNavi && renderLabels(selectedTab)}
      {renderContent(selectedTab, isExpanded)}
    </FloorTabsContainer>
  );
};

FloorTabs.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      performance: PropTypes.oneOf(['ok', 'warning', 'neutral']),
      content: PropTypes.element,
      tooltipContent: PropTypes.element,
    })
  ),
  scroll: PropTypes.shape({
    scrollTop: PropTypes.number.isRequired,
  }).isRequired,
  naviExtended: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  scroll: state.common.scroll,
  naviExtended: state.navigation.sideNaviExtended,
});

export default withQuery(withRouter(connect(mapStateToProps)(FloorTabs)));
