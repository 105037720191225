import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import map from 'lodash/map';
import values from 'lodash/values';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import compact from 'lodash/compact';
import styled from 'styled-components';

import {
  Button,
  InputRow,
  InputLabel,
  InputText,
  InputTextArea,
  InputForm,
  ErrorText,
  InputSelectDropdown,
} from 'components/index.js';

import { loadPermissions, loadFunctionalLocationsByPath } from 'redux/modules';
import { getParentFunctionLocations } from 'utils/Data/functionalLocations.js';
import { isValidPartner } from 'utils/Data/partners';
import Svg from 'components/Svg/Svg.jsx';
import Loader from 'components/Loader/Loader.jsx';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import ServiceRequestImageUpload from './ServiceRequestImageUpload';

const defaultCategoryOptions = t => {
  const orderedOptions = orderBy(
    [
      { value: 'customer_feedback', label: t('Customer feedback') },
      { value: 'outside_areas', label: t('Outside areas') },
      { value: 'building_parts', label: t('Building parts') },
      { value: 'equipment', label: t('Equipment') },
      { value: 'hvac_systems', label: t('HVAC system') },
      { value: 'cleaning_services', label: t('Cleaning services') },
      { value: 'office_employee_services', label: t('Office employee services') },
      { value: 'energy_services', label: t('Energy services') },
      { value: 'individual_order', label: t('Individual order') },
      { value: 'real_estate_management', label: t('DISCIPLINE_REE') },
      { value: 'electricity', label: t('DISCIPLINE_ELE') },
      { value: 'plumbing', label: t('DISCIPLINE_PLU') },
      { value: 'ventilation', label: t('DISCIPLINE_VEN') },
      { value: 'cooling', label: t('DISCIPLINE_COO') },
      { value: 'security', label: t('DISCIPLINE_SEC') },
      { value: 'automation', label: t('DISCIPLINE_AUT') },
      { value: 'av', label: t('DISCIPLINE_AVI') },
      { value: 'ict_systems', label: t('DISCIPLINE_IT') },
      { value: 'sprinkler_systems', label: t('DISCIPLINE_SPR') },
      { value: 'transportation_systems', label: t('DISCIPLINE_TRA') },
    ],
    'label'
  );

  orderedOptions.push({ value: 'other', label: t('Other') });
  return orderedOptions;
};

const StyledInputRow = styled(InputRow)`
  width: 100%;
  margin: ${props => props.theme.spacing.lg} 0 !important;
`;

const Column = styled.div`
  width: 100%;

  ${props => props.theme.media.landscape`
        width: 48%;
    `}

  &:first-child {
    margin-right: auto;
    order: 1;

    ${props => props.theme.media.landscape`
            order: 0;
        `}
  }
`;

const SingleFunctionalLocation = styled.div`
  opacity: 0.6;
  min-height: 36px;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  padding-right: 30px;
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledSVGSeparatorIcon = styled(Svg)`
  fill: ${props => props.theme.colors.black};
  opacity: 0.6;
  font-size: 8px;
  margin: 0 4px;
`;

const DisabledIconContainer = styled.div`
  opacity: 0.6;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledSVGDisabledIcon = styled(Svg)`
  font-size: 0.875em;
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.rockBlue};

  ${props => props.theme.media.landscape`
        height: 18em;
    `}
`;

class ServiceRequestForm extends React.Component {
  componentDidMount() {
    this.loadData(this.props, {});
  }

  componentDidUpdate(prevProps) {
    this.loadData(this.props, prevProps);
  }

  loadData = (nextProps, prevProps) => {
    const { functionalLocation, model, partnerNumber, profile, equipment, superordinate } = nextProps,
      alreadySelected =
        model.functionalLocation &&
        functionalLocation &&
        model.functionalLocation === functionalLocation.functionalLocation,
      oldPartner = prevProps.partnerNumber,
      oldFunctionalLocation = prevProps.functionalLocation;

    // Load parent functional locations for pre-selected FL
    if (functionalLocation && !oldFunctionalLocation) {
      nextProps.loadFunctionalLocationsByPath(partnerNumber, functionalLocation.path);
    }
    // Load top-level functional locations if none is pre-selected (and partner number is changed)
    else if (partnerNumber !== oldPartner) {
      nextProps.loadPermissions();
    }

    // Set pre-selected functional location as selected
    if (functionalLocation && !alreadySelected) {
      nextProps.setProperty('functionalLocation', functionalLocation.functionalLocation);
    }

    if (partnerNumber !== model.partnerNumber) {
      nextProps.setProperty('partnerNumber', partnerNumber);
    }

    if (profile && profile.division && model && !model.division) {
      nextProps.setProperty('division', profile.division);
    }

    if (equipment && !prevProps.equipment) {
      nextProps.setProperty('equipment', equipment.equipmentNumber);
    }

    if (superordinate && !prevProps.superordinate) {
      nextProps.setProperty('superordinate', superordinate.equipmentNumber);
    }
  };

  getCategoryOptions = (partnerNumber, partnerMeta, t) => {
    const metaValue =
      partnerMeta[partnerNumber] &&
      partnerMeta[partnerNumber].meta &&
      (find(partnerMeta[partnerNumber].meta, { key: 'new_service_request_categories' }) || {}).value;

    if (metaValue) {
      const options = metaValue.split(',');
      return map(options, option => ({ value: option, label: option }));
    }

    return defaultCategoryOptions(t);
  };

  render() {
    const {
      t,
      id,
      model,
      setProperty,
      submit,
      functionalLocation,
      functionalLocations,
      partnerNumber,
      profile,
      topLevelPermissions,
      partnerMeta,
      superordinate,
      equipment,
    } = this.props;

    const partnerPermissions = profile.partnerPermissions || [];
    const topLevelFunctionalLocations = values(functionalLocations).filter(functionalLocation => {
      // Show building level FLs if user has partner permissions.
      if (functionalLocation.partnerNumberWithParents.some(partner => includes(partnerPermissions, partner))) {
        return functionalLocation.type === 'BU';
      }

      // Otherwise show FLs the user has direct permissions to
      return includes(topLevelPermissions, functionalLocation.functionalLocation);
    });

    const options = topLevelFunctionalLocations
      // Filter out functional locations that are not related to selected partner (if selected)
      .filter(
        x =>
          !isValidPartner(partnerNumber) ||
          includes(functionalLocations[x.functionalLocation].partnerNumberWithParents, partnerNumber)
      )
      .map(x => ({ value: x.functionalLocation, label: x.description }));

    const parents = functionalLocation
      ? getParentFunctionLocations(
          functionalLocations,
          functionalLocation.path,
          functionalLocation.functionalLocation
        ).reverse()
      : [];

    const error = this.props.error !== null ? <ErrorText>{t(this.props.error)}</ErrorText> : null;

    const path = compact([...parents, functionalLocation, superordinate, equipment]);
    return (
      <Fragment>
        {error}
        <InputForm id={id} model={model} onPropertyChange={setProperty} onSubmit={submit}>
          <Column>
            {profile.division !== 'no' && (
              <StyledInputRow>
                <InputLabel id="category" text={t('Category')} />
                <InputSelectDropdown
                  model={model}
                  property="category"
                  placeholder={t('Select category')}
                  options={this.getCategoryOptions(partnerNumber, partnerMeta, t)}
                  t={t}
                  clearable={false}
                />
              </StyledInputRow>
            )}
            <StyledInputRow required>
              <InputLabel id="title" text={t('Title')} />
              <InputText id="title" property="title" placeholder={t('Give a short outline of the issue')} />
            </StyledInputRow>
            <StyledInputRow>
              <InputLabel id="functionalLocation" text={t('Location')} />
              {!functionalLocation && <InputSelectDropdown property="functionalLocation" options={options} t={t} />}
              {functionalLocation && (
                <SingleFunctionalLocation>
                  <div>
                    {path.map((item, index) => (
                      <span key={item.equipmentNumber || item.functionalLocation}>
                        {index > 0 && <StyledSVGSeparatorIcon name="ion-arrow-right" />}
                        {item.description || item.text}
                      </span>
                    ))}
                  </div>
                  <DisabledIconContainer>
                    <StyledSVGDisabledIcon name="fa-lock" />
                  </DisabledIconContainer>
                </SingleFunctionalLocation>
              )}
            </StyledInputRow>
            <StyledInputRow>
              <InputLabel id="locationDetails" text={t('Location details')} extraText={`(${t('Optional')})`} />
              <InputText
                id="locationDetails"
                property="locationDetails"
                placeholder={t('Describe the specific location')}
              />
            </StyledInputRow>
            <StyledInputRow>
              <InputLabel id="priority" text={t('Requested priority')} extraText={`(${t('Optional')})`} />
              <RadioButtonGroup name="priority" value={model.priority} onChange={setProperty} row>
                <RadioButton value="low" label={t('Low')} />
                <RadioButton value="medium" label={t('Medium')} />
                <RadioButton value="high" label={t('High')} />
                <RadioButton value="urgent" label={t('Urgent')} />
              </RadioButtonGroup>
            </StyledInputRow>
            <StyledInputRow>
              <InputLabel id="description" text={t('Description')} />
              <InputTextArea id="description" property="description" placeholder={t('Describe the issue thoroughly')} />
            </StyledInputRow>
            <StyledInputRow>
              <InputLabel
                id="createdByPhoneNumber"
                text={t('Phone number for contact')}
                extraText={`(${t('Optional')})`}
              />
              <InputText id="createdByPhoneNumber" property="createdByPhoneNumber" />
            </StyledInputRow>
            <StyledInputRow>
              <Button submit>
                {!this.props.saving && !this.props.saved && t('Send')}
                {this.props.saving && <Loader color="WHITE" size="SMALL" />}
                {this.props.saved && `${t('Sent')}!`}
              </Button>
            </StyledInputRow>
          </Column>
          <Column>
            <StyledInputRow>
              <InputLabel id="attachment" text={t('Add image')} extraText={`(${t('Optional')})`} />
              <Background>
                <ServiceRequestImageUpload
                  image={model.attachment}
                  removeAttachment={() => setProperty('attachment', null)}
                  addAttachment={attachment => setProperty('attachment', attachment)}
                />
              </Background>
            </StyledInputRow>
          </Column>
        </InputForm>
      </Fragment>
    );
  }
}

ServiceRequestForm.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  setProperty: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  functionalLocation: PropTypes.object,
  functionalLocations: PropTypes.objectOf(PropTypes.object),
  partnerNumber: PropTypes.string,
  equipment: PropTypes.object,
  superordinate: PropTypes.object,
  profile: PropTypes.object,
  topLevelPermissions: PropTypes.arrayOf(PropTypes.string),
  partnerMeta: PropTypes.object,
  saving: PropTypes.bool,
  saved: PropTypes.bool,
  error: PropTypes.string,
};

const mapStateToProps = state => ({
  functionalLocations: state.functionalLocations.functionalLocations,
  topLevelPermissions: state.profile.topLevelPermissions,
  error: state.profile.error || state.serviceRequests.error,
  profile: state.profile.profile,
  partnerMeta: state.partnerMeta.meta,
});

const mapDispatchToProps = dispatch => ({
  loadPermissions: path => dispatch(loadPermissions(path)),
  loadFunctionalLocationsByPath: (partnerNumber, functionalLocations) =>
    dispatch(loadFunctionalLocationsByPath(partnerNumber, functionalLocations)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(ServiceRequestForm);
