import styled from 'styled-components';

const PlainSection = styled.div`
  margin-bottom: ${props => (props.noMargin ? 0 : props.theme.spacing.sm)};
  display: ${props => (props.hideOnMobile ? 'none' : 'inherit')};
  padding: 0 ${props => props.theme.spacing.md};

  ${props => props.theme.media.portrait`
        display: inherit;
        margin-bottom: ${props => (props.noMargin ? 0 : props.theme.spacing.lg)};
        padding: 0;
    `}
`;

export default PlainSection;
