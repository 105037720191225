import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Hero from 'components/Hero/Hero';
import Header from 'containers/Application/Header/Header';

const type = 'UNKNOWN';
const links = [];
const context = { loadingContext: true, loading: true };

const LoadingFunctionalLocation = props => {
  const { t, partnerNumber } = props;

  return (
    <Fragment>
      <Header context={context} t={t} links={links} />
      <Hero type={type} loadingContext t={t} partnerNumber={partnerNumber} />
    </Fragment>
  );
};

LoadingFunctionalLocation.propTypes = {
  t: PropTypes.func,
  partnerNumber: PropTypes.string,
};

export default LoadingFunctionalLocation;
