import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog.jsx';
import DialogFrame from './DialogFrame.jsx';
import DialogBody from './DialogBody.jsx';

const DialogModal = ({ children, footer, small, large, extraLarge, bodyComponent: BodyComponent, ...otherProps }) => {
  return (
    <Dialog {...otherProps}>
      <DialogFrame footer={footer} onClose={otherProps.onOverlayClick}>
        <BodyComponent small={small} large={large} extraLarge={extraLarge}>
          {children}
        </BodyComponent>
      </DialogFrame>
    </Dialog>
  );
};

DialogModal.propTypes = {
  bodyComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
  children: PropTypes.node,
  footer: PropTypes.node,
  small: PropTypes.bool,
  large: PropTypes.bool,
  extraLarge: PropTypes.bool,
};

DialogModal.defaultProps = {
  bodyComponent: DialogBody,
};

export default DialogModal;
