import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import PermissionListItem from './PermissionListItem';
import { Infotip } from 'components';
import Icon from 'components/Icon/Icon';
import {
  PermissionListItemIcon,
  PermissionListItemGroups,
  PermissionListItemGroup,
  PermissionListItemHeading,
  PermissionListItemFLCount,
  PermissionListSubheading,
  PermissionListItemChip,
  PermissionListItemsChips,
} from './components';
import { isNil } from 'lodash';

const HideOnMobile = styled.span`
  display: none;

  ${props => props.theme.media.portrait`
        display: block;
    `}
`;

const PermissionListItemPortfolio = props => {
  const { t, customer, theme, functionalLocationCount, ...other } = props;
  return (
    <PermissionListItem {...other} clickable={false}>
      <PermissionListItemIcon>
        <Icon name="portfolio" iconType="PORTFOLIO" size="SMALL_PLUS" />
      </PermissionListItemIcon>
      <PermissionListItemGroups>
        <PermissionListItemGroup>
          <PermissionListItemHeading>
            {customer.name}
            {!isNil(functionalLocationCount) && (
              <PermissionListItemFLCount>({functionalLocationCount})</PermissionListItemFLCount>
            )}
          </PermissionListItemHeading>
        </PermissionListItemGroup>
        <PermissionListItemGroup spread>
          <PermissionListSubheading>{customer.partnerNumber}</PermissionListSubheading>
          <HideOnMobile>
            <Infotip text={[t('Customer Portfolio will automatically'), t('include all the locations available.')]}>
              <PermissionListItemChip background={theme.colors.cerulean}>
                {t('Customer Portfolio')}
              </PermissionListItemChip>
            </Infotip>
          </HideOnMobile>
        </PermissionListItemGroup>
        <PermissionListItemsChips {...props} />
      </PermissionListItemGroups>
    </PermissionListItem>
  );
};

PermissionListItemPortfolio.propTypes = {
  t: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    partnerNumber: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.object.isRequired,
  functionalLocationCount: PropTypes.number,
};

export default withTheme(PermissionListItemPortfolio);
