import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { filter, without, concat, reduce, forEach, cloneDeep, map, includes } from 'lodash';
import styled from 'styled-components';

import Section from 'components/Section/Section';
import CustomViewProfileTable from './CustomViewProfileTable';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';

const Title = styled.h2`
  padding: ${props => props.theme.spacing.md} 0;
  font-size: ${props => props.theme.font.size.lg};
`;
Title.displayName = 'Title';

const ButtonContainer = styled.div`
  margin: ${props => props.theme.spacing.xl} ${props => props.theme.spacing.xs} 0;

  ${props => props.theme.media.portrait`
        margin: ${props => props.theme.spacing.xs} 0 ${props => props.theme.spacing.lg};
    `}

  ${props => props.theme.media.landscape`
        margin: -${props => props.theme.spacing.sm} 0 ${props => props.theme.spacing.lg};
    `}
`;

const CustomViews = ({ t, onChange, model, customViews, customers, functionalLocations }) => {
  const [viewType, setViewType] = React.useState(0);
  const handleViewTypeChange = React.useCallback((property, value) => setViewType(value), []);

  const handleActiveChange = React.useCallback(
    (id, enabled) => {
      const disabledCustomViews = model.disabledCustomViews || [];
      const newDisabledCustomViews = enabled ? without(disabledCustomViews, id) : concat(disabledCustomViews, id);
      onChange('disabledCustomViews', newDisabledCustomViews);
    },
    [model]
  );

  const filteredCustomViews = viewType === 0 ? customViews : filter(customViews, { portfolio: viewType === 1 });

  // add every customView under the right customers (customers are deepcloned to prevent side effects)
  const portfoliosWithCustomViews = reduce(
    filteredCustomViews,
    (accu, view) => {
      forEach(view.customers, partnerNumber => {
        if (accu[partnerNumber]) {
          if (!accu[partnerNumber].customViews) {
            accu[partnerNumber].customViews = [];
          }
          accu[partnerNumber].customViews.push(view);
        }
      });
      return accu;
    },
    cloneDeep(customers)
  );

  // filter out portfolios without custom views
  const portfolios = filter(portfoliosWithCustomViews, 'customViews');

  // function to enhance data for table
  const customViewsForTable = customViews =>
    map(customViews, view => ({
      ...view,
      functionalLocations: map(view.functionalLocations, fl => (functionalLocations[fl] || {}).description),
      active: !includes(model.disabledCustomViews, view.id),
    }));

  return (
    <Fragment>
      <ButtonContainer>
        <RadioButtonGroup name="viewType" value={viewType} onChange={handleViewTypeChange} row>
          <RadioButton label={t('Show all')} value={0} name="viewType" />
          <RadioButton label={t('Portfolio level')} value={1} name="viewType" />
          <RadioButton label={t('Building level')} value={2} name="viewType" />
        </RadioButtonGroup>
      </ButtonContainer>
      {portfolios.map(portfolio => (
        <Section key={portfolio.partnerNumber}>
          <Title>{`${portfolio.name} (${portfolio.partnerNumber})`}</Title>
          <CustomViewProfileTable
            data={customViewsForTable(portfolio.customViews)}
            t={t}
            onClick={handleActiveChange}
            loading={false}
          />
        </Section>
      ))}
    </Fragment>
  );
};

CustomViews.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  model: PropTypes.shape({
    features: PropTypes.object,
    disabledCustomViews: PropTypes.array,
  }),
  customViews: PropTypes.array,
  customers: PropTypes.object.isRequired,
  functionalLocations: PropTypes.object.isRequired,
};

export default CustomViews;
