import filter from 'lodash/filter';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';

export const mapFls = (functionalLocations, partnerNumber, items, path) => {
  const flList = functionalLocations.map(fl => {
    const to = `/${partnerNumber}/FunctionalLocation/${fl.functionalLocation}/RemoteCenter/${path}`;
    const address = (fl.address && fl.city && `${fl.address}, ${fl.city}`) || '';
    const count = filter(items, item => includes(item.path, fl.functionalLocation)).length;
    return { title: fl.description, subtitle: address, value: count, to };
  });
  return orderBy(flList, 'value', 'desc');
};
