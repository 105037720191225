import { createReducerFromMapping } from 'redux/utils/index.js';
import _ from 'lodash';
import moment from 'moment-timezone';

const initialState = {
  loaded: false,
  loading: [],
  kpi: {},
  byMonth: {},
};

export const LOAD_ENERGY = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY';
export const LOAD_ENERGY_SUCCESS = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_SUCCESS';
export const LOAD_ENERGY_FAIL = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_FAIL';

export const LOAD_ENERGY_DATA = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_DATA';
export const LOAD_ENERGY_DATA_SUCCESS = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_DATA_SUCCESS';
export const LOAD_ENERGY_DATA_FAIL = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_DATA_FAIL';

export function loadEnergyKPIToday(partnerNumber) {
  const filter = {
    where: {
      partnerNumber: partnerNumber,
      functionalLocation: null,
      temperatureUnit: '°C',
    },
    limit: 1,
    order: 'windowEnd DESC',
  };

  return {
    partnerNumber: partnerNumber,
    types: [LOAD_ENERGY, LOAD_ENERGY_SUCCESS, LOAD_ENERGY_FAIL],
    key: JSON.stringify([LOAD_ENERGY, filter]),
    filter,
    promise: (client, { filter }, accessToken) =>
      client.get('/iot/energies', {
        accessToken,
        params: {
          filter: JSON.stringify(filter),
        },
      }),
  };
}

export function loadEnergyKPI(partnerNumber, start, end) {
  const filter = {
    where: {
      partnerNumber: partnerNumber,
      functionalLocation: null,
      windowStart: {
        between: [start.format('YYYY-MM-DDTHH:mm:ss.SSSZ'), end.format('YYYY-MM-DDTHH:mm:ss.SSSZ')],
      },
      temperatureUnit: '°C',
    },
  };

  return {
    partnerNumber: partnerNumber,
    types: [LOAD_ENERGY, LOAD_ENERGY_SUCCESS, LOAD_ENERGY_FAIL],
    key: JSON.stringify([LOAD_ENERGY, filter]),
    filter,
    promise: (client, { filter }, accessToken) =>
      client.get('/iot/energies', {
        accessToken,
        params: {
          filter: JSON.stringify(filter),
        },
      }),
  };
}

export function loadEnergyData(partnerNumber) {
  const start = moment
    .utc()
    .subtract(2, 'year')
    .startOf('year');
  const end = moment.utc();

  const filter = {
    where: {
      partnerNumber: partnerNumber,
      functionalLocation: null,
      windowStart: {
        between: [start.format('YYYY-MM-DDTHH:mm:ss.SSSZ'), end.format('YYYY-MM-DDTHH:mm:ss.SSSZ')],
      },
      temperatureUnit: '°C',
    },
  };

  return {
    partnerNumber: partnerNumber,
    types: [LOAD_ENERGY_DATA, LOAD_ENERGY_DATA_SUCCESS, LOAD_ENERGY_DATA_FAIL],
    key: JSON.stringify([LOAD_ENERGY, filter]),
    filter,
    promise: (client, { filter }, accessToken) =>
      client.get('/iot/energies', {
        accessToken,
        params: {
          filter: JSON.stringify(filter),
        },
      }),
  };
}

function parsePartnerFLEnergyKPI(kpi, action) {
  const referenceDateStart = moment.utc().subtract(31, 'days');
  const referenceDateEnd = moment.utc().subtract(30, 'days');
  const partnerNumber = action.partnerNumber;

  const data = _.first(action.result);

  if (!kpi[partnerNumber]) {
    kpi[partnerNumber] = {};
  }

  if (data) {
    const resultStart = moment.utc(data.windowStart);

    if (resultStart.isSame(referenceDateStart, 'day') || resultStart.isSame(referenceDateEnd, 'day')) {
      kpi[partnerNumber].reference = data;
    } else {
      kpi[partnerNumber].current = data;
    }
  }

  return kpi;
}

function parseMonthlyFLEnergyData(data, action) {
  const partnerNumber = action.partnerNumber;
  const partnerData = [];

  _.forEach(action.result, myResult => {
    if (myResult.netArea == null || myResult.consumption == null) {
      return;
    }
    const resultStart = moment.utc(myResult.windowStart);
    const year = resultStart.year();
    const month = resultStart.month();
    if (partnerData[year] == null) {
      partnerData[year] = [];
    }
    let monthConsumption = partnerData[year][month] || 0;
    monthConsumption += myResult.consumption;
    partnerData[year][month] = monthConsumption;
  });

  data[partnerNumber] = partnerData;
  return data;
}

export default createReducerFromMapping(
  {
    [LOAD_ENERGY]: state => ({ ...state, loading: true }),
    [LOAD_ENERGY_SUCCESS]: (state, action) => ({
      ...state,
      loaded: true,
      loading: false,
      kpi: (data => parsePartnerFLEnergyKPI(data, action))(_.cloneDeep(state.kpi)),
    }),
    [LOAD_ENERGY_FAIL]: (state, action) => ({ ...state, loaded: false, loading: false, error: action.error }),
    [LOAD_ENERGY_DATA]: state => ({ ...state, loading: true }),
    [LOAD_ENERGY_DATA_SUCCESS]: (state, action) => ({
      ...state,
      loaded: true,
      loading: false,
      byMonth: (data => parseMonthlyFLEnergyData(data, action))(_.cloneDeep(state.byMonth)),
    }),
    [LOAD_ENERGY_DATA_FAIL]: (state, action) => ({ ...state, loaded: false, loading: false, error: action.error }),
  },
  initialState
);
