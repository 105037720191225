import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { stripUnit } from 'polished';
import { throttle } from 'lodash';

import Columns from 'components/Columns/Columns';
import { Card } from './Card';

export const getDesktopAndLandscapeStyles = (props, styles) => {
  return css`
    ${props.naviClosed &&
      css`
        ${props.theme.media.landscape`
                ${styles}
            `};
      `};
    ${props.theme.media.desktop`
            ${styles}
        `};
  `;
};

const Container = styled.div`
  position: relative;
  margin: 0 ${props => stripUnit(props.theme.grid.gutter) / 2 + 'rem'};
  margin-top: ${props => props.smallMargin && !props.noSmallMarginMobile && '-0.7em'};

  ${props => props.theme.media.portrait`
        padding: ${props => (props.noPadding ? 0 : props.theme.grid.gutter)} 0;
        padding-top: ${props.noPaddingTop && 0};
    `};

  ${props =>
    !props.smallMargin &&
    css`
      ${props => props.theme.media.landscape`
            padding: 0 0 ${props => (props.noPadding ? 0 : props.theme.grid.gutter)};
        `}
    `};

  ${props =>
    getDesktopAndLandscapeStyles(
      props,
      css`
        padding: ${props => props.theme.spacing.md} 0;
        margin: 0 auto;
        margin-top: ${props => (props.smallMargin ? '-0.7em' : '-2em')};
        max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});

        ${props =>
          props.noPadding &&
          css`
            margin: -${props => props.theme.spacing.sm} auto;
            padding: 0;
          `}
      `
    )}

  ${props =>
    props.autoWidth &&
    css`
      ${Card} {
        width: auto;
      }
    `}
`;

const OPICards = props => {
  const { smallMargin, children, naviExtended, ...other } = props;
  const [width, setWidth] = useState(window.innerWidth);

  const opiCount = React.Children.toArray(children).filter(Boolean).length;
  const autoWidth = width >= opiCount * 155;

  const handleResize = () => setWidth(window.innerWidth);
  const throttledUpdate = throttle(handleResize, 100);

  useEffect(() => {
    window.addEventListener('resize', throttledUpdate);

    return () => {
      window.removeEventListener('resize', throttledUpdate);
    };
  }, []);

  return (
    <Container smallMargin={smallMargin} autoWidth={autoWidth} naviClosed={!naviExtended} {...other}>
      <Columns scrollMobile>
        {React.Children.toArray(children).map((child, index) =>
          React.cloneElement(child, {
            ctxHelpPosition: (index + 1) % 3 === 0 || (index + 1) % 4 === 0 ? 'left' : 'top',
          })
        )}
      </Columns>
    </Container>
  );
};

const mapStateToProps = state => ({
  naviExtended: state.navigation.sideNaviExtended,
});

const connector = connect(mapStateToProps);

export default connector(OPICards);
