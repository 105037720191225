import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';
import _ from 'lodash';

const initialState = {
  groups: [],
  saving: false,
  saved: false,
  error: null,
  savingGroup: false,
  groupError: null,
  saveError: null,
  saveResult: [],
};

export const LOAD_TAG_GROUPS = 'CUSTOMER_PLATFORM/Tag/LOAD_TAG_GROUPS';
export const LOAD_TAG_GROUPS_SUCCESS = 'CUSTOMER_PLATFORM/Tag/LOAD_TAG_GROUPS_SUCCESS';
export const LOAD_TAG_GROUPS_FAIL = 'CUSTOMER_PLATFORM/Tag/LOAD_TAG_GROUPS_FAIL';

export const loadTagGroups = division => async dispatch => {
  dispatch({
    type: LOAD_TAG_GROUPS,
  });
  const where = {};
  if (division) {
    where.division = division;
  }
  try {
    const groups = await dispatch(
      MasterData.getTagGroups(JSON.stringify({ where, filter: { include: 'tagDetails' } }))
    );

    return dispatch({
      type: LOAD_TAG_GROUPS_SUCCESS,
      groups,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_TAG_GROUPS_FAIL,
      error,
    });
  }
};
export const SAVE_TAG_DETAILS = 'CUSTOMER_PLATFORM/Tag/SAVE_TAG_DETAILS';
export const SAVE_TAG_DETAILS_SUCCESS = 'CUSTOMER_PLATFORM/Tag/SAVE_TAG_DETAILS_SUCCESS';
export const SAVE_TAG_DETAILS_FAIL = 'CUSTOMER_PLATFORM/Tag/SAVE_TAG_DETAILS_FAIL';

export const upsertTagDetails = tagDetails => async dispatch => {
  dispatch({
    type: SAVE_TAG_DETAILS,
  });

  try {
    const result = await dispatch(MasterData.upsertTagDetails(JSON.stringify({ data: tagDetails })));

    return dispatch({
      type: SAVE_TAG_DETAILS_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: SAVE_TAG_DETAILS_FAIL,
      error,
    });
  }
};

export const SAVE_TAG_GROUP = 'CUSTOMER_PLATFORM/Tag/SAVE_TAG_GROUP';
export const SAVE_TAG_GROUP_SUCCESS = 'CUSTOMER_PLATFORM/Tag/SAVE_TAG_GROUP_SUCCESS';
export const SAVE_TAG_GROUP_FAIL = 'CUSTOMER_PLATFORM/Tag/SAVE_TAG_GROUP_FAIL';

export const upsertTagGroup = groupDetails => async dispatch => {
  dispatch({
    type: SAVE_TAG_GROUP,
  });

  try {
    const result = await dispatch(MasterData.upsertTagGroup(JSON.stringify({ data: groupDetails })));

    return dispatch({
      type: SAVE_TAG_GROUP_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: SAVE_TAG_GROUP_FAIL,
      error,
    });
  }
};

export const SAVE_TAGS = 'CUSTOMER_PLATFORM/Tag/SAVE_TAGS';
export const SAVE_TAGS_SUCCESS = 'CUSTOMER_PLATFORM/Tag/SAVE_TAGS_SUCCESS';
export const SAVE_TAGS_FAIL = 'CUSTOMER_PLATFORM/Tag/SAVE_TAGS_FAIL';

export const submitTags = data => async dispatch => {
  dispatch({
    type: SAVE_TAGS,
  });
  try {
    const result = await dispatch(MasterData.saveTagsForSensors({ data }));
    return dispatch({
      type: SAVE_TAGS_SUCCESS,
      result,
    });
  } catch (error) {
    let errorObject;
    try {
      errorObject = JSON.parse(error.message);
    } catch (parseError) {
      console.error('Unable to parse error');
      errorObject = 'Unable to save tags';
    }
    return dispatch({
      type: SAVE_TAGS_FAIL,
      error: errorObject,
    });
  }
};

export const DELETE_TAG = 'CUSTOMER_PLATFORM/Tag/DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'CUSTOMER_PLATFORM/Tag/DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAIL = 'CUSTOMER_PLATFORM/Tag/DELETE_TAG_FAIL';

export const deleteTag = (tagId, groupId) => async dispatch => {
  dispatch({
    type: DELETE_TAG,
  });
  try {
    const result = await dispatch(MasterData.deleteTagDetails(tagId));
    return dispatch({
      type: DELETE_TAG_SUCCESS,
      result,
      tagId,
      groupId,
    });
  } catch (error) {
    return dispatch({
      type: DELETE_TAG_FAIL,
      error: 'Unable to delete tag',
    });
  }
};

export const DISMISS_ERRORS = 'CUSTOMER_PLATFORM/Tag/DISMISS_ERRORS';
export const dismissTagErrors = () => async dispatch => {
  dispatch({ type: DISMISS_ERRORS });
};

export const DELETE_TAG_GROUP = 'CUSTOMER_PLATFORM/Tag/DELETE_TAG_GROUP';
export const DELETE_TAG_GROUP_SUCCESS = 'CUSTOMER_PLATFORM/Tag/DELETE_TAG_GROUP_SUCCESS';
export const DELETE_TAG_GROUP_FAIL = 'CUSTOMER_PLATFORM/Tag/DELETE_TAG_GROUP_FAIL';

export const deleteTagGroup = id => async dispatch => {
  dispatch({
    type: DELETE_TAG_GROUP,
  });
  try {
    const result = await dispatch(MasterData.deleteTagGroup(id));
    return dispatch({
      type: DELETE_TAG_GROUP_SUCCESS,
      result,
      id,
    });
  } catch (error) {
    return dispatch({
      type: DELETE_TAG_GROUP_FAIL,
      error: 'Unable to delete tag group',
    });
  }
};

export default createReducerFromMapping(
  {
    [LOAD_TAG_GROUPS]: (state, action) => ({
      ...state,
      loading: true,
      error: undefined,
    }),
    [LOAD_TAG_GROUPS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      groups: action.groups,
    }),
    [LOAD_TAG_GROUPS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
    [SAVE_TAG_DETAILS]: (state, action) => ({
      ...state,
      saving: true,
      error: undefined,
    }),
    [SAVE_TAG_DETAILS_SUCCESS]: (state, action) => ({
      ...state,
      saving: false,
      result: action.result,
    }),
    [SAVE_TAG_DETAILS_FAIL]: (state, action) => ({
      ...state,
      saving: false,
      error: action.error,
    }),
    [SAVE_TAG_GROUP]: (state, action) => ({
      ...state,
      savingGroup: true,
      error: undefined,
    }),
    [SAVE_TAG_GROUP_SUCCESS]: (state, action) => ({
      ...state,
      savingGroup: false,
      result: action.result,
    }),
    [SAVE_TAG_GROUP_FAIL]: (state, action) => ({
      ...state,
      savingGroup: false,
      groupError: action.error,
    }),
    [DELETE_TAG]: state => ({
      ...state,
      deletingTag: true,
    }),
    [DELETE_TAG_SUCCESS]: (state, action) => {
      const groups = state.groups;
      const groupIndex = _.findIndex(groups, { id: action.groupId });
      let tags;
      if (groupIndex !== -1) {
        tags = _.filter(_.get(groups[groupIndex], 'tagDetails', []), tag => tag.id !== action.tagId);
      }
      groups[groupIndex].tagDetails = tags;

      return {
        ...state,
        deletingTag: false,
        groups,
      };
    },
    [DELETE_TAG_FAIL]: (state, action) => ({
      ...state,
      deletingTag: false,
      deleteTagError: action.error,
    }),
    [DELETE_TAG_GROUP]: state => ({
      ...state,
      deletingTag: true,
    }),
    [DELETE_TAG_GROUP_SUCCESS]: (state, action) => ({
      ...state,
      deletingTag: false,
      groups: _.filter(state.groups, group => group.id !== action.id),
    }),
    [DELETE_TAG_GROUP_FAIL]: (state, action) => ({
      ...state,
      deletingTag: false,
      deleteTagError: action.error,
    }),
    [SAVE_TAGS]: state => ({
      ...state,
      savingTags: true,
    }),
    [SAVE_TAGS_SUCCESS]: (state, action) => ({
      ...state,
      savingTags: false,
      saveResult: state.saveResult.concat(_.flatten(action.result)),
    }),
    [SAVE_TAGS_FAIL]: (state, action) => ({
      ...state,
      savingTags: false,
      saveError: action.error,
    }),
    [DISMISS_ERRORS]: state => ({
      ...state,
      saveError: null,
      error: null,
    }),
  },
  initialState
);
