import styled from 'styled-components';
import { rgba } from 'polished';

const Section = styled.div`
  display: block;
  position: relative;
  overflow: visible;
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  padding: ${props =>
    `${props.theme.section.verticalSpacing} ${!props.noPadding || props.mobilePadding ? props.theme.spacing.md : 0}`};
  background-color: ${props => (props.transparent ? props.theme.colors.transparent : props.theme.colors.white)};
  width: 100%;

  ${props => props.theme.media.portrait`
        padding: ${props => `${props.theme.section.verticalSpacing} ${!props.noPadding ? props.theme.grid.gutter : 0}`};
        margin: 0 auto ${props => !props.noMargin && props.theme.section.spacing};
        box-shadow: ${props => !props.transparent && `0 2px 5px ${rgba(props.theme.colors.black, 0.1)}`};
        border-radius: 4px;
    `}
  div[title] {
    padding-top: 0px;
  }
`;
Section.displayName = 'Section';

export default Section;
