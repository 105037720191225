import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import find from 'lodash/find';
import some from 'lodash/some';
import SensorItems from './SensorItems';
import SensorItem from './SensorItem';

export const Container = styled.div`
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.sensorValues.padding.mobile} 0;
  background: ${props => props.theme.colors.white};

  ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.sm} ${props => props.theme.sensorValues.padding.tablet} 0;
    `}

  ${props => props.theme.media.desktop`
        padding: ${props => props.theme.spacing.sm} ${props => props.theme.sensorValues.padding.desktop} 0;
    `}
`;
Container.displayName = 'Container';

const SensorSelector = ({
  model,
  onClick,
  t,
  options,
  loading,
  property,
  theme,
  isAirQuality,
  isUtilizationRate,
  disabled,
  areas,
  buildingSensors,
  useDeviceNames,
}) => {
  const getTitle = sensor => {
    if (sensor && sensor.isGroup) {
      return sensor.name;
    }
    if (isAirQuality) {
      return sensor.sensorType ? t(sensor.sensorType.name) : '-';
    }
    if (useDeviceNames) {
      const parent = find(buildingSensors, { id: sensor.parentId });
      return parent?.name ?? sensor.name ?? '-';
    }
    if (sensor.sensorType && sensor.sensorType.name === 'presence_area') {
      const parentArea = find(areas, area => some(area.sensors, { id: sensor.id }));
      if (parentArea) {
        return parentArea.name ?? '-';
      }
    }
    return sensor.name ?? '-';
  };

  const getColor = option => {
    if (isAirQuality) {
      return option.performance >= 90 ? theme.colors.emerald : theme.colors.radicalRed;
    }
    if (option.backgroundColor) {
      return theme.colors.black;
    }

    return option.color || theme.colors.midnight;
  };

  return (
    <Container>
      <SensorItems model={model} property={property} onClick={onClick} disabled={disabled}>
        {options.map(option => (
          <SensorItem
            title={getTitle(option.sensor)}
            loading={loading}
            performance={option.performance}
            value={option.sensor}
            key={`SensorItem-${option.sensor?.id || option.sensor?.name}`}
            color={getColor(option)}
            backgroundColor={option.backgroundColor}
            unit={option.sensor?.sensorType?.unit}
            noChart={!isAirQuality && !isUtilizationRate}
            noRounding={!isAirQuality && !isUtilizationRate}
          />
        ))}
      </SensorItems>
    </Container>
  );
};

SensorSelector.defaultProps = {
  loading: false,
};

SensorSelector.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  property: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  isAirQuality: PropTypes.bool,
  isUtilizationRate: PropTypes.bool,
  useDeviceNames: PropTypes.bool,
  areas: PropTypes.arrayOf(PropTypes.object),
  buildingSensors: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
};

export default SensorSelector;
