import { isNil, isEmpty, get, uniq, isEqual } from 'lodash';
import memoizeOne from 'memoize-one';
import moment from 'moment-timezone';
import { defaults, getUseCase, parseOpeningHours } from '@caverion/loopback-shared/utility/performance';

const CAPACITY_MAX_COEFFICIENT = 0.9;
const CAPACITY_OK_COEFFICIENT = 0.5;

export const getPerformanceStatus = (value, isPerformance = false) => {
  if (isPerformance) {
    return value >= 90 ? 'ok' : 'alert';
  }

  if (value >= 95) {
    return 'ok';
  }

  if (value >= 80) {
    return 'warning';
  }

  return 'alert';
};

export const performanceColors = props => ({
  ok: props.theme.status.okColor,
  warning: props.theme.status.warningColor,
  alert: props.theme.status.alarmColor,
  loading: props.theme.colors.rockBlue,
});

const getDefaultPerformanceLimit = (sensorType, useCase) => {
  if (useCase) {
    const limit = get(defaults.limits.byUseCase, `${useCase}.${sensorType}`);
    if (limit) {
      return typeof limit === 'function' ? limit : () => limit;
    }
  }

  const limit = defaults.limits.common[sensorType];
  if (typeof limit === 'undefined') {
    return () => [undefined, undefined];
  }

  return typeof limit === 'function' ? limit : () => limit;
};

export const getLimitFromMeta = (meta, key) => {
  const value = get(meta && meta.find(meta => meta.key === key || meta.metaKey === key), 'value');
  if (value || value === '0') {
    return Number.parseFloat(value);
  }

  return undefined;
};

const formatLimits = (min, max) => [
  typeof min === 'number' ? min : Number.NEGATIVE_INFINITY,
  typeof max === 'number' ? max : Number.POSITIVE_INFINITY,
];

/**
 * Determine performance limits for a sensor.
 *
 * 1. Sensor alarm
 * 2. Sensor min/max meta (h:min, h:max)
 * 3. Sensor capacity meta
 * 4. Use case specific building meta
 * 5. Global defaults for use case
 * 6. Building meta
 * 7. Global defaults
 */
export const getPerformanceLimit = (sensor, parent, buildingMeta, sensorAlarm) => {
  if (!sensor) {
    return () => [undefined, undefined];
  }

  if (sensorAlarm) {
    return () => [sensorAlarm.minValue, sensorAlarm.maxValue];
  }

  const sensorMin = getLimitFromMeta(sensor.sensorMeta, 'h:min');
  const sensorMax = getLimitFromMeta(sensor.sensorMeta, 'h:max');
  if (!isNil(sensorMin) || !isNil(sensorMax)) {
    return () => formatLimits(sensorMin, sensorMax);
  }

  const capacity = getLimitFromMeta(sensor.sensorMeta, 'capacity');
  if (capacity) {
    return () => formatLimits(undefined, capacity * CAPACITY_MAX_COEFFICIENT);
  }

  const useCase = getUseCase(get(parent, 'sensorType.name'));
  const type = sensor.sensorType && sensor.sensorType.name;
  if (useCase) {
    if (useCase === 'outdoor') {
      return () => [undefined, undefined];
    }

    const useCaseMax = getLimitFromMeta(buildingMeta, `performance/${useCase}/${type}/max`);
    const useCaseMin = getLimitFromMeta(buildingMeta, `performance/${useCase}/${type}/min`);
    if (!isNil(useCaseMin) || !isNil(useCaseMax)) {
      return () => formatLimits(useCaseMin, useCaseMax);
    }

    return getDefaultPerformanceLimit(type, useCase);
  }

  const buildingMin = getLimitFromMeta(buildingMeta, `performance/${type}/min`);
  const buildingMax = getLimitFromMeta(buildingMeta, `performance/${type}/max`);
  if (!isNil(buildingMin) || !isNil(buildingMax)) {
    return () => formatLimits(buildingMin, buildingMax);
  }

  return getDefaultPerformanceLimit(type);
};

export const mapThresholdToStatusValue = (value, thresholds) => {
  if (
    isNil(value) ||
    !thresholds ||
    isEmpty(thresholds) ||
    (Array.isArray(thresholds) && thresholds.every(threshold => typeof threshold === 'undefined'))
  ) {
    return 0;
  }

  return value >= thresholds[0] && value <= thresholds[1] ? 100 : 10;
};

export const generateTresholds = memoizeOne((start, end, getThreshold, granularity = 'day') => {
  const below = [];
  const within = [];
  const above = [];

  let infiniteMin = false;
  let infiniteMax = false;

  if (getThreshold) {
    let previous = [];
    const iterator = moment(start);
    while (iterator.isSameOrBefore(end)) {
      const threshold = getThreshold(iterator.toDate());
      const [min, max] = threshold;
      const timestamp = iterator.valueOf();

      if (threshold[0] !== previous[0] || threshold[1] !== previous[1]) {
        if (typeof previous[0] !== 'undefined' && typeof previous[1] !== 'undefined') {
          // Add previous value just before to produce vertical steps in thresholds
          below.push([timestamp - 1, previous[0]]);
          within.push([timestamp - 1, previous[0], previous[1]]);
          above.push([timestamp - 1, previous[1]]);
        }

        below.push([timestamp, min]);
        within.push([timestamp, min, max]);
        above.push([timestamp, max]);

        if (!isFinite(min)) {
          infiniteMin = true;
        }

        if (!isFinite(max)) {
          infiniteMax = true;
        }
      }

      iterator.add(1, granularity);
      previous = threshold;
    }

    // Add one point to the end.
    const timestamp = moment(end).valueOf();
    const [min, max] = getThreshold(moment(end).toDate());
    below.push([timestamp, min]);
    within.push([timestamp, min, max]);
    above.push([timestamp, max]);
  }

  return {
    below,
    within,
    above,
    infiniteMin,
    infiniteMax,
  };
});

const getDefaultOpeningHours = hours => {
  if (!hours) {
    return undefined;
  }

  // Default opening hours have Sunday as the first day of week, while UI expects Monday
  return [...hours.slice(1, hours.length), hours[0]];
};

export const getOpeningHours = (sensorHierarchy, buildingMeta) => {
  if (!sensorHierarchy || !sensorHierarchy.sensors) {
    return undefined;
  }

  const useCases = uniq(
    sensorHierarchy.sensors
      .filter(sensor => sensor.sensorType.name !== 'technical_performance')
      .map(sensor => getUseCase(get(sensor, 'sensorType.name')))
  );

  if (useCases.length > 1) {
    return undefined;
  }

  const useCase = useCases[0];
  const metaKey = ['performance', useCase, 'hours'].filter(part => part).join('/');
  const meta = buildingMeta && buildingMeta.find(meta => meta.key === metaKey);
  const openingHours = parseOpeningHours(meta);
  if (openingHours) {
    return openingHours;
  }

  if (useCase) {
    return getDefaultOpeningHours(defaults.openingHours.hours.byUseCase[useCase]);
  }

  return getDefaultOpeningHours(defaults.openingHours.hours.common);
};

function formatHours(hours) {
  return hours
    .map(hour =>
      hour
        .split(':')
        .splice(0, 2)
        .join(':')
    )
    .join('-');
}

export function formatOpeningHours(openingHours) {
  if (!openingHours) {
    return undefined;
  }

  if (typeof openingHours === 'string') {
    return openingHours;
  }

  return openingHours
    .reduce((acc, hours, index) => {
      if (!hours || hours.some(hour => hour === null)) {
        return acc;
      }

      const day = moment()
        .isoWeekday(index + 1)
        .format('ddd');
      if (isEqual(hours, openingHours[index + 1]) && !isEqual(hours, openingHours[index - 1])) {
        return [...acc, day];
      }

      if (isEqual(hours, openingHours[index - 1]) && !isEqual(hours, openingHours[index + 1])) {
        acc[acc.length - 1] += `-${day} ${formatHours(hours)}`;
        return acc;
      }

      if (isEqual(hours, openingHours[index - 1]) && isEqual(hours, openingHours[index + 1])) {
        return acc;
      }

      return [...acc, `${day} ${formatHours(hours)}`];
    }, [])
    .join(', ');
}

export function generateOpeningHourBands(openingHours, timezone, rangeStart, rangeEnd, theme) {
  if (!openingHours || typeof openingHours === 'string' || rangeEnd.diff(rangeStart, 'days') > 3) {
    return [];
  }

  const bands = [];
  let iterator = moment.tz(rangeStart, timezone);
  while (iterator < rangeEnd) {
    const start = moment.tz(iterator, timezone);
    let end = moment.tz(iterator, timezone).endOf('day');
    if (end > rangeEnd) {
      end = rangeEnd;
    }

    const hours = openingHours[start.isoWeekday() - 1];
    if (!hours || hours.some(hour => hour === null)) {
      bands.push([start, end]);
    } else {
      const opens = moment.tz(`${start.format('YYYY-MM-DD')} ${hours[0]}`, timezone);
      const closes = moment.tz(`${start.format('YYYY-MM-DD')} ${hours[1]}`, timezone);
      bands.push([start, opens]);
      bands.push([closes, end]);
    }

    iterator = moment
      .tz(iterator, timezone)
      .startOf('day')
      .add(1, 'day');
  }

  // Combine immediately consecutive bands to avoid thin lines between the bands
  return bands
    .reduce((acc, band) => {
      const previous = acc[acc.length - 1];
      if (previous && band[0].diff(previous[1], 'seconds') <= 1) {
        acc[acc.length - 1][1] = band[1];
        return acc;
      }

      return [...acc, band];
    }, [])
    .map(([from, to]) => ({
      from: from.toDate(),
      to: to.toDate(),
      color: theme.colors.lightGray,
    }));
}

export const technicalPerformanceOrder = [
  'technical_performance',
  'technical_performance/temperature',
  'technical_performance/humidity',
  'technical_performance/carbondioxide',
  'technical_performance/organic_gas',
  'technical_performance/particle/PM10',
  'technical_performance/particle/PM2.5',
  'technical_performance/pressure',
  'technical_performance/radon',
];

export const areaUtilizationStatus = utilizationRate => {
  if (utilizationRate <= CAPACITY_OK_COEFFICIENT * 100) {
    return 'ok';
  } else if (utilizationRate <= CAPACITY_MAX_COEFFICIENT * 100) {
    return 'warning';
  }
  return 'alert';
};
