import { pull, filter, intersection, clone, flatten, reduce, map, includes, isNil } from 'lodash';

export const NEW_PROFILE_FEATURES = { technicalTab: false, equipment: false, serviceOrders: true };

export const getSyntheticFeatures = features => {
  // This list should contain all possible non-synthetic feature flags and their default values.
  const allFeatures = {
    alarmsKpi: false,
    conditions: false,
    documents: false,
    files: false,
    energyKpi: false,
    energyTab: false,
    equipment: true,
    floorsTab: false,
    iot: false,
    kpi: false,
    plannedMaintenance: false,
    serviceOrders: true,
    serviceOrdersKpi: false,
    sr: false,
    technicalTab: true,
    controlRoomTab: false,
    buildingAutomationTab: false,
    cleaningTab: false,
    announcementsTab: false,
  };
  if (!features) {
    return {};
  }
  // This list should contain all synthetic features we want to use.
  const syntheticFeatures = {
    technicalMaintenance:
      features.equipment ||
      features.technicalTab ||
      features.serviceOrders ||
      features.documents ||
      features.files ||
      features.conditions,
    buildingManagement:
      features.floorsTab ||
      features.conditions ||
      features.energyTab ||
      features.controlRoomTab ||
      features.buildingAutomationTab ||
      features.cleaningTab ||
      features.announcementsTab,
    facilityManagement: features.alarmsKpi || features.energyKpi,
  };
  return { ...syntheticFeatures, ...allFeatures, ...features };
};

export const TABS = [
  'ServiceCalendar',
  'Conditions',
  'Technical',
  'Systems',
  'Equipment',
  'Building',
  'Documents',
  'ExternalDocuments',
  'RemoteCenter',
  'Cleaning',
  'News',
  'Floors',
  'Energy',
];

export const FEATURE_TO_TAB = {
  buildingAutomationTab: 'Building',
  files: 'Documents',
  documents: 'ExternalDocuments',
  floorsTab: 'Floors',
  controlRoomTab: 'RemoteCenter',
  energyTab: 'Energy',
  announcementsTab: 'News',
  conditions: 'Conditions',
  equipment: 'Equipment',
  technicalTab: ['Technical', 'Systems'],
  serviceOrders: 'ServiceCalendar',
  cleaningTab: 'Cleaning',
};

export const getEnabledTabsForFLType = flType => {
  const tabs = clone(TABS);

  if (!flType) {
    return tabs;
  }

  if (flType !== 'BU' && flType !== 'UN') {
    pull(tabs, 'Building');
    pull(tabs, 'Floors');
    pull(tabs, 'Energy');
    pull(tabs, 'RemoteCenter');
    pull(tabs, 'Cleaning');
    pull(tabs, 'News');
    pull(tabs, 'Documents');
  }

  if (flType === 'TO') {
    pull(tabs, 'Technical');
    pull(tabs, 'Conditions');
  } else {
    pull(tabs, 'Systems');
  }

  return tabs;
};

/**
 * enabled tabs for a user:
 * 1) tab is enabled for functional location type AND in users features
 * 2) custom view tab
 */
export const getEnabledTabs = (features, flType, customViews) => {
  if (!features) {
    return clone(TABS);
  }

  const flTabs = getEnabledTabsForFLType(flType);

  const featureTabs = flatten(
    reduce(
      features,
      (accu, value, key) => {
        // if feature is enabled, push tab to accu
        if (value) {
          accu.push(FEATURE_TO_TAB[key]);
        }
        return accu;
      },
      []
    )
  );

  const customViewTabs = map(customViews, 'id');

  const enabledTabs = intersection(flTabs, featureTabs).concat(customViewTabs);

  return enabledTabs;
};

export const getEnabledCustomViews = (customViews, functionalLocation) => {
  if (!functionalLocation) {
    return [];
  }

  const profileCustomViews = filter(
    customViews,
    customView =>
      includes(customView.functionalLocations, functionalLocation.functionalLocation) &&
      customView.enabled === true &&
      customView.portfolio === false
  );

  return profileCustomViews;
};

export const mapBuildingTabToNewTabs = features => {
  const mappedFeatures = { ...features };
  if (!isNil(features.buildingTab)) {
    mappedFeatures.controlRoomTab = features.buildingTab;
    mappedFeatures.buildingAutomationTab = features.buildingTab;
    mappedFeatures.announcementsTab = features.buildingTab;
    delete mappedFeatures.buildingTab;
  }

  return mappedFeatures;
};
