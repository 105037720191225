import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import { InputRow, InputLabel, InputText, InputForm, InputSelectDropdown } from 'components/index.js';
import { upsertSensorHierarchy, loadSensorHierarchies } from 'redux/modules/customer/sensorHierarchy.js';
import { map } from 'lodash';

const FormContainer = styled.section`
  margin-bottom: ${props => props.theme.spacing.xl};
`;

export const GROUP_TYPES = [
  {
    value: 'group',
    label: 'Generic',
  },
  {
    value: 'group_energy',
    label: 'Energy',
  },
  {
    value: 'group_waste',
    label: 'Waste',
  },
];

class CreateGroup extends Component {
  state = {
    error: '',
    loading: false,
    model: {
      type: 'group',
    },
  };

  handleSubmit = () => {
    const { model } = this.state;
    const data = {
      id: model.id,
      type: model.type,
      name: model.name,
      parentId: this.props.building.id,
      functionalLocation: this.props.functionalLocation,
    };

    this.setState({ loading: true });
    this.props.upsertSensorHierarchy(data).then(({ result }) => {
      if (result) {
        this.afterSubmit();
      } else {
        this.setState({ loading: false });
      }
    });
  };

  afterSubmit = () => {
    this.props.loadSensorHierarchies(this.props.functionalLocation).then(() => {
      this.setState({ loading: false });
      const notification = {
        type: 'success',
        message: this.props.t('Group successfully saved'),
      };
      this.props.onSubmit(null, notification);
    });
  };

  handleFormChange = (property, value) => {
    this.setState(prevState => ({
      model: {
        ...prevState.model,
        [property]: value,
      },
    }));
  };

  getGroupOptions(types, t) {
    return map(types, type => ({ value: type.value, label: t(type.label) }));
  }

  render() {
    const { t, onCancel } = this.props;
    const { loading, model } = this.state;
    const groupOptions = this.getGroupOptions(GROUP_TYPES, t);

    return (
      <DialogModal
        isActive
        t={t}
        onOverlayClick={onCancel}
        footer={
          <DialogFooter>
            <Button cancel onClick={onCancel}>
              {t('Cancel')}
            </Button>
            <Button submit onClick={this.handleSubmit}>
              {loading ? <Loader color="WHITE" size="SMALL" /> : t('Save')}
            </Button>
          </DialogFooter>
        }
      >
        <FormContainer>
          <h3>{t('Add group')}</h3>
          <InputForm onPropertyChange={this.handleFormChange} model={model} id="adminGroupForm">
            <InputRow required id="groupName">
              <InputLabel text={t('Group name')} />
              <InputText property="name" />
            </InputRow>
            <InputRow required>
              <InputLabel text={t('Group type')} />
              <InputSelectDropdown
                model={model}
                property="type"
                options={groupOptions}
                t={t}
                clearable={false}
                id="groupType"
                onChange={this.handleFormChange}
                scrollToMenu
              />
            </InputRow>
          </InputForm>
        </FormContainer>
      </DialogModal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  upsertSensorHierarchy: data => dispatch(upsertSensorHierarchy(data)),
  loadSensorHierarchies: functionalLocation => dispatch(loadSensorHierarchies(functionalLocation, true)),
});

const connector = connect(
  null,
  mapDispatchToProps
);

export default connector(translations(CreateGroup));
