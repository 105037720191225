import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CHART_HEIGHT } from '../utils';

const ChartContainer = styled.div`
  position: relative;
`;

const Text = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.colors.black};
  margin: 30px 0 -10px 20px;

  ${props => props.theme.media.landscape`
        position: absolute;
        top: 31px;
        right: 8px;
        margin 0;
    `}
`;

const Count = styled.span`
  font-weight: bold;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${CHART_HEIGHT}px;
`;

const StatisticsChartContainer = ({ count, countLabel, children, controls, empty, t, ...other }) => (
  <React.Fragment>
    <div>
      {empty ? (
        <NoData>{t('No data available')}</NoData>
      ) : (
        <ChartContainer {...other}>
          <Text>
            <Count>{count}</Count> {countLabel}
          </Text>
          {children}
        </ChartContainer>
      )}
    </div>
    {controls}
  </React.Fragment>
);

StatisticsChartContainer.propTypes = {
  count: PropTypes.number,
  countLabel: PropTypes.string,
  children: PropTypes.node,
  controls: PropTypes.node,
  empty: PropTypes.bool,
  t: PropTypes.func,
};

export default StatisticsChartContainer;
