import styled from 'styled-components';
import React from 'react';

const ValuesBoxComparisonValues = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
ValuesBoxComparisonValues.displayName = 'ValuesBoxComparisonValues';

export default ValuesBoxComparisonValues;
