import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { intersection } from 'lodash';
import Helmet from 'react-helmet';
import { StandardPage } from 'components/index.js';
import translations from 'decorators/Translations/translations';
import { getPartnerNumbers } from 'utils/profile';
import { isValidPartner } from 'utils/Data/partners';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import Header from 'containers/Application/Header/Header.jsx';
import find from 'lodash/find';
import memoizeOne from 'memoize-one';

import { icons } from 'components/Icon/IconNames';
import { default as ServiceRequestForm } from './ServiceRequestForm.jsx';

import {
  setNewServiceRequestProperty,
  createServiceRequest,
  loadFunctionalLocations,
  loadPartnerMeta,
} from 'redux/modules';

class ServiceRequest extends Component {
  state = { saving: false, saved: false };

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadData();
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const {
      loadFunctionalLocation,
      loadPartnerMeta,
      location: { search },
      match: {
        params: { partnerNumber },
      },
    } = this.props;

    const query = queryString.parse(search),
      functionalLocationKey = query.functionalLocation;

    functionalLocationKey && loadFunctionalLocation(functionalLocationKey);

    if (partnerNumber && partnerNumber !== 'all') {
      loadPartnerMeta(partnerNumber);
    }
  };

  createServiceRequest = newServiceRequest => {
    const { profile, functionalLocations } = this.props;

    this.setState({ saving: true });

    // If we are in '/all' -path, find a partnerNumber from FL that is also in user's profile
    // (there should always be at least one)
    if (!isValidPartner(newServiceRequest.partnerNumber)) {
      const profilePartnerNumbers = getPartnerNumbers(profile);
      const functionalLocation = functionalLocations[newServiceRequest.functionalLocation];
      const commonPartnerNumbers =
        functionalLocation && intersection(profilePartnerNumbers, functionalLocation.partnerNumberWithParents);
      if (!commonPartnerNumbers.length) {
        this.setState({ saving: false });
        return;
      }
      newServiceRequest.partnerNumber = commonPartnerNumbers[0];
    }

    this.props.createServiceRequest(newServiceRequest).then(result => {
      !result.error && this.setState({ saving: false, saved: true });
      result.error && this.setState({ saving: false });
    });

    setTimeout(() => {
      this.setState({ saved: false });
    }, 4000);
  };

  render() {
    const {
      t,
      newServiceRequest,
      functionalLocations,
      match: {
        params: { partnerNumber, functionalLocationId },
      },
      features,
      equipment,
      superordinate,
    } = this.props;

    if (features && !features.sr) {
      return null;
    }

    const functionalLocation = functionalLocations[functionalLocationId];

    const links = [
      { title: 'Back to Overview', icon: icons.ARROW_LEFT, to: { pathname: '/' }, smallIcon: true },
      { title: 'Service Request', icon: icons.NEW_SERVICE_REQUEST },
    ];

    const content = (
      <Section>
        <SectionHeader noBorder title={t('Service Request')} t={t} />
        <ServiceRequestForm
          id="serviceRequestForm"
          model={newServiceRequest}
          setProperty={this.props.setNewServiceRequestProperty}
          submit={() => this.createServiceRequest(newServiceRequest)}
          t={t}
          functionalLocation={functionalLocation}
          partnerNumber={partnerNumber}
          equipment={equipment}
          superordinate={superordinate}
          saving={this.state.saving}
          saved={this.state.saved}
        />
      </Section>
    );
    if (functionalLocationId) {
      return content;
    }

    return (
      <StandardPage>
        <Helmet title={t('Service Request')} />
        <Header t={t} showPartnerSelect links={links} selected="service request" />
        {content}
      </StandardPage>
    );
  }
}

ServiceRequest.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  newServiceRequest: PropTypes.object.isRequired,
  functionalLocations: PropTypes.objectOf(PropTypes.object).isRequired,
  profile: PropTypes.object,
  features: PropTypes.objectOf(PropTypes.bool),
  equipment: PropTypes.object,
  superordinate: PropTypes.object,
  setNewServiceRequestProperty: PropTypes.func.isRequired,
  loadFunctionalLocation: PropTypes.func.isRequired,
  loadPartnerMeta: PropTypes.func.isRequired,
  createServiceRequest: PropTypes.func.isRequired,
};

const getEquipmentById = (equipment, id) => (id ? find(equipment, { equipmentNumber: id }) : undefined);
const getEquipment = memoizeOne(getEquipmentById);
const getSuperordinate = memoizeOne(getEquipmentById);

const mapStateToProps = (state, props) => {
  const { functionalLocationId, equipmentNumber, superordinate } = props.match.params;
  const equipments = state.equipments.equipments[functionalLocationId];
  return {
    features: state.profile.profile.syntheticFeatures,
    newServiceRequest: state.serviceRequests.new,
    functionalLocations: state.functionalLocations.functionalLocations,
    equipment: getEquipment(equipments, equipmentNumber),
    superordinate: getSuperordinate(equipments, superordinate),
  };
};

const mapDispatchToProps = {
  setNewServiceRequestProperty,
  createServiceRequest,
  loadFunctionalLocation: functionalLocationId => loadFunctionalLocations([functionalLocationId]),
  loadPartnerMeta,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRouter(connector(translations(ServiceRequest)));
