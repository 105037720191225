import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BreakdownAllWasteTypes from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownAllWasteTypes/BreakdownAllWasteTypes';
import BreakdownOneWasteType from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownOneWasteType/BreakdownOneWasteType';
import translations from 'decorators/Translations/translations';
import {
  formatTimeframe,
  getDataSlice,
} from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteBreakdownUtils';

export const BreakdownTab = ({ t, profile, wasteSensors, tab, data, visible }) => {
  const [selectedTimeframeCategory, setSelectedTimeframeCategory] = useState('month');
  const [selectedTimeframe, setSelectedTimeframe] = useState({
    startTime: moment()
      .utc()
      .startOf(selectedTimeframeCategory),
    endTime: moment()
      .utc()
      .endOf(selectedTimeframeCategory),
  });
  const [dataSlice, setDataSlice] = useState(getDataSlice(data, selectedTimeframe));
  const [selectedSensor, setSelectedSensor] = useState(null);

  const onTimeframeCategoryChange = newTimeframeCategory => {
    const newTimeframe = {
      startTime: moment()
        .utc()
        .startOf(newTimeframeCategory),
      endTime: moment()
        .utc()
        .endOf(newTimeframeCategory),
    };
    setDataSlice(getDataSlice(data, newTimeframe));
    setSelectedTimeframeCategory(newTimeframeCategory);
    setSelectedTimeframe(newTimeframe);
  };

  const onTimeframeChange = newTimeframe => {
    const newTimeframeUtc = {
      startTime: moment(newTimeframe.startTime)
        .utc()
        .startOf(selectedTimeframeCategory),
      endTime: moment(newTimeframe.endTime)
        .utc()
        .endOf(selectedTimeframeCategory),
    };
    setDataSlice(getDataSlice(data, newTimeframeUtc));
    setSelectedTimeframe(newTimeframeUtc);
  };

  const selectSensor = sensorId => {
    if (sensorId) {
      setDataSlice(data.filter(entry => entry.sensorId === String(sensorId)));
      setSelectedSensor(wasteSensors.find(sensor => sensor.id === sensorId));
    }
  };

  const resetSensorSelection = () => {
    setDataSlice(getDataSlice(data, selectedTimeframe));
    setSelectedSensor(null);
  };

  if (!visible) {
    return null;
  }

  return selectedSensor ? (
    <BreakdownOneWasteType
      profile={profile}
      wasteSensors={wasteSensors}
      tab={tab}
      selectedSensor={selectedSensor}
      dataSlice={dataSlice}
      reset={resetSensorSelection}
      selectSensor={selectSensor}
    />
  ) : (
    <BreakdownAllWasteTypes
      profile={profile}
      wasteSensors={wasteSensors}
      timeframeCategoryOptions={['month', 'quarter', 'year'].map(category => ({
        value: category,
        label: t(category),
      }))}
      tab={tab}
      selectedTimeframeCategory={selectedTimeframeCategory}
      onTimeframeCategoryChange={onTimeframeCategoryChange}
      selectedTimeframe={selectedTimeframe}
      selectedTimeframeDescription={formatTimeframe(selectedTimeframeCategory, selectedTimeframe)}
      onTimeframeChange={onTimeframeChange}
      dataSlice={dataSlice}
      selectSensor={selectSensor}
    />
  );
};

BreakdownTab.propTypes = {
  t: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  tab: PropTypes.number.isRequired,
  data: PropTypes.array,
  visible: PropTypes.bool.isRequired,
};

export default translations(BreakdownTab);
