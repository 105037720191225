import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatValue } from 'containers/Application/ServiceCalendar/utils';

const Column = styled.div`
  color: ${props => (props.overSLA ? props.theme.colors.radicalRed : props.theme.colors.darkGray)};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  text-transform: lowercase;
  display: inline;

  ${props => props.theme.media.landscape`
        display: block;
    `}
`;

const ResponseColumn = ({ data, t, nilValue }) => {
  const hasResponse = data && data.response;

  let text;
  if (hasResponse) {
    text = formatValue(data.response);
  } else {
    text = nilValue;
  }

  return <Column overSLA={data.overSLA}>{text}</Column>;
};

export default ResponseColumn;

ResponseColumn.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  nilValue: PropTypes.string.isRequired,
};
