import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledAddressColumn = styled.div`
  font-size: ${props => props.theme.fontSize.xs};
  color: ${props => props.theme.colors.darkGray};
`;

export const AddressColumn = ({ address }) => <StyledAddressColumn>{address}</StyledAddressColumn>;

AddressColumn.propTypes = {
  address: PropTypes.string.isRequired,
};
