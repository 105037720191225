import { createReducerFromMapping } from 'redux/utils/index.js';
import { assign, groupBy, reject, isArray, isEmpty } from 'lodash';
import { MasterData } from '@caverion/redux/api/actions';

const initialState = {
  loading: false,
  meta: {},
  classifications: {},
  error: '',
};

export const LOAD_BUILDING_META = 'CUSTOMER_PLATFORM/BuildingMeta/LOAD_BUILDING_META';
export const LOAD_BUILDING_META_SUCCESS = 'CUSTOMER_PLATFORM/BuildingMeta/LOAD_BUILDING_META_SUCCESS';
export const LOAD_BUILDING_META_FAIL = 'CUSTOMER_PLATFORM/BuildingMeta/LOAD_BUILDING_META_FAIL';

export const loadBuildingMeta = (functionalLocations, filterable) => {
  return async (dispatch, getState) => {
    const filter = {};
    dispatch({ type: LOAD_BUILDING_META });
    if (isArray(functionalLocations) && !isEmpty(functionalLocations)) {
      filter.where = {
        functionalLocation: {
          inq: functionalLocations,
        },
      };
    } else {
      return dispatch({
        type: LOAD_BUILDING_META_FAIL,
      });
    }
    if (filterable) {
      filter.where = { ...filter.where, filterable };
    }

    try {
      const result = await dispatch(MasterData.buildingMeta(filter));
      return dispatch({
        type: LOAD_BUILDING_META_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_BUILDING_META_FAIL,
        error,
      });
    }
  };
};

export const UPDATE_BUILDING_META = 'CUSTOMER_PLATFORM/BuildingMeta/UPDATE_BUILDING_META';
export const UPDATE_BUILDING_META_SUCCESS = 'CUSTOMER_PLATFORM/BuildingMeta/UPDATE_BUILDING_META_SUCCESS';
export const UPDATE_BUILDING_META_FAIL = 'CUSTOMER_PLATFORM/BuildingMeta/UPDATE_BUILDING_META_FAIL';

export const updateBuildingMeta = (buildingMeta, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: UPDATE_BUILDING_META });
    try {
      const result = await dispatch(MasterData.upsertAllBuildingMeta(buildingMeta));
      return dispatch({
        type: UPDATE_BUILDING_META_SUCCESS,
        functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPDATE_BUILDING_META_FAIL,
        error,
      });
    }
  };
};

export const DELETE_BUILDING_META = 'CUSTMOER_PLATFORM/BuildingMeta/DELETE_BUILDING_META';
export const DELETE_BUILDING_META_SUCCESS = 'CUSTMOER_PLATFORM/BuildingMeta/DELETE_BUILDING_META_SUCCESS';
export const DELETE_BUILDING_META_FAIL = 'CUSTMOER_PLATFORM/BuildingMeta/DELETE_BUILDING_META_FAIL';

export const deleteBuildingMeta = (id, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: DELETE_BUILDING_META });
    try {
      const result = await dispatch(MasterData.deleteBuildingMeta(id));
      return dispatch({
        type: DELETE_BUILDING_META_SUCCESS,
        result,
        metaId: id,
        functionalLocation,
      });
    } catch (error) {
      return dispatch({
        type: DELETE_BUILDING_META_FAIL,
        error,
      });
    }
  };
};

export const LOAD_BUILDING_CLASSIFICATIONS = 'CUSTOMER_PLATFORM/BuildingMeta/LOAD_BUILDING_CLASSIFICATIONS';
export const LOAD_BUILDING_CLASSIFICATIONS_SUCCESS =
  'CUSTOMER_PLATFORM/BuildingMeta/LOAD_BUILDING_CLASSIFICATIONS_SUCCESS';
export const LOAD_BUILDING_CLASSIFICATIONS_FAIL = 'CUSTOMER_PLATFORM/BuildingMeta/LOAD_BUILDING_CLASSIFICATIONS_FAIL';

export const loadBuildingClassifications = functionalLocation => {
  const filter = {
    where: {
      path: { 'any x': { x: functionalLocation.functionalLocation } },
    },
  };

  return async dispatch => {
    dispatch({ type: LOAD_BUILDING_CLASSIFICATIONS });
    try {
      const result = await dispatch(MasterData.classifications(JSON.stringify(filter)));
      return dispatch({
        type: LOAD_BUILDING_CLASSIFICATIONS_SUCCESS,
        result,
        functionalLocation: functionalLocation.functionalLocation,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_BUILDING_CLASSIFICATIONS_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_BUILDING_META]: state => ({ ...state, loading: true }),
    [LOAD_BUILDING_META_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_BUILDING_META_SUCCESS]: (state, action) => {
      const meta = assign({}, groupBy(action.result, 'functionalLocation'));
      return { ...state, meta, loading: false };
    },
    [UPDATE_BUILDING_META]: state => ({ ...state, loading: true }),
    [UPDATE_BUILDING_META_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [UPDATE_BUILDING_META_SUCCESS]: (state, action) => {
      const meta = assign({}, state.meta, { [action.functionalLocation]: action.result });
      return {
        ...state,
        meta,
        loading: false,
      };
    },
    [DELETE_BUILDING_META]: state => ({ ...state, loading: true }),
    [DELETE_BUILDING_META_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [DELETE_BUILDING_META_SUCCESS]: (state, action) => {
      const { result, metaId, functionalLocation } = action;
      if (result && result.count === 1) {
        const newMeta = reject(state.meta[functionalLocation], { id: metaId });
        const meta = assign({}, state.meta, { [action.functionalLocation]: newMeta });
        return { ...state, meta, loading: false };
      }

      return { ...state, loading: false };
    },
    [LOAD_BUILDING_CLASSIFICATIONS]: state => ({ ...state, loading: true }),
    [LOAD_BUILDING_CLASSIFICATIONS_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_BUILDING_CLASSIFICATIONS_SUCCESS]: (state, action) => {
      const classifications = assign({}, state.classifications, {
        [action.functionalLocation]: groupBy(action.result, 'functionalLocation'),
      });
      return { ...state, classifications, loading: false };
    },
  },
  initialState
);
