import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import memoizeOne from 'memoize-one';

import DocumentTable from 'components/Documents/DocumentTable/DocumentTable';
import SkeletonText from 'components/Skeletons/SkeletonText';

const Container = styled.div`
  background-color: ${props => props.theme.colors.alabaster};
  margin: -${props => props.theme.spacing.md};
  margin-top: ${props => props.theme.spacing.md};
  padding: ${props => props.theme.spacing.lg} ${props => props.theme.spacing.md};

  ${props => props.theme.media.portrait`
        margin: -${props => props.theme.spacing.xl};
        margin-top: calc(${props => props.theme.spacing.xl} - 3px);
        padding: ${props => props.theme.spacing.xl} ${props => props.theme.spacing.xxl};
    `}

  ${props => props.theme.media.landscape`
        margin: -${props => props.theme.spacing.xxl};
        margin-top: calc(${props => props.theme.spacing.xxl} - 3px);
    `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  margin-top: ${props => props.theme.spacing.sm};
`;

const DocumentCount = styled.div`
  background-color: ${props => (props.count ? props.theme.colors.cerulean : props.theme.colors.lightGray)};
  color: ${props => (props.count ? props.theme.colors.white : props.theme.colors.darkGray)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.font.size.xs};
  padding: ${props => props.theme.spacing.xs};
  border-radius: ${props => props.theme.spacing.md};
  margin-left: ${props => props.theme.spacing.sm};
  min-width: 30px;
`;

const StyledSkeletonText = styled(SkeletonText)`
  max-width: 300px;
  margin: 0;
`;

const download = memoizeOne((downloadDocument, downloadFile, functionalLocationId) => (file, inline) => {
  if (file.blobName || file.externalType) {
    downloadFile(file, inline);
  } else {
    downloadDocument(functionalLocationId, file.documentId, file.id, `${file.name}.${file.extension}`);
  }
});

const ServiceOrderDocuments = ({
  t,
  functionalLocationId,
  documentCount,
  loadingDocuments,
  documents,
  downloadDocument,
  downloadFile,
}) => {
  return (
    <Container>
      <Header>
        <h4>{t('Documents')}</h4>
        <DocumentCount count={documentCount}>{loadingDocuments ? '···' : documentCount}</DocumentCount>
      </Header>
      <Content>
        {loadingDocuments ? (
          <StyledSkeletonText />
        ) : documentCount ? (
          <DocumentTable
            t={t}
            loading={loadingDocuments}
            documents={documents}
            download={download(downloadDocument, downloadFile, functionalLocationId)}
            minimalUI
          />
        ) : (
          t('No documents.')
        )}
      </Content>
    </Container>
  );
};

ServiceOrderDocuments.propTypes = {
  t: PropTypes.func.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  loadingDocuments: PropTypes.bool.isRequired,
  documentCount: PropTypes.number.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object),
  downloadDocument: PropTypes.func,
  downloadFile: PropTypes.func,
};

export default ServiceOrderDocuments;
