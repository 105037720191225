import React from 'react';
import styled from 'styled-components';

const Column = styled.div`
  font-size: ${props => props.theme.fontSize.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  text-align: center;
`;

const PriorityColumn = props => {
  return <Column>{(props.data && props.data.priority) || ''}</Column>;
};

export default PriorityColumn;
