import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import values from 'lodash/values';
import includes from 'lodash/includes';
import groupBy from 'lodash/groupBy';
import capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';

import { loadObservationsContainer, loadMoreObservations } from 'redux/modules/containers/remote-center';
import { percentage } from 'utils/math';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import Benchmarking from './Benchmarking';
import { filterFLsBySelectedPartner } from 'containers/Application/Energy/EnergyOptimizationUtil';
import { FilterPillsContainer, SkeletonFilter, overrideColumnWidth } from 'components/BuildingEvents/BuildingEvents';
import FilterPill from 'components/FilterPills/FilterPill';
import FilterPills from 'components/FilterPills/FilterPills';
import { mapFls } from './utils';
import ChartSection from './ChartSection';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';

const groups = ['heating', 'cooling', 'ventilation'];
const EMPTY_ARRAY = [];

const getPerformanceOPI = observations => {
  const total = observations ? observations.length : null;
  const completed = observations ? filter(observations, { status: 'completed' }).length : null;
  return {
    value: observations ? percentage(completed, total) : undefined,
    total,
    completed,
  };
};

const getMostObservations = (observations, functionalLocations, partnerNumber) => {
  if (!observations || !functionalLocations) {
    return EMPTY_ARRAY;
  }
  return mapFls(functionalLocations, partnerNumber, observations, 'Observations');
};

const getMostCompletedObservations = (observations, functionalLocations, partnerNumber) => {
  if (!observations || !functionalLocations) {
    return EMPTY_ARRAY;
  }

  const completedObservations = filter(observations, { status: 'completed' });
  return mapFls(functionalLocations, partnerNumber, completedObservations, 'Observations');
};

const currentYear = moment().year();

const Observations = props => {
  const { t, partnerNumber, loadData, observationsByPartner, functionalLocations, loadMoreObservations } = props;

  const [observationFilter, setFilter] = useState({ all: true });
  const [year, setYear] = useState(currentYear);
  const [onlyCompleted, setOnlyCompleted] = useState(false);
  const [scrollTo, setScrollTo] = useState(false);

  const yearlyData = (observationsByPartner[partnerNumber] || {})[currentYear];
  const observations = (observationsByPartner[partnerNumber] || {}).current;
  const loading = !yearlyData || yearlyData.loading;
  const flList = values(filterFLsBySelectedPartner(functionalLocations, partnerNumber)) || EMPTY_ARRAY;

  const filteredObservations = observationFilter.all
    ? observations
    : filter(observations, observation => {
        const deviceGroup = includes(groups, observation.deviceGroup) ? observation.deviceGroup : 'other';
        return observationFilter[deviceGroup];
      });

  const performance = getPerformanceOPI(filteredObservations);
  const mostObservations = getMostObservations(filteredObservations, flList, partnerNumber);
  const mostCompleted = getMostCompletedObservations(filteredObservations, flList, partnerNumber);

  useEffect(
    () => {
      loadData(partnerNumber);
      setYear(currentYear);
    },
    [partnerNumber]
  );

  const toggleFilter = (name, value) => {
    if (name === 'all') {
      setFilter({ all: value });
      return;
    }

    const newValues = { ...observationFilter, [name]: value, all: false };
    setFilter(newValues);
  };

  const changeYear = newYear => {
    setYear(newYear);
    loadMoreObservations(partnerNumber, newYear);
  };

  const scrollToElement = () => {
    setOnlyCompleted(true);
    setScrollTo(true);
    setTimeout(() => setScrollTo(false), 500);
  };

  const getChartData = observations =>
    [...Array(12)].map(
      (dummy, month) => filter(observations, item => new Date(item.timestamp).getMonth() === month).length
    );

  const selectedYearObservations = (observationsByPartner[partnerNumber] || {})[year];
  let chartObservations = (selectedYearObservations || {}).data || [];
  chartObservations = onlyCompleted ? filter(chartObservations, { status: 'completed' }) : chartObservations;
  const groupedObservations = groupBy(chartObservations, observation =>
    includes(groups, observation.deviceGroup) ? observation.deviceGroup : 'other'
  );

  return (
    <Fragment>
      <FilterPillsContainer>
        {loading && (
          <FilterPills>
            <SkeletonFilter />
            <SkeletonFilter />
            <SkeletonFilter />
          </FilterPills>
        )}
        {!loading && (
          <FilterPills>
            <FilterPill selected={observationFilter.all} onClick={value => toggleFilter('all', value)}>
              {t('Show All')}
            </FilterPill>
            <FilterPill
              icon="Cooling"
              selected={observationFilter.cooling}
              onClick={value => toggleFilter('cooling', value)}
            >
              {t('Cooling systems')}
            </FilterPill>
            <FilterPill
              icon="Heating"
              selected={observationFilter.heating}
              onClick={value => toggleFilter('heating', value)}
            >
              {t('Heating systems')}
            </FilterPill>
            <FilterPill
              icon="Ventilation"
              selected={observationFilter.ventilation}
              onClick={value => toggleFilter('ventilation', value)}
            >
              {t('Ventilation')}
            </FilterPill>
            <FilterPill icon="Other" selected={observationFilter.other} onClick={value => toggleFilter('other', value)}>
              {t('Other')}
            </FilterPill>
          </FilterPills>
        )}
      </FilterPillsContainer>
      <OPICards>
        <OPICard
          t={t}
          loading={loading}
          title={t('Observations')}
          value={performance.value}
          subtitle={`${performance.completed ? performance.completed : '-'}\u00A0${t('Completed')}\u00A0/\u00A0${
            performance.total ? performance.total : '-'
          }\u00A0${t('Total')}\n(${t('Last 365 Days')})`}
          ctxHelp={`${CTXHELP_PREFIX} Alarms OPI`}
          neutral
          overrideColumnWidth={overrideColumnWidth}
          onClick={() => scrollToElement()}
        />
      </OPICards>
      <Benchmarking
        t={t}
        ctxHelp={`${CTXHELP_PREFIX} Observations Benchmarking`}
        leftTitle="Most observations"
        rightTitle="Most completed observations"
        leftData={mostObservations}
        rightData={mostCompleted}
        infoText="Last 365 Days"
        loading={loading}
      />
      {scrollTo && <ScrollToComponent />}
      <ChartSection
        t={t}
        year={year}
        changeYear={changeYear}
        series={groups.concat('other').map(group => ({
          data: getChartData(groupedObservations[group]),
          name: t(capitalize(group)),
          _showTooltipForZeroValue: true,
          _unit: t('Observations'),
          _hideTooltipUnit: true,
        }))}
        loading={!selectedYearObservations || selectedYearObservations.loading}
        tabOptions={[{ label: t('New'), value: 'new' }, { label: t('Completed'), value: 'completed' }]}
        selectedTab={onlyCompleted ? 'completed' : 'new'}
        onTabChange={(property, value) => setOnlyCompleted(value === 'completed')}
      />
    </Fragment>
  );
};

Observations.propTypes = {
  t: PropTypes.func.isRequired,
  partnerNumber: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  observationsByPartner: state.notice.observationsByPartner,
  functionalLocations: state.functionalLocations.functionalLocations,
});

const mapDispatchToProps = dispatch => ({
  loadData: partnerNumber => dispatch(loadObservationsContainer(partnerNumber)),
  loadMoreObservations: (partnerNumber, year) => dispatch(loadMoreObservations(partnerNumber, year)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Observations);
