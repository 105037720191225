import React from 'react';

import InputSelectDropdown from './InputSelectDropdown';

class InputSearchDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ticker: null,
      value: props.initialValue || '',
    };
  }

  handleChange(value) {
    this.setState(prevState => {
      if (prevState.ticker !== null) {
        clearTimeout(prevState.ticker);
      }

      const ticker =
        typeof value === 'string' && value.length >= 3 ? setTimeout(() => this.props.onSearch(value), 500) : null;

      return {
        ticker: ticker,
        value: value,
      };
    });
  }

  render() {
    return (
      <InputSelectDropdown
        {...this.props}
        onInput={value => this.handleChange(value)}
        noResultsText={
          this.props.noResultsText || this.props.t('No results found. Change search keyword or input more characters.')
        }
        placeholder={this.props.placeholder || `${this.props.t('Select or search')}...`}
        filterOptions={false}
      />
    );
  }
}

export default InputSearchDropdown;
