import React from 'react';
import FloorTab from 'containers/Application/Modules/FloorModule/FloorPlan/FloorTab';
import memoizeOne from 'memoize-one';
import { filter, find, isEmpty, orderBy } from 'lodash';
import { getLatestCleaningData, mapCleaningData } from 'utils/Data/cleaning.js';
import { getFloorFeatures } from 'utils/Data/sensorHierarchy';
import { getOPIData } from './FloorOPICards/FloorOPIUtils';

const EMPTY_ARRAY = [];

export const floorTabsContent = memoizeOne(
  (
    t,
    loading,
    floors,
    valuesBySensorId,
    latestValuesBySensorId,
    cleaningValues,
    buildingMeta,
    sensorAlarmsById,
    renderTooltip,
    toggleDialog
  ) => {
    if (isEmpty(floors)) {
      return EMPTY_ARRAY;
    }

    return orderBy(floors, 'order').map(floor => {
      const floorData = getFloorData(t, floor, cleaningValues, latestValuesBySensorId, buildingMeta, sensorAlarmsById);
      const { floorSensors, floorAreas } = floorData;
      const opiData = getOPIData({
        floorSensors,
        floorAreas,
        valuesBySensorId,
        buildingMeta,
        latestValues: latestValuesBySensorId,
      });
      const tooltipContent = renderTooltip(opiData);

      return {
        title: floor.shortName,
        performance: 'neutral',
        tooltipContent,
        content: <FloorTab floor={floorData} opiData={opiData} toggleDialog={toggleDialog(floor)} loading={loading} />,
      };
    });
  }
);

export const getFloorData = (t, floor, cleaningValues, latestValuesBySensorId, buildingMeta, sensorAlarmsById) => {
  const {
    floorSensors,
    floorAreas,
    floorImage,
    floorImagePath,
    floorIcons,
    areaFeatures,
    sensorFeatures,
  } = getFloorFeatures(floor, latestValuesBySensorId, t, true, undefined, buildingMeta, sensorAlarmsById);

  const cleaningSensors = filter(floorSensors, sensor => sensor.sensorType && sensor.sensorType.name === 'cleaning');
  const cleaningSensorIds = cleaningSensors.map(sensor => sensor.id);

  const floorCleanings = filter(
    cleaningValues,
    value => cleaningSensorIds.indexOf(parseInt(value.sensorId, 10)) !== -1
  );

  const floorCleaningData = floorCleanings.map(value => mapCleaningData(t, value, floor));
  const { latestCleaning, latestCleaningTime, isCleanedToday } = getLatestCleaningData(floorCleaningData);

  return {
    id: floor.id,
    number: floor.order,
    title: floor.shortName,
    image: floorImagePath,
    imageWidth: floorImage ? floorImage.width : 3295,
    imageHeight: floorImage ? floorImage.height : 1800,
    areas: {
      type: 'FeatureCollection',
      features: areaFeatures,
    },
    sensors: {
      type: 'FeatureCollection',
      features: sensorFeatures,
    },
    floorIcons,
    cleaningSensors,
    floorCleanings,
    floorCleaningData,
    latestCleaning,
    latestCleaningTime,
    isCleanedToday,
    floorSensors,
    floorAreas,
  };
};

export const replaceMultisensorIdWithSubsensorsIds = (sensors, sensorsIds, includeMainSensor) => {
  let idArray = [];
  sensorsIds.forEach(id => {
    const sensor = find(sensors, { id });
    if (!sensor) {
      return;
    }
    if (sensor.children && sensor.children.length > 0) {
      if (includeMainSensor) {
        idArray.push(id);
      }

      idArray = idArray.concat(sensor.children.map(child => child.id));
    } else {
      idArray.push(id);
    }
  });
  return idArray;
};
