import { createReducerFromMapping } from 'redux/utils/index.js';
import { Profile } from '@caverion/redux/api/actions';
import { setGlobalLocale } from 'utils/Date/dateFormatter';
import cookies from 'utils/Cookies/Cookies.js';
import { LOAD_ME_SUCCESS } from '../profile/profile';

const initialState = {
  language: 'en',
  languages: ['fi', 'en', 'sv', 'nn', 'da'],
  translations: {},
  loadingUnauthenticated: true,
};

export const LOAD = 'CUSTOMER_PLATFORM/Translations/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/Translations/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/Translations/LOAD_FAIL';
export const SET_LANGUAGE = 'CUSTOMER_PLATFORM/Translations/SET_LANGUAGE';

export const loadTranslations = language => {
  return async dispatch => {
    dispatch({ type: LOAD });
    try {
      const result = await dispatch(Profile.loadTranslations(language));
      return dispatch({
        type: LOAD_SUCCESS,
        key: JSON.stringify([LOAD]),
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        key: JSON.stringify([LOAD]),
        error,
      });
    }
  };
};

export const LOAD_UNAUTHENTICATED = 'CUSTOMER_PLATFORM/Translations/LOAD_UNAUTHENTICATED';
export const LOAD_UNAUTHENTICATED_SUCCESS = 'CUSTOMER_PLATFORM/Translations/LOAD_UNAUTHENTICATED_SUCCESS';
export const LOAD_UNAUTHENTICATED_FAIL = 'CUSTOMER_PLATFORM/Translations/LOAD_UNAUTHENTICATED_FAIL';

export const loadUnauthenticatedTranslations = language => {
  return async dispatch => {
    dispatch({ type: LOAD_UNAUTHENTICATED });
    try {
      const result = await dispatch(Profile.loadUnauthenticatedTranslations(language));
      return dispatch({
        type: LOAD_UNAUTHENTICATED_SUCCESS,
        key: JSON.stringify([LOAD_UNAUTHENTICATED]),
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_UNAUTHENTICATED_FAIL,
        key: JSON.stringify([LOAD_UNAUTHENTICATED]),
        error,
      });
    }
  };
};

export const setLanguage = language => {
  setGlobalLocale(language);
  cookies.setItem('language', language, Infinity, '/');
  const googleLanguage = language === 'nb' ? 'no' : language;
  cookies.setItem('googtrans', `/en/${googleLanguage}`, Infinity, '/');
  return { type: SET_LANGUAGE, language };
};

export default createReducerFromMapping(
  {
    [LOAD]: state => ({ ...state, loading: true }),
    [LOAD_SUCCESS]: (state, action) => ({ ...state, loaded: true, loading: false, translations: action.result }),
    [LOAD_FAIL]: (state, action) => ({ ...state, loaded: false, loading: false, error: action.error }),
    [LOAD_UNAUTHENTICATED]: state => ({ ...state, loadingUnauthenticated: true }),
    [LOAD_UNAUTHENTICATED_SUCCESS]: (state, action) => ({
      ...state,
      loadingUnauthenticated: false,
      translations: action.result,
    }),
    [LOAD_UNAUTHENTICATED_FAIL]: (state, action) => ({ ...state, loadingUnauthenticated: false, error: action.error }),
    [SET_LANGUAGE]: (state, action) => ({ ...state, language: action.language }),
    [LOAD_ME_SUCCESS]: (state, action) => ({
      ...state,
      loaded: true,
      loading: false,
      translations: action.result.translations,
    }),
  },
  initialState
);
