import { hasPartnerPermission } from '../../utils/profile';

const defaults = {
  buildFunctionalLocationFilter: functionalLocations => ({
    path: {
      'any x': {
        x: {
          inq: functionalLocations,
        },
      },
    },
  }),
  buildPartnerNumberFilter: partnerNumber => ({
    partnerNumberWithParents: {
      'any x': {
        x: partnerNumber,
      },
    },
  }),
};

/**
 * Construct where filter based on either partner number or functional location. If user has partner permissions,
 * partnerNumber based filter is returned. Otherwise, filter will include the partner's functional permissions the user
 * has permissions to.
 */
export const getWhereFromPermissions = (profile, partnerNumber, options) => {
  const config = {
    ...defaults,
    ...options,
  };

  if (hasPartnerPermission(profile, partnerNumber)) {
    return config.buildPartnerNumberFilter(partnerNumber);
  }

  const functionalLocations = profile.permissions[partnerNumber] || [];
  return config.buildFunctionalLocationFilter(functionalLocations);
};
