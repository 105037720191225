import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import StatusInfo from 'components/StatusInfo/StatusInfo';

const ConsumptionKPI = ({ t, kpi, heading, history, partnerNumber, unit, formatValue, sideNaviExtended }) => {
  const currentYear = moment().year();
  const previousYear = currentYear - 1;
  const formatTooltip = value => `${formatValue(value)} ${unit}`;
  return (
    <StatusInfo
      t={t}
      sideNaviExtended={sideNaviExtended}
      loading={kpi.loading}
      valuePosition="outside"
      precision={1}
      heading={t(heading)}
      value={formatValue(kpi.value)}
      unit={unit}
      valueNote={t('Last 12 Months')}
      items={[
        {
          label: previousYear,
          value: formatValue(kpi.previous),
          unit,
          showUnit: kpi.previous !== null,
        },
        {
          label: currentYear,
          value: formatValue(kpi.current),
          unit,
          showUnit: kpi.current !== null,
        },
      ]}
      chart={kpi.chart}
      onClick={() => history.push(`/${partnerNumber}/EnergyOptimization`)}
      formatTooltip={formatTooltip}
    />
  );
};

ConsumptionKPI.propTypes = {
  t: PropTypes.func.isRequired,
  kpi: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  formatValue: PropTypes.func.isRequired,
  sideNaviExtended: PropTypes.bool.isRequired,
};

export default React.memo(ConsumptionKPI);
