import { getFloorFeatures } from 'utils/Data/sensorHierarchy';

// Creates floor object for Blueprint module
export const createFloorFeatures = (floor, latestValuesBySensorId, t, editId) => {
  if (!floor) {
    return {};
  }

  const {
    floorImage,
    floorImagePath,
    floorAreas,
    floorSensors,
    areaFeatures,
    sensorFeatures,
    floorIcons,
  } = getFloorFeatures(floor, latestValuesBySensorId, t, false, editId);

  const floorObj = {
    id: floor.id,
    areaList: floorAreas,
    sensorList: floorSensors,
    number: floor.order,
    title: floor.shortName,
    image: floorImagePath,
    imageId: floorImage ? floorImage.id : null,
    imageWidth: floorImage ? floorImage.width : null,
    imageHeight: floorImage ? floorImage.height : null,
    areas: {
      type: 'FeatureCollection',
      features: areaFeatures,
    },
    sensors: {
      type: 'FeatureCollection',
      features: sensorFeatures,
    },
    floorIcons,
  };
  return floorObj;
};
