import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SortableTable from 'components/SortableTable/SortableTable';
import ObservationsRow from './ObservationsRow';
import TableCell from './../EventsRow/EventsCell';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { showModal } from 'redux/modules/modal/modal';
import { MODALTYPE } from 'components/Modal/ModalTypes';

const SkeletonBodyComponent = () => (
  <tbody data-test-id="SkeletonObservationsTable">
    <tr>
      <TableCell grow hideOnMobile>
        <SkeletonText width="40%" style={{ marginLeft: '0' }} />
        <SkeletonText width="40%" style={{ marginLeft: '0' }} />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell grow hideOnDesktop>
        <SkeletonText />
        <SkeletonText />
      </TableCell>
    </tr>
  </tbody>
);

const NoDataAvailable = styled.p`
  text-align: center;
  margin-top: ${props => props.theme.spacing.md};
`;

const ObservationsTable = props => {
  const { t, data = [], loading, showModal } = props;

  const openModal = data => showModal(MODALTYPE.OBSERVATIONS_TIMELINE, null, null, { data, t });

  if (data.length === 0) {
    return <NoDataAvailable>{t('No data available')}</NoDataAvailable>;
  }

  return (
    <SortableTable
      columns={[
        {
          title: t('Date'),
          field: 'date',
          sortable: true,
          width: '150px',
          align: 'left',
        },
        {
          title: t('Type'),
          field: 'deviceGroup',
          sortable: true,
          width: '150px',
          align: 'left',
        },
        {
          title: t('Description'),
          field: 'location',
          sortable: true,
        },
        {
          title: t('Status'),
          field: 'status',
          sortable: true,
          width: '180px',
          align: 'left',
        },
      ]}
      data={data}
      RowComponent={ObservationsRow}
      SkeletonBodyComponent={SkeletonBodyComponent}
      headerBackgroundColor="transparent"
      t={t}
      showControls
      orderBy="date"
      order="desc"
      loading={loading}
      removeHeadOnMobile
      onClick={openModal}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
    dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps)),
});

const connector = connect(
  null,
  mapDispatchToProps
);

export default connector(ObservationsTable);
