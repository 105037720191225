import React from 'react';
import styled from 'styled-components';
import DateColumn from './../ColumnTypes/DateColumn';
import TitleColumn from '../ColumnTypes/TitleColumn';
import InfoColumn from '../ColumnTypes/InfoColumn';
import EventsRow from 'components/BuildingEvents/EventsRow/EventsRow';
import EventsCell from 'components/BuildingEvents/EventsRow/EventsCell';

const StyledCleaningRow = styled(EventsRow)`
  cursor: ${props => props.onClick && 'pointer'};
`;

const CleaningRow = props => {
  const { rowData, t, onClick } = props;

  return (
    <StyledCleaningRow onClick={() => onClick(rowData)} highlight={rowData.selected} noAlternatingRows>
      <EventsCell noPadding>
        <TitleColumn data={rowData.area} />
      </EventsCell>
      <EventsCell noPadding>
        <InfoColumn data={rowData.value} t={t} />
      </EventsCell>
      <EventsCell noPadding>
        <DateColumn data={rowData.date} />
      </EventsCell>
    </StyledCleaningRow>
  );
};

export default CleaningRow;
