import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import SensorValues from 'containers/Application/SensorValues/SensorValues';
import { getDefaultSubsensor } from 'utils/Data/sensor';
import memoizeOne from 'memoize-one';

const getDeviceSensors = memoizeOne(sensor =>
  sensor.children && sensor.children.length > 0 ? sensor.children : [sensor]
);

const ConditionModal = ({
  sensor,
  subsensor,
  onClick,
  functionalLocation,
  t,
  category,
  buildingMeta,
  sensorAlarmsById,
  title,
  openingHours,
}) => {
  let sensors = getDeviceSensors(sensor);

  const isAirQuality =
    sensor &&
    sensor.sensorType &&
    (sensor.sensorType.graphType === 'air_quality' || sensor.sensorType.graphType === 'technical_performance');

  if (isAirQuality) {
    sensors = [sensor, ...sensors];
  }

  return (
    <Dialog animate isActive onOverlayClick={onClick}>
      <DialogFrame onClose={onClick} smallVerticalPadding>
        <SensorValues
          sensorsIds={sensors.map(sensor => sensor.id)}
          buildingSensors={sensors}
          functionalLocation={functionalLocation}
          defaultSensorId={(subsensor && subsensor.id) || getDefaultSubsensor(sensor)?.id}
          isAirQuality={isAirQuality}
          category={category}
          buildingMeta={buildingMeta}
          sensorAlarmsById={sensorAlarmsById}
          title={title}
          openingHours={openingHours}
        />
      </DialogFrame>
    </Dialog>
  );
};

ConditionModal.propTypes = {
  sensor: PropTypes.object.isRequired,
  subsensor: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  functionalLocation: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  category: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    shortName: PropTypes.string,
  }),
  buildingMeta: PropTypes.arrayOf(PropTypes.object),
  sensorAlarmsById: PropTypes.object,
  title: PropTypes.string,
  openingHours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default ConditionModal;
