import React from 'react';
import styled from 'styled-components';
import { Skeleton } from './SkeletonText';
import SkeletonAnimation from './SkeletonAnimation';

const Pill = styled(Skeleton)`
  display: flex;
  margin: ${props => props.margin || '8px 8px 14px 0'};
  &:first-child {
    margin: ${props => props.margin || '8px 8px 14px 0'};
  }
`;
Pill.displayName = 'Pill';

const SkeletonPill = props => (
  <Pill {...props} header>
    <SkeletonAnimation />
  </Pill>
);

export default SkeletonPill;
