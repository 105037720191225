import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { placeholder } from 'polished';

const Textarea = styled.textarea`
  font-size: ${props => props.theme.input.font.mobileSize};
  font-family: ${props => props.theme.input.font.family};
  line-height: ${props => props.theme.input.lineHeight.default};
  color: ${props => props.theme.input.font.color.default};
  height: ${props => props.cssHeight || '5.5em'};
  width: 100%;
  padding: ${props => props.theme.input.padding};
  border: ${props => props.theme.input.border.static};
  border-radius: 0;
  box-shadow: ${props => props.theme.input.shadow.static};
  transition: ${props => props.theme.input.transition};
  &:hover,
  &:focus {
    box-shadow: ${props => props.theme.input.shadow.active};
    border: ${props => props.theme.input.border.active};
  }
  resize: none; /* Disabled because it breaks layout for some reason */
  ${props => placeholder({ color: props.theme.input.font.color.placeholder, opacity: 1 })}

  ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.input.font.size};
    `}
`;
Textarea.displayName = 'Textarea';

export const InputTextArea = props => {
  const value = _.get(props.model, props.property) || props.value || '';

  return (
    <Textarea
      id={props.id}
      data-test-id={props['data-test-id']}
      onChange={e => props.onChange(props.property, e.target.value)}
      placeholder={props.placeholder}
      readOnly={props.readOnly}
      required={props.required}
      maxLength={props.maxLength}
      value={value}
      cssHeight={props.height}
    />
  );
};

export default InputTextArea;
