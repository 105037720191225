import React from 'react';
import styled from 'styled-components';

import SkeletonPill from 'components/Skeletons/SkeletonPill';

export const ShowContainer = styled.div`
  padding: ${props => props.theme.spacing.xs} 0;
`;

export const NoDataAvailable = styled.p`
  margin: ${props => props.theme.spacing.md} 0;
  color: ${props => props.theme.colors.black};
`;
NoDataAvailable.displayName = 'NoDataAvailable';

export const HeaderInfo = styled.span`
  margin: ${props => props.theme.spacing.sm};
  font-size: ${props => props.theme.font.size.xxs};
  color: ${props => props.theme.colors.darkGray};

  ${props => props.theme.media.landscape`
        margin-left: ${props => props.theme.spacing.md};
        margin-right: auto;
    `}
`;

export const SkeletonFilter = () => <SkeletonPill width="110px" margin="8px 8px 0" />;

export const FilterPillsContainer = styled.div`
    padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.md} 0;

    ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.xl} 0 0;
        margin-bottom: -${props => props.theme.spacing.xl};
    `}
    ${props => props.theme.media.landscape`
        margin-bottom: 0;
    `}
    ${props => props.theme.media.desktop`
        padding: 0;
    `}
`;

export const overrideColumnWidth = {
  bigDesktop: 4,
  desktop: 4,
  landscape: 4,
  portrait: 4,
  default: 6,
};
