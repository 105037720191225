import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';

import FloorTabs from 'components/FloorTabs/FloorTabs.jsx';
import FloorSensorDialog from './FloorSensorDialog/FloorSensorDialog';
import FloorTooltip from './FloorPlan/FloorTooltip';
import FloorSkeleton from './FloorSkeleton';
import { getOpeningHours } from 'utils/Data/performance';
import { floorTabsContent, replaceMultisensorIdWithSubsensorsIds } from './FloorModuleUtils';
import { getDefaultSubsensor } from 'utils/Data/sensor';
import translations from 'decorators/Translations/translations';
import connect from './connectFloorModule';

const renderTooltip = opiData => <FloorTooltip opiData={opiData} />;

export class FloorModule extends Component {
  state = {
    firstRender: true,
    dialogOpen: false,
    dialogTitle: null,
    dialogType: null,
  };
  timeout = null;

  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({ firstRender: false }), 400);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  toggleDialog = floor => (title, type, sensorsIds, includeMainSensor = false) => {
    if (!sensorsIds || sensorsIds.length === 0) {
      this.setState({ dialogOpen: false });
      return;
    }

    const newIds = replaceMultisensorIdWithSubsensorsIds(this.props.sensors, sensorsIds, includeMainSensor);

    if (!newIds || newIds.length === 0) {
      this.setState({ dialogOpen: false });
      return;
    }

    const sensor = find(this.props.sensors, { id: sensorsIds[0] });
    const defaultSensorId = getDefaultSubsensor(sensor)?.id;

    const isTechnicalPerformance = get(sensor, 'sensorType.name') === 'technical_performance';
    const openingHours = isTechnicalPerformance ? getOpeningHours(floor, this.props.buildingMeta) : undefined;

    const isAreaUtilizationRate = get(sensor, 'sensorType.name') === 'area_count';

    this.setState(oldState => ({
      dialogOpen: !oldState.dialogOpen,
      dialogTitle: title && !isAreaUtilizationRate ? title : null,
      dialogType: type,
      sensorsIds: newIds,
      defaultSensorId,
      sensor,
      category: floor,
      openingHours,
    }));
  };

  render() {
    const {
      t,
      floors,
      valuesBySensorId,
      latestValuesBySensorId,
      cleaningValues,
      loading,
      sensors,
      functionalLocation,
      buildingMeta,
      sensorAlarmsById,
      partnerNumber,
      features,
    } = this.props;
    const {
      dialogOpen,
      sensorsIds,
      defaultSensorId,
      dialogType,
      category,
      dialogTitle,
      openingHours,
      sensor,
      firstRender,
    } = this.state;

    if (firstRender || loading) {
      return <FloorSkeleton />;
    }

    return (
      <Fragment>
        <FloorSensorDialog
          dialogOpen={dialogOpen}
          sensorsIds={sensorsIds}
          defaultSensorId={defaultSensorId}
          category={category}
          openingHours={openingHours}
          sensor={sensor}
          dialogTitle={dialogTitle}
          sensors={sensors}
          functionalLocation={functionalLocation}
          partnerNumber={partnerNumber}
          features={features}
          floors={floors}
          dialogType={dialogType}
          toggleDialog={this.toggleDialog}
          sensorAlarmsById={sensorAlarmsById}
        />
        <FloorTabs
          t={t}
          loading={loading}
          content={floorTabsContent(
            t,
            loading,
            floors,
            valuesBySensorId,
            latestValuesBySensorId,
            cleaningValues,
            buildingMeta,
            sensorAlarmsById,
            renderTooltip,
            this.toggleDialog
          )}
        />
      </Fragment>
    );
  }
}

FloorModule.propTypes = {
  t: PropTypes.func.isRequired,
  floors: PropTypes.array.isRequired,
  valuesBySensorId: PropTypes.object.isRequired,
  latestValuesBySensorId: PropTypes.object.isRequired,
  cleaningValues: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  sensors: PropTypes.array.isRequired,
  functionalLocation: PropTypes.object.isRequired,
  buildingMeta: PropTypes.array.isRequired,
  sensorAlarmsById: PropTypes.object.isRequired,
  partnerNumber: PropTypes.string.isRequired,
  features: PropTypes.object.isRequired,
};

export default connect(translations(FloorModule));
