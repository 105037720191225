import React from 'react';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';

export const RecyclingOPICardsSkeleton = () => (
  <OPICards>
    <OPICard loading />
    <OPICard loading />
    <OPICard loading />
  </OPICards>
);

export default RecyclingOPICardsSkeleton;
