import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledBackLinkArrow = styled.span`
  display: inline-block;
  margin-right: ${props => props.theme.spacing.xs};
  &::after {
    content: '←';
  }
`;

const StyledBackLink = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing.xl};
  color: ${props => props.theme.colors.cerulean};
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  ${props => props.theme.media.landscape`
        color: ${props => props.theme.colors.white};
        background-color: inherit;
        justify-content: flex-start;
    `}
`;

const StyledBackLinkContent = styled.div`
  font-size: ${props => props.theme.font.size.xxs};
  font-family: ${props => props.theme.font.family.arial};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
`;

StyledBackLinkContent.displayName = 'StyledBackLinkContent';

export const BackLink = ({ text, history }) => (
  <StyledBackLink data-test-id="UserManualBackLink">
    <StyledBackLinkContent onClick={() => history.goBack()}>
      <StyledBackLinkArrow />
      {text}
    </StyledBackLinkContent>
  </StyledBackLink>
);

BackLink.propTypes = {
  text: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
