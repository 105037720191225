import React from 'react';
import styled, { withTheme } from 'styled-components';
import { PermissionListItemChip } from '.';

const StyledPermissionListItemsChips = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70px;
`;

export const PermissionListItemsChips = withTheme(({ t, theme, added, removed, included }) => (
  <StyledPermissionListItemsChips>
    {added && !removed && (
      <PermissionListItemChip background={theme.colors.emerald}>{t('Added')}</PermissionListItemChip>
    )}
    {removed && <PermissionListItemChip background={theme.colors.radicalRed}>{t('Removed')}</PermissionListItemChip>}
    {included && <PermissionListItemChip color={theme.colors.emerald}>{t('Included')}</PermissionListItemChip>}
  </StyledPermissionListItemsChips>
));
