import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';

const initialState = {
  menuOpen: false,
  sending: false,
  sent: false,
  error: '',
};

export const TOGGLE_FEEDBACK_PANEL = 'CUSTOMER_PLATFORM/Feedback/TOGGLE_FEEDBACK_MENU';

export const toggleFeedbackPanel = () => ({ type: TOGGLE_FEEDBACK_PANEL });

export const SEND_FEEDBACK = 'CUSTOMER_PLATFORM/Feedback/SEND_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'CUSTOMER_PLATFORM/Feedback/SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAIL = 'CUSTOMER_PLATFORM/Feedback/SEND_FEEDBACK_FAIL';

export const sendFeedback = feedback => {
  return async dispatch => {
    dispatch({ type: SEND_FEEDBACK });
    try {
      const result = await dispatch(MasterData.sendFeedback(JSON.stringify(feedback)));
      return dispatch({
        type: SEND_FEEDBACK_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch({
        type: SEND_FEEDBACK_FAIL,
        error: 'Sending feedback failed',
      });
      throw error;
    }
  };
};

export default createReducerFromMapping(
  {
    [TOGGLE_FEEDBACK_PANEL]: state => ({
      ...state,
      menuOpen: !state.menuOpen,
      sent: initialState.sent,
      error: initialState.error,
    }),
    [SEND_FEEDBACK]: state => ({
      ...state,
      sending: true,
    }),
    [SEND_FEEDBACK_SUCCESS]: state => ({
      ...state,
      sending: false,
      sent: true,
      menuOpen: false,
    }),
    [SEND_FEEDBACK_FAIL]: (state, action) => ({
      ...state,
      sending: false,
      sent: false,
      error: action.error,
    }),
  },
  initialState
);
