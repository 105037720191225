import React from 'react';
import PropTypes from 'prop-types';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import translations from 'decorators/Translations/translations';
import styled, { withTheme } from 'styled-components';

const StyledWasteTypeSelector = styled.div`
  width: 100%;
  margin-bottom: 1em;
  p {
    text-transform: capitalize;
  }
  ${props =>
    props.isMobile
      ? `> div {
        justify-content: center;
        margin-top: 0.5em;
      }`
      : ''}
`;

export const WasteTypeSelector = ({ t, theme, wasteTypeOptions, selectedWasteType, onWasteTypeChange }) => {
  const isMobile = window.innerWidth < theme.breakpoints.landscape;
  return (
    <StyledWasteTypeSelector isMobile={isMobile}>
      <InputSelectDropdown
        t={t}
        id="wasteType"
        model={{ wasteType: selectedWasteType }}
        property="wasteType"
        options={wasteTypeOptions}
        onChange={(property, value) => onWasteTypeChange(value)}
        clearable={false}
      />
    </StyledWasteTypeSelector>
  );
};

WasteTypeSelector.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  wasteTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedWasteType: PropTypes.number,
  onWasteTypeChange: PropTypes.func.isRequired,
};

export default withTheme(translations(WasteTypeSelector));
