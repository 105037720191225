import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.alabaster};

  ${props => props.theme.media.portrait`
        margin: ${props => props.theme.spacing.md} 0;
        padding: ${props => props.theme.spacing.md} 0;
    `};

  ${props => props.theme.media.landscape`
        margin: ${props => props.theme.spacing.md} 0 -${props => props.theme.spacing.md};
        padding: ${props => props.theme.spacing.md} 0 0;
    `};
`;

const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  ${props => props.theme.media.portrait`
        flex-wrap: wrap;
    `}

  ${props => props.theme.media.landscape`
        overflow: visible;
        margin: 0 auto;
        max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
    `}
`;

const StatusInfos = ({ children }) => (
  <Container>
    <Content>{children}</Content>
  </Container>
);

StatusInfos.displayName = 'StatusInfos';

export default StatusInfos;
