import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

const StyledUserManualHeader = styled.div`
  width: 100%;
  h1 {
    margin-right: ${props => props.theme.spacing.md};
  }
`;

StyledUserManualHeader.displayName = 'StyledUserManualHeader';

export const UserManualHeader = ({ data }) => {
  if (data && data.title) {
    return (
      <StyledUserManualHeader data-test-id="UserManualHeader">
        {RichText.render(data.title)}
        {RichText.render(data.version)}
      </StyledUserManualHeader>
    );
  }
};

UserManualHeader.propTypes = {
  data: PropTypes.object.isRequired,
};
