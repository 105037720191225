import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT } from '@caverion/redux/api/actions';

export const initialState = {
  importingSensorValues: false,
};

export const IMPORT_SENSOR_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/IMPORT_SENSOR_VALUES';
export const IMPORT_SENSOR_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/IMPORT_SENSOR_VALUES_SUCCESS';
export const IMPORT_SENSOR_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/IMPORT_SENSOR_VALUES_FAIL';

export const importSensorValues = sensorValues => async dispatch => {
  dispatch({ type: IMPORT_SENSOR_VALUES });
  try {
    const result = await dispatch(IoT.importValues(sensorValues));
    dispatch({
      type: IMPORT_SENSOR_VALUES_SUCCESS,
      result,
    });
    return result;
  } catch (error) {
    dispatch({
      type: IMPORT_SENSOR_VALUES_FAIL,
      error,
    });
    throw error;
  }
};

export default createReducerFromMapping(
  {
    [IMPORT_SENSOR_VALUES]: state => ({
      ...state,
      importingSensorValues: true,
    }),
    [IMPORT_SENSOR_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      importingSensorValues: false,
    }),
    [IMPORT_SENSOR_VALUES_FAIL]: (state, action) => ({
      ...state,
      importingSensorValues: false,
    }),
  },
  initialState
);
