import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { find, filter, includes, isEqual, omit } from 'lodash';
import 'react-select/dist/react-select.css';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import SensorTable from 'components/SensorTable/SensorTable';
import OpiOverall from './OpiOverall';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import PerformanceModal from './PerformanceModal';
import SensorAlarm from 'containers/Application/SensorAlarm/SensorAlarm';
import SnackBar from 'components/SnackBar/SnackBar';

import translations from 'decorators/Translations/translations';
import { loadSensorsValues, loadLatestEquipmentSensorsValues } from 'redux/modules/iot/values/sensor_values.js';
import { deleteSensorAlarm } from 'redux/modules/iot/sensorAlarms';
import { showModal } from 'redux/modules/modal/modal';
import { getTableData, getSensorOptions } from './utils';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { getAggregation } from '../../SensorValues/SensorValuesUtils';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { getSensorAlarmsBySensorId } from 'redux/modules/iot/sensorAlarms';

const Container = styled.div`
  margin: 0 auto;
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
`;
Container.displayName = 'Container';

const NoData = styled.div`
  text-align: center;
  margin-top: 2em;
  line-height: 6em;
`;
NoData.displayName = 'NoData';

export class PerformanceData extends PureComponent {
  static propTypes = {
    latestValuesBySensorId: PropTypes.object.isRequired,
    sensorsByEquipmentNumber: PropTypes.object.isRequired,
    valuesBySensorId: PropTypes.object.isRequired,
    loadingSensorValues: PropTypes.bool.isRequired,
    loadSensorsValues: PropTypes.func.isRequired,
    loadLatestEquipmentSensorsValues: PropTypes.func.isRequired,
    sensorAlarmsById: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    equipment: PropTypes.object,
    theme: PropTypes.object.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    buildingMeta: PropTypes.object,
    deleteSensorAlarm: PropTypes.func,
    showModal: PropTypes.func,
  };

  static getOpenModalSensorId = location => {
    const openModalSensorId = parseInt(queryString.parse(location.search)?.openModalSensorId, 10);
    if (Number.isFinite(openModalSensorId)) {
      return openModalSensorId;
    }
    return undefined;
  };

  state = {
    loadingData: true,
    showAlarmModal: false,
    sensor: null,
    sensorId: null,
    sensorIds: null,
    parameterModel: { startDatetime: null, endDatetime: null },
  };

  componentDidMount() {
    this.loadIoTData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { parameterModel } = this.state;

    if (!prevProps.equipment && this.props.equipment) {
      this.loadIoTData();
    }

    const sensorId = PerformanceData.getOpenModalSensorId(this.props.location);
    const prevSensorId = PerformanceData.getOpenModalSensorId(prevProps.location);
    if (!isEqual(prevState.parameterModel, parameterModel) || sensorId !== prevSensorId) {
      this.loadValues();
    }
  }

  loadIoTData = async () => {
    const { loadLatestEquipmentSensorsValues, equipment } = this.props;

    if (equipment) {
      await loadLatestEquipmentSensorsValues(equipment);
      this.setState({ loadingData: false });
    }
  };

  loadValues = () => {
    const {
      parameterModel: { startDatetime, endDatetime },
    } = this.state;
    const { loadSensorsValues, sensorsByEquipmentNumber, equipment, location } = this.props;
    const sensorId = PerformanceData.getOpenModalSensorId(location);

    if (sensorId && equipment && startDatetime && endDatetime) {
      const sensor = find(sensorsByEquipmentNumber[equipment.equipmentNumber], { id: sensorId });
      const sensorType = sensor && sensor.sensorType;
      const aggregation = sensorType ? getAggregation(sensorType, this.state.parameterModel, sensor) : 'raw';
      loadSensorsValues([sensorId], startDatetime, endDatetime, aggregation);
    }
  };

  handleParameterChange = (property, value) => {
    this.setState(oldState => ({ parameterModel: { ...oldState.parameterModel, [property]: value } }));
  };

  openSensorModal = sensorId => {
    const { location, history } = this.props;
    if (sensorId) {
      const queryParamsString = queryString.stringify({
        ...queryString.parse(location.search),
        openModalSensorId: sensorId,
      });
      history.push(`${location.pathname}?${queryParamsString}`);
    }
  };

  closeSensorModal = () => {
    const { history, location } = this.props;
    const queryParamsWithoutModal = omit(queryString.parse(location.search), ['openModalSensorId']);
    const queryParamsString = queryString.stringify(queryParamsWithoutModal);
    history.push(`${location.pathname}?${queryParamsString}`);
  };

  openNotification = (message, type) => {
    this.setState({
      notificationVisible: true,
      notificationMessage: message,
      notificationType: type,
    });
    setTimeout(() => this.closeNotification(), 2000);
  };

  closeNotification = () => {
    this.setState({
      notificationVisible: false,
    });
  };

  toggleAlarmModal = () => this.setState(oldState => ({ showAlarmModal: !oldState.showAlarmModal }));

  openAlarmModal = sensor => this.setState({ sensor, showAlarmModal: true });

  deleteSensorAlarm = sensor => {
    const { t, deleteSensorAlarm, showModal } = this.props;

    showModal(
      MODALTYPE.CONFIRMATION_DELETE_SENSOR_ALARM,
      null,
      () =>
        deleteSensorAlarm(sensor.id)
          .then(() => this.openNotification(t('Alarm removed successfully', 'success')))
          .catch(() => this.openNotification(t('Removing alarm failed!', 'error'))),
      t('Alarm for measuring point {0} will be removed', sensor.name)
    );
  };

  handleAlarmSuccess = message => {
    this.openNotification(message, 'success');
    this.toggleAlarmModal();
  };

  handleAlarmError = message => this.openNotification(message, 'error');

  render() {
    const {
      loadingData,
      parameterModel,
      sensor,
      showAlarmModal,
      notificationType,
      notificationVisible,
      notificationMessage,
    } = this.state;

    const {
      t,
      equipment,
      latestValuesBySensorId,
      sensorsByEquipmentNumber,
      loadingSensorValues,
      valuesBySensorId,
      theme,
      sensorAlarmsById,
      buildingMeta,
      location,
    } = this.props;

    const equipmentSensors = equipment && sensorsByEquipmentNumber[equipment.equipmentNumber];

    const overallSensor = find(
      equipmentSensors,
      sensor => sensor.name && sensor.name.toLowerCase().indexOf('opi overall') !== -1
    );

    const overallPerformanceValue =
      overallSensor && latestValuesBySensorId[overallSensor.id] && latestValuesBySensorId[overallSensor.id].value;

    const performanceSensors = filter(
      equipmentSensors,
      sensor => sensor.name && includes(sensor.name.toLowerCase(), 'performance')
    );

    const otherSensors = filter(
      equipmentSensors,
      sensor => !includes(performanceSensors, sensor) && sensor !== overallSensor
    );

    const alarmConfig = {
      sensorAlarmsById,
      onClick: this.openAlarmModal,
      onDelete: this.deleteSensorAlarm,
    };

    const openModalSensorId = PerformanceData.getOpenModalSensorId(location);
    return (
      <Container data-test-id="PerformanceData">
        <SectionHeader title={t('Conditions')} t={t} ctxHelp={`${CTXHELP_PREFIX} Equipment Conditions`} />
        <Section>
          <OpiOverall
            t={t}
            overallSensor={overallSensor}
            overallPerformanceValue={overallPerformanceValue}
            onClick={this.openSensorModal}
            loading={loadingData}
          />
          {(loadingData || (performanceSensors && performanceSensors.length > 0)) && (
            <SensorTable
              isPerformance
              t={t}
              loading={loadingData}
              data={getTableData(
                performanceSensors,
                latestValuesBySensorId,
                t,
                this.openSensorModal,
                theme,
                alarmConfig,
                true
              )}
            />
          )}
          {!loadingData && (!equipmentSensors || equipmentSensors.length === 0) && (
            <NoData>{t('No Sensors Found')}</NoData>
          )}
        </Section>

        {(loadingData || (otherSensors && otherSensors.length > 0)) && (
          <Section>
            <SensorTable
              t={t}
              loading={loadingData}
              data={getTableData(otherSensors, latestValuesBySensorId, t, this.openSensorModal, theme, alarmConfig)}
            />
          </Section>
        )}

        {openModalSensorId && (
          <PerformanceModal
            t={t}
            sensorId={openModalSensorId}
            equipmentSensors={equipmentSensors}
            latestValuesBySensorId={latestValuesBySensorId}
            valuesBySensorId={valuesBySensorId}
            loading={loadingSensorValues}
            toggleModal={this.closeSensorModal}
            onSensorChange={this.openSensorModal}
            sensorOptions={getSensorOptions(equipmentSensors, t)}
            onParameterChange={this.handleParameterChange}
            parameterModel={parameterModel}
            sensorAlarm={sensorAlarmsById[openModalSensorId]}
            buildingMeta={buildingMeta}
          />
        )}
        {showAlarmModal && (
          <SensorAlarm
            t={t}
            onClose={this.toggleAlarmModal}
            onSuccess={this.handleAlarmSuccess}
            onError={this.handleAlarmError}
            sensor={sensor}
          />
        )}
        <SnackBar variant={notificationType} visible={notificationVisible}>
          {notificationMessage}
        </SnackBar>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  latestValuesBySensorId: state.values.sensors.latestValuesBySensorId,
  sensorsByEquipmentNumber: state.values.sensors.sensorsByEquipmentNumber,
  valuesBySensorId: state.values.sensors.valuesBySensorId,
  loadingSensorValues: state.values.sensors.loadingSensorValues,
  sensorAlarmsById: getSensorAlarmsBySensorId(state),
  buildingMeta: state.buildingMeta.meta[props.functionalLocationId],
});

const mapDispatchToProps = dispatch => ({
  loadSensorsValues: (sensorIds, startTime, endTime, aggregation) =>
    dispatch(loadSensorsValues(sensorIds, startTime, endTime, aggregation)),

  loadLatestEquipmentSensorsValues: equipment =>
    dispatch(loadLatestEquipmentSensorsValues(equipment.functionalLocation, equipment.equipmentNumber)),

  deleteSensorAlarm: id => dispatch(deleteSensorAlarm(id)),

  showModal: (type, preConditions, onSubmitAction, passedProps) =>
    dispatch(showModal(type, preConditions, onSubmitAction, passedProps)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(withRouter(translations(withTheme(PerformanceData))));
