import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputSearch } from 'components/index';
import translations from 'decorators/Translations/translations';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.spacing.md} 0;
  flex-direction: column;
  align-items: flex-start;

  ${props => props.theme.media.portrait`
        flex-direction: row;
        align-items: center;
    `}
`;

const Heading = styled.h4`
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.md};
`;

Heading.displayName = 'Heading';

const Search = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: ${props => props.theme.spacing.sm};

  ${props => props.theme.media.portrait`
        max-width: 400px;
        margin-top: 0;
    `}
`;

const SearchResultsHeader = ({ t, count, onChange, filter }) => {
  return (
    <Header>
      <Heading>{`${count} ${t('Results Found')}`}</Heading>
      <Search>
        <InputSearch
          initialValue={filter}
          onChange={onChange}
          onClear={() => onChange('')}
          placeholder={t('Filter name, id or address')}
        />
      </Search>
    </Header>
  );
};

SearchResultsHeader.propTypes = {
  t: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
};

export default translations(SearchResultsHeader);
