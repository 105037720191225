import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';

const UPLOAD_FILE = 'ServiFlex/Files/UPLOAD_FILE';
const UPLOAD_FILE_SUCCESS = 'ServiFlex/Files/UPLOAD_FILE_SUCCESS';
const UPLOAD_FILE_FAIL = 'ServiFlex/Files/UPLOAD_FILE_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  uploading: false,
  uploaded: false,
};
export const uploadFile = data => async dispatch => {
  dispatch({ type: UPLOAD_FILE });
  try {
    const result = await dispatch(MasterData.uploadFile(data));

    return dispatch({
      type: UPLOAD_FILE_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: UPLOAD_FILE_FAIL,
      error,
    });
  }
};

export const uploadAndCreateImage = data => async dispatch => {
  dispatch({ type: UPLOAD_FILE });
  try {
    const result = await dispatch(MasterData.uploadAndCreateImage(data));

    return dispatch({
      type: UPLOAD_FILE_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: UPLOAD_FILE_FAIL,
      error,
    });
  }
};

export default createReducerFromMapping(
  {
    [UPLOAD_FILE]: state => ({
      ...state,
      uploading: true,
    }),
    [UPLOAD_FILE_SUCCESS]: state => ({
      ...state,
      uploading: false,
    }),
    [UPLOAD_FILE_FAIL]: (state, action) => ({
      ...state,
      uploading: false,
      error: action.error,
    }),
  },
  initialState
);
