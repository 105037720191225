import { createReducerFromMapping } from 'redux/utils/index.js';

const initialState = {
  visible: false,
};

export const SHOW_NOTIFICATION = 'CUSTOMER_PLATFORM/Notification/SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'CUSTOMER_PLATFORM/Notification/HIDE_NOTIFICATION';

// Shows global notification
export const showGlobalNotification = (message, notificationType) => ({
  type: SHOW_NOTIFICATION,
  notificationType,
  message,
});

// Shows global error notification
export const hideGlobalNotification = () => ({
  type: HIDE_NOTIFICATION,
});

export default createReducerFromMapping(
  {
    [SHOW_NOTIFICATION]: (state, action) => ({
      ...state,
      visible: true,
      type: action.notificationType,
      message: action.message,
    }),
    [HIDE_NOTIFICATION]: (state, action) => ({
      ...state,
      visible: false,
    }),
  },
  initialState
);
