import moment from 'moment';

export const formatTimeframe = (category, timeframe) => {
  switch (category) {
    case 'month':
      return timeframe.startTime.local().format('MMMM YYYY');
    case 'quarter':
      return `Q${timeframe.startTime.local().quarter()}`;
    case 'year':
      return timeframe.startTime.local().format('YYYY');
    default:
      throw new Error('Unsupported timeframe category');
  }
};

export const getDataSlice = (data, timeframe) =>
  data.filter(entry => moment(entry.timestamp).isBetween(timeframe.startTime, timeframe.endTime, null, '[]'));
