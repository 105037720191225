import React from 'react';
import styled from 'styled-components';
import PageTemplate from './PageTemplate';

const Title = styled.h1`
  color: ${props => props.theme.colors.rockBlue};
  font-size: ${props => props.theme.font.size.xl};
  text-align: center;
`;
Title.displayName = 'Title';

const Note = styled.p`
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.xs};
  text-align: center;
  line-height: ${props => props.theme.font.lineHeight.lg};
  padding: ${props => props.theme.spacing.lg};
`;

const Button = styled.a`
  cursor: pointer;
  display: inline-block;
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxxs};
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.cerulean};
  border-radius: 2em;
  padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.xxl};
  margin-top: ${props => props.theme.spacing.sm};
`;

const SomethingWentWrong = () => {
  return (
    <PageTemplate title="Something went wrong">
      <Title>Something went wrong</Title>
      <Note>
        Unfortunately we are having an issue. Please try again later. If the problem persists, please contact local
        support for further assistance.
      </Note>
      <Button href="/Login">Go to login</Button>
    </PageTemplate>
  );
};

export default SomethingWentWrong;
