import moment from 'moment';
import _ from 'lodash';
import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT, MasterData } from '@caverion/redux/api/actions';
import { getWhereFromPermissions } from 'redux/utils/partnerFilter';
import {
  isEnergySensor,
  isElectricitySensor,
  isDistrictHeatingSensor,
  isWaterConsumptionSensor,
  isEnergyRatingSensor,
} from 'utils/Data/values.js';

export const initialState = {
  energyValues: {},
  energyValuesByPartner: {},
  energyValuesBySensor: {},
  energySensors: {},
  loadingEnergyValues: false,
};

export const LOAD_FL_ENERGY_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_FL_ENERGY_VALUES';
export const LOAD_FL_ENERGY_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_FL_ENERGY_VALUES_SUCCESS';
export const LOAD_FL_ENERGY_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_FL_ENERGY_VALUES_FAIL';

export function loadFunctionalLocationsEnergyValues(partnerNumber, functionalLocations, startTime, endTime) {
  const start =
    startTime ||
    moment
      .utc()
      .subtract(2, 'years')
      .startOf('year');
  const end = endTime || moment.utc().endOf('day');

  return async (dispatch, getState) => {
    const { profile } = getState().profile;

    // take partnerNumbers as a priority, if not found take functionalLocations
    let locationFilter;
    if (partnerNumber && partnerNumber.length) {
      locationFilter = getWhereFromPermissions(profile, partnerNumber, {
        buildFunctionalLocationFilter: functionalLocations => ({
          functionalLocation: {
            inq: functionalLocations,
          },
        }),
        buildPartnerNumberFilter: partnerNumber => ({
          partnerNumber,
        }),
      });
    } else if (functionalLocations && functionalLocations.length > 0) {
      locationFilter = {
        functionalLocation: {
          inq: functionalLocations.map(fl => fl.functionalLocation),
        },
      };
    }

    dispatch({ type: LOAD_FL_ENERGY_VALUES });
    try {
      if (!locationFilter) {
        return dispatch({
          type: LOAD_FL_ENERGY_VALUES_FAIL,
        });
      }
      let sensorValues = [];
      // Fetch sensor data types
      const sensorDataTypes = await dispatch(MasterData.sensorTypes());
      // Get energy sensor types from fetched data
      const energySensorTypes = _.filter(sensorDataTypes, sensorType => {
        return isEnergySensor(sensorType.name);
      });
      if (!energySensorTypes || energySensorTypes.length === 0) {
        return dispatch({
          type: LOAD_FL_ENERGY_VALUES_FAIL,
        });
      }
      // Fetch energy sensors from targeted FLs sensorHierarchy
      // Energy sensors should always be in group_energy type of group in hierarchy
      const sensorsFilter = {
        where: {
          type: 'building',
          ...locationFilter,
        },
        timestamp: moment
          .utc()
          .startOf('hour')
          .toISOString(),
        include: {
          relation: 'children',
          scope: {
            where: {
              type: 'group_energy',
            },
            include: {
              relation: 'sensors',
              scope: {
                where: {
                  sensorTypeId: {
                    inq: energySensorTypes.map(sensor => sensor.id),
                  },
                },
              },
            },
          },
        },
      };
      const buildings = await dispatch(MasterData.sensorHierarchies(sensorsFilter));
      const energySensorToBuildingMap = {};
      let energySensors = [];
      // Fetch values for those sensors
      if (buildings && buildings.length > 0) {
        buildings.forEach(building => {
          if (building && building.children && building.children.length > 0) {
            building.children.forEach(group => {
              if (group && group.sensors && group.sensors.length > 0) {
                const buildingEnergySensors = _.filter(group.sensors, sensor => sensor.sensorTypeId !== null);
                energySensors = energySensors.concat(buildingEnergySensors);

                // Energy sensor can be mapped to a different FL, so we'll make a mapping
                // from sensors to building to be able to tie values to building level FL
                buildingEnergySensors.forEach(sensor => {
                  if (sensor && sensor.id) {
                    energySensorToBuildingMap[sensor.id] = building.functionalLocation;
                  }
                });
              }
            });
          }
        });
        if (energySensors.length > 0) {
          const valuesFilter = {
            where: {},
            order: 'timestamp ASC',
            fields: ['sensorId', 'aggregation', 'timestamp', 'value'],
          };

          /**
           * Get aggregations based on the sensor's granularity value
           * Types:
           * - energy rating sensors
           * - all energy consumption sensors
           * - energy consumption sensors that have only monthly data
           */
          const sensorsByType = {
            energyRating: [],
            energyConsumption: [],
            energyConsumptionWithMonthlyData: [],
          };
          const energyRatingSensorTypes = _.filter(sensorDataTypes, sensorType => {
            return isEnergyRatingSensor(sensorType.name);
          });
          energySensors.forEach(sensor => {
            if (_.find(energyRatingSensorTypes, { id: sensor.sensorTypeId })) {
              // Energy rating is handled differently, so we gather them here
              sensorsByType.energyRating.push(sensor.id);
            } else {
              // Get all monthly granularity consumption sensors
              if (sensor.granularity === 'month') {
                sensorsByType.energyConsumptionWithMonthlyData.push(sensor.id);
              }
              // Add all consumption sensors to energy consumption type
              sensorsByType.energyConsumption.push(sensor.id);
            }
          });
          valuesFilter.where.or = [];
          if (!_.isEmpty(sensorsByType.energyRating)) {
            valuesFilter.where.or.push({
              sensorId: { inq: sensorsByType.energyRating },
              timestamp: {
                between: [start.toISOString(), end.toISOString()],
              },
              aggregation: 'energyRating',
              value: { neq: null },
            });
          }

          const startOfPreviousMonth = moment
            .utc()
            .subtract(1, 'months')
            .startOf('month');
          const endOfMonthBeforePreviousMonth = moment
            .utc()
            .subtract(2, 'months')
            .endOf('month');
          const startOfPreviousMonthYearAgo = moment
            .utc()
            .subtract(1, 'years')
            .subtract(1, 'months')
            .startOf('month');
          const endOfCurrentMonthYearAgo = moment
            .utc()
            .subtract(1, 'years')
            .endOf('month');
          const startofNextMonthYearAgo = moment
            .utc()
            .subtract(1, 'years')
            .add(1, 'months')
            .startOf('month');
          const endOfMonthBeforePreviousMonthYearAgo = moment
            .utc()
            .subtract(1, 'years')
            .subtract(2, 'months')
            .endOf('month');

          /**
           * If there are no sensors that have only monthly values, we can use daily values
           * to calculate for example last 365 days and compare values from month ago
           */
          if (
            !_.isEmpty(sensorsByType.energyConsumption) &&
            _.isEmpty(sensorsByType.energyConsumptionWithMonthlyData) &&
            startOfPreviousMonthYearAgo.isBefore(end) &&
            endOfMonthBeforePreviousMonthYearAgo.isAfter(start)
          ) {
            /**
             * Get aggregations as:
             * Daily:
             * - current month
             * - month before current month
             * - current month one year ago
             * - month before current month one year ago
             * Monthly:
             * - all the other months
             * This allows us to sum values from last 365 days and 30 days ago 365 days
             * in common case where start and end times are in the middle of the month.
             */

            // Get daily values
            valuesFilter.where.or.push({
              sensorId: { inq: sensorsByType.energyConsumption },
              timestamp: {
                between: [startOfPreviousMonth.toISOString(), end.toISOString()],
              },
              aggregation: 'dailySum',
              value: { neq: null },
            });
            valuesFilter.where.or.push({
              sensorId: { inq: sensorsByType.energyConsumption },
              timestamp: {
                between: [startOfPreviousMonthYearAgo.toISOString(), endOfCurrentMonthYearAgo.toISOString()],
              },
              aggregation: 'dailySum',
              value: { neq: null },
            });
            // Get rest of the values as monthlySum aggregation
            valuesFilter.where.or.push({
              sensorId: { inq: sensorsByType.energyConsumption },
              timestamp: {
                between: [start.toISOString(), endOfMonthBeforePreviousMonthYearAgo.toISOString()],
              },
              aggregation: 'monthlySum',
              value: { neq: null },
            });
            valuesFilter.where.or.push({
              sensorId: { inq: sensorsByType.energyConsumption },
              timestamp: {
                between: [startofNextMonthYearAgo.toISOString(), endOfMonthBeforePreviousMonth.toISOString()],
              },
              aggregation: 'monthlySum',
              value: { neq: null },
            });
          } else {
            // If there is at least one monthly granularity sensor,
            // or if timeframe is non-regular, we use monthly values
            valuesFilter.where.or.push({
              sensorId: { inq: sensorsByType.energyConsumption },
              timestamp: {
                between: [start.toISOString(), end.toISOString()],
              },
              aggregation: 'monthlySum',
              value: { neq: null },
            });
          }
          if (valuesFilter.where.or && valuesFilter.where.or.length > 0) {
            sensorValues = await dispatch(IoT.findWithPost(valuesFilter));
          }
        }
      }

      return dispatch({
        type: LOAD_FL_ENERGY_VALUES_SUCCESS,
        result: sensorValues,
        energySensors,
        energySensorToBuildingMap,
        partnerNumber,
        sensorDataTypes,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FL_ENERGY_VALUES_FAIL,
        error,
      });
    }
  };
}

export default createReducerFromMapping(
  {
    [LOAD_FL_ENERGY_VALUES]: (state, action) => ({
      ...state,
      loadingEnergyValues: true,
    }),
    [LOAD_FL_ENERGY_VALUES_SUCCESS]: (state, action) => {
      if (!action.result || action.result.length === 0) {
        return {
          ...state,
          loadingEnergyValues: false,
        };
      }
      const newValues = {};
      const partnerValues = {};
      const newValuesBySensor = {};
      const newEnergySensors = {};

      const getSensorTypeFromSensor = (sensors, sensorTypes, sensorId) => {
        if (sensors && sensors.length > 0 && sensorTypes && sensorTypes.length > 0) {
          const sensor = _.find(sensors, { id: sensorId });
          if (sensor) {
            const sensorType = _.find(sensorTypes, { id: sensor.sensorTypeId });
            if (sensorType) {
              return sensorType.name;
            }
          }
        }
        return null;
      };
      const getValueObject = (data, sensorType, fl) => ({
        aggregation: data.aggregation,
        timestamp: data.timestamp,
        functionalLocation: fl,
        sensorId: data.sensorId,
        type: sensorType,
        value: data.value,
      });
      action.result.forEach(data => {
        const dataSensorId = +data.sensorId;
        const dataSensorType = getSensorTypeFromSensor(action.energySensors, action.sensorDataTypes, dataSensorId);
        let fl;
        // If energy sensor is found from mapping (it should always be), get building FL id from there
        if (action.energySensorToBuildingMap && action.energySensorToBuildingMap[data.sensorId]) {
          fl = action.energySensorToBuildingMap[data.sensorId];
        } else {
          fl = data.functionalLocation;
        }
        const valueObject = getValueObject(data, dataSensorType, fl);

        if (fl) {
          newValues[fl] = newValues[fl] || {};
          // All sensors should be energy sensors but we might want to change this later
          if (isEnergySensor(dataSensorType)) {
            newValues[fl].allTypes = newValues[fl].allTypes || [];
            newValues[fl].allTypes.push(valueObject);
            partnerValues.allTypes = partnerValues.allTypes || [];
            partnerValues.allTypes.push(valueObject);
          }
          if (isElectricitySensor(dataSensorType)) {
            newValues[fl].electricity = newValues[fl].electricity || [];
            newValues[fl].electricity.push(valueObject);
            partnerValues.electricity = partnerValues.electricity || [];
            partnerValues.electricity.push(valueObject);
          }
          if (isDistrictHeatingSensor(dataSensorType)) {
            newValues[fl].districtHeating = newValues[fl].districtHeating || [];
            newValues[fl].districtHeating.push(valueObject);
            partnerValues.districtHeating = partnerValues.districtHeating || [];
            partnerValues.districtHeating.push(valueObject);
          }
          if (isWaterConsumptionSensor(dataSensorType)) {
            newValues[fl].waterConsumption = newValues[fl].waterConsumption || [];
            newValues[fl].waterConsumption.push(valueObject);
            partnerValues.waterConsumption = partnerValues.waterConsumption || [];
            partnerValues.waterConsumption.push(valueObject);
          }
          if (isEnergyRatingSensor(dataSensorType)) {
            newValues[fl].energyRating = newValues[fl].energyRating || [];
            newValues[fl].energyRating.push(valueObject);
            partnerValues.energyRating = partnerValues.energyRating || [];
            partnerValues.energyRating.push(valueObject);
          }
        }
        newValuesBySensor[data.sensorId] = newValuesBySensor[data.sensorId] || [];
        newValuesBySensor[data.sensorId].push(valueObject);
      });
      let energyValuesByPartner = {};
      if (action.partnerNumber) {
        energyValuesByPartner = _.cloneDeep(state.energyValuesByPartner);
        energyValuesByPartner[action.partnerNumber] = partnerValues;
      }
      if (action.energySensors && action.energySensors.length > 0) {
        action.energySensors.forEach(sensor => {
          let fl;
          if (action.energySensorToBuildingMap && action.energySensorToBuildingMap[sensor.id]) {
            fl = action.energySensorToBuildingMap[sensor.id];
          } else {
            fl = sensor.functionalLocation;
          }
          newEnergySensors[fl] = newEnergySensors[fl] || [];
          newEnergySensors[fl].push(sensor);
        });
      }

      return {
        ...state,
        energyValues: {
          ...state.energyValues,
          ...newValues,
        },
        energyValuesBySensor: {
          ...state.energyValuesBySensor,
          ...newValuesBySensor,
        },
        energySensors: {
          ...state.energySensors,
          ...newEnergySensors,
        },
        energyValuesByPartner,
        loadingEnergyValues: false,
      };
    },
    [LOAD_FL_ENERGY_VALUES_FAIL]: (state, action) => ({
      ...state,
      loadingEnergyValues: false,
    }),
  },
  initialState
);
