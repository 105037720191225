import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.row &&
    `
        flex-direction: row;
        flex-wrap: wrap;
        & > div {
            margin-right: ${props.theme.spacing.lg};
        }
    `};
`;

StyledGroup.displayName = 'StyledGroup';

const RadioButtonGroup = props => {
  const children = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        checked: props.value === child.props.value,
        name: props.name,
        onChange: props.onChange,
        type: props.type,
      });
    }
  });

  return (
    <StyledGroup icons={props.type === 'icon'} row={props.row}>
      {children}
    </StyledGroup>
  );
};

RadioButtonGroup.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['default', 'icon']),
  row: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  type: 'default',
  row: false,
};

export default RadioButtonGroup;
