import React, { Fragment } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { isEmpty, times, sumBy } from 'lodash';

import { ChartLoader } from './StatusInfoChart';
import { CHART_HEIGHT, CHART_HEIGHT_MOBILE } from './StatusInfo';
import Infotip, { InfotipContent } from 'components/Infotip/Infotip';

const StyledInfotip = styled(Infotip)`
  && {
    height: 100%;
    cursor: pointer;
    padding: 0 ${props => props.theme.spacing.xs};
  }

  && ${InfotipContent} {
    top: 0;
    background-color: ${props => props.theme.colors.midnight};
    color: ${props => props.theme.colors.white};
    padding: ${props => props.theme.spacing.xs};
    font-size: ${props => props.theme.font.size.xxs};
    display: none;

    ${props => props.theme.media.landscape`
            display: ${props => !props.hidden && 'block'};
        `}

    &:before {
      border-top-color: ${props => props.theme.colors.midnight};
    }
  }
`;

const BarContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  margin: ${props => props.theme.spacing.md} auto;

  ${props => props.theme.media.landscape`
        display: flex;
    `}
`;
BarContainer.displayName = 'BarContainer';

const MobileContainer = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing.xs};

  ${props => props.theme.media.landscape`
        display: none;
    `}
`;
MobileContainer.displayName = 'MobileContainer';

const Bar = styled.div`
  width: 14px;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const BarBackground = styled(Bar)`
  background-color: ${props => props.theme.colors.mystic};
  height: 100%;
  display: flex;
`;
BarBackground.displayName = 'BarBackground';

const BarValue = styled(Bar)`
  background-color: ${props => props.color};
  height: ${props => props.value}%;
  transition: ${props => props.theme.motion.quick} box-shadow ${props => props.theme.motion.easing},
    ${props => props.theme.motion.quick} transform linear;
  border: ${props => props.border && `1px dashed ${props.theme.colors.rockBlue}`};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  align-self: flex-end;
`;
BarValue.displayName = 'BarValue';

export const StatusInfoBars = ({ bars, theme, loading, t }) => {
  if (loading) {
    return <ChartLoader size={CHART_HEIGHT} mobileSize={CHART_HEIGHT_MOBILE} />;
  }

  let data = bars;
  if (isEmpty(bars)) {
    data = times(3, () => ({ title: '' }));
  }

  const mobileCount = sumBy(bars, 'count');
  const mobileReference = sumBy(bars, 'reference');
  const mobileValue = bars && mobileReference ? (mobileCount / mobileReference) * 100 : mobileCount ? 100 : 0;

  return (
    <Fragment>
      <MobileContainer>
        <BarBackground>
          <BarValue value={mobileValue} color={theme.colors.midnight} />
        </BarBackground>
      </MobileContainer>
      <BarContainer>
        {data.map((bar, index) => {
          const value = bar.reference ? (bar.count / bar.reference) * 100 : bar.count ? 100 : 0;
          const text = bar.title ? `${t(bar.title)} ${bar.count}/${bar.reference}` : '';
          return (
            <StyledInfotip text={text} hidden={!text} key={`${bar.title}-${index}`}>
              <BarBackground>
                <BarValue value={value} color={theme.colors.midnight} />
              </BarBackground>
            </StyledInfotip>
          );
        })}
      </BarContainer>
    </Fragment>
  );
};

StatusInfoBars.defaultProps = {
  bars: {},
};

StatusInfoBars.propTypes = {
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.number,
      title: PropTypes.string,
      count: PropTypes.number,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTheme(StatusInfoBars);
