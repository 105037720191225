import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LoggedInUserContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
`;

const StyledLoggedInUser = styled.p`
  color: ${props => props.theme.colors.darkGray};
  padding: ${props => props.theme.spacing.xl} ${props => props.theme.spacing.xl};
  font-size: ${props => props.theme.font.size.xxs};
  text-transform: uppercase;
`;

StyledLoggedInUser.displayName = 'StyledLoggedInUser';

export const LoggedInUser = ({ username }) => (
  <LoggedInUserContainer>
    <StyledLoggedInUser>{username}</StyledLoggedInUser>
  </LoggedInUserContainer>
);

LoggedInUser.propTypes = {
  username: PropTypes.string.isRequired,
};
