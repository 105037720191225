import styled from 'styled-components';

const UnreadIndicator = styled.div`
  width: 17px;
  height: 17px;
  line-height: 17px;
  text-align: center;
  background: ${props => props.theme.colors.cerulean};
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.font.size.xxxs};
`;

UnreadIndicator.displayName = 'UnreadIndicator';

export default UnreadIndicator;
