import _ from 'lodash';
import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT } from '@caverion/redux/api/actions';

export const initialState = {
  conditions: {},
  loadingConditions: false,
};

export const LOAD_CONDITIONS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONDITIONS';
export const LOAD_CONDITIONS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONDITIONS_SUCCESS';
export const LOAD_CONDITIONS_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONDITIONS_FAIL';

export const loadBuildingConditions = (functionalLocation, sensors, startTime, endTime) => {
  const filter = {
    where: {
      aggregation: 'hourlyAverage', // TODO: aggregation needs to be dynamic from the sensor.analytics , if has hourlyAverage analytics, use that, otherwise pick all values.
      timestamp: { between: [startTime, endTime] },
    },
    order: 'timestamp ASC',
    fields: ['sensorId', 'aggregation', 'timestamp', 'value'],
  };

  return async dispatch => {
    dispatch({ type: LOAD_CONDITIONS });

    if (!sensors || sensors.length === 0) {
      return dispatch({
        type: LOAD_CONDITIONS_FAIL,
      });
    }

    const sensorIds = _.map(sensors, 'id');
    filter.where.sensorId = { inq: sensorIds };

    try {
      const result = await dispatch(IoT.findWithPost(filter));

      return dispatch({
        type: LOAD_CONDITIONS_SUCCESS,
        result,
        sensors,
        functionalLocation,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_CONDITIONS_FAIL,
        error,
      });
    }
  };
};

const mapConditionsData = (conditions, sensors) => {
  const mapData = (accu, value) => {
    const sensor = _.find(sensors, { id: parseInt(value.sensorId, 10) });
    if (sensor && sensor.sensorType) {
      accu.push({
        avg: Math.round(value.value),
        sensorName: sensor.sensorType.name,
        unit: value.unit,
        timestamp: value.timestamp,
        sensorId: value.sensorId,
      });
    }
    return accu;
  };
  return _.groupBy(_.orderBy(conditions.reduce(mapData, []), 'timestamp', 'asc'), row => row.sensorName);
};

export default createReducerFromMapping(
  {
    [LOAD_CONDITIONS]: (state, action) => ({
      ...state,
      loadingConditions: true,
    }),
    [LOAD_CONDITIONS_SUCCESS]: (state, action) => ({
      ...state,
      conditions: {
        ...state.conditions,
        [action.functionalLocation]: mapConditionsData(action.result, action.sensors),
      },
      loadingConditions: false,
    }),
    [LOAD_CONDITIONS_FAIL]: (state, action) => ({
      ...state,
      loadingConditions: false,
    }),
  },
  initialState
);
