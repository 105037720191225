import React from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash';
import GaugeChart from './../Charts/GaugeChart';
import { withTheme } from 'styled-components';
import { CHART_HEIGHT_MOBILE } from './StatusInfo';
import Loader from 'components/Loader/Loader.jsx';

const Chart = styled.div`
  position: relative;
  display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;

  ${props => props.theme.media.landscape`
        display: flex;
        margin: ${props => props.theme.spacing.md};
    `}
`;
Chart.displayName = 'Chart';

const ChartInner = styled.div`
  position: relative;
  z-index: 1;
`;
ChartInner.displayName = 'ChartInner';

const ChartPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.mobileSize}px;
  height: ${props => props.mobileSize}px;

  ${props => props.theme.media.landscape`
        width: ${props => props.size}px;
        height: ${props => props.size}px;
    `}
`;
ChartPlaceholder.displayName = 'ChartPlaceholder';

export const ChartLoader = ({ size, mobileSize, hideOnMobile }) => (
  <Chart hideOnMobile={hideOnMobile}>
    <ChartPlaceholder size={size} mobileSize={mobileSize}>
      <Loader color="GRAY" />
    </ChartPlaceholder>
  </Chart>
);

// Styled components has a bug with withTheme on functional components so we use normal component here
class StatusInfoChart extends React.Component {
  render() {
    const { value, min, max, unit, axisTitle, valueNote, theme, minWidth, showValue, addPadding, loading } = this.props;

    let chartSize = minWidth;
    chartSize -= 32;
    // Limit chart size to 300px
    chartSize = chartSize < 300 ? chartSize : 300;

    if (loading) {
      return <ChartLoader size={chartSize} mobileSize={CHART_HEIGHT_MOBILE} />;
    }

    if (isNil(value)) {
      return null;
    }

    return (
      <Chart>
        <ChartInner>
          <GaugeChart
            size={chartSize}
            value={value}
            min={min}
            max={max}
            unit={unit}
            valueNote={valueNote}
            valueColor={theme.colors.black}
            background={theme.colors.alabaster}
            backgroundPadding={addPadding ? 3 : 0}
            axisTitle={axisTitle}
            lineWidth={24}
            showValue={showValue}
            hidePlus
          />
        </ChartInner>
      </Chart>
    );
  }
}

export default withTheme(StatusInfoChart);
