import React from 'react';
import styled, { css } from 'styled-components';

import Column from 'components/Columns/Column';
import ContextualHelp from 'components/ContextualHelp/ContextualHelp';
import { Card } from './Card';
import { getDesktopAndLandscapeStyles } from './OPICards';

const StyledCard = styled(Card)`
  justify-content: space-evenly;

  ${props =>
    getDesktopAndLandscapeStyles(
      props,
      css`
        flex-direction: column;
        align-items: flex-start;
        padding: ${props => props.theme.spacing.md};
      `
    )}
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
  padding-bottom: ${props => props.theme.spacing.xxs};

  ${props =>
    getDesktopAndLandscapeStyles(
      props,
      css`
        padding-right: ${props => props.theme.spacing.md};
        padding-bottom: 0;
        text-align: left;
        height: ${props => props.theme.fontSize.text};
      `
    )}
`;

const ValueContainer = styled.span`
  color: ${props => props.highlight && props.theme.colors.cerulean};
  cursor: ${props => props.highlight && 'pointer'};
  order: -1;
  padding-bottom: ${props => props.theme.spacing.xxs};

  ${props =>
    getDesktopAndLandscapeStyles(
      props,
      css`
        order: 0;
        padding-bottom: 0;
      `
    )}
`;

const Value = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.font.size.sm};

  ${props => props.theme.media.desktop`
        font-size: ${props => props.theme.font.size.xs};
    `}
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: ${props => props.theme.font.size.xxs};
  font-family: ${props => props.theme.font.arial};

  ${props =>
    getDesktopAndLandscapeStyles(
      props,
      css`
        flex-direction: row;
      `
    )}

  ${props => props.theme.media.desktop`
        font-size: ${props => props.theme.font.size.xs};
    `}
`;

const IconWrapper = styled.span`
  padding: 0 ${props => props.theme.spacing.xs};
  display: none;

  ${props => props.theme.media.desktop`
        display: block;
    `}
`;

const Subtitle = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  font-family: ${props => props.theme.font.arial};
  letter-spacing: 0.3px;
  white-space: pre-line;
`;

const InfoCard = props => {
  const { infos, ctxHelp, t, columnWidth, naviExtended, subtitle } = props;
  const hasCtxHelp = ctxHelp && t(ctxHelp) !== ctxHelp;

  return (
    <Column columnWidth={columnWidth}>
      <StyledCard data-test-id="OPICard" noCursor naviClosed={!naviExtended}>
        {infos.map((info, index) => (
          <InfoRow key={info.title} naviClosed={!naviExtended}>
            <Title naviClosed={!naviExtended}>
              {info.title}
              {hasCtxHelp && index === 0 && (
                <IconWrapper onClick={e => e.stopPropagation()}>
                  <ContextualHelp t={t} title={info.title} text={ctxHelp} standalone position="right" />
                </IconWrapper>
              )}
            </Title>
            <ValueContainer highlight={!!info.onClick} onClick={info.onClick} naviClosed={!naviExtended}>
              <Value>{info.value}</Value>&nbsp;{info.unit}
            </ValueContainer>
          </InfoRow>
        ))}
        <Subtitle>{subtitle}</Subtitle>
      </StyledCard>
    </Column>
  );
};

export default InfoCard;
