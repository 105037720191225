import React from 'react';
import PropTypes from 'prop-types';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import WasteChart from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteChart/WasteChart';
import WasteTimeframeSelector from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTimeframeSelector/WasteTimeframeSelector';
import WasteTimeframeCategorySelector from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTimeframeCategorySelector/WasteTimeframeCategorySelector';
import WasteTotals from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTotals/WasteTotals';
import translations from 'decorators/Translations/translations';
import {
  calculateRecyclingBreakdownPerWasteType,
  calculateCarbonFootprintPerWasteType,
} from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';
import { tabs } from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteBreakdown';

const translateData = (t, calculatedData) => ({
  ...calculatedData,
  categories: calculatedData.categories.map(category => ({
    ...category,
    name: t(category.name),
  })),
  series: calculatedData.series.map(entry => ({
    ...entry,
    wasteType: t(entry.wasteType),
    unit: t(entry.unit),
  })),
  unit: t(calculatedData.unit),
  totals: {
    ...calculatedData.totals,
    title: t(calculatedData.totals.title),
    unit: t(calculatedData.totals.unit),
    perCategory: calculatedData.totals.perCategory.map(entry => ({
      ...entry,
      categoryName: t(entry.categoryName),
    })),
  },
});

export const BreakdownAllWasteTypes = ({
  t,
  profile,
  wasteSensors,
  tab,
  timeframeCategoryOptions,
  selectedTimeframeCategory,
  onTimeframeCategoryChange,
  selectedTimeframe,
  selectedTimeframeDescription,
  onTimeframeChange,
  dataSlice,
  selectSensor,
}) => {
  let calculatedData;
  switch (tab) {
    case tabs.WASTE_AMOUNTS:
      calculatedData = calculateRecyclingBreakdownPerWasteType(dataSlice, wasteSensors);
      break;
    case tabs.CARBON_FOORTPRINT:
      calculatedData = calculateCarbonFootprintPerWasteType(dataSlice, wasteSensors);
      break;
    default:
      throw new Error('Unsupported tab');
  }
  const transformedData = translateData(t, calculatedData);
  return (
    <Columns justifyContent="space-between">
      <Column columnWidth={{ landscape: 9, default: 12 }}>
        <WasteChart
          profile={profile}
          data={transformedData}
          onValueBarClick={event => selectSensor(event.point.sensorId)}
        />
        <WasteTimeframeSelector
          selectedTimeframeCategory={selectedTimeframeCategory}
          selectedTimeframe={selectedTimeframe}
          onTimeframeChange={onTimeframeChange}
          selectedTimeframeDescription={selectedTimeframeDescription}
        />
      </Column>
      <Column columnWidth={{ landscape: 3, default: 12 }}>
        <WasteTimeframeCategorySelector
          timeframeCategoryOptions={timeframeCategoryOptions}
          selectedTimeframeCategory={selectedTimeframeCategory}
          onTimeframeCategoryChange={onTimeframeCategoryChange}
        />
        <WasteTotals
          profile={profile}
          selectedTimeframeDescription={selectedTimeframeDescription}
          totals={transformedData.totals}
        />
      </Column>
    </Columns>
  );
};

BreakdownAllWasteTypes.propTypes = {
  t: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  tab: PropTypes.number.isRequired,
  timeframeCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedTimeframeCategory: PropTypes.string.isRequired,
  onTimeframeCategoryChange: PropTypes.func.isRequired,
  selectedTimeframe: PropTypes.object.isRequired,
  selectedTimeframeDescription: PropTypes.string.isRequired,
  onTimeframeChange: PropTypes.func.isRequired,
  dataSlice: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  selectSensor: PropTypes.func.isRequired,
};

export default translations(BreakdownAllWasteTypes);
