import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { conditionStatus } from '../ConditionUtils';

const Column = styled.div`
  display: inline-block;
  margin-right: ${props => props.theme.spacing.xxs};
  margin-left: ${props => (props.depth ? '3rem' : 0)};
`;
Column.displayName = 'Column';

const StatusItem = styled.div`
  display: inline-block;
  margin-right: ${props => props.theme.spacing.xxs};
  border-radius: 50%;
  background-color: ${props => props.color || props.theme.colors.transparent};
  width: ${props => props.theme.spacing.xs};
  height: ${props => props.theme.spacing.xs};
`;
StatusItem.displayName = 'StatusItem';

const items = (status, theme) => {
  switch (status.value) {
    case conditionStatus.positive.value:
      return (
        <Fragment>
          <StatusItem color={theme.colors.emerald} />
          <StatusItem color={theme.colors.emerald} />
          <StatusItem color={theme.colors.emerald} />
        </Fragment>
      );
    case conditionStatus.neutral.value:
      return (
        <Fragment>
          <StatusItem color={theme.colors.rockBlue} />
          <StatusItem color={theme.colors.rockBlue} />
        </Fragment>
      );
    case conditionStatus.negative.value:
      return <StatusItem color={theme.colors.radicalRed} />;
    default:
      return <StatusItem />;
  }
};

const StatusColumn = ({ data, theme, depth }) => {
  return <Column depth={depth}>{items(data, theme)}</Column>;
};
StatusColumn.displayName = 'StatusColumn';

StatusColumn.propTypes = {
  data: PropTypes.object.isRequired,
  depth: PropTypes.number,
  theme: PropTypes.object,
};

export default withTheme(StatusColumn);
