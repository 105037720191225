import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import InputRow from 'components/Form/InputRow';
import InputText from 'components/Form/InputText';
import InputLabel from 'components/Form/InputLabel';
import InputDate from 'components/Form/InputDate';
import Loader from 'components/Loader/Loader';
import { Info } from './Api';

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 312px;
`;

const LoaderText = styled.span`
  font-size: ${props => props.theme.font.size.sm};
  font-family: ${props => props.theme.font.family.arial};
  margin-bottom: ${props => props.theme.spacing.md};
`;

const ApiKeyModalContent = ({ t, createdKey, saving, handlePropertyChange, model }) => {
  if (createdKey) {
    return (
      <React.Fragment>
        <Info>{t('The API key is shown only once, and cannot be retrieved later.')}</Info>
        <InputRow fullRow>
          <InputText value={createdKey.token} readonly success />
        </InputRow>
      </React.Fragment>
    );
  }

  return saving ? (
    <LoaderContainer>
      <LoaderText>{t('Generating API key...')}</LoaderText>
      <Loader />
    </LoaderContainer>
  ) : (
    <React.Fragment>
      <Info>{t('CREATE_API_KEY_INFO_1')}</Info>
      <Info>{t('CREATE_API_KEY_INFO_2')}</Info>
      <InputRow fullRow required>
        <InputLabel text={t('Description')} />
        <InputText property="name" value={model.name} onChange={handlePropertyChange} inputProps={{ maxLength: 64 }} />
      </InputRow>
      <InputRow fullRow>
        <InputLabel text={t('Expires at')} />
        <InputDate
          property="expires"
          value={model.expires}
          onChange={handlePropertyChange}
          placement="top-start"
          minDate={new Date()}
          maxDate={moment()
            .add(3, 'year')
            .toDate()}
        />
      </InputRow>
    </React.Fragment>
  );
};

ApiKeyModalContent.propTypes = {
  t: PropTypes.func.isRequired,
  createdKey: PropTypes.object,
  saving: PropTypes.bool.isRequired,
  handlePropertyChange: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
};

export default ApiKeyModalContent;
