import styled from 'styled-components';

export const PermissionListItemFLCount = styled.span`
  display: none;
  vertical-align: top;
  color: ${props => props.theme.colors.rockBlue};
  margin-left: ${props => props.theme.spacing.xs};
  font-size: ${props => props.theme.font.size.xs};

  ${props => props.theme.media.portrait`
        display: inline;
    `}
`;
