import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import Dropzone from 'react-dropzone';
import { rgba } from 'polished';
import Button from 'components/Button/Button';

const CurrentImage = styled.div`
  position: relative;
  height: 18em;
  width: 100%;
  background-image: ${props => `url(data:${props.type};base64,${props.content})`};
  background-size: cover;
  background-position: center center;

  ${props => props.theme.media.landscape`
        height: 100%;
    `};
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const AddNewImage = styled.div`
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSize.xxs};
  padding: 2em;
  cursor: pointer;

  ${props => props.theme.media.landscape`
        border: 1px dashed ${props => rgba(props.theme.colors.white, 0.2)};
    `}
`;

const RemoveButton = styled(Button)`
  min-width: auto;
  max-width: 90%;
`;

const StyledDropzone = styled(Dropzone)`
  width: 100%;
  text-align: center;

  ${props => props.theme.media.landscape`
        width: 80%;
    `}
`;

export const ServiceRequestImageUpload = ({ t, image, addAttachment, removeAttachment }) => {
  const handleRemoveImage = e => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    removeAttachment();
  };

  const uploadImage = files => {
    const filename = files[0].name;
    const mimeType = files[0].type;
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target.result) {
        let fileString = event.target.result;
        if (fileString.indexOf(',') !== -1) {
          fileString = fileString.split(',')[1];
        }
        const data = {
          content: fileString,
          filename,
          type: mimeType,
        };
        addAttachment(data);
      }
    };
    reader.readAsDataURL(files[0]);
  };

  if (image) {
    return (
      <CurrentImage content={image.content} type={image.type}>
        <DeleteButtonContainer>
          <RemoveButton remove onClick={handleRemoveImage}>
            {t('Remove image')}
          </RemoveButton>
        </DeleteButtonContainer>
      </CurrentImage>
    );
  }

  return (
    <StyledDropzone onDrop={uploadImage} style={{}}>
      <AddNewImage>{t('Click to add image')}</AddNewImage>
    </StyledDropzone>
  );
};

ServiceRequestImageUpload.defaultProps = {
  image: null,
};

ServiceRequestImageUpload.propTypes = {
  t: PropTypes.func.isRequired,
  image: PropTypes.object,
  addAttachment: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
};

export default translations(ServiceRequestImageUpload);
