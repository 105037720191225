import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { values, isEmpty, includes } from 'lodash';
import styled, { withTheme } from 'styled-components';
import memoizeOne from 'memoize-one';

import DialogModal from 'components/Dialog/DialogModal';
import translations from 'decorators/Translations/translations';
import { OrderType } from 'constants/serviceCalendar';
import {
  loadServiceOrder,
  loadPlannedMaintenance,
  loadFunctionalLocations,
  loadServiceOrderOperations,
  loadPartnerMeta,
  loadDocumentsByServiceOrder,
  downloadDocument,
  loadEquipments,
} from 'redux/modules';
import { downloadServiceOrderFile } from 'redux/modules/document/file';
import ServiceOrderOperations from './ServiceOrderOperations/ServiceOrderOperations';
import ServiceOrderInfo from './ServiceOrderInfo/ServiceOrderInfo';
import Icon from 'components/Icon/Icon';
import SkeletonText from 'components/Skeletons/SkeletonText';
import ServiceOrderDocuments from './ServiceOrderDocuments/ServiceOrderDocuments';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import DialogBody from 'components/Dialog/DialogBody';
import ServiceOrderKeys from './ServiceOrderKeys/ServiceOrderKeys';

const getStatusColors = memoizeOne(theme => ({
  Open: theme.serviceOrder.openColor,
  Completed: theme.serviceOrder.completedColor,
  'In Progress': theme.serviceOrder.inProgressColor,
  'Partly Completed': theme.serviceOrder.partlyCompletedColor,
  Postponed: theme.serviceOrder.postponedColor,
  Planned: theme.serviceOrder.plannedColor,
}));

const StyledDialogBody = styled(DialogBody)`
  padding: ${props => props.theme.spacing.md};

  ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.xl};
    `}

  ${props => props.theme.media.landscape`
        padding: ${props => props.theme.spacing.xxl};
        width: 95vw;
    `}
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.lg};
  font-size: ${props => props.theme.font.size.xxs};
  word-break: break-all;

  ${props => props.theme.media.portrait`
        font-size: ${props => props.theme.font.size.xs};
        word-break: normal;
    `}
`;

const OrderIcon = styled.div`
  background: ${props => props.color};
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin-right: ${props => props.theme.spacing.lg};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.font.size.xxs};
`;

const Sections = styled.main`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;

const Section = styled.section`
  &:not(:first-child) {
    margin-top: ${props => props.theme.spacing.xl};
  }

  ${props => props.theme.media.landscape`
        width: calc(100% / 2 - ${props => props.theme.spacing.xl});
        &:not(:first-child) {
            margin-top: 0;
            margin-left: ${props => props.theme.spacing.xl};
        }
    `}
`;

const Heading = styled.h3``;

Heading.displayName = 'Heading';

const HeadingSkeleton = styled(SkeletonText)`
  max-width: 600px;
  margin: 0;
`;

HeadingSkeleton.displayName = 'HeadingSkeleton';

export const ServiceOrder = ({
  t,
  theme,

  hide,
  orderId,
  orderType,
  partnerNumber,
  functionalLocationId,
  profile,

  order,
  linkedOrder,
  isSapMaintenance,
  functionalLocation,
  operations,
  partnerMeta,
  documentCount,
  documents,
  equipment,
  loadingOrder,
  loadingOrderKey,
  loadingDocuments,

  loadServiceOrder,
  loadPlannedMaintenance,
  loadFunctionalLocations,
  loadServiceOrderOperations,
  loadPartnerMeta,
  loadDocumentsByServiceOrder,
  downloadDocument,
  downloadServiceOrderFile,
  loadEquipments,
}) => {
  const [error, setError] = React.useState();

  React.useEffect(
    () => {
      if (!order) {
        const load = orderType === OrderType.ORDER ? loadServiceOrder : loadPlannedMaintenance;
        load(orderId, partnerNumber, functionalLocationId, loadingOrderKey)
          .then(({ result }) => {
            if (!result || isEmpty(result)) {
              setError(true);
            }
          })
          .catch(() => setError(true));
      }

      if (!operations && !isSapMaintenance) {
        loadServiceOrderOperations(orderId, functionalLocationId);
      }

      if (profile.features.documents || profile.features.files) {
        loadDocumentsByServiceOrder(orderId, functionalLocationId);
      }

      loadEquipments(functionalLocationId);
    },
    [orderId, orderType]
  );

  React.useEffect(
    () => {
      if (!functionalLocation) {
        loadFunctionalLocations([functionalLocationId]);
      }
    },
    [functionalLocationId]
  );

  React.useEffect(
    () => {
      if (orderType === OrderType.ORDER && !partnerMeta) {
        loadPartnerMeta(partnerNumber);
      }
    },
    [partnerNumber]
  );

  React.useEffect(
    () => {
      if (!loadingOrder && order && !linkedOrder && functionalLocationId && isSapMaintenance) {
        loadServiceOrder(order.serviceOrderNumber, partnerNumber, functionalLocationId, loadingOrderKey)
          .then(({ result }) => {
            if (!result || isEmpty(result)) {
              setError(true);
            }
          })
          .catch(() => setError(true));

        loadServiceOrderOperations(order.serviceOrderNumber, functionalLocationId);

        if (profile.features.documents || profile.features.files) {
          loadDocumentsByServiceOrder(order.serviceOrderNumber, functionalLocationId);
        }
      }
    },
    [loadingOrder]
  );

  const statusColors = getStatusColors(theme);

  return (
    <DialogModal isActive onOverlayClick={hide} t={t} bodyComponent={StyledDialogBody}>
      {error ? (
        <ErrorPage type="serviceOrder" embed />
      ) : (
        <React.Fragment>
          <Header>
            <OrderIcon color={(order && statusColors[order.status]) || theme.colors.mystic}>
              <Icon name="planned-maintenance" />
            </OrderIcon>
            <Heading>{order ? order.title : <HeadingSkeleton />}</Heading>
          </Header>
          {order && (orderType === OrderType.ORDER || linkedOrder) && (
            <ServiceOrderKeys
              t={t}
              order={linkedOrder || order}
              operations={operations}
              loadingOrder={loadingOrder}
              documentCount={documentCount}
              partnerMeta={partnerMeta}
              partnerNumber={partnerNumber}
              isMaintenance={!!linkedOrder}
            />
          )}
          <Sections>
            <Section>
              <ServiceOrderInfo
                t={t}
                partnerNumber={partnerNumber}
                order={order}
                functionalLocationId={functionalLocationId}
                functionalLocation={functionalLocation}
                loadingOrder={loadingOrder}
                documentCount={documentCount}
                equipment={equipment}
                type={orderType}
              />
              {linkedOrder && (
                <ServiceOrderInfo
                  t={t}
                  partnerNumber={partnerNumber}
                  order={linkedOrder}
                  functionalLocationId={functionalLocationId}
                  functionalLocation={functionalLocation}
                  loadingOrder={loadingOrder}
                  documentCount={documentCount}
                  equipment={equipment}
                  type={OrderType.ORDER}
                  isLinkedOrder
                />
              )}
            </Section>
            <Section>
              <ServiceOrderOperations t={t} operations={operations} order={order} />
            </Section>
          </Sections>
          {(profile.features.documents || profile.features.files) && (
            <ServiceOrderDocuments
              t={t}
              functionalLocationId={functionalLocationId}
              loadingDocuments={loadingDocuments}
              documentCount={documentCount}
              documents={documents}
              downloadDocument={downloadDocument}
              downloadFile={downloadServiceOrderFile}
            />
          )}
        </React.Fragment>
      )}
    </DialogModal>
  );
};

ServiceOrder.propTypes = {
  hide: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  orderType: PropTypes.oneOf(values(OrderType)).isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  partnerNumber: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,

  order: PropTypes.object,
  linkedOrder: PropTypes.object,
  isSapMaintenance: PropTypes.bool,
  functionalLocation: PropTypes.object,
  operations: PropTypes.object,
  partnerMeta: PropTypes.object,
  documentCount: PropTypes.number.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object),
  equipment: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingOrder: PropTypes.bool.isRequired,
  loadingOrderKey: PropTypes.string.isRequired,
  loadingDocuments: PropTypes.bool.isRequired,

  loadServiceOrder: PropTypes.func.isRequired,
  loadPlannedMaintenance: PropTypes.func.isRequired,
  loadFunctionalLocations: PropTypes.func.isRequired,
  loadServiceOrderOperations: PropTypes.func.isRequired,
  loadPartnerMeta: PropTypes.func.isRequired,
  loadDocumentsByServiceOrder: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  loadEquipments: PropTypes.func.isRequired,

  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

const getLoadingKey = (...args) => args.join(':');

const EMPTY_ARRAY = [];

const getOrderEquipment = memoizeOne((order, equipment) => {
  if (!order || !order.equipmentNumber || !Array.isArray(equipment)) {
    return EMPTY_ARRAY;
  }

  return equipment.filter(equipment => includes(order.equipmentNumber, equipment.equipmentNumber));
});

const mergeDocuments = memoizeOne((orderDocuments, relatedDocuments) => [
  ...(Array.isArray(orderDocuments) ? orderDocuments : []),
  ...(Array.isArray(relatedDocuments) ? relatedDocuments : []),
]);

const mapStateToProps = (state, ownProps) => {
  const dataSource = state[ownProps.orderType === OrderType.ORDER ? 'serviceOrders' : 'plannedMaintenance'];
  const order = dataSource.index.byId[ownProps.orderId];
  const loadingOrderKey = getLoadingKey('order', ownProps.orderType, ownProps.orderId);
  const documents = state.document.filteredServiceOrders[ownProps.orderId];
  const loadingDocuments = !documents || !Array.isArray(documents);

  // Show data from both maintenance and related service order for SAP planned maintenances (i.e. Y02)
  const isSapMaintenance =
    ownProps.orderType === OrderType.PLANNED &&
    order &&
    order.maintenanceActivityType === 'Y02' &&
    order.serviceOrderNumber;
  const linkedOrder = isSapMaintenance ? state.serviceOrders.index.byId[order.serviceOrderNumber] : undefined;

  // Load operations from related order for SAP planned maintenances. Otherwise use the orderId directly.
  const operations = isSapMaintenance
    ? order
      ? state.serviceOrders.operations[order.serviceOrderNumber]
      : undefined
    : state.serviceOrders.operations[ownProps.orderId];

  // Load documents for service order and linked order if available
  const linkedDocuments = isSapMaintenance ? state.document.filteredServiceOrders[order.serviceOrderNumber] : undefined;
  const allDocuments = mergeDocuments(documents, linkedDocuments);

  return {
    order,
    linkedOrder,
    isSapMaintenance: !!isSapMaintenance,
    functionalLocation: state.functionalLocations.functionalLocations[ownProps.functionalLocationId],
    operations,
    partnerMeta:
      ownProps.partnerNumber in state.partnerMeta.meta ? state.partnerMeta.meta[ownProps.partnerNumber] : undefined,
    documentCount: !loadingDocuments ? allDocuments.length : 0,
    documents: allDocuments,
    equipment: getOrderEquipment(order, state.equipments.equipments[ownProps.functionalLocationId]),
    loadingOrder: !!dataSource.loading[loadingOrderKey],
    loadingOrderKey,
    loadingDocuments,
    profile: state.profile.profile,
  };
};

const mapDispatchToProps = {
  loadServiceOrder,
  loadPlannedMaintenance,
  loadFunctionalLocations,
  loadServiceOrderOperations,
  loadPartnerMeta,
  loadDocumentsByServiceOrder,
  downloadDocument,
  downloadServiceOrderFile,
  loadEquipments: fl => loadEquipments(fl, true),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translations(withTheme(ServiceOrder)));
