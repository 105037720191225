import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import moment from 'moment';
import Checkbox from 'components/Form/Checkbox';
import MonthSelector from './MonthSelector';

export const createFilter = ({
  showCompleted = true,
  showOpen = true,
  hideWithoutImprovement = false,
  year,
  month,
}) => item => {
  const dateFilter = item =>
    moment(item.timestamp).isBetween(moment([year, month]), moment([year, month]).endOf('month'));
  if (hideWithoutImprovement) {
    return item.savingsPotentialHeat || item.savingsPotentialElectricity || item.savingsPotentialWater;
  }
  if (showCompleted && item.status?.status === 'completed') {
    return dateFilter(item);
  }
  if (showOpen && item.status?.status !== 'completed') {
    return dateFilter(item);
  }
  return false;
};

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: ${props => props.theme.spacing.xl};
  margin-right: ${props => props.theme.spacing.xl};
`;

const FilterLabel = styled.span`
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props => props.theme.colors.darkGray};
`;

export const ObservationsFilter = ({ observations = [], onChange, filter = {}, t }) => {
  const [month, setMonth] = useState(filter.month);
  const [year, setYear] = useState(filter.year);
  const [showOpen, setShowOpen] = useState(filter.showOpen ?? true);
  const [showCompleted, setshowCompleted] = useState(filter.showCompleted ?? true);
  const [hideWithoutImprovement, setHideWithoutImprovement] = useState(filter.hideWithoutImprovement ?? false);
  const handleFilterChange = changes => {
    onChange(() => createFilter({ showCompleted, showOpen, hideWithoutImprovement, year, month, ...changes }));
    if (typeof changes.showOpen === 'boolean') {
      setShowOpen(changes.showOpen);
    }
    if (typeof changes.showCompleted === 'boolean') {
      setshowCompleted(changes.showCompleted);
    }
    if (typeof changes.hideWithoutImprovement === 'boolean') {
      setHideWithoutImprovement(changes.hideWithoutImprovement);
    }
    if (changes.month) {
      setMonth(changes.month);
    }
    if (changes.year) {
      setYear(changes.year);
    }
  };
  return (
    <Filters>
      <FilterWrapper>
        <FilterLabel>{t('Status')}</FilterLabel>
        <Checkbox
          checked={showOpen}
          id="show-open"
          label={t('Open')}
          onChange={() => handleFilterChange({ showOpen: !showOpen })}
        />
        <Checkbox
          checked={showCompleted}
          id="show-completed"
          label={t('Completed')}
          onChange={() => handleFilterChange({ showCompleted: !showCompleted })}
        />
      </FilterWrapper>
      {false && (
        <FilterWrapper>
          <FilterLabel>{t('Change')}</FilterLabel>
          <Checkbox
            checked={hideWithoutImprovement}
            id="hide-without-improvement"
            label={t('Hide observations without improvement')}
            onChange={() => handleFilterChange({ hideWithoutImprovement: !hideWithoutImprovement })}
          />
        </FilterWrapper>
      )}
      <MonthSelector items={observations} active={{ year, month }} onMonthClick={handleFilterChange} />
    </Filters>
  );
};

ObservationsFilter.propTypes = {
  observations: PropTypes.array,
  onChange: PropTypes.func,
  t: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }),
};

export default translations(ObservationsFilter);
