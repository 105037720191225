import { useLocation, useHistory } from 'react-router-dom';

import queryString from 'query-string';
import memoizeOne from 'memoize-one';

const memoizedQuery = memoizeOne(search => queryString.parse(search));

const memoizedCreateSetQuery = memoizeOne(
  (query, history) => (newQuery, options = { replace: false, pathname: undefined, useReplace: false }) => {
    let newSearch = {};
    if (!options.replace) {
      newSearch = { ...query };
    }
    newSearch = { ...newSearch, ...newQuery };

    const newState = {
      pathname: options.pathname,
      search: queryString.stringify({ ...newSearch }),
    };

    if (options.useReplace) {
      history.replace(newState);
    } else {
      history.push(newState);
    }
  }
);

const useQuery = () => {
  const location = useLocation();
  const query = memoizedQuery(location.search);
  const history = useHistory();

  return {
    query,
    setQuery: memoizedCreateSetQuery(query, history),
  };
};

export default useQuery;
