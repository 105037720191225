import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import ReactSelect from 'react-select';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'react-select/dist/react-select.css';

import Svg from 'components/Svg/Svg';
import charWidth from 'utils/String/charWidth.js';

const Arrow = styled(({ lightBackground, ...props }) => <Svg {...props} />)`
  fill: ${props => (props.lightBackground ? props.theme.colors.black : props.theme.colors.white)};
`;
Arrow.displayName = 'Arrow';

const SelectWrapper = styled.div`
  width: ${props => props.width}px;
  color: ${props => (props.lightBackground ? props.theme.colors.black : props.theme.colors.white)};
  pointer-events: auto;
  cursor: pointer;

  .Select {
    .Select-control {
      background: transparent;
      border: none;
      box-shadow: unset;
      font-size: ${props => (props.large ? props.theme.font.size.md : props.theme.font.size.xs)};
      font-weight: ${props => props.theme.fontWeight.bold};
      cursor: pointer;

      .Select-value {
        display: ${props => (props.hideValueOnMobile ? 'none' : 'inherit')};
        background: transparent;
        justify-content: ${props => (props.center ? 'center' : 'flex-end')};
        align-items: center;

        span.Select-value-label {
          font-family: ${props => props.large && props.theme.fontFamily.heading};
          color: ${props => (props.lightBackground ? props.theme.colors.black : props.theme.colors.white)} !important;
        }

        ${props => props.theme.media.portrait`
                    display: flex;
                `}
      }
      .Select-input {
        background: transparent;
      }
      .Select-placeholder {
        text-align: right;
        color: ${props => (props.lightBackground ? props.theme.colors.black : props.theme.colors.white)};
        font-family: ${props => props.large && props.theme.fontFamily.heading};
      }
      .Select-arrow-zone {
        display: ${props => !props.customArrow && 'none'};
      }

      .Select-placeholder:after,
      .Select-value:after {
        content: '';
        border-color: ${props =>
          !props.customArrow &&
          `${props.lightBackground ? props.theme.colors.black : props.theme.colors.white} transparent transparent`};
        border-style: ${props => !props.customArrow && 'solid'};
        border-width: ${props => !props.customArrow && '5px 5px 2.5px'};
        display: inline-block;
        height: 0;
        width: 0;
        position: relative;
        margin-left: 10px;
      }
    }

    &.is-disabled > .Select-control,
    &.is-focused > .Select-control,
    &.is-focused:not(.is-open) > .Select-control {
      background: transparent;
      border: none;
      box-shadow: unset;
    }

    &.is-disabled .Select-value {
      opacity: 0.35;
    }

    .Select-arrow {
      border-color: ${props =>
        `${!props.lightBackground ? props.theme.colors.white : props.theme.colors.black} transparent transparent`};
    }

    &.is-open .Select-arrow {
      border-color: ${props =>
        `transparent transparent ${
          !props.lightBackground ? props.theme.colors.white : props.theme.colors.black
        }`} !important;
    }

    .Select-menu-outer {
      box-shadow: none;
      border-color: ${props => props.theme.colors.lightGray};
      background-color: ${props => props.theme.colors.white};
      border-radius: 4px;
      margin-top: 15px;
      z-index: 10;
      width: auto;

      &:before {
        top: -10px;
        bottom: auto;
        right: 20px;
        border-width: 0 10px 10px;
        content: '';
        position: absolute;
        border-style: solid;
        border-color: ${props => props.theme.colors.lightGray} transparent;
        display: block;
        width: 0;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-color: ${props => props.theme.colors.white} transparent;
        display: block;
        width: 0;
        top: -9px;
        bottom: auto;
        right: 20px;
        border-width: 0 10px 10px;
      }
    }

    .Select-menu {
      padding: 5px 0;
    }

    .Select-option {
      padding: 10px 18px;
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.black};
      font-size: 0.85em;
      text-align: left;

      &.is-selected {
        font-weight: ${props => props.theme.fontWeight.bold};
      }

      &.is-disabled {
        color: ${props => props.theme.colors.darkGray};
      }

      &:hover {
        background-color: ${props => transparentize(0.9, props.theme.colors.cerulean)};
      }
    }
  }
`;
SelectWrapper.displayName = 'SelectWrapper';

const MobileLabel = styled.div`
  position: absolute;
  right: 2em;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.colors.white};

  ${props => props.theme.media.portrait`
        display: none;
    `}
`;
MobileLabel.displayName = 'MobileLabel';

const Select = props => {
  const { t, disabled, value, options, customArrow, hideValueOnMobile, lightBackground, large, center } = props;
  const placeholder = props.placeholder || `${t('Select')}...`;

  // Handle 'options' disabled property
  const opts = options.map(o => (o.disabled ? { ...o, className: 'is-disabled' } : o));

  // Calculate the approximate width of the longest partner name
  let width = Math.max(...opts.concat({ label: placeholder }).map(c => _.reduce(c.label.split(''), charWidth, 0))) + 10;

  // If we are using large label, check that it will fit
  if (large) {
    const valueLabel = value && _.find(opts, { value }).label;
    const labelWidth = (_.reduce((valueLabel || placeholder).split(''), charWidth, 0) + 5) * 1.55;
    width = width < labelWidth ? labelWidth : width;
  }
  // Make sure the selector is not overflowing from the window
  width = window.innerWidth - 65 < width ? window.innerWidth - 65 : width;

  const renderArrow = customArrow => {
    if (React.isValidElement(customArrow)) {
      return customArrow;
    }
    return <Arrow name={customArrow} lightBackground={lightBackground} />;
  };

  const onOptionChange = selected => {
    if (!selected.disabled) {
      props.onChange(selected.value);
    }
  };

  return (
    <SelectWrapper
      width={width}
      hideValueOnMobile={hideValueOnMobile}
      lightBackground={lightBackground}
      large={large}
      center={center}
      customArrow={customArrow}
    >
      {hideValueOnMobile && <MobileLabel>{t('View as')}</MobileLabel>}
      <ReactSelect
        value={value}
        clearable={false}
        searchable={false}
        options={opts}
        onChange={onOptionChange}
        placeholder={placeholder}
        disabled={disabled}
        arrowRenderer={customArrow ? () => renderArrow(customArrow) : undefined}
      />
    </SelectWrapper>
  );
};

Select.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  customArrow: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hideValueOnMobile: PropTypes.bool,
  placeholder: PropTypes.string,
  lightBackground: PropTypes.bool,
  large: PropTypes.bool,
  center: PropTypes.bool,
};

Select.defaultProps = {
  disabled: false,
  value: null,
  customArrow: null,
  hideValueOnMobile: false,
  placeholder: null,
  lightBackground: false,
  large: false,
  center: false,
};

export default Select;
