import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import ValuesBox, { ValuesBoxFooter, Title, Value, Label } from 'components/ValuesBox/ValuesBox';
import ValuesBoxComparisonValues from 'components/ValuesBox/ValuesBoxComparisonValues';
import ValuesBoxComparisonValue from 'components/ValuesBox/ValuesBoxComparisonValue';

const MAX_NUMBER_OF_YEARS = 2;

export const EnergyBreakdownTotals = ({ unit, years, header, yearTotals, value, label, icon, theme, footer }) => {
  const sortedYears = [...years].sort().reverse();
  const yearsToRender = sortedYears.slice(0, MAX_NUMBER_OF_YEARS);
  return (
    <ValuesBox
      heading={header}
      value={value}
      label={label}
      icon={icon}
      positiveValueColor={theme.charts.colorsEnergy[0]}
    >
      {yearsToRender && yearsToRender.length > 0 && yearTotals && (
        <ValuesBoxComparisonValues>
          {yearsToRender.map(
            year =>
              !!yearTotals[year] && (
                <ValuesBoxComparisonValue
                  key={`energy-breakdown-total-${year}`}
                  value={`${Math.round(yearTotals[year])} ${unit}`}
                  label={year}
                />
              )
          )}
        </ValuesBoxComparisonValues>
      )}
      {footer?.header && footer?.value && (
        <ValuesBoxFooter>
          <Title>{footer.header}</Title>
          <Value>{footer.value}</Value>
          {footer.label && <Label>{footer.label}</Label>}
        </ValuesBoxFooter>
      )}
    </ValuesBox>
  );
};

EnergyBreakdownTotals.defaultProps = {
  unit: '',
  years: [],
  yearTotals: {},
  header: '',
  label: '',
  value: '',
};

EnergyBreakdownTotals.propTypes = {
  unit: PropTypes.string,
  years: PropTypes.array,
  yearTotals: PropTypes.object,
  header: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(EnergyBreakdownTotals);
