import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import { map, toUpper, uniqBy, flatten, isEmpty, compact, filter, find, uniq, sortBy } from 'lodash';
import Button from 'components/Button/Button';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import ExportSensorDataForm, { GRANULARITY } from 'components/ExportData/ExportSensorDataForm';
import memoizeOne from 'memoize-one';
import { exportSensorData } from 'redux/modules/iot/exportSensorData';
import { CATEGORY_SORT, TYPE_SORT, SENSOR_SORT } from 'components/Conditions/ConditionUtils';

const Heading = styled.h3`
  margin-bottom: ${props => props.theme.spacing.md};
`;

export class ExportSensorData extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    categories: PropTypes.array,
    sensorTypeOptions: PropTypes.array,
    categoriesSelected: PropTypes.array.isRequired,
    sensorTypesSelected: PropTypes.array,
    exportType: PropTypes.string,
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    categories: [],
    sensorTypeOptions: [],
    exportType: 'csv',
  };

  constructor(props) {
    super(props);
    this.state = {
      /**
       * Form model
       */
      model: {
        // Selected categories (groups / floors)
        categoriesSelected: props.categoriesSelected,
        // Selected sensor types
        sensorTypesSelected: props.sensorTypesSelected,
        // Selected particular sensors
        particularSensorsSelected: [],
        // Start date
        startDatetime: null,
        // End date
        endDatetime: null,
        // Granularity
        granularity: GRANULARITY.hourly,
      },
    };
  }

  /**
   * Form model property change handler
   */
  handleFormChange = (property, value) => {
    this.setState(oldState => {
      const newState = {
        model: {
          ...oldState.model,
          [property]: value,
        },
      };
      // Filter/reset combo values that are already been selected
      if (property === 'categoriesSelected') {
        if (value && !isEmpty(value)) {
          const { sensorTypeOptions } = this.props;
          newState.model.sensorTypesSelected = this.getSensorTypeOptions(sensorTypeOptions, value)
            .map(option => option.value)
            .filter(optionId => oldState.model.sensorTypesSelected.includes(optionId));
        } else {
          newState.model.sensorTypesSelected = [];
        }
      }
      if (property === 'categoriesSelected' || property === 'sensorTypesSelected') {
        if (value && !isEmpty(value)) {
          const { categories } = this.props;
          newState.model.particularSensorsSelected = this.getSensorOptions(
            categories,
            newState.model.categoriesSelected,
            newState.model.sensorTypesSelected
          )
            .map(option => option.value)
            .filter(optionId => oldState.model.particularSensorsSelected.includes(optionId));
        } else {
          newState.model.particularSensorsSelected = [];
        }
      }
      return newState;
    });
  };

  /**
   * Dialog onclose handler
   */
  handleDialogClose = e => {
    const { onClose } = this.props;

    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  /**
   * Show error notification
   */
  showError = message => {
    const { onError } = this.props;
    if (onError && typeof onError === 'function') {
      onError(message);
    }
  };

  /**
   * Handle form submit
   */
  handleSubmit = event => {
    event.preventDefault();
    const {
      categoriesSelected,
      sensorTypesSelected,
      particularSensorsSelected,
      startDatetime,
      endDatetime,
      granularity,
    } = this.state.model;
    const { exportType, onSuccess, onError, onClose, t } = this.props;

    const sensors = this.getSelectedSensors(categoriesSelected, sensorTypesSelected, particularSensorsSelected);
    if (isEmpty(sensors)) {
      this.showError(t('No sensors selected. Please select at least one sensor.'));
      return;
    }

    const payload = {
      format: exportType,
      sensorIds: sensors,
      start: startDatetime.toISOString(),
      end: endDatetime.toISOString(),
      ...(granularity !== 'raw' ? { granularity } : {}),
    };
    this.props.exportSensorData(payload).then(response => {
      if (response.error) {
        if (onError && typeof onError === 'function') {
          return onError(t('EXPORT_FAILED'));
        }
      } else if (onSuccess && typeof onSuccess === 'function') {
        return onSuccess(t('EXPORT_SUCCESS'));
      }

      onClose();
    });
  };

  /**
   * Get selected sensors
   */
  getSelectedSensors = (categoriesSelected, sensorTypesSelected, particularSensorsSelected) => {
    const { categories } = this.props;
    // If particular sensors are seleted, prioritize that
    if (!isEmpty(particularSensorsSelected)) {
      return particularSensorsSelected;
    }
    // Otherwise check category selection
    if (!isEmpty(categoriesSelected)) {
      const sensors = uniq(
        flatten(map(categoriesSelected, categoryId => this.getSensorsByCategory(categoryId, categories)))
      );
      // If both categories and sensor types are selected
      if (!isEmpty(sensorTypesSelected)) {
        // Sensors filtered by both, categories and sensor types
        return sensors.filter(sensor => sensorTypesSelected.includes(sensor.sensorType.id)).map(sensor => sensor.id);
      }
      // Sensors filtered only by category
      return map(sensors, 'id');
    }
    // If only sensor types are selected
    if (!isEmpty(sensorTypesSelected)) {
      return map(
        uniq(
          flatten(map(sensorTypesSelected, sensorTypeId => map(this.getSensorsByType(sensorTypeId, categories), 'id')))
        ),
        'id'
      );
    }
    // All allowed sensors
    return this.getSensorsFromCategories(categories).map(sensor => sensor.id);
  };

  /**
   * Get unique sensors from all categories
   */
  getSensorsFromCategories = memoizeOne(categories => uniqBy(flatten(compact(map(categories, 'sensors'))), 'id'));

  /**
   * Get measuring points (flatten multi-sensors)
   */
  getMeasuringPointsFromSensors = memoizeOne(sensors =>
    sensors.reduce((acc, sensor) => {
      if (sensor.sensorType) {
        return [...acc, sensor, ...sensor.children];
      }

      return [...acc, sensor];
    }, [])
  );

  /**
   * Create category options for input component
   */
  getCategoryOptions = memoizeOne((t, categories) =>
    map(sortBy(categories, CATEGORY_SORT), category => {
      const categoryName = category.shortName || category.name;
      return {
        value: category.id,
        label: category.type === 'floor' ? `${t('Floor')} ${categoryName}` : categoryName,
      };
    })
  );

  /**
   * Create sensor type options for input component
   */
  getSensorTypeOptions = memoizeOne((sensorTypeOptions, categoriesSelected) =>
    sortBy(
      !isEmpty(categoriesSelected)
        ? sensorTypeOptions
            .filter(option => Array.from(option.categoryIds).some(id => categoriesSelected.includes(id)))
            .map(option => ({ value: option.id, label: option.label }))
        : sensorTypeOptions.map(option => ({ value: option.id, label: option.label })),
      TYPE_SORT
    )
  );

  /**
   * Create sensor options for input component
   */
  getSensorOptions = memoizeOne((categories, categoriesSelected, sensorTypesSelected) => {
    // Sensors for all available categories
    let sensors = categories.map(category => category.sensors).reduce((a, b) => a.concat(b), []);

    if (!isEmpty(categoriesSelected)) {
      // Sensors by selected category
      sensors = categories
        .filter(category => categoriesSelected.includes(category.id))
        .map(category => category.sensors)
        .reduce((a, b) => a.concat(b), []);
    }
    if (!isEmpty(sensorTypesSelected)) {
      // Filter sensors by selected sensor type
      sensors = sensors.filter(sensor =>
        sensor && sensor.sensorType ? sensorTypesSelected.includes(sensor.sensorType.id) : false
      );
    }
    const sorted = sortBy(sensors, SENSOR_SORT);
    return map(sorted, sensor => ({
      value: sensor.id,
      label: sensor.name,
    }));
  });

  getSensorsByType = (sensorTypeId, categories) => {
    const sensors = this.getSensorsFromCategories(categories);
    const measuringPoints = this.getMeasuringPointsFromSensors(sensors);
    return filter(measuringPoints, { sensorTypeId });
  };

  getSensorsByCategory = (categoryId, categories) => {
    const category = find(categories, { id: categoryId });
    if (!category) {
      return [];
    }
    return category.sensors;
  };

  render() {
    const { t, categories, sensorTypeOptions, exportType, submitting } = this.props;
    const { categoriesSelected, sensorTypesSelected } = this.state.model;

    const categoryOptions = this.getCategoryOptions(t, categories) || [];
    const sensorTypeOpts = this.getSensorTypeOptions(sensorTypeOptions, categoriesSelected) || [];
    const sensorOptions = this.getSensorOptions(categories, categoriesSelected, sensorTypesSelected) || [];

    return (
      <form>
        <DialogModal
          t={t}
          isActive
          animate
          onOverlayClick={this.handleDialogClose}
          footer={
            <DialogFooter>
              <Button cancel onClick={this.handleDialogClose}>
                {t('Cancel')}
              </Button>
              <Button submit onClick={this.handleSubmit} type="submit" loading={submitting}>
                {t('Export')}
              </Button>
            </DialogFooter>
          }
        >
          <Heading>{t('Export to {0}', toUpper(exportType))}</Heading>
          <ExportSensorDataForm
            granularityOptions={GRANULARITY}
            model={this.state.model}
            onPropertyChange={this.handleFormChange}
            categoryOptions={categoryOptions}
            sensorTypeOptions={sensorTypeOpts}
            sensorOptions={sensorOptions}
            t={t}
          />
        </DialogModal>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  submitting: state.exportSensorData.loading,
});

const mapDispatchToProps = dispatch => ({
  exportSensorData: data => dispatch(exportSensorData(data)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(ExportSensorData));
