import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { range, map, take, takeRight, sortBy, isNil } from 'lodash';

import PerformanceList from 'components/PerformanceList/PerformanceList';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';

const StyledBenchmarking = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.theme.media.landscape`
        flex-direction: column;
    `}
`;
StyledBenchmarking.displayName = 'StyledBenchmarking';

const ListWrapper = styled.div`
  flex: 1;
  &:first-child:not(:only-child) {
    margin-right: ${props => props.theme.spacing.lg};
  }
`;

const Performances = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${props => props.theme.media.landscape`
        flex-flow: row nowrap;
        justify-content: space-between;
    `};
`;
Performances.displayName = 'Performances';

const Filter = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Select-control {
    width: 300px;
  }
  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;
Filter.displayName = 'Filter';

const FilterInfo = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 1em;
  ${props => props.theme.media.landscape`
        margin-bottom: auto;
        margin-top: auto;
        margin-right: 1em;
    `};
`;
FilterInfo.displayName = 'FilterInfo';

export class Benchmarking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: 10,
      top: [],
      worst: [],
      filterOptions: null,
      showWorst: props.performers && props.performers.length > 10,
    };
  }

  componentDidMount() {
    this.assignPerformers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { performers } = this.props;
    if (prevState.filter !== this.state.filter || prevProps.performers !== performers) {
      this.assignPerformers();
      this.setState({
        showWorst: performers && performers.length > 10,
      });
    }
  }

  assignPerformers = () => {
    const sorted = sortBy(this.props.performers, 'value');
    const top = take(sorted, this.state.filter);
    const worst = takeRight(sorted, this.state.filter);
    const filterOptions = this.generateFilterSelection();

    this.setState({ top, worst, filterOptions });
  };

  buildListItem = (entry, partnerNumber) => ({
    title: entry.title,
    subtitle: entry.address,
    value: `${isNil(entry.value) ? '' : entry.value.toFixed(1)} ${entry.unit}`,
    to: `/${partnerNumber}/FunctionalLocation/${entry.functionalLocation}/Energy`,
  });

  generateFilterSelection = () => {
    const options = map(range(5, this.props.performers.length, 5), index => ({
      label: `${index} ${this.props.t('Buildings')}`,
      value: index,
    }));
    options.push({
      label: `${this.props.performers.length} ${this.props.t('Buildings')}`,
      value: this.props.performers.length,
    });
    return options;
  };

  handleSelectDropdown = (property, value) => this.setState({ [property]: value });

  get performances() {
    const { t, partnerNumber, energyTabEnabled } = this.props;
    const { top, worst, showWorst } = this.state;

    return (
      <Performances>
        <ListWrapper>
          <PerformanceList
            t={t}
            title="Top performers"
            items={top.map(performer => this.buildListItem(performer, partnerNumber))}
            clickEnabled={energyTabEnabled}
          />
        </ListWrapper>
        {showWorst && (
          <ListWrapper>
            <PerformanceList
              t={t}
              title="Worst performers"
              items={worst.map(performer => this.buildListItem(performer, partnerNumber)).reverse()}
              clickEnabled={energyTabEnabled}
            />
          </ListWrapper>
        )}
      </Performances>
    );
  }

  render() {
    const { t, performers } = this.props;
    const { filter, filterOptions } = this.state;

    return (
      <Section>
        <StyledBenchmarking>
          <SectionHeader
            title={t('Benchmarking')}
            descriptionText={t('Buildings')}
            descriptionValue={performers.length}
          />
          {this.performances}
          {filterOptions && (
            <Filter>
              <FilterInfo>{t('Show')}</FilterInfo>
              <InputSelectDropdown
                t={t}
                options={filterOptions}
                onChange={this.handleSelectDropdown}
                model={{ filter }}
                property="filter"
                clearable={false}
              />
            </Filter>
          )}
        </StyledBenchmarking>
      </Section>
    );
  }
}

Benchmarking.propTypes = {
  performers: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  energyTabEnabled: PropTypes.bool.isRequired,
  partnerNumber: PropTypes.string.isRequired,
};

export default withTheme(Benchmarking);
