import { getResponseStatistics } from 'containers/Application/ServiceCalendar/utils';
import * as utils from 'containers/Application/ServiceOrderStatistics/utils';
import {
  STATISTICS_ORDER_DOWNTIME_HOURS,
  STATISTICS_ORDER_LEAD_TIME_HOURS,
  STATISTICS_ORDER_REACTION_TIME_HOURS,
  STATISTICS_ORDER_DOWNTIME_PERCENTS,
  STATISTICS_ORDER_LEAD_TIME_PERCENTS,
  STATISTICS_ORDER_REACTION_TIME_PERCENTS,
  SLA_META_KEY_DOWNTIME,
  SLA_META_KEY_LEAD_TIME,
  SLA_META_KEY_REACTION_TIME,
} from 'constants/serviceCalendar';

export const chartStatistics = (orders, responseSLAs, statisticsType) => {
  let property;
  switch (statisticsType) {
    case STATISTICS_ORDER_DOWNTIME_HOURS:
    case STATISTICS_ORDER_DOWNTIME_PERCENTS:
      property = 'downtimeStatistics';
      break;
    case STATISTICS_ORDER_LEAD_TIME_HOURS:
    case STATISTICS_ORDER_LEAD_TIME_PERCENTS:
      property = 'leadTimeStatistics';
      break;
    case STATISTICS_ORDER_REACTION_TIME_HOURS:
    case STATISTICS_ORDER_REACTION_TIME_PERCENTS:
      property = 'reactionTimeStatistics';
      break;
    default:
      break;
  }
  return getResponseStatistics(orders, responseSLAs)[property];
};

export const referenceData = (partnerMeta, statisticsType, selectedPriority, theme, t) => {
  let metaKey;
  switch (statisticsType) {
    case STATISTICS_ORDER_DOWNTIME_HOURS:
    case STATISTICS_ORDER_DOWNTIME_PERCENTS:
      metaKey = SLA_META_KEY_DOWNTIME;
      break;
    case STATISTICS_ORDER_LEAD_TIME_HOURS:
    case STATISTICS_ORDER_LEAD_TIME_PERCENTS:
      metaKey = SLA_META_KEY_LEAD_TIME;
      break;
    case STATISTICS_ORDER_REACTION_TIME_HOURS:
    case STATISTICS_ORDER_REACTION_TIME_PERCENTS:
      metaKey = SLA_META_KEY_REACTION_TIME;
      break;
    default:
      break;
  }
  return utils.getReference(partnerMeta, `${metaKey}${selectedPriority}`, theme, t, t('SLA'));
};

export const slas = (responseSLAs, statisticsType) => {
  switch (statisticsType) {
    case STATISTICS_ORDER_DOWNTIME_HOURS:
    case STATISTICS_ORDER_DOWNTIME_PERCENTS:
      return responseSLAs.downtime;
    case STATISTICS_ORDER_LEAD_TIME_HOURS:
    case STATISTICS_ORDER_LEAD_TIME_PERCENTS:
      return responseSLAs.leadTime;
    case STATISTICS_ORDER_REACTION_TIME_HOURS:
    case STATISTICS_ORDER_REACTION_TIME_PERCENTS:
      return responseSLAs.reactionTime;
    default:
      return null;
  }
};

export const helpTexts = (statisticsType, t) => {
  switch (statisticsType) {
    case STATISTICS_ORDER_DOWNTIME_HOURS:
    case STATISTICS_ORDER_DOWNTIME_PERCENTS:
      return {
        heading: t('Downtime'),
        text: t(
          'is the time between submitted service request and the time when the order is technically ' +
            'finished and equipment is running again.'
        ),
      };
    case STATISTICS_ORDER_LEAD_TIME_HOURS:
    case STATISTICS_ORDER_LEAD_TIME_PERCENTS:
      return {
        heading: t('Lead time'),
        text: t(
          'is the time between submitted service request and the time when the order is technically ' +
            'finished and equipment is running again.'
        ),
      };
    case STATISTICS_ORDER_REACTION_TIME_HOURS:
    case STATISTICS_ORDER_REACTION_TIME_PERCENTS:
      return {
        heading: t('Reaction Time'),
        text: t('is the time between submitted service request and the time when technician is starting the order.'),
      };
    default:
      return {};
  }
};
