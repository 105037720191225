import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { map, isArray, isPlainObject } from 'lodash';
import { rgba } from 'polished';
import Svg from 'components/Svg/Svg';
import ViewModeButton from 'components/Button/ViewModeButton';
import Section from 'components/Section/Section';

const StyledViewModeButton = styled(ViewModeButton)`
  margin-left: 0;
  display: inline-flex;
  margin-bottom: ${props => props.theme.spacing.xs};
`;
StyledViewModeButton.displayName = 'StyledViewModeButton';

const FilterItem = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.cerulean};
  background-color: ${props => rgba(props.theme.colors.cerulean, 0.1)};
  height: 20px;
  box-sizing: content-box;
  margin-right: ${props => props.theme.spacing.xs};
  margin-bottom: ${props => props.theme.spacing.xs};
`;
FilterItem.displayName = 'FilterItem';

const Cross = styled(Svg)`
  fill: ${props => props.theme.colors.cerulean};
  border-right: 1px solid ${props => props.theme.colors.cerulean};
  font-size: 20px;
  padding: 0.3rem;
  box-sizing: border-box;
  cursor: pointer;
`;
Cross.displayName = 'Cross';

const Label = styled.span`
  padding: ${props => props.theme.spacing.xs};
  font-size: ${props => props.theme.font.size.xxs};
  color: ${props => props.theme.colors.cerulean};
  font-family: ${props => props.theme.font.family.arial};
  font-weight: ${props => props.theme.font.weight.bold};
`;
Label.displayName = 'Label';

const Item = ({ t, keyLabel, valueObject, handleFilterRemove, isRange }) => (
  <FilterItem>
    <Cross onClick={() => handleFilterRemove(keyLabel, null, valueObject.value)} name="remove" />
    <Label>{`${t(keyLabel)}: ${isRange ? valueObject.join('-') : valueObject.value}`}</Label>
  </FilterItem>
);
Item.displayName = 'Item';

const EnergyOptimizationFilterRow = ({ t, handleFilterRemove, loading, toggleFilterClick, selectedFilterValues }) => (
  <Section transparent noPadding noMargin mobilePadding>
    <StyledViewModeButton
      isFilter
      data-test-id="FilterLink"
      iconName="control-room"
      onClick={toggleFilterClick}
      text={t('Show filters')}
      disabled={loading}
    />
    {map(selectedFilterValues, (value, key) => {
      if (isArray(value) && isPlainObject(value[0])) {
        return value.map(valueObject => (
          <Item
            t={t}
            key={key + valueObject.value}
            keyLabel={key}
            valueObject={valueObject}
            handleFilterRemove={handleFilterRemove}
          />
        ));
      }

      return (
        <Item
          t={t}
          key={key}
          keyLabel={key}
          valueObject={value}
          handleFilterRemove={handleFilterRemove}
          isRange={isArray(value)}
        />
      );
    })}
  </Section>
);

EnergyOptimizationFilterRow.propTypes = {
  t: PropTypes.func.isRequired,
  handleFilterRemove: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  toggleFilterClick: PropTypes.func.isRequired,
  selectedFilterValues: PropTypes.object,
  loading: PropTypes.bool,
};

export default React.memo(EnergyOptimizationFilterRow);
