import styled from 'styled-components';

const SMALL = 300;
const DEFAULT = 700;
const LARGE = 1000;
const EXTRA_LARGE = 1500;

/**
 * Dialog body UI component
 */
const DialogBody = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.xl};
  transition: ${props => `all ${props.theme.motion.default} ${props.theme.motion.easing}`};
  min-height: 100%;

  ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.xl};
    `}

  ${props => props.theme.media.landscape`
        padding: ${props => props.theme.spacing.xxl};
        min-height: initial;
        width: ${props =>
          (props.small && SMALL) || (props.large && LARGE) || (props.extraLarge && EXTRA_LARGE) || DEFAULT}px;
        max-width: 95vw;
    `}
`;

export default DialogBody;
