import React from 'react';
import { connect } from 'react-redux';

const translations = WrappedComponent => {
  class TranslationsComponent extends React.Component {
    t = (key, ...params) => {
      const localization = this.props.localization;
      if (!localization) {
        return key;
      }
      let formatted = localization.translations[key] || key;
      for (let i = 0; i < params.length; i++) {
        const regexp = new RegExp('\\{' + i + '\\}', 'gi');
        formatted = formatted.replace(regexp, params[i]);
      }
      return formatted;
    };

    render() {
      const { localization, ...others } = this.props;
      return <WrappedComponent {...others} t={this.t} />;
    }
  }

  const mapStateToProps = state => {
    return {
      localization: state.localization,
    };
  };

  return connect(mapStateToProps)(TranslationsComponent);
};

export default translations;
