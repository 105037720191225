import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import Application from 'containers/Application/Application.jsx';
import AcceptAuthorization from 'containers/Application/Authorization/AcceptAuthorization.jsx';
import WelcomeNewUser from 'containers/Application/User/WelcomeNewUser.jsx';
import Helpdesk from 'containers/Application/Anonymous/Helpdesk.jsx';
import SomethingWentWrong from 'containers/Application/User/SomethingWentWrong';
import GeneralLoading from 'containers/GeneralLoading/GeneralLoading';
import configureStore from './redux/create.js';
import themes from 'styles/themes';
import { ThemeProvider } from 'styled-components';
import ResetCss from 'styles/globalStyles/globalStylesReset';
import BaseCss from 'styles/globalStyles/globalStylesApplication';

const history = createBrowserHistory();
const store = configureStore(history);

const files = require.context('../app/images/svg/', false, /.*\.inline\.svg$/);
files.keys().forEach(files);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Fragment>
        <ResetCss />
        <BaseCss />
        <Switch>
          <Route exact path="/Authorize" component={AcceptAuthorization} />
          <Route path="/Welcome" component={WelcomeNewUser} store={store} />
          <Route path="/fi/Helpdesk" component={Helpdesk} />
          <Route path="/SomethingWentWrong" component={SomethingWentWrong} />
          <Application />
        </Switch>
        <ThemeProvider theme={themes.customerPlatform}>
          <GeneralLoading />
        </ThemeProvider>
      </Fragment>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
