import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Section from 'components/Section/Section';
import translations from 'decorators/Translations/translations';
import * as actions from 'redux/modules';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import AutocompleteSuggestions from './AutocompleteSuggestions';
import { InputRow, InputBooleanCheckbox } from 'components/index';
import Loader from 'components/Loader/Loader';
import SearchResultsBody from './SearchResultsBody';
import SearchResultsHeader from './SearchResultsHeader';
import { getFLResultsByCustomer } from 'redux/modules/customer/customer';

const types = [
  { type: 'BE', text: 'Business Entities' },
  { type: 'BU', text: 'Buildings' },
  { type: 'UN', text: 'Tenants' },
  { type: 'IN', text: 'Industries' },
  { type: 'TO', text: 'Technical Objects' },
  { type: 'SYSTEM', text: 'Disciplines' },
];

const SectionTitle = styled.h2`
  padding: ${props => props.theme.spacing.md} 0;
  font-size: ${props => props.theme.font.size.lg};
`;

const UserformSearchFilters = styled.div`
  margin-top: ${props => props.theme.spacing.md};
  background-color: ${props => props.theme.colors.alabaster};
  border-top: 1px solid ${props => props.theme.colors.alabaster};
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  color: ${props => props.theme.colors.blue};
  padding: 0.9em 1.2em;
  font-size: 1em;

  :checked + label {
    top: 3px;
  }

  > span {
    margin-right: 10px;
  }
`;

const SearchFilterInput = styled.span`
  display: inline-block;
  margin: ${props => props.theme.spacing.xs} 0;
`;

const AddPermissions = ({
  t,
  loading,
  suggestions,
  autocomplete,
  resetAutocomplete,
  loadFunctionalLocations,
  loadingFunctionalLocations,
  model,
  portfolioCounts,
  customers,
  searchTypes,
  addFunctionalLocation,
  deleteFunctionalLocation,
  addPartnerPermission,
  changeTypes,
  resultsByCustomer,
  setSelectedCustomerId,
  resetSearch,
}) => {
  React.useEffect(
    () => () => {
      resetAutocomplete();
      resetSearch(true);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [searchTerm, setSearchTerm] = React.useState('');
  const [filter, setFilter] = React.useState('');
  const [resultSelected, setResultSelected] = React.useState(false);

  const handleChange = term => {
    setSearchTerm(term);
    autocomplete(term).catch(err => undefined); // eslint-disable-line handle-callback-err
  };

  const handleSelect = selected => {
    setResultSelected(true);
    if (selected.functionalLocation) {
      loadFunctionalLocations(selected.partnerNumberWithParents);
      setSelectedCustomerId(undefined);
      setFilter(selected.functionalLocation);
    } else {
      loadFunctionalLocations([selected.partnerNumber]);
      setSelectedCustomerId(selected.partnerNumber);
      setFilter('');
    }
  };

  const toggleType = (type, value) => {
    const types = { ...searchTypes, [type]: value };
    changeTypes(types);

    const customerIds = resultsByCustomer.results.map(result => result.customerId);
    loadFunctionalLocations(customerIds, types);
  };

  return (
    <Section>
      <SectionTitle id="partnerNumber">{t('Add Permissions')}</SectionTitle>
      <Autocomplete
        value={searchTerm}
        onChange={handleChange}
        loading={loading}
        placeholder={t('Search customers and functional locations...')}
        onSelect={handleSelect}
        suggestions={suggestions}
        suggestionsComponent={AutocompleteSuggestions}
      />
      <div>
        {resultSelected && (
          <UserformSearchFilters>
            {types.map(type => (
              <SearchFilterInput key={type.type}>
                <InputBooleanCheckbox
                  id={type.type}
                  value={searchTypes[type.type]}
                  onChange={(_, value) => toggleType(type.type, value)}
                  label={t(type.text)}
                />
              </SearchFilterInput>
            ))}
          </UserformSearchFilters>
        )}
        {loadingFunctionalLocations ? (
          <InputRow fullRow>
            <Loader />
          </InputRow>
        ) : (
          resultsByCustomer.results.length > 0 && (
            <>
              <SearchResultsHeader count={resultsByCustomer?.count ?? 0} onChange={setFilter} filter={filter} />
              {resultsByCustomer.results.map(({ customerId, functionalLocations }) => (
                <SearchResultsBody
                  key={customerId}
                  t={t}
                  model={model}
                  items={functionalLocations}
                  customer={customers[customerId]}
                  portfolioCounts={portfolioCounts}
                  addFunctionalLocation={functionalLocation => addFunctionalLocation(customerId, functionalLocation)}
                  deleteFunctionalLocation={deleteFunctionalLocation}
                  addPartnerPermission={addPartnerPermission}
                  filter={filter}
                />
              ))}
            </>
          )
        )}
      </div>
    </Section>
  );
};

AddPermissions.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  suggestions: PropTypes.shape({
    customers: PropTypes.arrayOf(PropTypes.object).isRequired,
    functionalLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  autocomplete: PropTypes.func.isRequired,
  resetAutocomplete: PropTypes.func.isRequired,
  loadFunctionalLocations: PropTypes.func.isRequired,
  loadingFunctionalLocations: PropTypes.bool.isRequired,
  model: PropTypes.object.isRequired,
  portfolioCounts: PropTypes.object.isRequired,
  customers: PropTypes.object.isRequired,
  searchTypes: PropTypes.object.isRequired,
  addFunctionalLocation: PropTypes.func.isRequired,
  deleteFunctionalLocation: PropTypes.func.isRequired,
  addPartnerPermission: PropTypes.func.isRequired,
  changeTypes: PropTypes.func.isRequired,
  resultsByCustomer: PropTypes.shape({
    count: PropTypes.number.isRequired,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        customerId: PropTypes.string.isRequired,
        functionalLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
      })
    ).isRequired,
  }).isRequired,
  setSelectedCustomerId: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
};

const mapState = state => ({
  loading: state.customer.autocomplete.loading,
  suggestions: state.customer.autocomplete.result,
  searchTypes: state.customer.typesToSearch,
  resultsByCustomer: getFLResultsByCustomer(state),
});

const mapDispatch = {
  autocomplete: actions.autocompletePermissions,
  resetAutocomplete: actions.resetPermissionAutocomplete,
  changeTypes: actions.changeTypes,
  resetSearch: actions.resetSearch,
  setSelectedCustomerId: actions.setSelectedCustomerId,
};

export default connect(
  mapState,
  mapDispatch
)(translations(AddPermissions));
