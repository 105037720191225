import { MasterData } from '@caverion/redux/api/actions';
import { createReducerFromMapping } from 'redux/utils/index.js';
import { findIndex } from 'lodash';

const initialState = {
  analytics: [],
  error: {},
};

export const LOAD_ANALYTICS = 'CUSTOMER_PLATFORM/IoT_Analytics/LOAD_ANALYTICS';
export const LOAD_ANALYTICS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Analytics/LOAD_ANALYTICS_SUCCESS';
export const LOAD_ANALYTICS_FAIL = 'CUSTOMER_PLATFORM/IoT_Analytics/LOAD_ANALYTICS_FAIL';

export const loadAnalytics = () => {
  return async dispatch => {
    dispatch({ type: LOAD_ANALYTICS });
    try {
      const result = await dispatch(
        MasterData.analytics(
          JSON.stringify({
            include: 'requirements',
          })
        )
      );

      return dispatch({
        type: LOAD_ANALYTICS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_ANALYTICS_FAIL,
        error,
      });
    }
  };
};

export const UPSERT_ANALYTICS = 'CUSTOMER_PLATFORM/IoT_Analytics/UPSERT_ANALYTICS';
export const UPSERT_ANALYTICS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Analytics/UPSERT_ANALYTICS_SUCCESS';
export const UPSERT_ANALYTICS_FAIL = 'CUSTOMER_PLATFORM/IoT_Analytics/UPSERT_ANALYTICS_FAIL';

export const upsertAnalytics = analytics => {
  return async dispatch => {
    dispatch({ type: UPSERT_ANALYTICS });
    try {
      const result = await dispatch(MasterData.upsertAnalytics(analytics));
      return dispatch({
        type: UPSERT_ANALYTICS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPSERT_ANALYTICS_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_ANALYTICS]: (state, action) => ({
      ...state,
    }),
    [LOAD_ANALYTICS_SUCCESS]: (state, action) => ({
      ...state,
      analytics: action.result,
    }),
    [LOAD_ANALYTICS_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPSERT_ANALYTICS]: (state, action) => ({
      ...state,
    }),
    [UPSERT_ANALYTICS_SUCCESS]: (state, action) => {
      let analytics;
      const index = findIndex(state.analytics, { id: action.result.id });
      if (index === -1) {
        analytics = [].concat(state.analytics).concat(action.result);
      } else {
        analytics = [].concat(state.analytics);
        analytics[index] = action.result;
      }
      return {
        ...state,
        analytics,
      };
    },
    [UPSERT_ANALYTICS_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),
  },
  initialState
);
