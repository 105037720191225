import moment from 'moment-timezone';
import Highcharts from 'highcharts';
require('moment/locale/fi.js');
require('moment/locale/sv.js');
require('moment/locale/nb.js');
require('moment/locale/da.js');
require('moment/locale/lt.js');
require('moment/locale/en-gb.js');
require('moment/locale/de.js');
require('moment/locale/ru.js');

const defaultLocale = 'en-gb';
const conf = {
  globalLocale: defaultLocale,
};

export const setGlobalLocale = (locale = defaultLocale) => {
  let localeToUse = locale || defaultLocale;
  localeToUse = localeToUse === 'en' ? 'en-gb' : localeToUse;

  conf.globalLocale = localeToUse;
  moment.locale(conf.globalLocale);

  // Configure Highcharts globally to use the correct language in month and weekday names.
  Highcharts.setOptions({
    lang: {
      months: moment.localeData().months(),
      shortMonths: moment.localeData().monthsShort(),
      weekdays: moment.localeData().weekdays(),
    },
    time: {
      timezone: moment.tz.guess(),
      useUTC: false,
    },
  });
};

export const shortDate = date => {
  return moment(date).format('L');
};
export const longDate = date => {
  return moment(date).format('LL');
};
export const detailedTime = date => {
  return moment(date).format('L LT');
};

export const longDateWithTime = date => {
  return moment(date).format('LLL');
};
setGlobalLocale(defaultLocale);
