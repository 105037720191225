import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import memoizeOne from 'memoize-one';
import { find } from 'lodash';

import { StatisticsType, GroupedServiceOrders } from '../../types';
import StatisticsBarChart from 'components/Charts/StatisticsBarChart';
import { OrderType, SyntheticOrderStatus } from 'constants/serviceCalendar';
import * as data from './data';
import * as utils from '../../utils';
import StatisticsChartContainer from '../../StatisticsChartContainer/StatisticsChartContainer';
import StatisticsHelp from 'containers/Application/ServiceOrderStatistics/StatisticsHelp/StatisticsHelp';

const collectExternalUsers = memoizeOne(data.collectExternalUsers);
const aggregateMaintenances = memoizeOne(data.aggregateMaintenances);
const filterAggregates = memoizeOne(data.filterAggregates);
const getChartData = memoizeOne(data.getChartData);

const FilterInput = styled.input`
  width: 250px;
  padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.xs};
  box-shadow: none;
  border-bottom: thin solid ${props => props.theme.colors.rockBlue};
`;

const PlannedVolumes = ({ t, theme, serviceOrders, functionalLocations, statisticsType, yearControls }) => {
  const [filter, setFilter] = useState('');

  const maintenances = serviceOrders[OrderType.PLANNED];
  const externalUsers = collectExternalUsers(maintenances);
  const aggregates = aggregateMaintenances(maintenances, statisticsType, functionalLocations);
  const filtered = filterAggregates(aggregates, filter, statisticsType, functionalLocations, externalUsers, t);
  const { categories, series } = getChartData(t, filtered, statisticsType, functionalLocations, externalUsers);
  const hasCompletedOther = !!find(series, { name: `${t(SyntheticOrderStatus.COMPLETED_OTHER)} *` });
  const max = series.reduce((acc, { data }) => acc + (data[0] || 0), 0);
  const total = filtered
    .map(([, aggregate]) => utils.getAggregateTotal(aggregate, utils.plannedStatuses))
    .reduce((sum, count) => sum + count, 0);

  const empty = !aggregates.length;
  return (
    <React.Fragment>
      {!empty && (
        <FilterInput type="text" placeholder={t('Filter')} value={filter} onChange={e => setFilter(e.target.value)} />
      )}
      <StatisticsChartContainer
        t={t}
        empty={empty}
        count={total}
        countLabel={t('Planned Maintenance')}
        controls={yearControls}
      >
        <StatisticsBarChart
          key={statisticsType}
          t={t}
          type="bar"
          height={utils.CHART_HEIGHT}
          hideLegend={false}
          categories={categories}
          noZoom
          stacked
          plotBorderWidth="0"
          legendAlign="left"
          labelStyle={utils.getLabelStyle(theme)}
          series={series}
          colors={utils.getPlannedColors(theme, hasCompletedOther)}
          yTitle={t('Planned Maintenance')}
          hideDecimals
          backgroundColor="transparent"
          xAxis={utils.getXAxisOptions(categories.length)}
          yMax={max * 1.05}
        />
      </StatisticsChartContainer>
      {hasCompletedOther && (
        <StatisticsHelp
          useAsterisk
          heading={t('Completed, other')}
          text={t('contains all the completed planned maintenance that are missing planned date.')}
        />
      )}
    </React.Fragment>
  );
};

PlannedVolumes.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  serviceOrders: GroupedServiceOrders,
  functionalLocations: PropTypes.objectOf(PropTypes.object).isRequired,
  statisticsType: StatisticsType,
  yearControls: PropTypes.node.isRequired,
};

export default withTheme(PlannedVolumes);
