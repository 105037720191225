import { createReducerFromMapping } from 'redux/utils/index.js';
import _ from 'lodash';
import titleize from 'underscore.string/titleize';
import replaceAll from 'underscore.string/replaceAll';
import { IoT } from '@caverion/redux/api/actions';

const initialState = {
  realSensors: {},
  categories: {},
};

const Group = {
  MainMeter_kW_5min_rate: 'Energy',
  MainMeter_kW_15min_rate: 'Energy',
  MainMeter_kW_30min_rate: 'Energy',
  w: 'Energy',
  MainMeter_kWh_hourly: 'Energy',
  MainMeter_kWh_total: 'Energy',
  Total_Building_kWh_hourly: 'Energy',

  Temp_Performance: 'Temperature',
  'Temperature after HeatRecovery': 'Temperature',
  'Temperature Extract Air': 'Temperature',
  'Temperature Inlet Air': 'Temperature',
  'Temperature Supply Air': 'Temperature',
  'Temperature Room': 'Temperature',
  Vip_Temp_Performance: 'Temperature',
  'VIP temperature': 'Temperature',

  Lights_Off_Performance: 'Lighting',
  Lights_DayLux_Performance: 'Lighting',
  'Lights in building': 'Lighting',
  'LUX Room': 'Lighting',

  AfterHeatExchangerBattery: 'Power',
  ExctractSensorBattery: 'Power',
  ExtractFilterSensorBattery: 'Power',
  InletSensorBattery: 'Power',
  InletFilterSensorBattery: 'Power',
  PulseSensorBattery: 'Power',
  RoomSensorBattery: 'Power',
  SupplySensorBattery: 'Power',
  VIPSensorBattery: 'Power',

  HeatExchanger_Performance: 'Thermal',
  HeatRecoveryEfficiency: 'Thermal',
  Heating_Performance: 'Thermal',
  Heating: 'Thermal',
  'DewPoint Inlet Air': 'Thermal',
  'DewPoint Extract Air': 'Thermal',
  Humidity_Performance: 'Thermal',
  'Humidity Inlet Air': 'Thermal',
  'Humidity Extract Air': 'Thermal',
};

export const LOAD = 'CUSTOMER_PLATFORM/IoT_Sensor/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Sensor/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/IoT_Sensor/LOAD_FAIL';

export const load = (functionalLocation, equipment) => {
  const filter = {
    where: {
      functionalLocation: functionalLocation.functionalLocation,
    },
  };

  if (equipment !== undefined) {
    filter.where.equipmentNumber = equipment.equipmentNumber;
  }

  return async dispatch => {
    dispatch({ type: LOAD });
    try {
      const result = await dispatch(IoT.sensors(JSON.stringify(filter)));

      return dispatch({
        type: LOAD_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        equipmentNumber: equipment && equipment.equipmentNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        error,
      });
    }
  };
};

const humanreadableSensorName = id => titleize(replaceAll(id, '_', ' '));

const getName = sensor => {
  const sensorName = sensor.sensorName.split('/', 2)[1];

  if (sensorName === undefined) {
    return sensor.sensorName;
  }

  return sensorName;
};

export default createReducerFromMapping(
  {
    [LOAD]: (state, action) => ({
      ...state,
    }),

    [LOAD_SUCCESS]: (state, action) => ({
      ...state,
      realSensors: _.assign(_.clone(state.realSensors), {
        [action.equipmentNumber || action.functionalLocation]: Object.assign(
          {},
          ...action.result.map(x => ({
            [x.sensorName]: humanreadableSensorName(getName(x)),
          }))
        ),
      }),
      categories: _.merge(
        {},
        state.categories,
        ...(action.result.length > 0
          ? action.result.map(x => ({
              [action.functionalLocation]: {
                [Group[getName(x)] || '']: {
                  [x.sensorName]: x,
                },
              },
            }))
          : [
              {
                [action.functionalLocation]: null,
              },
            ])
      ),
    }),

    [LOAD_FAIL]: (state, action) => ({
      ...state,
    }),
  },
  initialState
);
