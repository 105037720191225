import { createSelector } from 'reselect';

import { getFunctionalLocations, getLoadingFLs, getProfile, getActiveFilter } from './common';
import { formatOrderForCalendar, filterServiceOrders } from 'utils/Data/serviceOrders';

const EMPTY_ARRAY = [];

/**
 * Get search results for active section.
 * Expects the following parameters: section
 */
export const getSearchResults = (state, { section }) => state.serviceOrders.search[section]?.items ?? EMPTY_ARRAY;

/**
 * Get search results for the active section, merging orders with functional locations.
 * Expects the following parameters: section, loadingFLs
 */
export const getFormattedSearchResults = createSelector(
  [getSearchResults, getFunctionalLocations, getLoadingFLs, getProfile],
  (results, functionalLocations, loadingFLs, profile) =>
    results.map(result => formatOrderForCalendar(result, functionalLocations, profile, false, loadingFLs))
);

/**
 * Get filtered service order search results
 * Expects the following props: section, activeFilter, loadingFLs
 */
export const getFilteredSearchResults = createSelector(
  [getFormattedSearchResults, getActiveFilter],
  (results, activeFilter) => filterServiceOrders(results, activeFilter)
);

/**
 * Get all and filtered service order search results
 * Expects the following props: section, activeFilter, loadingFLs
 */
export const getAllAndFilteredSearchResults = createSelector(
  [getFormattedSearchResults, getFilteredSearchResults],
  (all, filtered) => ({ all, filtered })
);
