import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import translations from 'decorators/Translations/translations';
import moment from 'moment';
import PropTypes from 'prop-types';
import { flatMap, find, omit } from 'lodash';

import Button from 'components/Button/Button';
import { showModal } from 'redux/modules/modal/modal';
import { statuses, getStartDate, toISOFormat } from 'components/Announcement/utils';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import AnnouncementForm from 'components/Announcement/AnnouncementForm';
import AdminAnnouncementColumns from 'components/Announcement/AdminAnnouncementColumns';
import StandardPage from 'components/StandardPage/StandardPage';

import { updateGeneralAnnouncement } from 'redux/modules/announcement/announcement';
import { deleteGeneralAnnouncement } from 'redux/modules/announcement/announcement';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { NOTIFICATION_TIMEOUT } from 'constants/common';
import SnackBar from 'components/SnackBar/SnackBar';
import { filterSystemLevelAnnouncements } from 'components/Announcement/utils';
import { filterPartnerLevelAnnouncementsByPartnerNumber } from 'components/Announcement/utils';
import { announcementShape, ANNOUNCEMENT_TYPE } from 'components/Announcement/utils';
import { ANNOUNCEMENT_TYPE_NAME } from 'components/Announcement/AnnouncementForm';
import { canEditGeneralAnnouncements } from 'utils/Data/profileData';
import Header from 'containers/Application/Header/Header';
import { icons } from 'components/Icon/IconNames';
import FadeTransition from 'components/FadeTransition/FadeTransition';

const AddNew = styled(Button)`
  min-width: 200px;
  height: 38px;
  font-size: ${props => props.theme.font.size.xs};
  font-family: ${props => props.theme.font.family.sanchez};
`;
AddNew.displayName = 'AddNew';

const StyledErrorMessage = styled.h4`
  display: flex;
  justify-content: center;
  color: red;
  margin-top: 24px;
`;

const ErrorMessage = ({ t }) => <StyledErrorMessage>{t('Save Failed')}</StyledErrorMessage>;

export class AdminGeneralAnnouncements extends Component {
  state = {
    dialogOpen: false,
    addNew: false,
    loading: false,
    error: null,
    notificationType: null,
    notificationMessage: null,
    notificationVisible: false,
  };

  showNotification = notification => {
    this.setState({
      notificationType: notification.type,
      notificationMessage: notification.message,
      notificationVisible: true,
    });
  };

  hideNotification = () => {
    this.setState({
      notificationVisible: false,
    });
  };

  handleSubmit = () => {
    const { addNew, model } = this.state;
    const { t, updateGeneralAnnouncement, type } = this.props;

    const newModel = Object.assign({}, type === ANNOUNCEMENT_TYPE.PARTNER ? model : omit(model, 'partnerNumber'));
    newModel.startDate = getStartDate(newModel.startDate);
    newModel.expirationDate = toISOFormat(newModel.expirationDate);
    newModel.created = addNew ? moment.utc().toISOString() : newModel.created;
    newModel.modified = moment.utc().toISOString();

    this.setState({ loading: true });
    updateGeneralAnnouncement(newModel)
      .then(() => {
        this.setState({ loading: false }, () => {
          this.closeForm();
          const notification = {
            type: 'success',
            message: addNew ? t('Announcement created successfully') : t('Announcement updated successfully'),
          };
          this.showNotification(notification);
          setTimeout(() => {
            this.hideNotification();
          }, NOTIFICATION_TIMEOUT);
        });
      })
      .catch(error => this.setState({ error, loading: false }));
  };

  getPartner = () =>
    find(
      flatMap(this.props.customers, partner => ({ value: partner.partnerNumber, label: partner.name })),
      options => options.value === this.props.partnerNumber
    );

  openNewForm = () => {
    this.setState({
      dialogOpen: true,
      model: {
        title: '',
        description: '',
        created: {},
        startDate: moment.utc(),
        expirationDate: moment.utc().add(1, 'week'),
        status: statuses[0],
        partnerNumber: this.props.type === ANNOUNCEMENT_TYPE.PARTNER ? this.props.partnerNumber : null,
      },
      addNew: true,
    });
  };

  openEditForm = announcement =>
    this.setState({
      dialogOpen: true,
      model: {
        ...announcement,
        startDate: moment.utc(announcement.startDate),
        expirationDate: moment.utc(announcement.expirationDate),
      },
      addNew: false,
    });

  closeForm = () => {
    this.setState({ dialogOpen: false, addNew: false });
  };

  deleteAnnouncement = id => {
    return this.props
      .deleteGeneralAnnouncement(id)
      .then(() => {
        const notification = {
          type: 'success',
          message: this.props.t('Announcement deleted successfully'),
        };
        this.showNotification(notification);
        setTimeout(() => {
          this.hideNotification();
        }, NOTIFICATION_TIMEOUT);
        return Promise.resolve();
      })
      .catch(error => Promise.reject(new Error('Delete Announcement Failed')));
  };

  handleDeletion = ({ id, title }) => {
    this.props.showModal(
      MODALTYPE.CONFIRMATION_DELETE_ANNOUNCEMENT_MODAL,
      null,
      () => this.deleteAnnouncement(id),
      `${this.props.t('Announcement')} ${this.props.t('{0} will be deleted', title)}`
    );
  };

  handleFormChange = (property, value) => {
    this.setState({
      model: {
        ...this.state.model,
        [property]: value,
      },
    });
  };

  renderContent() {
    const { t, announcements, type, partnerNumber } = this.props;

    return (
      <Section>
        <SectionHeader
          t={t}
          title={
            type === ANNOUNCEMENT_TYPE.SYSTEM
              ? t(`Manage ${ANNOUNCEMENT_TYPE_NAME.SYSTEM_LEVEL} Announcements`)
              : t(`Manage ${ANNOUNCEMENT_TYPE_NAME.PARTNER_LEVEL} Announcements`)
          }
        >
          <AddNew
            data-test-id="LinkToCreateNewAnnouncement"
            onClick={this.openNewForm}
            color={this.props.theme.colors.emerald}
          >
            {t('Add New')}
          </AddNew>
        </SectionHeader>
        {this.state.dialogOpen && (
          <AnnouncementForm
            t={t}
            announcement={this.state.model}
            addNew={this.state.addNew}
            onChange={this.handleFormChange}
            onSubmit={this.handleSubmit}
            onClose={this.closeForm}
            errorMessage={this.state.error && <ErrorMessage t={t} />}
            loading={this.state.loading}
            type={type}
            showPartners={type === ANNOUNCEMENT_TYPE.PARTNER}
            partner={type === ANNOUNCEMENT_TYPE.PARTNER ? this.getPartner() : undefined}
          />
        )}
        <AdminAnnouncementColumns
          t={t}
          announcements={
            type === ANNOUNCEMENT_TYPE.SYSTEM
              ? filterSystemLevelAnnouncements(announcements)
              : filterPartnerLevelAnnouncementsByPartnerNumber(announcements, partnerNumber)
          }
          onEdit={this.openEditForm}
          onDestroy={this.handleDeletion}
        />
        <SnackBar variant={this.state.notificationType} visible={this.state.notificationVisible}>
          {this.state.notificationMessage}
        </SnackBar>
      </Section>
    );
  }

  render() {
    const { t, type, profile, isComponent } = this.props;

    if (!canEditGeneralAnnouncements(profile.role) && type === ANNOUNCEMENT_TYPE.SYSTEM) {
      return null;
    }

    if (isComponent) {
      return this.renderContent();
    }

    const links = [];
    type === ANNOUNCEMENT_TYPE.SYSTEM &&
      links.push({
        title: 'Back to admin homepage',
        icon: icons.ARROW_LEFT,
        to: { pathname: '/Admin' },
        smallIcon: true,
      });

    links.push({
      title: 'Manage general announcements',
      icon: icons.ADMIN_MANAGE_NOTIFICATIONS,
      pathname: '/Admin/Announcements',
    });

    return (
      <FadeTransition>
        <StandardPage>
          <Helmet title={t('Manage general announcements')} />
          <Header t={t} links={links} />
          {this.renderContent()}
        </StandardPage>
      </FadeTransition>
    );
  }
}

AdminGeneralAnnouncements.defaultProps = {
  customers: {},
  announcements: [],
  type: ANNOUNCEMENT_TYPE.SYSTEM,
  partnerNumber: '',
  isComponent: false,
};

AdminGeneralAnnouncements.propTypes = {
  announcements: PropTypes.arrayOf(
    PropTypes.oneOfType([announcementShape(ANNOUNCEMENT_TYPE.PARTNER), announcementShape(ANNOUNCEMENT_TYPE.SYSTEM)])
  ),
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  type: PropTypes.string,
  partnerNumber: PropTypes.string,
  profile: PropTypes.object.isRequired,
  isComponent: PropTypes.bool,
};

const mapStateToProps = state => ({
  announcements: state.announcement.general,
  customers: state.customer.customers,
  profile: state.profile.profile,
});

const mapDispatchToProps = dispatch => ({
  updateGeneralAnnouncement: announcement => dispatch(updateGeneralAnnouncement(announcement)),
  deleteGeneralAnnouncement: id => dispatch(deleteGeneralAnnouncement(id)),
  showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
    dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(withTheme(AdminGeneralAnnouncements)));
