export const sortFunctionalLocations = (a, b) => {
  // Unknown type always last
  if (a.type === 'UNKNOWN' && b.type !== 'UNKNOWN') {
    return 1;
  }
  if (a.type !== 'UNKNOWN' && b.type === 'UNKNOWN') {
    return -1;
  }

  // Sort primarily by FL level
  const levelDiff = a.functionalLocation.split('-').length - b.functionalLocation.split('-').length;
  if (levelDiff) {
    return levelDiff;
  }

  return (a.description || a.functionalLocation).localeCompare(b.description || b.functionalLocation);
};
