import styled from 'styled-components';
import { transparentize } from 'polished';

const SimpleDropdown = styled.div`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: 4px;
  box-shadow: 0 2px 10px ${props => transparentize(0.9, props.theme.colors.black)};
  align-items: flex-end;
  flex-direction: column;
  max-height: 25em;
  width: ${props => props.width};
  overflow-y: auto;

  &::before {
    content: '';
    position: absolute;
    top: -9px;
    right: ${props => (!props.left ? '37px' : 'auto')};
    left: ${props => (props.left ? '16px' : 'auto')};
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${props => props.theme.colors.lightGray};

    ${props => props.theme.media.landscape`
            right: ${props => (!props.left ? '37px' : 'auto')};
            left: ${props => (props.left ? '25%' : 'auto')};
        `}
  }

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    right: ${props => (!props.left ? '37px' : 'auto')};
    left: ${props => (props.left ? '16px' : 'auto')};
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${props => props.theme.colors.white};

    ${props => props.theme.media.landscape`
            left: ${props => (props.left ? '25%' : 'auto')};
        `}
  }
`;

SimpleDropdown.displayName = 'SimpleDropdown';

export default SimpleDropdown;
