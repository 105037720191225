import { IoT } from '@caverion/redux/api/actions';
import { createReducerFromMapping } from 'redux/utils/index.js';
import moment from 'moment-timezone';
import { map } from 'lodash';

const initialState = {
  loading: false,
  utilizationRateChartValues: {},
};

export const LOAD_UTILIZATION_RATE_CHART_VALUES = 'CUSTOMER_PLATFORM/IoT/LOAD_UTILIZATION_RATE_CHART_VALUES';
export const LOAD_UTILIZATION_RATE_CHART_VALUES_SUCCESS =
  'CUSTOMER_PLATFORM/IoT/LOAD_UTILIZATION_RATE_CHART_VALUES_SUCCESS'; // eslint-disable-line max-len
export const LOAD_UTILIZATION_RATE_CHART_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT/LOAD_UTILIZATION_RATE_CHART_VALUES_FAIL';

export const loadUtilizationRateChartValues = (
  sensorIds,
  sensorGroups,
  startTime,
  endTime,
  utilizationHours,
  isArea
) => {
  const filter = {
    where: {
      sensorId: { inq: sensorIds },
      timestamp: { between: [startTime, endTime] },
      aggregation: 'hourlyUtilizationRate',
    },
  };

  // omit irrelevant fields
  const simpleGroups = map(sensorGroups, group =>
    group.id ? group.id : { name: group.name, sensorIds: group.sensorIds || map(group.sensors, 'id') }
  );
  const timeZone = moment.tz.guess();

  return async dispatch => {
    dispatch({ type: LOAD_UTILIZATION_RATE_CHART_VALUES });
    try {
      const result = await dispatch(
        IoT.utilizationRateChartValues(filter, simpleGroups, utilizationHours, timeZone, isArea)
      );

      return dispatch({
        type: LOAD_UTILIZATION_RATE_CHART_VALUES_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_UTILIZATION_RATE_CHART_VALUES_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_UTILIZATION_RATE_CHART_VALUES]: (state, action) => ({
      ...state,
      loading: true,
      utilizationRateChartValues: {},
    }),
    [LOAD_UTILIZATION_RATE_CHART_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      utilizationRateChartValues: action.result,
    }),
    [LOAD_UTILIZATION_RATE_CHART_VALUES_FAIL]: (state, action) => ({
      ...state,
      loading: false,
    }),
  },
  initialState
);
