import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import pullAt from 'lodash/pullAt';
import without from 'lodash/without';
import omit from 'lodash/omit';
import { asArray } from '@caverion/loopback-shared/lb4/utils/common';

import { Button, InputRow, InputForm, ErrorText, Infotip } from 'components/index.js';
import Loader from 'components/Loader/Loader';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import Section from 'components/Section/Section';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import {
  isAdminRole,
  isAdminOrCaverionUserRole,
  isCaverionAdminRole,
  isCaverionAdminOrUserRole,
} from 'utils/Data/profileData';
import {
  loadPermissionsHierarchy,
  loadCustomers,
  searchPartnersByIds,
  searchFunctionalLocationsByPartners,
  resetSearch,
  addLoading,
  removeLoading,
  searchUsername,
  getPortfolioCounts,
  clearEditProfile,
  deleteProfile,
  showGlobalNotification,
  loadFunctionalLocations,
} from 'redux/modules';
import SnackBar from 'components/SnackBar/SnackBar';
import Permissions from './Permissions/Permissions';
import Features from './Features/Features';
import Settings from './Settings/Settings';
import CustomViews from './CustomViews/CustomViews';
import { getPartnerNumbers, deriveEditedPermissions, getAllPermissionsFromModel } from 'utils/profile';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { showModal } from 'redux/modules/modal/modal';
import Api from './Api/Api';
import AddPermissions from './AddPermissions/AddPermissions';

const TOOLTIP_HIDE_DELAY = 5000;
const LOADING_RESULTS = 'results';

const tabOptions = (t, showPermissions, showApi) => {
  const tabs = [
    { label: t('Profile & Settings'), value: 0 },
    { label: t('Features'), value: 1 },
    { label: t('Permissions'), value: 2 },
    { label: t('Custom Views'), value: 3 },
  ];
  !showPermissions && pullAt(tabs, 2);
  if (showApi) {
    tabs.push({ label: t('API'), value: 4 });
  }

  return tabs;
};

const Container = styled.div`
  width: 100%;
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  margin: 0 auto;
`;

const StyledAdminOrCaverionUser = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
`;

const SectionTitle = styled.h2`
  padding: ${props => props.theme.spacing.md} 0;
  font-size: ${props => props.theme.font.size.lg};
`;

const StyledErrorText = styled.div`
  margin: ${props => props.theme.spacing.md} 0;
`;

const SnackBarButtonContainer = styled.div`
  display: flex;
`;

const SnackBarButtonSpacer = styled.div`
  width: ${props => props.theme.spacing.md};
`;

class UserForm extends Component {
  state = {
    tooltip: undefined,
    selectedTab: 0,
  };

  componentDidMount() {
    if (this.props.profile.role === 'customer-admin') {
      this.props.searchPartnersByIds(this.props.profile.adminPartnerNumbers || []);
    }
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    // Update pre-selected partner numbers for customer-admin.
    if (
      this.props.profile.role === 'customer-admin' &&
      prevProps.profile.adminPartnerNumbers !== this.props.profile.adminPartnerNumbers
    ) {
      this.props.searchPartnersByIds(this.props.profile.adminPartnerNumbers || []);
    }

    if (this.props.customViews !== prevProps.customViews) {
      // Load FL:s in custom views
      const customViews =
        (this.props.model && this.props.model.id && this.props.customViews[this.props.model.id]) || [];
      const FLs = compact(uniq(customViews.flatMap(view => view.functionalLocations), 'functionalLocations'));

      // Load FLs and customers for normal users (admins have already loaded customers)
      if (profile && !isCaverionAdminOrUserRole(profile.role)) {
        this.props.loadFunctionalLocations(FLs);
        const customers = uniq(customViews.flatMap(view => view.customers));
        this.props.loadCustomers(customers);
      } else {
        // Load FLs for admins
        this.props.loadPermissionsHierarchy(FLs);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearEditProfile();
    this.props.resetSearch();
    clearTimeout(this.hideTooltipTimer);
  }

  addFunctionalLocation = (partnerNumber, functionalLocations) => () => {
    const {
      model: { addedFunctionalLocations = {} },
    } = this.props;
    const flArray = asArray(functionalLocations);

    // Load information needed in order to show the FL(s) in permissions list
    this.props.loadPermissionsHierarchy(flArray);

    // Add functional location to partner permissions

    const addedForPartner = addedFunctionalLocations[partnerNumber] || [];
    this.props.setProperty('addedFunctionalLocations', {
      ...addedFunctionalLocations,
      [partnerNumber]: addedForPartner.concat(flArray),
    });

    return false;
  };

  deleteFunctionalLocation = (functionalLocation, partner) => () => {
    const {
      model: { addedFunctionalLocations = {}, deletedFunctionalLocations = {} },
    } = this.props;

    const deleted = deletedFunctionalLocations[partner]?.includes(functionalLocation);
    const newlyAdded = addedFunctionalLocations[partner]?.includes(functionalLocation);

    // Re-deleting item that is marked for deletion => don't delete
    if (deleted) {
      const editedDeletedFls = without(deletedFunctionalLocations[partner], functionalLocation);
      this.props.setProperty('deletedFunctionalLocations', {
        ...deletedFunctionalLocations,
        [partner]: editedDeletedFls,
      });
    }
    // Deleting newly added => remove from added and profile permissions
    else if (newlyAdded) {
      const editedAddedFls = without(addedFunctionalLocations[partner], functionalLocation);
      this.props.setProperty('addedFunctionalLocations', { ...addedFunctionalLocations, [partner]: editedAddedFls });
    } else {
      const deletedForPartner = deletedFunctionalLocations[partner] || [];
      this.props.setProperty('deletedFunctionalLocations', {
        ...deletedFunctionalLocations,
        [partner]: deletedForPartner.concat([functionalLocation]),
      });
    }

    return false;
  };

  deletePartnerFunctionalLocations = partnerNumber => () => {
    const {
      model,
      model: { permissions = {}, addedFunctionalLocations = {}, deletedFunctionalLocations = {} },
      setProperty,
    } = this.props;

    const functionalLocations = getAllPermissionsFromModel(model)[partnerNumber] || [];
    const deleted = functionalLocations.every(fl => deletedFunctionalLocations[partnerNumber]?.includes(fl));

    if (deleted) {
      setProperty('deletedFunctionalLocations', {
        ...deletedFunctionalLocations,
        [partnerNumber]: [],
      });
    } else {
      setProperty('addedFunctionalLocations', omit(addedFunctionalLocations, [partnerNumber]));

      // Mark as deleted only if previously added
      if (permissions[partnerNumber]) {
        setProperty('deletedFunctionalLocations', {
          ...deletedFunctionalLocations,
          [partnerNumber]: functionalLocations,
        });
      }
    }
  };

  deletePartnerPermissions = partner => () => {
    const {
      setProperty,
      model: { partnerPermissions = [], addedPartnerPermissions = [], deletedPartnerPermissions = [] },
    } = this.props;

    const previouslyAdded = partnerPermissions?.includes(partner);
    const deleted = deletedPartnerPermissions?.includes(partner);
    if (deleted) {
      setProperty('deletedPartnerPermissions', without(deletedPartnerPermissions, partner));
    } else {
      setProperty('addedPartnerPermissions', without(addedPartnerPermissions, partner));
      if (previouslyAdded) {
        setProperty('deletedPartnerPermissions', [...deletedPartnerPermissions, partner]);
      }
    }
  };

  addPartnerPermission = partnerNumber => () => {
    const {
      setProperty,
      model: { addedPartnerPermissions = [], deletedPartnerPermissions = [] },
    } = this.props;

    setProperty('addedPartnerPermissions', [...addedPartnerPermissions, partnerNumber]);
    setProperty('deletedPartnerPermissions', without(deletedPartnerPermissions, partnerNumber));
  };

  submit = () => {
    const { model, submit } = this.props;
    const profile = {
      ...model,
      ...deriveEditedPermissions(model),
    };

    const partnerNumbers = getPartnerNumbers(profile);

    // If user role is set to customer-admin, populate administrated partner numbers.
    if (profile.role === 'customer-admin') {
      profile.adminPartnerNumbers = partnerNumbers;
    }

    // Prevent saving profile without partners.
    if (partnerNumbers.length === 0) {
      this.setState({ tooltip: 'Please add at least one permission for the user.' });
      clearInterval(this.hideTooltipTimer);
      this.hideTooltipTimer = setTimeout(() => this.setState({ tooltip: undefined }), TOOLTIP_HIDE_DELAY);
      return;
    }

    this.setState({ tooltip: undefined });
    submit(profile);
  };

  loadFunctionalLocations = (partners, types = this.props.types) => {
    // Clear search if partners are not given.
    if (!partners) {
      this.props.resetSearch(this.props.profile.role === 'customer-admin');
    } else {
      this.props.addLoading(LOADING_RESULTS);
      this.props.searchFunctionalLocationsByPartners(partners, types).then(
        () => {
          this.props.removeLoading(LOADING_RESULTS);
        },
        () => this.props.removeLoading(LOADING_RESULTS)
      );
    }
  };

  checkExistingUsername(model) {
    const username = model?.username;
    if (!this.props.newProfile || !username) {
      return;
    }
    this.props.searchUsername(username);
  }

  handleDeleteClick = event => {
    event.preventDefault();

    const { model, history, showModal, deleteProfile, showGlobalNotification, t, profile } = this.props;
    showModal(
      MODALTYPE.CONFIRMATION_DELETE_USER,
      null,
      () =>
        deleteProfile(model.id).then(result => {
          if (result.error) {
            showGlobalNotification(t('User deletion failed.'), 'error');
          } else if (profile.id === model.id) {
            history.push('/Logout');
          } else {
            history.push('/Admin');
            showGlobalNotification(t('User has been deleted.'), 'success');
          }
        }),
      `${t('User')} ${t('{0} will be deleted', model.username)}`
    );
  };

  render() {
    const {
      t,
      model,
      functionalLocations,
      profile,
      newProfile,
      loading,
      loadingPermissions,
      usernameExists,
      customers,
      portfolioCounts,
      customViews,
      functionalLocationsForUser,
    } = this.props;

    const { tooltip, selectedTab } = this.state;

    const isAdmin = profile && isAdminRole(profile.role);
    const isCaverionAdmin = profile && isCaverionAdminRole(profile.role);
    const isCaverionAdminOrUser = profile && isCaverionAdminOrUserRole(profile.role);
    const isAdminOrCaverionUser = profile && isAdminOrCaverionUserRole(profile.role);
    const invalidUsername = (newProfile && model && model.username && usernameExists[model.username]) || false;
    const hasApi = model && model.features.api;

    const error = this.props.error && (
      <StyledErrorText>
        <ScrollToComponent key={this.props.error} />
        <ErrorText>{t(this.props.error)}</ErrorText>
      </StyledErrorText>
    );

    const showSnackbar = model && model.edited && !loadingPermissions && !invalidUsername;

    return (
      <Container>
        <SectionHeader t={t}>
          <SectionTabSelector
            left
            large
            t={t}
            options={tabOptions(t, isAdminOrCaverionUser, hasApi)}
            model={{ selectedTab }}
            property="selectedTab"
            onTabChange={(property, value) => this.setState({ selectedTab: value })}
          />
        </SectionHeader>
        {error}
        <InputForm id={this.props.id} model={model} onPropertyChange={this.props.setProperty} onSubmit={this.submit}>
          {selectedTab === 0 && (
            <Settings
              t={t}
              model={model}
              isCaverionAdminOrUser={isCaverionAdminOrUser}
              isAdmin={isAdmin}
              isCaverionAdmin={isCaverionAdmin}
              onChange={this.props.setProperty}
              newProfile={newProfile}
              handleDeleteClick={this.handleDeleteClick}
              usernameExists={usernameExists}
            />
          )}
          {selectedTab === 1 && (
            <Features
              t={t}
              model={model}
              isCaverionAdminOrUser={isCaverionAdminOrUser}
              onChange={this.props.setProperty}
            />
          )}
          {isAdminOrCaverionUser && selectedTab === 2 && (
            <StyledAdminOrCaverionUser>
              <AddPermissions
                loadFunctionalLocations={this.loadFunctionalLocations}
                loadingFunctionalLocations={loading.includes(LOADING_RESULTS)}
                model={model}
                portfolioCounts={portfolioCounts}
                customers={customers}
                addFunctionalLocation={this.addFunctionalLocation}
                deleteFunctionalLocation={this.deleteFunctionalLocation}
                addPartnerPermission={this.addPartnerPermission}
              />
              <Section>
                <SectionTitle id="permissions" data-test-id="Permissions">
                  {t('Existing Permissions')}
                </SectionTitle>
                <InputRow fullRow>
                  {tooltip && <ScrollToComponent key={tooltip} />}
                  {loadingPermissions && (
                    <div style={{ marginTop: '1em' }}>
                      <Loader />
                    </div>
                  )}
                  {model && !loadingPermissions && (
                    <Permissions
                      t={t}
                      partnerPermissions={model.partnerPermissions || EMPTY_ARRAY}
                      addedFunctionalLocations={model.addedFunctionalLocations || EMPTY_OBJECT}
                      deletedFunctionalLocations={model.deletedFunctionalLocations || EMPTY_OBJECT}
                      addedPartnerPermissions={model.addedPartnerPermissions || EMPTY_ARRAY}
                      deletedPartnerPermissions={model.deletedPartnerPermissions || EMPTY_ARRAY}
                      permissions={model.permissions || EMPTY_OBJECT}
                      inactivePermissions={model.inactivePermissions || EMPTY_OBJECT}
                      functionalLocations={functionalLocations}
                      customers={customers}
                      portfolioCounts={portfolioCounts}
                      deleteFunctionalLocation={this.deleteFunctionalLocation}
                      deletePartnerFunctionalLocations={this.deletePartnerFunctionalLocations}
                      deletePartnerPermissions={this.deletePartnerPermissions}
                    />
                  )}
                </InputRow>
              </Section>
            </StyledAdminOrCaverionUser>
          )}
          {selectedTab === 3 && (
            <CustomViews
              t={t}
              model={model}
              onChange={this.props.setProperty}
              customViews={(model && model.id && customViews[model.id]) || []}
              customers={this.props.customers}
              functionalLocations={Object.assign({}, functionalLocationsForUser, functionalLocations)}
            />
          )}
          {selectedTab === 4 && (
            <Api t={t} profileId={this.props.profileId} showModal={this.props.showModal} profile={profile} />
          )}
          <SnackBar
            visible={showSnackbar}
            variant="confirmation"
            hideDelay={1000}
            primaryContent={
              <div>
                {!this.props.saving && !this.props.saved && t('Save your changes')}
                {this.props.saving && `${t('Saving')}...`}
                {this.props.saved && t('Profile successfully saved')}
              </div>
            }
            secondaryContent={
              <SnackBarButtonContainer>
                {tooltip && (
                  <Infotip text={t(tooltip)} alwaysVisible position="left" textAlign="left">
                    <SnackBarButtonSpacer />
                  </Infotip>
                )}
                <Button submit>
                  {!this.props.saving && !this.props.saved && t('Save')}
                  {this.props.saving && <Loader color="WHITE" size="SMALL" />}
                  {this.props.saved && t('Saved')}
                </Button>
              </SnackBarButtonContainer>
            }
          />
        </InputForm>
      </Container>
    );
  }
}

const COMPONENT = 'UserForm';
const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const mapStateToProps = (state, props) => ({
  usernameExists: state.profile.usernameExists,
  profile: state.profile.profile,
  customerHierarchy: state.customer.hierarchy,
  totalResults: state.customer.totalResults,
  functionalLocations: state.customer.functionalLocations,
  functionalLocationsForUser: state.functionalLocations.functionalLocations,
  types: state.customer.typesToSearch,
  customers: state.customer.customers,
  error: state.profile.error,
  loading: state.common.loading[COMPONENT] || EMPTY_ARRAY,
  portfolioCounts: state.customer.portfolioCounts,
  customViews: state.customView.customViewsByUser,
});

const mapDispatchToProps = dispatch => ({
  searchPartnersByIds: ids => dispatch(searchPartnersByIds(ids)),
  searchFunctionalLocationsByPartners: (partners, types) =>
    Promise.all([
      dispatch(searchFunctionalLocationsByPartners(partners, types)),
      dispatch(loadCustomers(partners)),
      dispatch(getPortfolioCounts(partners)),
    ]),
  loadPermissionsHierarchy: functionalLocations => dispatch(loadPermissionsHierarchy(functionalLocations)),
  loadFunctionalLocations: functionalLocations => dispatch(loadFunctionalLocations(functionalLocations)),
  loadCustomers: customers => dispatch(loadCustomers(customers)),
  searchUsername: username => dispatch(searchUsername(username)),
  resetSearch: leaveOptions => dispatch(resetSearch(leaveOptions)),
  addLoading: key => dispatch(addLoading(key, COMPONENT)),
  removeLoading: key => dispatch(removeLoading(key, COMPONENT)),
  clearEditProfile: () => dispatch(clearEditProfile()),
  showModal: (type, preConditions, onSubmitAction, passedProps) =>
    dispatch(showModal(type, preConditions, onSubmitAction, passedProps)),
  deleteProfile: profileId => dispatch(deleteProfile(profileId)),
  showGlobalNotification: (message, type) => dispatch(showGlobalNotification(message, type)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(withRouter(UserForm));
