import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    valuesBySensorId: state.values.sensors.valuesBySensorId,
    latestValuesBySensorId: state.values.sensors.latestValuesBySensorId,
  };
};

const connector = connect(mapStateToProps);

export default connector;
