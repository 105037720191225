import PropTypes from 'prop-types';
import { flatten, values } from 'lodash';

import { OrderType, STATISTICS_TYPES } from 'constants/serviceCalendar';

export const StatisticsType = PropTypes.oneOf(flatten(values(STATISTICS_TYPES)));

export const StatisticsOptions = PropTypes.shape({
  visible: PropTypes.bool.isRequired,
  orderType: PropTypes.oneOf([OrderType.ORDER, OrderType.PLANNED]),
  statisticsType: StatisticsType,
});

export const GroupedServiceOrders = PropTypes.shape({
  [OrderType.ORDER]: PropTypes.arrayOf(PropTypes.object).isRequired,
  [OrderType.PLANNED]: PropTypes.arrayOf(PropTypes.object).isRequired,
});

export const PartnerMeta = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
});
