import styled, { css } from 'styled-components';
import { rgba, stripUnit } from 'polished';

import { getDesktopAndLandscapeStyles } from './OPICards';

export const Card = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing.xs};
  color: ${props => props.theme.colors.midnight};
  background-color: ${props => (props.isLoading ? props.theme.colors.mystic : props.theme.colors.white)};
  font-family: ${props => props.theme.font.sanchez};
  border: 1px solid ${props => props.theme.colors.lightGray};
  text-align: center;
  flex: 1;
  height: 155px;
  width: 155px;
  margin: 0 ${props => -stripUnit(props.theme.grid.gutter) / 2 + 'rem'};

  ${props => props.theme.media.portrait`
        justify-content: flex-start;
        width: auto;
        height: 165px;
        padding: ${props => props.theme.spacing.md};
        margin: 0 ${props => -stripUnit(props.theme.grid.gutter) / 2 + 'rem'};
    `};
  ${props =>
    getDesktopAndLandscapeStyles(
      props,
      css`
        flex-direction: row;
        margin: ${props.theme.spacing.sm} 0;
        padding: ${props.theme.spacing.xl} ${props.dense ? props.theme.spacing.xs : props.theme.spacing.md};
        border: none;
        border-radius: 0.25rem;
        box-shadow: ${!props.loading && `0 2px 5px ${rgba(props.theme.colors.black, 0.1)}`};
        text-align: left;
        height: ${props.dense ? '80px' : '102px'};
      `
    )};
  top: 0;
  ${props =>
    !props.noCursor &&
    css`
      cursor: pointer;

      ${props => props.theme.media.landscape`
            &:hover {
                top: -4px;
                box-shadow: ${props => props.theme.shadows.opiCard};
            }
        `}
    `};
  transition: ${props => props.theme.motion.easeIn} ${props => props.theme.motion.quick};
`;
