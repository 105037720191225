import React from 'react';
import PropTypes from 'prop-types';
import { flatten, values, filter, includes } from 'lodash';
import styled from 'styled-components';

import {
  OrderType,
  STATISTICS_TYPES,
  STATISTICS_ORDER_VOLUMES_BY_BUILDING,
  STATISTICS_PLANNED_VOLUMES_BY_BUILDING,
  STATISTICS_ORDER_REACTION_TIME_HOURS,
  STATISTICS_ORDER_DOWNTIME_HOURS,
  STATISTICS_ORDER_LEAD_TIME_HOURS,
  STATISTICS_ORDER_REACTION_TIME_PERCENTS,
  STATISTICS_ORDER_DOWNTIME_PERCENTS,
  STATISTICS_ORDER_LEAD_TIME_PERCENTS,
  STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT,
} from 'constants/serviceCalendar';
import InputLabel from 'components/Form/InputLabel';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.sm};

  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme.spacing.sm};
  flex-direction: column;

  ${props => props.theme.media.portrait`
        flex-direction: row;
    `}

  ${props => props.theme.media.landscape`
        margin: 0;
        margin-left: ${props => props.theme.spacing.md};
    `}
`;

const DropdownWrapper = styled.div`
  width: 230px;
  margin-left: ${props => props.theme.spacing.md};
`;

const SLAPercentTypes = [
  STATISTICS_ORDER_REACTION_TIME_PERCENTS,
  STATISTICS_ORDER_DOWNTIME_PERCENTS,
  STATISTICS_ORDER_LEAD_TIME_PERCENTS,
];

const SLATypes = [
  STATISTICS_ORDER_REACTION_TIME_HOURS,
  STATISTICS_ORDER_DOWNTIME_HOURS,
  STATISTICS_ORDER_LEAD_TIME_HOURS,
  ...SLAPercentTypes,
];

const StatisticsHeader = ({
  options,
  setStatisticsType,
  t,
  hideBuildingStats,
  hideBreakdownStats,
  hideEquipmentStats,
  hideBreakdownPercentStats,
}) => {
  let statisticsTypeOptions = STATISTICS_TYPES[options.orderType].map(type => ({
    label: t(type),
    value: type,
  }));

  // If we are on building page, we don't want to show stats per building
  if (hideBuildingStats) {
    statisticsTypeOptions = filter(
      statisticsTypeOptions,
      option =>
        option.value !== STATISTICS_ORDER_VOLUMES_BY_BUILDING && option.value !== STATISTICS_PLANNED_VOLUMES_BY_BUILDING
    );
  }

  if (hideEquipmentStats) {
    statisticsTypeOptions = filter(
      statisticsTypeOptions,
      option => option.value !== STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT
    );
  }

  if (hideBreakdownStats) {
    statisticsTypeOptions = filter(statisticsTypeOptions, option => !includes(SLATypes, option.value));
  } else if (hideBreakdownPercentStats) {
    statisticsTypeOptions = filter(statisticsTypeOptions, option => !includes(SLAPercentTypes, option.value));
  }

  return (
    <Header>
      <h3>{t(options.statisticsType)}</h3>
      <SelectWrapper>
        <InputLabel text={t('Statistics type')} />
        <DropdownWrapper>
          <InputSelectDropdown
            onChange={(_, type) => setStatisticsType(type)}
            options={statisticsTypeOptions}
            placeholder={t(options.statisticsType)}
            t={t}
            model={options}
            property="statisticsType"
            clearable={false}
          />
        </DropdownWrapper>
      </SelectWrapper>
    </Header>
  );
};

StatisticsHeader.propTypes = {
  options: PropTypes.shape({
    orderType: PropTypes.oneOf([OrderType.ORDER, OrderType.PLANNED]),
    statisticsType: PropTypes.oneOf(flatten(values(STATISTICS_TYPES))),
  }).isRequired,
  setStatisticsType: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  hideBuildingStats: PropTypes.bool,
  hideEquipmentStats: PropTypes.bool,
  hideBreakdownStats: PropTypes.bool,
  hideBreakdownPercentStats: PropTypes.bool,
};

export default StatisticsHeader;
