import React from 'react';
import styled from 'styled-components';

const StyledTitleColumn = styled.div`
  position: relative;
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
`;

const Title = styled.div`
  font-size: ${props => props.theme.fontSize.sm};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const Asterisk = styled.span`
  line-height: ${props => props.theme.fontSize.xxxs};
  font-size: ${props => props.theme.fontSize.md};
`;

const TitleColumn = props => {
  const { value } = props.data;

  return (
    <StyledTitleColumn>
      <Title>{value ? value : <Asterisk>&#8270;</Asterisk>}</Title>
    </StyledTitleColumn>
  );
};

export default TitleColumn;
