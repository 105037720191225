export const OrderType = {
  ORDER: 'ORDER_TYPE_ORDER',
  PLANNED: 'ORDER_TYPE_PLANNED',
};

export const STATISTICS_ORDER_REACTION_TIME_HOURS = 'STATISTICS_ORDER_REACTION_TIME_HOURS';
export const STATISTICS_ORDER_DOWNTIME_HOURS = 'STATISTICS_ORDER_DOWNTIME_HOURS';
export const STATISTICS_ORDER_LEAD_TIME_HOURS = 'STATISTICS_ORDER_LEAD_TIME_HOURS';
export const STATISTICS_ORDER_REACTION_TIME_PERCENTS = 'STATISTICS_ORDER_REACTION_TIME_PERCENTS';
export const STATISTICS_ORDER_DOWNTIME_PERCENTS = 'STATISTICS_ORDER_DOWNTIME_PERCENTS';
export const STATISTICS_ORDER_LEAD_TIME_PERCENTS = 'STATISTICS_ORDER_LEAD_TIME_PERCENTS';
export const STATISTICS_ORDER_PERFORMANCE_RATIO = 'STATISTICS_ORDER_PERFORMANCE_RATIO';
export const STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE = 'STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE';
export const STATISTICS_ORDER_VOLUMES_BY_BUILDING = 'STATISTICS_ORDER_VOLUMES_BY_BUILDING';
export const STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT = 'STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT';

export const STATISTICS_PLANNED_PERFORMANCE_RATIO = 'STATISTICS_PLANNED_PERFORMANCE_RATIO';
export const STATISTICS_PLANNED_VOLUMES_BY_BUILDING = 'STATISTICS_PLANNED_VOLUMES_BY_BUILDING';
export const STATISTICS_PLANNED_VOLUMES_BY_PROVIDER = 'STATISTICS_PLANNED_VOLUMES_BY_PROVIDER';
export const STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE = 'STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE';

export const STATISTICS_TYPES = {
  [OrderType.ORDER]: [
    STATISTICS_ORDER_PERFORMANCE_RATIO,
    STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE,
    STATISTICS_ORDER_VOLUMES_BY_BUILDING,
    STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT,
    STATISTICS_ORDER_DOWNTIME_HOURS,
    STATISTICS_ORDER_LEAD_TIME_HOURS,
    STATISTICS_ORDER_REACTION_TIME_HOURS,
    STATISTICS_ORDER_DOWNTIME_PERCENTS,
    STATISTICS_ORDER_LEAD_TIME_PERCENTS,
    STATISTICS_ORDER_REACTION_TIME_PERCENTS,
  ],
  [OrderType.PLANNED]: [
    STATISTICS_PLANNED_PERFORMANCE_RATIO,
    STATISTICS_PLANNED_VOLUMES_BY_BUILDING,
    STATISTICS_PLANNED_VOLUMES_BY_PROVIDER,
    STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE,
  ],
};

export const FILTERABLE_FIELDS = ['status', 'type', 'location', 'address', 'discipline', 'priority', 'source'];

export const OrderStatus = {
  OPEN: 'Open',
  OVERDUE: 'Late',
  COMPLETED_ON_TIME: 'Completed on time',
  COMPLETED_EARLY: 'Completed early',
  COMPLETED_LATE: 'Completed late',
  IN_PROGRESS: 'In Progress',
  PARTLY_COMPLETED: 'Partly Completed',
  COMPLETED: 'Completed',
  POSTPONED: 'Postponed',
  PLANNED: 'Planned',
  DELAYED: 'Postponed',
  RELEASED: 'Released',
  STARTED: 'Started',
  ARRIVED: 'Arrived',
};

export const SyntheticOrderStatus = {
  UNFINISHED: 'Open / Unfinished',
  COMPLETED_OTHER: 'Completed, other',
};

export const ViewMode = {
  LIST: 'list',
  CALENDAR: 'calendar',
};

export const ExternalType = {
  SAP: 'sap',
  TAMPUURI: 'tampuuri',
};

export const DEFAULT_PRIORITY = 4;

export const SLA_META_KEY_REACTION_TIME = 'SLA_reactionTime_prio';
export const SLA_META_KEY_DOWNTIME = 'SLA_downtime_prio';
export const SLA_META_KEY_LEAD_TIME = 'SLA_leadTime_prio';

/**
 * Service order breakdown fields have been changing, so this mapping is used when orders are processed.
 */
export const BreakdownFields = {
  REACTION_TIME: 'reactionTime',
  DOWNTIME: 'breakdownTime',
  LEAD_TIME: 'downtime',
};

export const GROUP_UNKNOWN = 'UNKNOWN';

export const CONFIGURATION_PROPERTY = {
  [OrderType.ORDER]: 'serviceOrderListConfiguration',
  [OrderType.PLANNED]: 'plannedMaintenanceListConfiguration',
};
export const DEFAULT_COLUMNS = {
  [OrderType.ORDER]: ['title', 'createdDate', 'modifiedDate', 'location', 'link'],
  [OrderType.PLANNED]: ['title', 'modifiedDate', 'plannedDate', 'location', 'link'],
};
