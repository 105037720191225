import { resolveFunctionalLocationType } from 'redux/modules/index.js';
import includes from 'lodash/includes';
import findLast from 'lodash/findLast';
import last from 'lodash/last';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import compact from 'lodash/compact';
import moment from 'moment';

import {
  isServiceOrdersEnabled,
  isTechnicalTabEnabled,
  isEquipmentEnabled,
  isConditionsEnabled,
  canEditBuilding,
  canEditPartner,
  isPartnerRecyclingEnabled,
} from 'utils/Data/profileData';
import { getEnabledTabs, getEnabledCustomViews } from 'utils/Data/features';
import { isValidPartner } from 'utils/Data/partners';
import { getHeaderImageUrl, variants } from './images';
import { icons } from 'components/Icon/IconNames';
import { VIEW_TYPES, getIcon } from 'containers/Application/Admin/CustomViews/utils';

export const disciplines = ['AUT', 'AVI', 'COO', 'ELE', 'HEA', 'PLU', 'SEC', 'VEN'];

export const flIcons = {
  BE: 'Customer',
  BU: 'fa-building-o',
  UN: 'ic-tenant',
  TO: 'fa-cubes',
  IN: 'fa-industry',
  AUT: 'Automation',
  AVI: 'AV',
  COO: 'Cooling',
  ELE: 'Electric',
  HEA: 'Heating',
  PLU: 'Plumbing',
  SEC: 'Security',
  VEN: 'Ventilation',
  EXT: 'ICON_EXT',
  EQ: 'Equipment',
  SO: 'Maintenance',
  OVERVIEW: 'Customer',
  EQUIPMENT: 'fa-cube',
  UNKNOWN: 'unknown-fl',
};

export const disciplineIcons = {
  AUT: 'Automation',
  AVI: 'AV',
  COO: 'Cooling',
  ELE: 'Electric',
  HEA: 'Heating',
  PLU: 'Plumbing',
  SEC: 'Security',
  VEN: 'Ventilation',
  EXT: 'ICON_EXT',
};

export const getParentFunctionLocations = (functionalLocations, path, functionalLocationId, results = []) => {
  if (!path) {
    return results;
  }

  const parent = functionalLocations[path[path.indexOf(functionalLocationId) - 1]];
  if (!parent || !parent.functionalLocation) {
    return results;
  }

  results.push(parent);
  return getParentFunctionLocations(functionalLocations, parent.path, parent.functionalLocation, results);
};

export const getHeroContext = (parents, partnerNumber, features, customViews) => {
  if (parents && compact(parents).length > 0) {
    return {
      contextLinks: parents.map(parent => {
        if (
          !parent ||
          (!parent.equipmentNumber &&
            isValidPartner(partnerNumber) &&
            !includes(parent.partnerNumberWithParents, partnerNumber))
        ) {
          return {
            context: '',
            contextLink: '',
            type: '',
          };
        }
        let type = parent.equipmentNumber ? 'EQ' : resolveFunctionalLocationType(parent);
        const enabledTabs = getEnabledTabs(
          features,
          type,
          getEnabledCustomViews(customViews, { functionalLocation: parent.functionalLocation })
        );
        let tab = type !== 'TO' ? 'Technical' : 'Systems';
        if (type === 'SYSTEM') {
          const discipline = last(parent.functionalLocation.split('-'));
          type = disciplines.indexOf(discipline) !== -1 ? discipline : 'EXT';
          tab = 'Equipment';
        }

        // Check if wanted tab is enabled for user and if not, just select first enabled tab
        tab = includes(enabledTabs, tab) ? tab : head(enabledTabs);

        const partnerPart = isNil(partnerNumber) ? '' : `/${partnerNumber}`;
        let contextLink = `${partnerPart}/FunctionalLocation/${parent.functionalLocation}/${tab}`;
        if (type === 'EQ') {
          contextLink = `${partnerPart}/Equipment/${parent.functionalLocation}//${parent.equipmentNumber}/Subequipment`;
        }

        return {
          context: parent.description || parent.text,
          contextLink,
          type,
        };
      }),
    };
  }
};

export const getFunctionalLocationUrl = (functionalLocation, features, partnerNumber, customViews, wantedTab) => {
  const type = resolveFunctionalLocationType(functionalLocation);
  const enabledTabs = getEnabledTabs(features, type, getEnabledCustomViews(customViews, functionalLocation));
  let tab = wantedTab;

  if (!tab) {
    switch (type) {
      case 'TO':
        tab = 'Systems';
        break;
      case 'SYSTEM':
        tab = 'Equipment';
        break;
      default:
        tab = 'ServiceCalendar';
    }
  }
  // Check if the wanted tab is enabled for the user and if not, just select the first enabled tab
  const selectedTab = includes(enabledTabs, tab) ? tab : head(enabledTabs);

  const partnerPart = !partnerNumber ? '' : `/${partnerNumber}`;
  return `${partnerPart}/FunctionalLocation/${encodeURIComponent(
    functionalLocation.functionalLocation
  )}/${selectedTab}`;
};

export const getEquipmentUrl = (partnerNumber, functionalLocationId, equipmentNumber, superordinate) => {
  const equipmentPath = superordinate
    ? `Equipment/${functionalLocationId}/${superordinate}/${equipmentNumber}`
    : `Equipment/${functionalLocationId}//${equipmentNumber}`;
  const partnerPart = !partnerNumber ? '' : `/${partnerNumber}`;
  return `${partnerPart}/${equipmentPath}/Subequipment`;
};

export const getBuildingImage = (functionalLocationImages, functionalLocation) => {
  let buildingImagePath = '';
  if (
    functionalLocationImages &&
    functionalLocation &&
    functionalLocationImages[functionalLocation.functionalLocation]
  ) {
    const images = functionalLocationImages[functionalLocation.functionalLocation];
    const buildingImage = findLast(images, image => image.type === 'building');
    if (buildingImage && buildingImage.path) {
      if (
        buildingImage.variants &&
        buildingImage.variants.length > 0 &&
        buildingImage.variants.indexOf(variants.HEADER) !== -1
      ) {
        buildingImagePath = getHeaderImageUrl(buildingImage.path);
      } else {
        buildingImagePath = buildingImage.path;
      }
    }
  }
  return buildingImagePath;
};

export const addServiceOrderLink = (links, pathname, features) => {
  isServiceOrdersEnabled(features) &&
    links.push({
      title: 'Service Orders',
      icon: icons.SERVICE_ORDERS,
      queryParams: {
        year: moment().year(),
      },
      pathname: `${pathname}/ServiceCalendar`,
    });
  return links;
};

export const addCCCLink = (links, pathname, features, functionalLocation, noIoTData) => {
  showCCC(features, functionalLocation) &&
    links.push({
      title: 'Conditions',
      icon: icons.CONDITIONS,
      disabled: noIoTData,
      pathname: `${pathname}/Conditions`,
    });
  return links;
};

export const addConditionsLink = (links, pathname, features, disabled, featureTeasers) => {
  const teaser = includes(featureTeasers, 'conditions');
  (isConditionsEnabled(features) || teaser) &&
    links.push({ title: 'Conditions', icon: icons.CONDITIONS, disabled, pathname: `${pathname}/Conditions`, teaser });
  return links;
};

export const addBuildingAutomationLink = (links, pathname, features, disabled, featureTeasers) => {
  const teaser = includes(featureTeasers, 'buildingAutomationTab');
  ((features && features.buildingAutomationTab) || teaser) &&
    links.push({ title: 'Building', icon: icons.TO_BUILDING, disabled, pathname: `${pathname}/Building`, teaser });
  return links;
};

export const addControlRoomLink = (links, pathname, features, disabled, featureTeasers) => {
  const teaser = includes(featureTeasers, 'controlRoomTab');
  ((features && features.controlRoomTab) || teaser) &&
    links.push({
      title: 'Control Room',
      icon: icons.CONTROL_ROOM,
      disabled,
      pathname: `${pathname}/RemoteCenter`,
      teaser,
    });
  return links;
};

export const addCleaningLink = (links, pathname, features, disabled, featureTeasers) => {
  const teaser = includes(featureTeasers, 'cleaningTab');
  ((features && features.cleaningTab) || teaser) &&
    links.push({ title: 'Cleaning', icon: icons.CLEANING, disabled, pathname: `${pathname}/Cleaning`, teaser });
  return links;
};

export const addAnnouncementsLink = (links, pathname, features, count, featureTeasers) => {
  const teaser = includes(featureTeasers, 'announcementsTab');
  ((features && features.announcementsTab) || teaser) &&
    links.push({
      title: 'News',
      icon: icons.FL_ANNOUNCEMENTS,
      count: !teaser ? count : undefined,
      pathname: `${pathname}/News`,
      teaser,
    });
  return links;
};

export const addFloorsLink = (links, pathname, features, disabled, featureTeasers) => {
  const teaser = includes(featureTeasers, 'floorsTab');
  ((features && features.floorsTab) || teaser) &&
    links.push({ title: 'Floors', icon: icons.TO_FLOOR, disabled, pathname: `${pathname}/Floors`, teaser });
  return links;
};

export const addEnergyLink = (links, pathname, features, disabled, featureTeasers) => {
  const teaser = includes(featureTeasers, 'energyTab');
  ((features && features.energyTab) || teaser) &&
    links.push({ title: 'Energy', icon: icons.ENERGY, disabled, pathname: `${pathname}/Energy`, teaser });
  return links;
};

export const addRecyclingLink = (links, pathname, features, disabled) => {
  if (isPartnerRecyclingEnabled(features)) {
    links.push({ title: 'Recycling', icon: icons.RECYCLING, disabled, pathname: `${pathname}/Recycling` });
  }
  return links;
};

export const addTechnicalLink = (
  links,
  pathname,
  features,
  functionalLocation,
  totalFunctionalLocations,
  loading,
  featureTeasers
) => {
  const teaser = includes(featureTeasers, 'technicalTab');
  const title = isTechnicalObject(functionalLocation) ? 'Systems' : 'Technical';
  (isTechnicalTabEnabled(features) || teaser) &&
    links.push({
      title,
      icon: icons.TECHNICAL,
      count: isTechnicalObject(functionalLocation) ? totalFunctionalLocations : undefined,
      disabled: !(totalFunctionalLocations > 0),
      loading: isTechnicalObject(functionalLocation) ? loading : undefined,
      pathname: `${pathname}/${title}`,
      teaser,
    });
  return links;
};

export const addEquipmentLink = (
  links,
  pathname,
  features,
  equipmentCount,
  loading,
  featureTeasers,
  subEquipment = false
) => {
  const teaser = includes(featureTeasers, 'equipment');
  (isEquipmentEnabled(features) || teaser) &&
    links.push({
      title: subEquipment ? 'Subequipment' : 'Equipment',
      icon: flIcons.EQUIPMENT,
      count: subEquipment ? equipmentCount : undefined,
      disabled: !(equipmentCount > 0),
      loading: subEquipment ? loading : undefined,
      pathname: `${pathname}/Equipment`,
      teaser,
    });
  return links;
};

export const addDocumentsLink = (links, pathname, features, featureTeasers) => {
  const teaser = includes(featureTeasers, 'files');
  ((features && features.files) || teaser) &&
    links.push({
      title: 'Documents',
      icon: icons.DOCUMENTS,
      pathname: `${pathname}/Documents`,
      teaser,
    });
  return links;
};

export const addExternalDocumentsLink = (links, pathname, features, documentCount, loading, featureTeasers) => {
  const teaser = includes(featureTeasers, 'documents');
  ((features && features.documents) || teaser) &&
    links.push({
      title: 'External Documents',
      icon: icons.EXTERNAL_DOCUMENTS,
      count: !teaser ? documentCount : undefined,
      disabled: !(documentCount > 0),
      loading: !teaser ? loading : false,
      pathname: `${pathname}/ExternalDocuments`,
      teaser,
    });
  return links;
};

export const addBuildingAdminLink = (links, functionalLocation, profile) => {
  functionalLocation &&
    profile &&
    canEditBuilding(profile.role) &&
    links.push({
      title: 'Edit Building',
      icon: icons.ADMIN_PANEL,
      pathname: `/Admin/Buildings/Edit/${functionalLocation.functionalLocation}/Configuration`,
    });
  return links;
};

export const addNewServiceRequestLink = (links, pathname, features) => {
  features &&
    features.sr &&
    links.push({
      title: 'New Service Request',
      icon: icons.NEW_SERVICE_REQUEST,
      pathname: `${pathname}/ServiceRequest`,
    });
  return links;
};

export const addNewServiceRequestCustomerLink = (links, partnerNumber, features) => {
  features &&
    features.sr &&
    links.push({
      title: 'New Service Request',
      icon: icons.NEW_SERVICE_REQUEST,
      pathname: `/${partnerNumber ? `${partnerNumber}/` : ''}ServiceRequest`,
    });
  return links;
};

export const addCustomerAdminLink = (links, partnerNumber, profile) => {
  profile &&
    canEditPartner(profile.role) &&
    partnerNumber !== 'all' &&
    links.push({
      title: 'Edit Customer',
      icon: icons.ADMIN_PANEL,
      pathname: `/Admin/Partners/Edit/${partnerNumber}/Configuration`,
    });
  return links;
};

export const addCustomViewLinks = (links, pathname, customViews) => {
  return links.concat(
    customViews.map(customView => {
      const isExternalLink = customView.viewType === VIEW_TYPES.externalLink;
      const icon = getIcon(customView.viewType);
      return {
        id: customView.id,
        title: customView.viewName,
        icon,
        external: isExternalLink,
        pathname: isExternalLink ? customView.content : `${pathname}/${customView.id}`,
      };
    })
  );
};

export const addContactCaverionLink = (links, pathname, enabled) => {
  enabled &&
    links.push({
      title: 'Contact Caverion',
      icon: icons.CONTACT,
      pathname: `${pathname}/ContactCaverion`,
    });
  return links;
};

export const addContactCaverionCustomerLink = (links, partnerNumber, enabled) => {
  enabled &&
    links.push({
      title: 'Contact Caverion',
      icon: icons.CONTACT,
      pathname: `/${partnerNumber ? `${partnerNumber}/` : ''}ContactCaverion`,
    });
  return links;
};

const showCCC = (features, functionalLocation) =>
  features && features.conditions && !isBusinessEntity(functionalLocation) && !isTechnicalObject(functionalLocation);

export const isBusinessEntity = functionalLocation => functionalLocation && functionalLocation.type === 'BE';
export const isBusinessUnit = functionalLocation => functionalLocation && functionalLocation.type === 'BU';
export const isIndustry = functionalLocation => functionalLocation && functionalLocation.type === 'IN';
export const isTenant = functionalLocation => functionalLocation && functionalLocation.type === 'UN';
export const isTechnicalObject = functionalLocation => functionalLocation && functionalLocation.type === 'TO';
export const isSystem = functionalLocation =>
  functionalLocation && resolveFunctionalLocationType(functionalLocation) === 'SYSTEM';
