import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MultiInputRow from './MultiInputRow';
import Button from 'components/Button/Button';

const ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.spacing.md};
`;

const MultiInput = props => {
  const { t, model, onAddRow, addRowLabel, baseProperty } = props;

  if (!model) {
    return null;
  }

  return (
    <div>
      {model[baseProperty] &&
        model[baseProperty].map((row, index) => (
          <MultiInputRow
            key={`${baseProperty}-${index}`}
            row={row}
            index={index}
            isLastRow={index === model[baseProperty].length - 1}
            {...props}
          />
        ))}

      <ButtonWrapper>
        <Button onClick={onAddRow}>{addRowLabel || t('Add row')}</Button>
      </ButtonWrapper>
    </div>
  );
};

MultiInput.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired, // data
  onAddRow: PropTypes.func.isRequired, // to be called when Add row -button is clicked
  baseProperty: PropTypes.string.isRequired, // property in model that contains multi input data
  addRowLabel: PropTypes.string, // custom label for Add row -button
};

export default MultiInput;
