import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { sortBy, concat, includes, without, isArray } from 'lodash';
import InputLabel from 'components/Form/InputLabel';
import InputCheckbox from 'components/Form/InputCheckbox';
import SkeletonText from 'components/Skeletons/SkeletonText';

const CheckboxWrapper = styled.div`
  padding: ${props => props.theme.spacing.xxs} 0;
`;
CheckboxWrapper.displayName = 'CheckboxWrapper';

const StyledSkeleton = styled(SkeletonText)`
  height: 17px;
`;
StyledSkeleton.displayName = 'StyledSkeleton';

const FilterCheckboxes = ({ label, field, options, selectedFilter, sort, setFilter, loading }) => {
  const sortedOptions = field === 'status' ? options : sortBy(options, sort);

  const handleFilterClick = (value, checked) =>
    setFilter(field, checked ? concat(selectedFilter, value) : without(selectedFilter, value));

  return (
    <Fragment>
      <InputLabel text={label} />
      {loading ? (
        <CheckboxWrapper>
          <StyledSkeleton />
        </CheckboxWrapper>
      ) : (
        sortedOptions.map(option => (
          <CheckboxWrapper key={option.text}>
            <InputCheckbox
              label={option.text}
              id={option.value}
              value={
                selectedFilter === option.value ||
                (isArray(selectedFilter) && includes(selectedFilter, option.value)) ||
                sortedOptions.length === 1
              }
              property={option.value}
              onChange={handleFilterClick}
              trueValue={true}
              falseValue={false}
              useBold
              disabled={sortedOptions.length === 1}
            />
          </CheckboxWrapper>
        ))
      )}
    </Fragment>
  );
};

FilterCheckboxes.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  sort: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default FilterCheckboxes;
