import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Infotip from 'components/Infotip/Infotip';

const StyledTitleColumn = styled.div`
  padding-left: 5em;
  text-align: right;
`;

const EventIcon = styled.span`
  display: inline-block;
  position: relative;
  background-color: ${props => props.theme.colors.rockBlue};
  height: ${props => props.theme.spacing.md};
  width: ${props => props.theme.spacing.md};
  min-width: ${props => props.theme.spacing.md};
  margin-right: ${props => props.theme.spacing.md};
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: '!';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${props => props.theme.colors.white};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: 11px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
`;

const Text = styled.p`
  font-family: monospace;
  padding: 0.5em;
`;

const InfoColumn = props => {
  const { data } = props;
  const text = <Text>{data}</Text>;

  return (
    <StyledTitleColumn>
      <Infotip text={text} position="left" type="white" textAlign="left">
        <EventIcon />
      </Infotip>
    </StyledTitleColumn>
  );
};

InfoColumn.propTypes = {
  data: PropTypes.string.isRequired,
};

export default InfoColumn;
