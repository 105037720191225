import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';

import withQuery from 'decorators/Query/withQuery';
import translations from 'decorators/Translations/translations';
import connect from './connectEnergyBreakdown';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import { Link } from 'react-router-dom';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import ZoomableChart from 'components/Charts/ZoomableChart';
import EnergyBreakdownTotals from './EnergyBreakdownTotals';
import {
  getZoomedEnergyValues,
  ZOOM_TYPE,
  loadDailyChartValues,
  loadHourlyChartValues,
  getTotalRawValues,
  getReferenceSeries,
} from 'containers/Application/Modules/EnergyModule/EnergyModuleUtils';
import { energyBreakdownTypes, metaReferenceKeys } from 'utils/Data/values';
import ContextualHelp, { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';

const EMPTY_ARRAY = [];

const HelpPositioner = styled.div`
  margin-right: auto;
  margin-top: 1em;
`;

const AllMetersPositioner = styled.div`
  text-align: right;
  font-size: ${props => props.theme.font.size.xxs};
`;

const TotalsWrapper = styled.div`
  margin-top: 60px;
`;

export class EnergyBreakdown extends Component {
  state = {
    zoom: {
      type: ZOOM_TYPE.year,
      selected: null,
    },
    loading: false,
  };

  getTemperatureSeries = () =>
    (this.props.outdoorsTemperatureData[this.state.zoom.type] &&
      this.props.outdoorsTemperatureData[this.state.zoom.type][this.state.zoom.selected || 0]) ||
    EMPTY_ARRAY;

  componentDidUpdate(prevProps, prevState) {
    const { zoom, loading } = this.state;
    const { loadingEnergyChartValues, outdoorsTemperatureData } = this.props;
    /*
     * Update chart data if
     *  1) zoom has changed and no loading were required
     *  2) loadingEnergyChartValues (from redux) stopped and this component had the loading on
     *  3) Outdoors temp values have changed
     */
    const stoppedLoadingChartValues = prevProps.loadingEnergyChartValues && !loadingEnergyChartValues;
    if (
      (!isEqual(zoom, prevState.zoom) && !loading) ||
      (loading && stoppedLoadingChartValues) ||
      !isEqual(outdoorsTemperatureData, prevProps.outdoorsTemperatureData)
    ) {
      if (zoom.type !== ZOOM_TYPE.year) {
        this.setState({ loading: false });
      }
    }
  }

  handleZoom = (selected, type) => {
    const { loadEnergyChartValues, partnerNumber, energyChartValues } = this.props;
    let loading = true;

    if (type === ZOOM_TYPE.month && isEmpty(energyChartValues.dailySum)) {
      loadDailyChartValues(loadEnergyChartValues, partnerNumber);
    } else if (type === ZOOM_TYPE.date) {
      loadHourlyChartValues(selected, loadEnergyChartValues, partnerNumber);
    } else {
      loading = false;
    }

    this.setState(oldState => ({
      zoom: {
        ...oldState.zoom,
        type,
        selected,
      },
      loading,
    }));
  };

  getEnergyValues = ({ type, zoom, unit }) => {
    const { theme, energyChartValues, referenceValues, t } = this.props;
    let chartValues;
    switch (zoom.type) {
      case ZOOM_TYPE.date:
        chartValues = energyChartValues.hourlySum;
        break;
      case ZOOM_TYPE.month:
        chartValues = energyChartValues.dailySum;
        break;
      default:
        chartValues = energyChartValues.monthlySum;
        break;
    }
    if (!chartValues) {
      return {
        series: [],
        categories: [],
      };
    }
    chartValues = type === 'energy' ? getTotalRawValues(chartValues) : chartValues[type];
    const zoomedEnergyValues = getZoomedEnergyValues(chartValues, theme, zoom.type, zoom.selected, unit);
    const metaKey = metaReferenceKeys[type];
    if (referenceValues[metaKey]) {
      const referenceSeries = metaKey && getReferenceSeries(referenceValues[metaKey], theme, t('Reference'));
      const zoomedReferenceSeries = referenceSeries[zoom.type] && referenceSeries[zoom.type][zoom.selected || 0];
      zoomedReferenceSeries && zoomedEnergyValues.series.push(zoomedReferenceSeries);
    }
    return zoomedEnergyValues;
  };

  getTabs = () => {
    const { t, valuesByType } = this.props;
    return energyBreakdownTypes
      .filter(type => Boolean(valuesByType[type]))
      .map(type => {
        switch (type) {
          case 'electricity':
            return {
              heading: t('Electricity'),
              subHeading: t('Total electricity consumption'),
              icon: 'electricity.consumption',
              type,
            };
          case 'districtHeating':
            return {
              heading: t('District heating'),
              subHeading: t('Total district heating'),
              icon: 'heating.consumption',
              type,
            };
          case 'waterConsumption':
            return {
              heading: t('Water'),
              subHeading: t('Total water consumption'),
              icon: 'water.consumption',
              type,
            };
          default:
            return {
              heading: t('Total'),
              subHeading: t('Total energy consumption'),
              icon: 'energy.consumption',
              type,
            };
        }
      });
  };

  handleTabChange = index => this.props.setQuery({ breakdown: this.getTabs()[index].type });

  renderTotals = tab => {
    const { t, valuesByType, referenceValues } = this.props;
    if (valuesByType[tab.type]) {
      const { unit, years, yearTotals, last365DaysConsumption } = valuesByType[tab.type];
      const metaKey = metaReferenceKeys[tab.type];
      return (
        <TotalsWrapper>
          <EnergyBreakdownTotals
            header={tab.subHeading}
            label={t('Last 365 days')}
            icon={tab.icon}
            value={`${Math.round(last365DaysConsumption)} ${unit}`}
            years={years}
            yearTotals={yearTotals}
            unit={unit}
            footer={{
              header: t('Reference Consumption'),
              value: referenceValues[metaKey] && `${sum(referenceValues[metaKey])} ${unit}`,
            }}
          />
          <AllMetersPositioner>
            <Link
              to={`${window.location.pathname
                .split('/')
                .slice(0, -1)
                .join('/')}/Conditions`}
            >
              {t('All Energy Meters')} &rarr;
            </Link>
          </AllMetersPositioner>
        </TotalsWrapper>
      );
    }
  };

  renderChart = tab => {
    const { t, valuesByType } = this.props;
    const { zoom, loading } = this.state;
    const { maxZoomLevel, unit } = valuesByType[tab.type];
    const energyValues = this.getEnergyValues({
      type: tab.type,
      zoom,
      unit,
    });
    if (!energyValues) {
      return null;
    }
    const { series, categories, xTitle } = energyValues;
    return (
      <ZoomableChart
        categories={categories}
        series={series}
        temperatureSeries={this.getTemperatureSeries()}
        t={t}
        maxZoomLevel={maxZoomLevel}
        onZoom={this.handleZoom}
        zoom={zoom}
        xTitle={xTitle}
        loading={loading}
      />
    );
  };

  getSelectedTabIndex = () => {
    const { breakdown } = this.props.query;
    const tabs = this.getTabs();

    const selectedTabIndex = tabs.findIndex(tab => tab.type === breakdown);
    if (selectedTabIndex === -1) {
      return 0;
    }
    return selectedTabIndex;
  };

  render() {
    const { t } = this.props;
    const tabs = this.getTabs();
    const selectedTabIndex = this.getSelectedTabIndex();
    const currentTab = tabs[selectedTabIndex];
    const options = tabs.map((tab, index) => ({ value: index, label: tab.heading }));
    return (
      <Section>
        <SectionHeader t={t}>
          <SectionTabSelector
            t={t}
            options={options}
            model={{ tabSelected: selectedTabIndex }}
            property="tabSelected"
            onTabChange={(property, value) => this.handleTabChange(value)}
            left
            large
          />
          <HelpPositioner>
            <ContextualHelp
              t={t}
              title={'Energy breakdown'}
              text={`${CTXHELP_PREFIX} Energy breakdown`}
              standalone
              position="bottom"
            />
          </HelpPositioner>
        </SectionHeader>
        <Columns>
          <Column
            columnWidth={{
              landscape: 9,
              default: 12,
            }}
          >
            {this.renderChart(currentTab)}
          </Column>
          <Column
            columnWidth={{
              landscape: 3,
              default: 12,
            }}
          >
            {this.renderTotals(currentTab)}
          </Column>
        </Columns>
      </Section>
    );
  }
}

EnergyBreakdown.defaultProps = {
  functionalLocation: null,
  partnerNumber: null,
  valuesByType: {},
  outdoorsTemperatureData: {},
  energyChartValues: {},
  referenceValues: {},
};

EnergyBreakdown.propTypes = {
  t: PropTypes.func.isRequired,
  loadingEnergyChartValues: PropTypes.bool.isRequired,
  loadEnergyChartValues: PropTypes.func.isRequired,
  functionalLocation: PropTypes.object,
  partnerNumber: PropTypes.string,
  referenceValues: PropTypes.object,
  valuesByType: PropTypes.object,
  outdoorsTemperatureData: PropTypes.object,
  energyChartValues: PropTypes.object,
  theme: PropTypes.object,
  query: PropTypes.object,
  setQuery: PropTypes.func,
};

export default withQuery(withTheme(connect(translations(EnergyBreakdown))));
