import styled from 'styled-components';
import { transparentize } from 'polished';

const InfoBox = styled.div`
  background: ${props =>
    props.error ? transparentize(0.9, props.theme.colors.radicalRed) : props.theme.colors.serenade};
  color: ${props => props.theme.colors.black};
  border: 1px solid ${props => (props.error ? props.theme.colors.radicalRed : props.theme.colors.peachOrange)};
  padding: ${props => props.theme.spacing.md};
  margin: ${props => props.theme.spacing.lg} 0;
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.md};
`;
InfoBox.displayName = 'InfoBox';

export default InfoBox;
