import { connect } from 'react-redux';
import { loadFunctionalLocationContainer } from 'redux/modules/containers/functional-location.js';
import { loadEquipmentsModule } from 'redux/modules/containers/equipments-module.js';
import { getSensorAlarmsBySensorId } from 'redux/modules/iot/sensorAlarms';

const emptyArray = [];

const mapStateToProps = state => ({
  loading: state.functionalLocationContainer.loading,
  loadingParentFL: state.functionalLocationContainer.loadingParents,
  loadingChildren: state.functionalLocationContainer.loadingChildren,
  loadingEquipment: state.functionalLocationContainer.loadingEquipment || state.equipmentsModule.loadingMoreData,
  features: state.profile.profile.syntheticFeatures,
  featureTeasers: state.profile.profile.featureTeasers,
  permissions: state.profile.permissionTree,
  functionalLocations: state.functionalLocations.functionalLocations,
  equipments: state.equipments.equipments,
  subEquipments: state.equipments.subEquipments,
  sensorHierarchy: state.sensorHierarchy,
  valuesBySensorId: state.values.sensors.valuesBySensorId,
  latestValuesBySensorId: state.values.sensors.latestValuesBySensorId,
  cleaningValues: state.values.cleaning.cleaningValues,
  buildingConditions: state.values.conditions.conditions,
  notice: state.notice,
  alarm: state.alarm,
  functionalLocationDocuments: state.document.filteredFunctionalLocations,
  sensorCategories: state.sensors.categories,
  profile: state.profile.profile,
  functionalLocationImages: state.partnerImage.byFL,
  announcements: state.announcement,
  meta: state.buildingMeta.meta,
  slaByFL: state.notice.slaByFL,
  customViewsByFl: state.customView.customViewsByFl,
  disabledCustomViews: state.profile.profile.disabledCustomViews || emptyArray,
  sensorAlarmsById: getSensorAlarmsBySensorId(state),
  partnerMeta: state.partnerMeta.meta,
});

const mapDispatchToProps = {
  loadFunctionalLocationContainer,
  loadEquipmentsModule,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector;
