import React, { Component } from 'react';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';
import _ from 'lodash';
import styled from 'styled-components';

import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import Blueprint from 'components/Blueprint/Blueprint';
import { InputRow, InputLabel, InputText, InputForm } from 'components/index.js';
import Button from 'components/Button/Button';

import { upsertSensorHierarchy, loadSensorHierarchies, upsertCoords } from 'redux/modules/customer/sensorHierarchy.js';
import 'react-sortable-tree/style.css';
import { createFeature } from 'utils/Data/sensorHierarchy';

const StyledSection = styled.section`
  padding-right: ${props => props.withBlueprint && props.theme.spacing.md};
`;
StyledSection.displayName = 'StyledSection';

class EditArea extends Component {
  state = {
    coords: [],
    error: '',
    loading: false,
    form: {},
  };

  componentDidMount() {
    const { editId, floors, node } = this.props;
    if (!this.isCreateMode(editId)) {
      const subHierarchies = _.flatten(floors.map(floor => floor.children));
      const area = _.find(subHierarchies, { id: editId, type: 'area' });
      this.setState({
        form: area,
        coords: node && node.coords,
      });
    }
  }

  isCreateMode(mode) {
    return mode === 'add';
  }

  saveArea = (baseData, coords) => {
    const model = this.getModel();
    const data = {
      id: model.id,
      type: 'area',
      name: model.name,
      ...baseData,
    };

    this.setState({ loading: true });
    this.props.upsertSensorHierarchy(data).then(({ result }) => {
      if (result) {
        if (coords && coords.length > 0) {
          const coordsData = {
            functionalLocation: this.props.functionalLocation,
            type: 'area',
            sensorHierarchyId: result && result.id,
            area: coords.length > 0 ? coords.map(points => points.map(point => ({ x: point[0], y: point[1] }))) : null,
          };
          if (this.props.node && this.props.node.coordsId) {
            coordsData.id = this.props.node.coordsId;
          }
          this.props.upsertCoords(coordsData).then(() => {
            this.afterSubmit();
          });
        } else {
          this.afterSubmit();
        }
      } else {
        this.setState({ loading: false });
      }
    });
  };

  afterSubmit = () => {
    this.props.loadSensorHierarchies(this.props.functionalLocation).then(() => {
      this.setState({ loading: false });
      if (typeof this.props.onSubmit === 'function') {
        const notification = {
          type: 'success',
          message: this.props.t('Area successfully saved'),
        };
        this.props.onSubmit(null, notification);
      }
    });
  };

  handleSubmit = () => {
    const data = {};
    if (this.isCreateMode(this.props.editId)) {
      data.parentId = this.props.floorId;
      data.functionalLocation = this.props.functionalLocation;
    }
    const coords = this.state.coords;

    this.saveArea(data, coords);
  };

  handleCancel = () => {
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  };

  handleFormChange = (property, value) => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [property]: value,
      },
    }));
  };

  setCoordinates = coords => {
    this.setState({ coords });
  };

  getModel = () => {
    return this.state.form;
  };

  render() {
    const { t, floors, floorId, onCancel } = this.props;
    let featureCollection;
    let image;

    if (floorId) {
      const floor = _.find(floors, { id: floorId });
      image = _.find(floor.images, { type: 'floor' });
    }

    const model = this.getModel();
    const coords = this.state.coords;
    const hasCoords = coords && coords.length > 0;
    const editMode = hasCoords ? true : 'area';

    if (hasCoords) {
      featureCollection = [
        [
          {
            type: 'FeatureCollection',
            features: [createFeature(coords, 'id', 'area', '')],
          },
        ],
      ];
    }

    return (
      <DialogModal
        extraLarge
        animate
        isActive
        onOverlayClick={onCancel}
        t={t}
        footer={
          <DialogFooter>
            <Button cancel onClick={onCancel}>
              {t('Cancel')}
            </Button>
            <Button
              submit
              onClick={this.handleSubmit}
              type="submit"
              data-test-id="CustomViewSubmitButton"
              loading={this.state.loading}
            >
              {t('Save changes')}
            </Button>
          </DialogFooter>
        }
      >
        <section>
          <h3>{this.isCreateMode(this.props.editId) ? t('Add custom shape area') : t('Edit custom shape area')}</h3>
          <InputForm onPropertyChange={this.handleFormChange} model={model} id="adminAreaForm">
            <InputRow required id="areaName">
              <InputLabel text={t('Area name')} />
              <InputText property="name" />
            </InputRow>
          </InputForm>
          {image && image.path && (
            <Blueprint
              ref={node => {
                this.mapDiv = node;
              }}
              t={t}
              image={image.path}
              imageSize={{ width: image.width, height: image.height }}
              editMode={editMode}
              handleNewArea={this.setCoordinates}
              featureGroups={featureCollection}
            />
          )}
        </section>
      </DialogModal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  upsertSensorHierarchy: data => dispatch(upsertSensorHierarchy(data)),
  loadSensorHierarchies: functionalLocation => dispatch(loadSensorHierarchies(functionalLocation, true)),
  upsertCoords: data => dispatch(upsertCoords(data)),
});

const connector = connect(
  null,
  mapDispatchToProps
);

export default connector(translations(EditArea));
