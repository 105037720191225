import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { uniqueId, includes, isEmpty } from 'lodash';

import Button from 'components/Button/Button';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputText from 'components/Form/InputText';
import InputTextArea from 'components/Form/InputTextArea';
import InputForm from 'components/Form/InputForm';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import FileUpload from 'components/FileUpload/FileUpload';
import DocumentFile from 'components/Documents/DocumentManager/DocumentFile';
import { customViewShape } from './utils';
import { VIEW_TYPES } from 'containers/Application/Admin/CustomViews/utils';

const getHeaderText = (t, isNew) => {
  return isNew ? t(`Add New Custom View`) : t(`Edit Custom View`);
};

export const noFilter = 'NO_FILTER';

const Columns = styled.div`
  display: flex;
  width: 100%;

  div:first-child {
    margin-right: ${props => props.theme.spacing.md};
  }
`;

const CustomViewForm = props => {
  const {
    t,
    customView,
    addNew,
    onSubmit,
    onClose,
    onChange,
    flOptions,
    usersOptions,
    loading,
    errorMessage,
    customerOptions,
    handleFileUpload,
    handleFileRemoval,
    uploading,
  } = props;

  const contentRequired = includes(
    [VIEW_TYPES.embeddedDocument, VIEW_TYPES.externalLink, VIEW_TYPES.embedded, VIEW_TYPES.freeLayout],
    customView.viewType
  );
  const buttonDisabled =
    !customView.viewName ||
    (contentRequired && !customView.content) ||
    (!customView.portfolio && isEmpty(customView.functionalLocations));

  return (
    <DialogModal
      large
      animate
      isActive
      onOverlayClick={onClose}
      t={t}
      footer={
        <DialogFooter>
          <Button cancel onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            submit
            onClick={onSubmit}
            type="submit"
            data-test-id="CustomViewSubmitButton"
            loading={loading}
            disabled={buttonDisabled}
          >
            {t('Save')}
          </Button>
        </DialogFooter>
      }
    >
      <section>
        <h3>{getHeaderText(t, addNew)}</h3>
        <InputForm id={uniqueId('customview-form-')} model={customView} onPropertyChange={onChange}>
          <InputRow fullRow required>
            <RadioButtonGroup name="portfolio" value={customView.portfolio} onChange={onChange} row>
              <RadioButton label={t('Portfolio level')} value={true} name="portfolio" />
              <RadioButton label={t('Building level')} value={false} name="building" />
            </RadioButtonGroup>
          </InputRow>
          <InputRow fullRow>
            <InputLabel text={t('Customer')} />
            <InputSelectDropdown
              property="customers"
              model={customView}
              onChange={onChange}
              required
              options={customerOptions}
              clearable={false}
              closeOnSelect={false}
              t={t}
            />
          </InputRow>
          {!customView.portfolio && (
            <InputRow fullRow required>
              <InputLabel text={t('Functional locations')} />
              <InputSelectDropdown
                property="functionalLocations"
                model={customView}
                onChange={onChange}
                required
                options={flOptions}
                clearable={false}
                closeOnSelect={false}
                multi
                t={t}
              />
            </InputRow>
          )}
          <InputRow fullRow>
            <RadioButtonGroup name="public" value={customView.public} onChange={onChange} row>
              <RadioButton label={t('Select users')} value={false} name="users" />
              <RadioButton
                label={
                  customView.portfolio
                    ? t('Make public within the selected customer')
                    : t('Make public within the selected functional locations')
                }
                value={true}
                name="public"
              />
            </RadioButtonGroup>
          </InputRow>
          {!customView.public && (
            <InputRow fullRow required>
              <InputLabel text={t('Users')} />
              <InputSelectDropdown
                property="users"
                model={customView}
                onChange={onChange}
                options={usersOptions}
                clearable={false}
                closeOnSelect={false}
                multi
                t={t}
                noResultsText={t('No users for location')}
              />
            </InputRow>
          )}
          {customView.public && (
            <InputRow fullRow required>
              <InputLabel text={t('Users')} />
              <InputSelectDropdown disabled={customView.public} placeholder=" " t={t} />
            </InputRow>
          )}
          <InputRow fullRow required>
            <InputLabel text={t('Title')} />
            <InputText
              id="title"
              data-test-id="CustomViewTitleInput"
              type="text"
              property="viewName"
              placeholder={customView.viewName}
            />
          </InputRow>
          <InputRow fullRow>
            <InputLabel text={t('Description')} extraText={`(${t('Max')} 100 ${t('Characters')})`} />
            <InputText
              id="description"
              data-test-id="CustomViewDescriptionInput"
              type="text"
              property="viewDesc"
              placeholder={customView.viewDesc}
              inputProps={{ maxlength: 100 }}
            />
          </InputRow>
          {customView.viewType === VIEW_TYPES.powerBi && (
            <InputRow fullRow required>
              <InputLabel text={t('Power BI App ID')} />
              <InputText
                id="powerbiAppId"
                data-test-id="CustomViewPowerbiAppIdInput"
                type="text"
                property="powerbiAppId"
                placeholder={customView.powerbiAppId}
              />
            </InputRow>
          )}
          {customView.viewType === VIEW_TYPES.powerBi && (
            <InputRow fullRow required>
              <InputLabel text={t('Power BI Workspace ID')} />
              <InputText
                id="powerbiworkspaceid"
                data-test-id="CustomViewPowerbiWorkspaceIdInput"
                type="text"
                property="powerbiWorkspaceId"
                placeholder={customView.powerbiWorkspaceId}
              />
            </InputRow>
          )}
          {customView.viewType === VIEW_TYPES.powerBi && (
            <InputRow fullRow required>
              <InputLabel text={t('Power BI Report ID')} />
              <InputText
                id="powerbireportid"
                data-test-id="CustomViewPowerbiReportIdInput"
                type="text"
                property="powerbiReportId"
                placeholder={customView.powerbiReportId}
              />
            </InputRow>
          )}
          {customView.viewType === VIEW_TYPES.freeLayout && (
            <InputRow fullRow>
              <InputLabel text={t('HTML')} />
              <InputTextArea
                id="content"
                data-test-id="CustomViewFreeLayoutContent"
                type="text"
                property="content"
                height="15.5em"
              />
            </InputRow>
          )}
          {customView.viewType === VIEW_TYPES.embedded && (
            <Fragment>
              <InputRow fullRow>
                <InputLabel text={t('Url')} />
                <InputText
                  id="content"
                  data-test-id="CustomViewEmbeddedContent"
                  type="text"
                  property="content"
                  placeholder={t('Url for the website to embed')}
                />
              </InputRow>
              <Columns>
                <InputRow fullRow required>
                  <InputLabel text={t('Height')} />
                  <InputText
                    id="height"
                    data-test-id="CustomViewEmbeddedHeight"
                    type="number"
                    property="height"
                    placeholder={t('Iframe height')}
                  />
                </InputRow>
                <InputRow fullRow>
                  <InputLabel text={t('Top offset')} />
                  <InputText
                    id="offsetTop"
                    data-test-id="CustomViewEmbeddedOffsetTop"
                    type="number"
                    property="offsetTop"
                    placeholder={t('How many pixels to hide from top')}
                  />
                </InputRow>
              </Columns>
            </Fragment>
          )}
          {customView.viewType === VIEW_TYPES.externalLink && (
            <InputRow fullRow>
              <InputLabel text={t('Url')} />
              <InputText
                id="content"
                data-test-id="CustomViewExternalLinkContent"
                type="text"
                property="content"
                placeholder={t('Url for the website to link')}
              />
            </InputRow>
          )}
          {customView.viewType === VIEW_TYPES.embeddedDocument && (
            <InputRow fullRow>
              <InputLabel text={t('File')} />
              {!customView.content && (
                <FileUpload
                  upload={handleFileUpload}
                  t={t}
                  header={t('Click and select file or drag and drop')}
                  noIcon
                  noMimeTypes
                  height="100px"
                  infoText={t('Supported file types are PDF and the most common image files.')}
                />
              )}
              {customView.content && (
                <ul>
                  <DocumentFile
                    file={{ name: customView.content }}
                    index={0}
                    onFileRemoval={handleFileRemoval}
                    isCreateMode
                    loading={uploading}
                    hasErrored={false}
                    allLoading={loading}
                  />
                </ul>
              )}
            </InputRow>
          )}
          {errorMessage}
        </InputForm>
      </section>
    </DialogModal>
  );
};

CustomViewForm.propTypes = {
  customView: PropTypes.oneOfType([customViewShape()]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  handleFileRemoval: PropTypes.func.isRequired,
  // optional:
  onUserSelectChange: PropTypes.func,
  onFLSelectChange: PropTypes.func,
  flOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  usersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.object,
    })
  ),
  addNew: PropTypes.bool,
  errorMessage: PropTypes.element,
  loading: PropTypes.bool,
  height: PropTypes.number,
  offsetTop: PropTypes.number,
  uploading: PropTypes.bool,
};

CustomViewForm.defaultProps = {
  loading: false,
  errorMessage: <div />,
  addNew: false,
  selectedFilter: noFilter,
  uploading: false,
};

export default withTheme(CustomViewForm);
