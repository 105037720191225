import React from 'react';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import {
  FloorTabsContainer,
  FloorTabsListContainer,
  FloorTabsList,
  ScrollContainer,
} from 'components/FloorTabs/FloorTabs';
import SkeletonCircle from 'components/Skeletons/SkeletonCircle';

const FloorSkeleton = () => (
  <FloorTabsContainer>
    <FloorTabsListContainer>
      <FloorTabsList>
        <ScrollContainer>
          <SkeletonCircle width="40px" borderWidth="2px" />
          <SkeletonCircle width="40px" borderWidth="2px" />
          <SkeletonCircle width="40px" borderWidth="2px" />
          <SkeletonCircle width="40px" borderWidth="2px" />
          <SkeletonCircle width="40px" borderWidth="2px" />
        </ScrollContainer>
      </FloorTabsList>
    </FloorTabsListContainer>
    <OPICards smallMargin>
      <OPICard loading t={text => text} />
      <OPICard loading t={text => text} />
      <OPICard loading t={text => text} />
    </OPICards>
  </FloorTabsContainer>
);

export default FloorSkeleton;
