import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import { StandardPage } from 'components/index.js';
import { ThemeProvider } from 'styled-components';

import themes from '../../../styles/themes';

export const Background = styled.div`
  background-image: ${props => 'url(' + props.image + ')'};
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
`;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: ${props => props.theme.colors.white};
  padding: 1em;
  text-align: center;
  max-width: 38em;

  ${props => props.theme.media.landscape`
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 4em 5em;
    `}
`;

export const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => rgba(props.theme.colors.midnight, 0.75)};
`;

export const Header = styled.header`
  position: relative;
  padding: 0 2em;
`;

export const Logo = styled.img`
  height: 50px;
  margin-top: 1em;
`;

const Links = styled.div`
  display: inline-block;
  float: right;
  margin-top: 1em;
`;

const Link = styled.a`
  display: block;
  margin: 10px 0;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSize.xs};
`;

const PageTemplate = props => {
  const { children, title, mainSiteLinkText } = props;

  return (
    <ThemeProvider theme={themes.customerPlatform}>
      <Background image={`${window.location.origin}/landing_background.jpg`}>
        <ImageOverlay />
        <Helmet title={title} />
        <Header>
          <Logo src="/caverion_logo_white.svg" alt="logo" />
          <Links>
            <Link href="http://www.caverion.com">{mainSiteLinkText || 'Caverion.com main site'}&nbsp;&rarr;</Link>
          </Links>
        </Header>
        <StandardPage noMargin noBackground noFooter>
          <Container>{children}</Container>
        </StandardPage>
      </Background>
    </ThemeProvider>
  );
};

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  mainSiteLinkText: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default PageTemplate;
