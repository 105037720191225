import papa from 'papaparse';
import moment from 'moment';
import { get, isEmpty, isNil } from 'lodash';

export const VALID_MIME_TYPES = ['text/csv', 'text/plain', 'application/vnd.ms-excel'];

export const parseDate = input => {
  const possibleMomentFormats = [
    moment.ISO_8601,
    moment.RFC_2822,
    'D.M.YYYY',
    'D.M.YYYY H:m',
    'D.M.YYYY H:m:s',
    'D/M/YYYY',
    'YYYY/M',
    'YYYY-M',
    'M/YYYY',
    'M-YYYY',
    'YYYY-M-D',
    'YYYY/M/D',
  ];
  return moment.parseZone(input, possibleMomentFormats, true);
};

export const csvColumns = [
  {
    name: 'timestamp',
    title: 'Date',
    transform: value => parseDate(value),
    isValid: value => value && value.isValid(),
    format: value => value && value.format('L LT (Z)'),
    get: value => value && value.toISOString(),
    align: 'left',
  },
  {
    name: 'value',
    title: 'Value',
    transform: value => (value || value === 0 ? Number(value) : undefined),
    isValid: value => typeof value === 'number' && !Number.isNaN(value) && Number.isFinite(value),
    format: value => (typeof value === 'number' ? value.toLocaleString(moment.locale()) : value),
    get: value => value,
    align: 'right',
  },
  {
    name: 'sensorId',
    title: 'Sensor',
    transform: value => value && String(value),
    isValid: (value, defaults) => Boolean(value) || Boolean(get(defaults, 'sensorId')),
    format: (value, defaults) => (value ? String(value) : get(defaults, 'sensorId')),
    get: (value, defaults) => (value ? String(value) : get(defaults, 'sensorId')),
    align: 'left',
  },
  {
    name: 'aggregation',
    title: 'Aggregation',
    transform: value => value && String(value),
    isValid: value => true,
    format: (value, defaults) => (value ? String(value) : get(defaults, 'aggregation')),
    get: (value, defaults) => (value ? String(value) : get(defaults, 'aggregation')),
    align: 'left',
  },
];

export const transformCsvValue = (value, columnName) => {
  const columnConfig = csvColumns.find(column => column.name === columnName);
  if (!columnConfig) {
    return undefined;
  }
  if (typeof columnConfig.transform !== 'function') {
    return value;
  }
  return columnConfig.transform(value);
};

export const transformCsvHeader = header => {
  const columnNameLowerCase = header.toLowerCase();
  const columnConfig = csvColumns.find(column => column.name.toLowerCase() === columnNameLowerCase);
  if (columnConfig) {
    return columnConfig.name;
  }
  return header;
};

export const parseCsvValues = rawCsv =>
  papa.parse(rawCsv, {
    skipEmptyLines: true,
    header: true,
    transform: transformCsvValue,
    transformHeader: transformCsvHeader,
  });

const validateRow = (defaults, invalidRows, row, rowIndex) => {
  const rowErrors = csvColumns.reduce(validateRowColumn.bind(this, defaults, row), {});
  if (!isEmpty(rowErrors)) {
    invalidRows[rowIndex] = rowErrors;
  }
  return invalidRows;
};

const validateRowColumn = (defaults, row, columnErrors, columnConfig, columnIndex) => {
  const cellValue = row[columnConfig.name];
  if (typeof columnConfig.isValid === 'function' && !columnConfig.isValid(cellValue, defaults)) {
    columnErrors[columnConfig.name] = true;
  }
  return columnErrors;
};

export const validateParsedCsvValues = (parsedCsvValues, defaults) => {
  const validationErrors = parsedCsvValues.reduce(validateRow.bind(this, defaults), []);
  return validationErrors.length ? validationErrors : undefined;
};

export const readFileAsCsv = (file, t) => {
  if (!file) {
    return Promise.reject(new Error(t('No file')));
  }
  const { name, type, size, lastModified } = file;
  if (!VALID_MIME_TYPES.includes(type)) {
    return Promise.reject(new Error(t('Invalid mime type {0}', type)));
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      const rawCsv = event.target.result;
      const parsedValues = rawCsv && parseCsvValues(rawCsv);
      if (parsedValues) {
        resolve({
          data: parsedValues.data,
          name,
          type,
          size,
          lastModified,
        });
      }
    };
    reader.onerror = event => reject(event);
    reader.onabort = event => reject(event);
    reader.readAsText(file);
  });
};

export const convertParsedCsvToSensorValueObjects = (csvArray, defaults) =>
  csvArray.map(row =>
    csvColumns.reduce(
      (sensorProperties, columnConfig, columnIndex) => ({
        ...sensorProperties,
        [columnConfig.name]: columnConfig.get(row[columnConfig.name], defaults),
      }),
      {}
    )
  );
