import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;
Form.displayName = 'Form';

function propagateDownwards(scope, model, onChange, children) {
  if (children === undefined) {
    return [];
  }

  return React.Children.map(children, x => {
    if (typeof x === 'string' || !x) {
      return x;
    }

    const props = {};

    if (x.props !== undefined && x.props.id !== undefined) {
      props.id = `${scope}-${x.props.id}`;
    }

    if (x.props !== undefined && x.props.property !== undefined) {
      props.model = model;
      props.property = Array.isArray(x.props.property) ? x.props.property : [x.props.property];

      // Join onChange callbacks if element already has onChange callback.
      if (x.props.onChange !== undefined) {
        props.onChange = (property, value, override) => {
          onChange(property, value, override);
          x.props.onChange(property, value, override);
        };
      } else {
        props.onChange = onChange;
      }
    }

    const childs = propagateDownwards(scope, model, onChange, x.props && x.props.children);

    return React.cloneElement(x, props, childs);
  });
}

export const InputForm = React.forwardRef((props, ref) => {
  const newChilds = propagateDownwards(props.id, props.model, props.onPropertyChange, props.children);

  const submit = e => {
    e.preventDefault();
    props.onSubmit(e);
  };

  const { FormComponent } = props;
  return (
    <FormComponent
      ref={ref}
      data-test-id={props.id}
      id={props.id}
      name={props.id}
      onSubmit={submit}
      className={`input-form ${props.className}`}
    >
      {newChilds}
    </FormComponent>
  );
});
InputForm.displayName = 'InputForm';

InputForm.defaultProps = {
  FormComponent: Form,
};

export default InputForm;
