import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import IconButton from 'components/Button/IconButton';

const StyledViewModeButton = styled(IconButton)`
  padding: ${props => props.theme.spacing.xs};
  margin-left: ${props => props.theme.spacing.xxs};
  margin-right: ${props => props.isFilter && props.theme.spacing.sm};
  outline: none;
  text-transform: uppercase;
  height: auto;
  border-radius: 3px;

  svg {
    font-size: ${props => props.theme.font.size.md};
    transform: ${props => props.isFilter && 'rotate(90deg)'};
  }
`;

const ViewModeButton = ({ active, theme, isFilter, ...other }) => {
  return (
    <StyledViewModeButton
      {...other}
      stroke={isFilter ? theme.colors.white : active ? theme.colors.midnight : theme.colors.darkGray}
      color={isFilter ? theme.colors.midnight : theme.colors.transparent}
      isFilter={isFilter}
    />
  );
};

ViewModeButton.propTypes = {
  theme: PropTypes.object.isRequired,
  active: PropTypes.bool,
  isFilter: PropTypes.bool,
};

export default withTheme(ViewModeButton);
