import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputText from './InputText';
import Button from 'components/Button/Button';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const InputContainer = styled.div`
  flex: 1;
`;

const ButtonContainer = styled.div`
  margin-left: ${props => props.theme.spacing.md};

  button {
    height: 100%;
    width: auto;
    min-width: auto;
    border-radius: 3em;
  }
`;

class InputWithButton extends Component {
  state = {
    text: '',
    invalid: false,
  };

  render() {
    const { placeholder, buttonText, onSubmit } = this.props;

    return (
      <Container>
        <InputContainer>
          <InputText
            data-test-id="InputText"
            inputProps={{ name: 'inputWithButton' }}
            type="email"
            value={this.state.text}
            onChange={(property, text) => this.setState({ text, invalid: false })}
            placeholder={placeholder}
            invalid={this.state.invalid}
          />
        </InputContainer>
        <ButtonContainer>
          <Button
            type="submit"
            data-test-id="SubmitButton"
            onClick={e => {
              e.preventDefault();
              const valid = e.target.form.inputWithButton.checkValidity();

              if (!valid) {
                this.setState({ invalid: true });
                return;
              }

              this.setState({ text: '' });
              return onSubmit(this.state.text);
            }}
            disabled={!this.state.text}
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      </Container>
    );
  }
}

InputWithButton.displayname = 'InputWithButton';

InputWithButton.propTypes = {
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

InputWithButton.defaultProps = {
  placeholder: '',
};

export default InputWithButton;
