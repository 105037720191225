import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'components/Icon/Icon';

const IconContainer = styled.span`
  display: none;
  margin-right: ${props => props.theme.spacing.md};
  font-size: ${props => props.theme.font.size.md};

  div {
    width: 1em;
    height: 1em;
    margin: 0;
    vertical-align: middle;
  }

  svg {
    margin: 0;
  }

  ${props => props.theme.media.desktop`
        display: inline;
        div {
            width: 2em;
            height: 2em;
        }
    `}
`;
IconContainer.displayName = 'IconContainer';

const TableIcon = ({ name, iconType, size, fill, onIconClick }) => (
  <IconContainer>
    <Icon name={name} iconType={iconType} size={size} fill={fill} onClick={onIconClick} />
  </IconContainer>
);

TableIcon.displayName = 'TableIcon';

TableIcon.defaultProps = {
  onIconClick: undefined,
};

TableIcon.propTypes = {
  name: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  onIconClick: PropTypes.func,
};

export default TableIcon;
