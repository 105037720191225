import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import translations from 'decorators/Translations/translations';
import { createSelector } from 'reselect';

import {
  upsertSensor,
  upsertSensorHierarchy,
  upsertHierarchiesForSensor,
  loadSensorHierarchies,
  deleteSensorHierarchy,
  deleteSensor,
} from 'redux/modules/customer/sensorHierarchy';
import { loadLatestSensorsValues } from 'redux/modules/iot/values/sensor_values.js';
import { upsertImage } from 'redux/modules/customer/partnerImage';
import { showModal } from 'redux/modules/modal/modal';
import { uploadFile } from 'redux/modules/common/fileupload';

import EditOrCreateFloor from './EditOrCreateFloor';
import EditOrCreateSensor from './EditOrCreateSensor';
import EditOrCreateArea from './EditOrCreateArea';
import CreateGroup from './CreateGroup';
import BuildingAdminFloors from './BuildingAdminFloors';
import BuildingSensorTree from './BuildingSensorTree';
import EditSensorMeta from './EditSensorMeta';

import { MODALTYPE } from 'components/Modal/ModalTypes';
import {
  getBuildingFloorsFromHierarchy,
  getBuildingCoordsFromHierarchy,
  createFeature,
  getSensorFromHierarchy,
  getAssignedGroupsFromHierarchy,
  getBuildingSensorsFromHierarchy,
} from 'utils/Data/sensorHierarchy';
import { createFloorFeatures } from './utils';

class BuildingSensors extends PureComponent {
  state = {
    currentFloorId: null,
    scrollTo: null,
  };

  componentDidMount() {
    if (this.props.buildingHierarchy) {
      const sensorsIds = _.map(getBuildingSensorsFromHierarchy(this.props.buildingHierarchy), 'id');
      sensorsIds && sensorsIds.length > 0 && loadLatestSensorsValues(sensorsIds);
    }

    if (this.props.floors) {
      const floors = this.props.floors;
      const currentFloorId = floors && floors.length > 0 && _.orderBy(floors, ['order'], ['asc'])[0].id;

      this.setState({ currentFloorId });
    }
  }

  // Sets current floor to floorid
  setCurrentFloor = (e, floorId) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    this.setState({ currentFloorId: floorId });
  };

  // Set current floor as editable
  editFloor = e => {
    e.preventDefault();
    this.setState({
      editFloorId: this.state.currentFloorId,
    });
  };

  // Add floor
  addFloor = e => {
    e.preventDefault();
    this.setState({
      editFloorId: 'add',
    });
  };

  // Add sensor
  addSensor = (e, editParentId) => {
    e.preventDefault();
    this.setState({
      editNode: null,
      editSensorId: 'add',
      editParentId: editParentId || this.state.currentFloorId || this.getBuilding().id,
    });
  };

  // Add area
  addArea = e => {
    e.preventDefault();
    this.setState({
      editNode: null,
      editAreaId: 'add',
    });
  };

  // Add group
  addGroup = e => {
    e.preventDefault();
    this.setState({
      editNode: null,
      editGroupId: 'add',
    });
  };

  // Close edit dialog
  closeEdit = (e, notification) => {
    if (e) {
      e.preventDefault();
    }

    if (notification && notification.type && notification.message) {
      this.props.showNotification(notification);
    }

    this.setState({
      editNode: null,
      editFloorId: null,
      editSensorId: null,
      editAreaId: null,
      editGroupId: null,
      editSensorAnalyticsId: null,
      editSensorMetaId: null,
      editParentId: null,
    });
  };

  // Handle editing of feature node (sensor or area)
  handleEdit = node => {
    const { id, type, title } = node;
    const settings = {};
    const coords = this.props.coords;
    let featureCoords, coordsObject;

    if (type === 'area') {
      coordsObject = _.find(coords, { sensorHierarchyId: id, type: 'area' });
      featureCoords =
        coordsObject && coordsObject.area ? [_.first(coordsObject.area).map(coord => [coord.x, coord.y])] : null;
      settings.editAreaId = id;
    } else {
      coordsObject = _.find(coords, { sensorId: id });
      featureCoords = coordsObject && coordsObject.point ? [coordsObject.point.x, coordsObject.point.y] : null;
      settings.editSensorId = id;
    }
    const editNode = {
      ...node,
      coordsId: coordsObject ? coordsObject.id : null,
      coords: featureCoords,
      feature: featureCoords ? createFeature(featureCoords, id, type, title) : null,
    };

    this.setState({
      ...settings,
      editNode: editNode,
    });
  };

  // Handle editing sensor meta
  handleMeta = node => {
    this.setState({
      editSensorMetaId: node.id,
      editNode: node,
    });
  };

  // Handle sensor toggle (enable / disable)
  handleSensorToggle = async sensor => {
    const { t, functionalLocation, upsertSensor, loadSensorHierarchies, showNotification } = this.props;

    const result = await upsertSensor({
      id: sensor.id,
      disabled: !sensor.disabled,
    });

    if (!result.error) {
      showNotification({
        type: 'success',
        message: t('Sensor successfully saved'),
      });

      await loadSensorHierarchies(functionalLocation);
    }
  };

  // Handle edit or creating of floor
  handleEditOrCreateFloor = floorData => {
    const {
      updateSensorHierarchy,
      loadSensorHierarchies,
      updateFloorImage,
      functionalLocation,
      activePartner,
    } = this.props;
    return updateSensorHierarchy(floorData).then(data => {
      if (data.result) {
        if (floorData.imagePath) {
          let image = {
            id: data.result.imageId,
            path: floorData.imagePath,
            width: floorData.imageWidth,
            height: floorData.imageHeight,
            functionalLocation: data.result.functionalLocation,
            partnerNumber: activePartner && activePartner !== 'all' && activePartner,
            type: data.result.type,
            sensorHierarchyId: data.result.id,
          };
          if (!data.result.imageId) {
            image = _.omit(image, 'id');
          }
          updateFloorImage(image)
            .then(() => loadSensorHierarchies(functionalLocation))
            .then(() => {
              if (data.result.id) {
                this.setState({
                  currentFloorId: data.result.id,
                });
              }
            });
        } else {
          loadSensorHierarchies(functionalLocation).then(() => {
            if (data.result.id) {
              this.setState({
                currentFloorId: data.result.id,
              });
            }
          });
        }
        return Promise.resolve();
      }
      return Promise.reject(new Error(data.error));
    });
  };

  // Toggles floor removal modal
  toggleRemoveFloorModal = (id, name) => {
    this.props.showModal(
      MODALTYPE.CONFIRMATION_DELETE_FLOOR_MODAL,
      () => this.handleRemoveFloorPreConditions(id),
      () => this.handleRemoveFloor(id),
      `${this.props.t('Floor')} ${this.props.t('{0} will be removed', name)}`
    );
  };

  // Toggles sensor removal modal
  toggleRemoveSensorModal = (id, name) => {
    this.props.showModal(
      MODALTYPE.CONFIRMATION_DELETE_SENSOR_MODAL,
      null,
      () => this.handleRemoveSensor(id),
      `${this.props.t('Sensor')} ${this.props.t('{0} will be removed', name)}`
    );
  };

  // Toggles area removal modal
  toggleRemoveAreaModal = (id, name) => {
    this.props.showModal(
      MODALTYPE.CONFIRMATION_DELETE_AREA_MODAL,
      null,
      () => this.handleRemoveAreaOrGroup(id, true),
      `${this.props.t('Custom shape area')} ${this.props.t('{0} will be removed', name)}`
    );
  };

  // Toggles group removal modal
  toggleRemoveGroupModal = (id, name) => {
    this.props.showModal(
      MODALTYPE.CONFIRMATION_DELETE_GROUP_MODAL,
      null,
      () => this.handleRemoveAreaOrGroup(id),
      `${this.props.t('Group')} ${this.props.t('{0} will be removed', name)}`
    );
  };

  // Toggles sensor location removal modal
  handleRemoveSensorLocationModal = node => {
    this.props.showModal(
      MODALTYPE.CONFIRMATION_DELETE_SENSOR_LOCATION_MODAL,
      null,
      () => this.handleRemoveSensorLocation(node.id, node.hierarchyId),
      this.props.t('Location for measuring point {0} will be removed', node.name)
    );
  };

  // Checks if floor can be deleted
  // Areas and sensors must be removed before floor can be deleted
  handleRemoveFloorPreConditions = id => {
    const { floors } = this.props;
    const floor = _.find(floors, { id });
    const areas = _.find(floor.children, { type: 'area' });
    const hasSensors = floor.sensors && floor.sensors.length > 0;
    const hasAreas = areas && areas.length > 0;

    if (hasSensors || hasAreas) {
      return Promise.reject(new Error('Cannot remove floor that has sensors or areas'));
    }
    return Promise.resolve();
  };

  // Handle floor removal
  handleRemoveFloor = id => {
    const { t, deleteFloor, loadSensorHierarchies, functionalLocation, showNotification } = this.props;

    this.setState({ currentFloorId: null });

    return deleteFloor(id, functionalLocation).then(data => {
      if (data.result) {
        const notification = {
          type: 'success',
          message: t('Floor successfully removed'),
        };
        showNotification(notification);
        loadSensorHierarchies(functionalLocation).then(() => Promise.resolve());
      } else {
        return Promise.reject(new Error('Floor removal failed'));
      }
    });
  };

  // Handle area or sensor removal button click
  handleAreaOrSensorRemove = node => {
    const { id, type, name } = node;

    if (type === 'area') {
      this.toggleRemoveAreaModal(id, name);
    } else if (type === 'group' || type === 'group_energy') {
      this.toggleRemoveGroupModal(id, name);
    } else if (type === 'sensor') {
      this.toggleRemoveSensorModal(id, name);
    } else {
      console.error(`Unknown node with type: ${type}`);
    }
  };

  handleRemoveSensorLocation = (sensorId, hierarchyId) => {
    const building = this.getBuilding();
    const sensor = getSensorFromHierarchy(building, sensorId);
    const hierarchies = getAssignedGroupsFromHierarchy(building, sensorId).reduce((accu, hierarchy) => {
      if (hierarchy.id !== hierarchyId) {
        accu.push(hierarchy.id);
      }
      return accu;
    }, []);
    // ensure that sensor will not be left orphan
    if (hierarchies.length === 0 && !sensor.parentId) {
      hierarchies.push(building.id);
    }

    return this.props.upsertHierarchiesForSensor(sensorId, hierarchies).then(data => {
      if (data.error) {
        return Promise.reject(new Error('Removing sensor from location failed'));
      }
      return this.onDeleteSuccess(this.props.t('Sensor successfully removed from location'));
    });
  };

  handleTitleSaveClick = node => {
    const { id, name } = node;
    const data = { id };
    if (node.type === 'floor') {
      data.shortName = name;
    } else {
      data.name = name;
    }
    this.props.updateSensorHierarchy(data).then(({ result }) => {
      if (!!result) {
        const { t, loadSensorHierarchies, functionalLocation, showNotification } = this.props;
        const notification = {
          type: 'success',
          message: t('Hierarchy name updated'),
        };
        showNotification(notification);
        loadSensorHierarchies(functionalLocation);
      }
    });
  };

  // Handle area or group removal
  handleRemoveAreaOrGroup = (id, isArea = false) => {
    const { t, deleteAreaOrGroup, functionalLocation } = this.props;
    return deleteAreaOrGroup(id, functionalLocation).then(data => {
      if (data.error) {
        return Promise.reject(new Error(isArea ? 'Deleting custom shape area failed' : 'Deleting group failed'));
      }
      return this.onDeleteSuccess(
        isArea ? t('Custom shape area successfully removed') : t('Group successfully removed')
      );
    });
  };

  // Handle sensor removal
  handleRemoveSensor = id => {
    const { t, deleteSensor, functionalLocation } = this.props;
    return deleteSensor(id, functionalLocation).then(data => {
      if (data.error) {
        return Promise.reject(new Error('Deleting sensor failed'));
      }
      return this.onDeleteSuccess(t('Sensor successfully removed'));
    });
  };

  // Callback for successful deletion
  onDeleteSuccess = message => {
    const { loadSensorHierarchies, functionalLocation, showNotification } = this.props;
    this.setState({ openMenu: null });

    return loadSensorHierarchies(functionalLocation).then(() => {
      const notification = {
        type: 'success',
        message: message,
      };
      showNotification(notification);
    });
  };

  handleScrollToNode = nodeId => {
    this.setState({ scrollTo: nodeId });
    setTimeout(() => this.setState({ scrollTo: null }), 500);
  };

  // Is floor new or are we editing old one
  isAddFloorMode = mode => mode === 'add';

  // File upload handler
  uploadFile = obj => this.props.uploadFile(obj);

  getBuilding = () => {
    const buildingHierarchy = this.props.buildingHierarchy;
    return _.isArray(buildingHierarchy) ? _.head(buildingHierarchy) : buildingHierarchy;
  };

  render() {
    const {
      t,
      floors,
      functionalLocation,
      fileupload: { uploading },
      latestValuesBySensorId,
      buildingHierarchy,
    } = this.props;

    const {
      currentFloorId,
      editFloorId,
      editSensorId,
      editAreaId,
      editGroupId,
      editNode,
      editSensorMetaId,
      editParentId,
    } = this.state;

    const building = this.getBuilding();

    let floorData = {};

    if (floors && floors.length > 0 && currentFloorId) {
      const floor = floors[_.findIndex(floors, { id: currentFloorId })];
      floorData = createFloorFeatures(floor, latestValuesBySensorId, t);
    }

    return (
      <Fragment>
        {building && (
          <BuildingAdminFloors
            t={t}
            floors={floors}
            floorData={floorData}
            addFloor={this.addFloor}
            addSensor={this.addSensor}
            addArea={this.addArea}
            editFloor={this.editFloor}
            handleRemoveFloorModal={this.toggleRemoveFloorModal}
            handleScrollToNode={this.handleScrollToNode}
            onCurrentFloorChange={this.setCurrentFloor}
            currentFloorId={currentFloorId}
          />
        )}
        {building && (
          <BuildingSensorTree
            t={t}
            scrollToId={this.state.scrollTo}
            buildingId={building.id}
            buildingHierarchy={buildingHierarchy}
            addGroup={this.addGroup}
            addSensor={this.addSensor}
            editSensor={this.handleEdit}
            editSensorMeta={this.handleMeta}
            toggleSensor={this.handleSensorToggle}
            removeSensorLocation={this.handleRemoveSensorLocationModal}
            onDelete={this.handleAreaOrSensorRemove}
            onTitleSave={this.handleTitleSaveClick}
          />
        )}

        {/** MODALS: */}
        {editFloorId && (
          <EditOrCreateFloor
            editId={editFloorId}
            onClose={this.closeEdit}
            handleEditOrCreateFloor={this.handleEditOrCreateFloor}
            isAddFloorMode={this.isAddFloorMode(editFloorId)}
            functionalLocation={functionalLocation}
            floorData={this.isAddFloorMode(editFloorId) ? {} : floorData}
            uploadFile={this.uploadFile}
            uploading={uploading}
          />
        )}
        {editSensorId && (
          <EditOrCreateSensor
            editId={editSensorId}
            editParentId={editParentId}
            functionalLocation={functionalLocation}
            onSubmit={this.closeEdit}
            onCancel={this.closeEdit}
            coordsId={editNode && editNode.coordsId}
            coords={editNode && editNode.coords}
            floors={floors}
          />
        )}
        {editAreaId && (
          <EditOrCreateArea
            editId={editAreaId}
            node={editNode}
            functionalLocation={functionalLocation}
            onSubmit={this.closeEdit}
            onCancel={this.closeEdit}
            floorId={(editNode && editNode.hierarchyId) || currentFloorId}
            floors={floors}
          />
        )}
        {editGroupId && (
          <CreateGroup
            editId={editGroupId}
            node={editNode}
            functionalLocation={functionalLocation}
            building={building}
            onSubmit={this.closeEdit}
            onCancel={this.closeEdit}
          />
        )}
        {editSensorMetaId && (
          <EditSensorMeta
            node={editNode}
            editId={editSensorMetaId}
            onSubmit={this.closeEdit}
            onClose={this.closeEdit}
            functionalLocation={functionalLocation}
          />
        )}
      </Fragment>
    );
  }
}

BuildingSensors.propTypes = {
  /** Translations function */
  t: PropTypes.func.isRequired,
  /** Currently active partner */
  activePartner: PropTypes.string.isRequired,
  /** Floors on current hierarchy */
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Coordinates available */
  coords: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Building sensor hierarchy */
  buildingHierarchy: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Fileupload state */
  fileupload: PropTypes.object,
  /** Updates sensor hierachy */
  updateSensorHierarchy: PropTypes.func.isRequired,
  /** Loads sensor hierarchies */
  loadSensorHierarchies: PropTypes.func.isRequired,
  /** Updates floor image */
  updateFloorImage: PropTypes.func.isRequired,
  /** Deletes floor by id */
  deleteFloor: PropTypes.func.isRequired,
  /** Deletes sensor by id */
  deleteSensor: PropTypes.func.isRequired,
  /** Deletes area or group by id */
  deleteAreaOrGroup: PropTypes.func.isRequired,
  /** Shows global modal */
  showModal: PropTypes.func.isRequired,
  /** Uploads file */
  uploadFile: PropTypes.func.isRequired,
  /** Updates sensor */
  upsertSensor: PropTypes.func.isRequired,
  /** Latest sensor values by sensor id */
  latestValuesBySensorId: PropTypes.object.isRequired,
  /** Loads latest sensor values */
  loadLatestSensorsValues: PropTypes.func.isRequired,
};

const EMPTY_ARRAY = [];

const hierarchySelector = (state, props) =>
  state.sensorHierarchy.buildingHierarchy[props.functionalLocation] || EMPTY_ARRAY;

const floorsSelector = createSelector(
  hierarchySelector,
  hierarchy => (hierarchy && hierarchy.length > 0 ? getBuildingFloorsFromHierarchy(_.head(hierarchy)) : EMPTY_ARRAY)
);

const coordsSelector = createSelector(
  hierarchySelector,
  hierarchy => (hierarchy && hierarchy.length > 0 ? getBuildingCoordsFromHierarchy(_.head(hierarchy)) : EMPTY_ARRAY)
);

const mapStateToProps = (state, props) => ({
  buildingHierarchy: hierarchySelector(state, props),
  coords: coordsSelector(state, props),
  floors: floorsSelector(state, props),
  fileupload: state.fileupload,
  latestValuesBySensorId: state.values.sensors.latestValuesBySensorId,
});

const mapDispatchToProps = dispatch => ({
  updateSensorHierarchy: sensorHierarchy => dispatch(upsertSensorHierarchy(sensorHierarchy)),
  loadSensorHierarchies: functionalLocation => dispatch(loadSensorHierarchies(functionalLocation, true)),
  updateFloorImage: image => dispatch(upsertImage(image)),
  deleteFloor: (id, functionalLocation) => dispatch(deleteSensorHierarchy(id, functionalLocation)),
  deleteSensor: (id, functionalLocation) => dispatch(deleteSensor(id, functionalLocation)),
  deleteAreaOrGroup: (id, functionalLocation) => dispatch(deleteSensorHierarchy(id, functionalLocation)),
  showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
    dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps)),
  uploadFile: obj => dispatch(uploadFile(obj)),
  upsertSensor: data => dispatch(upsertSensor(data)),
  upsertHierarchiesForSensor: (sensorId, hierarchies) => dispatch(upsertHierarchiesForSensor(sensorId, hierarchies)),
  loadLatestSensorsValues: sensorsIds => dispatch(loadLatestSensorsValues(sensorsIds)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(BuildingSensors));
