import { createReducerFromMapping } from 'redux/utils/index.js';
import _ from 'lodash';

import { Profile, MasterData } from '@caverion/redux/api/actions';
import cookies from 'utils/Cookies/Cookies.js';
import { fixLanguage } from 'utils/Data/language';
import { getValidPartnerFromRouter } from 'utils/Data/partners';
import { deriveEditedPermissions, isExtenalADUser, getEnabledTeasers } from 'utils/profile';

import { NEW_PROFILE_FEATURES, getSyntheticFeatures, mapBuildingTabToNewTabs } from 'utils/Data/features';

const SEARCH_LIMIT = 2000;
export const SEARCH_TYPE_PARTNER_NUMBER = 'SEARCH_TYPE_PARTNER_NUMBER';
export const SEARCH_TYPE_PARTNER_PERMISSION = 'SEARCH_TYPE_PARTNER_PERMISSION';
export const SEARCH_TYPE_FUNCTIONAL_LOCATION = 'SEARCH_TYPE_FUNCTIONAL_LOCATION';

const initialState = {
  profile: {},
  permissionTree: {},
  topLevelPermissions: [],
  newProfile: {
    language: 'en',
    role: 'user',
    permissions: {},
    features: NEW_PROFILE_FEATURES,
    featureTeasers: [],
  },
  welcomeProfile: {},
  profiles: {},
  editProfiles: {},
  activePartner: null,
  error: '',
  usernameExists: {},
  resentVerification: {},
  loading: false,
  userSearch: undefined,
  userSearchFilters: {
    limit: SEARCH_LIMIT,
    internal: true,
    external: true,
  },
  apiKeys: {},
  apiQuotas: {},
};

export const LOAD_ME = 'CUSTOMER_PLATFORM/Profile/LOAD_ME';
export const LOAD_ME_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_ME_SUCCESS';
export const LOAD_ME_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_ME_FAIL';

export const loadMe = () => {
  return async dispatch => {
    dispatch({ type: LOAD_ME });
    try {
      const result = await dispatch(Profile.me());
      dispatch(Profile.setCacheTag(result.cacheTag));
      return dispatch({
        type: LOAD_ME_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_ME_FAIL,
        error: 'Loading own profile failed!',
      });
    }
  };
};

export const LOAD = 'CUSTOMER_PLATFORM/Profile/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_FAIL';

export const load = id => {
  return async dispatch => {
    dispatch({ type: LOAD });
    try {
      const result = await dispatch(Profile.load(id));

      return dispatch({
        type: LOAD_SUCCESS,
        id,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        error: 'Loading profile failed!',
      });
    }
  };
};

export const LOAD_PERMISSIONS = 'CUSTOMER_PLATFORM/Profile/LOAD_PERMISSIONS';
export const LOAD_PERMISSIONS_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_PERMISSIONS_SUCCESS';
export const LOAD_PERMISSIONS_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_PERMISSIONS_FAIL';

/**
 * Load permissions based on current context.
 *
 * The following scenarios are covered:
 *  1. Load overview without selected partner.
 *  2. Load partner overview.
 *  3. Load children for selected functional location.
 *
 * @param {hierarchy} functionalLocation Current functional location, if any.
 */
export function loadPermissions(functionalLocation) {
  const path = !functionalLocation ? [] : functionalLocation.path;
  return async (dispatch, getState) => {
    const state = getState();
    const partnerNumber = getValidPartnerFromRouter(state.router) || undefined;
    const functionalLocationFilter = functionalLocation && {
      where: {
        path: {
          'any x': {
            x: functionalLocation.functionalLocation,
          },
        },
        functionalLocationParent: path[path.length - 1],
      },
    };

    dispatch({ type: LOAD_PERMISSIONS });
    try {
      const result = await (functionalLocation
        ? dispatch(MasterData.hierarchies(functionalLocationFilter))
        : dispatch(MasterData.hierarchiesOverview(partnerNumber)));
      return dispatch({
        type: LOAD_PERMISSIONS_SUCCESS,
        result,
        path,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_PERMISSIONS_FAIL,
        path,
        error: 'Loading permissions failed!',
      });
    }
  };
}

export const LOAD_WELCOME = 'CUSTOMER_PLATFORM/Profile/LOAD_WELCOME';
export const LOAD_WELCOME_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_WELCOME_SUCCESS';
export const LOAD_WELCOME_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_WELCOME_FAIL';

export const loadWelcome = verificationCode => {
  return async dispatch => {
    dispatch({ type: LOAD_WELCOME });
    try {
      const result = await dispatch(Profile.loadWelcome(verificationCode));
      return dispatch({
        type: LOAD_WELCOME_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_WELCOME_FAIL,
        error: 'Loading profile failed!',
      });
    }
  };
};

export const RESEND_VERIFICATION = 'CUSTOMER_PLATFORM/Profile/RESEND_VERIFICATION';
export const RESEND_VERIFICATION_SUCCESS = 'CUSTOMER_PLATFORM/Profile/RESEND_VERIFICATION_SUCCESS';
export const RESEND_VERIFICATION_FAIL = 'CUSTOMER_PLATFORM/Profile/RESEND_VERIFICATION_FAIL';

export const resendVerification = id => {
  return async dispatch => {
    dispatch({ type: RESEND_VERIFICATION });
    try {
      await dispatch(Profile.resendVerification(id));
      return dispatch({
        type: RESEND_VERIFICATION_SUCCESS,
        id,
      });
    } catch (error) {
      return dispatch({
        type: RESEND_VERIFICATION_FAIL,
        id,
        error: 'Sending verification code failed!',
      });
    }
  };
};

export const SET_NEW_PROFILE_PROPERTY = 'CUSTOMER_PLATFORM/Profile/SET_NEW_PROFILE_PROPERTY';

export const setNewProfileProperty = (property, value) => {
  return {
    type: SET_NEW_PROFILE_PROPERTY,
    property,
    value,
  };
};

export const SET_PROPERTY = 'CUSTOMER_PLATFORM/Profile/SET_PROPERTY';

export const setProperty = (id, property, value) => {
  return {
    type: SET_PROPERTY,
    id,
    property,
    value,
  };
};

export const SET_EDIT = 'CUSTOMER_PLATFORM/Profile/SET_EDIT';

export const setEdit = id => {
  return {
    type: SET_EDIT,
    id,
  };
};

export const CLEAR_EDIT = 'CUSTOMER_PLATFORM/Profile/CLEAR_EDIT';

export const clearEdit = () => ({
  type: CLEAR_EDIT,
});

export const SAVE = 'CUSTOMER_PLATFORM/Profile/SAVE';
export const SAVE_SUCCESS = 'CUSTOMER_PLATFORM/Profile/SAVE_SUCCESS';
export const SAVE_FAIL = 'CUSTOMER_PLATFORM/Profile/SAVE_FAIL';

export const save = (id, data) => {
  const profile = {
    ...data,
    ...deriveEditedPermissions(data),
    ...getEnabledTeasers(data.features, data.featureTeasers),
  };
  const cleanData = _.omit(profile, [
    'keyword',
    'edited',
    'addedFunctionalLocations',
    'deletedFunctionalLocations',
    'addedPartnerPermissions',
    'deletedPartnerPermissions',
    'syntheticFeatures',
    'inactivePermissions',
    'cacheTag',
  ]);

  return async (dispatch, getState) => {
    dispatch({ type: SAVE });
    try {
      const result = await dispatch(Profile.save(JSON.stringify(cleanData)));
      if (id === getState().profile.profile.id) {
        dispatch(Profile.setCacheTag(result.cacheTag));
      }

      return dispatch({
        type: SAVE_SUCCESS,
        id,
        result,
      });
    } catch (error) {
      return dispatch({
        type: SAVE_FAIL,
        error: 'Saving profile failed!',
      });
    }
  };
};

export const VERIFY = 'CUSTOMER_PLATFORM/Profile/VERIFY';
export const VERIFY_SUCCESS = 'CUSTOMER_PLATFORM/Profile/VERIFY_SUCCESS';
export const VERIFY_FAIL = 'CUSTOMER_PLATFORM/Profile/VERIFY_FAIL';
export const VERIFY_PASSWORDS_DID_NOT_MATCH = 'CUSTOMER_PLATFORM/Profile/VERIFY_PASSWORDS_DID_NOT_MATCH';

export const verify = (verificationCode, password, passwordAgain) => {
  if (password !== passwordAgain) {
    return {
      type: VERIFY_PASSWORDS_DID_NOT_MATCH,
    };
  }

  return async dispatch => {
    dispatch({ type: VERIFY });
    try {
      const result = await dispatch(Profile.verify(verificationCode, password));
      return dispatch({
        type: VERIFY_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: VERIFY_FAIL,
        error: 'Setting password failed! Check that the password meets complexity requirements.',
      });
    }
  };
};

export const CREATE = 'CUSTOMER_PLATFORM/Profile/CREATE';
export const CREATE_SUCCESS = 'CUSTOMER_PLATFORM/Profile/CREATE_SUCCESS';
export const CREATE_FAIL = 'CUSTOMER_PLATFORM/Profile/CREATE_FAIL';

export const create = data => {
  const cleanData = _.omit(data, [
    'keyword',
    'edited',
    'addedFunctionalLocations',
    'deletedFunctionalLocations',
    'addedPartnerPermissions',
    'deletedPartnerPermissions',
    'syntheticFeatures',
    'inactivePermissions',
  ]);

  return async dispatch => {
    dispatch({ type: CREATE });
    try {
      const result = await dispatch(Profile.create(JSON.stringify(cleanData)));
      return dispatch({
        type: CREATE_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: CREATE_FAIL,
        error: 'Creating profile failed!',
      });
    }
  };
};

export const SEARCH = 'CUSTOMER_PLATFORM/Profile/SEARCH';
export const SEARCH_SUCCESS = 'CUSTOMER_PLATFORM/Profile/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'CUSTOMER_PLATFORM/Profile/SEARCH_FAIL';

export const search = (keyword, searchType) => async dispatch => {
  if (searchType === SEARCH_TYPE_FUNCTIONAL_LOCATION) {
    return await dispatch(
      Profile.search({
        functionalLocation: keyword,
        limit: SEARCH_LIMIT,
      })
    );
  }

  return dispatch(searchByProfileAttributes(keyword, searchType));
};

const searchByProfileAttributes = (keyword, searchType) => {
  let filter = {};
  switch (searchType) {
    case SEARCH_TYPE_PARTNER_NUMBER:
      filter = {
        where: {
          partnerNumbers: {
            any: keyword,
          },
        },
      };
      break;

    case SEARCH_TYPE_PARTNER_PERMISSION:
      filter = {
        where: {
          partnerPermissions: {
            any: keyword,
          },
        },
      };
      break;

    default:
      throw new Error(`Unknown search type: '${searchType}'`);
  }

  return async dispatch => dispatch(Profile.find(filter));
};

export const SEARCH_USERNAME = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERNAME';
export const SEARCH_USERNAME_SUCCESS = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERNAME_SUCCESS';
export const SEARCH_USERNAME_FAIL = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERNAME_FAIL';

export const searchUsername = username => {
  const filter = {
    where: { username: username },
  };

  return async dispatch => {
    dispatch({ type: SEARCH_USERNAME });
    try {
      const result = await dispatch(Profile.find(filter));

      return dispatch({
        type: SEARCH_USERNAME_SUCCESS,
        username,
        result,
      });
    } catch (error) {
      return dispatch({
        type: SEARCH_USERNAME_FAIL,
        error: 'Username search failed!',
      });
    }
  };
};

export const SEARCH_USERS = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAIL = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS_FAIL';

export const searchUsers = filter => async dispatch => {
  dispatch({ type: SEARCH_USERS });
  try {
    const result = await dispatch(Profile.search(filter));
    return dispatch({
      type: SEARCH_USERS_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: SEARCH_USERS_FAIL,
      error: 'Search failed!',
    });
  }
};

export const SEARCH_USERS_CLEAR = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS_CLEAR';

export const clearUserSearch = () => ({
  type: SEARCH_USERS_CLEAR,
});

export const SEARCH_USERS_SET_FILTERS = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS_SET_FILTERS';

export const setUserSearchFilters = filters => ({
  type: SEARCH_USERS_SET_FILTERS,
  filters,
});

export const SET_ACTIVE_PARTNER = 'CUSTOMER_PLATFORM/Profile/SET_ACTIVE_PARTNER';

export const setActivePartner = partnerNumber => {
  cookies.setItem('selectedPartner', partnerNumber, Infinity, '/');

  return {
    type: SET_ACTIVE_PARTNER,
    partnerNumber: partnerNumber || null,
  };
};

export const DELETE_PROFILE = 'CUSTOMER_PLATFORM/Profile/DELETE_PROFILE';
export const DELETE_PROFILE_SUCCESS = 'CUSTOMER_PLATFORM/Profile/DELETE_PROFILE_SUCCESS';
export const DELETE_PROFILE_FAIL = 'CUSTOMER_PLATFORM/Profile/DELETE_PROFILE_FAIL';

export const deleteProfile = profileId => async dispatch => {
  dispatch({ type: DELETE_PROFILE });
  try {
    await dispatch(Profile.delete(profileId));
    return dispatch({
      type: DELETE_PROFILE_SUCCESS,
      profileId,
    });
  } catch (error) {
    return dispatch({
      type: DELETE_PROFILE_FAIL,
      profileId,
      error: 'Failed to delete profile.',
    });
  }
};

export const UPDATE_TRANSLATIONS = 'CUSTOMER_PLATFORM/Profile/UPDATE_TRANSLATIONS';
export const UPDATE_TRANSLATIONS_SUCCESS = 'CUSTOMER_PLATFORM/Profile/UPDATE_TRANSLATIONS_SUCCESS';
export const UPDATE_TRANSLATIONS_FAIL = 'CUSTOMER_PLATFORM/Profile/UPDATE_TRANSLATIONS_FAIL';

export const updateTranslations = () => {
  return async dispatch => {
    dispatch({ type: UPDATE_TRANSLATIONS });
    try {
      await dispatch(Profile.updateTranslations());
      return dispatch({ type: UPDATE_TRANSLATIONS_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPDATE_TRANSLATIONS_FAIL,
        error: 'Updating translations failed!',
      });
    }
  };
};

export const LOAD_API_KEYS = 'CUSTOMER_PLATFORM/Profile/LOAD_API_KEYS';
export const LOAD_API_KEYS_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_API_KEYS_SUCCESS';
export const LOAD_API_KEYS_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_API_KEYS_FAIL';

export const loadApiKeys = (profileId, cache = true) => async (dispatch, getState) => {
  if (cache && getState().profile.apiKeys[profileId]) {
    return;
  }

  dispatch({ type: LOAD_API_KEYS, profileId });
  try {
    const result = await dispatch(Profile.getApiKeys(profileId));
    return dispatch({ type: LOAD_API_KEYS_SUCCESS, profileId, result });
  } catch (error) {
    return dispatch({ type: LOAD_API_KEYS_FAIL, profileId, error: error.message });
  }
};

export const DELETE_API_KEY = 'CUSTOMER_PLATFORM/Profile/DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'CUSTOMER_PLATFORM/Profile/DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAIL = 'CUSTOMER_PLATFORM/Profile/DELETE_API_KEY_FAIL';

export const deleteApiKey = (profileId, keyId) => async dispatch => {
  dispatch({ type: DELETE_API_KEY, profileId });
  try {
    await dispatch(Profile.deleteApiKey(keyId));
    return dispatch({ type: DELETE_API_KEY_SUCCESS, profileId, keyId });
  } catch (error) {
    return dispatch({ type: DELETE_API_KEY_FAIL, profileId, error: error.message });
  }
};

export const CREATE_API_KEY = 'CUSTOMER_PLATFORM/Profile/CREATE_API_KEY';
export const CREATE_API_KEY_SUCCESS = 'CUSTOMER_PLATFORM/Profile/CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_FAIL = 'CUSTOMER_PLATFORM/Profile/CREATE_API_KEY_FAIL';

export const createApiKey = (profileId, apiKey) => async dispatch => {
  dispatch({ type: CREATE_API_KEY, profileId });
  try {
    const result = await dispatch(Profile.createApiKey({ ...apiKey, profileId }));
    return dispatch({ type: CREATE_API_KEY_SUCCESS, profileId, result });
  } catch (error) {
    return dispatch({ type: CREATE_API_KEY_FAIL, profileId, error: error.message });
  }
};

export const LOAD_API_QUOTA = 'CUSTOMER_PLATFORM/Profile/LOAD_API_QUOTA';
export const LOAD_API_QUOTA_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_API_QUOTA_SUCCESS';
export const LOAD_API_QUOTA_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_API_QUOTA_FAIL';

export const loadApiQuota = (profileId, cache = true) => async (dispatch, getState) => {
  if (cache && getState().profile.apiQuotas[profileId]) {
    return;
  }

  dispatch({ type: LOAD_API_QUOTA, profileId });
  try {
    const result = await dispatch(Profile.getApiQuota(profileId));
    return dispatch({ type: LOAD_API_QUOTA_SUCCESS, profileId, result });
  } catch (error) {
    return dispatch({ type: LOAD_API_QUOTA_FAIL, profileId, error: error.message });
  }
};

export const SET_API_QUOTA = 'CUSTOMER_PLATFORM/Profile/SET_API_QUOTA';
export const SET_API_QUOTA_SUCCESS = 'CUSTOMER_PLATFORM/Profile/SET_API_QUOTA_SUCCESS';
export const SET_API_QUOTA_FAIL = 'CUSTOMER_PLATFORM/Profile/SET_API_QUOTA_FAIL';

export const setApiQuota = (profileId, perHour) => async dispatch => {
  dispatch({ type: SET_API_QUOTA, profileId });
  try {
    const result = await dispatch(
      perHour ? Profile.putApiQuota(profileId, { perHour }) : Profile.deleteApiQuota(profileId)
    );

    return dispatch({ type: SET_API_QUOTA_SUCCESS, profileId, result });
  } catch (error) {
    return dispatch({ type: SET_API_QUOTA_FAIL, profileId, error: error.message });
  }
};

export const normalizeName = name => {
  const substitutionMap = {
    À: 'A',
    Á: 'A',
    Â: 'A',
    Ã: 'A',
    Ä: 'A',
    Å: 'A',
    Ấ: 'A',
    Ắ: 'A',
    Ẳ: 'A',
    Ẵ: 'A',
    Ặ: 'A',
    Æ: 'AE',
    Ầ: 'A',
    Ằ: 'A',
    Ȃ: 'A',
    Ç: 'C',
    Ḉ: 'C',
    È: 'E',
    É: 'E',
    Ê: 'E',
    Ë: 'E',
    Ế: 'E',
    Ḗ: 'E',
    Ề: 'E',
    Ḕ: 'E',
    Ḝ: 'E',
    Ȇ: 'E',
    Ì: 'I',
    Í: 'I',
    Î: 'I',
    Ï: 'I',
    Ḯ: 'I',
    Ȋ: 'I',
    Ð: 'D',
    Ñ: 'N',
    Ò: 'O',
    Ó: 'O',
    Ô: 'O',
    Õ: 'O',
    Ö: 'O',
    Ø: 'O',
    Ố: 'O',
    Ṍ: 'O',
    Ṓ: 'O',
    Ȏ: 'O',
    Ù: 'U',
    Ú: 'U',
    Û: 'U',
    Ü: 'U',
    Ý: 'Y',
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    å: 'a',
    ấ: 'a',
    ắ: 'a',
    ẳ: 'a',
    ẵ: 'a',
    ặ: 'a',
    æ: 'ae',
    ầ: 'a',
    ằ: 'a',
    ȃ: 'a',
    ç: 'c',
    ḉ: 'c',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    ế: 'e',
    ḗ: 'e',
    ề: 'e',
    ḕ: 'e',
    ḝ: 'e',
    ȇ: 'e',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    ḯ: 'i',
    ȋ: 'i',
    ð: 'd',
    ñ: 'n',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ø: 'o',
    ố: 'o',
    ṍ: 'o',
    ṓ: 'o',
    ȏ: 'o',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    ý: 'y',
    ÿ: 'y',
    Ā: 'A',
    ā: 'a',
    Ă: 'A',
    ă: 'a',
    Ą: 'A',
    ą: 'a',
    Ć: 'C',
    ć: 'c',
    Ĉ: 'C',
    ĉ: 'c',
    Ċ: 'C',
    ċ: 'c',
    Č: 'C',
    č: 'c',
    C̆: 'C',
    c̆: 'c',
    Ď: 'D',
    ď: 'd',
    Đ: 'D',
    đ: 'd',
    Ē: 'E',
    ē: 'e',
    Ĕ: 'E',
    ĕ: 'e',
    Ė: 'E',
    ė: 'e',
    Ę: 'E',
    ę: 'e',
    Ě: 'E',
    ě: 'e',
    Ĝ: 'G',
    Ǵ: 'G',
    ĝ: 'g',
    ǵ: 'g',
    Ğ: 'G',
    ğ: 'g',
    Ġ: 'G',
    ġ: 'g',
    Ģ: 'G',
    ģ: 'g',
    Ĥ: 'H',
    ĥ: 'h',
    Ħ: 'H',
    ħ: 'h',
    Ḫ: 'H',
    ḫ: 'h',
    Ĩ: 'I',
    ĩ: 'i',
    Ī: 'I',
    ī: 'i',
    Ĭ: 'I',
    ĭ: 'i',
    Į: 'I',
    į: 'i',
    İ: 'I',
    ı: 'i',
    Ĳ: 'IJ',
    ĳ: 'ij',
    Ĵ: 'J',
    ĵ: 'j',
    Ķ: 'K',
    ķ: 'k',
    Ḱ: 'K',
    ḱ: 'k',
    K̆: 'K',
    k̆: 'k',
    Ĺ: 'L',
    ĺ: 'l',
    Ļ: 'L',
    ļ: 'l',
    Ľ: 'L',
    ľ: 'l',
    Ŀ: 'L',
    ŀ: 'l',
    Ł: 'l',
    ł: 'l',
    Ḿ: 'M',
    ḿ: 'm',
    M̆: 'M',
    m̆: 'm',
    Ń: 'N',
    ń: 'n',
    Ņ: 'N',
    ņ: 'n',
    Ň: 'N',
    ň: 'n',
    ŉ: 'n',
    N̆: 'N',
    n̆: 'n',
    Ō: 'O',
    ō: 'o',
    Ŏ: 'O',
    ŏ: 'o',
    Ő: 'O',
    ő: 'o',
    Œ: 'OE',
    œ: 'oe',
    P̆: 'P',
    p̆: 'p',
    Ŕ: 'R',
    ŕ: 'r',
    Ŗ: 'R',
    ŗ: 'r',
    Ř: 'R',
    ř: 'r',
    R̆: 'R',
    r̆: 'r',
    Ȓ: 'R',
    ȓ: 'r',
    Ś: 'S',
    ś: 's',
    Ŝ: 'S',
    ŝ: 's',
    Ş: 'S',
    Ș: 'S',
    ș: 's',
    ş: 's',
    Š: 'S',
    š: 's',
    ß: 's',
    Ţ: 'T',
    ţ: 't',
    ț: 't',
    Ț: 'T',
    Ť: 'T',
    ť: 't',
    Ŧ: 'T',
    ŧ: 't',
    T̆: 'T',
    t̆: 't',
    Ũ: 'U',
    ũ: 'u',
    Ū: 'U',
    ū: 'u',
    Ŭ: 'U',
    ŭ: 'u',
    Ů: 'U',
    ů: 'u',
    Ű: 'U',
    ű: 'u',
    Ų: 'U',
    ų: 'u',
    Ȗ: 'U',
    ȗ: 'u',
    V̆: 'V',
    v̆: 'v',
    Ŵ: 'W',
    ŵ: 'w',
    Ẃ: 'W',
    ẃ: 'w',
    X̆: 'X',
    x̆: 'x',
    Ŷ: 'Y',
    ŷ: 'y',
    Ÿ: 'Y',
    Y̆: 'Y',
    y̆: 'y',
    Ź: 'Z',
    ź: 'z',
    Ż: 'Z',
    ż: 'z',
    Ž: 'Z',
    ž: 'z',
    ſ: 's',
    ƒ: 'f',
    Ơ: 'O',
    ơ: 'o',
    Ư: 'U',
    ư: 'u',
    Ǎ: 'A',
    ǎ: 'a',
    Ǐ: 'I',
    ǐ: 'i',
    Ǒ: 'O',
    ǒ: 'o',
    Ǔ: 'U',
    ǔ: 'u',
    Ǖ: 'U',
    ǖ: 'u',
    Ǘ: 'U',
    ǘ: 'u',
    Ǚ: 'U',
    ǚ: 'u',
    Ǜ: 'U',
    ǜ: 'u',
    Ứ: 'U',
    ứ: 'u',
    Ṹ: 'U',
    ṹ: 'u',
    Ǻ: 'A',
    ǻ: 'a',
    Ǽ: 'AE',
    ǽ: 'ae',
    Ǿ: 'O',
    ǿ: 'o',
    Þ: 'TH',
    þ: 'th',
    Ṕ: 'P',
    ṕ: 'p',
    Ṥ: 'S',
    ṥ: 's',
    X́: 'X',
    x́: 'x',
    Ѓ: 'Г',
    ѓ: 'г',
    Ќ: 'К',
    ќ: 'к',
    A̋: 'A',
    a̋: 'a',
    E̋: 'E',
    e̋: 'e',
    I̋: 'I',
    i̋: 'i',
    Ǹ: 'N',
    ǹ: 'n',
    Ồ: 'O',
    ồ: 'o',
    Ṑ: 'O',
    ṑ: 'o',
    Ừ: 'U',
    ừ: 'u',
    Ẁ: 'W',
    ẁ: 'w',
    Ỳ: 'Y',
    ỳ: 'y',
    Ȁ: 'A',
    ȁ: 'a',
    Ȅ: 'E',
    ȅ: 'e',
    Ȉ: 'I',
    ȉ: 'i',
    Ȍ: 'O',
    ȍ: 'o',
    Ȑ: 'R',
    ȑ: 'r',
    Ȕ: 'U',
    ȕ: 'u',
    B̌: 'B',
    b̌: 'b',
    Č̣: 'C',
    č̣: 'c',
    Ê̌: 'E',
    ê̌: 'e',
    F̌: 'F',
    f̌: 'f',
    Ǧ: 'G',
    ǧ: 'g',
    Ȟ: 'H',
    ȟ: 'h',
    J̌: 'J',
    ǰ: 'j',
    Ǩ: 'K',
    ǩ: 'k',
    M̌: 'M',
    m̌: 'm',
    P̌: 'P',
    p̌: 'p',
    Q̌: 'Q',
    q̌: 'q',
    Ř̩: 'R',
    ř̩: 'r',
    Ṧ: 'S',
    ṧ: 's',
    V̌: 'V',
    v̌: 'v',
    W̌: 'W',
    w̌: 'w',
    X̌: 'X',
    x̌: 'x',
    Y̌: 'Y',
    y̌: 'y',
    A̧: 'A',
    a̧: 'a',
    B̧: 'B',
    b̧: 'b',
    Ḑ: 'D',
    ḑ: 'd',
    Ȩ: 'E',
    ȩ: 'e',
    Ɛ̧: 'E',
    ɛ̧: 'e',
    Ḩ: 'H',
    ḩ: 'h',
    I̧: 'I',
    i̧: 'i',
    Ɨ̧: 'I',
    ɨ̧: 'i',
    M̧: 'M',
    m̧: 'm',
    O̧: 'O',
    o̧: 'o',
    Q̧: 'Q',
    q̧: 'q',
    U̧: 'U',
    u̧: 'u',
    X̧: 'X',
    x̧: 'x',
    Z̧: 'Z',
    z̧: 'z',
  };

  let result = '';

  for (let i = 0; i < name.length; ++i) {
    const c = name[i].toLowerCase();
    result += /[A-Za-z0-9\-_]/.test(c) // Matches any alphanumeric character, hyphen and underscore.
      ? c
      : // Drop non-alphanumeric characters without conversion
      substitutionMap[c] === undefined
      ? ''
      : substitutionMap[c];
  }
  return result.toLowerCase();
};

const setImplicitProperties = (oldProfile, newProfile, isNewProfile) => {
  const profile = Object.assign({}, newProfile);
  // Set username equal to email address for external AD users.
  if (isExtenalADUser(profile.email)) {
    // Hackish way to prevent editing username for hackishly made internal AD users with external AD email
    if (oldProfile.email === oldProfile.username || isNewProfile) {
      profile.username = profile.email.toLowerCase();
    }
  }
  // Construct username if firstname and lastname are given.
  else if (profile.firstName !== undefined && profile.lastName !== undefined) {
    profile.username = `${normalizeName(profile.firstName)}.${normalizeName(profile.lastName)}@${
      process.env.REACT_APP_AZUREAD_DOMAIN
    }`;
  }

  // Don't set profile as edited if the change is just partner search keyword
  const setKeyword = !profile.edited && oldProfile.keyword !== profile.keyword;
  profile.edited = profile.edited === false || setKeyword ? undefined : true;

  return profile;
};

const loadMeReducer = (state, action) => {
  return {
    ...state,
  };
};

const loadMeSuccessReducer = (state, action) => {
  const result = action.result.profile;
  const cleanedFeatures = mapBuildingTabToNewTabs(result.features);
  return {
    ...state,
    profile: {
      ...result,
      syntheticFeatures: getSyntheticFeatures(cleanedFeatures),
      features: cleanedFeatures,
      language: fixLanguage(result.language),
    },
    topLevelPermissions: _.uniq(_.flatten(Object.keys(result.permissions || {}).map(x => result.permissions[x]))),
    profiles: _.assign({}, state.profiles, {
      [result.id]: { ...result, features: cleanedFeatures, language: fixLanguage(result.language) },
    }),

    // Get selected partner from a cookie or set first partner as active partner or null if not available.
    activePartner: cookies.getItem('selectedPartner') || (Object.keys(result.permissions) || [])[0] || null,
  };
};

const loadMeFailReducer = (state, action) => {
  return {
    ...state,
    profile: action.error,
    permissionTree: {},
  };
};

const loadReducer = (state, action) => {
  return {
    ...state,
  };
};

const loadSuccessReducer = (state, action) => {
  const result = action.result;
  const cleanedFeatures = mapBuildingTabToNewTabs(result.features);
  return {
    ...state,
    profiles: {
      ...state.profiles,
      [action.id]: { ...action.result, features: cleanedFeatures, language: fixLanguage(result.language) },
    },
  };
};

const loadFailReducer = (state, action) => {
  return {
    ...state,
    profiles: {
      ...state.profiles,
      [action.id]: action.error,
    },
  };
};

const loadPermissionsReducer = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const loadPermissionsSuccessReducer = (state, action) => {
  return {
    ...state,
    permissionTree:
      action.result.length > 0
        ? action.result.reduce(
            (acc, x) => _.setWith(acc, x.path, _.get(state.permissionTree, x.path, {}), Object),
            _.cloneDeep(state.permissionTree)
          )
        : _.setWith(_.cloneDeep(state.permissionTree), action.path, null, Object),
    loading: false,
  };
};

const loadPermissionsFailReducer = (state, action) => {
  return {
    ...state,
    permissionTree: {},
    loading: false,
  };
};

const loadWelcomeReducer = (state, action) => {
  return {
    ...state,
  };
};

const loadWelcomeSuccessReducer = (state, action) => {
  return {
    ...state,
    welcomeProfile: action.result.verified === '' ? action.result : null,
    profiles: _.assign({}, state.profiles, { [action.result.id]: action.result }),
  };
};

const loadWelcomeFailReducer = (state, action) => {
  return {
    ...state,
    welcomeProfile: null,
    error: action.error,
  };
};

const setNewProfilePropertyReducer = (state, action) => {
  return {
    ...state,
    newProfile: setImplicitProperties(
      state.newProfile,
      _.setWith(_.clone(state.newProfile), action.property, action.value, Object),
      true
    ),
  };
};

const setPropertyReducer = (state, action) => {
  const oldData = state.editProfiles[action.id];
  return {
    ...state,
    editProfiles: {
      ...state.editProfiles,
      [action.id]:
        oldData &&
        setImplicitProperties(
          oldData,
          _.setWith(_.clone(state.editProfiles[action.id]), action.property, action.value, Object)
        ),
    },
  };
};

const createReducer = (state, action) => {
  return {
    ...state,
    error: null,
  };
};

const createSuccessReducer = (state, action) => {
  return {
    ...state,
    newProfile: initialState.newProfile,
    profiles: {
      ...state.profiles,
      [action.result.id]: action.result,
    },
  };
};

const createFailReducer = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const saveReducer = (state, action) => {
  return {
    ...state,
  };
};

const saveSuccessReducer = (state, action) => {
  const ownProfile = state.profile.id === action.result.id;
  return {
    ...state,
    profiles: {
      ...state.profiles,
      [action.id]: action.result,
    },
    editProfiles: {
      ...state.editProfiles,
      [action.id]: action.result,
    },
    profile: ownProfile
      ? { ...action.result, syntheticFeatures: getSyntheticFeatures(action.result.features) }
      : state.profile,
    topLevelPermissions: ownProfile
      ? _.uniq(_.flatten(Object.keys(action.result.permissions || {}).map(x => action.result.permissions[x]))) || []
      : _.uniq(_.flatten(Object.keys(state.profile.permissions || {}).map(x => state.profile.permissions[x]))) || [],
  };
};

const saveFailReducer = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const verifyReducer = (state, action) => {
  return {
    ...state,
  };
};

const verifySuccessReducer = (state, action) => {
  return {
    ...state,
    welcomeProfile: action.result,
  };
};

const verifyFailReducer = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const resendVerificationReducer = (state, action) => {
  return {
    ...state,
    resentVerification: {},
  };
};

const resendVerificationSuccessReducer = (state, action) => {
  return {
    ...state,
    resentVerification: { [action.id]: true },
  };
};

const resendVerificationFailReducer = (state, action) => {
  return {
    ...state,
    resentVerification: { [action.id]: false },
  };
};

const verifyPasswordsDidNotMatchReducer = state => {
  return {
    ...state,
    error: 'Password and password verification do not match.',
  };
};

const searchUsernameReducer = (state, action) => {
  return {
    ...state,
    usernameExists: {},
  };
};

const searchUsernameSuccessReducer = (state, action) => {
  return {
    ...state,
    usernameExists: { [action.username]: action.result.length > 0 },
  };
};

const searchUsernameFailReducer = (state, action) => {
  return {
    ...state,
    usernameExists: {},
    error: action.error,
  };
};

const setActivePartnerReducer = (state, action) => {
  return {
    ...state,
    activePartner: action.partnerNumber,
  };
};

const setEditReducer = (state, action) => {
  return {
    ...state,
    editProfiles: {
      ...state.editProfiles,
      [action.id]: _.cloneDeep(state.profiles[action.id]),
    },
  };
};

const clearEditReducer = state => ({
  ...state,
  editProfiles: initialState.editProfiles,
});

const deleteProfileSuccessReducer = (state, action) => ({
  ...state,
  userSearch: state.userSearch
    ? {
        ...state.userSearch,
        users: state.userSearch.users.filter(user => user.id !== action.profileId),
      }
    : undefined,
  profiles: {
    ...state.profiles,
    [action.profileId]: undefined,
  },
});

const searchUsersReducer = (state, action) => ({
  ...state,
  userSearch: {
    users: [],
    state: 'loading',
  },
});

const searchUsersSuccessReducer = (state, action) => ({
  ...state,
  userSearch: {
    users: action.result,
    state: 'success',
  },
});

const searchUsersFailReducer = (state, action) => ({
  ...state,
  userSearch: {
    users: [],
    state: 'error',
  },
});

const searchUsersClearReducer = state => ({
  ...state,
  userSearch: undefined,
  userSearchFilters: initialState.userSearchFilters,
});

const searchUsersSetFiltersReducer = (state, action) => ({
  ...state,
  userSearchFilters: action.filters,
});

const loadApiKeysReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      loading: true,
    },
  },
});

const loadApiKeysSuccessReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      data: action.result,
    },
  },
});

const loadApiKeysFailReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      error: action.error,
    },
  },
});

const deleteApiKeySuccessReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      data: state.apiKeys[action.profileId].data.filter(key => key.id !== action.keyId),
    },
  },
});

const createApiKeySuccessReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      data: [...state.apiKeys[action.profileId].data, _.omit(action.result, 'token')],
    },
  },
});

const loadApiQuotaReducer = (state, action) => ({
  ...state,
  apiQuotas: {
    ...state.apiQuotas,
    [action.profileId]: {
      loading: true,
    },
  },
});

const loadApiQuotaSuccessReducer = (state, action) => ({
  ...state,
  apiQuotas: {
    ...state.apiQuotas,
    [action.profileId]: {
      data: action.result,
    },
  },
});

const loadApiQuotaFailReducer = (state, action) => ({
  ...state,
  apiQuotas: {
    ...state.apiQuotas,
    [action.profileId]: {
      error: action.error,
    },
  },
});

const setApiQuotaSuccessReducer = (state, action) => ({
  ...state,
  apiQuotas: {
    ...state.apiQuotas,
    [action.profileId]: {
      data: action.result,
    },
  },
});

export default createReducerFromMapping(
  {
    [LOAD_ME]: loadMeReducer,
    [LOAD_ME_SUCCESS]: loadMeSuccessReducer,
    [LOAD_ME_FAIL]: loadMeFailReducer,
    [LOAD]: loadReducer,
    [LOAD_SUCCESS]: loadSuccessReducer,
    [LOAD_FAIL]: loadFailReducer,
    [LOAD_PERMISSIONS]: loadPermissionsReducer,
    [LOAD_PERMISSIONS_SUCCESS]: loadPermissionsSuccessReducer,
    [LOAD_PERMISSIONS_FAIL]: loadPermissionsFailReducer,
    [LOAD_WELCOME]: loadWelcomeReducer,
    [LOAD_WELCOME_SUCCESS]: loadWelcomeSuccessReducer,
    [LOAD_WELCOME_FAIL]: loadWelcomeFailReducer,
    [SET_NEW_PROFILE_PROPERTY]: setNewProfilePropertyReducer,
    [SET_PROPERTY]: setPropertyReducer,
    [CREATE]: createReducer,
    [CREATE_SUCCESS]: createSuccessReducer,
    [CREATE_FAIL]: createFailReducer,
    [SAVE]: saveReducer,
    [SAVE_SUCCESS]: saveSuccessReducer,
    [SAVE_FAIL]: saveFailReducer,
    [VERIFY]: verifyReducer,
    [VERIFY_SUCCESS]: verifySuccessReducer,
    [VERIFY_FAIL]: verifyFailReducer,
    [VERIFY_PASSWORDS_DID_NOT_MATCH]: verifyPasswordsDidNotMatchReducer,
    [SEARCH_USERNAME]: searchUsernameReducer,
    [SEARCH_USERNAME_SUCCESS]: searchUsernameSuccessReducer,
    [SEARCH_USERNAME_FAIL]: searchUsernameFailReducer,
    [SET_ACTIVE_PARTNER]: setActivePartnerReducer,
    [SET_EDIT]: setEditReducer,
    [CLEAR_EDIT]: clearEditReducer,
    [RESEND_VERIFICATION]: resendVerificationReducer,
    [RESEND_VERIFICATION_SUCCESS]: resendVerificationSuccessReducer,
    [RESEND_VERIFICATION_FAIL]: resendVerificationFailReducer,
    [DELETE_PROFILE_SUCCESS]: deleteProfileSuccessReducer,
    [SEARCH_USERS]: searchUsersReducer,
    [SEARCH_USERS_SUCCESS]: searchUsersSuccessReducer,
    [SEARCH_USERS_FAIL]: searchUsersFailReducer,
    [SEARCH_USERS_CLEAR]: searchUsersClearReducer,
    [SEARCH_USERS_SET_FILTERS]: searchUsersSetFiltersReducer,
    [LOAD_API_KEYS]: loadApiKeysReducer,
    [LOAD_API_KEYS_SUCCESS]: loadApiKeysSuccessReducer,
    [LOAD_API_KEYS_FAIL]: loadApiKeysFailReducer,
    [DELETE_API_KEY_SUCCESS]: deleteApiKeySuccessReducer,
    [CREATE_API_KEY_SUCCESS]: createApiKeySuccessReducer,
    [LOAD_API_QUOTA]: loadApiQuotaReducer,
    [LOAD_API_QUOTA_SUCCESS]: loadApiQuotaSuccessReducer,
    [LOAD_API_QUOTA_FAIL]: loadApiQuotaFailReducer,
    [SET_API_QUOTA_SUCCESS]: setApiQuotaSuccessReducer,
  },
  initialState
);
