import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { omit } from 'lodash';
import memoizeOne from 'memoize-one';

import translations from 'decorators/Translations/translations';
import Section from 'components/Section/Section';
import { InputRow, InputText, InputBooleanCheckbox, InputForm, InputSearchDropdown, Button } from 'components';
import { searchUsers, clearUserSearch, setUserSearchFilters } from 'redux/modules';
import InputLabel from 'components/Form/InputLabel';
import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import { icons } from 'components/Icon/IconNames';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import SectionHeader from 'components/Section/SectionHeader';
import { divisionOptions, roleOptions } from './UserForm/Settings/Settings';
import SortableTable, { StyledSortableTable } from 'components/SortableTable/SortableTable';
import UserSearchResultRow from './UserSearchResultRow';
import ContextualHelp, { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${props => props.theme.media.desktop`
        margin: -${props => props.theme.spacing.sm};
    `}
`;

const StyledInputRow = styled(InputRow)`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing.xs};
  margin-bottom: ${props => props.theme.spacing.xs};

  ${props => props.theme.media.desktop`
        width: calc(100% / 3 - 2 * ${props => props.theme.spacing.sm});
        &:nth-of-type(n) {
            margin-left: ${props => props.theme.spacing.sm};
            margin-right: ${props => props.theme.spacing.sm};
        }
    `}
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  & > * {
    margin-right: ${props => props.theme.spacing.md};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing.sm};
  margin-bottom: ${props => props.theme.spacing.xs};
  flex: 1;
  margin-right: ${props => props.theme.spacing.lg};

  ${props => props.theme.media.portrait`
        flex-grow: 0;
        margin-top: ${props => props.theme.spacing.sm};
    `}

  ${props => props.theme.media.desktop`
        margin-top: ${props => props.theme.spacing.lg};
    `}
`;

const Message = styled.div`
  margin-bottom: ${props => props.theme.spacing.xxs};
`;
Message.displayName = 'Message';

const ContextualHelpContainer = styled.div`
  display: flex;
`;

const TableComponent = styled(StyledSortableTable)`
  td {
    word-wrap: break-word;
    word-break: break-word;
  }

  td:nth-of-type(n + 2) {
    display: none;
  }

  ${props => props.theme.media.portrait`
        td:nth-of-type(-n + 2) {
            display: table-cell;
        }
    `}

  ${props => props.theme.media.desktop`
        td:nth-of-type(n) {
            display: table-cell;
        }
    `}
`;

const links = [
  { title: 'Back to admin homepage', icon: icons.ARROW_LEFT, to: { pathname: '/Admin' }, smallIcon: true },
  { title: 'Manage existing users', icon: icons.ADMIN_MANAGE_USERS, pathname: '/Admin/Users' },
];

const placeholder = 'Type here...';

const requireOne = ['name', 'partnerNumber', 'functionalLocation', 'division', 'role'];

const getColumns = memoizeOne(t => [
  {
    title: t('Name'),
    field: 'fullName',
    sortable: true,
  },
  {
    title: t('Username'),
    field: 'username',
    sortable: true,
  },
  {
    title: t('Email'),
    field: 'email',
    sortable: true,
  },
  {
    title: t('Created'),
    field: 'created',
    sortable: true,
  },
  {
    title: t('Last login'),
    field: 'lastLogin',
    sortable: true,
  },
]);

export const AdminManageUsers = props => {
  const { t, search, searchUsers, clearUserSearch, filters, setUserSearchFilters, profile } = props;
  const disabled = requireOne.every(field => !filters[field]);
  const showReset = !disabled || !filters.internal || !filters.external || search;

  const resultsTitle =
    !search || search.state === 'loading' ? t('Searching...') : `${t('Search results:')} ${search.users.length}`;

  const handlePropertyChange = (property, value) =>
    setUserSearchFilters({
      ...filters,
      [property[0]]: value,
    });

  const handleSubmit = () => {
    searchUsers({
      ...omit(filters, ['internal', 'external']),
      ...(!filters.external && { internalOnly: true }),
      ...(!filters.internal && { externalOnly: true }),
    });
  };

  return (
    <FadeTransition>
      <StandardPage>
        <Helmet title={t('Manage existing users')} />
        <Header t={t} links={links} />
        <Section>
          <SectionHeader title={t('Search existing users')} noBorder />
          <InputForm
            model={filters}
            onPropertyChange={handlePropertyChange}
            FormComponent="form"
            onSubmit={handleSubmit}
          >
            <FieldsContainer>
              <StyledInputRow>
                <InputLabel text={t('Name or email')} />
                <InputText property="name" placeholder={t(placeholder)} />
              </StyledInputRow>
              <StyledInputRow>
                <InputLabel text={t('Partner number')} />
                <InputText property="partnerNumber" placeholder={t(placeholder)} />
              </StyledInputRow>
              <StyledInputRow>
                <ContextualHelpContainer>
                  <InputLabel text={t('Functional location number')} />
                  <ContextualHelp
                    t={t}
                    title={t('Functional location number')}
                    text={`${CTXHELP_PREFIX} user search functional location`}
                    position="top"
                  />
                </ContextualHelpContainer>
                <InputText property="functionalLocation" placeholder={t(placeholder)} />
              </StyledInputRow>
              <StyledInputRow>
                <InputLabel text={t('Division')} options={divisionOptions(t)} />
                <InputSearchDropdown property="division" t={t} options={divisionOptions(t)} />
              </StyledInputRow>
              <StyledInputRow>
                <InputLabel text={t('Role')} />
                <InputSearchDropdown property="role" t={t} options={roleOptions(t, profile.role)} />
              </StyledInputRow>
              <StyledInputRow>
                <InputLabel text={t('Users')} />
                <CheckboxContainer>
                  <InputBooleanCheckbox id="internal" property="internal" label={t('Internal')} />
                  <InputBooleanCheckbox id="external" property="external" label={t('External')} />
                </CheckboxContainer>
              </StyledInputRow>
            </FieldsContainer>
            <StyledButton type="submit" disabled={disabled}>
              {t('Search users')}
            </StyledButton>
            {showReset && (
              <Button cancel onClick={clearUserSearch} id="reset">
                {t('Reset search')}
              </Button>
            )}
          </InputForm>
        </Section>
        {search && (
          <Section id="search-results">
            <SectionHeader title={resultsTitle} noBorder />
            {search.state === 'loading' || (search.state === 'success' && search.users.length) ? (
              <SortableTable
                t={t}
                columns={getColumns(t)}
                data={search.users}
                loading={search.state === 'loading'}
                RowComponent={UserSearchResultRow}
                TableComponent={TableComponent}
                pure
              />
            ) : (
              <Message>
                {search.state === 'error'
                  ? t('An error occurred while searching for users. Please try again.')
                  : t('No users were found with the given search criteria.')}
              </Message>
            )}
          </Section>
        )}
      </StandardPage>
    </FadeTransition>
  );
};

AdminManageUsers.propTypes = {
  t: PropTypes.func.isRequired,
  search: PropTypes.shape({
    state: PropTypes.oneOf(['loading', 'success', 'error']).isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        fullName: PropTypes.string,
        email: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        lastLogin: PropTypes.string,
      })
    ).isRequired,
  }),
  filters: PropTypes.object.isRequired,
  searchUsers: PropTypes.func.isRequired,
  clearUserSearch: PropTypes.func.isRequired,
  setUserSearchFilters: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  search: state.profile.userSearch,
  filters: state.profile.userSearchFilters,
  profile: state.profile.profile,
});

const mapDispatchToProps = {
  searchUsers: searchUsers,
  clearUserSearch,
  setUserSearchFilters,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(AdminManageUsers));
