import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled, { withTheme } from 'styled-components';
import Svg from 'components/Svg/Svg';
import { icons } from 'components/Icon/IconNames';
import MarkdownContent from './MarkdownContent';
import { statusToColor, statusToIcon, DESCRIPTION_MAX_LENGTH, ANNOUNCEMENT_TYPE, announcementShape } from './utils';

const StyledAnnouncement = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding: 1.15em 1em;
  margin: 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
`;
StyledAnnouncement.displayName = 'StyledAnnouncement';

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 1em;
  width: 25px;
`;
IconWrapper.displayName = 'IconWrapper';

const AnnouncementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
`;
AnnouncementWrapper.displayName = 'AnnouncementWrapper';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;
Header.displayName = 'Header';

const Title = styled.h4`
  color: ${props => (props.read ? props.theme.colors.rockBlue : props.theme.colors.black)};
  font-family: ${props => props.theme.font.family.sanchez};
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.bold};
`;
Title.displayName = 'Title';

const PartnerName = styled.span`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: ${props => props.theme.spacing.xs} 0 0;
`;
PartnerName.displayName = 'PartnerName';

const CheckBall = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  padding: 2px;

  border-radius: 50%;
  background-color: ${props => (props.checked ? props.theme.colors.cerulean : props.theme.colors.white)};
  border: 2px solid ${props => props.theme.colors.cerulean};
`;
CheckBall.displayName = 'CheckBall';

const Icon = styled(({ small, ...props }) => <Svg {...props} />)`
  font-size: ${props => (props.small ? '0.7em' : '1.6em')};
  fill: ${props => props.fill};
`;
Icon.displayName = 'Icon';

const StyledDate = styled.p`
  color: ${props => props.theme.colors.rockBlue};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  font-family: ${props => props.theme.font.family.arial};
  font-style: italic;
`;
StyledDate.displayName = 'StyledDate';

const ReadMore = styled.a`
  display: ${props => !props.show && 'none'};
  position: relative;
  margin-left: auto;
  text-decoration: underline;
  white-space: nowrap;
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xs};
  &:hover {
    color: ${props => props.theme.colors.black};
    cursor: pointer;
  }
`;
ReadMore.displayName = 'ReadMore';

const DescriptionWrapper = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.xs};
  overflow: hidden;
  max-height: 44px;
`;
DescriptionWrapper.displayName = 'DescriptionWrapper';

const StyledMarkdownContent = styled(MarkdownContent)`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.lg};

  p {
    padding: 0;
  }
`;
StyledMarkdownContent.displayName = 'StyledMarkdownContent';

const DateWrapper = styled.div`
  display: flex;
`;

const GeneralAnnouncement = props => {
  const { announcement, onClick, unread, partner, t } = props;
  const MARK_READ_IMPLEMENTED = false;
  const getTimeFromNow = date => {
    const momentTime = moment.utc(date);
    return moment.utc().diff(momentTime, 'days') >= 1 ? momentTime.local().fromNow() : t('today');
  };
  const descriptionHasLineBreaks = /\r|\n/.exec(announcement.description);

  return (
    <StyledAnnouncement plain={props.plain}>
      <IconWrapper>
        <Icon name={statusToIcon[announcement.status]} fill={statusToColor[announcement.status]} />
      </IconWrapper>
      <AnnouncementWrapper>
        <Header>
          <Title data-test-id="AnnouncementBoxTitle" read={!unread}>
            {announcement.title}
          </Title>
          {MARK_READ_IMPLEMENTED && (
            <CheckBall checked={unread} hidden={!unread}>
              <Icon small name={icons.OK} fill={unread ? props.theme.colors.white : props.theme.colors.cerulean} />
            </CheckBall>
          )}
        </Header>
        {partner && <PartnerName>{partner.name}</PartnerName>}
        <DescriptionWrapper data-test-id="AnnouncementBoxDescription">
          <StyledMarkdownContent content={announcement.description} />
        </DescriptionWrapper>
        <DateWrapper>
          <StyledDate>{getTimeFromNow(announcement.startDate)}</StyledDate>
          <ReadMore
            onClick={onClick}
            show={descriptionHasLineBreaks || announcement.description.length > DESCRIPTION_MAX_LENGTH}
          >
            {t('Read more')} &rarr;
          </ReadMore>
        </DateWrapper>
      </AnnouncementWrapper>
    </StyledAnnouncement>
  );
};

GeneralAnnouncement.propTypes = {
  announcement: PropTypes.oneOfType([
    announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
    announcementShape(ANNOUNCEMENT_TYPE.SYSTEM),
  ]).isRequired,
  partner: PropTypes.object,
  unread: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

GeneralAnnouncement.defaultProps = {
  unread: false,
  partner: null,
};

export default withTheme(GeneralAnnouncement);
