import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { loadSensorHierarchies, searchSensors } from 'redux/modules/customer/sensorHierarchy.js';
import { loadTagGroups, submitTags, dismissTagErrors } from 'redux/modules/tag/tag';
import { InputRow, InputSearch, InputLabel, InputText, InputBooleanCheckbox, Button } from 'components/index.js';
import InputMultiSelect from 'components/Form/InputMultiSelect';
import Pill from 'components/Pill/Pill';
import Section from 'components/Section/Section';
import SnackBar from 'components/SnackBar/SnackBar';
import Loader from 'components/Loader/Loader';
import SkeletonText from 'components/Skeletons/SkeletonText';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;
const Column = styled.div`
  margin-bottom: ${props => props.theme.spacing.md};

  ${props => props.theme.media.landscape`
        flex-basis: calc(50% - ${props => props.theme.spacing.xs});
        &:not(:first-child) {
            margin-left: ${props => props.theme.spacing.md};
        }
    `}
`;
const ColumnContent = styled.div`
  min-height: 400px;
`;

const SensorTagSelection = styled.div`
  width: 100%;
  line-height: ${props => props.theme.font.lineHeight.md};
  padding-bottom: ${props => props.theme.spacing.md};
  padding-top: ${props => props.theme.spacing.xs};
  border-bottom: 1px solid ${props => props.theme.colors.darkGray};
`;

const StyledInput = styled.input`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.input.background.default};
  padding: ${props => props.theme.input.padding};
  border: ${props => (props.invalid ? props.theme.input.border.error : props.theme.input.border.static)};
  box-shadow: ${props =>
    props.simple ? 'none' : props.invalid ? props.theme.input.shadow.errorStatic : props.theme.input.shadow.static};
  color: ${props => props.theme.input.font.color.default};
  font-size: ${props => props.theme.input.font.mobileSize};
  font-family: ${props => props.theme.input.font.family};
  line-height: ${props => props.theme.input.lineHeight.default};
  transition: ${props => props.theme.input.transition};

  &:hover,
  &:focus {
    box-shadow: ${props =>
      props.simple ? 'none' : props.invalid ? props.theme.input.shadow.errorActive : props.theme.input.shadow.active};
    border: ${props => (props.invalid ? props.theme.input.border.error : props.theme.input.border.active)};
  }

  &:read-only,
  &:read-only:hover,
  &:read-only:focus,
  &:disabled {
    opacity: 0.6;
    box-shadow: ${props => (props.invalid ? props.theme.input.shadow.errorStatic : 'none')};
  }
  &:disabled {
    color: ${props => props.theme.input.font.color.disabled};
    background-color: ${props => props.theme.input.background.disabled};
  }

  ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.input.font.size};
    `}
`;

const StyledPills = styled.div`
  display: flex;
  & > div {
    margin-right: ${props => props.theme.spacing.xxs};
  }
`;
const MetaSubHeader = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.bold};
  padding-bottom: ${props => props.theme.spacing.xs};
`;

const initialModel = {
  showAllSensors: false,
  showAdded: false,
  overrideExistingSelect: true,
  commonEquipRef: '',
  commonEquipRefSelect: false,
  tagGroups: [],
  sensorTags: [],
  sensors: [],
  tagTextFilter: '',
};

export class BuildingAdminTags extends PureComponent {
  state = {
    error: null,
    notificationType: '',
    showAdded: false,
    notificationMessage: '',
    notificationVisible: false,
    fl: null,
    sensors: [],
    sensorTags: [],
    selectedGroups: [],
    selectedSensors: [],
    model: initialModel,
    // Object containing earlier added tags per sensor id
    alreadyAdded: {},
  };
  componentDidMount() {
    this.props.loadSensorHierarchies(this.props.functionalLocation);
    this.props.loadTagGroups();
  }

  handleSubmit = async () => {
    const model = this.state.model;
    if (model.commonEquipRefSelect) {
      _.map(model.sensorTags, sensorTag => {
        _.map(sensorTag.tags, tag => {
          if (tag.key === 'equipRef') {
            tag.value = model.commonEquipRef;
          }
        });
      });
    }
    const data = {
      sensorTags: _.map(model.sensorTags, sensorTag => ({
        sensorId: sensorTag.sensorId,
        tags: _.map(sensorTag.tags, tag => ({
          key: tag.key,
          value: tag.value || this.getDefaultValueForTag(tag, sensorTag.sensorId),
          type: tag.type,
        })),
      })),
      overrideExisting: model.overrideExistingSelect,
    };
    const submitTagsResult = await this.props.submitTags(data);
    if (submitTagsResult && submitTagsResult.error) {
      this.setState({ errorMessage: submitTagsResult.error });
    } else {
      const addedBySensor = this.state.alreadyAdded;
      _.map(model.sensorTags, bySensor => {
        const currentSensorDetails = this.getSensorDetailsForId(bySensor.sensorId);
        const currentMeta = currentSensorDetails.sensorMeta;
        // Map tag data to same format with sensor meta
        const newlyAdded = _.map(bySensor.tags, tag => ({
          metaKey: tag.key,
          value: tag.defaultValue,
          type: 'tagging_tool',
        }));
        if (model.overrideExistingSelect) {
          // If existing meta was overriden, filter out all existing meta added with tagging tool
          addedBySensor[bySensor.sensorId] = newlyAdded.concat(
            _.filter(currentMeta, meta => meta.type !== 'tagging_tool')
          );
        } else {
          // If no overwrite was defined, make sure old and new meta are both in.
          addedBySensor[bySensor.sensroId] = [].concat(currentMeta).concat(newlyAdded);
        }
      });
      this.setState({ model: initialModel, alreadyAdded: addedBySensor });
    }
  };

  openNewForm = () => {
    this.setState({
      dialogOpen: true,
      model: {},
      addNew: true,
    });
  };

  openEditForm = () =>
    this.setState({
      dialogOpen: true,
      model: {
        name: '',
      },
      addNew: false,
    });

  closeForm = () => {
    this.setState({ dialogOpen: false, addNew: false });
  };

  handleFormChange = (property, value) => {
    const newModel = {
      ...this.state.model,
      [property]: value,
    };
    if (property === 'sensors' || property === 'tagGroups') {
      newModel.sensorTags = this.getSensorTagsForSelection(newModel);
    }
    this.setState(state => ({
      ...state,
      model: newModel,
    }));
  };
  getFlNumber = () => this.props.functionalLocation;

  search = async value => {
    const buildingArr = this.props.buildingHierarchy[this.getFlNumber()];
    const building = _.first(buildingArr);
    const filter = {
      buildingId: building.id,
      search: `%${value}%`,
    };
    await this.props.searchSensors(filter);
  };
  getMultiSelectValues = event => {
    const options = event.target.options;
    const values = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        values.push(parseInt(options[i].value, 10));
      }
    }
    return values;
  };

  // newModel used to fetch changes from object not yet in state
  getTagsForGroups = newModel => {
    const model = newModel || this.state.model;
    const groups = _.filter(this.props.groups, group => model.tagGroups.indexOf(group.id) !== -1);
    return _.uniqBy(_.flatten(_.map(groups, 'tagDetails')), 'key');
  };
  // Using model property, otherwise would need setState before this.
  getSensorTagsForSelection = model => {
    const sensors = model.sensors;
    const tags = this.getTagsForGroups(model);
    return _.map(sensors, sensorId => ({ sensorId, tags }));
  };
  getSensorDetailsForId = sensorId => {
    return _.find(this.getAvailableSensors(), { id: sensorId }) || {};
  };
  handleTagValueChange = (sensorId, property, value) => {
    const model = this.state.model;
    let sensorTagsIndex = _.findIndex(model.sensorTags, { sensorId });
    if (sensorTagsIndex === -1) {
      model.sensorTags.push({ sensorId, tags: [] });
      sensorTagsIndex = model.sensorTags.length - 1;
    }
    const existingTagIndex = _.findIndex(model.sensorTags[sensorTagsIndex].tags, { key: property });
    if (existingTagIndex === -1) {
      model.sensorTags[sensorTagsIndex].tags.push({ key: property, value, type: 'value' });
    } else {
      // If existing tag is found, update value.
      model.sensorTags[sensorTagsIndex].tags[existingTagIndex].value = value;
    }
    this.setState({ model });
  };

  getValueForSensorTag = (sensorId, property) => {
    const model = this.state.model;

    const sensorTags = _.find(model.sensorTags, { sensorId });
    if (!sensorTags || !sensorTags.tags) {
      return '';
    }
    const existingTag = _.find(sensorTags.tags, { tag: property });
    if (!existingTag) {
      return '';
    }
    return existingTag.value;
  };

  getDefaultValueForTag = (tag, sensorId) => {
    const model = this.state.model;
    let defaultValue;
    const shouldUseCommonRef = tag.key === 'equipRef' && model.commonEquipRefSelect === true;

    switch (tag.key) {
      case 'equipRef':
        defaultValue = shouldUseCommonRef ? model.commonEquipRef : '';
        break;

      case 'siteRef':
        defaultValue = this.getFlNumber();
        break;

      case 'id':
        defaultValue = sensorId;

        break;
      default:
        defaultValue = tag.defaultValue;
    }
    return defaultValue;
  };

  renderTags = sensorId => {
    const model = this.state.model;
    return _.map(_.uniqBy(_.filter(this.getTagsForGroups(), tag => tag.type === 'value'), 'key'), (tag, idx) => {
      const defaultValue = this.getDefaultValueForTag(tag, sensorId);
      const shouldUseCommonRef = tag.key === 'equipRef' && model.commonEquipRefSelect === true;

      const shouldBeDisabled = shouldUseCommonRef || (tag.key !== 'equipRef' && tag.defaultValue !== '');
      return (
        <div
          key={`valuetag_${idx}`}
          style={{ width: '200px', display: 'flex', alignItems: 'baseline', marginLeft: '5px' }}
        >
          <div style={{ paddingRight: '5px' }}>{tag.key} </div>
          <StyledInput
            type="text"
            placeholder={defaultValue}
            disabled={shouldBeDisabled}
            onChange={event => this.handleTagValueChange(sensorId, tag.key, event.target.value)}
          />
        </div>
      );
    });
  };

  getBuildingSensors = () => {
    const buildings = this.props.buildingHierarchy;
    const building = _.get(buildings, `[${this.getFlNumber()}][0]`);
    if (!building) {
      return [];
    }
    let allSensors = building.sensors;
    const traverse = children => {
      for (let i = 0; i < children.length; i++) {
        const obj = children[i];
        if (obj.sensors && obj.sensors.length > 0) {
          allSensors = allSensors.concat(obj.sensors);
        }
        if (obj.children && obj.children.length > 0) {
          traverse(obj.children);
        }
      }
    };
    return allSensors;
  };
  getExistingMetaForSensor = sensor => {
    return this.state.alreadyAdded && this.state.alreadyAdded[sensor.id]
      ? this.state.alreadyAdded[sensor.id]
      : sensor.sensorMeta;
  };

  getAvailableSensors = () => {
    return this.state.model.showAllSensors ? this.getBuildingSensors() : this.props.sensors;
  };

  handleDismissError = () => {
    this.props.dismissTagErrors();
  };

  shouldSaveBeVisible = () => {
    const model = this.state.model;
    const hasGroups = model.tagGroups && model.tagGroups.length > 0;
    const hasSensors = model.sensorTags && model.sensorTags.length > 0;
    return hasGroups && hasSensors;
  };

  render() {
    const { t, loading } = this.props;
    const tag = this.props.tag;
    const model = this.state.model;
    const hasSensorsSelected = this.state.model.sensors && this.state.model.sensors.length > 0;
    const sensorsResult = this.getAvailableSensors();
    return (
      <Section>
        <FlexContainer>
          <Column>
            <h4>{t('Select sensors')}:</h4>
            {loading ? (
              <SkeletonText />
            ) : (
              <ColumnContent>
                {!this.state.model.showAllSensors && (
                  <InputRow fullRow>
                    <InputLabel id="tagTextFilter" text={t('Search sensors')} />
                    <InputSearch
                      disabled={this.state.model.showAllSensors}
                      onChange={value => this.search(value)}
                      placeholder={t('Start typing to search for sensors')}
                      initialValue={this.props.searchWord}
                    />
                  </InputRow>
                )}
                {this.props.sensorHierarchy.searchingSensors && <Loader />}
                <InputRow>
                  <InputBooleanCheckbox
                    id="showAllSensors"
                    model={model}
                    property="showAllSensors"
                    onChange={this.handleFormChange}
                    label={t('Show all sensors')}
                  />
                </InputRow>
                <InputMultiSelect
                  model={model}
                  property="sensors"
                  t={t}
                  clearable={true}
                  id="sensorsSelector"
                  value={model.sensors && model.sensors.length > 0 ? model.sensors[0] : null}
                  onChange={this.handleFormChange}
                >
                  {_.map(sensorsResult, (sensor, idx) => {
                    const optionProps = {
                      value: sensor.id,
                      key: `sensor_${idx}`,
                    };
                    return (
                      <option {...optionProps}>
                        {sensor.id}. {sensor.name}
                      </option>
                    );
                  })}
                </InputMultiSelect>
              </ColumnContent>
            )}
          </Column>
          <Column>
            <h4>{t('Available tag groups')}:</h4>
            <ColumnContent>
              <FlexContainer>
                <InputRow fullRow>
                  <InputLabel id="tagTextFilter" text={t('Filter by text')} />
                  <InputText
                    model={model}
                    id="tagTextFilter"
                    property="tagTextFilter"
                    placeholder={t('Type here')}
                    onChange={this.handleFormChange}
                  />
                </InputRow>
              </FlexContainer>
              <InputMultiSelect
                model={model}
                property="tagGroups"
                t={t}
                clearable={true}
                id="tagGroupsSelector"
                onChange={this.handleFormChange}
              >
                {_.map(
                  _.filter(
                    this.props.groups,
                    group =>
                      model.tagTextFilter === '' ||
                      group.name.toLowerCase().indexOf(model.tagTextFilter.toLowerCase()) !== -1
                  ),
                  (group, idx) => (
                    <option value={group.id} key={idx}>
                      {group.name}
                    </option>
                  )
                )}
              </InputMultiSelect>
            </ColumnContent>
          </Column>
        </FlexContainer>
        {!loading && <h4 style={{ display: 'inline-block' }}>{t('Adding following tags')}</h4>}
        {!hasSensorsSelected && <div style={{ padding: '1em' }}>{t('No sensors selected')}</div>}
        {hasSensorsSelected && (
          <div style={{ display: 'inline-block', marginLeft: '1em' }}>
            <InputRow fullRow>
              <InputBooleanCheckbox
                id="commonEquipRefSelect"
                model={model}
                property="commonEquipRefSelect"
                onChange={this.handleFormChange}
                label={t('Common equipRef')}
              />
            </InputRow>
          </div>
        )}
        {this.state.model.commonEquipRefSelect && !loading && (
          <div>
            <InputRow>
              <InputLabel id="commonEquipRef" text={t('equipRef')} />
              <InputText
                model={model}
                id="commonEquipRef"
                property="commonEquipRef"
                placeholder={t('Type here')}
                onChange={this.handleFormChange}
              />
            </InputRow>
          </div>
        )}

        {!loading && (
          <FlexContainer>
            {_.map(model.sensors, sensorId => {
              const sensor = this.getSensorDetailsForId(sensorId);
              const existingTags = this.getExistingMetaForSensor(sensor);
              return (
                <SensorTagSelection key={`sensor_selection_${sensorId}`}>
                  <p>
                    <b>
                      {t('Sensor')}: {sensor.name}
                    </b>
                  </p>
                  <div style={{ marginTop: '1em' }}>
                    <MetaSubHeader>{t('Existing meta')}: </MetaSubHeader>
                    <StyledPills>
                      {_.map(existingTags, (meta, idx) => (
                        <Pill key={`existing_meta_${idx}`} color={this.props.theme.colors.midnight}>
                          <span>
                            {meta.metaKey} {meta.value ? `: ${meta.value}` : ''}
                          </span>
                        </Pill>
                      ))}
                    </StyledPills>
                  </div>
                  <div style={{ marginTop: '1em' }}>
                    <MetaSubHeader>{t('Adding following')}: </MetaSubHeader>

                    <StyledPills>
                      {(() => {
                        const filtered = _.uniqBy(
                          _.filter(this.getTagsForGroups(), tag => tag.type !== 'value'),
                          'key'
                        );
                        const existingKeys = _.map(existingTags, 'metaKey');
                        return _.map(filtered, (tag, idx) => {
                          const color =
                            existingKeys.indexOf(tag.key) !== -1
                              ? this.props.theme.colors.radicalRed
                              : this.props.theme.colors.midnight;
                          return (
                            <Pill key={`tag_${idx}`} color={color}>
                              <span>{tag.key}</span>
                            </Pill>
                          );
                        });
                      })()}
                    </StyledPills>
                  </div>
                  <div style={{ display: 'flex', width: '100%', marginTop: '0.5em' }}>{this.renderTags(sensor.id)}</div>
                </SensorTagSelection>
              );
            })}
          </FlexContainer>
        )}
        {hasSensorsSelected && !loading && (
          <div>
            <InputRow>
              <InputBooleanCheckbox
                id="overrideExistingSelect"
                model={model}
                property="overrideExistingSelect"
                onChange={this.handleFormChange}
                label={t('Override existing tags')}
              />
            </InputRow>
          </div>
        )}

        <SnackBar
          visible={this.shouldSaveBeVisible()}
          variant="confirmation"
          hideDelay={1000}
          primaryContent={
            <div>
              {!tag.saved && t('Save your changes')}
              {tag.saving && `${t('Saving')}...`}
              {tag.saved && !tag.error && t('Meta data successfully saved')}
            </div>
          }
          secondaryContent={
            <Button submit onClick={this.handleSubmit} loading={tag.saving}>
              {!tag.saving && !tag.saved ? t('Save') : t('Saved')}
            </Button>
          }
        />
      </Section>
    );
  }
}

BuildingAdminTags.defaultProps = {
  sensors: [],
};

BuildingAdminTags.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  functionalLocation: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  groups: state.tag.groups,
  buildingHierarchy: state.sensorHierarchy.buildingHierarchy,
  searchWord: state.sensorHierarchy.sensorSearchWord,
  searchResult: state.sensorHierarchy.sensorSearchResult,
  sensorHierarchy: state.sensorHierarchy,
  errorMessage: state.errorMessage,
  sensors: state.sensorHierarchy.sensorSearchResult,
  tag: state.tag,
});

const mapDispatchToProps = {
  loadSensorHierarchies,
  searchSensors,
  loadTagGroups,
  submitTags,
  dismissTagErrors,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(withTheme(translations(BuildingAdminTags)));
