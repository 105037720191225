import React, { useState } from 'react';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import ObservationsTable from 'components/BuildingEvents/Observations/ObservationsTable';
import ObservationsFilter from './ObservationsFilter';
import { createFilter } from './ObservationsFilter';

const MAX_HEIGHT = 780;

const ObservationsTableWraper = styled.section`
  height: 100%;
  width: 100vw;
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.lg};
  overflow: auto;
  ${props => props.theme.media.landscape`
        max-height: ${MAX_HEIGHT}px;
        height: initial;
        max-width: ${props => props.theme.grid.maxWidth};
    `};
`;

const ObservationsFilterWraper = styled.section`
  padding: 0 ${props => props.theme.spacing.lg};
`;

const DialogHeader = styled.div`
  padding: ${props => props.theme.spacing.lg};
  padding-bottom: 0;
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.font.weight.bold};
  font-family: ${props => props.theme.font.family.sanchez};
`;

export const ObservationsModal = ({ t, observations = [], onClose, filter }) => {
  const [observationsFilter, setFilter] = useState(() => createFilter({ ...filter }));
  return (
    <Dialog animate isActive onOverlayClick={onClose} t={t}>
      <DialogFrame onClose={onClose} smallVerticalPadding>
        <DialogHeader>{t('Observations')}</DialogHeader>
        <ObservationsFilterWraper>
          <ObservationsFilter onChange={setFilter} filter={filter} observations={observations} />
        </ObservationsFilterWraper>

        <ObservationsTableWraper>
          <ObservationsTable loading={false} t={t} data={observations.filter(observationsFilter)} />
        </ObservationsTableWraper>
      </DialogFrame>
    </Dialog>
  );
};

ObservationsModal.propTypes = {
  t: PropTypes.func.isRequired,
  observations: PropTypes.array,
  onClose: PropTypes.func,
  filter: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }),
};
export default translations(ObservationsModal);
