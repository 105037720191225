import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import Svg from 'components/Svg/Svg';
import PermissionListItem from './PermissionListItem';
import {
  PermissionListItemIcon,
  PermissionListItemGroups,
  PermissionListItemGroup,
  PermissionListItemHeading,
  PermissionListItemFLCount,
  PermissionListSubheading,
  PermissionListItemsChips,
} from './components';

const StyledSVGPermissionsToggleIcon = styled(Svg)`
  vertical-align: text-bottom;
  margin-left: 5px;
`;

const PermissionListItemPartner = props => {
  const { customer, functionalLocationCount, open, ...other } = props;
  return (
    <PermissionListItem {...other}>
      <PermissionListItemIcon>
        <StyledSVGPermissionsToggleIcon name={open ? 'fa-caret-up' : 'fa-caret-down'} />
      </PermissionListItemIcon>
      <PermissionListItemGroups>
        <PermissionListItemGroup>
          <PermissionListItemHeading>
            {customer.name}
            <PermissionListItemFLCount>({functionalLocationCount})</PermissionListItemFLCount>
          </PermissionListItemHeading>
        </PermissionListItemGroup>
        <PermissionListItemGroup>
          <PermissionListSubheading>{customer.partnerNumber}</PermissionListSubheading>
        </PermissionListItemGroup>
        <PermissionListItemsChips {...props} />
      </PermissionListItemGroups>
    </PermissionListItem>
  );
};

PermissionListItemPartner.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    partnerNumber: PropTypes.string.isRequired,
  }).isRequired,
  functionalLocationCount: PropTypes.number.isRequired,
  open: PropTypes.bool,
};

export default PermissionListItemPartner;
