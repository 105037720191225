import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import translations from 'decorators/Translations/translations';
import styled, { withTheme } from 'styled-components';
import { createWasteChartConfig } from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteChart/WasteChartConfig';

addNoDataModule(Highcharts);

const StyledWasteAmountsChart = styled.div`
  .highcharts-legend-item rect {
    visibility: hidden;
  }
  .highcharts-legend-item {
    margin-left: -25px;
  }
  .highcharts-axis-labels {
    letter-spacing: 1px;
  }
`;

export const WasteChart = ({ t, theme, profile, data, onValueBarClick }) => {
  // map series to Highcharts compatible form and set colors from theme
  const transformedSerie = data.series.map(entry => ({
    name: entry.wasteType,
    y: entry.totalValue,
    _unit: entry.unit,
    color: theme.charts.colorsRecycling[entry.colorName],
    sensorId: entry.sensorId,
  }));
  const categories = transformedSerie.map(entry => entry.name);
  const customLegend = data.categories.map(category => ({
    name: category.name,
    color: theme.charts.colorsRecycling[category.colorName],
  }));
  const barChartConfig = createWasteChartConfig({
    theme,
    language: profile.language,
    categories,
    series: transformedSerie,
    customLegend,
    onValueBarClick,
  });
  barChartConfig.lang = {
    ...barChartConfig.lang,
    noData: t('No data to display'),
  };
  return (
    <StyledWasteAmountsChart>
      <HighchartsReact highcharts={Highcharts} options={barChartConfig} />
    </StyledWasteAmountsChart>
  );
};

WasteChart.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  data: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        colorName: PropTypes.string.isRequired,
      })
    ).isRequired,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        wasteType: PropTypes.string.isRequired,
        colorName: PropTypes.string.isRequired,
        totalValue: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onValueBarClick: PropTypes.func.isRequired,
};

export default withTheme(translations(WasteChart));
