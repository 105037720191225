export const PARTNER_META_KEYS = {
  service_request_email: [],
  contact_email: [],
  contact_name: [],
  contact_tel: [],
  SLA_reactionTime_prio1: [],
  SLA_reactionTime_prio2: [],
  SLA_reactionTime_prio3: [],
  SLA_reactionTime_prio4: [],
  SLA_downtime_prio1: [],
  SLA_downtime_prio2: [],
  SLA_downtime_prio3: [],
  SLA_downtime_prio4: [],
  SLA_leadTime_prio1: [],
  SLA_leadTime_prio2: [],
  SLA_leadTime_prio3: [],
  SLA_leadTime_prio4: [],
  tampuuriConfig: [],
  hide_response_times: ['true', 'false'],
  new_service_request_categories: ['option1,option2,option3'],
};

export const BUILDING_META_KEYS = {
  BuildingType: [
    'Kindergarten',
    'Shopping center shop',
    'Hotel',
    'Sportsbuilding',
    'Office',
    'Cultural building',
    'Light industry workshop',
    'School',
    'Small house',
    'Hospital',
    'Nursing home',
    'University',
  ],
  YearOfConstruction: [],
  grossArea: [],
  netArea: [],
  degree_days_location: ['Helsinki'],
  energy_reference: ['[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]'],
  district_heating_reference: ['[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]'],
  electricity_reference: ['[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]'],
  location_latlng: ['60.1,24.8'],
  tampuuriId: [],
  weather_station_id: [],
  timezone: ['EET'],
  opening_hours: ['08:00-16:00'],
  utilization_calculation_hours: ['09:00-15:00'],
  'air_quality/temperature/min': [],
  'air_quality/temperature/max': [],
  'air_quality/humidity/min': [],
  'air_quality/humidity/max': [],
  'air_quality/co2/min': [],
  'air_quality/co2/max': [],
  'air_quality/tvoc/min': [],
  'air_quality/tvoc/max': [],
  'air_quality/particle_pm10/min': [],
  'air_quality/particle_pm10/max': [],
  'air_quality/particle_pm2_5/min': [],
  'air_quality/particle_pm2_5/max': [],
  'air_quality/radon/min': [],
  'air_quality/radon/max': [],
  'air_quality/pressure/min': [],
  'air_quality/pressure/max': [],
  'performance/min': [],
  'performance/max': [],
  default_outdoor_temperature_id: [],
};
