import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';

import DocumentTable from 'components/Documents/DocumentTable/DocumentTable';
import Section from 'components/Section/Section';
import Button from 'components/Button/Button';
import DocumentManager from 'components/Documents/DocumentManager/DocumentManager';
import FolderManager from 'components/Documents/FolderManager/FolderManager';
import SnackBar from 'components/SnackBar/SnackBar';
import InputSearch from 'components/Form/InputSearch';
import SkeletonPill from 'components/Skeletons/SkeletonPill';

import translations from 'decorators/Translations/translations';
import { findFromFolderTree, filterFolders } from 'utils/Data/documents';
import { showModal } from 'redux/modules/modal/modal';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import {
  loadFolders,
  deleteFolder,
  downloadFolder,
  loadFiles,
  deleteFile,
  downloadFile,
  resetFilterFilesByKeyword,
  filterFilesByKeyword,
  loadPartnerFolders,
  loadPartnerFiles,
  downloadPartnerFolder,
  downloadPartnerFile,
} from 'redux/modules/index.js';
import { NOTIFICATION_TIMEOUT } from 'constants/common';
import { isCaverionAdminRole } from 'utils/Data/profileData';
import Loader from 'components/Loader/Loader';

const Heading = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  ${props => props.theme.media.portrait`
        flex-direction: row;
        justify-content: space-between;
    `}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: ${props => props.theme.spacing.md} 0;
`;

const DocumentSearch = styled.div`
  display: ${props => props.desktop && 'none'};
  margin: ${props => props.theme.spacing.md} 0;

  ${props => props.theme.media.portrait`
        width: 300px;
        margin-top: 0;
    `}

  ${props => props.theme.media.desktop`
        display: ${props => (props.desktop ? 'block' : 'none')};
        margin-bottom: 0;
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  button:last-of-type {
    margin-top: ${props => props.theme.spacing.md};
  }

  ${props => props.theme.media.portrait`
        flex-direction: row;
        button:last-of-type {
            margin-top: 0;
            margin-left: ${props => props.theme.spacing.md};
        }

        button {
            width: auto;
            min-width: auto;
        }
    `}
`;

const Title = styled.h2`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.heading};

  ${props => props.theme.media.portrait`
        padding-right: ${props => props.theme.spacing.xl};
    `}
`;

const SnackBarContent = styled.div`
  display: flex;
`;

const SnackBarInfoContainer = styled.div`
  margin-left: ${props => props.theme.spacing.lg};
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InfoTitle = styled.p``;

const InfoDescription = styled.p`
  font-weight: ${props => props.theme.font.weight.normal};
`;

const EMPTY_ARRAY = [];

class DocumentModule extends Component {
  state = {
    showDocumentManager: false,
    showFolderManager: false,
    documentId: null,
    folderId: null,
    notificationType: '',
    notificationMessage: '',
    notificationVisible: false,
    openFolders: [],
  };
  componentDidMount() {
    this.props.resetFilterFilesByKeyword();
    this.timeout = null;
  }

  componentDidUpdate(prevProps) {
    const { functionalLocation, resetFilterFilesByKeyword } = this.props;

    if (!_.isEqual(functionalLocation, prevProps.functionalLocation)) {
      resetFilterFilesByKeyword();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  renderDocumentSearch = showSkeletons => {
    const { t, filterFilesByKeyword, resetFilterFilesByKeyword, filterKeyword } = this.props;

    return showSkeletons ? (
      <SkeletonPill />
    ) : (
      <InputSearch
        onChange={filterFilesByKeyword}
        onClear={resetFilterFilesByKeyword}
        placeholder={t('Search from documents')}
        initialValue={filterKeyword}
      />
    );
  };

  toggleDocumentManager = documentId =>
    this.setState(oldState => ({
      showDocumentManager: !oldState.showDocumentManager,
      documentId: documentId || null,
    }));

  toggleFolderManager = folderId =>
    this.setState(oldState => ({
      showFolderManager: !oldState.showFolderManager,
      folderId: folderId || null,
    }));

  handleAfterSubmit = (notification, isFolder = false, folderId = null, openFolders) => {
    const closing = {
      ...(isFolder ? { showFolderManager: false } : { showDocumentManager: false }),
      ...(isFolder ? { folderId: this.state.showDocumentManager ? folderId : null } : { documentId: null }),
      openFolders,
    };
    this.setState({
      ...closing,
      ...notification,
    });
    this.timeout = setTimeout(
      () => this.setState({ notificationVisible: false, openFolders: [] }),
      NOTIFICATION_TIMEOUT
    );
  };

  handleShowDeleteDocument = (id, name) => {
    this.props.showModal(
      MODALTYPE.CONFIRMATION_DELETE_DOCUMENT,
      null,
      () => this.handleDeleteDocument(id),
      `${this.props.t('Document')} ${this.props.t('{0} will be deleted', name)}`
    );
  };

  handleShowDeleteFolder = (id, name) => {
    this.props.showModal(
      MODALTYPE.CONFIRMATION_DELETE_FOLDER,
      null,
      () => this.handleDeleteFolder(id),
      `${this.props.t('Everything inside folder {0} will be deleted', name)}`
    );
  };

  handleDeleteDocument = id =>
    this.props
      .deleteFile(id)
      .then(() => this.props.loadFiles())
      .then(response => {
        if (response.error) {
          this.handleAfterSubmit({
            notificationType: 'error',
            notificationMessage: this.props.t('Deleting document failed'),
            notificationVisible: true,
          });
        } else {
          this.handleAfterSubmit({
            notificationType: 'success',
            notificationMessage: this.props.t('Document deleted successfully'),
            notificationVisible: true,
          });
        }
      });

  handleDeleteFolder = id =>
    this.props
      .deleteFolder(id)
      .then(() => Promise.all([this.props.loadFolders(), this.props.loadFiles()]))
      .then(response => {
        if (response.error) {
          this.handleAfterSubmit({
            notificationType: 'error',
            notificationMessage: this.props.t('Deleting folder failed'),
            notificationVisible: true,
          });
        } else {
          this.handleAfterSubmit({
            notificationType: 'success',
            notificationMessage: this.props.t('Folder deleted successfully'),
            notificationVisible: true,
          });
        }
      });

  handleDownload = (file, inline, folder) => {
    (!!folder ? this.props.downloadFolder(folder.id, folder.name) : this.props.downloadFile(file, inline)).then(
      response => {
        if (response && response.error) {
          this.handleAfterSubmit({
            notificationType: 'error',
            notificationMessage: this.props.t('Downloading failed'),
            notificationVisible: true,
          });
        }
      }
    );
  };

  handleFolderFileUpload = folderId => {
    this.setState({ folderId });
    this.toggleDocumentManager();
  };

  render() {
    const {
      t,
      theme,
      functionalLocation,
      functionalLocationFolders,
      functionalLocationFiles,
      partnerNumber,
      partnerFolders,
      partnerFiles,
      profile,
      filterKeyword,
      downloadingFolder,
    } = this.props;
    const {
      showDocumentManager,
      showFolderManager,
      documentId,
      folderId,
      notificationType,
      notificationMessage,
      notificationVisible,
      openFolders,
    } = this.state;

    if (!functionalLocation && !partnerNumber) {
      return null;
    }

    const files = partnerNumber
      ? _.get(partnerFiles, [partnerNumber])
      : _.get(functionalLocationFiles, [functionalLocation.functionalLocation]);

    let folders = partnerNumber
      ? _.get(partnerFolders, [partnerNumber])
      : _.get(functionalLocationFolders, [functionalLocation.functionalLocation]);

    if (!_.isEmpty(filterKeyword)) {
      const filteredFolderIds = _.compact(_.map(files, 'folderId'));
      folders = filterFolders(folders, filteredFolderIds);
    }

    const showSkeletons = !files || !folders;

    return (
      <Section>
        <Fragment>
          <Heading>
            <TitleWrapper>
              <Title>{t('Documents')}</Title>
              <DocumentSearch desktop>{this.renderDocumentSearch(showSkeletons)}</DocumentSearch>
            </TitleWrapper>
            <ButtonWrapper>
              <Button
                onClick={e => this.toggleFolderManager()}
                borderColor={theme.colors.cerulean}
                color={theme.colors.white}
                textColor={theme.colors.cerulean}
              >
                {t('Create folder')}
              </Button>
              <Button onClick={e => this.toggleDocumentManager()}>{t('Upload files')}</Button>
            </ButtonWrapper>
          </Heading>
          <DocumentSearch>{this.renderDocumentSearch(showSkeletons)}</DocumentSearch>

          <DocumentTable
            loading={showSkeletons}
            t={t}
            files={files || EMPTY_ARRAY}
            folders={folders || EMPTY_ARRAY}
            onEdit={this.toggleDocumentManager}
            onDelete={this.handleShowDeleteDocument}
            onFolderEdit={this.toggleFolderManager}
            onFolderDelete={this.handleShowDeleteFolder}
            onFolderFileUpload={this.handleFolderFileUpload}
            profile={profile}
            download={this.handleDownload}
            openAllFolders={!_.isEmpty(filterKeyword)}
            openFolders={openFolders}
          />

          {showDocumentManager && (
            <DocumentManager
              onClose={this.toggleDocumentManager}
              onSubmit={this.handleAfterSubmit}
              isCreateMode={!documentId}
              document={_.find(files, { id: documentId }) || {}}
              folders={folders}
              onNewFolder={this.toggleFolderManager}
              isCaverionAdmin={isCaverionAdminRole(profile.role)}
              functionalLocation={functionalLocation}
              partnerNumber={partnerNumber}
              folderId={folderId}
            />
          )}
          {showFolderManager && (
            <FolderManager
              onClose={this.toggleFolderManager}
              onSubmit={this.handleAfterSubmit}
              isCreateMode={!folderId}
              folder={folderId ? findFromFolderTree(folderId, { children: folders }) : {}}
              folders={folders}
              functionalLocation={functionalLocation}
              partnerNumber={partnerNumber}
            />
          )}

          <SnackBar variant={notificationType} visible={notificationVisible}>
            {notificationMessage}
          </SnackBar>
          <SnackBar variant="confirmation" visible={downloadingFolder} secondaryContent={<div />}>
            <SnackBarContent>
              <Loader color="CERULEAN" size="MEDIUM" />
              <SnackBarInfoContainer>
                <InfoTitle>{t('We are generating a ZIP file for you.')}</InfoTitle>
                <InfoDescription>{t('The file may take up to a couple of minutes to generate.')}</InfoDescription>
              </SnackBarInfoContainer>
            </SnackBarContent>
          </SnackBar>
        </Fragment>
      </Section>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  functionalLocationFolders: state.folder.functionalLocations,
  functionalLocationFiles: state.file.filteredFunctionalLocations,
  partnerFolders: state.folder.partners,
  partnerFiles: state.file.filteredPartners,
  filterKeyword: state.file.filterKeyword,
  downloadingFolder: state.folder.downloadingFolder,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
    dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps)),

  loadFolders: () =>
    ownProps.partnerNumber
      ? dispatch(loadPartnerFolders(ownProps.partnerNumber, true))
      : dispatch(loadFolders(ownProps.functionalLocation, true)),
  deleteFolder: id => dispatch(deleteFolder(id)),
  downloadFolder: (id, name) =>
    ownProps.partnerNumber
      ? dispatch(downloadPartnerFolder(id, name, ownProps.partnerNumber))
      : dispatch(downloadFolder(id, name, ownProps.functionalLocation)),

  loadFiles: () =>
    ownProps.partnerNumber
      ? dispatch(loadPartnerFiles(ownProps.partnerNumber, true))
      : dispatch(loadFiles(ownProps.functionalLocation, true)),
  deleteFile: id => dispatch(deleteFile(id)),
  downloadFile: (file, inline) =>
    ownProps.partnerNumber
      ? dispatch(downloadPartnerFile(file.id, inline))
      : dispatch(downloadFile(file, file.functionalLocation ? file : ownProps.functionalLocation, inline)),
  resetFilterFilesByKeyword: () => dispatch(resetFilterFilesByKeyword()),
  filterFilesByKeyword: value => dispatch(filterFilesByKeyword(value)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(withTheme(DocumentModule)));
