import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NameColumn from 'components/CustomView/NameColumn';
import FunctionalLocationsColumn from 'components/CustomView/FunctionalLocationsColumn';
import { InputBooleanCheckbox } from 'components/index.js';

const Row = styled.tr`
  border-bottom: ${props => props.theme.spacing.border} solid ${props => props.theme.colors.alabaster};
  overflow: hidden;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.colors.alabaster};
  }
`;

const Cell = styled.td`
  position: relative;
  padding: ${props => props.theme.spacing.md} 0;
  display: ${props => props.hideOnMobile && 'none'};
  vertical-align: middle;
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.colors.black};

  &:first-child {
    padding-left: ${props => props.theme.spacing.sm};
  }
  &:last-child {
    padding-right: ${props => props.theme.spacing.sm};
  }

  ${props => props.theme.media.landscape`
        display: table-cell;
        padding: ${props => props.theme.spacing.sm};
        &:last-child { padding-right: initial; }
    `}

  label::before, label::after {
    margin-left: 10px;
  }
`;

const CustomViewProfileRow = props => {
  const { rowData, t, onClick } = props;

  return (
    <Row data-test-id={props['data-test-id']} onClick={() => onClick(rowData.id, !rowData.active)}>
      <Cell>
        <InputBooleanCheckbox
          model={rowData}
          property="active"
          onChange={(property, value) => onClick(rowData.id, value)}
          id={`active-${rowData.id}`}
        />
      </Cell>
      <Cell>
        <NameColumn data={rowData.viewName} />
      </Cell>
      <Cell hideOnMobile>
        <FunctionalLocationsColumn t={t} data={rowData.functionalLocations} />
      </Cell>
    </Row>
  );
};

CustomViewProfileRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  'data-test-id': PropTypes.string,
};

export default CustomViewProfileRow;
