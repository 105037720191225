import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import round from 'lodash/round';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import isArray from 'lodash/isArray';
import queryString from 'query-string';
import includes from 'lodash/includes';

import StatusMeter from 'components/StatusMeter/StatusMeter';
import { getStatusValue, legendColors } from 'components/Calendar/Legend/Legend';
import { serviceStatusSortOrder } from 'utils/Data/resolveServiceOrderStatus.js';
import { OrderStatus } from 'constants/serviceCalendar';

const MeterBlock = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.md};

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  section {
    max-width: initial;
    &:not(:last-child) {
      border-right: 1px solid ${props => props.theme.colors.lightGray};
    }
  }

  ${props => props.theme.media.landscape`
        flex-wrap: wrap;
        section {
            min-width: 130px;
            max-width: 130px;
        }
    `}

  ${props => props.theme.media.desktop`
        section {
            min-width: 152px;
            max-width: 152px;
        }
    `}
`;

const CalendarStatusMeters = ({ t, loading, statusCounts, theme, showPlanned, location, history }) => {
  // Show "Planned" only in Planned Maintenance -view
  const statuses = showPlanned ? serviceStatusSortOrder : without(serviceStatusSortOrder, OrderStatus.PLANNED);
  const query = queryString.parse(location.search);

  const setFilter = status => () => {
    if (isArray(query.status)) {
      if (includes(query.status, status)) {
        query.status = without(query.status, status);
      } else {
        query.status.push(status);
      }
    } else if (query.status) {
      if (query.status === status) {
        delete query.status;
      } else {
        query.status = [query.status, status];
      }
    } else {
      query.status = status;
    }

    // Reset pager when filters change
    if (query.page) {
      query.page = 1;
    }

    history.push(`${location.pathname}?${queryString.stringify(query)}`);
  };

  const isFiltering = status => (isArray(query.status) ? includes(query.status, status) : query.status === status);

  return (
    <MeterBlock>
      {statuses.map(status => {
        const count = getStatusValue(serviceStatusSortOrder, statusCounts, status);
        const value = round((count / statusCounts.totalStatusCounts) * 100);

        return (
          <StatusMeter
            key={status}
            value={value}
            title={t(status)}
            subtitle={`${count} ${t('Total')}`}
            color={legendColors({ theme })[status.replace(' ', '_').toLowerCase()]}
            loading={loading}
            onClick={setFilter(status)}
            highlight={isFiltering(status)}
          />
        );
      })}
    </MeterBlock>
  );
};

CalendarStatusMeters.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  statusCounts: PropTypes.object.isRequired,
  showPlanned: PropTypes.bool,
  theme: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

CalendarStatusMeters.defaultProps = {
  loading: false,
  showPlanned: false,
};

export default React.memo(withTheme(CalendarStatusMeters), isEqual);
