import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading/Loading';
import FadeTransition from 'components/FadeTransition/FadeTransition';

class GeneralLoading extends Component {
  render() {
    if (this.props.loading) {
      return (
        <FadeTransition>
          <Loading showLogo={false} />
        </FadeTransition>
      );
    } else if (this.props.splash) {
      return (
        <FadeTransition>
          <Loading />
        </FadeTransition>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  loading: state.generalLoading.loading,
  splash: state.generalLoading.splash,
});

const connector = connect(mapStateToProps);

export default connector(GeneralLoading);
