import moment from 'moment-timezone';
import { isNil, sortBy, map, includes, round } from 'lodash';
import { getPerformanceStatus, performanceColors, getPerformanceLimit } from 'utils/Data/performance';
import { conditionStatus, getColorFromThreshold, conditionStatusToColor } from 'components/Conditions/ConditionUtils';

const dataStatus = valueObject => {
  if (!valueObject || isNil(valueObject.value)) {
    return conditionStatus.negative;
  }
  if (moment.utc(valueObject.timestamp).isBefore(moment.utc().subtract(4, 'hour'))) {
    return conditionStatus.neutral;
  }
  return conditionStatus.positive;
};

export const sensorMetaColorStatus = (theme, minThreshold, maxThreshold, value) => {
  if (isNil(minThreshold) && isNil(maxThreshold)) {
    return undefined;
  }

  const min = !isNil(minThreshold) ? minThreshold : Number.NEGATIVE_INFINITY;
  const max = !isNil(maxThreshold) ? maxThreshold : Number.POSITIVE_INFINITY;
  return min < value && max > value ? conditionStatus.positive : conditionStatus.negative;
};

const sensorMetaValueColor = (theme, minThreshold, maxThreshold, value) => {
  const color = sensorMetaColorStatus(theme, minThreshold, maxThreshold, value);
  return color ? conditionStatusToColor(theme)[color.name] : undefined;
};

const valueColor = (theme, sensor, buildingMeta, value, alarm) => {
  const color = getColorFromThreshold(sensor, sensor.parent, buildingMeta, value, alarm);
  return conditionStatusToColor(theme)[color.name];
};

export const getTableData = (sensors, values, t, onClick, theme, alarmData, isPerformance) => {
  if (!sensors || !t || !theme) {
    return [];
  }

  return sensors.map(sensor => {
    const hasValue = values && values[sensor.id] && !isNil(values[sensor.id].value);
    const value = hasValue ? values[sensor.id].value : 'N/A';
    let fixedValue = !isNaN(value) ? round(value, 1) : value;

    const sensorType = sensor.sensorType || {};
    let unit = (hasValue && sensorType.unit) || '';

    // Special handling to show humidity unit as %RH in condition table
    if (unit && sensorType.name === 'humidity') {
      unit = '%RH';
    }

    const alarmConfig = alarmData.sensorAlarmsById && alarmData.sensorAlarmsById[sensor.id];
    const getLimit = getPerformanceLimit(sensor, sensor.parent, [], alarmConfig);
    const [min, max] = getLimit ? getLimit(new Date()) : [undefined, undefined];

    // 1. Use performance colors for performance sensors
    // 2. Use sensor specific h:min/h:max if they exist
    // 3. Use sensor type color config
    let color =
      (isPerformance && hasValue && performanceColors({ theme })[getPerformanceStatus(value)]) ||
      (hasValue && sensorMetaValueColor(theme, min, max, value)) ||
      (hasValue && valueColor(theme, sensor, [], value, undefined)) ||
      null;

    // Special handling for presence
    if (hasValue && sensorType.graphType === 'presence') {
      color =
        fixedValue > 0
          ? conditionStatusToColor(theme)[conditionStatus.negative.name]
          : conditionStatusToColor(theme)[conditionStatus.positive.name];
      fixedValue = fixedValue > 0 ? t('Occupied') : t('Available');
    }

    const alarm = {
      active: !!alarmConfig,
      onClick: () => alarmData.onClick(sensor),
      onDelete: () => alarmData.onDelete(sensor),
    };

    if (alarmConfig && value) {
      const min = !isNil(alarmConfig.min) ? alarmConfig.min : Number.NEGATIVE_INFINITY;
      const max = !isNil(alarmConfig.max) ? alarmConfig.max : Number.POSITIVE_INFINITY;
      alarm.alert = value < min || value > max;
    }

    return {
      name: {
        t,
        value: sensor.name,
        name: sensor.name,
      },
      value: {
        value: fixedValue,
        unit,
        color,
      },
      status: dataStatus(values && values[sensor.id]),
      onClick: () => onClick(sensor.id),
      addon: { alarm },
    };
  });
};

export const getSensorOptions = (equipmentSensors, t) => {
  return sortBy(
    map(equipmentSensors, sensor => ({
      label: t(sensor.name.replace(/_/g, ' ')),
      value: sensor.id,
      notPerformanceSensor: !includes(sensor.name.toLowerCase(), 'performance'),
    })),
    ['notPerformanceSensor', 'label']
  );
};
