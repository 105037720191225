import React from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import OPICards from 'components/OPICard/OPICards';
import OPICard, { TREND_TYPE } from 'components/OPICard/OPICard';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { overrideColumnWidth } from 'components/BuildingEvents/BuildingEvents';

const createTrend = (value, unit) => {
  let type;
  let stringValue;
  switch (Math.sign(value)) {
    case -1:
      type = TREND_TYPE.NEGATIVE;
      stringValue = `${value} ${unit}`.replace('-', '- ');
      break;
    case 1:
      type = TREND_TYPE.POSITIVE;
      stringValue = `+ ${value} ${unit}`;
      break;
    default:
      type = TREND_TYPE.NEUTRAL;
      stringValue = `${value} ${unit}`;
  }
  return {
    value: stringValue.trim(),
    type,
  };
};

export const RecyclingOPICards = ({
  t,
  recyclingRate,
  recyclingRateDelta,
  recoveryRate,
  recoveryRateDelta,
  carbonFootprint,
  carbonFootprintDelta,
  observations,
}) => (
  <OPICards>
    <OPICard
      t={t}
      title={t('Recycling rate')}
      subtitle={t('Last 30 days')}
      noCircle
      valueInside={`${recyclingRate} %`}
      valueInsideIsPositive={true}
      valueInsideIsNegative={false}
      ctxHelp={`${CTXHELP_PREFIX} Recycling rate OPI`}
      trend={createTrend(recyclingRateDelta, '%')}
    />
    <OPICard
      t={t}
      title={t('Recovery rate')}
      subtitle={t('Last 30 days')}
      noCircle
      valueInside={`${recoveryRate} %`}
      valueInsideIsPositive={true}
      valueInsideIsNegative={false}
      ctxHelp={`${CTXHELP_PREFIX} Recovery rate OPI`}
      trend={createTrend(recoveryRateDelta, '%')}
    />
    <OPICard
      t={t}
      title={t('Carbon footprint')}
      subtitle={t('Last 30 days')}
      noCircle
      valueInside={carbonFootprint}
      valueInsideLabel={t('tonnes')}
      valueInsideIsPositive={true}
      valueInsideIsNegative={false}
      ctxHelp={`${CTXHELP_PREFIX} Carbon footprint OPI`}
      trend={createTrend(carbonFootprintDelta, 't')}
    />
    {observations.total ? (
      <OPICard
        t={t}
        title={t('Recycling observations')}
        subtitle={`${observations.completed ? observations.completed : '-'} ${t('Completed')} / ${
          observations.total ? observations.total : '-'
        } ${t('Total')}\n(${t('Last 365 Days')})`}
        value={0}
        ctxHelp={`${CTXHELP_PREFIX} Recycling observations OPI`}
        icon={'opi-expand'}
        neutral
        overrideColumnWidth={overrideColumnWidth}
      />
    ) : null}
  </OPICards>
);

RecyclingOPICards.defaultProps = {
  observations: { completed: 0, total: 0 },
};

RecyclingOPICards.propTypes = {
  t: PropTypes.func.isRequired,
  recyclingRate: PropTypes.number.isRequired,
  recoveryRate: PropTypes.number.isRequired,
  carbonFootprint: PropTypes.number.isRequired,
  observations: PropTypes.shape({
    completed: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

export default translations(RecyclingOPICards);
