import flatten from 'lodash/flatten';
import compact from 'lodash/compact';

export const constructRelativeUrl = (matchParams, suffix) => {
  const { partnerNumber, functionalLocationId, equipmentNumber, superordinate } = matchParams;
  const isValidPartner = /\d+/.test(partnerNumber);
  const urlParts = compact(
    flatten([
      isValidPartner && partnerNumber,
      functionalLocationId && !equipmentNumber && ['FunctionalLocation', functionalLocationId],
      equipmentNumber && ['Equipment', functionalLocationId, superordinate, equipmentNumber],
      suffix,
    ])
  );

  return `/${urlParts.join('/')}`;
};
