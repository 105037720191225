import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Collapse from '../Collapse/Collapse.jsx';

const rootClass = 'accordion';

export default class Accordion extends Component {
  state = {
    isOpen: [],
  };

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount() {
    if (this.props.isOpen !== undefined) {
      const isOpen = Array.isArray(this.props.isOpen) ? this.props.isOpen : [this.props.isOpen];
      this.setState({
        isOpen: isOpen,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      const isOpen = Array.isArray(this.props.isOpen) ? this.props.isOpen : [this.props.isOpen];
      this.setState({
        isOpen: isOpen,
      });
    }

    // Current implementation cannot track open accordion items reliably if items are added or deleted
    // Reset open state if the number of children changes
    if (
      Array.isArray(this.props.children) &&
      Array.isArray(prevProps.children) &&
      this.props.children.length !== prevProps.children.length
    ) {
      this.setState({ isOpen: [] });
    }
  }

  // Handle clicking of title
  // Opened state is stored as an array of element indexes in this.state.isOpen
  onClick = (event, idx) => {
    event.preventDefault();
    const arrayIdx = this.state.isOpen.indexOf(idx);

    // Default behavior doesn't allow multiple tabs to be open
    let isOpenArray = [];

    if (!this.props.allowMultipleOpen) {
      if (arrayIdx === -1) {
        isOpenArray = [idx];
      }
    } else if (arrayIdx === -1) {
      const newArr = this.state.isOpen.slice();
      newArr.push(idx);
      isOpenArray = newArr;
    } else {
      const newArr = this.state.isOpen.slice();
      newArr.splice(arrayIdx, 1);
      isOpenArray = newArr;
    }

    // Set state with callback
    this.setState(
      {
        isOpen: isOpenArray,
      },
      () => {
        if (typeof this.props.onChange === 'function') {
          this.props.onChange(this.state.isOpen);
        }
      }
    );
  };

  // Render single accordion item
  renderItem = (child, idx) => {
    const isClosed = this.state.isOpen.indexOf(idx) === -1;
    return (
      <div className={rootClass + '-item'} key={'accordion' + idx}>
        <div
          style={child.props.titleStyle}
          onClick={event => this.onClick(event, idx)}
          className={`accordion--${isClosed ? 'closed' : 'open'}`}
        >
          {child.props.titleComponent
            ? React.cloneElement(child.props.titleComponent, { open: !isClosed })
            : child.props.title}
        </div>
        <Collapse
          isCollapsed={isClosed}
          onCollapse={child.props.onCollapse}
          onExpand={child.props.onExpand}
          index={idx}
        >
          {child}
        </Collapse>
      </div>
    );
  };

  render() {
    const { children, allowMultipleOpen, className, isOpen, ...other } = this.props;
    const classes = className ? rootClass + ' ' + className : rootClass;

    return (
      children && (
        <div data-test-id="Accordion" className={classes} {...other}>
          {Array.isArray(children)
            ? children.map((child, idx) => {
                if (child) {
                  return this.renderItem(child, idx);
                }
                return null;
              })
            : this.renderItem(children, 0)}
        </div>
      )
    );
  }
}

Accordion.propTypes = {
  // Custom className
  className: PropTypes.string,
  // Allow multiple elements to be open same time
  allowMultipleOpen: PropTypes.bool,
  // Initial open elements, default is closed
  isOpen: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
  onChange: PropTypes.func,
  // One or many AccordionItems
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};
