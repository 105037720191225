import React from 'react';
import styled from 'styled-components';

import { StyledIcon } from './SortableFLTableRow';
import Svg from 'components/Svg/Svg';

const StyledAddressColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
`;

const ArrowIcon = styled(Svg)`
  fill: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontSize.xxs};
`;

ArrowIcon.displayName = 'ArrowIcon';

export const IconColumn = () => (
  <StyledAddressColumn>
    <StyledIcon marginRight>
      <ArrowIcon name="ion-arrow-right" />
    </StyledIcon>
  </StyledAddressColumn>
);
