import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';

import Svg from 'components/Svg/Svg';
import DefaultInput from './DefaultInput';
import DefaultCheckbox from './DefaultCheckbox';
import MultiInputOptions from './MultiInputOptions';
import InputLabel from 'components/Form/InputLabel';

const RowInputs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin: ${props => !props.dense && props.theme.spacing.md} 0;
`;
RowInputs.displayName = 'RowInputs';

const InputWrapper = styled.div`
  flex: ${props => !props.hasCheckbox && '1'};
  margin-right: ${props => !props.dense && props.theme.spacing.sm};
  width: ${props => props.hasCheckbox && '120px'};
`;
InputWrapper.displayName = 'InputWrapper';

const RemoveIconWrapper = styled.div`
  align-self: flex-end;
  line-height: 3em;
  margin-left: ${props => props.dense && props.theme.spacing.sm};
`;

const RemoveIcon = styled(Svg)`
  fill: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;
RemoveIcon.displayName = 'RemoveIcon';

const MultiInputRow = ({
  row,
  index,
  isLastRow,

  onAddRow,
  onRemoveRow,
  onPropertyChange,

  isDisabledInput,
  isDisabledRow,
  isHiddenRow,
  disableRemoveRow,
  dense,

  baseProperty,
  subProperties,
  subPropertyComponents,
  subPropertyCheckboxes,
  subPropertyLabels,

  options,
  optionsSubProperty,
}) => {
  const firstInputRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  const hiddenRow = isHiddenRow(index, row);
  const disabledRow = isDisabledRow(index, row);

  if (hiddenRow) {
    return null;
  }

  const handleRemoveRow =
    !disableRemoveRow && !disabledRow
      ? e => {
          e.preventDefault();
          onRemoveRow(index);
        }
      : undefined;

  /**
   * onPropertyChange is called with property and value
   * - property is something like 'baseProperty[index].subProperty'
   * - your handler can use setWith: https://lodash.com/docs/4.17.15#setWith
   * */
  const handleInputChange = property => (e, value) => {
    if (!isNil(value)) {
      onPropertyChange(property, value);
    } else {
      onPropertyChange(property, e.target.value);
    }
  };

  /**
   * Add new row when:
   * - tabular key is used
   * - shift is not pressed at the same time
   * - this is the last row
   * - target field has value
   */
  const handleKeyDown = event => {
    if (event.key === 'Tab' && !event.shiftKey && isLastRow && !!event.target.value) {
      event.preventDefault();
      onAddRow();
    }
  };

  return (
    <RowInputs dense={dense}>
      {subProperties.map(subProperty => {
        const hasCheckbox = includes(subPropertyCheckboxes, subProperty);
        const disabled = disabledRow || isDisabledInput(subProperty, index, row);

        const InputComponent = subPropertyComponents[subProperty] || (hasCheckbox && DefaultCheckbox) || DefaultInput;

        const key = `${baseProperty}[${index}].${subProperty}`;
        const forwardRef = isLastRow && InputComponent === DefaultInput && subProperties.indexOf(subProperty) === 0;

        return (
          <InputWrapper key={key} hasCheckbox={hasCheckbox} dense={dense}>
            {index === 0 && <InputLabel text={subPropertyLabels[subProperty] || ''} />}
            <InputComponent
              id={key}
              value={row[subProperty] || ''}
              onKeyDown={handleKeyDown}
              onChange={handleInputChange(key)}
              disabled={disabled}
              list={`options-${key}`}
              {...forwardRef && { ref: firstInputRef }}
            />
            {options && options[subProperty] && (
              <MultiInputOptions
                id={`options-${key}`}
                subProperty={subProperty}
                options={options}
                optionsSubProperty={optionsSubProperty}
                row={row}
              />
            )}
          </InputWrapper>
        );
      })}

      <RemoveIconWrapper dense={dense}>
        <RemoveIcon disabled={disableRemoveRow || disabledRow} name="remove" onClick={handleRemoveRow} />
      </RemoveIconWrapper>
    </RowInputs>
  );
};

MultiInputRow.propTypes = {
  /**
   * Required:
   */
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isLastRow: PropTypes.bool.isRequired,
  onAddRow: PropTypes.func.isRequired,
  onRemoveRow: PropTypes.func.isRequired,
  onPropertyChange: PropTypes.func.isRequired,
  baseProperty: PropTypes.string.isRequired,
  subProperties: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Optional
   */
  subPropertyComponents: PropTypes.object,
  subPropertyCheckboxes: PropTypes.arrayOf(PropTypes.string),
  subPropertyLabels: PropTypes.object,
  disableRemoveRow: PropTypes.bool,
  isHiddenRow: PropTypes.func,
  isDisabledRow: PropTypes.func,
  isDisabledInput: PropTypes.func,
  dense: PropTypes.bool,

  /**
   * Options for inputs. For more information,
   * see MultiInputOptions, storybook, or how meta options are passed from building/partner configuration.
   */
  options: PropTypes.object,
  optionsSubProperty: PropTypes.string,
};

MultiInputRow.defaultProps = {
  subPropertyComponents: {},
  subPropertyCheckboxes: [],
  subPropertyLabels: {},
  disableRemoveRow: false,
  isHiddenRow: () => {
    return false;
  },
  isDisabledRow: () => {
    return false;
  },
  isDisabledInput: () => {
    return false;
  },
  dense: false,
};

export default MultiInputRow;
