import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { clone, find, some } from 'lodash';

import translations from 'decorators/Translations/translations';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import { InputRow, InputLabel, InputText, InputForm, InputSelectDropdown } from 'components/index.js';
import { getFolderOptions, toPath } from 'utils/Data/documents';
import { upsertFolder, loadFolders, upsertPartnerFolder, loadPartnerFolders } from 'redux/modules';

const StyledInputRow = styled(InputRow)`
  width: 100%;
  margin-right: 0 !important;
  margin-left: 0 !important;
`;
StyledInputRow.displayName = 'StyledInputRow';

const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.radicalRed};
  font-weight: ${props => props.theme.font.weight.bold};
`;
ErrorMessage.displayName = 'ErrorMessage';

const nameExists = (folderOptions, folder) => {
  const parent = find(folderOptions, { value: folder.parentId }) || {};
  const folderPath = toPath(parent.label, folder.name);
  return some(folderOptions, { label: folderPath });
};

const FolderManager = ({
  t,
  onClose,
  onSubmit,
  isCreateMode,
  folder,
  folders,
  upsertFolder,
  functionalLocation,
  partnerNumber,
  loadFolders,
}) => {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(clone(folder));
  const [error, setError] = useState(null);

  const formRef = useRef();

  const folderOptions = getFolderOptions(folders, model.id);

  const handleSubmit = useCallback(
    () => {
      if (nameExists(folderOptions, model)) {
        setError(t('Name already exists on this level'));
        return;
      }

      setLoading(true);
      setError(null);

      // Use null instead of empty string
      if (!model.parentId) {
        model.parentId = null;
      }

      let folderId;
      upsertFolder({
        ...model,
        functionalLocation: functionalLocation && functionalLocation.functionalLocation,
        partnerNumber,
      })
        .then(response => {
          if (response.error) {
            throw new Error(t('Save Failed'));
          }
          folderId = response.folderId;
        })
        .then(() => loadFolders())
        .then(() =>
          onSubmit(
            {
              notificationType: 'success',
              notificationVisible: true,
              notificationMessage: isCreateMode ? t('Folder successfully saved') : t('Folder successfully updated'),
            },
            true,
            folderId
          )
        )
        .catch(error => {
          setError(error.message);
          setLoading(false);
        });
    },
    [model]
  );

  const handleFormChange = useCallback(
    (property, value) => {
      setModel({
        ...model,
        [property]: value,
      });
    },
    [model]
  );

  return (
    <DialogModal
      isActive
      t={t}
      onOverlayClick={onClose}
      footer={
        <DialogFooter>
          <Button cancel onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button submit onClick={formRef.current ? formRef.current.onSubmit : undefined} form="folderForm">
            {loading ? <Loader color="WHITE" size="SMALL" /> : t('Save')}
          </Button>
        </DialogFooter>
      }
    >
      <section>
        <h3>{isCreateMode ? t('Add folder') : t('Edit folder')}</h3>
        <InputForm
          id="folderForm"
          onPropertyChange={handleFormChange}
          model={model}
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <StyledInputRow required>
            <InputLabel text={t('Name')} />
            <InputText property="name" id="folderName" />
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel text={t('Parent folder')} />
            <InputSelectDropdown model={model} t={t} property="parentId" options={folderOptions} scrollToMenu />
          </StyledInputRow>
        </InputForm>
        <ErrorMessage>{error}</ErrorMessage>
      </section>
    </DialogModal>
  );
};

FolderManager.defaultProps = {
  isCreateMode: true,
  folder: {},
};

FolderManager.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  folders: PropTypes.array.isRequired,
  functionalLocation: PropTypes.object.isRequired,
  isCreateMode: PropTypes.bool,
  folder: PropTypes.object,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  upsertFolder: folder =>
    ownProps.partnerNumber
      ? dispatch(upsertPartnerFolder(folder))
      : dispatch(upsertFolder(folder, ownProps.functionalLocation)),

  loadFolders: () =>
    ownProps.partnerNumber
      ? dispatch(loadPartnerFolders(ownProps.partnerNumber, true))
      : dispatch(loadFolders(ownProps.functionalLocation, true)),
});

const connector = connect(
  null,
  mapDispatchToProps
);

export default connector(translations(FolderManager));
