import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import range from 'lodash/range';
import values from 'lodash/values';
import moment from 'moment';

import Detail from './Detail';
import { optionLabelOverrides } from 'containers/Application/ServiceCalendar/utils';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { OrderType } from 'constants/serviceCalendar';

const Container = styled.div`
  margin-top: ${props => (props.isLinkedOrder ? props.theme.spacing.md : 0)};
`;

const Details = styled.ul`
  margin-top: ${props => props.theme.spacing.md};
`;
Details.displayName = 'Details';

const ServiceOrderParagraph = styled.p`
  margin-bottom: ${props => props.theme.spacing.xs};
`;
ServiceOrderParagraph.displayName = 'ServiceOrderParagraph';

const RelatedEquipment = styled.div`
  padding: ${props => props.theme.spacing.xs} 0;
`;
RelatedEquipment.displayName = 'RelatedEquipment';

export const SKELETON_TEXT_COUNT = 8;

export const ServiceOrderInfo = ({
  t,
  loadingOrder,
  order,
  functionalLocationId,
  functionalLocation,
  partnerNumber,
  documentCount,
  equipment,
  isLinkedOrder,
}) => {
  const partnerPart = !partnerNumber ? '' : `/${partnerNumber}`;

  if (!order || loadingOrder) {
    return (
      <div>
        <h4>{t('Service Order Info')}</h4>
        <Details>
          {range(SKELETON_TEXT_COUNT).map(index => (
            <Detail key={index} label={<SkeletonText />} value={<SkeletonText />} />
          ))}
        </Details>
      </div>
    );
  }

  const identifier = order.externalId || order.id || order.serviceOrderNumber;
  const year = order.PlannedDate ? moment.utc(order.plannedDate).year() : moment(order.createdDate).year();
  const section = order.orderType === OrderType.PLANNED ? 'PlannedMaintenance' : 'ServiceOrders';
  return (
    <Container isLinkedOrder={isLinkedOrder}>
      <h4>{t(order.orderType === OrderType.PLANNED ? 'Planned Maintenance Info' : 'Service Order Info')}</h4>
      <Details>
        {!isLinkedOrder && functionalLocation && (
          <Detail
            label={t('Location')}
            value={
              <Link
                to={`${partnerPart}/FunctionalLocation/${functionalLocationId}/ServiceCalendar/${section}?year=${year}`}
              >
                {functionalLocation.description}
              </Link>
            }
          />
        )}
        {!isLinkedOrder && functionalLocation && (
          <Detail label={t('Address')} value={`${functionalLocation.address}, ${functionalLocation.city}`} />
        )}
        {!isLinkedOrder && (
          <Detail label={t('Activity Type')} value={optionLabelOverrides.type(order.maintenanceActivityType, { t })} />
        )}
        {identifier && <Detail label={t('Identifier')} value={identifier} />}
        {order.externalType !== 'sap' && (
          <Detail
            label={t('External system')}
            value={optionLabelOverrides.source[order.externalType] || order.externalType}
          />
        )}
        <Detail
          label={t('Responsible')}
          value={
            order.technicalResponsible &&
            order.technicalResponsible.length &&
            order.technicalResponsible.map(responsible => <div key={responsible.id}>{responsible.name}</div>)
          }
        />
        {order.orderType === OrderType.ORDER && <Detail label={t('Created')} value={order.createdDate} isDate />}
        <Detail label={t('Planned Date')} value={order.plannedDate} isPlannedDate />
        <Detail label={t('Last Update')} value={order.modifiedDate} isDate />
        <Detail label={t('Documents')} value={documentCount} />
        {order.description && (
          <Detail
            label={t('Description')}
            value={order.description.split('\n').map((dd, index) => {
              if (dd.trim().length > 0) {
                return <ServiceOrderParagraph key={`${identifier}-${index}`}>{dd}</ServiceOrderParagraph>;
              }
              return null;
            })}
          />
        )}
        {equipment.length > 0 && (
          <Detail
            label={t('Related To')}
            value={equipment.map(equipment => (
              <RelatedEquipment key={equipment.equipmentNumber}>
                <Link
                  to={`${partnerPart}/Equipment/${functionalLocationId}//${
                    equipment.equipmentNumber
                  }/ServiceCalendar/${section}?year=${year}`}
                >
                  {equipment.text}
                </Link>
              </RelatedEquipment>
            ))}
          />
        )}
      </Details>
    </Container>
  );
};

ServiceOrderInfo.propTypes = {
  t: PropTypes.func.isRequired,
  loadingOrder: PropTypes.bool.isRequired,
  order: PropTypes.object,
  functionalLocationId: PropTypes.string.isRequired,
  functionalLocation: PropTypes.object,
  partnerNumber: PropTypes.string.isRequired,
  documentCount: PropTypes.number.isRequired,
  equipment: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.oneOf(values(OrderType)),
  isLinkedOrder: PropTypes.bool,
};

export default ServiceOrderInfo;
