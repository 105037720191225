import { createReducerFromMapping } from 'redux/utils/index.js';
import { Document } from '@caverion/redux/api/actions';
import FileSaver from 'file-saver';
import { isNil } from 'lodash';

const initialState = {
  functionalLocations: {},
  filteredFunctionalLocations: {},
  partners: {},
  filteredPartners: {},
  filterKeyword: null,
  error: null,
  fileCountsByFL: {},
  loadingCounts: false,
  customViewUrls: {},
};

export const LOAD_FILES = 'CUSTOMER_PLATFORM/File/LOAD_FILES';
export const LOAD_FILES_SUCCESS = 'CUSTOMER_PLATFORM/File/LOAD_FILES_SUCCESS';
export const LOAD_FILES_FAIL = 'CUSTOMER_PLATFORM/File/LOAD_FILES_FAIL';

export const loadFiles = (functionalLocation, noCache) => {
  // TODO: Proper cache invalidation
  const filter = {
    where: {
      functionalLocation: functionalLocation.functionalLocation,
    },
    timestamp: noCache ? Date.now() : undefined,
  };

  return async dispatch => {
    dispatch({ type: LOAD_FILES, functionalLocation: functionalLocation.functionalLocation });
    try {
      const result = await dispatch(Document.files(JSON.stringify(filter), true));

      return dispatch({
        type: LOAD_FILES_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FILES_FAIL,
        functionalLocation: functionalLocation.functionalLocation,
        error,
      });
    }
  };
};

export const LOAD_PARTNER_FILES = 'CUSTOMER_PLATFORM/File/LOAD_PARTNER_FILES';
export const LOAD_PARTNER_FILES_SUCCESS = 'CUSTOMER_PLATFORM/File/LOAD_PARTNER_FILES_SUCCESS';
export const LOAD_PARTNER_FILES_FAIL = 'CUSTOMER_PLATFORM/File/LOAD_PARTNER_FILES_FAIL';

export const loadPartnerFiles = (partnerNumber, noCache) => {
  return async dispatch => {
    dispatch({ type: LOAD_PARTNER_FILES, partnerNumber });
    try {
      const result = await dispatch(Document.filesForPartner(partnerNumber, noCache ? Date.now() : undefined));

      return dispatch({
        type: LOAD_PARTNER_FILES_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_PARTNER_FILES_FAIL,
        partnerNumber,
        error,
      });
    }
  };
};

export const UPLOAD_FILE = 'CUSTOMER_PLATFORM/File/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPLOAD_FILE_FAIL';

export const uploadFile = (data, functionalLocation) => {
  const filter = {
    where: {
      functionalLocation: functionalLocation.functionalLocation,
    },
  };

  return async dispatch => {
    dispatch({ type: UPLOAD_FILE });
    try {
      await dispatch(Document.uploadFile(data, JSON.stringify(filter)));
      return dispatch({ type: UPLOAD_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPLOAD_FILE_FAIL,
        error,
      });
    }
  };
};

export const UPLOAD_PARTNER_FILE = 'CUSTOMER_PLATFORM/File/UPLOAD_PARTNER_FILE';
export const UPLOAD_PARTNER_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPLOAD_PARTNER_FILE_SUCCESS';
export const UPLOAD_PARTNER_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPLOAD_PARTNER_FILE_FAIL';

export const uploadPartnerFile = data => {
  return async dispatch => {
    dispatch({ type: UPLOAD_PARTNER_FILE });
    try {
      await dispatch(Document.uploadPartnerFile(data));
      return dispatch({ type: UPLOAD_PARTNER_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPLOAD_PARTNER_FILE_FAIL,
        error,
      });
    }
  };
};

export const UPLOAD_CUSTOM_VIEW_FILE = 'CUSTOMER_PLATFORM/File/UPLOAD_CUSTOM_VIEW_FILE';
export const UPLOAD_CUSTOM_VIEW_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPLOAD_CUSTOM_VIEW_FILE_SUCCESS';
export const UPLOAD_CUSTOM_VIEW_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPLOAD_CUSTOM_VIEW_FILE_FAIL';

export const uploadCustomViewFile = data => {
  return async dispatch => {
    dispatch({ type: UPLOAD_CUSTOM_VIEW_FILE });
    try {
      await dispatch(Document.uploadCustomViewFile(data));
      return dispatch({ type: UPLOAD_CUSTOM_VIEW_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPLOAD_CUSTOM_VIEW_FILE_FAIL,
        error,
      });
    }
  };
};

export const UPDATE_FILE = 'CUSTOMER_PLATFORM/File/UPDATE_FILE';
export const UPDATE_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPDATE_FILE_FAIL';

export const updateFile = (fileId, file, functionalLocation) => {
  const filter = {
    where: {
      functionalLocation: functionalLocation.functionalLocation,
    },
  };

  return async dispatch => {
    dispatch({ type: UPDATE_FILE });
    try {
      await dispatch(Document.updateFile(fileId, file, filter));
      return dispatch({ type: UPDATE_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPDATE_FILE_FAIL,
        error,
      });
    }
  };
};

export const UPDATE_PARTNER_FILE = 'CUSTOMER_PLATFORM/File/UPDATE_PARTNER_FILE';
export const UPDATE_PARTNER_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPDATE_PARTNER_FILE_SUCCESS';
export const UPDATE_PARTNER_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPDATE_PARTNER_FILE_FAIL';

export const updatePartnerFile = (fileId, file) => {
  return async dispatch => {
    dispatch({ type: UPDATE_PARTNER_FILE });
    try {
      await dispatch(Document.updatePartnerFile(fileId, file));
      return dispatch({ type: UPDATE_PARTNER_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPDATE_PARTNER_FILE_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_FILE = 'CUSTOMER_PLATFORM/File/DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'CUSTOMER_PLATFORM/File/DOWNLOAD_FILE_FAIL';

export const downloadFile = (file, functionalLocation, inline) => {
  const filter = {
    where: {
      functionalLocation: functionalLocation.functionalLocation,
      fileId: file.id,
    },
  };

  return async dispatch => {
    dispatch({ type: DOWNLOAD_FILE });

    try {
      if (file.externalType) {
        return downloadExternalFile(file, inline, dispatch);
      }

      const result = await dispatch(Document.getDownloadUrl(file.id, JSON.stringify(filter), inline));
      if (inline) {
        window.open(result.sasURL, 'name');
      } else {
        window.location.href = result.sasURL;
      }
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_FILE_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_PARTNER_FILE = 'CUSTOMER_PLATFORM/File/DOWNLOAD_PARTNER_FILE';
export const DOWNLOAD_PARTNER_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/DOWNLOAD_PARTNER_FILE_SUCCESS';
export const DOWNLOAD_PARTNER_FILE_FAIL = 'CUSTOMER_PLATFORM/File/DOWNLOAD_PARTNER_FILE_FAIL';

export const downloadPartnerFile = (fileId, inline) => {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_PARTNER_FILE });
    inline && window.open('about:blank', 'name');

    try {
      const result = await dispatch(Document.getDownloadUrlForPartner(fileId, inline));
      if (inline) {
        window.open(result.sasURL, 'name');
      } else {
        window.location.href = result.sasURL;
      }
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_PARTNER_FILE_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_SERVICE_ORDER_FILE = 'CUSTOMER_PLATFORM/File/DOWNLOAD_SERVICE_ORDER_FILE';
export const DOWNLOAD_SERVICE_ORDER_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/DOWNLOAD_SERVICE_ORDER_FILE_SUCCESS';
export const DOWNLOAD_SERVICE_ORDER_FILE_FAIL = 'CUSTOMER_PLATFORM/File/DOWNLOAD_SERVICE_ORDER_FILE_FAIL';

export const downloadServiceOrderFile = (file, inline) => {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_SERVICE_ORDER_FILE });
    try {
      if (file.externalType) {
        return downloadExternalFile(file, inline, dispatch);
      }

      const result = await dispatch(Document.getDownloadUrlForServiceOrder(file.id));
      window.location.href = result.sasURL;
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_SERVICE_ORDER_FILE_FAIL,
        error,
      });
    }
  };
};

export const GET_CUSTOM_VIEW_FILE_URL = 'CUSTOMER_PLATFORM/File/GET_CUSTOM_VIEW_FILE_URL';
export const GET_CUSTOM_VIEW_FILE_URL_SUCCESS = 'CUSTOMER_PLATFORM/File/GET_CUSTOM_VIEW_FILE_URL_SUCCESS';
export const GET_CUSTOM_VIEW_FILE_URL_FAIL = 'CUSTOMER_PLATFORM/File/GET_CUSTOM_VIEW_FILE_URL_FAIL';

export const getCustomViewFileUrl = customViewId => {
  return async dispatch => {
    dispatch({ type: GET_CUSTOM_VIEW_FILE_URL });
    try {
      const result = await dispatch(Document.getDownloadUrlForCustomView(customViewId));
      dispatch({
        type: GET_CUSTOM_VIEW_FILE_URL_SUCCESS,
        customViewId,
        url: result.sasURL,
      });
    } catch (error) {
      return dispatch({
        type: GET_CUSTOM_VIEW_FILE_URL_FAIL,
        error,
      });
    }
  };
};

export const DELETE_FILE = 'CUSTOMER_PLATFORM/File/DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = 'CUSTOMER_PLATFORM/File/DELETE_FILE_FAIL';

export const deleteFile = id => {
  return async dispatch => {
    dispatch({ type: DELETE_FILE });
    try {
      await dispatch(Document.deleteFile(id));
      return dispatch({ type: DELETE_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DELETE_FILE_FAIL,
        error,
      });
    }
  };
};

export const GET_FILE_COUNTS = 'CUSTOMER_PLATFORM/File/GET_FILE_COUNTS';
export const GET_FILE_COUNTS_SUCCESS = 'CUSTOMER_PLATFORM/File/GET_FILE_COUNTS_SUCCESS';
export const GET_FILE_COUNTS_FAIL = 'CUSTOMER_PLATFORM/File/GET_FILE_COUNTS_FAIL';

export const getFileCounts = flList => {
  const filter = { where: { functionalLocation: { inq: flList } } };

  return async dispatch => {
    dispatch({ type: GET_FILE_COUNTS });
    try {
      const result = await dispatch(Document.getFileCounts(filter));
      return dispatch({ type: GET_FILE_COUNTS_SUCCESS, result });
    } catch (error) {
      return dispatch({
        type: GET_FILE_COUNTS_FAIL,
        error,
      });
    }
  };
};

export const FILTER_FILES_BY_KEYWORD = 'CUSTOMER_PLATFORM/File/FILTER_FILES_BY_KEYWORD';
export const filterFilesByKeyword = keyword => {
  return {
    type: [FILTER_FILES_BY_KEYWORD],
    keyword,
  };
};

export const RESET_FILTER_FILES_BY_KEYWORD = 'CUSTOMER_PLATFORM/File/RESET_FILTER_FILES_BY_KEYWORD';
export const resetFilterFilesByKeyword = () => {
  return {
    type: [RESET_FILTER_FILES_BY_KEYWORD],
  };
};

async function downloadExternalFile(file, inline, dispatch) {
  const isIE = window.navigator && window.navigator.msSaveOrOpenBlob;
  if (inline && !isIE) {
    window.open('about:blank', 'name');
  }

  const result = await dispatch(
    Document.downloadExternalFile(
      JSON.stringify({
        where: {
          fileId: file.id,
          functionalLocation: file.functionalLocation,
        },
      })
    )
  );

  if (inline && !isIE) {
    window.open(URL.createObjectURL(result), 'name');
  } else {
    FileSaver.saveAs(result, file.name);
  }
}

export default createReducerFromMapping(
  {
    [LOAD_FILES]: (state, action) => ({
      ...state,
    }),
    [LOAD_FILES_SUCCESS]: (state, action) => ({
      ...state,
      functionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: action.result,
      },
      filteredFunctionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: action.result,
      },
    }),
    [LOAD_FILES_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [LOAD_PARTNER_FILES]: (state, action) => ({
      ...state,
    }),
    [LOAD_PARTNER_FILES_SUCCESS]: (state, action) => ({
      ...state,
      partners: {
        ...state.partners,
        [action.partnerNumber]: action.result,
      },
      filteredPartners: {
        ...state.partners,
        [action.partnerNumber]: action.result,
      },
    }),
    [LOAD_PARTNER_FILES_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPLOAD_FILE]: (state, action) => ({
      ...state,
    }),
    [UPLOAD_FILE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [UPLOAD_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPLOAD_PARTNER_FILE]: (state, action) => ({
      ...state,
    }),
    [UPLOAD_PARTNER_FILE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [UPLOAD_PARTNER_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPLOAD_CUSTOM_VIEW_FILE]: state => ({
      ...state,
    }),
    [UPLOAD_CUSTOM_VIEW_FILE_SUCCESS]: state => ({
      ...state,
    }),
    [UPLOAD_CUSTOM_VIEW_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPDATE_FILE]: (state, action) => ({
      ...state,
    }),
    [UPDATE_FILE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [UPDATE_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPDATE_PARTNER_FILE]: (state, action) => ({
      ...state,
    }),
    [UPDATE_PARTNER_FILE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [UPDATE_PARTNER_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [DOWNLOAD_FILE]: (state, action) => ({
      ...state,
    }),
    [DOWNLOAD_FILE_SUCCESS]: (state, action) => {
      FileSaver.saveAs(action.result, action.filename);

      return {
        ...state,
      };
    },
    [DOWNLOAD_FILE_FAIL]: (state, action) => ({
      ...state,
    }),

    [DOWNLOAD_PARTNER_FILE]: (state, action) => ({
      ...state,
    }),
    [DOWNLOAD_PARTNER_FILE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [DOWNLOAD_PARTNER_FILE_FAIL]: (state, action) => ({
      ...state,
    }),

    [GET_CUSTOM_VIEW_FILE_URL]: state => ({
      ...state,
    }),
    [GET_CUSTOM_VIEW_FILE_URL_SUCCESS]: (state, action) => ({
      ...state,
      customViewUrls: { ...state.customViewUrls, [action.customViewId]: action.url },
    }),
    [GET_CUSTOM_VIEW_FILE_URL_FAIL]: state => ({
      ...state,
    }),

    [DELETE_FILE]: (state, action) => ({
      ...state,
    }),
    [DELETE_FILE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [DELETE_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [FILTER_FILES_BY_KEYWORD]: (state, action) => ({
      ...state,
      filteredFunctionalLocations: applyFilter(action.keyword, state.functionalLocations),
      filteredPartners: applyFilter(action.keyword, state.partners),
      filterKeyword: action.keyword,
    }),
    [RESET_FILTER_FILES_BY_KEYWORD]: state => ({
      ...state,
      filteredFunctionalLocations: state.functionalLocations,
      filteredPartners: state.partners,
      filterKeyword: null,
    }),
    [GET_FILE_COUNTS]: state => ({
      ...state,
      loadingCounts: true,
    }),
    [GET_FILE_COUNTS_SUCCESS]: (state, action) => ({
      ...state,
      fileCountsByFL: { ...state.fileCountsByFL, ...action.result },
      loadingCounts: false,
    }),
    [GET_FILE_COUNTS_FAIL]: state => ({
      ...state,
      loadingCounts: false,
    }),
  },
  initialState
);

const applyFilter = (keyword, object) => {
  if (isNil(object)) {
    return object;
  }

  const filtered = {};

  keyword = keyword.toLowerCase();

  for (const k in object) {
    if (!object.hasOwnProperty(k)) {
      continue;
    }

    filtered[k] = object[k].filter(x => (x.name || '').toLowerCase().indexOf(keyword) !== -1);
  }

  return filtered;
};
