import React, { Component } from 'react';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import { head, isArray } from 'lodash';

import { InputRow, InputLabel, InputText, InputForm, Button } from 'components/index.js';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import FileUpload from 'components/FileUpload/FileUpload';

const StyledErrorMessage = styled.h4`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colors.radicalRed};
  margin: ${props => props.theme.spacing.xl};
`;

const Container = styled.section`
  form {
    display: flex;
    flex-direction: column;
  }
`;

const Column = styled.div`
  &:first-child {
    margin-right: ${props => props.theme.spacing.xl};
  }

  ${props => props.theme.media.landscape`
        width: 50%;
    `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;

const StyledHeader = styled.h3`
  margin-bottom: ${props => props.theme.spacing.xl};
`;

const StyledInputRow = styled(InputRow)`
  width: 100%;
  margin-right: 0 !important;
  margin-left: 0 !important;
`;

const ErrorMessage = ({ t }) => <StyledErrorMessage>{t('Save Failed')}</StyledErrorMessage>;

class EditOrCreateFloor extends Component {
  constructor(props) {
    super(props);
    const { id, number, title, image, imageId, imageHeight, imageWidth } = this.props.floorData;
    this.state = {
      model: {
        id,
        order: number,
        shortName: title,
        type: 'floor',
        functionalLocation: props.functionalLocation,
        imagePath: image,
        imageId: imageId,
        imageHeight,
        imageWidth,
      },
      error: '',
      loading: false,
    };
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    this.props
      .handleEditOrCreateFloor(this.state.model)
      .then(() => {
        const notification = {
          type: 'success',
          message: this.props.t('Floor successfully saved'),
        };
        this.setState({ loading: false });
        this.props.onClose(null, notification);
      })
      .catch(error => this.setState({ error, loading: false }));
  };

  handleFormChange = (property, value) => {
    this.setState({
      model: {
        ...this.state.model,
        [property]: value,
      },
    });
  };

  uploadFile = files => {
    const filename = files[0].name;
    const mimeType = files[0].type;
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target.result) {
        let fileString = event.target.result;
        if (fileString.indexOf(',') !== -1) {
          fileString = fileString.split(',')[1];
        }
        this.props
          .uploadFile({
            file: fileString,
            container: 'cdn',
            filename: 'floormap/' + filename,
            mimeType,
          })
          .then(uploadResult => {
            if (uploadResult && uploadResult.result) {
              const result = uploadResult.result;
              const image = isArray(result) ? head(result) : result;
              // save to state.
              this.setState(state => {
                const newModelState = {
                  ...state.model,
                  imagePath: [image.storagePath, image.name].join('/'),
                };
                if (image.width) {
                  newModelState.imageWidth = image.width;
                }
                if (image.height) {
                  newModelState.imageHeight = image.height;
                }
                return {
                  ...state,
                  model: newModelState,
                };
              });
            }
          })
          .catch(error => {
            throw error;
          });
      }
    };
    reader.readAsDataURL(files[0]);
  };
  render() {
    const { t, onClose, isAddFloorMode } = this.props;

    return (
      <DialogModal
        large
        isActive
        onOverlayClick={onClose}
        t={t}
        footer={
          <DialogFooter>
            <Button cancel onClick={onClose}>
              {t('Close')}
            </Button>
            <Button submit loading={this.state.loading} onClick={this.handleSubmit}>
              {t('Save')}
            </Button>
          </DialogFooter>
        }
      >
        <Container>
          <StyledHeader>{isAddFloorMode ? t('Add New Floor') : t('Edit Floor')}</StyledHeader>
          <InputForm
            id={this.props.id}
            model={this.state.model}
            onPropertyChange={this.handleFormChange}
            onSubmit={this.handleSubmit}
          >
            <Row>
              <Column>
                <StyledInputRow>
                  <InputLabel text={t('Floorplan')} />
                  <FileUpload upload={this.uploadFile} t={t} uploading={this.props.uploading} />
                </StyledInputRow>
                <StyledInputRow>
                  <InputLabel text={t('Floor Image')} />
                  <InputText disabled property="imagePath" />
                </StyledInputRow>
              </Column>
              <Column>
                <StyledInputRow required>
                  <InputLabel text={t('Floor Order Number')} />
                  <InputText id="order" type="number" property="order" placeholder={t('Select here')} />
                </StyledInputRow>
                <StyledInputRow required>
                  <InputLabel text={t('Floor Name')} />
                  <InputText id="shortName" property="shortName" placeholder={t('Type here')} />
                </StyledInputRow>
                <StyledInputRow required={!!this.state.model.imagePath}>
                  <InputLabel text={t('Image Width')} />
                  <InputText id="imageWidth" type="number" property="imageWidth" placeholder={t('Type here')} />
                </StyledInputRow>
                <StyledInputRow required={!!this.state.model.imagePath}>
                  <InputLabel text={t('Image Height')} />
                  <InputText id="imageHeight" type="number" property="imageHeight" placeholder={t('Type here')} />
                </StyledInputRow>
              </Column>
            </Row>
            {this.state.error && <ErrorMessage t={t} />}
          </InputForm>
        </Container>
      </DialogModal>
    );
  }
}

export default translations(EditOrCreateFloor);
