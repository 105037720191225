import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

const Container = styled.div`
  padding: ${props => props.theme.spacing.md};
`;

const ColumnContainer = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1;
  word-wrap: break-word;
  margin-right: ${props => props.theme.spacing.md};
  &:last-child {
    margin-right: 0;
  }
`;

const Heading = styled.h6`
  font-family: ${props => props.theme.fontFamily.subHeading};
  font-size: ${props => props.theme.fontSize.text};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: ${props => props.theme.spacing.xs};
`;

const Result = styled.li`
  cursor: pointer;
  font-size: ${props => props.theme.fontSize.note};
  padding: ${props => props.theme.spacing.xs} 0;
  &:last-child {
    padding-bottom: 0;
  }
`;

const Info = styled.div`
  color: ${props => props.theme.colors.darkGray};
  padding-top: ${props => props.theme.spacing.xs};
  font-size: ${props => props.theme.fontSize.note};
`;

const MoreResults = styled(Info)`
  text-align: center;
  padding-top: ${props => props.theme.spacing.md};
`;

const getTotal = items => (items.length ? items[0].totalMatches : items.length);

const getCount = items => (getTotal(items) > items.length ? `${items.length}+` : items.length);

const AutocompleteSuggestions = ({ t, onSelect, suggestions: { customers, functionalLocations } }) => {
  const hasMore = getTotal(customers) + getTotal(functionalLocations) > customers.length + functionalLocations.length;
  return (
    <Container>
      <ColumnContainer>
        <Column>
          <Heading>
            {t('Customers')} ({getCount(customers)})
          </Heading>
          {customers.length ? (
            <ul>
              {customers.map(customer => (
                <Result key={customer.partnerNumber} onClick={() => onSelect(customer)}>
                  {customer.name} ({customer.partnerNumber})
                </Result>
              ))}
            </ul>
          ) : (
            <Info>{t('No customers found.')}</Info>
          )}
        </Column>
        <Column>
          <Heading>
            {t('Functional locations')} ({getCount(functionalLocations)})
          </Heading>
          {functionalLocations.length ? (
            <ul>
              {functionalLocations.map(fl => (
                <Result key={fl.functionalLocation} onClick={() => onSelect(fl)}>
                  {fl.name || fl.description} ({fl.functionalLocation})
                </Result>
              ))}
            </ul>
          ) : (
            <Info>{t('No functional locations found.')}</Info>
          )}
        </Column>
      </ColumnContainer>
      {hasMore && <MoreResults>{t('Please refine the search to see more results.')}</MoreResults>}
    </Container>
  );
};

AutocompleteSuggestions.propTypes = {
  t: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  suggestions: PropTypes.shape({
    customers: PropTypes.arrayOf(PropTypes.object).isRequired,
    functionalLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

export default translations(AutocompleteSuggestions);
