import React from 'react';
import Svg from 'components/Svg/Svg';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import { values, omit } from 'lodash';
import { flIcons } from 'utils/Data/functionalLocations';

export const ICON_TYPES = {
  DEFAULT: 'DEFAULT',
  PORTFOLIO: 'PORTFOLIO',
  SECONDARY: 'SECONDARY',
  EQUIPMENT: 'EQUIPMENT',
  MODAL: 'MODAL',
  MODAL_WARNING: 'MODAL_WARNING',
  TRASH_CAN: 'TRASH_CAN',
  DROPZONE: 'DROPZONE',
  TRANSPARENT: 'TRANSPARENT',
};

export const ICON_SIZES = {
  EXTRA_SMALL: 'EXTRA_SMALL',
  SMALL_MINUS: 'SMALL_MINUS',
  SMALL: 'SMALL',
  SMALL_PLUS: 'SMALL_PLUS',
  MEDIUM_MINUS: 'MEDIUM_MINUS',
  MEDIUM: 'MEDIUM',
};

const getColorsByType = ({ iconType, theme }) => {
  switch (iconType) {
    case ICON_TYPES.DEFAULT: {
      return {
        backgroundColor: theme.colors.blue,
        border: theme.colors.blue,
      };
    }
    case ICON_TYPES.PORTFOLIO: {
      return {
        backgroundColor: theme.colors.cerulean,
        border: theme.colors.cerulean,
      };
    }
    case ICON_TYPES.SECONDARY: {
      return {
        backgroundColor: theme.colors.rockBlue,
        border: theme.colors.rockBlue,
      };
    }
    case ICON_TYPES.EQUIPMENT: {
      return {
        backgroundColor: theme.colors.alabaster,
      };
    }
    case ICON_TYPES.TRASH_CAN: {
      return {
        background: theme.colors.radicalRed,
        border: theme.colors.radicalRed,
      };
    }
    case ICON_TYPES.MODAL_WARNING: {
      return {
        background: theme.colors.radicalRed,
        border: `7px solid ${rgba(theme.colors.radicalRed, 0.5)}`,
        backgroundClip: 'padding-box',
      };
    }
    case ICON_TYPES.MODAL: {
      return {
        background: theme.colors.emerald,
        border: `7px solid ${rgba(theme.colors.emerald, 0.5)}`,
        backgroundClip: 'padding-box',
      };
    }
    case ICON_TYPES.DROPZONE: {
      return {
        background: theme.colors.cerulean,
        border: `7px solid ${rgba(theme.colors.cerulean, 0.5)}`,
        backgroundClip: 'padding-box',
      };
    }
    case ICON_TYPES.TRANSPARENT: {
      return {
        backgroundColor: theme.colors.transparent,
        border: theme.colors.transparent,
      };
    }
    default:
      return {
        backgroundColor: theme.colors.white,
        border: theme.colors.white,
      };
  }
};

export const getIconContainerSizeByType = size => {
  switch (size) {
    case ICON_SIZES.EXTRA_SMALL:
      return '1em';
    case ICON_SIZES.SMALL_MINUS:
      return '1.5em';
    case ICON_SIZES.SMALL:
      return '2em';
    case ICON_SIZES.SMALL_PLUS:
      return '2.5em';
    case ICON_SIZES.MEDIUM_MINUS:
      return '3em';
    case ICON_SIZES.MEDIUM:
      return '5em';
    default:
      return '1em';
  }
};

export const IconContainer = styled.div`
  ${props => getColorsByType(props)};
  border-radius: 50%;
  float: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => getIconContainerSizeByType(props.size)};
  height: ${props => getIconContainerSizeByType(props.size)};
  margin-top: 0.5em;

  :hover {
    cursor: ${props => (props.hover ? 'pointer' : 'auto')};
  }
`;
IconContainer.displayName = 'IconContainer';

export const getIconFontSizeByType = size => {
  switch (size) {
    case ICON_SIZES.EXTRA_SMALL:
      return '0.5em';
    case ICON_SIZES.SMALL_MINUS:
      return '0.75em';
    case ICON_SIZES.SMALL:
      return '1em';
    case ICON_SIZES.MEDIUM_MINUS:
      return '1.25em';
    case ICON_SIZES.MEDIUM:
      return '2.25em';
    default:
      return '1em';
  }
};

const StyledIcon = styled(({ ...props }) => <Svg {...omit(props, 'hover')} />)`
  font-size: ${props => getIconFontSizeByType(props.size)};
  fill: ${props => props.fill};
  margin: 0.4em 0;
`;
StyledIcon.displayName = 'StyledIcon';

export const Icon = ({ name, fill, iconType, size, onClick }) => {
  if (iconType) {
    return (
      <IconContainer iconType={iconType} size={size} onClick={onClick} hover={!!onClick}>
        <StyledIcon name={name} fill={fill} size={size} />
      </IconContainer>
    );
  }
  return <StyledIcon name={name} fill={fill} size={size} onClick={onClick} hover={!!onClick} />;
};

Icon.defaultProps = {
  name: flIcons.BE,
  fill: 'white',
  iconType: '',
  size: ICON_SIZES.MEDIUM,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconType: PropTypes.string,
  size: PropTypes.oneOf(values(ICON_SIZES)),
};

export default withTheme(Icon);
