import React from 'react';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import WasteChartSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteChart/WasteChartSkeleton';
import WasteTimeframeSelectorSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTimeframeSelector/WasteTimeframeSelectorSkeleton';
import WasteTimeframeCategorySelectorSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTimeframeCategorySelector/WasteTimeframeCategorySelectorSkeleton';
import WasteTotalsSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTotals/WasteTotalsSkeleton';

export const BreakdownAllWasteTypesSkeleton = () => (
  <Columns justifyContent="space-between">
    <Column columnWidth={{ landscape: 9, default: 12 }}>
      <WasteChartSkeleton />
      <WasteTimeframeSelectorSkeleton />
    </Column>
    <Column columnWidth={{ landscape: 3, default: 12 }}>
      <WasteTimeframeCategorySelectorSkeleton />
      <WasteTotalsSkeleton />
    </Column>
  </Columns>
);

export default BreakdownAllWasteTypesSkeleton;
