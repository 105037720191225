import React, { useState } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import ObservationsModal from './ObservationsModal';
import MonthSelector from './MonthSelector';

export const Observations = ({ observations = [], t }) => {
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [modalVisible, toggleModal] = useState(false);
  const handleMonthClick = ({ year, month }) => {
    setMonth(month);
    setYear(year);
    toggleModal(true);
  };
  return (
    <Section>
      <SectionHeader title={t('Observations')} />
      <MonthSelector onMonthClick={handleMonthClick} items={observations} toggleActiveMonth={false} />
      {modalVisible && (
        <ObservationsModal observations={observations} filter={{ year, month }} onClose={() => toggleModal(false)} />
      )}
    </Section>
  );
};

Observations.propTypes = {
  t: PropTypes.func.isRequired,
  observations: PropTypes.array,
};

export default translations(Observations);
