import React from 'react';
import BarChart from './BarChart';
import { colors } from '../../styles/definitions';

const CleaningChart = props => {
  const mobile = window.innerWidth < 900;

  return (
    <BarChart
      t={props.t}
      type="column"
      categories={props.categories}
      hideLegend
      hideDecimals
      yOpposite
      disableTooltip
      xTitle={!mobile && props.xTitle}
      series={[
        {
          data: props.data,
          _unit: !mobile ? props.t('Times Cleaned') : '',
          color: colors.emerald,
        },
      ]}
      loading={props.loading}
      error={props.error}
      height={props.height}
    />
  );
};

export default CleaningChart;
