import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import SensorAlarmForm from 'components/SensorAlarmForm/SensorAlarmForm';
import { upsertSensorAlarm, getSensorAlarmsBySensorId } from 'redux/modules/iot/sensorAlarms';
import { getPerformanceLimit } from 'utils/Data/performance';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import find from 'lodash/find';
import get from 'lodash/get';

const Heading = styled.h3`
  margin-bottom: ${props => props.theme.spacing.md};
`;

const units = [
  { unit: 'day', coefficient: 60 * 60 * 24, label: 'Days' },
  { unit: 'hour', coefficient: 60 * 60, label: 'Hours' },
  { unit: 'minute', coefficient: 60, label: 'Mins' },
];

const unitOptions = [...units].reverse();

export const secondsToDelay = seconds => {
  if (typeof seconds !== 'number' || seconds <= 0) {
    return { unit: 'minute', value: 0 };
  }

  const suitable = find(units, unit => seconds % unit.coefficient === 0);
  if (suitable) {
    return { unit: suitable.unit, value: seconds / suitable.coefficient };
  }

  return { unit: 'minute', value: Math.round(seconds / 60) };
};

export const delayToSeconds = delay => {
  if (!delay || delay.value <= 0) {
    return 0;
  }

  const unit = find(units, unit => unit.unit === delay.unit);
  return delay.value * unit.coefficient;
};

export const dtoToModel = data => {
  if (!data) {
    return undefined;
  }

  return {
    ...data,
    alarmDelay: secondsToDelay(data.alarmDelay),
    occupiedAlarm: data.alarmType === 'OCCUPANCY' ? (data.minValue === 0 ? 'occupied' : 'available') : undefined,
  };
};

export const modelToDto = data => {
  let { minValue, maxValue } = data;
  if (data.occupiedAlarm) {
    if (data.occupiedAlarm === 'occupied') {
      minValue = 0;
      maxValue = 0;
    } else {
      minValue = 1;
      maxValue = null;
    }
  }

  return {
    ...data,
    alarmDelay: delayToSeconds(data.alarmDelay),
    alarmType: data.occupiedAlarm ? 'OCCUPANCY' : 'STANDARD',
    minValue,
    maxValue,
  };
};

export const SensorAlarm = props => {
  const {
    t,
    sensorAlarmsById,
    submitting,
    onClose,
    onError,
    onSuccess,
    upsertSensorAlarm,
    buildingMeta,
    sensor,
  } = props;

  const sensorId = sensor.id;
  const sensorName = sensor.name;
  const sensorType = sensor.sensorType;
  const isPresence = sensorType && sensorType.graphType === 'presence';
  const aggregation = get(sensor, 'sensorType.latestValueAggregation.aggregation') || 'raw';

  const getDefaultData = () => {
    const defaults = {
      sensorId,
      weekdaysOnly: true,
      aggregation,
      alarmDelay: secondsToDelay(0),
    };

    if (!isPresence) {
      const getLimits = getPerformanceLimit(sensor, sensor.parent, buildingMeta);
      const limits = getLimits && getLimits(new Date());
      if (limits) {
        const [minValue, maxValue] = limits;
        return {
          ...defaults,
          minValue,
          maxValue,
        };
      }
    }

    return defaults;
  };

  const initialValue = dtoToModel(sensorAlarmsById[sensorId]) || getDefaultData();
  const [model, setModel] = useState(initialValue);

  const handleFormChange = (property, value) => setModel(set(cloneDeep(model), property, value));

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await upsertSensorAlarm(modelToDto(model));
      return onSuccess(t('Sensor alarm set successfully'));
    } catch (error) {
      return onError(t('Setting sensor alarm failed!'));
    }
  };

  const invalidDelay = delayToSeconds(model.alarmDelay) > 60 * 60 * 24 * 7;
  return (
    <form>
      <DialogModal
        t={t}
        isActive
        animate
        onOverlayClick={onClose}
        footer={
          <DialogFooter>
            <Button cancel onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button
              submit
              onClick={handleSubmit}
              type="submit"
              loading={submitting}
              disabled={(isNaN(model.minValue) && isNaN(model.maxValue) && !model.occupiedAlarm) || invalidDelay}
            >
              {t('Set Alarm')}
            </Button>
          </DialogFooter>
        }
      >
        <Heading>{t('Set Alarm')}</Heading>
        <SensorAlarmForm
          model={model}
          onPropertyChange={handleFormChange}
          sensorName={sensorName}
          t={t}
          isPresence={isPresence}
          delayUnits={unitOptions}
          invalidDelay={invalidDelay}
        />
      </DialogModal>
    </form>
  );
};

SensorAlarm.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  sensorAlarmsById: PropTypes.object.isRequired,
  sensor: PropTypes.object.isRequired,
  parent: PropTypes.object,
  upsertSensorAlarm: PropTypes.func.isRequired,

  buildingMeta: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
  submitting: state.sensorAlarms.loading,
  sensorAlarmsById: getSensorAlarmsBySensorId(state),
});

const mapDispatchToProps = {
  upsertSensorAlarm,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(SensorAlarm));
