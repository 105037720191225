import React from 'react';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

const StyledPill = styled.div`
  border-radius: 25px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  height: 100%;
  width: auto;
  line-height: 1.5em;
  background-color: ${props => props.color || props.theme.colors.darkGray};
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9em;
`;

const Icon = styled(Svg)`
  fill: ${props => props.theme.colors.white};
  margin-right: ${props => props.theme.spacing.xs};
`;

const Label = styled.span`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSize.xs};
`;

const Pill = props => {
  const { icon, children, ...other } = props;

  return (
    <StyledPill {...other}>
      {icon && <Icon name={icon} />}
      <Label>{children}</Label>
    </StyledPill>
  );
};

export default Pill;
