import { createSelector, createStructuredSelector } from 'reselect';
import values from 'lodash/values';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';

import { filterServiceOrders, formatOrderForCalendar, filterByDay, filterByMonths } from 'utils/Data/serviceOrders';
import {
  getPartnerNumber,
  getFunctionalLocations,
  getLoadingFLs,
  getActiveFilter,
  getMonths,
  getDay,
  getFunctionalLocation,
  getProfile,
} from './common';

export const getPlannedMaintenances = (state, { year }) => state.plannedMaintenance.index.byYear[year];

export const getPlannedMaintenanceList = createSelector(
  [getPlannedMaintenances],
  plannedMaintenances => values(plannedMaintenances)
);

/**
 * Get planned maintenances for a year for a partner (or all partners)
 * Expects the following props: year, match.params['partnerNumber' | 'functionalLocationId'] match.params.
 */
export const getPlannedMaintenancesForPartner = createSelector(
  [getPlannedMaintenanceList, getPartnerNumber, getFunctionalLocation],
  (plannedMaintenances, partnerNumber, functionalLocationId) => {
    if (functionalLocationId) {
      return plannedMaintenances.filter(maintenance => includes(maintenance.path, functionalLocationId));
    }

    return partnerNumber === 'all'
      ? plannedMaintenances
      : plannedMaintenances.filter(maintenance => includes(maintenance.partnerNumberWithParents, partnerNumber));
  }
);

/**
 * Get service orders for a year for a partner (or all partners) with FLs
 * Expects the following props: year, match.params['partnerNumber' | 'functionalLocationId'], loadingFLs
 */
export const getPlannedMaintenancesForPartnerWithFLs = createSelector(
  [getPlannedMaintenancesForPartner, getFunctionalLocations, getLoadingFLs, getProfile],
  (plannedMaintenances, functionalLocations, loadingFLs, profile) =>
    plannedMaintenances.map(plannedMaintenance =>
      formatOrderForCalendar(plannedMaintenance, functionalLocations, profile, true, loadingFLs)
    )
);

/**
 * Get filtered planned maintenances for a year for a partner (or all partners) with FLs
 * Expects the following props: year, match.params['partnerNumber' | 'functionalLocationId'], activeFilter, loadingFLs
 */
export const getFilteredPlannedMaintenances = createSelector(
  [getPlannedMaintenancesForPartnerWithFLs, getActiveFilter],
  (plannedMaintenances, activeFilter) => filterServiceOrders(plannedMaintenances, activeFilter)
);

/**
 * Get filtered planned maintenances (not filtered by status!) for a year for a partner (or all partners) with FLs
 * Expects the following props: year, match.params['partnerNumber' | 'functionalLocationId'], activeFilter, loadingFLs
 */
export const getFilteredWithoutStatusPlannedMaintenances = createSelector(
  [getPlannedMaintenancesForPartnerWithFLs, getActiveFilter],
  (plannedMaintenances, activeFilter) => filterServiceOrders(plannedMaintenances, activeFilter, 'status')
);

/**
 * Get planned maintenances for a year / months for a partner (or all partners) with FLs
 * Expects the following props: year, months, match.params['partnerNumber' | 'functionalLocationId'], loadingFLs
 */
export const getPlannedMaintenacesForDates = createSelector(
  [getPlannedMaintenancesForPartnerWithFLs, getMonths, getDay],
  (plannedMaintenances, months, day) => {
    if (day) {
      return filterByDay(plannedMaintenances, months[0], day);
    }

    return months.length ? filterByMonths(plannedMaintenances, months) : plannedMaintenances;
  }
);

/**
 * Get filtered planned maintenances for a year / months for a partner (or all partners) with FLs
 * Expects the following props: year, months, match.params['partnerNumber' | 'functionalLocationId'], activeFilter,
 * loadingFLs
 */
export const getFilteredPlannedMaintenacesForDates = createSelector(
  [getFilteredPlannedMaintenances, getPlannedMaintenacesForDates],
  (filtered, selected) => intersection(filtered, selected)
);

/**
 * Get filtered planned maintenances (not by status!) for a year / months for a partner (or all partners) with FLs
 * Expects the following props: year, months, match.params['partnerNumber' | 'functionalLocationId'], activeFilter,
 * loadingFLs
 */
export const getFilteredWithoutStatusPlannedMaintenacesForDates = createSelector(
  [getFilteredWithoutStatusPlannedMaintenances, getPlannedMaintenacesForDates],
  (filtered, selected) => intersection(filtered, selected)
);

/**
 * Get all planned maintenance list variants for service calendar
 * Expects the following props: year, months, match.params['partnerNumber' | 'functionalLocationId'], activeFilter,
 * loadingFLs
 */
export const getPlannedMaintenanceListVariants = createStructuredSelector({
  all: getPlannedMaintenancesForPartnerWithFLs,
  filtered: getFilteredPlannedMaintenances,
  filteredWithoutStatus: getFilteredWithoutStatusPlannedMaintenances,
  selected: getPlannedMaintenacesForDates,
  selectedAndFiltered: getFilteredPlannedMaintenacesForDates,
  selectedAndFilteredWithoutStatus: getFilteredWithoutStatusPlannedMaintenacesForDates,
});
