import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import sum from 'lodash/sum';
import keys from 'lodash/keys';
import isNil from 'lodash/isNil';
import moment from 'moment-timezone';

import { loadAlarmsContainer, loadMoreAlarms } from 'redux/modules/containers/remote-center';
import { percentage } from 'utils/math';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { overrideColumnWidth } from 'components/BuildingEvents/BuildingEvents';
import { filterFLsBySelectedPartner } from 'containers/Application/Energy/EnergyOptimizationUtil';
import DialogModal from 'components/Dialog/DialogModal';
import EventSummary from 'components/EventSummary/EventSummary';
import Benchmarking from './Benchmarking';
import ChartSection from './ChartSection';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';

const EMPTY_ARRAY = [];
const getMostAlarms = (benchmarking, functionalLocations, partnerNumber) => {
  if (!benchmarking || !functionalLocations) {
    return EMPTY_ARRAY;
  }

  const flList = functionalLocations.map(fl => {
    const to = `/${partnerNumber}/FunctionalLocation/${fl.functionalLocation}/RemoteCenter/Alarms`;
    const address = (fl.address && fl.city && `${fl.address}, ${fl.city}`) || '';
    const count = benchmarking[fl.functionalLocation] || 0;
    return { title: fl.description, subtitle: address, value: count, to };
  });
  return orderBy(flList, 'value', 'desc');
};

const currentYear = moment().year();
const HANDLING_TIME_TAB = 'handlingTime';

const Alarms = props => {
  const {
    t,
    partnerNumber,
    loadData,
    loadMore,
    opis: { performance, counts, handlingTimeOpi, timeToActionOpi },
    benchmarking,
    functionalLocations,
    handlingTime,
    timeToAction,
  } = props;

  const [showActions, setShowActions] = useState(false);
  const [selectedTab, setSelectedTab] = useState(HANDLING_TIME_TAB);
  const [year, setYear] = useState(currentYear);
  const [scrollTo, setScrollTo] = useState(false);

  useEffect(
    () => {
      loadData(partnerNumber);
    },
    [partnerNumber]
  );

  const flList = values(filterFLsBySelectedPartner(functionalLocations, partnerNumber)) || EMPTY_ARRAY;
  const mostAlarms = getMostAlarms(benchmarking, flList, partnerNumber);

  const chartData = selectedTab === HANDLING_TIME_TAB ? handlingTime : timeToAction;
  const yearlyChartData = chartData && chartData[year];
  const series =
    (yearlyChartData &&
      yearlyChartData.data &&
      yearlyChartData.data.map(data => ({ ...data, sensorName: 'no temperature' }))) ||
    [];

  const changeYear = newYear => {
    setYear(newYear);
    loadMore(partnerNumber, newYear, selectedTab === HANDLING_TIME_TAB);
  };

  const changeTab = newTab => {
    setSelectedTab(newTab);
    loadMore(partnerNumber, year, newTab === HANDLING_TIME_TAB);
  };

  const scrollToElement = tab => {
    selectedTab !== tab && changeTab(tab);
    setScrollTo(true);
    setTimeout(() => setScrollTo(false), 500);
  };

  return (
    <Fragment>
      <OPICards>
        <OPICard
          t={t}
          loading={performance.loading}
          title={t('Alarms')}
          value={performance.value}
          subtitle={`${performance.handled ? performance.handled : '-'}\u00A0${t('Handled')}\u00A0/\u00A0${
            performance.total ? performance.total : '-'
          }\n(${t('Last 365 Days')})`}
          ctxHelp={`${CTXHELP_PREFIX} Alarms OPI`}
          overrideColumnWidth={overrideColumnWidth}
        />
        <OPICard
          t={t}
          loading={counts.loading}
          title={t('Actions to Alarms')}
          valueInside={counts.total}
          subtitle={t('Last 365 Days')}
          ctxHelp={`${CTXHELP_PREFIX} Action breakdown`}
          overrideColumnWidth={overrideColumnWidth}
          onClick={() => setShowActions(true)}
          noCircle
          icon="opi-expand"
        />
        <OPICard
          t={t}
          title={t('Alarm handling time')}
          subtitle={t('Last 365 days average')}
          valueInside={handlingTimeOpi.mean}
          valueInsideLabel={t('min')}
          valueInsideIsPositive={handlingTimeOpi.positive}
          noCircle
          ctxHelp={`${CTXHELP_PREFIX} Alarm handling time`}
          loading={handlingTimeOpi.loading}
          overrideColumnWidth={overrideColumnWidth}
          onClick={() => scrollToElement('handlingTime')}
        />
        <OPICard
          t={t}
          title={t('Time to action')}
          subtitle={t('Last 365 days average')}
          valueInside={timeToActionOpi.mean}
          valueInsideLabel={t('min')}
          valueInsideIsPositive={timeToActionOpi.positive}
          noCircle
          ctxHelp={`${CTXHELP_PREFIX} Time to action`}
          loading={timeToActionOpi.loading}
          overrideColumnWidth={overrideColumnWidth}
          onClick={() => scrollToElement('timeToAction')}
        />
      </OPICards>
      <Benchmarking
        t={t}
        ctxHelp={`${CTXHELP_PREFIX} Observations Benchmarking`}
        leftTitle="Most alarms"
        rightTitle="Least alarms"
        leftData={mostAlarms}
        rightData={mostAlarms.slice().reverse()}
        infoText="Last 365 Days"
        loading={!benchmarking || benchmarking.loading}
      />
      {scrollTo && <ScrollToComponent />}
      <ChartSection
        t={t}
        year={year}
        changeYear={changeYear}
        series={series}
        loading={!yearlyChartData || yearlyChartData.loading}
        tabOptions={[
          { label: t('Average alarm handling time'), value: 'handlingTime' },
          { label: t('Average time to action'), value: 'timeToAction' },
        ]}
        selectedTab={selectedTab}
        onTabChange={(property, value) => changeTab(value)}
        lineChart
      />
      {showActions && (
        <DialogModal isActive onOverlayClick={() => setShowActions(false)} t={t}>
          <EventSummary
            breakdownHeading={t('Action breakdown')}
            breakdownItems={counts ? orderBy(counts.byAction, 'value', 'desc') : {}}
            t={t}
          />
        </DialogModal>
      )}
    </Fragment>
  );
};

Alarms.propTypes = {
  t: PropTypes.func.isRequired,
  partnerNumber: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  opis: PropTypes.object.isRequired,
  benchmarking: PropTypes.object,
  functionalLocations: PropTypes.object.isRequired,
  handlingTime: PropTypes.object,
  timeToAction: PropTypes.object,
};

const getPerformanceOPI = createSelector(
  (state, props) => state.alarm.byPartner[props.partnerNumber],
  state => state.alarm.byPartner.loading,
  (alarms, loading) => {
    const total = alarms ? alarms.total : null;
    const handled = alarms ? alarms.handled : null;
    return {
      loading,
      total,
      handled,
      value: alarms ? percentage(handled, total) : undefined,
    };
  }
);

const meanCombiner = (kpi, loading, limit) => {
  const mean = kpi && !isNil(kpi.mean) ? kpi.mean : '-';
  return {
    loading,
    mean,
    positiveValue: mean < limit,
  };
};

const getHandlingTimeOPI = createSelector(
  (state, props) => state.alarm.handlingTimeKPI[props.partnerNumber],
  state => state.alarm.handlingTimeKPI.loading,
  () => 60,
  meanCombiner
);

const getTimeToActionOPI = createSelector(
  (state, props) => state.alarm.timeToActionKPI[props.partnerNumber],
  state => state.alarm.timeToActionKPI.loading,
  () => 180,
  meanCombiner
);

const getActionCountOPI = createSelector(
  (state, props) => state.alarm.actionCounts[props.partnerNumber],
  (state, props) =>
    !state.alarm.actionCounts[props.partnerNumber] || state.alarm.actionCounts[props.partnerNumber].loading,
  (data, loading) => {
    const counts = data && data.data;
    const total = counts ? sum(values(counts)) : null;
    const byAction =
      counts &&
      keys(counts).map(count => {
        const parts = count.split('_');
        const activeKeyMax = parts[0] === 'FI' ? 10 : 8;
        const group = +parts.pop() <= activeKeyMax ? count : 'unknown';
        return { title: `alarmActionGroup_${group}`, value: counts[count] };
      });
    return {
      loading,
      total,
      byAction,
    };
  }
);

const getOPIs = createStructuredSelector({
  performance: getPerformanceOPI,
  counts: getActionCountOPI,
  handlingTimeOpi: getHandlingTimeOPI,
  timeToActionOpi: getTimeToActionOPI,
});

const mapStateToProps = (state, props) => ({
  opis: getOPIs(state, props),
  benchmarking: state.alarm.benchmarking[props.partnerNumber],
  functionalLocations: state.functionalLocations.functionalLocations,
  handlingTime: state.alarm.handlingTime[props.partnerNumber],
  timeToAction: state.alarm.timeToAction[props.partnerNumber],
});

const mapDispatchToProps = dispatch => ({
  loadData: partnerNumber => dispatch(loadAlarmsContainer(partnerNumber)),
  loadMore: (partnerNumber, year, isHandlingTime) => dispatch(loadMoreAlarms(partnerNumber, year, isHandlingTime)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alarms);
