import React from 'react';
import PropTypes from 'prop-types';

import translations from 'decorators/Translations/translations';
import UserManualContainer from './UserManualContainer';
import { CUSTOM_TYPE } from './utils/UserManualUtils';

export const AdminUserManual = ({ history, t }) => (
  <UserManualContainer type={CUSTOM_TYPE.ADMIN_USER_MANUAL} title={t('Admin User Manual')} history={history} />
);

AdminUserManual.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default translations(AdminUserManual);
