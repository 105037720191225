import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import ZoomableChart, { ChartWrapper, ResetButton } from 'components/Charts/ZoomableChart';
import WasteTypeSelector from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTypeSelector/WasteTypeSelector';
import WasteTotals from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTotals/WasteTotals';
import translations from 'decorators/Translations/translations';
import { withTheme } from 'styled-components';
import {
  calculateYearlyDataForWasteType,
  sensorTypeNameToWasteType,
  carbonFootprintSensorTypes,
} from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';
import { tabs } from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteBreakdown';

const filterSensorsByTab = (wasteSensors, tab) => {
  switch (tab) {
    case tabs.WASTE_AMOUNTS:
      return wasteSensors;
    case tabs.CARBON_FOORTPRINT:
      return wasteSensors.filter(sensor => includes(carbonFootprintSensorTypes, sensor.sensorType?.name));
    default:
      throw new Error('Unsupported tab');
  }
};

export const BreakdownOneWasteType = ({
  t,
  theme,
  profile,
  wasteSensors,
  tab,
  selectedSensor,
  dataSlice,
  reset,
  selectSensor,
}) => {
  const calculatedData = calculateYearlyDataForWasteType(dataSlice);
  const transformedData = {
    ...calculatedData,
    series: calculatedData.series.map(entry => ({
      ...entry,
      type: 'column',
      color: theme.charts.colorsRecycling[entry.colorName],
      _unit: t('tonnes'),
      _showTooltipForZeroValue: true,
    })),
    totals: {
      ...calculatedData.totals,
      title: t(sensorTypeNameToWasteType(selectedSensor.sensorType?.name)),
      unit: t('tonnes'),
    },
  };
  const wasteTypeOptions = orderBy(
    filterSensorsByTab(wasteSensors, tab).map(wasteSensor => ({
      value: wasteSensor.id,
      label: t(sensorTypeNameToWasteType(wasteSensor.sensorType?.name)),
    })),
    'label',
    'asc'
  );
  return (
    <Columns justifyContent="space-between">
      <Column columnWidth={{ landscape: 9, default: 12 }}>
        <ChartWrapper>
          <ZoomableChart
            t={t}
            language={profile.language}
            categories={moment
              .monthsShort()
              .map(t)
              .map(capitalize)}
            series={transformedData.series}
            xTitle={null}
          />
          <ResetButton onClick={reset}>{t('Back')}</ResetButton>
        </ChartWrapper>
      </Column>
      <Column columnWidth={{ landscape: 3, default: 12 }}>
        <WasteTypeSelector
          wasteTypeOptions={wasteTypeOptions}
          selectedWasteType={selectedSensor.id}
          onWasteTypeChange={selectSensor}
        />
        <WasteTotals
          profile={profile}
          selectedTimeframeDescription={t('Past 12 months')}
          totals={transformedData.totals}
        />
      </Column>
    </Columns>
  );
};

BreakdownOneWasteType.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  tab: PropTypes.number.isRequired,
  selectedSensor: PropTypes.object.isRequired,
  dataSlice: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  reset: PropTypes.func.isRequired,
  selectSensor: PropTypes.func.isRequired,
};

export default withTheme(translations(BreakdownOneWasteType));
