import _ from 'lodash';
import { createReducerFromMapping } from 'redux/utils/index.js';

import { loadAvailableSensors, loadSensorHistoryValues, loadLast } from 'redux/modules/index.js';

const initialState = {
  loading: true,
  error: false,
};

// Building energy signature is the latest MainMeter kW/m2 (/year) value.
export const SIGNATURE = ['MainMeter_kW_m2', 'MainMeter/kW_m2', 'kW_m2'];

// Building energy consumption is calculated by summing the hourly kWh values.
export const HOURLY = ['MainMeter_kWh_hourly', 'MainMeter/kWh_hourly', 'kWh_hourly'];

export const CCC_ENERGY_CONTAINER_LOAD = 'CUSTOMER_PLATFORM/CCCEnergyContainer/LOAD';
export const CCC_ENERGY_CONTAINER_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/CCCEnergyContainer/LOAD_SUCCESS';
export const CCC_ENERGY_CONTAINER_LOAD_FAIL = 'CUSTOMER_PLATFORM/CCCEnergyContainer/LOAD_FAIL';

export const loadCCCEnergyContainer = (
  functionalLocation,
  startTime,
  endTime,
  monthStartTime,
  monthEndTime,
  equipment
) => {
  return async dispatch => {
    dispatch({ type: CCC_ENERGY_CONTAINER_LOAD });
    try {
      const sensorsResult = await dispatch(loadAvailableSensors(functionalLocation, equipment));
      const sensors = sensorsResult.result || [];

      sensors.length > 0 &&
        (await Promise.all([
          // Load the latest (last) energy signature values
          Promise.all(
            sensors
              .filter(sensor => _.includes(SIGNATURE, sensor.sensorName))
              .map(sensor => dispatch(loadLast(functionalLocation, sensor.sensorName, 'latest', equipment)))
          ),
          // Load monthly aggregated hourly consumption data since the beginning of last year
          Promise.all(
            sensors
              .filter(sensor => _.includes(HOURLY, sensor.sensorName))
              .map(sensor =>
                dispatch(
                  loadSensorHistoryValues(functionalLocation, sensor.sensorName, startTime, endTime, 'month', equipment)
                )
              )
          ),
          // Load daily aggregated hourly consumption data
          Promise.all(
            sensors
              .filter(sensor => _.includes(HOURLY, sensor.sensorName))
              .map(sensor =>
                dispatch(
                  loadSensorHistoryValues(
                    functionalLocation,
                    sensor.sensorName,
                    monthStartTime,
                    monthEndTime,
                    'day',
                    equipment
                  )
                )
              )
          ),
        ]));

      return dispatch({ type: CCC_ENERGY_CONTAINER_LOAD_SUCCESS });
    } catch (error) {
      return dispatch({
        type: CCC_ENERGY_CONTAINER_LOAD_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [CCC_ENERGY_CONTAINER_LOAD]: state => ({
      ...state,
      loading: true,
    }),
    [CCC_ENERGY_CONTAINER_LOAD_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [CCC_ENERGY_CONTAINER_LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
