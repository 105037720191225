import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NavBarButton from 'components/NavBarButton/NavBarButton';
import UnreadIndicator from './UnreadIndicator';

export const Unread = styled(UnreadIndicator)`
  position: absolute;
  top: 13px;
  right: -2px;
  display: none;
  ${props => props.theme.media.portrait`
    display: ${props => (props.hidden ? 'none' : 'block')};
  `}
`;
Unread.displayName = 'Unread';

export const BellButton = ({ onClick, unread, label }) => (
  <NavBarButton icon="bell" onClick={onClick} label={label}>
    <Unread hidden={unread < 1}>{unread}</Unread>
  </NavBarButton>
);

BellButton.displayName = 'BellButton';

BellButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  unread: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};
