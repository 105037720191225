import styled from 'styled-components';

export const PermissionListItemChip = styled.span`
  display: inline-block;
  border-radius: 15px;
  padding: ${props => props.theme.spacing.xxs} ${props => (props.background ? '1em' : 0)};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  background-color: ${props => props.background};
  color: ${props => props.color || props.theme.colors.white};
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1;
`;
