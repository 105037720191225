import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setScrollPosition } from 'redux/modules/index.js';

class ScrollAware extends PureComponent {
  static propTypes = {
    scroll: PropTypes.object.isRequired,
    setScrollPosition: PropTypes.func.isRequired,
  };

  handleScroll = () => {
    const {
      scroll: { scrollTop },
      setScrollPosition,
    } = this.props;
    const newScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Don't update if just scrolling couple of pixels
    if (newScrollTop !== 0 && Math.abs(newScrollTop - scrollTop) <= 5) {
      return;
    }

    const scrollingUp = newScrollTop < scrollTop;
    setScrollPosition({ scrollTop: newScrollTop, scrollingUp });
  };

  throttledUpdate = _.throttle(this.handleScroll, 100);

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.throttledUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledUpdate);
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  scroll: state.common.scroll,
});

const mapDispatchToProps = dispatch => ({
  setScrollPosition: scroll => dispatch(setScrollPosition(scroll)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(ScrollAware);
