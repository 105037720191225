import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const dialogAnimation = keyframes`
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

/**
 * Dialog animatable UI body component
 */
const StyledDialogAnimate = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

/**
 * We use pre-centered element for animatable content
 * to prevent glitches with rendering and better performance
 */
const Animation = styled.div`
  animation: ${dialogAnimation} ${props => props.theme.motion.default} ${props => props.theme.motion.easing} forwards;
  transform: translateY(-1rem);
  opacity: 0;
`;

const DialogAnimate = props => (
  <StyledDialogAnimate data-test-id="DialogAnimate">
    <Animation>{props.children}</Animation>
  </StyledDialogAnimate>
);

DialogAnimate.propTypes = {
  children: PropTypes.node,
};

export default DialogAnimate;
