import React from 'react';
import styled from 'styled-components';

import ShadowCard from 'components/ShadowCard/ShadowCard';
import SkeletonText from 'components/Skeletons/SkeletonText';
import moment from 'moment-timezone';

const statusColors = props => ({
  open: props.theme.serviceOrder.openColor,
  released: props.theme.serviceOrder.releasedColor,
  completed: props.theme.serviceOrder.completedColor,
  arrived: props.theme.serviceOrder.arrivedColor,
  started: props.theme.serviceOrder.startedColor,
  partly_completed: props.theme.serviceOrder.partlyCompletedColor,
  in_progress: props.theme.serviceOrder.inProgressColor,
  postponed: props.theme.serviceOrder.postponedColor,
});

const Card = styled(ShadowCard)`
  padding: ${props => props.theme.spacing.md};
  padding-left: ${props => props.serviceOrder && props.theme.spacing.xl};
  margin-top: ${props => props.theme.spacing.xs};
  border-left: 5px solid ${props => statusColors(props)[props.status]};
  position: relative;
  overflow: visible;
`;

const Heading = styled.h4`
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.bold};
  font-family: ${props => props.theme.font.family.arial};
`;

const Text = styled.p`
  margin: ${props => props.theme.spacing.xxs} 0;
  font-size: ${props => props.theme.font.size.xs};
  font-family: ${props => props.theme.font.family.arial};
  color: ${props => props.theme.colors.black};
  line-height: ${props => props.theme.font.lineHeight.md};
`;

const Link = styled.span`
  color: ${props => props.theme.colors.darkGray};
`;

const ShowLink = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 5px;
  text-align: right;
`;

const Timeline = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing.xs};

  ${props => props.theme.media.portrait`
        flex-direction: row;
        align-items: flex-end;
    `}
`;

const Date = styled.li`
  flex: 1;
  margin-bottom: ${props => props.theme.spacing.xs};

  &:last-child {
    margin-bottom: 0;
  }

  ${props => props.theme.media.portrait`
        margin-bottom: 0;
    `}
`;

const DateContent = styled.p`
  font-size: ${props => props.theme.font.size.xs};
  font-family: ${props => props.theme.font.family.arial};
  color: ${props => props.theme.colors.black};
  margin-top: ${props => props.theme.spacing.xxs};
`;

const DateTitle = styled.span`
  color: ${props => (props.invalid ? props.theme.colors.radicalRed : props.theme.colors.darkGray)};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  font-family: ${props => props.theme.font.arial};
  letter-spacing: 0.3px;
  padding-left: 1px;
`;

const DateCircleContainer = styled.div`
  position: absolute;
  width: 3em;
  height: 3em;
  line-height: 3em;
  text-align: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 1.5em;
  left: -1.65em;
  top: 50%;
  transform: translateY(-50%);
`;

const DateCircle = styled.div`
  position: absolute;
  width: 4em;
  height: 4em;
  line-height: 1em;
  text-align: center;
  color: ${props => props.theme.colors.darkGray};
  background-color: ${props => props.theme.colors.white};
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 0.75em;
  font-weight: ${props => props.theme.fontWeight.bold};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3em;
  box-shadow: -6px 0 12px 2px rgba(51, 51, 51, 0.1);
  padding: 1em 0;
`;

const SkeletonCard = styled(Card)`
  display: flex;
  flex-direction: row;
  border: 1px solid ${props => props.theme.colors.mystic};
  border-left: 5px solid ${props => props.theme.colors.rockBlue};
  height: ${props => (props.operation ? '144px' : '118px')};
  background-color: transparent;
`;
const SkeletonRow = styled.div`
  display: flex;
  flexdirection: row;
  width: 100%;
  height: 60%;
`;
const SkeletonColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
  padding: 0.5em;
`;
const SkeletonSideColum = styled.div`
  display: flex;
  width: ${props => (props.operation ? '0%' : '25%')};
  height: 100%;
  padding: 0.5em;
`;
const SkeletonHeader = styled.div`
  padding: 0.5em;
  padding-right: 0.7em;
`;

const SkeletonMainColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.operation ? '100%' : '75%')};
  height: 100%;
`;

const timeline = ['released', 'arrivedAtSiteDate', 'startedDate', 'completedDate'];
const timelineDescription = ['Released', 'Arrived at Site', 'Started'];

const mapStatus = status => {
  switch (status) {
    case 'Technically Completed':
    case 'Confirmed':
      return 'Completed';
    default:
      return status;
  }
};

const renderOperationTimeline = (createdDate, operation, t) => {
  return timeline.map((property, index) => {
    // Special handling for released
    if (index === 0) {
      const date = moment.utc(createdDate || operation.earliestStart).local();
      return (
        <Date key={`operation-date-${property}`}>
          <DateTitle>{t(timelineDescription[index])}:</DateTitle>
          <DateContent>
            {date.format('LL')} <br /> {date.format('HH:mm')}
          </DateContent>
        </Date>
      );
    } else if (operation[property] && index < timeline.length - 1) {
      const date = moment.utc(operation[property]).local();
      return (
        <Date key={`operation-date-${property}`}>
          <DateTitle>{t(timelineDescription[index])}:</DateTitle>
          <DateContent>
            {date.format('LL')} <br /> {date.format('HH:mm')}
          </DateContent>
        </Date>
      );
      // Special handling for completed
    } else if (operation[property] && index === timeline.length - 1) {
      const date = moment.utc(operation[property]).local();
      return (
        <Date key={`operation-date-${property}`}>
          <DateTitle>{t(mapStatus(operation.status))}:</DateTitle>
          <DateContent>
            {date.format('LL')} <br /> {date.format('HH:mm')}
          </DateContent>
        </Date>
      );
    }
    return null;
  });
};

const renderServiceOrderTimeline = (createdDate, modifiedDate, serviceOrderLink, t) => {
  return (
    <Timeline>
      <Date>
        <DateTitle>{t('Created')}: </DateTitle>
        <DateContent>
          {moment
            .utc(createdDate)
            .local()
            .format('LL, HH:mm z')}
        </DateContent>
      </Date>
      {modifiedDate && (
        <Date>
          <DateTitle>{t('Last Update')}: </DateTitle>
          <DateContent>
            {moment
              .utc(modifiedDate)
              .local()
              .format('LL, HH:mm z')}
          </DateContent>
        </Date>
      )}
      <ShowLink data-test-id="ServiceOrderLink">{serviceOrderLink}</ShowLink>
    </Timeline>
  );
};

const renderDateCircle = created => {
  return (
    <DateCircleContainer>
      <DateCircle>
        <div>
          {moment
            .utc(created)
            .local()
            .format('Do')}
        </div>
        <div>
          {moment
            .utc(created)
            .local()
            .format('MMM')}
        </div>
      </DateCircle>
    </DateCircleContainer>
  );
};

const ServiceOrderCard = props => {
  const {
    status,
    text,
    title,
    createdDate,
    modifiedDate,
    operation,
    t,
    addressLink,
    serviceOrderLink,
    loading,
  } = props;

  if (loading) {
    return (
      <SkeletonCard data-test-id="SkeletonCard" isLoading status="loading" operation={operation}>
        <SkeletonMainColumn operation={operation}>
          <SkeletonHeader>
            {operation && <SkeletonText width="50%" style={{ marginLeft: 0 }} />}
            <SkeletonText />
          </SkeletonHeader>
          <SkeletonRow>
            <SkeletonColumn>
              <SkeletonText />
              <SkeletonText />
            </SkeletonColumn>
            <SkeletonColumn>
              <SkeletonText />
              <SkeletonText />
            </SkeletonColumn>
            <SkeletonColumn>
              <SkeletonText />
              <SkeletonText />
            </SkeletonColumn>
          </SkeletonRow>
        </SkeletonMainColumn>
        <SkeletonSideColum operation={operation}>
          <SkeletonText style={{ marginTop: 'auto', marginBottom: '0.05em' }} />
        </SkeletonSideColum>
      </SkeletonCard>
    );
  }

  return (
    <Card data-test-id="ServiceOrderCard" status={status} serviceOrder={!operation}>
      <Heading>{title}</Heading>
      {text && <Text>{text}</Text>}
      {addressLink && (
        <Text>
          <Link>{addressLink}</Link>
        </Text>
      )}
      {operation && <Timeline> {renderOperationTimeline(createdDate, operation, t)}</Timeline>}
      {serviceOrderLink && renderServiceOrderTimeline(createdDate, modifiedDate, serviceOrderLink, t)}
      {!operation && renderDateCircle(createdDate, status)}
    </Card>
  );
};

export default ServiceOrderCard;
