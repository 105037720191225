import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import range from 'lodash/range';
import styled from 'styled-components';

import translations from 'decorators/Translations/translations';
import { rgba } from 'polished';
import Svg from 'components/Svg/Svg';
import { Box, MonthSelector, Year, YearValue, Label, Value } from 'components/Calendar/CalendarMonthSelector';

const StyledMonthSelect = styled.div`
  margin-top: ${props => props.theme.spacing.md};
  flex-basis: 100%;
  ${Box} {
    box-shadow: none;
    border: 1px solid ${props => props.theme.colors.lightGray};
    :hover {
      border-color: ${props => props.theme.colors.cerulean};
      background-color: ${props => rgba(props.theme.colors.cerulean, 0.05)};
    }
  }
  ${Year} {
    cursor: auto;
  }
`;
const ArrowIcon = styled(({ tiny, fill, ...props }) => <Svg {...props} />)`
  font-size: ${props => props.theme.font.size.xxs};
`;

export const YearSelector = styled.div`
  padding: 0 ${props => props.theme.spacing.md};
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  height: 100%;
  display: flex;
  align-items: center;
  ${ArrowIcon} {
    fill: ${props => (props.disabled ? props.theme.colors.lightGray : props.theme.colors.darkGray)};
  }
`;

export const Month = styled(Box)`
  border-bottom-color: ${props => (props.active ? `${props.theme.colors.cerulean} !important` : '')};
  border-bottom-width: ${props => (props.active ? '2px !important' : '1px')};
`;

const calculateItemsWithin = ({ year, month, items = [] }) => {
  const endOf = isFinite(month) ? 'month' : 'year';
  return items.filter(item =>
    moment(item.timestamp).isBetween(moment([year, month ?? 0, 1]), moment([year, month ?? 0]).endOf(endOf))
  ).length;
};

export const MonthSelect = ({ t, items = [], onMonthClick, active = {}, toggleActiveMonth = true }) => {
  const now = Date.now();
  const currentYear = new Date(now).getFullYear();
  const [year, setYear] = useState(active.year || currentYear);
  const [activeMonth, setActiveMonth] = useState(active.month);
  const monthNames = moment.localeData().monthsShort();
  const lastMonth = year === currentYear ? new Date(now).getMonth() + 1 : 12;
  const disableNextYear = year >= currentYear;
  const handleMonthClick = ({ year, month }) => {
    setActiveMonth(month);
    onMonthClick && onMonthClick({ year, month });
  };
  return (
    <StyledMonthSelect>
      <MonthSelector>
        <Year>
          <YearSelector onClick={() => setYear(year - 1)}>
            <ArrowIcon name="ion-arrow-left" />
          </YearSelector>
          <YearValue>
            <Value>{calculateItemsWithin({ items, year })}</Value>
            <Label>{`${t('Year')} ${year}`}</Label>
          </YearValue>
          <YearSelector onClick={!disableNextYear ? () => setYear(year + 1) : null} disabled={disableNextYear}>
            <ArrowIcon name="ion-arrow-right" />
          </YearSelector>
        </Year>
        {range(0, lastMonth).map(month => (
          <Month
            key={month}
            onClick={() => handleMonthClick({ year, month })}
            active={toggleActiveMonth && activeMonth === month}
          >
            <Value>{calculateItemsWithin({ items, year, month })}</Value>
            <Label>{monthNames[month]}</Label>
          </Month>
        ))}
      </MonthSelector>
    </StyledMonthSelect>
  );
};

MonthSelect.proptTypes = {
  t: PropTypes.func.isRequired,
  onMonthClick: PropTypes.func,
  items: PropTypes.array,
  active: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }),
  toggleActiveMonth: PropTypes.bool,
};

export default translations(MonthSelect);
