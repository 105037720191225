import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadFunctionalLocationImages } from 'redux/modules/customer/partnerImage';
import ImageUpload from 'containers/Application/ImageUpload/ImageUpload';
import { MODALTYPE } from 'components/Modal/ModalTypes';

const BuildingImage = ({ uploading, loadFunctionalLocationImages, functionalLocation, functionalLocationImages }) => {
  const onUploadSuccess = () => loadFunctionalLocationImages(functionalLocation);

  const onRemoveSuccess = () => loadFunctionalLocationImages(functionalLocation);

  return (
    <ImageUpload
      functionalLocation={functionalLocation}
      imageType="building"
      images={functionalLocationImages[functionalLocation]}
      onUploadSuccess={onUploadSuccess}
      onRemoveSuccess={onRemoveSuccess}
      uploading={uploading}
      removeModalType={MODALTYPE.CONFIRMATION_DELETE_BUILDING_IMAGE_MODAL}
    />
  );
};

BuildingImage.defaultProps = {
  uploading: false,
  functionalLocationImages: {},
};

BuildingImage.propTypes = {
  functionalLocation: PropTypes.string.isRequired,
  loadFunctionalLocationImages: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
  functionalLocationImages: PropTypes.object,
};

const mapStateToProps = state => ({
  functionalLocationImages: state.partnerImage.byFL,
});

const mapDispatchToProps = dispatch => ({
  loadFunctionalLocationImages: functionalLocation => dispatch(loadFunctionalLocationImages(functionalLocation, true)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(BuildingImage);
