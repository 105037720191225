import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { setMenuStatus } from 'redux/modules/index.js';
import { MenuToggle } from 'components/index.js';
import Svg from 'components/Svg/Svg';
import GeneralAnnouncements from 'components/Announcement/GeneralAnnouncements';
import SideNavigation from 'components/SideNavigation/SideNavigation';
import Context from './Context';
import { getAnnouncements } from 'components/Announcement/utils';
import { myCustomerCustomViews } from 'redux/modules/customView/customView';
import Feedback from 'components/Feedback/Feedback';
import NavBarButton from 'components/NavBarButton/NavBarButton';
import translations from 'decorators/Translations/translations';
import { constructRelativeUrl } from 'utils/url';
import UnreadIndicator from 'components/Announcement/UnreadIndicator';

const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.theme.navigation.height};
  z-index: ${props => props.theme.zIndex('header')};

  ${props => props.theme.media.landscape`
    position: fixed;
  `};
`;

const StyledHeader = styled.header`
  position: relative;
  display: flex;
  height: ${props => props.theme.navigation.height};
  width: 100%;
  pointer-events: none;
  background-color: ${props => props.theme.colors.midnight};
  z-index: ${props => props.theme.zIndex('header')};
`;

const CenterFillContainer = styled.div`
  flex-grow: 2;
  flex-shrink: 2;
  min-width: 0;
`;

const linkStyles = props => css`
  pointer-events: auto;
  z-index: 10;
  padding: 0 ${props.theme.spacing.md};
  display: none;
  align-items: center;

  ${props => props.theme.media.landscape`
        display: flex;
    `}
`;

const StyledLink = styled(Link)`
  ${props => linkStyles(props)}
`;
StyledLink.displayName = 'StyledLink';

const StyledALink = styled.a`
  ${props => linkStyles(props)}
`;
StyledALink.displayName = 'StyledALink';

const AnnouncementUnreadContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  ${props => props.theme.media.portrait`
        display: none;
    `}
`;

AnnouncementUnreadContainer.displayName = 'AnnouncementUnreadContainer';

const CaverionIcon = styled(Svg)`
  fill: ${props => props.theme.colors.white};
`;

CaverionIcon.displayName = 'CaverionIcon';

export class Header extends PureComponent {
  componentDidMount() {
    const {
      match: {
        params: { partnerNumber },
      },
      selected,
    } = this.props;

    if (selected && partnerNumber && partnerNumber !== 'all') {
      this.props.myCustomerCustomViews(partnerNumber);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { partnerNumber },
      },
      selected,
    } = this.props;

    if (
      selected &&
      partnerNumber &&
      partnerNumber !== prevProps.match.params.partnerNumber &&
      partnerNumber !== 'all'
    ) {
      this.props.myCustomerCustomViews(partnerNumber);
    }
  }

  handleServiceRequestClick = () => {
    const { match, history } = this.props;
    const url = constructRelativeUrl(match.params, 'ServiceRequest');
    history.push(url);
  };

  render() {
    const {
      toggleMenu,
      menuOpen,
      match,
      location,
      context,
      t,
      links,
      linksMobile,
      selected,
      showPartnerSelect,
      profile,
      generalAnnouncements,
      personalAnnouncements,
      activePartner,
      useALinkTag,
      loading,
      customViewsByCustomer,
    } = this.props;

    const hasContextualNavi = !!(links || selected);
    const shouldShowContext = !!context || showPartnerSelect;
    const { unread } = getAnnouncements(profile, generalAnnouncements, personalAnnouncements, activePartner);
    const linkTitle = 'SmartView v' + process.env.VERSION;

    return (
      <Fragment>
        <HeaderContainer data-test-id="Header">
          <StyledHeader>
            {useALinkTag ? (
              <StyledALink data-test-id="HeaderLink" href="/" title={linkTitle}>
                <CaverionIcon name="caverion_logo" width={140} height={38} alt="Caverion Logo" />
              </StyledALink>
            ) : (
              <StyledLink data-test-id="HeaderLink" to="/" title={linkTitle} onClick={this.toggleMenu}>
                <CaverionIcon name="caverion_logo" width={140} height={38} alt="Caverion Logo" />
              </StyledLink>
            )}
            <CenterFillContainer>
              {shouldShowContext && (
                <Context match={match} t={t} {...context} location={location} showPartnerSelect={showPartnerSelect} />
              )}
            </CenterFillContainer>
            <NavBarButton
              icon="new-service-request"
              label={t('New service request')}
              onClick={this.handleServiceRequestClick}
            />
            <Feedback />
            <GeneralAnnouncements />
            <MenuToggle toggleMenu={toggleMenu} menuOpen={menuOpen} />
            <AnnouncementUnreadContainer>
              <UnreadIndicator hidden={unread.length < 1}>{unread.length}</UnreadIndicator>
            </AnnouncementUnreadContainer>
          </StyledHeader>
        </HeaderContainer>
        {hasContextualNavi && (
          <SideNavigation
            t={t}
            links={links}
            linksMobile={linksMobile}
            selected={selected}
            loading={(context && context.loading) || loading}
            customViews={customViewsByCustomer[match.params.partnerNumber]}
          />
        )}
      </Fragment>
    );
  }
}

Header.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  menuOpen: state.navigation.menuOpen,
  generalAnnouncements: state.announcement.general,
  personalAnnouncements: state.announcement.personal,
  activePartner: state.profile.activePartner,
  profile: state.profile.profile,
  customViewsByCustomer: state.customView.customViewsByCustomer,
});
const mapDispatchToProps = dispatch => ({
  toggleMenu: status => dispatch(setMenuStatus(status)),
  myCustomerCustomViews: partnerNumber => dispatch(myCustomerCustomViews(partnerNumber)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(translations(Header))
);
