import _ from 'lodash';
import moment from 'moment';
import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT, MasterData } from '@caverion/redux/api/actions';

export const initialState = {
  sensorValues: [],
  valuesBySensorId: {},
  modalValuesBySensorId: {},
  latestValuesBySensorId: {},
  modalLatestValuesBySensorId: {},
  sensorsByEquipmentNumber: {},
  loadingSensorValues: false,
  loadingLatestSensorValues: false,
};

export const LOAD_SENSOR_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSOR_VALUES';
export const LOAD_SENSOR_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSOR_VALUES_SUCCESS';
export const LOAD_SENSOR_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSOR_VALUES_FAIL';

export const loadSensorValues = (sensorIds, startTime, endTime, aggregation) => {
  const filter = {
    where: {
      sensorId: { inq: sensorIds },
      timestamp: { between: [startTime, endTime] },
    },
    order: 'timestamp ASC',
    fields: ['sensorId', 'aggregation', 'timestamp', 'value'],
  };
  if (aggregation) {
    filter.where.aggregation = aggregation;
  }

  return async dispatch => {
    dispatch({ type: LOAD_SENSOR_VALUES });
    try {
      const result = await dispatch(IoT.findWithPost(filter));

      return dispatch({
        type: LOAD_SENSOR_VALUES_SUCCESS,
        result,
        sensorIds,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_SENSOR_VALUES_FAIL,
        error,
        sensorIds,
      });
    }
  };
};

export const LOAD_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSORS_VALUES';
export const LOAD_SENSORS_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSORS_VALUES_SUCCESS';
export const LOAD_SENSORS_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSORS_VALUES_FAIL';

export const loadSensorsValues = (sensorIds, startTime, endTime, aggregation, isSensorModal = false) => {
  const filter = {
    where: {
      timestamp: { between: [startTime, endTime] },
      sensorId: { inq: sensorIds },
    },
    order: 'timestamp ASC',
    fields: ['sensorId', 'aggregation', 'timestamp', 'value'],
  };
  if (aggregation !== undefined) {
    filter.where.aggregation = aggregation;
  }

  return async dispatch => {
    dispatch({ type: LOAD_SENSORS_VALUES });
    try {
      const result = await dispatch(IoT.findWithPost(filter));

      return dispatch({
        type: LOAD_SENSORS_VALUES_SUCCESS,
        result,
        sensorIds,
        isSensorModal,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_SENSORS_VALUES_FAIL,
        error,
        sensorIds,
        isSensorModal,
      });
    }
  };
};

export const LOAD_LATEST_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_SENSORS_VALUES';
export const LOAD_LATEST_SENSORS_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_SENSORS_VALUES_SUCCESS';
export const LOAD_LATEST_SENSORS_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_SENSORS_VALUES_FAIL';

export const loadLatestSensorsValues = (sensorIds, aggregation = 'raw', isSensorModal = false) => async dispatch => {
  dispatch({ type: LOAD_LATEST_SENSORS_VALUES });
  if (_.isEmpty(sensorIds)) {
    return dispatch({
      type: LOAD_LATEST_SENSORS_VALUES_FAIL,
      isSensorModal,
    });
  }
  try {
    const result = await dispatch(IoT.sensorsLatestValues(sensorIds, aggregation));

    return dispatch({
      type: LOAD_LATEST_SENSORS_VALUES_SUCCESS,
      result,
      isSensorModal,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_LATEST_SENSORS_VALUES_FAIL,
      error,
      isSensorModal,
    });
  }
};

export const LOAD_EQUIPMENT_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_EQUIPMENT_SENSORS_VALUES';
export const LOAD_EQUIPMENT_SENSORS_VALUES_SUCCESS =
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_EQUIPMENT_SENSORS_VALUES_SUCCESS';
export const LOAD_EQUIPMENT_SENSORS_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_EQUIPMENT_SENSORS_VALUES_FAIL';

export const loadEquipmentSensorsValues = (equipmentNumber, types, startTime, endTime, aggregation) => {
  const sensorFilter = {
    where: {
      equipmentNumber,
    },
    fields: ['id'],
    include: {
      relation: 'children',
      scope: {
        fields: ['id'],
      },
    },
  };

  const filter = {
    where: {
      type: { inq: types },
      timestamp: { between: [startTime, endTime] },
    },
    order: 'timestamp ASC',
    fields: ['sensorId', 'aggregation', 'timestamp', 'value'],
  };
  if (aggregation) {
    filter.where.aggregation = aggregation;
  }

  return async dispatch => {
    dispatch({ type: LOAD_EQUIPMENT_SENSORS_VALUES });
    try {
      const equipmentSensors = await dispatch(MasterData.sensors(JSON.stringify(sensorFilter)));
      filter.where.sensorId = { inq: _.flatMap(equipmentSensors[0].children, 'id') };
      const result = await dispatch(IoT.findWithPost(filter));

      return dispatch({
        type: LOAD_EQUIPMENT_SENSORS_VALUES_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_EQUIPMENT_SENSORS_VALUES_FAIL,
        error,
      });
    }
  };
};

export const LOAD_LATEST_EQUIPMENT_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_EQUIPMENT_SENSORS_VALUES';
export const LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS =
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS';
export const LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL =
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL';

export const loadLatestEquipmentSensorsValues = (functionalLocation, equipmentNumber) => {
  const sensorFilter = {
    where: {
      functionalLocation,
      equipmentNumber,
    },
    include: [
      {
        children: [
          {
            relation: 'sensorMeta',
          },
          {
            relation: 'sensorType',
            scope: {
              include: [
                {
                  relation: 'aggregations',
                  scope: {
                    where: {
                      active: true,
                    },
                    fields: ['aggregation', 'frequency'],
                  },
                },
              ],
            },
          },
        ],
      },
    ],
  };

  return async dispatch => {
    dispatch({ type: LOAD_LATEST_EQUIPMENT_SENSORS_VALUES });
    try {
      const equipmentSensors = await dispatch(MasterData.sensors(sensorFilter));
      const sensorIds = _.flatMap(equipmentSensors[0].children, 'id');
      const result = await dispatch(IoT.sensorsLatestValues(sensorIds));

      return dispatch({
        type: LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS,
        equipmentNumber,
        equipmentSensors: equipmentSensors[0].children,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_SENSOR_VALUES]: (state, action) => ({
      ...state,
    }),
    [LOAD_SENSOR_VALUES_SUCCESS]: (state, action) => {
      let newValues = action.sensorIds.reduce((accu, id) => {
        accu[id] = [];
        return accu;
      }, {});

      if (action.result && action.result.length > 0) {
        const result = _.forOwn(_.groupBy(action.result, value => value.sensorId), sensorValues =>
          sensorValues.sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf())
        );
        newValues = { ...newValues, ...result };
      }

      return {
        ...state,
        sensorValues: [].concat(state.sensorValues, action.result),
        valuesBySensorId: Object.assign({}, state.valuesBySensorId, newValues),
      };
    },
    [LOAD_SENSOR_VALUES_FAIL]: (state, action) => {
      const sensorIds = _.isArray(action.sensorIds)
        ? action.sensorIds.reduce((accu, id) => {
            accu[id] = [];
            return accu;
          }, {})
        : {};

      return {
        ...state,
        valuesBySensorId: {
          ...state.valuesBySensorId,
          ...sensorIds,
        },
        error: action.error,
      };
    },
    [LOAD_SENSORS_VALUES]: (state, action) => ({
      ...state,
      loadingSensorValues: true,
    }),
    [LOAD_SENSORS_VALUES_SUCCESS]: (state, action) => {
      let newValues = action.sensorIds.reduce((accu, id) => {
        accu[id] = [];
        return accu;
      }, {});

      if (action.result && action.result.length > 0) {
        const result = _.forOwn(_.groupBy(action.result, value => value.sensorId), sensorValues =>
          sensorValues.sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf())
        );
        newValues = { ...newValues, ...result };
      }

      if (action.isSensorModal) {
        return {
          ...state,
          modalValuesBySensorId: Object.assign({}, state.modalValuesBySensorId, newValues),
          loadingSensorValues: false,
        };
      }

      return {
        ...state,
        sensorValues: [].concat(state.sensorValues, action.result),
        valuesBySensorId: Object.assign({}, state.valuesBySensorId, newValues),
        loadingSensorValues: false,
      };
    },
    [LOAD_SENSORS_VALUES_FAIL]: (state, action) => ({
      ...state,
      loadingSensorValues: false,
    }),
    [LOAD_LATEST_SENSORS_VALUES]: (state, action) => ({
      ...state,
      loadingLatestSensorValues: true,
    }),
    [LOAD_LATEST_SENSORS_VALUES_SUCCESS]: (state, action) => {
      const newValues = _.keyBy(action.result, value => value.sensorId);
      if (action.isSensorModal) {
        return {
          ...state,
          modalLatestValuesBySensorId: { ...state.modalLatestValuesBySensorId, ...newValues },
          loadingLatestSensorValues: false,
        };
      }
      return {
        ...state,
        sensorValues: [].concat(state.sensorValues, action.result),
        latestValuesBySensorId: { ...state.latestValuesBySensorId, ...newValues },
        loadingLatestSensorValues: false,
      };
    },
    [LOAD_LATEST_SENSORS_VALUES_FAIL]: (state, action) => ({
      ...state,
      loadingLatestSensorValues: false,
    }),
    [LOAD_EQUIPMENT_SENSORS_VALUES]: (state, action) => ({
      ...state,
    }),
    [LOAD_EQUIPMENT_SENSORS_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      sensorValues: [].concat(state.sensorValues, action.result),
      valuesBySensorId: _.groupBy(action.result, value => value.sensorId),
    }),
    [LOAD_EQUIPMENT_SENSORS_VALUES_FAIL]: (state, action) => ({
      ...state,
    }),
    [LOAD_LATEST_EQUIPMENT_SENSORS_VALUES]: (state, action) => ({
      ...state,
    }),
    [LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      sensorValues: [].concat(state.sensorValues, action.result),
      sensorsByEquipmentNumber: {
        ...state.sensorsByEquipmentNumber,
        [action.equipmentNumber]: action.equipmentSensors,
      },
      latestValuesBySensorId: _.keyBy(action.result, value => value.sensorId),
    }),
    [LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL]: (state, action) => ({
      ...state,
    }),
  },
  initialState
);
