import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import translations from 'decorators/Translations/translations';
import styled, { withTheme } from 'styled-components';
import capitalize from 'lodash/capitalize';

const StyledWasteAmountsTimeframeSelector = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxs};
  padding: 0.5em;

  .link {
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.font.weight.bold};
    cursor: pointer;
    text-transform: uppercase;
  }
  .label {
    text-transform: capitalize;
  }
`;

export const WasteTimeframeSelector = ({
  t,
  theme,
  selectedTimeframeCategory,
  selectedTimeframe,
  onTimeframeChange,
  selectedTimeframeDescription,
}) => {
  const onPreviousClick = () => {
    const newTimeframe = {
      startTime: moment(selectedTimeframe.startTime).utc().subtract(1, selectedTimeframeCategory),
      endTime: moment(selectedTimeframe.endTime).utc().subtract(1, selectedTimeframeCategory),
    };
    onTimeframeChange(newTimeframe);
  };

  const onNextClick = () => {
    const newTimeframe = {
      startTime: moment(selectedTimeframe.startTime).utc().add(1, selectedTimeframeCategory),
      endTime: moment(selectedTimeframe.endTime).utc().add(1, selectedTimeframeCategory),
    };
    onTimeframeChange(newTimeframe);
  };
  const isMobile = window.innerWidth < theme.breakpoints.landscape;
  const translatedSelectedCategory = t(capitalize(selectedTimeframeCategory));
  return (
    <StyledWasteAmountsTimeframeSelector theme={theme}>
      <div className="link" onClick={onPreviousClick}>
        &larr; {isMobile ? t('Previous') : `${t(`Previous`)} ${translatedSelectedCategory}`}
      </div>
      <div className="label">{t(selectedTimeframeDescription)}</div>
      <div className="link" onClick={onNextClick}>
        {isMobile ? t('Next') : `${t(`Next`)} ${t(translatedSelectedCategory)}`} &rarr;
      </div>
    </StyledWasteAmountsTimeframeSelector>
  );
};

WasteTimeframeSelector.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  selectedTimeframeCategory: PropTypes.string.isRequired,
  selectedTimeframe: PropTypes.object.isRequired,
  onTimeframeChange: PropTypes.func.isRequired,
  selectedTimeframeDescription: PropTypes.string.isRequired,
};

export default withTheme(translations(WasteTimeframeSelector));
