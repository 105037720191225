import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { uniqueId } from 'lodash';

import Button from 'components/Button/Button';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputText from 'components/Form/InputText';
import InputTextArea from 'components/Form/InputTextArea';
import InputForm from 'components/Form/InputForm';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';

const ModalContent = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 2em 0 0 0;
  width: 1000px;
  max-width: ${props => props.maxWidth}px;
  max-height: ${props => props.maxHeight}px;
  overflow-y: scroll;
`;
ModalContent.displayName = 'ModalContent';

const StyledHeader = styled.header`
  ${props =>
    props.showDropdown
      ? `
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1em 2em 0 2em;
    `
      : `margin: 1em auto 0 2em;`}
`;
StyledHeader.displayName = 'StyledHeader';

const StyledDropdown = styled.div`
  width: 50%;
`;
StyledDropdown.displayName = 'StyledDropdown';

const StyledFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;
StyledFormContainer.displayName = 'StyledFormContainer';

const FormColumn = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 2em;
  &:last-child {
    padding-left: 0;
  }
  textarea {
    height: 14em;
  }
`;
FormColumn.displayName = 'FormColumn';

const FormFooter = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 6em;
  background: ${props => props.theme.colors.alabaster};
  padding: 2em;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
`;
FormFooter.displayName = 'FormFooter';

const StyledInputRow = styled(InputRow)`
  width: 100%;
  &&& {
    margin-right: 0;
    margin-left: 0;
  }
`;
StyledInputRow.displayName = 'StyledInputRow';

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  width: 100%;
`;
StyledButtonGroup.displayName = 'StyledButtonGroup';

const getHeaderText = (t, type, isNew) => {
  return 'Tag form';
};

const TagForm = props => {
  const { t, tag, addNew, onSubmit, onClose, onChange, loading, errorMessage, type } = props;

  return (
    <ModalContent maxWidth={window.innerWidth} maxHeight={window.innerHeight}>
      <StyledHeader>
        <h3>{getHeaderText(t, type, addNew)}</h3>
      </StyledHeader>
      <InputForm id={uniqueId('tag-form-')} model={tag} onPropertyChange={onChange} onSubmit={onSubmit}>
        <StyledFormContainer>
          <FormColumn>
            <StyledInputRow required>
              <InputLabel text={t('Key')} />
              <InputText id="key" data-test-id="tagKey" type="text" property="key" placeholder={tag.key} />
            </StyledInputRow>
            <StyledInputRow>
              <InputLabel text={t('Default value')} />
              <InputText
                id="defaultValue"
                data-test-id="TagDefaultValueInput"
                type="text"
                property="defaultValue"
                placeholder={tag.defaultValue}
              />
            </StyledInputRow>
            <StyledInputRow>
              <InputLabel text={t('Description')} />
              <InputTextArea
                id="description"
                data-test-id="TagDescriptionInput"
                type="text"
                property="description"
                placeholder={tag.description}
              />
            </StyledInputRow>
          </FormColumn>
          <FormColumn>
            <InputLabel text={t('Tag type')} />
            <RadioButtonGroup name="type" value={tag.type} onChange={onChange}>
              <RadioButton label={t('Boolean')} value="boolean" id="boolean" />
              <RadioButton label={t('Value')} value="value" id="value" />
              <RadioButton label={t('Other')} value="other" id="other" />
            </RadioButtonGroup>
          </FormColumn>
        </StyledFormContainer>
        {errorMessage}
        <FormFooter>
          <StyledButtonGroup>
            <Button cancel onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button submit type="submit" data-test-id="TagSubmitButton" loading={loading}>
              {!tag.id ? t('Add') : t('Save')}
            </Button>
          </StyledButtonGroup>
        </FormFooter>
      </InputForm>
    </ModalContent>
  );
};

TagForm.propTypes = {
  // required:
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  // optional:
  addNew: PropTypes.bool,
  errorMessage: PropTypes.element,
  loading: PropTypes.bool,
  type: PropTypes.string,
  tag: PropTypes.object,
};

TagForm.defaultProps = {
  tag: {},
  loading: false,
  errorMessage: <div />,
  type: undefined,
  addNew: false,
};

export default withTheme(TagForm);
