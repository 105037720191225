import moment from 'moment';
import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import startsWith from 'lodash/startsWith';

const recyclableSensorTypes = [
  'waste/biowaste',
  'waste/cardboard',
  'waste/magazine_paper',
  'waste/glass',
  'waste/metal',
  'waste/heavy_metal_batteries',
  'waste/electronics',
  'waste/office_paper',
];
const recoverableSensorTypes = [
  ...recyclableSensorTypes,
  'waste/burned_mixed_waste',
  'waste/mixed_waste',
  'waste/energy_waste',
];
export const carbonFootprintSensorTypes = ['waste/burned_mixed_waste', 'waste/energy_waste'];

const getSensorIds = (wasteSensors, sensorTypes) =>
  (wasteSensors ?? [])
    .filter(wasteSensor => includes(sensorTypes, wasteSensor.sensorType.name))
    .map(wasteSensor => String(wasteSensor.id));

/**
 * Calculates total tonnes from given IoT data for all the given sensor ids.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {number[]} sensorIds - sensor ids
 */
const calculateTotalTonnes = (ioTData, sensorIds) =>
  (ioTData ?? [])
    .filter(ioTEntry => includes(sensorIds, ioTEntry.sensorId))
    .map(ioTEntry => ioTEntry.value)
    .reduce((prev, curr) => prev + curr, 0);

export const getWasteSensors = (buildingHierarchy, functionalLocationId) => {
  const allSensors = buildingHierarchy[functionalLocationId]?.[0]?.sensors;
  return (allSensors ?? []).filter(sensor => sensor.sensorType && startsWith(sensor.sensorType.name, 'waste/'));
};

/**
 * Calculates recycling and recovery rates, and carbon footprint from given IoT data by the given sensors.
 * @param  {Object[]} ioTData - fetched IoT data for past 60 days
 * @param  {Date} splitLimit - datetime to where to split the 60 day data for deltas
 * @param  {Object[]} wasteSensors - waste type sensors
 */
export const calculateRecyclingOPIValues = (ioTData, splitLimit, wasteSensors) => {
  // split 60 days into two 30 chunks
  const last30Days = ioTData.filter(entry => moment(entry.timestamp).isSameOrAfter(splitLimit));
  const rest = ioTData.filter(entry => moment(entry.timestamp).isBefore(splitLimit));
  // recycling
  const recyclableSensorIds = getSensorIds(wasteSensors, recyclableSensorTypes);
  const recyclingTonnes = calculateTotalTonnes(last30Days, recyclableSensorIds);
  const recyclingTonnesRest = calculateTotalTonnes(rest, recyclableSensorIds);
  // recovery
  const recoverableSensorIds = getSensorIds(wasteSensors, recoverableSensorTypes);
  const recoveryTonnes = calculateTotalTonnes(last30Days, recoverableSensorIds);
  const recoveryTonnesRest = calculateTotalTonnes(rest, recyclableSensorIds);
  // carbon footprint
  const carbonFootprintSensorIds = getSensorIds(wasteSensors, carbonFootprintSensorTypes);
  const carbonFootprintTonnes = calculateTotalTonnes(last30Days, carbonFootprintSensorIds) * 0.41;
  const carbonFootprintTonnesRest = calculateTotalTonnes(rest, recyclableSensorIds);
  // totals
  const last30DaysTotalTonnes = last30Days.map(entry => entry.value).reduce((prev, curr) => prev + curr, 0);
  const restTotalTonnes = rest.map(entry => entry.value).reduce((prev, curr) => prev + curr, 0);

  const recyclingRate = last30DaysTotalTonnes ? (recyclingTonnes / last30DaysTotalTonnes) * 100 : 0;
  const recyclingRateRest = restTotalTonnes ? (recyclingTonnesRest / restTotalTonnes) * 100 : 0;
  const recyclingRateDelta = recyclingRate - recyclingRateRest;
  const recoveryRate = last30DaysTotalTonnes ? (recoveryTonnes / last30DaysTotalTonnes) * 100 : 0;
  const recoveryRateRest = restTotalTonnes ? (recoveryTonnesRest / restTotalTonnes) * 100 : 0;
  const recoveryRateDelta = recoveryRate - recoveryRateRest;
  const carbonFootprint = carbonFootprintTonnes;
  const carbonFootprintRest = carbonFootprintTonnesRest;
  const carbonFootprintDelta = carbonFootprint - carbonFootprintRest;
  const precision = 0;
  return {
    recyclingRate: round(recyclingRate, precision),
    recyclingRateDelta: round(recyclingRateDelta, precision),
    recoveryRate: round(recoveryRate, precision),
    recoveryRateDelta: round(recoveryRateDelta, precision),
    carbonFootprint: round(carbonFootprint, precision),
    carbonFootprintDelta: round(carbonFootprintDelta, precision),
  };
};

/**
 * Calculates a total sum of IoT values for the given sensor type.
 * @param  {string} sensorType - sensor type to calculate the sum for
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {Object[]} wasteSensors - waste type sensors
 */
const calculateTotalValueForSensorType = (sensorType, ioTData, wasteSensors) => {
  const sensorIdsByType = wasteSensors
    .filter(sensor => sensor.sensorType?.name === sensorType)
    .map(sensor => String(sensor.id));
  return ioTData
    .filter(ioTEntry => includes(sensorIdsByType, ioTEntry.sensorId))
    .map(ioTEntry => ioTEntry.value)
    .reduce((prev, curr) => prev + curr, 0);
};

const resolveColorName = sensorType => {
  if (includes(recyclableSensorTypes, sensorType)) {
    return 'recyclableWaste';
  }
  return 'nonRecyclableWaste';
};

const createEmptySeries = (title, unit, categories) => ({
  categories,
  series: [],
  unit,
  totals: {
    title: title,
    total: 0,
    unit,
    perCategory: categories.map(category => ({
      categoryId: category.id,
      categoryName: category.name,
      colorName: category.colorName,
      total: 0,
    })),
  },
});

export const sensorTypeNameToWasteType = sensorType => capitalize(sensorType?.replace('waste/', '').replace(/_/g, ' '));

/**
 * Calculates a total sum per waste type from the given IoT data. Also total total and
 * totals for recyclable and non-recyclable types are calculated.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {Object[]} wasteSensors - waste type sensors to calculate the data for
 */
export const calculateRecyclingBreakdownPerWasteType = (ioTData, wasteSensors) => {
  const title = 'Waste amounts (all)';
  const unit = 'tonnes';
  const categories = [
    { id: 'recyclableWaste', name: 'Recyclable waste', colorName: 'recyclableWaste' },
    { id: 'nonRecyclableWaste', name: 'Non-recyclable waste', colorName: 'nonRecyclableWaste' },
  ];
  if (isEmpty(ioTData)) {
    return createEmptySeries(title, unit, categories);
  }
  let recyclableTotal = 0;
  let total = 0;
  const precision = 1;
  const rawSerie = wasteSensors.map(sensor => {
    const sensorType = sensor.sensorType?.name;
    const wasteType = sensorTypeNameToWasteType(sensorType);
    const totalValue = calculateTotalValueForSensorType(sensorType, ioTData, wasteSensors);
    if (includes(recyclableSensorTypes, sensorType)) {
      recyclableTotal += totalValue;
    }
    total += totalValue;
    return {
      sensorId: sensor.id,
      wasteType,
      colorName: resolveColorName(sensorType),
      totalValue: round(totalValue, precision),
      unit: sensor?.sensorType?.unit,
    };
  });
  const series = orderBy(rawSerie, 'wasteType', 'asc');
  return {
    categories,
    series,
    unit,
    totals: {
      title,
      total: round(total, precision),
      unit,
      perCategory: categories.map(category => ({
        categoryId: category.id,
        categoryName: category.name,
        colorName: category.colorName,
        total:
          category.id === 'recyclableWaste'
            ? round(recyclableTotal, precision)
            : round(total - recyclableTotal, precision),
      })),
    },
  };
};

/**
 * Calculates a total sum for carbon footprint per waste type from the given IoT data.
 * Also total total is calculated.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {Object[]} wasteSensors - waste type sensors to calculate the data for
 */
export const calculateCarbonFootprintPerWasteType = (ioTData, wasteSensors) => {
  const title = 'Carbon footprint (all)';
  const unit = 'tonnes';
  const categories = [{ id: 'carbonFootprint', name: 'Carbon footprint', colorName: 'carbonFootprint' }];
  if (isEmpty(ioTData)) {
    return createEmptySeries(title, unit, []);
  }
  let total = 0;
  const precision = 1;
  const rawSerie = wasteSensors
    .filter(sensor => includes(carbonFootprintSensorTypes, sensor.sensorType?.name))
    .map(sensor => {
      const sensorType = sensor.sensorType?.name;
      const wasteType = sensorTypeNameToWasteType(sensorType);
      const totalValue = calculateTotalValueForSensorType(sensorType, ioTData, wasteSensors);
      if (includes(carbonFootprintSensorTypes, sensorType)) {
        total += totalValue;
      }
      return {
        sensorId: sensor.id,
        wasteType,
        colorName: 'carbonFootprint',
        totalValue: round(totalValue, precision),
        unit: sensor?.sensorType?.unit,
      };
    });
  const series = orderBy(rawSerie, 'wasteType', 'asc');
  return {
    categories,
    series,
    unit,
    totals: {
      title,
      total: round(total, precision),
      unit,
      perCategory: [],
    },
  };
};

/**
 * Calculates yearly series for one waste type. Input is already filtered for only one sensor data.
 * @param  {Object[]} ioTData - fetched and filtered IoT data
 */
export const calculateYearlyDataForWasteType = ioTData => {
  const months = moment.months().map((month, index) => index);
  const lastYear = moment()
    .subtract(1, 'year')
    .utc();
  const lastYearData = months.map(
    month =>
      ioTData.find(entry => {
        const entryTimestamp = moment(entry.timestamp);
        return lastYear.year() === entryTimestamp.year() && entryTimestamp.month() === month;
      })?.value || 0
  );
  const thisYear = moment().utc();
  const thisYearData = months.map(
    month =>
      ioTData.find(entry => {
        const entryTimestamp = moment(entry.timestamp);
        return thisYear.year() === entryTimestamp.year() && entryTimestamp.month() === month;
      })?.value || 0
  );
  const past12MonthsLimit = moment()
    .subtract(12, 'months')
    .utc();
  const total = ioTData
    .filter(entry => moment(entry.timestamp).isAfter(past12MonthsLimit))
    .map(entry => entry.value)
    .reduce((prev, curr) => prev + curr, 0);
  return {
    series: [
      {
        name: lastYear.format('YYYY'),
        data: lastYearData,
        colorName: 'lastYear',
      },
      {
        name: thisYear.format('YYYY'),
        data: thisYearData,
        colorName: 'thisYear',
      },
    ],
    totals: {
      total,
      perCategory: [
        {
          categoryId: 'lastYear',
          categoryName: lastYear.format('YYYY'),
          colorName: 'lastYear',
          total: lastYearData.reduce((prev, curr) => prev + curr, 0),
        },
        {
          categoryId: 'thisYear',
          categoryName: thisYear.format('YYYY'),
          colorName: 'thisYear',
          total: thisYearData.reduce((prev, curr) => prev + curr, 0),
        },
      ],
    },
  };
};
