import React from 'react';
import PropTypes from 'prop-types';
import * as models from 'powerbi-models';
import { connect } from 'react-redux';

import PowerBIEmbed from 'components/PowerBI/PowerBIEmbed';
import Section from 'components/Section/Section';

import { loadReport } from 'redux/modules/powerbi/powerbi';
import { isEmpty } from 'lodash';
import { customViewShape } from 'components/CustomView/utils';

export class PowerBIReport extends React.Component {
  componentDidMount() {
    const { customView } = this.props;

    if (!isEmpty(customView)) {
      this.props.loadReport(customView.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { customView } = this.props;

    if (prevProps.customView !== customView) {
      if (!isEmpty(customView)) {
        this.props.loadReport(customView.id);
      }
    }
  }

  render() {
    const { powerbireport, loadingReport, loadingRender, noDataRender } = this.props;

    if (loadingReport) {
      return <div data-test-id="PowerBIReport-Loading">{loadingRender}</div>;
    }

    if (!loadingReport && isEmpty(powerbireport)) {
      return <div data-test-id="PowerBIReport-NoData">{noDataRender}</div>;
    }

    return (
      <Section>
        <PowerBIEmbed
          id={powerbireport.id}
          type="report"
          embedUrl={powerbireport.embedUrl}
          accessToken={powerbireport.accessToken}
          tokenType={models.TokenType.Embed}
          filterPaneEnabled={false}
          navContentPaneEnabled={false}
          background={models.BackgroundType.Transparent}
          permissions={models.Permissions.View}
        />
      </Section>
    );
  }
}

PowerBIReport.propTypes = {
  customView: PropTypes.oneOfType([customViewShape()]).isRequired,
  powerbireport: PropTypes.shape({
    id: PropTypes.string,
    embedUrl: PropTypes.string,
    accessToken: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  powerbireport: state.powerbi.powerbireport,
  loadingReport: state.powerbi.loading,
  profile: state.profile.profile,
});

const mapDispatchToProps = dispatch => ({
  loadReport: id => dispatch(loadReport(id)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(PowerBIReport);
