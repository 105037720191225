import React from 'react';
import PropTypes from 'prop-types';
import DateColumn from './../ColumnTypes/DateColumn';
import DescriptionColumn from './../ColumnTypes/DescriptionColumn';
import InfoColumn from './../ColumnTypes/InfoColumn';
import EventsRow from './../EventsRow/EventsRow';
import EventsCell from './../EventsRow/EventsCell';

const AlarmsRow = props => {
  const { rowData } = props;

  return (
    <EventsRow data-test-id={props['data-test-id']}>
      <EventsCell hideOnMobile>
        <DescriptionColumn data={{ ...rowData.alarm, ...rowData.event }} />
      </EventsCell>
      <EventsCell hideOnMobile>
        <DateColumn data={rowData.date} />
      </EventsCell>
      <EventsCell noPadding hideOnMobile>
        {rowData.message && <InfoColumn data={rowData.message} />}
      </EventsCell>
      <EventsCell grow hideOnDesktop>
        <DateColumn data={rowData.date} />
        <br />
        <DescriptionColumn data={rowData.alarm} />
      </EventsCell>
    </EventsRow>
  );
};

AlarmsRow.propTypes = {
  rowData: PropTypes.object,
  'data-test-id': PropTypes.string,
};

export default AlarmsRow;
