export const isAdminRole = role => {
  return ['caverion-admin', 'customer-admin'].indexOf(role) > -1;
};

export const isCaverionAdminRole = role => {
  return ['caverion-admin'].indexOf(role) > -1;
};

export const isCaverionAdminOrUserRole = role => {
  return ['caverion-admin', 'caverion-user'].indexOf(role) > -1;
};

export const isAdminOrCaverionUserRole = role => {
  return ['caverion-admin', 'customer-admin', 'caverion-user'].indexOf(role) > -1;
};

export const isCustomerAdminRole = role => {
  return ['customer-admin'].indexOf(role) > -1;
};

export const isServiceOrdersEnabled = features => {
  return !features || features.serviceOrders || features.serviceOrders === undefined;
};

export const isTechnicalTabEnabled = features => {
  return !features || features.technicalTab || features.technicalTab === undefined;
};

export const isEquipmentEnabled = features => {
  return !features || features.equipment || features.equipment === undefined;
};

export const isConditionsEnabled = features => {
  return !features || features.conditions || features.conditions === undefined;
};

export const isPartnerEnergyEnabled = features => {
  return !features || features.energyKpi || features.energyKpi === undefined;
};

export const isPartnerRecyclingEnabled = features => {
  return features && features.recycling;
};

export const isPortfolioDocumentsEnabled = features => {
  return features && features.portfolioDocuments;
};

export const canEditBuilding = role => {
  return isCaverionAdminRole(role);
};

export const canEditPartner = role => {
  return isCaverionAdminRole(role);
};

export const canEditGeneralAnnouncements = role => {
  return isCaverionAdminRole(role);
};
