import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { transparentize } from 'polished';
import DialogAnimate from './DialogAnimate.jsx';
import { toggleScroll } from 'utils/Modal/toggleScroll';

const DialogFadein = keyframes`
    from {
        opacity: 0.3;
    }
`;

const StyledDialog = styled.div`
  ${props =>
    props.active &&
    `
        position: fixed;
        z-index: ${props.theme.zIndex('modal')};
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    `}
`;
StyledDialog.displayName = 'StyledDialog';

export const DialogOverlay = styled.div`
  ${props =>
    props.active &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${props => transparentize(0.3, props.theme.colors.black)};
      animation: ${DialogFadein} 0.25s ease-out;
    `}
`;
DialogOverlay.displayName = 'DialogOverlay';

const DialogInanimate = styled.div`
  ${props =>
    props.isActive &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    `}
`;
DialogInanimate.displayName = 'DialogInanimate';

/**
 * Dialog (modal) UI component
 */
class Dialog extends Component {
  componentWillUnmount() {
    toggleScroll(false);
  }

  componentDidUpdate(prevProps) {
    const { isActive } = this.props;
    if (!prevProps.isActive && isActive) {
      toggleScroll(true);
    }
  }

  handleOverlayClick = event => {
    const { onOverlayClick } = this.props;

    if (typeof onOverlayClick === 'function') {
      event.stopPropagation();
      toggleScroll(false);
      onOverlayClick(event);
    }
  };

  render() {
    const { children, isActive, animate } = this.props;

    if (isActive) {
      toggleScroll(true);
    }

    return (
      <StyledDialog active={isActive} animate={animate}>
        <DialogOverlay onClick={this.handleOverlayClick} active={isActive} />
        {animate ? (
          <DialogAnimate isActive={isActive}>{children}</DialogAnimate>
        ) : (
          <DialogInanimate isActive={isActive}>{children}</DialogInanimate>
        )}
      </StyledDialog>
    );
  }
}

Dialog.propTypes = {
  isActive: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  animate: PropTypes.bool,
  children: PropTypes.node,
};

Dialog.defaultProps = {
  isActive: false,
  onOverlayClick: null,
  animate: false,
  children: null,
};

export default Dialog;
