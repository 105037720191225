import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import round from 'lodash/round';
import moment from 'moment';
import { ICON_TYPES, ICON_SIZES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';
import { flIcons } from 'utils/Data/functionalLocations';
import {
  getPerformanceLimit,
  getPerformanceStatus,
  performanceColors,
  technicalPerformanceOrder,
  getOpeningHours,
  getLimitFromMeta,
} from 'utils/Data/performance';
import { getDefaultSubsensor } from 'utils/Data/sensor';

// value is the sorting order in SortableTable
export const conditionStatus = {
  positive: { name: 'POSITIVE', value: 1 },
  neutral: { name: 'NEUTRAL', value: 2 },
  empty: { name: 'EMPTY', value: 3 },
  negative: { name: 'NEGATIVE', value: 4 },
  warning: { name: 'WARNING', value: 5 },
};

export const isSapEquipment = sensor =>
  sensor.sensorType &&
  sensor.sensorType.name === 'sap-equipment' &&
  sensor.equipmentNumber &&
  !isNaN(sensor.equipmentNumber);

export const CATEGORY_SORT = ['type', 'order'];
export const TYPE_SORT = ['label'];
export const SENSOR_SORT = [!isSapEquipment, 'name'];

export const conditionStatusToColor = theme => ({
  [conditionStatus.positive.name]: theme.colors.emerald,
  [conditionStatus.neutral.name]: theme.colors.rockBlue,
  [conditionStatus.negative.name]: theme.colors.radicalRed,
  [conditionStatus.warning.name]: theme.colors.orange,
  [conditionStatus.empty.name]: null,
});

const temperatureSensorTypes = ['indoor temperature', 'outdoor temperature', 'temperature'];

export const conditionIcon = (isSapEquipment, isMultiSensor, flType, theme, isAirQuality) => {
  if (isSapEquipment) {
    return {
      iconName: flIcons[flType],
      iconType: ICON_TYPES.DEFAULT,
      iconFill: theme.colors.white,
      iconSize: ICON_SIZES.SMALL,
    };
  } else if (isAirQuality && isMultiSensor) {
    return {
      iconName: icons.AIR_QUALITY,
      iconType: ICON_TYPES.PORTFOLIO,
      iconFill: theme.colors.white,
      iconSize: ICON_SIZES.SMALL_MINUS,
    };
  } else if (isMultiSensor) {
    return {
      iconName: icons.DEVICE,
      iconType: ICON_TYPES.EQUIPMENT,
      iconFill: theme.colors.midnight,
      iconSize: ICON_SIZES.SMALL,
    };
  }
  return {
    iconName: icons.SENSOR,
    iconType: ICON_TYPES.TRANSPARENT,
    iconFill: theme.colors.midnight,
    iconSize: ICON_SIZES.MEDIUM_MINUS,
  };
};

export const getColorFromThreshold = (sensor, parent, buildingMeta, value, alarm) => {
  const getLimits = getPerformanceLimit(sensor, parent, buildingMeta, alarm);
  const limits = getLimits && getLimits(new Date());
  if (!limits) {
    return conditionStatus.empty;
  }

  const [min, max] = limits;
  return value < min || value > max ? conditionStatus.negative : conditionStatus.positive;
};

export const distinctConditionValue = (graphType, latestValueObject, t) => {
  let distinctValue, distinctColorStatus, perfStatus;
  switch (graphType) {
    case 'air_quality':
    case 'technical_performance':
      perfStatus = getPerformanceStatus(latestValueObject.value, true);
      distinctColorStatus = perfStatus === 'ok' ? conditionStatus.positive : conditionStatus.negative;
      break;

    case 'presence':
      distinctValue = latestValueObject.value > 0 ? t('Occupied') : t('Available');
      distinctColorStatus = latestValueObject.value > 0 ? conditionStatus.negative : conditionStatus.positive;
      break;

    case 'cleaning':
      distinctValue = moment
        .utc(latestValueObject.timestamp)
        .local()
        .format('D.M.');
      break;

    case 's2':
      distinctValue = latestValueObject.value === 100 ? t('Yes') : t('No');
      distinctColorStatus = latestValueObject.value === 100 ? conditionStatus.positive : conditionStatus.negative;
      break;

    default:
      break;
  }

  return { distinctValue, distinctColorStatus };
};

export const getConditionValue = ({
  sensor,
  parent,
  latestValueObject,
  buildingMeta,
  theme,
  t,
  noDefaultSensor,
  isPerformance,
  alarm,
}) => {
  let value,
    status,
    colorStatus = {};
  const noValue = !latestValueObject || isNil(latestValueObject.value);

  if (noDefaultSensor) {
    value = '';
    status = conditionStatus.empty;
  } else if (noValue) {
    value = 'N/A';
    status = conditionStatus.negative;
    colorStatus = conditionStatus.empty;
  } else {
    value = round(latestValueObject.value, 0);
    status = conditionStatus.positive;

    if (sensor.sensorType) {
      const { distinctValue, distinctColorStatus } = distinctConditionValue(
        sensor.sensorType.graphType,
        latestValueObject,
        t
      );

      // Temperature sensors are shown with one decimal
      if (includes(temperatureSensorTypes, sensor.sensorType.name)) {
        value = round(latestValueObject.value, 1);
      }

      // some sensor values are presented differently
      value = distinctValue || value;
      colorStatus =
        distinctColorStatus || getColorFromThreshold(sensor, parent, buildingMeta, +value, alarm) || colorStatus;
    }

    const latestValueMoment = moment.utc(latestValueObject.timestamp);
    // If granularity is monthly set neutral if latest value is from beginning or previous month
    if (
      sensor.granularity === 'month' &&
      latestValueMoment.isBefore(
        moment
          .utc()
          .subtract(1, 'month')
          .startOf('month')
      )
    ) {
      status = conditionStatus.neutral;
    }
    // status drops to neutral if the latest value is older than one hour (or 4 hours if CCC sensor)
    if (sensor.granularity !== 'month' && latestValueMoment.isBefore(moment.utc().subtract(1, 'hour'))) {
      const isCCC = !!find(sensor.sensorMeta, { metaKey: 'CCCID' });
      if (!isCCC || latestValueMoment.isBefore(moment.utc().subtract(4, 'hour'))) {
        status = conditionStatus.neutral;
      }
    }
  }

  // value color (some types use colorStatus to define color apart from the data status)
  let color = conditionStatusToColor(theme)[colorStatus.name || status.name];
  if (isPerformance && !noValue) {
    color = performanceColors({ theme })[getPerformanceStatus(value)];
  }

  return { value, color, status };
};

const getSensorTypeName = (t, sensorType, hasChildren, isSapEquipment, isAirQuality) => {
  if (isAirQuality) {
    if (sensorType.name.startsWith('technical_performance')) {
      return sensorType.name === 'technical_performance'
        ? t(sensorType.name)
        : `${t('Technical performance')} (${t(sensorType.name)})`;
    }

    return `${t('Air Quality')} (${t(sensorType.name)})`;
  }

  if (sensorType.name) {
    return t(sensorType.name);
  }

  if (isSapEquipment) {
    return t('Equipment');
  }

  if (hasChildren) {
    return t('Multisensor');
  }

  return '';
};

export const conditionData = (
  parent,
  sensor,
  latestValues,
  buildingMeta,
  theme,
  t,
  openSensorModal,
  flType,
  link,
  alarmData,
  category
) => {
  if (!sensor || !latestValues || !theme || !t) {
    return {};
  }

  const hasChildren = sensor.children && sensor.children.length > 0;
  const isAirQuality =
    sensor &&
    sensor.sensorType &&
    sensor.sensorType.name &&
    (sensor.sensorType.name.startsWith('air_quality') || sensor.sensorType.name.startsWith('technical_performance'));
  const isPerformance = sensor && sensor.sensorType && sensor.sensorType.name === 'performance';

  const sensorForLatestValue = !hasChildren || isAirQuality ? sensor : undefined;
  const latestValueObject = sensorForLatestValue ? latestValues[sensorForLatestValue.id] : undefined;

  const sensorType = sensor.sensorType || {};
  const sapEquipment = isSapEquipment(sensor);
  const sensorTypeName = getSensorTypeName(t, sensorType, hasChildren, sapEquipment, isAirQuality);

  const conditionValue = getConditionValue({
    sensor: sensorForLatestValue,
    parent,
    latestValueObject,
    buildingMeta,
    theme,
    t,
    noDefaultSensor: !sensorForLatestValue,
    isPerformance,
    alarm: sensorForLatestValue && alarmData && alarmData.alarms[sensorForLatestValue.id],
  });
  let { value } = conditionValue;
  const { status, color } = conditionValue;
  let unit = isNaN(value) ? '' : sensorType.unit || (latestValueObject && latestValueObject.unit) || '';

  const capacity = getLimitFromMeta(sensor.sensorMeta, 'capacity');
  if (capacity && unit !== '%') {
    // Don't calculate capacity utilization if sensor values are already percentual
    unit = '%';
    value = Math.round((value / capacity) * 100);
  } else if (sensorType.name === 'humidity' && unit) {
    unit = '%RH';
  }

  const alarmsSupported = !hasChildren || isAirQuality;
  const sensorAlarm = alarmsSupported && alarmData?.alarms[sensor.id];
  const alarm =
    alarmData && alarmsSupported
      ? {
          active: !!sensorAlarm,
          onClick: () => alarmData.openAlarmModal(sensor),
          onDelete: sensorAlarm
            ? () => alarmData.deleteSensorAlarm(sensorAlarm.id, sensor.name ?? sensor.id)
            : undefined,
        }
      : undefined;

  if (alarm && alarm.active && latestValueObject && !isNil(latestValueObject.value)) {
    const value = latestValueObject.value;
    const min = sensorAlarm.minValue ?? Number.NEGATIVE_INFINITY;
    const max = sensorAlarm.maxValue ?? Number.POSITIVE_INFINITY;
    alarm.alert = value < min || value > max;
  }

  let openingHours;
  if (isAirQuality) {
    openingHours = getOpeningHours(category, buildingMeta);
  }

  const measuringPoint = getDefaultSubsensor(sensor);
  const children =
    hasChildren && isAirQuality
      ? sortBy(sensor.children, child => {
          const index = technicalPerformanceOrder.indexOf(get(child, 'sensorType.name'));
          return index !== -1 ? index : child.id;
        })
      : sensor.children;

  return {
    id: sensor.id,
    key: `${parent.id}-${sensor && sensor.id}`,
    name: {
      // Use empty string for indoor air quality to make it first
      value:
        sensorType.graphType === 'air_quality' || sensorType.graphType === 'technical_performance' ? '' : sensor.name,
      name: sensor.name,
      icon: conditionIcon(sapEquipment, hasChildren, flType, theme, isAirQuality),
    },
    status,
    type: sensorTypeName,
    value: {
      value,
      color,
      unit,
    },
    addon: {
      showArrow: sapEquipment,
      alarm,
    },
    parent,
    measuringPoint,
    category,
    title: isAirQuality ? parent.name : undefined,
    openingHours,
    link: sapEquipment && link,
    children:
      hasChildren &&
      children.map(child =>
        conditionData(
          sensor,
          child,
          latestValues,
          buildingMeta,
          theme,
          t,
          openSensorModal,
          flType,
          link,
          alarmData,
          category
        )
      ),
  };
};
