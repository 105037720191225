import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

const StyledValuesBoxComparisonValue = styled.div`
  padding: ${props => props.theme.spacing.xxs};
  flex: 1;
  text-align: center;
`;
StyledValuesBoxComparisonValue.displayName = 'StyledValuesBoxComparisonValue';

const Value = styled.div`
  font-size: ${props => props.theme.fontSize.sm};
  line-height: 1rem;
`;
Value.displayName = 'Value';

const Label = styled.div`
  font-size: ${props => props.theme.fontSize.xxs};
  color: ${props => props.theme.colors.darkGray};
  line-height: 1rem;
`;
Label.displayName = 'Label';

const ValuesBoxComparisonValue = ({ value, label, ...others }) => (
  <StyledValuesBoxComparisonValue {...others}>
    {value && <Value>{value}</Value>}
    {label && <Label>{label}</Label>}
  </StyledValuesBoxComparisonValue>
);

ValuesBoxComparisonValue.defaultProps = {
  value: '',
  label: '',
};

ValuesBoxComparisonValue.propTypes = {
  /** Value with unit */
  value: PropTypes.string,
  /** Value label */
  label: PropTypes.string,
};

export default ValuesBoxComparisonValue;
