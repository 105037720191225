import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';
import { assign, reject } from 'lodash';
import { isAdminRole } from 'utils/Data/profileData';

const initialState = {
  loading: false,
  customViews: [],
  customViewsByFl: {},
  customViewsByCustomer: {},
  customViewsByUser: {},
  error: false,
};

export const LOAD_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/LOAD_CUSTOM_VIEW';
export const LOAD_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_CUSTOM_VIEW_SUCCESS';
export const LOAD_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_CUSTOM_VIEW_FAIL';

export const loadCustomViews = () => {
  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    // TODO: Proper cache invalidation process
    const state = getState();
    const profile = state.profile && state.profile.profile;
    const timestamp = profile && isAdminRole(profile.role) ? Date.now() : undefined;

    dispatch({ type: LOAD_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterData.customViews(timestamp));

      return dispatch({
        type: LOAD_CUSTOM_VIEW_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_CUSTOM_VIEW_FAIL,
        error,
      });
    }
  };
};

export const LOAD_MY_CUSTOM_VIEWS = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOM_VIEWS';
export const LOAD_MY_CUSTOM_VIEWS_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOM_VIEWS_SUCCESS';
export const LOAD_MY_CUSTOM_VIEWS_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOM_VIEWS_FAIL';

export const loadMyCustomViews = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const profile = state.profile && state.profile.profile;

    dispatch({ type: LOAD_MY_CUSTOM_VIEWS });
    try {
      const result = await dispatch(MasterData.myCustomViews());

      return dispatch({
        type: LOAD_MY_CUSTOM_VIEWS_SUCCESS,
        userId: profile.id,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_MY_CUSTOM_VIEWS_FAIL,
        error,
      });
    }
  };
};

export const LOAD_USER_CUSTOM_VIEWS = 'CUSTOMER_PLATFORM/CustomView/LOAD_USER_CUSTOM_VIEWS';
export const LOAD_USER_CUSTOM_VIEWS_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_USER_CUSTOM_VIEWS_SUCCESS';
export const LOAD_USER_CUSTOM_VIEWS_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_USER_CUSTOM_VIEWS_FAIL';

export const loadUserCustomViews = userId => {
  return async dispatch => {
    dispatch({ type: LOAD_USER_CUSTOM_VIEWS });
    try {
      const result = await dispatch(MasterData.userCustomViews(userId));

      return dispatch({
        type: LOAD_USER_CUSTOM_VIEWS_SUCCESS,
        userId,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_USER_CUSTOM_VIEWS_FAIL,
        error,
      });
    }
  };
};

export const UPDATE_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/UPDATE_CUSTOM_VIEW';
export const UPDATE_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/UPDATE_CUSTOM_VIEW_SUCCESS';
export const UPDATE_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/UPDATE_CUSTOM_VIEW_FAIL';

export const updateCustomView = customView => {
  return async dispatch => {
    dispatch({ type: UPDATE_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterData.upsertCustomView(customView));
      return dispatch({
        type: UPDATE_CUSTOM_VIEW_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPDATE_CUSTOM_VIEW_FAIL,
        error,
      });
    }
  };
};

export const DELETE_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/DELETE_CUSTOM_VIEW';
export const DELETE_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/DELETE_CUSTOM_VIEW_SUCCESS';
export const DELETE_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/DELETE_CUSTOM_VIEW_FAIL';

export const deleteCustomView = id => {
  return async dispatch => {
    dispatch({ type: DELETE_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterData.deleteCustomView(id));
      return dispatch({
        type: DELETE_CUSTOM_VIEW_SUCCESS,
        result,
        customViewId: id,
      });
    } catch (error) {
      return dispatch({
        type: DELETE_CUSTOM_VIEW_FAIL,
        error,
      });
    }
  };
};

export const LOAD_MY_FL_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_FL_CUSTOM_VIEW';
export const LOAD_MY_FL_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_FL_CUSTOM_VIEW_SUCCESS';
export const LOAD_MY_FL_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_FL_CUSTOM_VIEW_FAIL';

export const myFLCustomViews = functionalLocation => {
  const filter = {
    where: {
      functionalLocation,
    },
  };

  return async dispatch => {
    dispatch({ type: LOAD_MY_FL_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterData.myFLCustomViews(JSON.stringify(filter)));
      return dispatch({
        type: LOAD_MY_FL_CUSTOM_VIEW_SUCCESS,
        result,
        functionalLocation,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_MY_FL_CUSTOM_VIEW_FAIL,
        error,
      });
    }
  };
};

export const LOAD_MY_CUSTOMER_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOMER_CUSTOM_VIEW';
export const LOAD_MY_CUSTOMER_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOMER_CUSTOM_VIEW_SUCCESS';
export const LOAD_MY_CUSTOMER_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOMER_CUSTOM_VIEW_FAIL';

export const myCustomerCustomViews = customerNumber => {
  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    // TODO: Proper cache invalidation process
    const state = getState();
    const profile = state.profile && state.profile.profile;
    const timestamp = profile && isAdminRole(profile.role) ? Date.now() : undefined;

    dispatch({ type: LOAD_MY_CUSTOMER_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterData.myCustomerCustomViews(customerNumber, timestamp));
      return dispatch({
        type: LOAD_MY_CUSTOMER_CUSTOM_VIEW_SUCCESS,
        result,
        customerNumber,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_MY_CUSTOMER_CUSTOM_VIEW_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_CUSTOM_VIEW]: (state, action) => ({ ...state, loading: true }),
    [LOAD_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_CUSTOM_VIEW_SUCCESS]: (state, action) => {
      return {
        ...state,
        customViews: action.result,
        loading: false,
      };
    },
    [LOAD_MY_CUSTOM_VIEWS_FAIL]: (state, action) => ({ ...state, error: action.error }),
    [LOAD_MY_CUSTOM_VIEWS_SUCCESS]: (state, action) => ({
      ...state,
      customViewsByUser: { ...state.customViewsByUser, [action.userId]: action.result },
    }),
    [LOAD_USER_CUSTOM_VIEWS_SUCCESS]: (state, action) => ({ ...state, error: action.error }),
    [LOAD_USER_CUSTOM_VIEWS_SUCCESS]: (state, action) => ({
      ...state,
      customViewsByUser: { ...state.customViewsByUser, [action.userId]: action.result },
    }),
    [UPDATE_CUSTOM_VIEW]: (state, action) => ({ ...state, loading: true }),
    [UPDATE_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [UPDATE_CUSTOM_VIEW_SUCCESS]: (state, action) => {
      const updatedCustomView = assign({}, action.result);
      const customViews = reject([].concat(state.customViews), { id: updatedCustomView.id });
      customViews.push(updatedCustomView);

      return {
        ...state,
        customViews,
        loading: false,
      };
    },
    [DELETE_CUSTOM_VIEW]: state => ({ ...state }),
    [DELETE_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, error: action.error }),
    [DELETE_CUSTOM_VIEW_SUCCESS]: (state, action) => {
      const { result, customViewId } = action;
      if (result && result.count === 1) {
        const customViews = reject(state.customViews, { id: customViewId });
        return {
          ...state,
          customViews,
        };
      }
      return {
        ...state,
      };
    },
    [LOAD_MY_FL_CUSTOM_VIEW]: (state, action) => ({ ...state, loading: true }),
    [LOAD_MY_FL_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_MY_FL_CUSTOM_VIEW_SUCCESS]: (state, action) => {
      return {
        ...state,
        customViewsByFl: {
          ...state.customViewsByFl,
          [action.functionalLocation]: action.result,
        },
        loading: false,
      };
    },
    [LOAD_MY_CUSTOMER_CUSTOM_VIEW]: (state, action) => ({ ...state, loading: true }),
    [LOAD_MY_CUSTOMER_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_MY_CUSTOMER_CUSTOM_VIEW_SUCCESS]: (state, action) => {
      return {
        ...state,
        customViewsByCustomer: {
          ...state.customViewsByCustomer,
          [action.customerNumber]: action.result,
        },
        loading: false,
      };
    },
  },
  initialState
);
