import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import WasteBreakdownSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteBreakdownSkeleton';
import BreakdownTab from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownTab/BreakdownTab';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import { loadBreakdownData } from 'redux/modules/iot/values/recycling';
import { connect } from 'react-redux';

export const tabs = {
  WASTE_AMOUNTS: 0,
  CARBON_FOORTPRINT: 1,
};

const Error = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WasteBreakdown = ({
  t,
  profile,
  wasteSensors,
  functionalLocationId,
  loadBreakdownData,
  error,
  data,
  meta,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs.WASTE_AMOUNTS);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (data === null || meta?.functionalLocationId !== functionalLocationId) {
        if (!loading) {
          setLoading(true);
          loadBreakdownData({
            functionalLocationId,
            sensorIds: wasteSensors.map(wasteSensor => wasteSensor.id),
            aggregation: 'monthlySum',
            startTime: moment()
              .subtract(1, 'year')
              .utc()
              .startOf('year')
              .toISOString(),
            endTime: moment()
              .utc()
              .endOf('year')
              .toISOString(),
          }).then(() => {
            setLoading(false);
          });
        }
      }
    },
    [data, functionalLocationId]
  );

  if (error.status) {
    return <Error>{t('Error while fetching data')}</Error>;
  }
  if (loading || !data) {
    return <WasteBreakdownSkeleton />;
  }
  return (
    <Section>
      <SectionHeader t={t}>
        <SectionTabSelector
          t={t}
          options={[
            { value: tabs.WASTE_AMOUNTS, label: t('Waste amounts') },
            { value: tabs.CARBON_FOORTPRINT, label: t('Carbon footprint') },
          ]}
          model={{ tabSelected: selectedTab }}
          property="tabSelected"
          onTabChange={(property, value) => setSelectedTab(value)}
          left
          large
        />
      </SectionHeader>
      <BreakdownTab
        profile={profile}
        wasteSensors={wasteSensors}
        tab={tabs.WASTE_AMOUNTS}
        data={data}
        visible={selectedTab === tabs.WASTE_AMOUNTS}
      />
      <BreakdownTab
        profile={profile}
        wasteSensors={wasteSensors}
        tab={tabs.CARBON_FOORTPRINT}
        data={data}
        visible={selectedTab === tabs.CARBON_FOORTPRINT}
      />
    </Section>
  );
};

WasteBreakdown.defaultProps = {
  wasteSensors: [],
};

WasteBreakdown.propTypes = {
  t: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  loadBreakdownData: PropTypes.func,
  error: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    message: PropTypes.string,
  }).isRequired,
  data: PropTypes.array,
  meta: PropTypes.shape({
    functionalLocationId: PropTypes.string,
    sensorIds: PropTypes.arrayOf(PropTypes.number),
  }),
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  error: state.values.recyclingBreakdown.error,
  data: state.values.recyclingBreakdown.data,
  meta: state.values.recyclingBreakdown.meta,
});

const mapDispatchToProps = {
  loadBreakdownData: loadBreakdownData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translations(WasteBreakdown));
