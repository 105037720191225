import {
  STATISTICS_PLANNED_VOLUMES_BY_BUILDING,
  STATISTICS_PLANNED_VOLUMES_BY_PROVIDER,
  STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE,
  SyntheticOrderStatus,
} from 'constants/serviceCalendar';
import { groupByFunctions, aggregate, plannedStatuses, filterByBuilding } from '../../utils';
import { getPlannedMaintenanceStatus } from 'utils/Data/serviceOrders';
import { sum } from 'lodash';

export const collectExternalUsers = maintenances => {
  const users = {};
  maintenances.forEach(maintenance => {
    if (maintenance.technicalResponsible) {
      maintenance.technicalResponsible.forEach(user => {
        users[user.id] = user;
      });
    }
  });
  return users;
};

export const aggregateMaintenances = (maintenances, statisticsType, functionalLocations) =>
  aggregate(
    maintenances,
    functionalLocations,
    groupByFunctions[statisticsType],
    plannedStatuses,
    getPlannedMaintenanceStatus
  );

export const filterAggregates = (aggregates, filter, statisticsType, functionalLocations, externalUsers, t) => {
  const needle = filter.trim().toLowerCase();
  switch (statisticsType) {
    case STATISTICS_PLANNED_VOLUMES_BY_BUILDING:
      return aggregates.filter(([key]) => filterByBuilding(needle, key, functionalLocations));
    case STATISTICS_PLANNED_VOLUMES_BY_PROVIDER:
      return aggregates.filter(([key]) => {
        const provider = externalUsers[key];
        return provider && provider.name.toLowerCase().indexOf(needle) !== -1;
      });
    case STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE:
      return aggregates.filter(([key]) => {
        const label = t(key);
        return label && label.toLowerCase().indexOf(needle) !== -1;
      });
    default:
      return aggregates.filter(([key]) => key.toLowerCase().indexOf(needle) !== -1);
  }
};

export const getChartData = (t, aggregates, statisticsType, functionalLocations, externalUsers) => {
  const categories = aggregates.map(([key]) => {
    switch (statisticsType) {
      case STATISTICS_PLANNED_VOLUMES_BY_BUILDING: {
        const functionalLocation = functionalLocations[key];
        return (functionalLocation && (functionalLocation.description || functionalLocation.name)) || key;
      }
      case STATISTICS_PLANNED_VOLUMES_BY_PROVIDER: {
        const provider = externalUsers[key];
        return provider ? provider.name : key;
      }
      case STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE:
        return t(key);
      default:
        return key;
    }
  });

  const data = aggregates.map(group => group[1]);
  const series = plannedStatuses.reduce((series, status) => {
    const statusData = data.map(categoryData => categoryData[status]);

    // Don't add COMPLETED_OTHER to series if there is no data
    if (status === SyntheticOrderStatus.COMPLETED_OTHER && sum(statusData) === 0) {
      return series;
    }
    series.push({
      name: `${t(status)}${status === SyntheticOrderStatus.COMPLETED_OTHER ? ' *' : ''}`,
      data: statusData,
      _showTooltipForZeroValue: true,
    });
    return series;
  }, []);

  return {
    categories,
    series,
  };
};
