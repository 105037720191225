import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputLabel from 'components/Form/InputLabel';
import Svg from 'components/Svg/Svg';

const Info = styled.div`
  &:not(:first-child) {
    margin-top: ${props => props.theme.spacing.xs};
  }
`;
Info.displayName = 'Info';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

const Content = styled.div`
  font-family: ${props => props.theme.font.arial};
  color: ${props => props.theme.colors.black};
`;
Content.displayName = 'Content';

const Name = styled.div`
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: 1.75;
`;
Name.displayName = 'Name';

const Row = styled.div`
  line-height: ${props => props.theme.font.lineHeight.lg};
  a {
    word-break: break-all;
  }
`;
Row.displayName = 'Row';

const Label = styled.span`
  color: ${props => props.theme.colors.darkGray};
`;
Label.displayName = 'Label';

const BALL_SIZE = 70;

const Ball = styled.div`
  width: ${BALL_SIZE}px;
  height: ${BALL_SIZE}px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.midnight};
  margin: ${props => props.theme.spacing.xs};
  margin-left: 0;

  display: none;
  ${props => props.theme.media.landscape`
        display: block;
    `}
`;
Ball.displayName = 'Ball';

const Icon = styled(Svg)`
  fill: ${props => props.theme.colors.white};
  width: ${BALL_SIZE}px;
  height: ${BALL_SIZE}px;
  padding: 5px;
`;
Icon.displayName = 'Icon';

const ContactInfo = ({ t, info: { header, name, tel, email } }) => {
  if (!tel && !email) {
    return null;
  }

  return (
    <Info>
      <InputLabel text={t(header)} />
      <Wrapper>
        <Ball>
          <Icon name="caverion_logo" />
        </Ball>
        <Content>
          <Name>{name}</Name>
          {tel && (
            <Row>
              <Label>{t('tel')}.</Label> <a href={`tel:${tel}`}>{tel}</a>
            </Row>
          )}
          {email && (
            <Row>
              <Label>{t('email')}</Label> <a href={`mailto:${email}`}>{email}</a>
            </Row>
          )}
        </Content>
      </Wrapper>
    </Info>
  );
};

ContactInfo.propTypes = {
  t: PropTypes.func.isRequired,
  info: PropTypes.shape({
    header: PropTypes.string.isRequired,
    name: PropTypes.string,
    tel: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default ContactInfo;
